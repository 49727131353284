
import { IsFail, NewFail, rzlog,RzRes} from "./inc"
import { NewOk } from "./inc"
import { RzOnFn } from "./rzcmn.ui"
import { RzEventNotier } from "./rzcmn.ui"
import { RzNote, RzNoteShare, RzNoteUser, RzPenAttr } from "./rznote.ui"
import './rznote.ui.css'
import { RzoNoteInfo, RzoNotePath, RzoNoteShare, RzoPageInfo } from "./rznote.ui.dto"
import { FtSrcOpt } from "../dto/ftclass.dto"
import { FtUiContext } from '../ui/ftclass2/ftui.context';


 
const rzIs=rzlog.makeDefs()
export const RzNoteUiRepo_setDbg=rzIs.setDbg

/********************************
 * RzNoteConn
 *******************************/
export class RzNoteEventEnum{
    
    static PAGE_ADD='page.add';
    static PAGE_PUT='page.put';
    static PAGE_DEL='page.del';



    static PAGES_PUT='pages.put';

    static PATH_ADD='path.add';
    static PATH_PUT='path.put';
    static PATH_DEL='path.del';
    static PATH_UNDO='path.undo';

    //@ADD
    static SHARING='sharing';
    static CHANGED='changed';
    static REPAINT='repaint';

   //@ static PEN_PUT='pen.put';
}

export interface RzSrcOpt {
    type?:string;
    isSkip?:boolean;
    isNoRepaint?:boolean;
}

export class RzNoteEvent {
    id?:string;
    noteId?:string;
    cmd?:string; //page,put,add,del
    scale?:number;
    page?:number;
    pages?:RzoPageInfo[];
    paths?:RzoNotePath[];
    depth?:number;

    visible?:boolean;
    writeOn?:boolean;
    //@ADD
    sharing?:RzNoteShare;
    penAttr?:RzPenAttr;
    srcOpt?:RzSrcOpt;
    
    constructor(pr?:Partial<RzNoteEvent>){
        Object.assign(this,pr)
        
    }
}

/****************** */
export interface RzNotePageLoader {
    hasPage(repo:RzNoteRepo, pgNo:number):Promise<RzRes<boolean>>
    loadPage(repo:RzNoteRepo, pgNo:number):Promise<RzRes<void>>
}

/****************** */
export interface RzNoteLoader {
    hasNote(noteId:string):Promise<RzRes<boolean>>
    loadNote(repo:RzNoteRepo, ldr?:RzNotePageLoader):Promise<RzRes<void>>
}
/****************** */
export interface RzNoteSaver {
    saveNote(repo:RzNoteRepo):Promise<RzRes<void>>
}

/****************** */
export class RzNotePageInfo {
    noteId?:string;
    ownerId?:string;
    pageNo?:number
    sesNo?:string
    pathCount?:number
    url?:string
    width?:number
    height?:number

    constructor(pr?:any){
        Object.assign(this,pr)
    }
}

export class RzNoteSes{

}

/****************** */
export class RzNotePage {
    sesNo='1'
    pathCount=1
    paths:RzoNotePath[]=[]
    attrs:any={}
    url?:string;
    width?:number;
    height?:number;
    ownerId?:string;
    noteId?:string;
   // pageNo?:number;

    noteRepo?:RzNoteRepo;

    setSesNo(sesNo:string){this.sesNo=sesNo;}
   // setPageNo(pgNo:number){this.pageNo=pgNo;}

    async addPath(p:RzoNotePath,ses?:RzNoteSes){
        //console.log('addpath --> ' , 'RzNotePage.addPath.0: pathCount=',this.pathCount,',id=',p.id, 'targetid=',p.targetId);
        if(rzIs.d)rzlog.debug('RzNotePage.addPath.0: pathCount=',this.pathCount,',id=',p.id)
        if(!p.id) { p.seq=this.pathCount++;  p.id=this.sesNo+'.'+ p.seq; p.sesNo=this.sesNo }
        else if(p.seq! >= this.pathCount ) this.pathCount=p.seq!+1 

        if(rzIs.d)rzlog.debug('RzNotePage.addPAth.1: pathCount=',this.pathCount,',id=',p.id)
  
        this.paths.push(p)

        return NewOk(p)
    }

    async putPath(p:RzoNotePath,ses?:RzNoteSes){
        let tpaths=this.paths.filter(t=> t.id===p.id)
        if(tpaths.length===0) return
        Object.assign(tpaths[0],p)
        return NewOk(tpaths[0])
    }

    async delPath(p:RzoNotePath,ses?:RzNoteSes){
        let opaths=this.paths.filter(t=> t.id === p.id)
        if(opaths.length===0) return
        let tpaths=this.paths.filter(t=> t.id !== p.id)
        
        this.paths=tpaths
        return NewOk(opaths[0])
    }

    pathAt(ix:number){
        if(this.paths.length===0) return null
        if(ix>0 && this.paths.length<=ix) return null

        if(ix<0)  {
           return this.paths[this.paths.length+ix]
        }
        else    return this.paths[ix]
    }

    delPathAt(ix:number){
        if(this.paths.length===0) return null
        let p:RzoNotePath 
        if(ix<0)  {
            p= this.paths[this.paths.length+ix]
        }
        else    p=this.paths[ix]
        console.log('delPathAt --> ' , p, this.paths);
        let tpaths=this.paths.filter(t=> t.id !== p.id)
        console.log('delPathAt --> ' , tpaths);
        this.paths=tpaths
        return p
    }

    getPath(id:string,ses?:RzNoteSes){
        console.log('undogetpath --> ',this.paths);
        if(this.paths.length===0) return null
        let tpaths=this.paths.filter(t=> t.id===id)
        if(tpaths.length===0) return null

        return tpaths[0]
    }

    getAttr(id:string,ses?:RzNoteSes){
        if(id in this.attrs) return  this.attrs[id]
        return null
    }

    setAttr(id:string,v:any,ses?:RzNoteSes){
        this.attrs[id]=v
    }

    iter(ses?:RzNoteSes){return this.paths}
}


 
/*******************************
 *  RzNoteRepo
 */

export class RzNoteRepo extends RzEventNotier {

    id:number =0
    scale=1
    curPage=0
    pages:RzNotePage[]=[]
    pathSeq=0

    undos=[]
    noteLoader?:RzNoteLoader
    pageLoader?:RzNotePageLoader
    noteSaver?:RzNoteSaver

    noteId?:string
    type?:string
    group?:string
    refRepo?:any

    ownerUsername?:string
    ownerId?:string

    drawer?:RzNoteUser;
    owner?:RzNoteUser;
    
    isLoaded:boolean =false;
    isSaved:boolean=true;

    penAttr?:RzPenAttr;
    
    pageWidth?:number;
    pageHeight?:number;

    context? :FtUiContext;

    isVisible?:boolean; 
    sharing?:RzNoteShare;
    
    setId(id:number){this.id=id}
    setNoteId(noteId:string){this.noteId=noteId}
    getNoteId(){return this.noteId!}
    setRefRepo(refRepo:any){this.refRepo=refRepo}

    getOwnerUsername(){ return this.owner?.username||this.ownerUsername; }
    setOwnerUsername(unm:string){ 
        if(!this.owner)this.owner={username:unm};
        else this.owner.username=unm;
        this.ownerUsername=unm;
    }

    getOwnerId(){ return this.owner?.uid||this.ownerId;}
    setOwnerId(id:string){     
        if(!this.owner)this.owner={uid:id};
        else this.owner.uid=id;
        
        if(this.refRepo) this.refRepo.owner={...this.owner};

        this.ownerId=id;
    }
    setOwner(owner?:RzNoteUser){
        this.owner=owner;
        if(this.refRepo) this.refRepo.owner={...owner};
    }

    setDrawer(ui:RzNoteUser){
        this.drawer={...ui};
        if(this.refRepo) this.refRepo.drawer={...ui};
    }

    // setPenAttr(attr:RzPenAttr,isNotiEvent?:boolean){
    //     this.penAttr=attr;
        
    //     if(isNotiEvent==true) {
    //         let evt=new RzNoteEvent({ penAttr:this.penAttr, noteId:this.noteId});
    //         this.notiChanged(RzNoteEventEnum.PEN_PUT,evt);
    //     }
    // }

    // getPenAttr(){
    //     return this.penAttr;
    // }
    refresh(){
        this.notiRepaint();
    }
    
    getDrawer(){
        return this.drawer;
    }
    getDrawerId(){
        return this.drawer?.uid;
    }

    setType(ty:string){this.type=ty}
    getType(){return this.type}
    
    getGroup(){return this.group}
    setGroup(grp:string){this.group=grp;}
    

    setVisible(b:boolean,srcOpt?:FtSrcOpt){
        this.isVisible=b;
         if(!srcOpt) {
            let evt=new RzNoteEvent({ visible:b, noteId:this.noteId});
            this.notiChanged(RzNoteEventEnum.PAGE_PUT,evt);
         }
    }

    setSharing(sharing?:RzoNoteShare,srcOpt?:FtSrcOpt ){
        let nsh={...sharing,noteId:this.noteId}
        this.sharing={...this.sharing, ...sharing, };

            let evt=new RzNoteEvent({ sharing:nsh, noteId:this.noteId});
            this.notiChanged(RzNoteEventEnum.SHARING,evt);
         
    }

    getSharing():RzoNoteShare|undefined{
        return this.sharing;
    }

    notiRepaint(){
        let evt=new RzNoteEvent({noteId:this.noteId});
        this.notiChanged(RzNoteEventEnum.REPAINT,evt);
    }

    getPageWidth(){return this.pageWidth;}
    setPageWidth(w:number){this.pageWidth=w;}
    getPageHeight(){return this.pageHeight;}
    setPageHeight(h:number){this.pageHeight=h;}
    getPageSize(){return {width:this.pageWidth,height:this.pageHeight}}

    async load(opts?:any){
        return await this.doLoad(opts);
    }

    async save(opts?:any){
        if(this.noteSaver) {
            let r= await this.noteSaver.saveNote(this);
            if(r.status>0) return NewFail(r.message);
        }
        this.isSaved=true;
        return NewOk();
    }

    setNoteLoader(noteLoader:RzNoteLoader){
        this.noteLoader=noteLoader;
    }

    setPageLoader(pageLoader:RzNotePageLoader){
        this.pageLoader=pageLoader;
    }

    setNoteSaver(saver:RzNoteSaver){
        this.noteSaver=saver;
    }
    
    getNoteInfo(){
        let r=new RzoNoteInfo({
            ...this.noteInfo,
            noteId:this.noteId,type:this.type, 
            pageNo:this.curPage, pageCount:this.pages.length,
            ... (this.pageWidth)?{pageWidth:this.pageWidth,
                pageHeight:this.pageHeight}:{}
            });
        return r;
    }

    noteInfo?:RzoNoteInfo;
    putNoteInfo(inf:RzoNoteInfo){
        this.noteInfo={...inf};

        if(inf.noteId!==undefined)this.noteId=inf.noteId;
        if(inf.type!==undefined)this.type=inf.type;
        if(inf.pageNo!==undefined) this.curPage=inf.pageNo;
   
        //if(inf.pageCount!==undefined) this.curPage=inf.pageCount
        if(inf.pageWidth!==undefined) this.pageWidth=inf.pageWidth;
        if(inf.pageHeight!==undefined) this.pageHeight=inf.pageHeight;
    }

    //attrs={}
    async getAttr(pg:number,id:string){
        if(this.pages.length===0) return NewFail();

        let tpg=(pg || this.curPage ) -1;
        if(!this.pages[tpg]) return NewFail();

        let r= this.pages[tpg].getAttr(id);
        rzlog.debug(`RzNoteRepo.getAttr:${id}.pg=`,pg,',id=',id,',r=',r);

        if(r) return NewOk(r);
        //if( id in this.attrs) return NewOk({src:this.attrs[id]})
        return NewFail();
    }


    setAttr(pg:number,id:string, v:any) {
        this.isSaved=false;
        //this.attrs[id]=v
        if(this.pages.length===0) {
            rzlog.error('RzNoteRepo.setAttr : no pages - id=',id,', v=',v);
            return;
        }
        let tpg=(pg||this.curPage ) -1;

        this.pages[tpg].setAttr(id,v);
    }
    

    /**************** */
    putScale(scale:number,srcOpt?:FtSrcOpt){
        this.scale=scale;

        let evt=new RzNoteEvent({cmd:'scale', scale:scale, srcOpt:srcOpt});
        this.notiNoteEvent(evt);
    }

    getScale(){ return this.scale; }


    async undoPath(pg?:number,depth?:number,srcOpt?:FtSrcOpt):Promise<RzRes<RzoNotePath>>{
        let tpg = ( pg || this.curPage);
        let page=this._pageAt(tpg);
        if(!page) return  NewFail("RzNote.undoPath : no page");

        let inf=this.getMyLastPath(page)
        if(!inf) return NewFail("RzNote.undoPath : no path");

        if(inf.type==='erase' && inf.id ){
            let tp=page.getPath(inf.id);
            //console.log('undoPath targetId ==> ' , tp);
            if(tp){
                 tp.drawable=true;
                // console.log('undopath --> tp:' , tp.points , ', inf : ' , inf.target?.points)
                 tp.points = [...inf.points||[]];
                 page.putPath(tp);
            }
        } else {
            if(inf.id){
                    inf.drawable=false;
                    page.putPath(inf);
            }
        } 

        let evt=new RzNoteEvent({cmd:RzNoteEventEnum.PATH_UNDO,  noteId:this.noteId, page:pg , depth:depth} );
        this.notiNoteEvent( evt);
        return NewOk(inf);
    }

    getMyLastPath(page :RzNotePage){
        // const drawerId = this.noteRepo?.refRepo.drawer.uid
        console.log("getMyLastPath======================>", this)
        let cnt=1;
        do{
            let inf:RzoNotePath|null =page.pathAt(-cnt);
            if(!inf) return null;
            cnt++;
            if(inf.type!='erase' && inf.drawable===false) continue;
            let drwId=this.getDrawerId(); //undefined
            rzlog.debug('getMyLastPath did:',drwId,'=inf.did:',inf.drawerId);
            if (drwId ==undefined) drwId=this.getOwnerId();
            if (inf.drawerId==drwId) return inf;
        }while(true);
    }

    async redoPath(pg:number,depth:number,srcOpt?:FtSrcOpt){
        return NewFail('not implement');
    }

    
    /**************** */
 
    getCurPageNo(){
        return this.curPage;
    }

    setCurPageNo(pg:number,srcOpt?:FtSrcOpt){
        
        if(rzIs.i)rzlog.info('RzNoteRepo.setCurPage:x ntID=',this.noteId,',pg=',pg,", total=",this.pages.length);
        this.curPage=pg;
        if(this.refRepo){
            this.refRepo.curPage=pg;
        } 
        
        

        //alert('setCurPageNo');
        this.notiChanged( RzNoteEventEnum.PAGES_PUT,{noteId:this.noteId, pages:[{pageNo:pg}], srcOpt:srcOpt});
    }

    async addPage(pg:number,pgInf?:RzNotePageInfo,srcOpt?:FtSrcOpt){
        if(pg>=this.pages.length){
            this.doNewPageAt(pg);
        }  
        let r:RzNotePageInfo= new RzNotePageInfo({pageNo:pg}) ;

        this.doUpdateNoteInfo({total:this.pages.length,pageNo:pg});
        this.notiChanged( RzNoteEventEnum.PAGE_ADD,{noteId:this.noteId, pages:[{pageNo:pg}], srcOpt:srcOpt});
        return NewOk(r);
    }

    doUpdateNoteInfo( inf:RzoNoteInfo){
        if(!this.noteInfo) return;
        if(inf.total!==undefined)this.noteInfo.total=inf.total;
        if(inf.pageNo!==undefined)this.noteInfo.pageNo=inf.pageNo;
    }

    async putPage(pg:number, pgInf: RzNotePageInfo, srcOpt?:FtSrcOpt):Promise<RzRes<RzNotePageInfo>>{
        let vs=this.pages.filter((t:any,ix:number)=> ix===pg-1);
        if(vs.length===0) return NewFail();

        let nvs=this.pages.filter((t:any,ix:number)=> ix===pg-1);
        if(nvs.length===0) return NewFail();

        let r:RzNotePageInfo= new RzNotePageInfo();
        this.bindPageInfo(r,nvs[0]);

        this.notiChanged( RzNoteEventEnum.PAGE_DEL,{noteId:this.noteId, pages:[{pageNo:pg}], srcOpt:srcOpt});
        return NewOk(r);
    }

    bindPageInfo(pgi:RzNotePageInfo, pg:RzNotePage){
        Object.assign(pgi,pg);
    }

    bindPage(pg:RzNotePage, pgi:RzNotePageInfo){
        Object.assign(pg,pgi);
    }

    async delPage(pg:number,srcOpt?:FtSrcOpt):Promise<RzRes<RzNotePageInfo>>{
        let vs=this.pages.filter((t:any,ix:number)=> ix===pg-1);
        if(vs.length===0) return NewFail('no page');

        let nvs=this.pages.filter((t:any,ix:number)=> ix!==pg-1);
        this.pages=nvs;

        let r:RzNotePageInfo= new RzNotePageInfo({pageNo:pg}) ;

        this.notiChanged( RzNoteEventEnum.PAGE_DEL,{noteId:this.noteId, pages:[{pageNo:pg}], srcOpt:srcOpt});
        return NewOk(r);
    }

    doNewPageAt(pgNo:number ){
        let last:RzNotePage|undefined=undefined

        while(pgNo > this.pages.length){
            last = new RzNotePage();
          //  last.setPageNo(pgNo);
            last.noteRepo=this;
            this.pages.push(last);
        }
        if(this.curPage === 0 ) this.curPage=1;

        return last;   
    }

    _pageAt(pg:number){
        var page=this.pages[pg-1];
        return page;
    }

    async getPageInfo(pgNo:number){

        //if(this.refRepo) return this.refRepo.getPageInfo(pgNo);

        let pg:RzNotePage =   this._pageAt(pgNo);
        let pgi:RzNotePageInfo = new RzNotePageInfo();

        this.bindPageInfo(pgi, pg);
        return NewOk(pgi);
    }
    

    addPagePathAt(pgNo:number, path:any){
        if(pgNo>=this.pages.length){
            this.doNewPageAt(pgNo);
        }
        rzlog.debug("RzNote.addPagePathAt : pgNo=",pgNo,', pgs.len=', this.pages.length);
        this._pageAt(pgNo).addPath(path);
    }


    notiChanged(cmd:RzNoteEventEnum,param?:any,srcOpt?:RzSrcOpt){
        let evt=new RzNoteEvent({ ...param, cmd:cmd, srcOpt:srcOpt});
        this.notiNoteEvent(evt);

        //VERY IMPORTANT !! - for PATH
        if(this.refRepo) this.refRepo.notiNoteEvent(evt);
    }
    
    async doLoad( opts?:any):Promise<RzRes<void>>{
        if(this.noteLoader) {
            let r0=await this.noteLoader.hasNote(this.noteId!);
            if(r0.status>0) return NewFail(r0.message);

            let r= await this.noteLoader.loadNote(this,this.pageLoader);
            if(r.status>0) return NewFail(r.message);
         }

         if(this.noteInfo){
            rzlog.debug('RzNoteUiRepo.doLoad : noteInfo=',this.noteInfo);
            this.ownerId=this.noteInfo.ownerId;
            this.ownerUsername=this.noteInfo.ownerName;
            
         }

         this.curPage=1;
         this.isLoaded=true;
         return NewOk();
    }


    async iter():Promise<RzRes<RzNotePage[]>>{
        if(!this.isLoaded) {
            let r = await this.doLoad();
            if(r.status>0) return NewFail(r.message);
        }
            
        
        if(this.pages.length===0) return NewOk([]);

        return NewOk(this.pages);
    }

    async doLoadPage(pg:number){
        if(this.pageLoader){
            let r=   await this.pageLoader.loadPage(this, pg);
            return r;
        }   
        return NewOk();
    }

    async iterPath(pg?:number):Promise<RzRes<RzoNotePath[]>>{
        let tpg=pg||this.curPage;
        if(tpg>0){
            //let r= await this.doLoadPage(pg)
            //if(r.status>0) return NewFail(r.message)
        }
        
        if(this.pages.length===0) return NewFail("NoteRepo : noPage="+pg);

        let paths=this._pageAt(tpg).iter();
        return NewOk(paths)
    }

    async iterPathAll(type?:string):Promise<RzRes<RzoNotePath[]>>{
        let rpaths:RzoNotePath[]=[];
        let pgCnt=this.getPageCount();
        for(let i=1;i<=pgCnt;i++){
          
            let  paths=this._pageAt(i).iter();
            if(type){
                let vs=paths.filter(e=>e.type===type);
                rpaths.push(...vs);
            }
        }
        
        return NewOk(rpaths)
    }

    async getPath(pg:number, id:string):Promise<RzRes<RzoNotePath>>{
        let tp=this._pageAt(pg).paths.filter(t=> t.id===id);
        if(tp.length===0) return NewFail("no path : id="+id);

        let r=tp[0];
        return NewOk(r);
    }
    

    async addPath(path:RzoNotePath,pg?:number){
       //console.log('addpath --> ' , path);
          
            if(path &&  path.drawerId ==undefined)path.drawerId=this.ownerId;
            if(path &&  path.ownerId ==undefined){

                path.ownerId=this.ownerId;
            }

            let tpg = ( pg || this.curPage);
            if(rzIs.d)rzlog.trace('RzNoteRepo.addPAth: tgtPg= ',tpg,',path=',path);

            if(this.pages.length===0) return NewFail("NoteRepo.addPAth : no page - pg=",tpg);

            if(path.pageNo===undefined) path.pageNo=tpg;
            let tr=await this._pageAt(tpg).addPath(path);
            if(tr.status>0) return NewFail(tr.message);
            
 
            rzlog.debug('RzNoteRepo.addPath : path=',path,',pg=',tpg,',id=',path.id,',drawerId=',path.drawerId,',ownerId=',path.ownerId);
            //@ this.notiChanged(RzNoteEventEnum.PATH_ADD ,{page:tpg,paths:[npath]});
            this.notiChanged(RzNoteEventEnum.PATH_ADD ,{page:tpg,paths:[path]});
            return tr;
    }


    async putPath(path:RzoNotePath,pg?:number){
            let tpg=(pg||this.curPage);
            let page=this._pageAt(tpg);
            if(!page) return NewFail("RzNoteRepo.putPath.getPage");
            let r= await page.putPath(path);
            if(!r || r!.status>0) return NewFail("putPath");

   
            this.notiChanged(RzNoteEventEnum.PATH_PUT,{page:tpg,paths:[path]});
            return r;
    }

    async delPath(path:RzoNotePath,pg:number){
        let tpg=(pg||this.curPage);
        let page=this._pageAt(tpg);

        let r=await page.delPath(path);
        if(!r || r.status>0) return NewFail("delPath");

        this.notiChanged(RzNoteEventEnum.PATH_DEL,{page:tpg,paths:[path]});
        return r;
    }


    async pathCount(pg?:number){
       let tpg=pg||this.curPage;
       if(this.pages.length===0) return NewOk(0);
       if(!this._pageAt(tpg)) return NewOk(0);
       
       let c=this._pageAt(tpg).paths.length;
       return  NewOk(c);
    }
    
    getPageCount(){
        return this.pages.length;
    }

    setContext(context: FtUiContext){
        this.context = context;
    }


    async size(){
        return NewOk(this.pages.length);
    }

    /*********
     * 
     */

    addOnNoteEvent(fn:RzOnFn,pr?:any){
        super.addOnEvent(fn,pr);
    }
    delOnNoteEvent(fn:RzOnFn,pr?:any) {
        super.delOnEvent(fn,pr);
    }

    notiNoteEvent(e:any){
        super.notiEvent(e);
    }
    delOnNoteEventAll()    {
        super.delOnEventAll();
    }
}//class


export class RzNotePathUtil { 

    async getAllPath(repo:RzNoteRepo,pg:number) :Promise<RzRes<RzoNotePath[]>>{
        let tr=await repo.iterPath(pg);
        if(tr.status>0) return NewFail();
        let paths=[...tr.data!];
        let rpaths=paths.reverse().filter((el) => el.type === 'line');
        console.log('getAllPath ===> ' , rpaths);
        return NewOk(rpaths!);
    }

    async getPathAt(repo:RzNoteRepo,pg:number, cent_x:number,cent_y:number, w:number, h:number, shape?:string):Promise<RzRes<RzoNotePath>>{
        
        let tr=await repo.iterPath(pg);
        if(tr.status>0) return NewFail();

        let paths=[...tr.data!];

        let r:RzoNotePath|null=null;
        if(rzIs.d)rzlog.debug('pathAt : x=',cent_x,',y=',cent_y,',paths=',paths);
        let rpaths=paths.reverse();
        for(let k in rpaths){
            let p = rpaths[k];
            if(!p.drawable)  continue;
            let b=this.containsPath(p,cent_x,cent_y,w,h,shape);
            if(rzIs.t)rzlog.trace('RzNote : erase.containAt : ,x=',cent_x,',y=',cent_y,',p=',p,', isOk=',b);
            if(b) {
                r=p;
                if(rzIs.d)rzlog.debug('pathAt OK : p=',p);
                break;
            }
            
        }
        if(!r) return NewFail('no path :x='+cent_x+',y='+cent_y+',paths='+paths.length);
        return NewOk(r!);
    }

 
    checkPoint(p:any, x :number,y:number,w:number,h:number,shape?:string) {
        let r=false;
        let tw=(p.width || 8)/2;
        let in_x=false;
        let in_y=false;
        if(p.x0 < p.x ){
            if( x>=p.x0-tw && x<=p.x+tw )   in_x=true;
        } else {
            if( x>=p.x -tw && x<=p.x0 +tw)   in_x=true;
        }

        if(p.y0< p.y ){
            if( y>=p.y0 -tw&& y<=p.y+tw )   in_y=true;
        } else {
            if( y>=p.y -tw && y<=p.y0+tw )   in_y=true;
        }
        if(in_x && in_y) r=true;

        let dx=p.x0-p.x;
        let dy=p.y0-p.y;
        let d=-1;
        let pr=r;
        if(r && dx!==0){
            let a=dy/dx ;
            let c= p.y  - p.x * a;
            d= Math.abs(a*x -y+c) / Math.sqrt(a*a + 1);
           
            if(tw < d ) r=false;
        }
        // if( x>=p.x0 && x<=p.x && y>=p.y0 && y<=p.y) r=true      // x,y
        // else if( x<=p.x0 && x>=p.x && y>=p.y0 && y<=p.y) r=true // x'
        // else if( x>=p.x0 && x<=p.x && y<=p.y0 && y>=p.y) r=true // y'
        // else if( x<=p.x0 && x>=p.x && y<=p.y0 && y>=p.y) r=true // x',y'
        
        if(rzIs.t)rzlog.trace('checkPoint : p=',p,',x=',x,',y=',y,',w=',w ,'pr=',pr,',r=',r,';d=',d);

        return r;
    }

    containsPath(p:any,x:number,y:number,w:number,h:number,shape?:string){
        let r=false;
       // if(!p.drawble) return r

        if(p.type==='line'){
            if(p.points){
                for(let i in p.points){
                    let np={...p.points[i], width:p.width};
                    r=this.checkPoint(np,x,y,w,h,shape);
                    if(r) break;
                }
            } else {
                r= this.checkPoint(p,x,y,w,h,shape);
            }
        } else if(p.type==='rect' || p.type==='triangle'
                || p.type==='hexagon' 
            || p.type==='triangle'  || p.type==='circle'   ) {

            if( x>=p.x && x<=p.x + p.w && y>=p.y && y<=p.y+p.h) r=true; 

        } else if(p.type==='text'){
            if( x>=p.x && x<=p.x + p.w && y>=p.y && y<=p.y+p.h) r=true;      // x,y
        } else if(p.type==='memo'){
            if( x>=p.x -4 && x<=p.x + p.w && y>=p.y-4  && y<=p.y+p.h) r=true ;
            // if( x>=p.x && x<=p.x + p.w && y>=p.y && y<=p.y+p.h){
            //     let sz=16
            //     let r1=false, r2=false
            //     if(x>=p.x && x<=p.x + sz && y>=p.y && y<=p.y+sz){
            //         r1=r=true
            //     }  
            //     if(x>=p.x+sz && x<=p.x + p.w && y>= p.y  && y<=p.y+p.h){ 
            //         r2=r=true
            //     }
            //     rzlog.debug(', r1=',r1,'r2=',r2,",x,y=(",x,y,"), p=",p)
            // } 
        }
        return r
    }
}//class

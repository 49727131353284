import { IsFail, IsFailLog, NewOkRs, NewRsRes, RzReq, RzRes, RzRsRes } from "../rzcmn"
import { FtConnInfoOpts } from "./ftclass"
import { FtClassClientFac } from "./ftclass.client.fac"
import { FtClientEvent } from "./ftclass.client.rtc"
import { FtSrcOpt, FtoClassInfo, FtoConnInfo, FtoNoteInfo } from "./dto/ftclass.dto"
import { FtClassEvent } from "./ftclass.event"
import { FtClassRepoMng } from "./repo/ftclass.repo-mng"
import { FteChatMsg, FteClassJoinReq, FteNoteInfo, FtePage } from "./repo/ftclass.repo.dto"
import { rzlog, NewOk,NewFail } from "./inc"
import { RzEventNotier } from "./inc"
import { RzNotePage, RzOnFn } from "./note.ui"

const rzIs=rzlog.makeDefs(true)
export const FtClassClient_setDbg=rzIs.setDbg 

/******************************
 * FtClassClient
 */
export abstract class FtClassClient extends RzEventNotier {
    subscribs=[]
    _isConnected=false
    sesId?:string
    classId?:string
    username?:string 
    devType?:string
    userType?:string
    accToken?:string
    token?:string;
    connInfo?:FtoConnInfo
    isReconnected=false;

    setSesId(sesId:string){this.sesId=sesId}
    setUsername(username:string){this.username=username}
    setClassId(clsId:string){this.classId=clsId}
    

    setToken(tkn:string){this.token=tkn
        rzlog.debug("FtClassClient.setToken : TOKEN=",tkn);
        if(this.connInfo)this.connInfo.token=tkn;
    }
    isConnected(){
        return this._isConnected
    }

    getUserInfo(){
        return {classId:this.classId, username:this.username, sesId:this.sesId}
    }

    
    init(connInfo:FtoConnInfo){
        if(rzIs.i) rzlog.info("FtClassClient.init : opt(connInfo)=", connInfo)
        this.connInfo=connInfo
        return true
    }

    setConnInfo(connInfo:FtoConnInfo){
        this.connInfo=connInfo
    }
    
    onReconnected(){
        this.isReconnected=true;
    }
    
    /**
     * 
     */
    abstract connect():Promise<RzRes<boolean>>;
     
    abstract close():Promise<RzRes<void>>;
    abstract subscribClassEvent(clsId:string,fn:(e:FtClassEvent)=>void, errFn:(err:any)=>void):void;
    abstract fetchClassInfo(classId:string,opt?:any):Promise<RzRes<FtoClassInfo>>;
    abstract fetchNoteInfos(classId:string, pg:number, opt?:any):Promise<RzRsRes<FteNoteInfo>>;
    abstract addNoteInfo(classId:string, ntInf:FtoNoteInfo):Promise<RzRes<FteNoteInfo>>;
    //@abstract addNoteInfo(classId:string, ntInf:FtoNoteInfo):Promise<RzRsRes<FteNoteInfo>>
    abstract fetchNotePage(noteId:string,pg:number,opt?:any):Promise<RzRes<FtePage>>;
    abstract fetchNotePages(noteId:string,opt?:any):Promise<RzRes<RzNotePage[]>>;
    abstract fetchGetChats(classId:string):Promise<RzRsRes<FteChatMsg>>;
    //abstract fetchSendNoti(classId:string, msg:FteNotiMsg):Promise<RzRes<FteNotiMsg>>;
    abstract fetchJoinClass(req:any):Promise<any>;
    abstract fetchLeaveClass(req:any):Promise<any>;
    abstract fetchSendChat(req:RzReq<FteChatMsg>):Promise<RzRes<FteChatMsg>>;
    /**
     * 
     * @param {*} classId string
     * @param {*} fn fn(evt FtClassClientEvent)
     * @returns RzRes
     */
    async subscribClass(classId:string,fn:RzOnFn,pr?:any){
        return NewOk()
    }

    async notiClassEventSubscribed(ev:any){

    }

    async pubClassEvent(ev:FtClassEvent){
        return NewOk()
    }

    addOnClientEvent(fn:RzOnFn){
      this.addOnEvent(fn)
    }

    /**
     * 
     * @param {*} ev FtClientEvent
     */
    notiClientEvent(ev:FtClientEvent){
      this.notiEvent(ev)

      if(ev.cmd !=='conn') {
          var nev={...ev}
          this.pubClassEvent(nev)
      } else {
        var nev={...ev}
        this.pubClassEvent(nev)
      }
    }
}



/******* */

/**********
 * 
 */
 export class FtClassTmpClient extends FtClassClient {
     subscribClassEvent(clsId:string,fn: (e: FtClassEvent) => void, errFn: (err: any) => void): void {
      //   throw new Error("Method not implemented.")

     }
     fetchSendChat(req: any): Promise<any> {
         throw new Error("Method not implemented.")
     }
     async fetchJoinClass(req: FteClassJoinReq): Promise<RzRes<Boolean>> {
       //  throw new Error("Method not implemented.")
       return NewFail('cant join class');
     }

    fetchLeaveClass(req: FteClassJoinReq): Promise<any> {
        throw new Error("Method not implemented.")
    }

     async connect(): Promise<RzRes<boolean>> {
       //  throw new Error("Method not implemented.")
       return NewOk(true);
     }

     async close(): Promise<any> {
         //throw new Error("Method not implemented.")
     }

     fetchClassInfo(classId: string, opt?: any): Promise<RzRes<FtoClassInfo>> {
         throw new Error("Method not implemented.")
     }
     fetchNoteInfos(classId: string, pg: number, opt?: any): Promise<RzRsRes<FteNoteInfo>> {
         throw new Error("Method not implemented.")
     }

     classInfos:FtoClassInfo[]=[];
     noteInfos :FteNoteInfo[]=[];
     noteCnt=0;
     async addNoteInfo(classId:string, ntInf:FtoNoteInfo): Promise<RzRes<FteNoteInfo>> {
            let cls= this.classInfos.filter(t=> t.classId===classId);
            let cl:FtoClassInfo|null=null;

            if(cls.length == 0) {
                cl=new FtoClassInfo();
                cl.classId=classId;
                cl.notes=[];
                this.classInfos.push(cl);
            } else {
                cl=cls[0];
            }

            cl.notes.push(ntInf);
            
            let sz=this.noteInfos.length;
            let nt={ notdId:ntInf.noteId, curPage:0, pages:[] as FtePage[]};
            this.noteInfos.push(nt);
            let ntInfos=this.noteInfos;//
            return NewOk(nt);
      }


     fetchNotePage(noteId: string, pg: number, opt?: any): Promise<RzRes<FtePage>> {
         throw new Error("Method not implemented.")
     }

    fetchNotePages(noteId: string,  opt?: any): Promise<RzRes<RzNotePage[]>> {
        throw new Error("Method not implemented.")
    }
     fetchGetChats(classId: string): Promise<RzRsRes<FteChatMsg>> {
         throw new Error("Method not implemented.")
     }
     procClassEventByUi(e: FtClassEvent, pr: any): void {
         throw new Error("Method not implemented.")
     }
 
}//class



/***************
 * 
 */
 export class FtClassClientRef {
    repoMng?: FtClassRepoMng;
    client?:FtClassClient;
    clientFac?:FtClassClientFac;
    connInfo?: FtoConnInfo;

    async init(mng:FtClassRepoMng){
      this.repoMng=mng
      return NewOk()
    }
    
    close(){
        this.client?.close();
        this.client=undefined;
    }

    isConnected(){
        return this.client?.isConnected()||false
    }
    
    async getClient():Promise<FtClassClient|undefined>{
  
      let r0= await this.doGetClient()
      if(rzIs.i) rzlog.info('FtClassClientRef.getClient : r0=',r0)
      if(IsFail(r0)) return undefined;
  
      return r0.data;
    }
    
    onClientConnected? : ()=>void;
    onClientClosed? : ()=>void;
    onClientClassEvent?:(evt:FtClassEvent,srcOpt?:FtSrcOpt)=>void;

    async doGetClient():Promise<RzRes<FtClassClient>>{
        if(!this.client && !this.clientFac) return NewFail("FtClassRepoMng : doGetClient > no clientFac,client")

        if(this.client && this.client.isConnected()) {
            if(this.client.isReconnected){
                this.client.isReconnected=false;
                if(this.repoMng){
                    let classId=this.repoMng.getClassId();
                    this.client.subscribClassEvent(classId!,(evt:any)=>{
                        if(IsFail(evt)){
                            rzlog.error("FtClassClient : subClassEvent - e= ",evt)
                            return ;
                        } 
            
                        //@this.repoMng?.doSubscrib(evt.data!)
            
                        let nevt={...evt.data!,  srcOpt:{type:'net'}}
                        if(this.onClientClassEvent) this.onClientClassEvent(nevt)
                    },(err)=>{
                        rzlog.error("FtClassClient : subClassEvent - e= ",err)
                    });
                }
            }
            

            return NewOk(this.client);
        } 

        if(this.client && !this.client.isConnected()) {   
            this.client.close(); 
            this.client=undefined   
            if(this.onClientClosed) this.onClientClosed()
        }

        if(! this.clientFac) return NewFail("FtClassRepoMng : doGetClient > no clientFac");
        if(! this.connInfo ) return NewFail("FtClassRepoMng : doGetClient > no connInfo ");
        if(! this.connInfo.linkType ) return NewFail("FtClassRepoMng : doGetClient > no connInfo.linkType");

        this.client=this.clientFac.newClient(this.connInfo.linkType,this.connInfo);
        if(!this.client) return NewFail("FtClassRepoMng : clientFac.newClient > no client");

        let tr= await this.client.connect();
        if(IsFailLog(tr,"FtClassRepoMng : doGetClient > can't connect !!!!")){
            this.client=undefined;
            return NewFail("FtClassRepoMng > doGetClient > client.connect > "+tr.message);
        }

        if(!this.repoMng) return NewFail("FtClassRepoMng : doGetClient > no repoMng");
        let classId=this.repoMng.getClassId();
        this.client.subscribClassEvent(classId!,(evt:any)=>{
            if(IsFail(evt)){
                rzlog.error("FtClassClient : subClassEvent - e= ",evt)
                return 
            } 

            //@this.repoMng?.doSubscrib(evt.data!)

            let nevt={...evt.data!,  srcOpt:{type:'net'}}
            if(this.onClientClassEvent) this.onClientClassEvent(nevt)
        },(err)=>{
            rzlog.error("FtClassClient : subClassEvent - e= ",err)
        })


        if(this.onClientConnected)  await this.onClientConnected();
        rzlog.debug('FtClassClientRef.doGetCient : this.client=',this.client);
        return NewOk(this.client);
     }


  }//class




  

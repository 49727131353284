import { rzlog,RzUiLet, } from "../../inc";

import { FtLayoutCtx, FtLayoutInit, FtLayoutOpts, FtUiLets } from "../ftclass/ftclass.ui.layout";
import { FtFullLayout } from "./ftclass.ui.layout.full";
import { FtClassTop, FtPaletteEvent } from "../ftclass/ftclass.ui.top";

/** dbg */
const rzIs=rzlog.makeDefs()
export const FtClassUiLayoutSubTop_setDbg =rzIs.setDbg

/**
 * FtSubTopInit
 */
export class FtSubTopInit implements FtLayoutInit {
    lman:FtFullLayout;

    constructor(lman:FtFullLayout){
        this.lman=lman;
    }
    
    init(lctx:FtLayoutCtx,pr?:any){
        let lman=this.lman;

        if(rzIs.d) rzlog.debug('FtClassLayout.doInit : opt=',lctx.opt)
        let csstext='width:100vw;height:60px;background-color:#333333;display:flex; flex-direction:row;'
        if( lctx.opt.isCtrlOn) this.doInitSubTopCtrl(lctx.body,lctx.lets!.ctrl!,csstext)
        else if(!lctx.opt.isCtrlOn){
            rzlog.debug('FtClassLayout.Ctrl.setVisible: removeChild=',lctx.lets!.ctrl)
            if(lctx.lets!.ctrl) lman.doRemoveChild(lctx.body,lctx.lets!.ctrl)
        }
    }

    doInitSubTopCtrl(p:RzUiLet,topLet:RzUiLet,cssText:string,opt?:any){
        let lman=this.lman;
        if(lman.doUdateVisble(p,topLet,0,cssText))   {
            return topLet
        }   

        let sublet:FtClassTop=lman.subLetFac.createTop(p,cssText,lman.repoMng!,opt) as FtClassTop
        sublet.setUserRepo(lman.repoMng!)

        sublet.addPaletteEvent((e:FtPaletteEvent,pr:any)=>{
            if(rzIs.d) rzlog.debug('FtFullLayout.doInitSubTopCtrl : onPaletteEvent e=',e)
            pr.doProcPaletteEventByUI(e)
        },lman)

        lman.lets!.ctrl=sublet
        return sublet
    }

}//class
/**
 * FtClassRepoMng
 * 
 */

import { rzlog,RzEventNotier,RzCtx, RzSrcOpt, RzRsRes, NewOkRs, rzDlgShow, RzPenAttr, } from "../inc";
import { NewOk, RzRes, RzBasRepo,NewFail,IsOk,IsFail,IsFailLog,RzOnFn,OnFn,RzNoteRepo,RzNotePageInfo, } from "../inc";

import { FtClassEvent, FtClassEventEnum } from "../ftclass.event";
import { FtNoteRefRepo, FtNoteRefRepoFac } from "./ftclass.repo.ref";
import { FtDeskRefRepo, FtDeskRepo } from "../ui/ftclass/ftdesk.ui.repo";
import { FtClassStoreFac, FtClassRepoFac, FtClassRepoFacDef } from "./ftclass.repo.fac";
import { FtSrcOpt, FtoClassInfo, FtoClassNoti, FtoClassNotiEnum, FtoClassSharing, FtoConnInfo, FtoDevTypeEnum, FtoEditLock, FtoEtcSetting, FtoNoteInfo, FtoPage, FtoPageInfo, FtoSession, FtoSetting,  FtoUser, FtoUserTypeEnum } from "../dto/ftclass.dto";
import { FtClassRepoMngRecvHdr } from "./ftclass.repo-mng.rhdr";
import { FtClassClientFac } from "../ftclass.client.fac";
 


import { RzChatRmtRepo, } from "./ftclass.repo.chat";
 
import { FtNotesRepo } from "./ftclass.repo.bas";
import { FtClassClient, FtClassClientRef } from "../ftclass.client";
import { FtClassInfoRepo } from "./ftclass.inf.repo";
import { RzChatRepo, RzChatRepoFac } from "../ui/ftclass/ftclass.ui.chat.repo";
import { RzoNotePath, RzoNoteShare, RzoPageInfo } from "../note.ui/rznote.ui.dto";
import { FtNoteRepoFac } from "./ftclass.repo";
import { FTCTX_CLIENT } from "../../config/consts";
import { FtClassRepoMngSendHdr } from "./ftclass.repo-mng.shdr";
import { FtCastEvent, FtCastEventEnum, FtCastRepo } from "../ui/ftclass/ftclass.ui.top";
import { FtLayoutOpts } from "../ui/ftclass/ftclass.ui.layout";
import { FtUiContext } from "../ui/ftclass2/ftui.context";
import { FtClassShareReqProc } from "./ftclass.share.proc";
import { FtDeskEvent } from "../ui/ftclass/ftdesk.ui.event";
import { FtxNote } from "../dto/ftclass2.dto";
import { cmToFtxNotes } from "./ftclass2.repo.default";
import { cmToPagesFromFtxNotes } from "../ui/wiz2/ftnotes.ui";
import { CONF_STORED_CLASS_ID, CONF_STORED_USERINFO, CONF_STORED_USERINFO_AT } from "../../config2";
import { FtClassWsClient } from "../ftclass.client.ws";

 
 
 /************** */
 
const rzIs=rzlog.makeDefs()
export const FtClassRepoMng_setDbg=rzIs.setDbg

/**************** */
class ftPenAttr {
    noteId?:string;
    penAttr?:RzPenAttr;
}

/********************** */
export enum FtClassRepoEventEnum {
    LAYOUT='layout',
    REPO_LOAD='repo.load',
    REPO_LOAD_DONE='repo.load.done',
 

    REPO_SAVE='repo.save',
    REPO_CLOSE='repo.close',

    SESSION_START='session.start',
    SESSION_END='session.end',

    SETTING_ETC='setting.etc',
}

export interface FtRepoLoadParam {
    mySession:FtoSession;
    mySessions:FtoSession[];
}

export interface FtRepoUnLoadParam {
    mySession:FtoSession;
}

export class FtClassRepoEvent{
    cmd?:FtClassRepoEventEnum;
    layout?:FtLayoutOpts;
    load?:FtRepoLoadParam;
    unload?:FtRepoUnLoadParam;
    constructor(pr:Partial<FtClassRepoEvent>){
        Object.assign(this,pr)
    }
}

export class FtClassJoinReq {
    classId?:string;
}


 /******************************************************* 
  *  FtClassReMngpoOpts
 */
 
 export class FtClassMngRepoEnum{
    static REMOTE='remote';
    static MIRROR='mirror';
}

export class FtClassRepoEnum{
    static BOOK='book'
    static NOTE='note'
}


export interface FtClassRepoMngOpts {
    ctx?:RzCtx;
    classId?:string;
    
    client?:FtClassClient;
    clientFacType?:string;
    clientFac?:FtClassClientFac;

   // loaderType?:string;
    loaderType?:string;
    chatType?:string;
    connInfo?:FtoConnInfo;
    isConnOn?:boolean;
    classRepoFac?:FtClassRepoFac
 }
 
 /***************** 
  * 
  */
 export  class FtClassRepoMng extends RzBasRepo implements FtCastRepo  {
    id?:number;
    initOpt?:FtClassRepoMngOpts;

    classReposFac?:FtClassRepoFac;
    notesRepo?:FtNotesRepo;
    classInfoRepo?:FtClassInfoRepo;
    deskRepos:{[key:string]:FtDeskRefRepo|null}={book:null, note:null};

    userInfo?:FtoUser;
    //client?:FtClassClient
    clientFac?:FtClassClientFac;
    connInfo?:FtoConnInfo;
    clientRef?:FtClassClientRef;
     
    classId?:string;
    sesId?:string;
    
    noteRepoFac?:FtNoteRepoFac =new FtNoteRefRepoFac();
    classEventSendHdr?:FtClassRepoMngSendHdr= new FtClassRepoMngSendHdr();
    classEventRecvHdr?:FtClassRepoMngRecvHdr= new FtClassRepoMngRecvHdr();

    isPageNotiOn?:boolean;
    chatRepo?:RzChatRepo;
    chatRepoFac?:RzChatRepoFac;
    ctx?:RzCtx;
    
    allNotes:FtoNoteInfo[]=[];

    constructor(opt?:FtClassRepoMngOpts){
         super()
         this.initOpt=opt? {...opt}:{};
    }

    /* init control  */
    async init(ctx:RzCtx,opt?:FtClassRepoMngOpts):Promise<RzRes<void>>{

        this.ctx=ctx ;
        if(rzIs.d)rzlog.debug('FtClassRepoMng.init [',this.id,'] : ctx=',ctx);
        if(rzIs.i)rzlog.info ('FtClassRepoMng.init [',this.id,'] : ctx=',ctx,', clsInfRepo=',this.classInfoRepo, ',notesRepo=',this.notesRepo);

        let classReposFac: FtClassRepoFac|null = opt?.classRepoFac || FtClassRepoFacDef.NewFac(opt);
        if(!classReposFac) return NewFail('no classReposFac');
        this.classReposFac=classReposFac;

        this.classId=opt?.classId;

        return NewOk();
     }

    //  curPenAttr?:RzPenAttr;
    //  setCurPen(type:string, penAttr?:RzPenAttr){
    //     if(penAttr!==undefined) this.curPenAttr={...penAttr,type:type};
    //     else this.curPenAttr={type:type};
    //  }
    //  getCurPen(){
    //     return this.curPenAttr;
    //  }

    setCurNoteId(noteId:string){
        if(this.classInfoRepo) this.classInfoRepo.setCurNoteId(noteId);
        //   this.classEventSendHdr!.setCurNoteId(noteId);
    }

     /************* */
     onDeskEvents:OnFn[]=[] ;
     notiDeskEvent(ev:FtClassEvent,srcOpt?:FtSrcOpt){
        if(srcOpt?.isSkip) return;

        ev.srcOpt=srcOpt;
        RzEventNotier.NotiEvent(this.onDeskEvents,ev);
     }
     addOnDeskEvent(fn:RzOnFn,pr?:any){
        RzEventNotier.AddOnEvent(this.onDeskEvents,fn,pr);
     }

     delOnDeskEvent(fn:RzOnFn){
        this.onDeskEvents=RzEventNotier.DelOnEvent(this.onDeskEvents,fn);
     }


     refreshRepo(){
        this.notesRepo?.refreshRepos();
     }

     
     async doInitRepos(opt?:FtClassRepoMngOpts){
        if(!this.classReposFac) return NewFail('no classReposFac');
        if(!this.ctx) return NewFail('no Ctx');
        if(!this.classId) return NewFail('no ClassId');
     

 


        let classId=this.classId;
        this.classInfoRepo=this.classReposFac.newClassInfoRepo(classId,opt);
        var r1=await this.classInfoRepo.init(this.ctx,opt);
            
        this.notesRepo=this.classReposFac.newNotesRepo(this.classInfoRepo, opt);
        var r2=await this.notesRepo!.init(this.ctx,opt);
     }

     clearRepos(){
        this.classInfoRepo=undefined;
        this.notesRepo=undefined;   
        this.classId=undefined;
        this.userInfo=undefined;
     }
 
     /**********/
    setClientFac(cliFac:FtClassClientFac){
        this.clientFac=cliFac;
    }
    
    setConnInfo(connInfo:FtoConnInfo){
        this.connInfo=connInfo;
    }

    async doInitClientRef(){
        let cliRef=new FtClassClientRef()
            await cliRef.init(this)

            this.ctx!.setAttr(FTCTX_CLIENT,cliRef)
            this.clientRef=cliRef;
            this.clientRef.clientFac=this.clientFac
            this.clientRef.connInfo=this.connInfo;

            cliRef.onClientClosed=()=>{
                this.doClientClosed();
            }
            
            cliRef.onClientConnected=async ()=>{
                //await this.doReloadClassInfo();
            }

            cliRef.onClientClassEvent=(evt)=>{
                this.doSubscrib(evt);
            }
            
            this.addOnClassEvent(this.procClassEventByUi,this)

            return cliRef;
    }

 

    isConnected(){
        return this.clientRef?.isConnected()|| false;
    }

 

    /********* */
    loginInfo?:FtoUser;
 
    
    async getAttendants(): Promise<FtoSession[]|undefined> {
        let r=await this.getClassInfo()
        if(IsFail(r)) return undefined;
        
        let dt=r.data!;
        let ses=dt?.attendants;

        return ses
    }

    async getBeginAt(): Promise<Date|undefined> {
        let r=await this.getClassInfo()
        if(IsFail(r)) return undefined;
        
        let dt=r.data!;
        let ses=dt?.beginAt;

        return ses
    }

    async getMySessions(): Promise<FtoSession[]|undefined> {
        let r=await this.getClassInfo()
        if(IsFail(r)) return undefined;
        
        let dt=r.data!;
        let ses=dt?.mySessions;

        return ses
    }

    async getMySession(): Promise<FtoSession|undefined> {
        let r=await this.getClassInfo()
        if(IsFail(r)) return undefined;
        
        let dt=r.data!;
        let ses=dt?.mySession;

        return ses
    }

    getLoginInfo():FtoUser|undefined{
        return this.loginInfo
    }
    setLoginInfo(userId:string, accToken:string, userType:string,devType:string,loginMode?:string){
        this.loginInfo=new FtoUser({username:userId, accToken:accToken, userType:userType, devType:devType,mode:loginMode})
        this.userInfo={...this.loginInfo};
    }


    setUserInfo(ui:FtoUser|undefined ){
        this.userInfo=ui;
    }
    getUserInfo():FtoUser|undefined{
        return this.userInfo;
    }

    getSesId(){
        return this.sesId
    }

    async doReloadClassInfo():Promise<RzRes<FtoUser>> {
        if(! this.clientRef?.client){
            await this.clientRef?.getClient();
        }

        let ui:FtoUser|null=null;
        let hasUi= this.doHasStoredUserInfo();
        rzlog.debug("UserInfo : hasUi=",hasUi);
        if(hasUi){
           let r=await  this.doLoadStoredUserInfoAndClassInfo();
           if(IsFail(r)) return NewFail(r.message);
           
           if(!r.data)return NewFail("storage has no userInfo");
            ui=r.data;

        } else {
            let rx1=await this.doJoinClassAndLoad();
            if(IsFail(rx1)) return NewFail(rx1.message)
            ui=rx1.data||null;

            rzlog.debug("UserInfo : save UserInfo=",ui);
           if(ui){
             this.doSaveUserInfo(ui);
           }
        }


        if(rzIs.i) rzlog.info("FtClassRepoMng.doReloadClassInfo : doGetClient > JOIN_CLASS >> userInfo=",this.userInfo)
        if(!ui) return NewFail("no userInfo");
        return NewOk(ui);
    }

    doClientClosed(){

    }

    procClassEventByUi(e:any, pr:any){
        //if(e.srcOpt?.isCastOn)return;

        pr.classEventSendHdr!.procClassEventByUi(pr,e)
    }

 
    async leaveClass():Promise<RzRes<boolean>>{
        if(! this.clientRef?.client) return NewFail('no client')

        let rq={classId:this.classId, sesId:this.sesId}
        let r=await this.clientRef?.client.fetchLeaveClass(rq);
        if(IsFail(r)) return NewFail('cant leave class')

        this.doClear();

        return NewOk(true)
    }

    doClear(){
        //if(this.client) this.client.close();

        if(this.clientRef)this.clientRef.close();
        
        this.sesId=undefined;
        this.userInfo=undefined;

        this.doClearStore();
    }

    doClearStore(){
       sessionStorage.removeItem(CONF_STORED_USERINFO);
       sessionStorage.removeItem(CONF_STORED_USERINFO_AT);
       sessionStorage.removeItem(CONF_STORED_CLASS_ID);
    }

    
    close(): void {
        this.doClear();
        let ev= new FtClassRepoEvent({cmd:FtClassRepoEventEnum.REPO_CLOSE});
        this.notiClassRepoEvent(ev);
    }
 

  
     async doJoinClassAndLoad():Promise<RzRes<FtoUser>>{
        if(!this.clientRef?.client) return NewFail('no client');

        if(rzIs.i) rzlog.info("FtClassRepoMng : doGetClient > client.connect >> OK !!!!!!!!!!!!! -classId=",this.classId,'login=',this.loginInfo);
        let joinReq={...this.loginInfo,classId:this.classId,};
        let tr1=await this.clientRef.client.fetchJoinClass(joinReq);
        rzlog.debug("FtClassRepoMng.JoinClass :tr1=",tr1);

        if(IsFailLog(tr1,"FtClassRepoMng : doGetClient > fetchJoinClass >> can't joinClass !!!!, classId=",this.classId,this.userInfo)){
            if(this.clientRef  ) this.clientRef.close();
            return NewFail("FtClassRepoMng : doGetClient > fetchJoinClass >> can't joinClass !!!!"+tr1.message);
        }
        if(!tr1.data.sesId){
            return NewFail("FtClassRepoMng : doGetClient > fetchJoinClass >> no sesId ");
        }
        
        await this.doInitUserInfo(tr1.data);

        return NewOk(this.userInfo);
     }

    async doInitUserInfo(data:any){
            if(!this.clientRef?.client) return NewFail('no client');

            let tkn=data.token;
        

            let sesId=data.sesId;
            this.sesId=sesId;
            this.clientRef.client.setSesId(sesId);
            this.clientRef.client.setClassId(this.classId!);            
            this.clientRef.client.setUsername(this.userInfo!.username!);
            this.clientRef.client.setToken(tkn);
    
            this.userInfo={...data} as FtoUser;
            this.userInfo.sesId=sesId;
            this.userInfo.uid=data.uid;
            
            rzlog.debug("MySesID=",this.sesId,', userInfo : r=',data);
    
    
            await this.doLoadClassInfo(true);
    
            await this.doLoadNoteRepos();

            let ev= new FtClassRepoEvent({cmd:FtClassRepoEventEnum.REPO_LOAD_DONE});
            this.notiClassRepoEvent(ev);

            
            return NewOk(this.userInfo);
        }


     async doLoadNoteRepos(){
            // let r1= await this.classInfoRepo!.load()
            // if(rzIs.i) rzlog.info('FtClassRepoMng.load : classInfoRepo.load > r1=',r1)
            // if(IsFail(r1)) return NewFail('FtClassRepoMng.load > '+r1.message)

            // const r1x = await this.classInfoRepo!.getClassInfo(this.classId!)
            // rzlog.debug('FtClassRepoMng.load : uinfo=',this.userInfo)
            // rzlog.debug('FtClassRepoMng.load : sett=',this.setting,',esett=',this.etcSetting)
        if(!this.notesRepo) return NewFail("no notesRepo");

            let nopt={}
        // if(this.notesStore) this.notesRepo!.setNotesStore(this.notesStore)
            let r2 = await this.notesRepo.load(nopt)
            if(rzIs.i) rzlog.info('FtClassRepoMng.load : notesRepo.load > r2=',r2);
            if(IsFail(r2)) return NewFail('FtClassRepoMng.load > '+r2.message);

            return NewOk()
     }  

     doHasStoredUserInfo(){
        let ui= sessionStorage.getItem(CONF_STORED_USERINFO);
        let sdate= sessionStorage.getItem(CONF_STORED_USERINFO_AT);
        if(ui) return true;
        return false;
     }

    async   doLoadStoredUserInfoAndClassInfo():Promise<RzRes<FtoUser>>{
        let ci=await sessionStorage.getItem(CONF_STORED_USERINFO);
        if(!ci) return NewFail('no storedUserInfo');

        let sdate=await sessionStorage.getItem(CONF_STORED_USERINFO_AT);
        if(!sdate) return NewFail('no storedUserInfoAt');

        let dt=new Date(sdate);
        let now=new Date();

        let ui=JSON.parse(ci) as FtoUser;
        this.userInfo=ui;
        if(rzIs.d)rzlog.debug('FtClassRepoMng.doLoadStoredUserInfoAndClassInfo( UserInfo : )  : ui=',ui);
        
        this.doInitUserInfo(ui);
        
        return NewOk(ui);
     }

 
       doSaveUserInfo(ui:FtoUser){
        let sdate=new Date().toISOString();
        sessionStorage.setItem(CONF_STORED_USERINFO,JSON.stringify(ui));
        sessionStorage.setItem(CONF_STORED_USERINFO_AT,sdate);

        rzlog.debug('doLoadStoredUserInfoAndClassInfo - UserInfo : this.classId=',this.classId);
        if(this.classId) sessionStorage.setItem(CONF_STORED_CLASS_ID,this.classId);
     }


// doSubscrib 이벤트 받는곳

     doSubscrib(evt:FtClassEvent){
        if(rzIs.d) rzlog.debug("FtClassRepoMng : doSubscrib > client.onClassEvent:my.sesId=",this.sesId,'=',evt.sesId,evt.sesId===this.sesId,",evt=",evt);

        if(evt.scmd!==FtClassEventEnum.CLASS_BEG &&evt.scmd!==FtClassEventEnum.CHAT_ADD &&evt.cmd!==FtClassEventEnum.SESSION_ADD){
            if(!evt.sesId) return;
            if(evt.sesId===this.sesId) return;
        } 
        
         this.procClassEventByClient(evt);
     }

     async doLoadClassInfo(pathOn?:boolean){
        await this.classInfoRepo!.load({pathOn:pathOn});

        let rx1=await this.classInfoRepo!.getNoteInfos();
        rzlog.debug("FtClassRepoMng.doLoadClassInfo : rx1=",rx1);
        if(IsFail(rx1)) return NewFail(' getNoteInfos > '+rx1.message);




        this.allNotes=rx1.data!
        if(this.deskRepos.book){
            let i=this.doGetSelRepoIdx(this.allNotes,'book');
            this.deskRepos.book.setCurRepoAt(i);
        }  
        if(this.deskRepos.note){  
            let i=this.doGetSelRepoIdx(this.allNotes,'book');
            this.deskRepos.note.setCurRepoAt(i);
        }

        
        this.doBindEditLock();
        this.notiOnEditLock({cmd:FtCastEventEnum.CHANGED});

        this.doUpdateSessions();
        rzlog.debug("FtClassRepoMng.doLoadClassInfo : allNotes=",this.allNotes,', onCasts=',this.onEditLocks);
     }

     async doUpdateSessions(){
        let rx0=await this.getClassInfo();
        rzlog.debug('FtClassRepoMng.loadClassInfo : clsInf=',rx0.data);
        let rx2=await this.classInfoRepo!.getMySessions();
        if(IsFail(rx2)) return;
        let myses=this.classInfoRepo!.getMySession();


        let sess=rx2.data!;

        let ev:FtClassRepoEvent={cmd:FtClassRepoEventEnum.SESSION_START,
                    load: {mySession:myses!, mySessions:sess}};

        this.notiClassRepoEvent(ev)
        
     }


     repoEvents:OnFn[]=[];
     notiClassRepoEvent(ev:FtClassRepoEvent){
        RzEventNotier.NotiEvent(this.repoEvents,ev)
     }
     addOnClassRepo(fn:RzOnFn,pr?:any){
        RzEventNotier.AddOnEvent(this.repoEvents,fn,pr)
     }
     delOnClassRepo(fn:RzOnFn){
        RzEventNotier.DelOnEvent(this.repoEvents,fn)
     }


     doBindEditLock(){
        rzlog.debug('FtClassRepoMng.doBindEditLock (doLoadClassInfo): ui=',this.userInfo)
        if(this.userInfo?.userType===FtoUserTypeEnum.TEACHER){
            this.editLock={userType:FtoUserTypeEnum.TEACHER,
                    isOwnerLock:false, isOtherLock:false}
        } else if(this.userInfo?.userType===FtoUserTypeEnum.STUDENT){
            this.editLock={userType:FtoUserTypeEnum.STUDENT,
                isOwnerLock:false, isOtherLock:false}
        } else if(this.userInfo?.userType===FtoUserTypeEnum.OBSERVER){
            this.editLock={userType:FtoUserTypeEnum.OBSERVER,
                isOwnerLock:false, isOtherLock:false}
        }
     }

     doGetSelRepoIdx(notes:FtoNoteInfo[],type:string){
        let r=-1
        let vs=notes.filter(t => t.group===type)
        vs.forEach((t,i)=> {if(t.selected) r=i;})
        return r
     }
 

     isConnOn?:Boolean;
     setConnOn(on:boolean){
        this.isConnOn=on;
     }

     async doInitClient(connInfo?:FtoConnInfo){
            let cliRef= await this.doInitClientRef();
            let storeOpt={ clientRef:cliRef};
            this.clientRef=cliRef
            rzlog.info("FtClassRepoMng : client ref ON !!!!!!!! > ref= ",cliRef);

            if(! this.clientRef?.client){
              let cli=  await this.clientRef?.getClient();
              if(!cli) return NewFail<void>("can't connet client");
            }
        
        if( !this.clientRef)      return NewFail<void>("no clientRef");
        return NewOk<void>();
     }


     async load(opts?:any) :Promise<RzRes<any>> {
        if(rzIs.i) rzlog.info('FtClassRepoMng.load : opt=',opts)
        let isConnOn =  opts?.isConnOn === undefined ? true : opts.isConnOn
        if(this.isConnOn!==undefined) isConnOn=Boolean(this.isConnOn);
        this.isConnOn=isConnOn
        
        
        if(isConnOn){
            let connInfo=this.connInfo||opts.connInfo;
            let rc=await this.doInitClient(connInfo);
            if(IsFail(rc)) return NewFail(rc.message) ;
        } 

        await this.doInitRepos(opts);

        let cli:FtClassClient|null = null
        let topts=this.initOpt||opts
        if(cli){topts.client=cli}
 
        let r= await this.doReloadClassInfo();
        if(IsFail(r)){
            return NewFail('FtClassRepoMng.load > '+r.message)   ;
        }
 

        if(isConnOn){
            let cliRef= this.ctx!.getAttr(FTCTX_CLIENT) as FtClassClientRef
            if(cliRef) this.chatRepo=this.doNewChatRepo(cliRef, this.classId!,this.getUserInfo()!.username!, this.sesId!)
            // if(cliRef) this.chatRepo=this.doNewChatRepo(cliRef, this.classId!,this.userInfo!.username!, this.sesId!)
        }

        //this.userInfo=r.data;
        

        let ev= new FtClassRepoEvent({cmd:FtClassRepoEventEnum.REPO_LOAD})
        this.notiClassRepoEvent(ev)        

         return NewOk();
     }



    doNewChatRepo(cliRef :FtClassClientRef, classId:string,userName:string,sesId:string):RzChatRepo{

        let repo:RzChatRepo|null = null;
        if(this.chatRepoFac){
            repo = this.chatRepoFac.newChatRepo();
        } else {
            let rrepo:RzChatRmtRepo =new RzChatRmtRepo();
                rrepo!.setClient(cliRef);
                rrepo.setClassUserInfo(classId,userName,sesId);
              
            repo=(rrepo as any) as RzChatRepo ;
        }

        return repo as RzChatRepo;
    }

 
     async save(opts?:any){
        let topts=this.initOpt||opts;
        let r= await this.classInfoRepo!.save(topts);
        if(r.status>0) return r;

        let nopt={...opts};
        let r1= await  this.notesRepo!.save(nopt);
        return r1;
     }



     /********* */
     setClassId(id:string){
         this.classId=id;
         if(this.classInfoRepo) this.classInfoRepo.setClassId(id);
         //this.notesRepo!.setClassId(id)
    }

    getClassId(){ return this.classId;}

     

    async getClassInfo(pathOn?:boolean):Promise<RzRes<FtoClassInfo>> {
        if(!this.classInfoRepo) return NewFail('no ClassInfoRepo');
        if(!this.classId) return NewFail('no classId');
        let r=await this.classInfoRepo.getClassInfo(this.classId,pathOn);
        
        return r;
    }

    // async fetchClassInfo(){
    //     if(this.store&& this.classInfoRepo){
    //         let r=await this.store.fetchClassInfo(this.classInfoRepo.classId!,this.classInfoRepo);
    //         return r 
    //     } 
    //     return NewFail('no store');
    // }

     setting?:FtoSetting
     getSetting():FtoSetting|undefined{
        if(!this.classInfoRepo) return undefined;

        let setting=this.classInfoRepo!.getSetting();
        rzlog.debug("FtClassRepoMng.getSetting: stt=",setting);
        return setting;
     }

     setSetting(stt:FtoSetting,srcOpt?:FtSrcOpt){
        if(!this.classInfoRepo) return  ;

        rzlog.debug("FtClassRepoMng.setSetting: stt=",stt,',srcOpt=',srcOpt);

        this.classInfoRepo!.setSetting(stt);
        const ne={cmd:FtClassEventEnum.SETTING, setting:stt};
        this.notiClassEvent(ne,srcOpt);
     }

     etcSetting?:FtoEtcSetting
     getEtcSetting():FtoEtcSetting|undefined{
        if(!this.classInfoRepo) return undefined;

        let setting=this.classInfoRepo!.getEtcSetting();
        rzlog.debug("FtClassRepoMng.getEtcSetting: etc=",setting);
        return setting;
     }

     setEtcSetting(etcStt:FtoEtcSetting,srcOpt?:any){        
        let orgSett=this.classInfoRepo!.getEtcSetting();
        this.classInfoRepo!.setEtcSetting(etcStt);
        
        let ui=this.getUserInfo();
        let ui2=this.userInfo;

        rzlog.debug('FtClassRepoMng.setEtcSetting: ui.type=',ui?.userType,',etcStt=',etcStt,',org=',orgSett,',ui=',ui, 'ui2=',ui2);
        const ne={cmd:FtClassEventEnum.SETTING_ETC, etcSetting:etcStt};
        this.notiClassEvent(ne,srcOpt);
     }


      
     getClassSharing():FtoClassSharing|undefined{
        if(!this.classInfoRepo) return undefined;

        let share=this.classInfoRepo!.getClassSharing();
        rzlog.debug("FtClassRepoMng.getClassSharing: classSharing=",share);
        return share;
     }

     setClassSharing(sharing:FtoClassSharing,srcOpt?:any){        
        let orgSett=this.classInfoRepo!.getClassSharing();
        this.classInfoRepo!.setClassSharing(sharing);
        let ui=this.getUserInfo();
        let ui2=this.userInfo;
        
        // if (srcOpt?.isAnswer) {
        //     // 답안지공유 this.doBindRepoSharing(sharing); 처리안하도록 수정
        //     const ne={cmd:FtClassEventEnum.SHARING_PUT, classSharing:sharing};
        //     this.notiClassEvent(ne,srcOpt);
        // } else {
        //     this.doBindRepoSharing(sharing);

        //     rzlog.debug('FtClassRepoMng.setClassSharing: ui.type=',ui?.userType,',clzSharing=',sharing,',org=',orgSett,',ui=',ui, 'ui2=',ui2);
            
        //     const ne={cmd:FtClassEventEnum.SHARING_PUT, classSharing:sharing};
        //     this.notiClassEvent(ne,srcOpt);
        // }        
        this.doBindRepoSharing(sharing);

        rzlog.debug('FtClassRepoMng.setClassSharing: ui.type=',ui?.userType,',clzSharing=',sharing,',org=',orgSett,',ui=',ui, 'ui2=',ui2);
        
        
        const ne={cmd:FtClassEventEnum.SHARING_PUT, classSharing:sharing};
        this.notiClassEvent(ne,srcOpt);
     }

     doBindRepoSharing(sharing:FtoClassSharing){
        if(!sharing.noteSharings) return;
            
        sharing.noteSharings.forEach(async (t)=>{
                let noteId=t.noteId;
                if(noteId){
                    let  r= await this.getNoteRepo(noteId);
                    if(!IsFail(r)){
                        if((t as any).__typename ) delete (t as any).__typename;
                        if((t as any).isMyGroupWritingReadOn!=undefined ) delete (t as any).isMyGroupWritingReadOn;
                        if((t as any).isMyWritingAllOn !=undefined) delete (t as any).isMyWritingAllOn;
                        if((t as any).isMyWritingReadOn !=undefined) delete (t as any).isMyWritingReadOn;
                        
                        r.data?.setSharing(t);
                    }
                }
        });
     }

    noteMySharings:RzoNoteShare[]=[];
    async getNoteShare(noteId:string):Promise<RzRes<RzoNoteShare>>{
        let r=await this.getClassInfo();
        if(IsFail(r)) return NewFail(r.message);
        if(!r.data) return NewFail('repoMng.getNoteShare > no classInfo');
        let ci=r.data ;
        
        let vs=ci.classSharing?.noteSharings?.filter(t=>t.noteId===noteId);

        if(!vs || vs.length==0) return NewFail("no noteSharing:noteId="+noteId);
        
        let sh=vs[0];
        this.doBindMyShare(sh);

        return NewOk(sh);    
    }

    doBindMyShare(sh:RzoNoteShare){        
        let noteId = sh.noteId;                
        if (noteId) {                        
            if (this.myShares[noteId] === undefined) {                
                this.myShares[noteId]={isMyWritingAllOn:true,isMyWritingReadOn:true,isMyGroupWritingReadOn:true, isGroupWritingReadOn:false}
            } 

            sh.isMyWritingAllOn=this.myShares[noteId].isMyWritingAllOn;
            sh.isMyWritingReadOn=this.myShares[noteId].isMyWritingReadOn;
            sh.isMyGroupWritingReadOn=this.myShares[noteId].isMyGroupWritingReadOn;
            sh.isGroupWritingReadOn=this.myShares[noteId].isGroupWritingReadOn;
        }        
        // let vs=this.noteMySharings.filter(t=>t.noteId===sh.noteId);
        // if(vs.length!==0) return;
        // sh.isMyWritingAllOn=false;
        // sh.isMyWritingReadOn=true;
        // sh.isMyGroupWritingReadOn=true;
        // this.noteMySharings.push(sh);
    }


    async putNoteShare(sh:RzoNoteShare , srcOpt?:RzSrcOpt):Promise<RzRes<RzoNoteShare>>{
      //  let repo=this.getNoteRepo()
        return NewOk({});
    }

    async findNoteMemo(noteId:string):Promise<RzRsRes<RzoNotePath>>{
        let dt:RzoNotePath[]=[];
        return NewOkRs(dt,0);
    }


    // notePenAttrs:ftPenAttr[]=[];
    // setNotePenAttr(noteId:string, attr?:RzPenAttr, isNotiEvent?:boolean){

        
    //     let vs=this.notePenAttrs.filter(t=>t.noteId===noteId);
    //     if(vs.length===0){
    //         let nattr:ftPenAttr= {noteId:noteId, penAttr:{...attr,noteId:noteId} };
    //         this.notePenAttrs.push(nattr);
    //         attr=nattr;
    //     } else {
    //         if(attr) vs[0].penAttr={... attr, noteId:noteId};
    //         else this.notePenAttrs.splice(0,1);
    //     }
        

    //     if(isNotiEvent){
    //         let ev={cmd:FtClassEventEnum.PEN_PUT, noteId:noteId,penAttr:attr};
    //         this.notiClassEvent(ev);
    //     }
        
    // }

    // getNotePenAttr(noteId:string):RzPenAttr|undefined{
    //     let vs=this.notePenAttrs.filter(t=>t.noteId===noteId);
    //     if(vs.length===0) return undefined;

    //     return vs[0].penAttr;
    // }
  
     
    /************ */
     editLockOther?:FtoEditLock;
     editLock?:FtoEditLock;
 
     // 2023.11.17
    //  reqShare(el: FtoShareReq,srcOpt?:any) {
    //     this.share = el;

    //     let ui=this.getUserInfo();
    //     rzlog.debug("RepoMng.reqShare() : el=",el,',ui=',ui);
    //     const ne={cmd:FtClassEventEnum.SHARE_REQ, reqShare:el};
    //     this.notiClassEvent(ne,srcOpt);
    //  }

     async refreshEdit(noteId: string, ctx: FtUiContext,  mode?: string, onoff?:boolean) {
        let noterepo = await this.getNoteRepo(noteId);

        let note = noterepo.data;
        //note?.myNoteOn();

        const ne={cmd:FtClassEventEnum.REFRESH};
        this.notiClassEvent(ne);

        //rr.data?.doLoad();
     }

     setEditLock(el:FtoEditLock,srcOpt?:FtSrcOpt){
        this.editLock=el;
       
        let ui=this.getUserInfo();
        rzlog.debug("RepoMng.EditLock(doLoadClassInfo) : el=",el,',ui=',ui);
        //@ FtoEditLock.procPausedEvent(this,el,ui!);

        this.notiOnEditLock({ cmd:FtCastEventEnum.LOCK, editLock:el});

        const ne={cmd:FtClassEventEnum.EDIT_LOCK, editLock:el};
        this.notiClassEvent(ne,srcOpt);
     }

     async notiMyPagesOnCast(notes:FtxNote[]){
        let pages=cmToPagesFromFtxNotes(notes,true);
        //let tnotes=cmToFtxNotes(notes);
        this.putNoteInfoPages(pages,{isCastOn:true});
    }  
     
     getEditLock():FtoEditLock{
     
        return this.editLock!;
     }

     getEditLockOther():FtoEditLock {
        
        return this.editLockOther!;
     }

     onEditLocks=[]
     addOnEditLock(fn:(ev:FtCastEvent)=>void){
        RzEventNotier.AddOnEvent(this.onEditLocks,fn);
     }

     notiOnEditLock(ev:FtCastEvent){
        RzEventNotier.NotiEvent(this.onEditLocks,ev);
     }
     
     delOnEditLock(fn:(ev:FtCastEvent)=>void){
        RzEventNotier.DelOnEvent(this.onEditLocks,fn);
     }
     
     
     /************** */
  
     async getNoteInfos(type?:string,stat?:string):Promise<RzRes<FtoNoteInfo[]>>{
            if(!this.classInfoRepo) return NewFail('no classInfoRepo');


            let r= await this.classInfoRepo!.getNoteInfos();
            if(IsFail(r)) return NewFail("FtClassRepoMng.getNoteInfos > "+r.message);

            let noteInfos=r.data!;
            let nifs=noteInfos;
            if(rzIs.d) rzlog.debug('FtClassRepoMng.getNoteInfos : type=',type,', noteInfos=',noteInfos);
            if (type==undefined) type='book'
            if(type==='book') nifs=noteInfos.filter((t:FtoNoteInfo)=> t.group===type ||t.group==undefined );
            else if(type==='note') nifs=noteInfos.filter((t:FtoNoteInfo)=> t.group===type );


            if(rzIs.d) rzlog.debug('FtClassRepoMng.getNoteInfos : type=',type,', filter.noteInfos=',noteInfos);

            return NewOk(nifs);
     }
  
 


     async putNoteInfos(infos:FtoNoteInfo[]):Promise<RzRes<FtoNoteInfo[]>>{
            
            let r= await this.classInfoRepo!.putNoteInfos(infos);
            if(IsFail(r)) NewFail("FtClassRepoMng.getNoteInfos > "+r.message)

            let noteInfos=r.data!;
            // if(rzIs.d) rzlog.debug('FtClassRepoMng.getNoteInfos : type=',type,', noteInfos=',noteInfos)
            // if(type==='book') noteInfos=noteInfos.filter((t:FtoNoteInfo)=> t.group===type )
            // else if(type==='note') noteInfos=noteInfos.filter((t:FtoNoteInfo)=> t.group===type )
            // if(rzIs.d) rzlog.debug('FtClassRepoMng.getNoteInfos : type=',type,', filter.noteInfos=',noteInfos)

            return NewOk(noteInfos);
     }

     async sendClassNoti(msg:FtoClassNoti){
        const ne={cmd:FtClassEventEnum.NOTI_SEND, scmd:FtClassEventEnum.NOTI_SEND, noti:msg};
        this.notiClassEvent(ne);
        return NewOk(msg);
     }
 
     async getNoteInfo(noteId:string){
            let r=await this.classInfoRepo!.getNoteInfo(noteId);
            return r;
     }

     async doAddNoteInfoByCli(rscInf:FtoNoteInfo):Promise<RzRes<FtoNoteInfo>>{
            let rcli=await this.clientRef?.getClient();//doGetClient();
            let cli=rcli!;
            if(!cli) return NewFail('doAddNoteInfoByCli : no client');
            
            let ui=this.userInfo;
            let nrscInf:FtoNoteInfo={...rscInf, ownerName:ui?.username };
            let rr1=await cli.addNoteInfo(this.classId!,nrscInf);
            if(rzIs.d) rzlog.debug(`FtClassRepoMng.doAddNoteInfoByCli :clsId=${this.classId},inf=`,nrscInf,',r=',rr1);

            let rrscInf=rr1.data! ;
            let rrscInf0:FtoNoteInfo=new FtoNoteInfo({...rrscInf});
            let rr2=await this.notesRepo?.addNote(rrscInf0.noteId!,rrscInf0);
            if(rzIs.d) rzlog.debug(`FtClassRepoMng.addNoteInfo.doAddNoteInfoByCli :clsId=${this.classId},ret=`,rrscInf,',r=',rr2);

            return NewOk(rrscInf0);
     }


     async addNoteInfo(rscInf:FtoNoteInfo,srcOpt?:FtSrcOpt):Promise<RzRes<FtoNoteInfo>>{

        if(!this.classInfoRepo) return NewFail('no classInfoRepo');

            let rrscInf0=rscInf;
            if(srcOpt ===undefined){
                let rx0=await this.doAddNoteInfoByCli(rscInf);
                rrscInf0=rx0.data!;
                rzlog.debug('FtClassRepoMng.addNoteInfo : new RmtNote =',rrscInf0,',org=',rscInf);
            }

            let r=await this.classInfoRepo!.addNoteInfo(rrscInf0,srcOpt);
            if(IsFail(r)) return NewFail('addNoteInfo : '+r.message);

            
            let ninf0=r.data!;
            rzlog.debug('RepoMng.addNoteInfo : info=',ninf0);

            let repos=this.getNoteRepos();
            let r0=await repos!.getNoteRepo(ninf0.noteId!)
            if(IsFail(r0)) return NewFail(r0.message);


            
            let r1=await repos!.loadNote(ninf0.noteId!);
            rzlog.info("FtCalssRepoMng.addNoteInfo : loadNote r=",r1);
            if(IsFail(r1)) return NewFail(r1.message);
            if(rzIs.d) rzlog.debug('FtCalssRepoMng.addNoteInfo.loadNote : info=',rscInf,', r=',r1);


            let ev=new FtClassEvent({cmd:FtClassEventEnum.PAGES_ADD,pages:[ninf0]});
            this.notiClassEvent(ev,srcOpt);

            let isNewPage=true;
            if(isNewPage){
                rzlog.debug("FtCalssRepoMng.addNoteInfo > addPage:1,srcOpt=",srcOpt);
                let r3=await this.addPage(ninf0.noteId!,{pageNo:1},srcOpt);
                if(IsFail(r3)) return r3;
            }

            return NewOk(ninf0);
     }

     async putNoteInfo(noteId:string,rscInf:FtoNoteInfo,srcOpt?:FtSrcOpt):Promise<RzRes<FtoNoteInfo>>{
            if(!this.classInfoRepo) return NewFail('no classInfoRepo');

            rzlog.debug("FtClassRepoMng.putNoteInfo(unvis) : noteId=",noteId,',inf=',rscInf )
            let r=await this.classInfoRepo!.putNoteInfo(noteId,rscInf,{...srcOpt,isSkip:true})
            if(IsFail(r)) return NewFail(r.message)

            let ninf:FtoNoteInfo=r.data!
            let rr= await this.doGetNoteRepo(noteId)
            if(IsFail(rr)) return NewFail(rr.message)

            if(ninf.pageNo && rr.data) rr.data.setCurPageNo(ninf.pageNo,{...srcOpt,isSkip:true})
            
            
            let tinf=ninf;
            let ev=new FtClassEvent({cmd:FtClassEventEnum.PAGES_PUT, noteId:noteId, pages:[tinf]})
            this.notiClassEvent(ev,srcOpt)
            return NewOk(ninf)
     }


     async requestRepaint(srcOpt?:FtSrcOpt){
        this.notiRepaint()
     }

     onRepaint?:()=>void;
     notiRepaint(){
        if(this.onRepaint) this.onRepaint()
     }

    setOnRepaint(fn:()=>void){
        this.onRepaint=fn;
    }

     async putNoteInfoPages(rscInfs:FtoNoteInfo[],srcOpt?:FtSrcOpt):Promise<RzRes<FtoNoteInfo[]>>{
        if(!this.classInfoRepo) return NewFail('no classInfoRepo');
        
        let noteId:string|null=null;

        for(let i in rscInfs){
            let rscInf=rscInfs[i];
            noteId=rscInf.noteId||null;
            if(noteId===null) continue;
            
            rzlog.debug("FtClassRepoMng.putNoteInfo(unvis) : noteId=",noteId,',inf=',rscInfs )
            let r=await this.classInfoRepo!.putNoteInfo(noteId,rscInf,{...srcOpt,isSkip:true})
            if(IsFail(r)) return NewFail(r.message)
    
            let ninf:FtoNoteInfo=r.data!
            let rr= await this.doGetNoteRepo(noteId)
            if(IsFail(rr)) return NewFail(rr.message)
    
            if(ninf.pageNo && rr.data) rr.data.setCurPageNo(ninf.pageNo,{...srcOpt,isSkip:true})
        }
        
 
        let ev=new FtClassEvent({cmd:FtClassEventEnum.PAGES_PUT,  
                noteId:noteId||undefined, 
                pages:rscInfs});

        this.notiClassEvent(ev,srcOpt)
        return NewOk(rscInfs)
 }


     async putNoteInfoOn(prevInf:FtoNoteInfo,newInf:FtoNoteInfo,srcOpt?:FtSrcOpt){
            if(!this.classInfoRepo) return NewFail('no classInfoRepo');

            rzlog.debug("FtCalssRepoMng.putNoteInfoOn:",prevInf,newInf,srcOpt);
            let r=await this.classInfoRepo!.putNoteInfo(prevInf.noteId!,prevInf);
            if(r.status>0) return r;

            let r2=await this.classInfoRepo!.putNoteInfo(newInf.noteId!,newInf);
            if(r2.status>0) return r2;
        
            let ev=new FtClassEvent({cmd:FtClassEventEnum.PAGES_ON,sesId:this.sesId, pages:[prevInf,newInf]});
            this.notiClassEvent(ev,srcOpt);
            return NewOk();
     }

     async changeDeskRepoOn(info:FtoNoteInfo,oinfo:FtoNoteInfo,srcOpt?:FtSrcOpt){
       
        if(!info){
            return  ;
        }
        if(!this.classInfoRepo) return NewFail('no classInfoRepo');
        let r0 = await this.classInfoRepo!.getNoteInfo(info.noteId!);
        if(r0.status > 0) return r0;
        let info1 =r0.data! as FtoNoteInfo;

        let r1=await this.getNoteRepo(info.noteId!);
        if(rzIs.d) rzlog.debug('FtClassRepoMng.changeDeskRepo : getNoteRepo r=',r1);

        if(r1.status > 0) return r1;
        if(rzIs.d) rzlog.debug('FtClassRepoMng.changeDeskRepo : PAGES_ON > noteId=',info.noteId,',repo=',r1.data,',ntInfo=',info);

        let deskType='note';
        if(r1.data!.getGroup()==='book' ) deskType='book';
        
        let dkRepo=this.getDeskRepo(deskType);
        let rix=await dkRepo.indexOfRepo(info.noteId!);
        let ix=rix.data!;

        await dkRepo.putCurRepoAt(ix,srcOpt);
        rzlog.debug("FtClassRepoMng.changeDeskRepoOn : Done =============================");
     }


     async delNoteInfo(noteId:string,rscInf:FtoNoteInfo,srcOpt?:FtSrcOpt):Promise<RzRes<FtoNoteInfo>> {
        if(!this.classInfoRepo) return NewFail('no classInfoRepo');

        let r=await this.classInfoRepo!.delNoteInfo(noteId,rscInf);
        if(IsFail(r)) return r;

        let ninf=r.data;
        let pageInfo={ noteId:ninf?.noteId} as FtoPageInfo;

        let ev=new FtClassEvent({cmd:FtClassEventEnum.PAGES_DEL,pages:[pageInfo]});
        this.notiClassEvent(ev,srcOpt);

        return NewOk(ninf);
     }

   
     doMakeDeskRepo(type:string){
        let repo=new FtDeskRefRepo()
        repo.setRepoMng(this)
        repo.setType(type)
        

        let ix=0
        let notes=this.allNotes.filter(t=> t.group===type)
        if(notes){
            rzlog.debug("FtClassRepoMng.doMakeDeskRepo : type=",type,
                " notes=",notes,",allNotes=",this.allNotes)

            notes.forEach((t,i)=>{ if(t.selected) ix=i;})
        }

        repo.setCurRepoAt(ix)
        
        return repo
     }
     /** DeskRepo */
     getDeskRepo(type:string){
         let repo= this.deskRepos[type]
         if(!repo){
            repo= this.doMakeDeskRepo(type)
            this.deskRepos[type]=repo
        }

         return repo
     }
     
     /************* 
      * Repo Control 
     */
    noteRefRepoFac = new FtNoteRefRepoFac(); 
    doMakeRefNote(repo:RzNoteRepo){
        //let r= new  FtNoteRefRepo(this)

        let r=this.noteRefRepoFac.newRepo(this);

        //r.refRepo=repo;
        r.setRefRepo(repo)
        r.curPage=repo.curPage;
        r.pageCount=repo.getPageCount()

        const uname=repo.getOwnerUsername()
        const uid=repo.getOwnerId()
        const group=repo.getGroup();

        if(uname) r.setOwnerUsername(uname)
        if(uid){
            r.setOwnerId(uid)
            //r.setDrawerUid(uid);
        }
        let drawerId=this.userInfo?.uid;
        if(drawerId){
            let u={uid:drawerId,username:this.userInfo?.username}
            r.setDrawer(u);  
        } 
        
        if(group) r.setGroup(group);


        rzlog.debug('FtClassRepoMng.doMakeRefNote: uname=',uname,',uid=',uid)

        return r
    }
    

    async saveNote(noteId:string,opt?:any){
        let r=await this.doGetNoteRepo(noteId)
        if(IsFail(r)) return r
        return r.data!.save(opt)
        
    }

    async loadNote(noteId:string,opt?:any){
        let r=await this.doGetNoteRepo(noteId)
        if(IsFail(r)) return r
        if(!r.data) return NewFail('no repo');

        let repo=r.data;
        if(rzIs.d) rzlog.debug('FtClassRepoMng.loadNote : repo=',repo)

        let r2=await repo.load(opt); 
        let realCnt=repo.pages?.length||0;
        let rpgCnt=await this.classInfoRepo!.getNoteInfo(noteId);
        let pgCnt=0;
        if(!IsFail(rpgCnt)) {
            pgCnt=rpgCnt.data?.total||0;
        }

        if(realCnt<pgCnt){
            let cli=  await this.clientRef?.getClient();
            if(cli){
                // let wscli=  cli as FtClassWsClient;
                // let rr=await wscli.fetchNotePages(noteId);
                // if(!IsFail(rr)){
                //     let pages=rr.data;
                //     if(pages) repo.pages=pages;
                // }
            } 
        }
        
        return r2;
    }

 

    async getCurNoteInfo(type?:string):Promise<RzRes<FtoNoteInfo>>{

        let r=await this.getNoteInfos(type)
        if(IsFail(r)) return NewFail(r.message)

        let nis=r.data!
        let vs=nis.filter(t => t.selected)
        
        if(vs.length==0) {
            if(nis.length>0) {
                let dt=nis[0];
                dt.selected=true
                return NewOk(dt);
            }
            return  NewFail('no data')
        }
        let ni=vs[0]
        return NewOk(ni)
    }

    async getCurNoteRepo(type?:string):Promise<RzRes<RzNoteRepo>>{
        let r=await this.getCurNoteInfo(type);//book,'note'
        if(IsFail(r)) return NewFail('cant getNoteInfo :'+r.message);


        if(r.data?.noteId==undefined) return NewFail('no noteId');

        let r2=await this.getNoteRepo(r.data.noteId);
        return r2;
    }

     mutNoteRepo(noteId:string,refRepo:RzNoteRepo):void{
        if(refRepo.refRepo) refRepo.refRepo.setRefRepo(refRepo);

        this.notesRepo!.mutNoteRepo(noteId,refRepo.refRepo);
        this.cachedNoteRepos[noteId]=refRepo;
     }


    cachedNoteRepos:{[key:string]:RzNoteRepo}={}
    async getNoteRepo(noteId:string):Promise<RzRes<RzNoteRepo>>{
        if(noteId in  this.cachedNoteRepos ){
            let rp=this.cachedNoteRepos[noteId]
            //if(rzIs.d)
            rzlog.debug('FtClassRepoMng.doGetNoteRepo : notesRepo.noteId=',noteId,',v=',rp)
            return NewOk(rp)
        }

        let r=await this.doGetNoteRepo(noteId)
        rzlog.debug("FtClassRepoMng : doGetNoteRepo :r=",r)
        if(IsFail(r)) return r
        let repo=r.data!
        if(!repo) {
            rzlog.debug('FtClassRepoMng.doGetNoteRepo : no NoteRepo:',noteId)
            return NewFail('no NoteRepo:'+noteId);
        }

        let tr:FtNoteRefRepo|null=null
       
        if(rzIs.d) rzlog.debug('FtClassRepoMng.doGetNoteRepo : notesRepo.noteId=',noteId,',r=',r)
        let grp= repo.getGroup()!
        if(repo.getType()==='book') {
            
            tr=this.doMakeRefNote(repo)
            tr.setNoteId(noteId)
            tr.setType('book')
            tr.setGroup(grp)
            
            
        } else if(repo.getType()==='note') {
            tr=this.doMakeRefNote(repo)
            tr.setNoteId(noteId)
            tr.setType('note')
            tr.setGroup(grp)

        }

           
       let ttr:RzNoteRepo= tr as RzNoteRepo
       if(tr) {
           repo!.setRefRepo(tr);
           this.cachedNoteRepos[noteId]=ttr;
       }


       //@if(!tr.isLoaded()) tr.load()
       rzlog.debug("FtClassRepoMng : doGetNoteRepo :ttr=",ttr);
        return NewOk(ttr);
     }

     
     getChatRepo(){
         return this.chatRepo
     }
   
     /************ */
 
     async doGetNoteRepo(noteId:string){
        let r=await this.notesRepo!.getNoteRepo(noteId)
         return r
     }

     setEventPaused(b:boolean ){
         this.classEventRecvHdr!.setEventPaused(this,b)
         this.classEventSendHdr!.setEventPaused(this,b)
     }
     /*** */
      getNoteRepos(){
          return this.notesRepo
      }
 
     /**  data object */
     setSessions(sess:FtoSession[],srcOpt?:FtSrcOpt){
         this.classInfoRepo!.setSessions(sess,srcOpt)
     }
 
     async addSession(ses:FtoSession,srcOpt?:FtSrcOpt){
        let r= await this.classInfoRepo!.addSession(ses,srcOpt);
        if(IsFail(r)) return r;

        let ev=new FtClassEvent({cmd:FtClassEventEnum.SESSION_ADD,session:ses});
        this.notiClassEvent(ev,srcOpt);
        return r;
     }

     async putSession(ses:FtoSession,srcOpt?:FtSrcOpt){
        let r=  await this.classInfoRepo!.putSession(ses,srcOpt);
        if(IsFail(r)) return r;

        let ev=new FtClassEvent({cmd:FtClassEventEnum.SESSION_PUT,session:ses});
        this.notiClassEvent(ev,srcOpt);
        return r;
     }
     async delSession(ses:FtoSession,srcOpt?:FtSrcOpt){
        let r= await this.classInfoRepo!.delSession(ses,srcOpt);
        if(IsFail(r)) return r;

        let ev=new FtClassEvent({cmd:FtClassEventEnum.SESSION_DEL,session:ses});
        this.notiClassEvent(ev,srcOpt);
        return r;
     }

     /****************
      * 
      */
  
     shareReqProc=new FtClassShareReqProc()
     async procSharePutFromRemote(sharing:FtoClassSharing, srcOpt?:FtSrcOpt){
        this.shareReqProc.procSharePutFromRemote(this,sharing,srcOpt);
     }
 

     async notiClassNotiRecv(noti:FtoClassNoti, srcOpt?:FtSrcOpt){

        //alert('notiClassNotiRecv : noti.title='+noti.title);
        //rzDlgShow('공유요청',noti.title||'',(b)=>{});
        if(noti.type== FtoClassNotiEnum.OVBSERVER_JOIN_REQ){            
            if (this.userInfo?.userType !== 'teacher') return;
            rzDlgShow(noti.title||'',noti.body||'',(b)=>{                
                if (b) {
                    let ev=new FtClassEvent({cmd:FtClassEventEnum.NOTI_SEND, noti:noti});
                    this.notiClassEvent(ev,srcOpt);     
                } else {
                    let ev=new FtClassEvent({cmd:FtClassEventEnum.NOTI_SEND, noti:{...noti, type:FtoClassNotiEnum.OVBSERVER_JOIN_CANCEL_REQ}});
                    this.notiClassEvent(ev,srcOpt);    
                }
            });            
        }

        if(noti.type== FtoClassNotiEnum.OVBSERVER_JOIN_CANCEL_RES){            
            if (this.userInfo?.userType !== 'observer') return;

            rzDlgShow(noti.title||'',noti.body||'',(b)=>{
                if (b) {
                    // let ev=new FtClassEvent({cmd:FtClassEventEnum.NOTI_SEND, noti:noti});
                    // this.notiClassEvent(ev,srcOpt);                
                }
            }, {isCancel:false});
        }

        if(noti.type== FtoClassNotiEnum.OVBSERVER_JOIN_RES){
            let ev=new FtClassEvent({cmd:FtClassEventEnum.NOTI_SEND, noti:noti});
            this.notiClassEvent(ev,srcOpt);     
        }

        if(noti.type== FtoClassNotiEnum.PARENT_JOIN_OUT_RES){
            let ev=new FtClassEvent({cmd:FtClassEventEnum.NOTI_SEND, noti:noti});
            this.notiClassEvent(ev,srcOpt);  
        }

        if(noti.type== FtoClassNotiEnum.WRITING_SHARE_REQ){            
            if (this.userInfo?.userType === 'observer') return;
            let ev=new FtClassEvent({cmd:FtClassEventEnum.NOTI_SEND, noti:noti});
            this.notiClassEvent(ev,srcOpt);                     
        }

        if(noti.type== FtoClassNotiEnum.WRITING_SHARE_RES){
            let ev=new FtClassEvent({cmd:FtClassEventEnum.NOTI_SEND, noti:noti});
            this.notiClassEvent(ev,srcOpt);     
        }

        if (noti.type == FtoClassNotiEnum.ANSWER_ALLOWED) {
            let ev = new FtClassEvent({ cmd: FtClassEventEnum.NOTI_SEND, noti: noti });
            this.notiClassEvent(ev, srcOpt);
        }

        if(noti.type== FtoClassNotiEnum.ANSWER_SHARE_REQ){            
            if (this.userInfo?.userType !== 'teacher') return;
            let ev=new FtClassEvent({cmd:FtClassEventEnum.NOTI_SEND, noti:noti});
            this.notiClassEvent(ev,srcOpt);
        }

        if(noti.type== FtoClassNotiEnum.ANSWER_SHARE_RES){
            let ev=new FtClassEvent({cmd:FtClassEventEnum.NOTI_SEND, noti:noti});
            this.notiClassEvent(ev,srcOpt);     
        }

        if(noti.type== FtoClassNotiEnum.NOTE_SHARE_REQ){
            if (this.userInfo?.userType === 'observer') return;
            let ev=new FtClassEvent({cmd:FtClassEventEnum.NOTI_SEND, noti:noti});
            this.notiClassEvent(ev,srcOpt);
        }

        if(noti.type== FtoClassNotiEnum.NOTE_SHARE_CANCEL_REQ){
            if (this.userInfo?.userType === 'observer') return;
            let ev=new FtClassEvent({cmd:FtClassEventEnum.NOTI_SEND, noti:noti});
            this.notiClassEvent(ev,srcOpt);
        }

        if(noti.type== FtoClassNotiEnum.NOTE_SHARE_RES){
            let ev=new FtClassEvent({cmd:FtClassEventEnum.NOTI_SEND, noti:noti});
            this.notiClassEvent(ev,srcOpt);     
        }

        if(noti.type== FtoClassNotiEnum.NOTE_SHARE_CANCEL_RES){
            let ev=new FtClassEvent({cmd:FtClassEventEnum.NOTI_SEND, noti:noti});
            this.notiClassEvent(ev,srcOpt);     
        }

        // let ev=new FtClassEvent({cmd:FtClassEventEnum.CLASS_NOTI, classNoti:noti});
        //this.notiClassEvent(ev);

        if(noti.type== FtoClassNotiEnum.MIRROR_MINE_REQ){
            let ev=new FtClassEvent({cmd:FtClassEventEnum.NOTI_SEND, noti:noti});
            this.notiClassEvent(ev,srcOpt);            
        }

        if(noti.type== FtoClassNotiEnum.MIRROR_MINE_RES){
            let ev=new FtClassEvent({cmd:FtClassEventEnum.NOTI_SEND, noti:noti});
            this.notiClassEvent(ev,srcOpt);     
        }

        if(noti.type== FtoClassNotiEnum.MIRROR_OTHER_REQ){
            rzDlgShow(noti.title||'',noti.body||'',(b)=>{                
                if (b) {
                    let ev=new FtClassEvent({cmd:FtClassEventEnum.NOTI_SEND, noti:noti});
                    this.notiClassEvent(ev,srcOpt);     
                } else {

                }
            });            
        }

        if(noti.type== FtoClassNotiEnum.MIRROR_OTHER_RES){
            let ev=new FtClassEvent({cmd:FtClassEventEnum.NOTI_SEND, noti:noti});
            this.notiClassEvent(ev,srcOpt);     
        }

        if (noti.type === FtoClassNotiEnum.NOTE_WRITE_STOP_REQ) {
            let ev=new FtClassEvent({cmd:FtClassEventEnum.NOTI_SEND, noti:noti});
            this.notiClassEvent(ev,srcOpt);     
        }

        if (noti.type === FtoClassNotiEnum.NOTE_WRITE_ALLOW_REQ) {
            let ev=new FtClassEvent({cmd:FtClassEventEnum.NOTI_SEND, noti:noti});
            this.notiClassEvent(ev,srcOpt);
        }

        if (noti.type === FtoClassNotiEnum.WRITING_TEACHER_SHOW_REQ) {
            let ev=new FtClassEvent({cmd:FtClassEventEnum.NOTI_SEND, noti:noti});
            this.notiClassEvent(ev,srcOpt);     
        }

        if (noti.type === FtoClassNotiEnum.WRITING_TEACHER_HIDE_REQ) {
            let ev=new FtClassEvent({cmd:FtClassEventEnum.NOTI_SEND, noti:noti});
            this.notiClassEvent(ev,srcOpt);     
        }

     }
     /***************
      * 
      */
    hasNote(noteId:string)   {
        return this.notesRepo?.hasNote(noteId);
    }

    async getPageCount(noteId:string):Promise<RzRes<number>>{
        let repos=this.getNoteRepos();
        let r=await repos!.getNoteRepo(noteId);
        if(IsFail(r)) return NewFail("RepoMng.getPageCount : no page count");

        let pgcnt=r.data!.getPageCount();
        return NewOk(pgcnt);
     }

     /* note */
     async addNote(noteInf:FtoNoteInfo,srcOpt?:FtSrcOpt){
        
        //  let repos=this.getNoteRepos()
        //  let r=await repos!.addNote(noteId, noteInf,srcOpt)
        
        let r=await this.addNoteInfo(noteInf);
         if(IsFail(r)) return NewFail('FtClassRepoMng.addNote > ' + r.message);
 
         let ninf=r.data;
         let ev=new FtClassEvent({cmd:FtClassEventEnum.REPO_ADD,note:ninf});
         this.notiClassEvent(ev,srcOpt);
         return r;
     }


     async putClassInfo(classId:string,clzInf:FtoClassInfo,srcOpt?:FtSrcOpt){
        if(!this.classInfoRepo) return NewFail('no classInfoRepo');
         //let ninf=r.data!;
         let r= await this.classInfoRepo.putClassInfo(classId,clzInf);
         if(IsFail(r)) return r;

         let sesId=this.sesId;
         rzlog.debug("putClassInfo : from sesId=",sesId);
         let ev=new FtClassEvent({cmd:FtClassEventEnum.CLASS_PUT, classId:classId, sesId:sesId, classInfo:clzInf});
         this.notiClassEvent(ev,srcOpt);
         return r;
     }
 
 
     async putNote(noteId:string,noteInf:FtoNoteInfo,srcOpt?:FtSrcOpt){
    

        let r=await this.putNoteInfo(noteId,noteInf);
        if(IsFail(r)) return NewFail('FtClassRepoMng.putNote > ' + r.message);

 
        let ninf=r.data!;

         let ev=new FtClassEvent({cmd:FtClassEventEnum.REPO_PUT,note:ninf});
         this.notiClassEvent(ev,srcOpt);
         return r;
     }
 
     async delNote(noteId:string,noteInf:FtoNoteInfo,srcOpt?:FtSrcOpt){
 
        let rscInf
        let r=await this.delNoteInfo(noteId,noteInf,srcOpt);
        if(IsFail(r)) return NewFail('FtClassRepoMng.putNote > ' + r.message);

 
         let ninf=r.data;
         let ev=new FtClassEvent({cmd:FtClassEventEnum.REPO_DEL,note:ninf});
         this.notiClassEvent(ev,srcOpt);
     }
     
     async getNotePageCount(noteId:string){
        let r=await  this.doGetNoteRepo(noteId);
        if(IsFail(r)) return NewFail<number>(`no repo: noteId=${noteId}`);
        let repo=r.data!;
        let cnt=repo.getPageCount();
        return NewOk<number>(cnt);
     }



     /* page */
     putCurPageNoByType(pg:number, type:string){
        let drepo= this.getDeskRepo(type);
        drepo.putCurPageNo(pg);
    }

    getCurPageNoByType(type:string){
        let drepo= this.getDeskRepo(type);
        let pg= drepo.getCurPageNo();
        
        return pg;
    }


    async putCurPageNo(noteId:string,pg:number,srcOpt?:FtSrcOpt){
       
        this.classInfoRepo!.setCurPageNo(noteId,pg,srcOpt);

        let r0=await this.classInfoRepo!.getNoteInfo(noteId);
        if(IsFail(r0)) return r0;
        let inf= r0.data!;
        

        let r=await this.doGetNoteRepo(noteId);
        if(IsFail(r)){
            rzlog.error('FtClassRepoMng  : no repo , noteId= ',noteId);
        } else {
            let repo=r.data!;
            repo.setCurPageNo(pg,srcOpt);
        }

        rzlog.debug("FtClassRepoMng.putCurPageNo: inf=",inf,',sesId=',this.sesId);
        let {isFloatingOn,isShowOn,...tinf}=inf;
        tinf={... tinf,selected:true};  
        let ev=new FtClassEvent({sesId: this.sesId, 
            noteId:noteId, classId:this.classId,
            cmd:FtClassEventEnum.PAGES_PUT,pages:[tinf]});
        this.notiClassEvent(ev,srcOpt);

     }

 
     async getCurPageNo(noteId:string):Promise<RzRes<number>>{ 
         let r=  await this.classInfoRepo!.getCurPageNo(noteId);
         
         return r;
     }

     async pathCount(noteId:string){
        let r0=await  this.doGetNoteRepo(noteId);
        if(IsFail(r0)) return NewFail<number>("RepoMng.pathCount:no repo");
        
        if(!r0.data) return  NewOk(0);

        return await r0.data!.pathCount();
     }

    //   async iterPath(noteId:string){
    //     let r0=await  this.doGetNoteRepo(noteId)
    //     if(r0.status>0) return  NewFail<RzoNotePath[]>("RepoMng.pathCount:no repo")
    //     return await r0.data!.iterPath()
    //  }
 
     async getPageInfo(noteId:string,pg:number){ 
         let r0=await  this.doGetNoteRepo(noteId);
         if(IsFail(r0)) return r0;
         let r=await r0.data!.getPageInfo(pg);
         return r;
     }


     async getAttr(noteId:string,pg:number,id:string){ 
        let r0=await  this.doGetNoteRepo(noteId);
        if(IsFail(r0)) return r0;
        let repo= r0.data!;
        if(!repo) return NewFail("no repo : noteId="+noteId);

        let r=await repo.getAttr(pg,id);
        return NewOk(r.data);
    }
 
     async addPage(noteId:string,pgInf:FtoPage,srcOpt?:FtSrcOpt):Promise<RzRes<RzNotePageInfo>>{
        let r0=await  this.doGetNoteRepo(noteId);
        if(IsFail(r0)) return NewFail(r0.message);
        
        let repo=r0.data!;
        if(!repo) return NewFail("no repo : noteId="+noteId);

        let r=await repo.addPage(pgInf.pageNo!,pgInf,srcOpt);
        if(IsFail(r)) return  NewFail(r.message);
 
        let ev=new FtClassEvent({cmd:FtClassEventEnum.PAGE_ADD,page:pgInf});
        this.notiClassEvent(ev,srcOpt);
         
        return r;
     }
 
     async putPage(noteId:string, pgInf:FtoPage,srcOpt?:FtSrcOpt):Promise<RzRes<RzNotePageInfo>>{
        let r0=await  this.doGetNoteRepo(noteId);
        if(IsFail(r0)) return NewFail(r0.message);
        let repo=r0.data!;
        
        let r=await repo.putPage(pgInf.pageNo!,pgInf,srcOpt);
        if(IsFail(r)) return NewFail(r.message);
 
        let ev=new FtClassEvent({cmd:FtClassEventEnum.PAGE_PUT,page:pgInf});
        this.notiClassEvent(ev,srcOpt);
        return r;
     }
 
     async delPage(noteId:string, pgInf:FtoPage,srcOpt?:FtSrcOpt){
        let r0=await  this.doGetNoteRepo(noteId);
        if(IsFail(r0)) return NewFail(r0.message);
        
        let repo=r0.data!;
        let r=await repo.delPage(pgInf.pageNo!,srcOpt);
        if(IsFail(r)) return NewFail(r.message);
 
        let ev=new FtClassEvent({cmd:FtClassEventEnum.PAGE_DEL,page:pgInf});
        this.notiClassEvent(ev,srcOpt);
        return r
     }

     /* path */
     async iterPath(noteId:string,pg:number):Promise<RzRes<RzoNotePath[]>>{ 
        let r0=await  this.doGetNoteRepo(noteId);
        if(IsFail(r0)) return NewFail(r0.message);

        let repo=r0.data!;
        let r=await repo.iterPath(pg);
        return r;
     }

     async iterPathAll(noteId:string,type?:string):Promise<RzRes<RzoNotePath[]>>{ 
        let r0=await  this.doGetNoteRepo(noteId);
        if(IsFail(r0)) return NewFail(r0.message);

        let repo=r0.data!;
        let r=await repo.iterPathAll(type);
        return r;
     }


     async getPath(noteId:string, pg:number, id:string):Promise<RzRes<RzoNotePath>>{
        let r0=await  this.doGetNoteRepo(noteId);
        if(IsFail(r0)) return NewFail(r0.message);
        let repo=r0.data!;
        let r1=await repo.getPath(pg,id);
        if(IsFail(r1)) return r1;
        let p= r1.data!;
        return r1;
    }

     async undoPath( noteId:string,pg:number,depth:number,srcOpt?:FtSrcOpt):Promise<RzRes<RzoNotePath>> {
        let r0=await  this.doGetNoteRepo(noteId);
        if(IsFail(r0)) return NewFail(r0.message);
        let repo=r0.data!;
 
        let r=await repo.undoPath(pg,depth);
        if(IsFail(r)) return NewFail(r.message);


        let pinfo=r.data!;
        if(!pinfo) return NewFail("no info");

 
        let npath={...pinfo, noteId:noteId, pageNo:pg};
        let ev=new FtClassEvent({cmd:FtClassEventEnum.PATH_DEL,paths:[npath]});
        this.notiClassEvent(ev,srcOpt);

        let r2=await repo.getPath(pg,pinfo.id!);
        let tpath=r2.data!;
        let { ...opath2}=tpath;//{...tpath };
        let putpath={...opath2,  noteId:noteId, pageNo:pg};
        let pev=new FtClassEvent({cmd:FtClassEventEnum.PATH_PUT,paths:[putpath]});
        this.notiClassEvent(pev,srcOpt);


        return NewOk(tpath);
     }

     myShares:{[k:string]:RzoNoteShare}={};
     async setNoteSharing(noteId:string,  share?:RzoNoteShare, srcOpt?:FtSrcOpt):Promise<void>{
        //let r=await this.getNoteRepo(noteId);
        let r= await this.doGetNoteRepo(noteId);
        if(IsFail(r)) return;
        let repo=r.data;
        if(!repo)return;
        
        if(this.classInfoRepo){
            this.classInfoRepo.setNoteShare(noteId,share,srcOpt);
        }
        let osh=this.classInfoRepo?.getNoteShare(noteId);
        
        if(this.myShares[noteId]===undefined) {
            let mySh= {
                isMyGroupWritingReadOn:share?.isMyGroupWritingReadOn,
                isMyWritingReadOn:share?.isMyWritingReadOn, 
                isMyOtherWritingReadOn:share?.isMyOtherWritingReadOn,
                isMyWritingAllOn:share?.isMyWritingAllOn,
                // isGroupWritingReadOn:share?.isGroupWritingReadOn
            };
            this.myShares[noteId]=mySh;
        } else { 
            let mySh= {
                isMyGroupWritingReadOn:share?.isMyGroupWritingReadOn ?? this.myShares[noteId].isMyGroupWritingReadOn,
                isMyWritingReadOn:share?.isMyWritingReadOn ?? this.myShares[noteId].isMyWritingReadOn, 
                isMyOtherWritingReadOn:share?.isMyOtherWritingReadOn ?? this.myShares[noteId].isMyOtherWritingReadOn,
                isMyWritingAllOn:share?.isMyWritingAllOn ?? this.myShares[noteId].isMyWritingAllOn,
                // isGroupWritingReadOn: share?.isGroupWriteOn ?? this.myShares[noteId].isGroupWritingReadOn,
                /** FIXME: 24.2.8 isGroupWritingReadOn 넣는 곳에 isGroupWriteOn 로 판단하는게 맞는지 확인 필요*/
                isGroupWritingReadOn: share?.isGroupWritingReadOn ?? this.myShares[noteId].isGroupWritingReadOn,
            };

            this.myShares[noteId]=Object.assign(this.myShares[noteId],mySh);
        }

        repo.setSharing(osh,srcOpt);
           
          if(!srcOpt){            
                let {  isMyGroupWritingReadOn,isMyWritingReadOn, 

                        isMyOtherWritingReadOn,isMyWritingAllOn, ...nsh}=share as any; 
                    let isLocalOnly=Object.keys(nsh).length==1;
                    
                    nsh.classId=this.classId;
                    let classSharing={
                        classId:this.classId,
                        noteSharings:[nsh]} as FtoClassSharing ;
                    let pev=new FtClassEvent({cmd:FtClassEventEnum.SHARING_PUT, 
                            classSharing:classSharing});
                    if(isLocalOnly && srcOpt === undefined)srcOpt={ type:'local'};
                    this.notiClassEvent(pev,srcOpt);
              
          }
     }

     getNoteSharing(noteId:string):RzoNoteShare{
        let sh=this.classInfoRepo?.getClassSharing();      
        if(sh?.noteSharings){
            let vs= sh.noteSharings.filter(t=> t.noteId===noteId);            
            if(vs.length>0) {
                let nsh=vs[0];
                if(this.myShares[noteId]!==undefined)  {                    
                    let mySh= {    
                        isMyGroupWritingReadOn:this.myShares[noteId]?.isMyGroupWritingReadOn,
                        isMyWritingReadOn:this.myShares[noteId]?.isMyWritingReadOn, 
                        isMyOtherWritingReadOn:this.myShares[noteId]?.isMyOtherWritingReadOn,
                        isMyWritingAllOn:this.myShares[noteId]?.isMyWritingAllOn,
                        isGroupWritingReadOn:this.myShares[noteId]?.isGroupWritingReadOn ?? false
                    };
        
                    this.myShares[noteId]=mySh;

                    nsh={...nsh, ...this.myShares[noteId]};
                    
                }

                //add delete __typename
                if((nsh as any).__typename ) delete (nsh as any).__typename;
        
                return nsh;
            }
        }
 
        return {};
     }


     async redoPath(noteId:string,tpg:number,depth:number,srcOpt?:FtSrcOpt){
        return NewOk();
     }


     async addPath(noteId:string,pg:number,pathInf:RzoNotePath,srcOpt?:FtSrcOpt) {
        if(rzIs.t) rzlog.trace('FtClassRepoMng.addPAth:noteId=',noteId,pg,pathInf);
        let r= await this.doGetNoteRepo(noteId);
        //if(r.status>0) return NewFail(`FtClassRepoMng.addPath : doGetNoteRepo(${noteId}) > `+r.message)
        if(IsFailLog(r,"FtClassRepoMng.addPath : no repo > noteId=",noteId)) 
            return NewFail(r.message);
        

        let repo=r.data!
        //repo.setCurPageNo(pg)
        let tr=await repo.addPath(pathInf,pg);
        if(IsFailLog(tr,`FtClassRepoMng.addPath : repo.addPath(${noteId})`)) 
            return NewFail(tr.message);
        //if(tr.status>0) return NewFail(`FtClassRepoMng.addPath : repo.addPath(${noteId}) > `+tr.message)


        let {attr, ...opath}=pathInf;
        //let ninf={...opath, attrs:JSON.stringify(attr), noteId:noteId, pageNo:pg};
        let ninf={...opath, attr, noteId:noteId, pageNo:pg};
        
        let ev=new FtClassEvent({cmd:FtClassEventEnum.PATH_ADD,paths:[ninf]});
        this.notiClassEvent(ev,srcOpt);
        return tr;
     }
 
     async putPath(noteId:string,pg:number, pathInf:RzoNotePath,srcOpt?:FtSrcOpt) {
         let r0= await this.doGetNoteRepo(noteId);
         if(IsFailLog(r0,"FtClassRepoMng.putPath : doGetNoteRepo > noteId=",noteId))
            return NewFail(r0.message);

         let repo=r0.data!;
         
         let r1=await repo.putPath(pathInf,pg);
         if(IsFailLog(r1,"FtClassRepoMng.putPath : putPath > noteId=",noteId))
            return NewFail(r1.message);
        
         let {attr, ...opath}=pathInf;
         //let npath={...opath, attrs:JSON.stringify(attr), noteId:noteId, pageNo:pg};
         let npath={...opath, attr, noteId:noteId, pageNo:pg};
         let ev=new FtClassEvent({cmd:FtClassEventEnum.PATH_PUT,paths:[npath]});
         this.notiClassEvent(ev,srcOpt);
         return r1;
     }
 
     async delPath(noteId:string,pg:number, pathInf:RzoNotePath,srcOpt?:FtSrcOpt) {
         let r0= await this.doGetNoteRepo(noteId);
         if(IsFail(r0)) return NewFail(r0.message);
         let repo=r0.data!;

         let r=await repo.delPath(pathInf,pg);
         if(IsFail(r)) return  NewFail(r.message);

         let {attr, ...opath}=pathInf;
         //let npath={...opath, attrs:JSON.stringify(attr), noteId:noteId, pageNo:pg};
         let npath={...opath, attr, noteId:noteId, pageNo:pg};
         let ev=new FtClassEvent({cmd:FtClassEventEnum.PATH_DEL,paths:[npath]});
         this.notiClassEvent(ev,srcOpt);
         return r;
     }




     /*************************** */
     /* ClassEvent  */
     onClassEvents:OnFn[]=[] ;
     notiClassEvent(ev:FtClassEvent,srcOpt?:FtSrcOpt){        
        if(srcOpt?.isSkip) return;
        ev.srcOpt=srcOpt;
        RzEventNotier.NotiEvent(this.onClassEvents,ev);
     }
     addOnClassEvent(fn:RzOnFn,pr?:any){
        RzEventNotier.AddOnEvent(this.onClassEvents,fn,pr);
     }

     delOnClassEvent(fn:RzOnFn){
        this.onClassEvents=RzEventNotier.DelOnEvent(this.onClassEvents,fn);
     }

     procClassEventByClient(e:FtClassEvent){
        rzlog.debug("procClassEventByClient:e=",e);
         this.classEventRecvHdr!.procEvent(this,e);
     }

     /************* */
 }//class
 

let _classRepoMngCnt=1;
 /***************
  * Factory
  */
 export class FtClassRepoMngFac {

     static async Init( type:string,opt:FtClassRepoMngOpts){        
            let ctx:RzCtx=new RzCtx();
            let rmng = new FtClassRepoMng();
            let fac=new FtClassRepoMngFac();

            await fac.configure(ctx,rmng,{...opt,loaderType:type});
    
            return NewOk(rmng);
     }
 
    async configure(ctx:RzCtx, repoMng:FtClassRepoMng,opts:FtClassRepoMngOpts){        
        
        repoMng.id=_classRepoMngCnt++;
        rzlog.debug(`FtClassRepoMngFac.configure [${repoMng.id}] : opt=`,opts);

        let connInfo:FtoConnInfo|null=null;   
        if(opts?.connInfo)  connInfo = opts.connInfo;

        let client:FtClassClient|null = null;
        if( opts?.client) client=opts?.client;
        
        let clientFac=null;
        if( opts?.clientFac) clientFac=opts.clientFac;
        if( !clientFac ) clientFac=new FtClassClientFac();

        if(connInfo) repoMng.setConnInfo(connInfo);
        if(clientFac) repoMng.setClientFac(clientFac);

        rzlog.debug(`FtClassRepoMngFac.configure [${repoMng.id}] : client=`,client);

        await repoMng.init(ctx,opts);
     }   


 }//class
 


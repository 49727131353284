import { RzOnVal,  RzRes, RzRsRes } from "../rzcmn"
import { FtClassClient } from "./ftclass.client"
import { FtoClassInfo, FtoConnStat, FtoNoteInfo, FtoPage, FtoPath, FtoSession } from "./dto/ftclass.dto"
import { FtClassEvent } from "./ftclass.event"
import { FteNoteInfo, FteChatMsg, FtePage } from "./repo/ftclass.repo.dto"
import { rzlog, NewOk,RzOnFn, RzNotePage } from "./inc"

 

/******************************
 * FtClientEvent
 */
export class FtClientEvent {
    cmd?:string // conn, session[cud], class[cud], pages[u], book[cud], note[cud], page[cud], paths[cud]
    connStat?:FtoConnStat
    classId?:string
    classInfo?:FtoClassInfo
    session?:FtoSession
    pages?:FtoNoteInfo[]
    note?:FtoNoteInfo;
    page?:FtoPage;
    paths?:FtoPath[]; 

    constructor(opt:any){
        Object.assign(this,opt)
    }
}//class


interface FtClientSubscrib{
    classId:string
    subscribFn :(ev:any)=>void
}
/**********
 * 
 */
export class FtClassRtcClient extends FtClassClient {
    close(): Promise<RzRes<void>> {
        throw new Error("Method not implemented.")
    }
    subscribClassEvent(clsId:string,fn: (e: FtClassEvent) => void, errFn: (err: any) => void): void {
        throw new Error("Method not implemented.")
    }
    fetchNoteInfos(classId: string, pg: number, opt?: any): Promise<RzRsRes<FteNoteInfo>> {
        throw new Error("Method not implemented.")
    }
    addNoteInfo(classId:string, ntInf:FteNoteInfo): Promise<RzRes<FteNoteInfo>> {
        throw new Error("Method not implemented.")
    }

    fetchNotePage(noteId: string, pg: number, opt?: any): Promise<RzRes<FtePage>> {
        throw new Error("Method not implemented.")
    }
    fetchNotePages(noteId: string,   opt?: any): Promise<RzRes<RzNotePage[]>> {
        throw new Error("Method not implemented.");
      }    
  
    
      
    fetchGetChats(classId: string): Promise<RzRsRes<FteChatMsg>> {
        throw new Error("Method not implemented.")
    }
    fetchJoinClass(req: any): Promise<any> {
        throw new Error("Method not implemented.")
    }
    fetchLeaveClass(req: any): Promise<any> {
        throw new Error("Method not implemented.")
    }
    fetchSendChat(req: any): Promise<any> {
        throw new Error("Method not implemented.")
    }
 
    procClassEventByUi(e: FtClassEvent, pr: any): void {
        throw new Error("Method not implemented.")
    }
    conn?:any;
    //subscribs:FtClientSubscrib[]=[] ;
    
    setConn(conn:any){ 
        this.conn=conn
        conn.addOnEvent((e:any)=>{
            let ev={cmd:'conn', conStat:'connect'}
            this.notiClientEvent(ev)
        })
        conn.subscribConn((m:any)=>{
            if(m.status>0){
                rzlog.error('')
                return 
            }

            let evt=new FtClientEvent({...m.data, cmd:m.data.cmd, classId:m.data.classId })
            this.notiClientEvent(evt)
        })
    }

    isConnected(){
        if(!this.conn) return false
        return this.conn.isConnected()
    }

    /**
     * 
     */
    async connect(){
        return await this.conn.connect()
     }

    async disconnect(){
        return  await this.conn.disconnect()
    }

    async fetchClassInfo(classId:string,opt?:any){
        let req={cmd:'getClassInfo',classId:classId, }
        let r= await this.conn.fetchReq(req)
        //let ci=new FtClassInfo()
        return NewOk<FtoClassInfo>()
    }

    async fetchClassPages(classId:string, pg:number, opt?:any){
        let req={cmd:'getClassPages',classId:classId, page:pg}
        let r= await this.conn.fetchReq(req)
        //let ci=new FtClassInfo()

        return NewOk()
    }

    // async fetchNotePage(noteId:string,pg:number,opt?:any){
    //     let req={cmd:'getNotePage',classId:noteId, page:pg}
    //     let r= await this.conn.fetchReq(req)
    //     return NewOk()
    // }

    async fetchBookPage(bookId:string,pg:number,opt?:any){
        let req={cmd:'getNotePage',classId:bookId, page:pg}
        let r= await this.conn.fetchReq(req)
        return NewOk()
    }

    /**
     * 
     * @param {*} classId string
     * @param {*} fn fn(evt FtClassClientEvent)
     * @returns RzRes
     */
    async subscribClass(classId:string,fn:(ev:any)=>void){
       // this.subscribs.push({classId:classId, subscribFn:fn})
        return NewOk()
    }
    async pubClassEvent(ev:FtClassEvent){
        return NewOk()
    }

    addOnClientEvent(fn:RzOnFn){
      this.addOnEvent(fn)
    }

    /**
     * 
     * @param {*} ev FtClientEvent
     */
    notiClientEvent(ev:FtClientEvent){
      this.notiEvent(ev)

      if(ev?.cmd!=='conn') {
          let nev={...ev}
          this.pubClassEvent(nev)
      } else  {
        this.pubClassEvent(ev)
      }
    }
    
}//class
 
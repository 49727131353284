import { rzlog ,rzPut} from "./inc";
import { RzBtnLet, RzRect, RzUiLet, RzUiOpts, } from "./rzcmn.ui";



const rzIs=rzlog.makeDefs()
export const RzCmnUiSplit_setDbg=rzIs.setDbg

interface tRzPos {
    x:number;
    y:number;
    x0?:number;
    y0?:number;
    w0?:number; 
    lx0?:number;
    lw?:number;
    lh?:number; 
    rx0?:number;
    rw?:number;
    rh?:number;
}


export interface RzResizeLet {
    native: ()=> HTMLElement
    onResize:(e:any)=>void
   
}

export class RzSplitLet extends RzUiLet {

    isPressed=false
    onSplitMove?:(e:any)=>void;
    prevPos:tRzPos={x:0,y:0}
    quickBtnCssText?:string;
    quickWidth=24
    quickHeight=40
    quickTop=50
    isQuickClosed=false;
    onQuickClick?:(isOpen:boolean,e?:Event)=>void;

    
    quickBtn?:RzUiLet
    first?:RzResizeLet
    second?:RzResizeLet
    firstMinSize:number=0;
    secondMinSize:number=0;

    setMinSize(sz1:number,sz2:number){
       this.firstMinSize=sz1;
       this.secondMinSize=sz2;
    }
    setLets(first:RzResizeLet,second:RzResizeLet){
        this.first=first
        this.second=second
    }

    bgColor:string="#E1E1E1";
    
    doPreOpts(opt:RzUiOpts){
        this.isDraggable=true
        return {...opt, isDraggable:true,cssText:(opt?.cssText||'')+ `cursor:col-resize; background:${this.bgColor};`}
    }

    setBarColor(color:string){
        this.bgColor=color;
    }

    doInit(opt:RzUiOpts){
        let r=this.getBounds()
        rzlog.debug("DDD r=",r)
           
        let layer=2;
        let btn= new RzBtnLet()
            btn.setParent(this)
            btn.setTitle("&gt;")
            btn.init({
                cssText:`position:absolute; z-index:${layer}; 
                        box-shadow: -6px 0px 10px rgba(100, 89, 89, 0.1);
                        background-color: #FCFCFC; border: 1px solid #FCFCFC; 
                        border-top-left-radius: 100px; border-bottom-left-radius: 100px;
                        border-right : #FCFCFC;
                        left:${r.x-this.quickWidth}px; top:${r.y+this.quickTop}px; 
                        width:${this.quickWidth}px; height:${this.quickHeight}px;
                        `})
            btn.onClick=(e)=>{ this.doNotiQuickBtnClick(e)}
            this.quickBtn=btn

        return true
    }

    doNotiQuickBtnClick(e:Event){
        this.isQuickClosed=!this.isQuickClosed;
        if(this.onQuickClick)  this.onQuickClick(this.isQuickClosed,e)
    }


    onMouseDown=(e:MouseEvent)=>{
        
        e.stopPropagation()
        if(rzIs.d)rzlog.debug('RzSplitLet.doMouseDown:e=',e)
       // this.isPressed=true
        if(!this.nativeDiv) return
        let x0=this.nativeDiv?.offsetLeft
        let w0=this.nativeDiv?.offsetWidth

        let first=this.first?.native() //this.lets.book
        let second=this.second?.native()// this.lets.note
        if(!first || !second) return

        let lx0= (first.offsetLeft)
        let lw=(first.offsetWidth)
        let lh=(first.offsetHeight)
        let rx0=(second.offsetLeft)
        let rw= (second.offsetWidth)
        let rh=(second.offsetHeight)

        this.prevPos={x:e.clientX,y:e.clientY, x0:x0,w0:w0, lx0:lx0, lw:lw,lh:lh, rx0:rx0,rw:rw,rh:rh}
    }

    onMouseUp=(e:MouseEvent)=>{
        e.stopPropagation()
        if(rzIs.d)rzlog.debug('RzSplitLet.doMouseUp:e=',e)

        let ne={x:e.clientX, y:e.clientY, 
            dx:e.clientX-this.prevPos.x, dy:e.clientY-this.prevPos.y}

        this.doProcSplitMove(ne,true)

    }   

    onMouseMove=(e:MouseEvent)=>{
        e.stopPropagation()
        if(rzIs.d)rzlog.debug('RzSplitLet.doMouseMove :e=',e)


        let ne={x:e.clientX, y:e.clientY, 
            dx:e.clientX-this.prevPos.x, dy:e.clientY-this.prevPos.y}
    
        this.doProcSplitMove(ne,false)
    }


    doProcSplitMove(ne:any,isMoveEnd:boolean){
        let dx= ne.dx
        let r=this.getBounds()
        let x0=(this.prevPos.x0+dx)
        let w0=(this.prevPos.w0)

        if(!this.first || !this.second || !this.nativeDiv)  return

        let first=this.first.native() //this.lets.book
        let second=this.second.native()// this.lets.note
        let lx0= (this.prevPos.lx0)
        let lw=(this.prevPos.lw+dx)
        let lh=(this.prevPos.lh)

        let rx0=(this.prevPos.rx0+dx)
        let rw= (this.prevPos.rw!-dx)
        let rh=(this.prevPos!.rh)

        if(this.firstMinSize  > 0 &&  this.firstMinSize > lw) return;
        if(this.secondMinSize > 0 &&  this.secondMinSize > rw) return;

        if(rzIs.d)rzlog.debug('layout.splitMove : ',ne,',x0=',x0,',lw=',lw,',rx0=',rx0,',rw=',rw,
        ',lmin=',this.firstMinSize,',rmin=',this.secondMinSize)

        this.nativeDiv.style.left=x0+'px'
        this.nativeDiv.style.width=w0+'px'
        
        first!.style.left=lx0+'px'
        first!.style.width=lw+'px'
        this.first.onResize!({w:lw,h:lh,isMoving:true,isEnd:isMoveEnd})

        second!.style.left=rx0+'px'
        second!.style.width=rw+'px'
        this.second.onResize!({w:rw,h:rh,isMoving:true,isEnd:isMoveEnd})

        this.doUpdateQuickBtn(rx0,r.y);

    }

    doUpdateQuickBtn(x:number,y:number){
        if(this.quickBtn) this.quickBtn.nativeDiv!.style.left=(x-this.quickWidth-this.splitWidth)+'px'
        if(this.quickBtn) this.quickBtn.nativeDiv!.style.top=(y+this.quickTop)+'px'
    }

    onMouseOut=(e:MouseEvent)=>{
       // this.isPressed=false
       this.prevPos={x:0,y:0}
    }

    notiOnSplitMove(ne:any){

        if(this.onSplitMove)  this.onSplitMove(ne)
    }   

    splitWidth=5

    setVisible(b: boolean): void {
        super.setVisible(b);
        if(b){
 
            let pos=this.getBounds()
            
 
            if(rzIs.d) rzlog.debug("Split.setVisible : pos=",pos)
            this.doUpdateQuickBtn(pos.x+this.splitWidth,pos.y);
    
        }
    }
    //onSplitMove
}
import { rzlog,RzUiLet, } from "../../inc";

import { FtLayoutCtx, FtLayoutInit, FtLayoutOpts, FtUiLets } from "../ftclass/ftclass.ui.layout";
import { FtFullLayout } from "./ftclass.ui.layout.full";
import { FtClassTop, FtPaletteEvent } from "../ftclass/ftclass.ui.top";
import { FtClassPopMenu } from "../ftclass/ftclass.ui.pop-menu";
import { FtPopMenuEnum, FtPopMenuEvent } from "../ftclass/ftclass.ui.pop";
import { FtPopEtcDlg, FtPopSettingDlg } from "./ftclass.ui.layout.dlg";

/** dbg */
const rzIs=rzlog.makeDefs()
export const FtClassUiLayoutSubPopBar_setDbg =rzIs.setDbg

 
/**
 FtSubPopBarInit
 */
export class FtSubPopBarInit implements FtLayoutInit {
    lman:FtFullLayout;

    constructor(lman:FtFullLayout){
        this.lman=lman;
    }
    
    init(lctx:FtLayoutCtx,pr?:any){
        this.doInitPopMenu(lctx);
    }

    doInitPopMenu(lctx:FtLayoutCtx){
        let lman=this.lman;

        let FTLAYER_TOP=9//lctx.layer_top
        //let topPos=60;
        let topHeight=91;

        if(lctx.opt.isMenuOn){
            let rct=lctx.lets!.ctrl!.getBounds();
            let topPos=rct.height;
            let cssTxt=` position:absolute; left:0px; top:${topPos}px;   
                        width:100%; height: ${topHeight}px; 
                        z-index:${FTLAYER_TOP};
                        display:flex; flex-direction:row;
                        align-items:center;  
                        background: linear-gradient(180deg, rgba(11, 11, 11, 0.4) -54.48%, #0B0B0B 108.58%);`
            this.doInitSubPopMenu(lctx,lctx.body,lctx.lets!.menu!,cssTxt)
        } else {
            if(lctx.lets!.menu!)  lman.doRemoveChild(lctx.body,lctx.lets!.menu!)
        }
    }

    chatSelected=false;
    doInitSubPopMenu(lctx:FtLayoutCtx ,p:RzUiLet,menuLet:RzUiLet,cssText:string,opt?:any){
        let lman=this.lman;
        if(lman.doUdateVisble(p,menuLet,0,cssText)) return menuLet

        let sublet:FtClassPopMenu=lman.subLetFac.createPopMenu(p,cssText,opt,lctx.repoMng) as FtClassPopMenu
        sublet.chatBtn?.setSelected(this.chatSelected)

        sublet.addOnPopMenuEvent((e:FtPopMenuEvent,pr?:any)=>{
            if(rzIs.d) rzlog.debug('Layout.onPopMenuEvent : e=',e)
            let ce=null

            if(pr.lets?.side) {
                if(e.cmd===FtPopMenuEnum.VIDEO ||e.cmd===FtPopMenuEnum.LOCK||e.cmd===FtPopMenuEnum.AUDIO)
                pr.lets.side.onPopupMenu(e,pr.lets.side)
            }
        
    
            if(pr.lets?.popup) {
                if(e.cmd===FtPopMenuEnum.VIDEO ||e.cmd===FtPopMenuEnum.LOCK||e.cmd===FtPopMenuEnum.AUDIO)
                    pr.lets.popup.onPopupMenu(e,pr.lets.popup)
            }

           
            if(e.cmd === FtPopMenuEnum.VIDEO){

            } else if(e.cmd === FtPopMenuEnum.AUDIO){
            } else if(e.cmd === FtPopMenuEnum.LOCK){
                //if(rzIs.d) 
                rzlog.debug('LOCK !! : pr=',pr)
                let layMng:FtFullLayout= pr
                if(e.selected){
                    if(!layMng.layoutOpts.isSideOn) { if(rzIs.d) rzlog.debug('LOCK !! : On');  pr.doUpdateUi({isMenuOn:true, isSideOn:true,isPopVideoOn:false})}
                } else {
                    if(layMng.layoutOpts.isSideOn) { if(rzIs.d) rzlog.debug('LOCK !! : Off');  pr.doUpdateUi({isMenuOn:true, isSideOn:false,isPopVideoOn:true})}
                }
            } else if(e.cmd === FtPopMenuEnum.VIDEO2){
            } else if(e.cmd === FtPopMenuEnum.AUDIO2){
            } else if(e.cmd === FtPopMenuEnum.LOCK2){
            } else if(e.cmd === FtPopMenuEnum.VIDEO){
            } else if(e.cmd === FtPopMenuEnum.CHAT){
                if(rzIs.d)rzlog.debug('CHAT : pr=',pr)
                pr.doUpdateUi({isMenuOn:true, isChatOn:e.selected})
            } else if(e.cmd === FtPopMenuEnum.NOTE){
            } else if(e.cmd === FtPopMenuEnum.SETTING){
                if(rzIs.d) rzlog.debug('FtFullLayout.doInitSubPopMenu : cmd==setting ')
                let pos=sublet.settingBtn!.getBounds()
                this.doShowSettingDlg(lctx,sublet,pos,e.selected)
            } else if(e.cmd === FtPopMenuEnum.ETC){
                if(rzIs.d) rzlog.debug('FtFullLayout.doInitSubPopMenu : cmd==etc(dot3) ')
                let pos=sublet.etcBtn!.getBounds()
                this.doShowEtcDlg(lctx,sublet,pos,e.selected)

            } else if(e.cmd === FtPopMenuEnum.EXIT){

            }

            if(ce) lctx.repoMng!.notiClassEvent(e)
        },this.lman)

  
        lctx.lets!.menu=sublet
        return sublet
    }


    doShowSettingDlg(lctx:FtLayoutCtx,p:RzUiLet,ppos:any,stat:any){
        let lman=this.lman;
        
        if(lctx.lets!.settingDlg) {  
            if(stat){
                p.addChild(lctx.lets!.settingDlg)

            } else {
                p.removeChild(lctx.lets!.settingDlg)
            }
             
            lctx.lets!.settingDlg.setVisible(stat);  
            return 
         }

        let ui=lctx.repoMng!.getUserInfo()!
        let dlg=new FtPopSettingDlg()
        dlg.setUserInfo(ui)
        dlg.btnPos=ppos;
        //dlg.setSize(w,h)
        dlg.onVisible=()=>{dlg.updateUi()}
        dlg.setParent(p)
        if(lctx.repoMng) dlg.setRepo(lctx.repoMng)
        dlg.init()

        lctx.lets!.settingDlg=dlg
        if(rzIs.d) rzlog.debug('FtClassLayout.doShowSettingDlg : dlg=',dlg)
    }


    doShowEtcDlg(lctx:FtLayoutCtx,p:RzUiLet,ppos:any,stat:boolean){
        if(lctx.lets!.etcDlg) {
            if(stat){
                p.addChild(lctx.lets!.etcDlg)
            } else {
                p.removeChild(lctx.lets!.etcDlg)
            }
            lctx.lets!.etcDlg.setVisible(stat);  return 
        }

        
        let w=300
        let h=385
        let pos={x:ppos.x -(w*2/3), y:ppos.y+20,width:w,height:h}

        let ui=lctx.repoMng!.getUserInfo()!
        //let dlg=new RzUiLet()
        let dlg=new FtPopEtcDlg()
        dlg.setUserInfo(ui)

        dlg.btnPos=ppos;
        dlg.setParent(p)
        if(lctx.repoMng) dlg.setRepo(lctx.repoMng)
        dlg.onVisible=()=>{dlg.updateUi()}
        dlg.init()
        lctx.lets!.etcDlg=dlg
        if(rzIs.d) rzlog.debug('FtClassLayout.doShowSettingDlg : dlg=',dlg)
    }


}//class
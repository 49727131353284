import { volatile } from "../rzcmn"
import { FtSrcOpt, FtoClassInfo, FtoClassNoti, FtoClassSharing, FtoEditLock, FtoEtcSetting, FtoNoteInfo, FtoPage, FtoPageInfo, 
        FtoPath, FtoSession, FtoSetting} from "./dto/ftclass.dto"
import { RzPenAttr } from "./note.ui"
import { RzChatMsg } from "./ui/ftclass/ftclass.ui.chat"



  /******************************************************* 
  *  FtClassEvent
  * 
 */
   export class FtClassEventEnum {
    static REPO_LOAD='repo.load'

 
    static CLASS_ADD='class.add'
    static CLASS_PUT='class.put'
    static CLASS_DEL='class.del'
    
    static CLASS_BEG='class.beg'
    static CLASS_BEG_ACK='class.beg.ack'
    static CLASS_END='class.end'
    
 
    static SESSION_ADD='session.add'
    static SESSION_ADD_ACK='session.add.ack'

    static SESSION_PUT='session.put'
    static SESSION_DEL='session.del'

 
    static PAGES_ON='pages.on'
    static PAGES_ADD='pages.add'
    static PAGES_PUT='pages.put'
    static PAGES_DEL='pages.del'
 
    static REPO_ADD='repo.add'
    static REPO_PUT='repo.put'
    static REPO_DEL='repo.del'

    static NOTE_ADD='note.add'
    static NOTE_PUT='note.put'
    static NOTE_DEL='note.del'


    static PAGE_ADD='page.add'
    static PAGE_PUT='page.put'
    static PAGE_DEL='page.del'

 
    static PATH_ADD='path.add'
    static PATH_PUT='path.put'
    static PATH_DEL='path.del'

    static CONN_ON='conn.on'
    static CONN_OFF='conn.off'

    static CHAT_ADD='chat.add'

    static UI_TOOL='ui.tool'
    
    
    static EDIT_LOCK='edit.lock'
    static REFRESH='refresh'

    static SETTING='setting'
    static SETTING_ETC='setting.etc'
   
    static SHARING_PUT = 'sharing.put'

    static NOTI_SEND='noti.send'

 

    //static PEN_PUT='pen.put'
}//class


export class FtClassEvent {
    classId?:string
    cmd?:string 
    scmd?:string 
    sesId?:string 
    noteId?:string
    begSeq?:number
    
    isObserverChatOn?:boolean;
    seq?:number;
    classInfo?:FtoClassInfo;
    session?:FtoSession;
    pages?:FtoPageInfo[];
    book?:FtoNoteInfo;
    note?:FtoNoteInfo;
    page?:FtoPage;
    paths?:FtoPath[]; 
    chats?:RzChatMsg[];
    conn?:any;
    srcOpt?:FtSrcOpt;
    editLock?:FtoEditLock;
    setting?:FtoSetting;
    etcSetting?:FtoEtcSetting;
    //2023.11.17 추가
    classSharing?:FtoClassSharing;
    noti?:FtoClassNoti;

    //@volatile
    penAttr?:RzPenAttr;
    isRepaint?:boolean;

    constructor(opt?:Partial<FtClassEvent>){
        Object.assign(this,opt);
    }
}//class

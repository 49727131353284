import { rzlog,RzUiLet } from "../../inc";
import { FtFullLayout } from "./ftclass.ui.layout.full";
import { FT_LAYOUT_VIDEO, FT_LAYOUT_VIDEO1 } from "../../consts";
import { FtLayoutCtx, FtLayoutOpts } from "../ftclass/ftclass.ui.layout";

/** dbg */
const rzIs=rzlog.makeDefs()
export const FtNoSplitLayout_setDbg =rzIs.setDbg

 

/**
 * FtNoSplitLayout
 */
export class FtNoSplitLayout extends FtFullLayout {

    doInit(opt:FtLayoutOpts){
        let nopt={...opt,isBookOn:true, isNoteOn:false,
            layoutType:FT_LAYOUT_VIDEO}
 
        return super.doInit(nopt)
    }

    doInitSubBody(lctx:FtLayoutCtx){

        // let nopt={...lctx.opt, isBookOn:true, isNoteOn:false}

        // if(lctx.opt.layoutType===FT_LAYOUT_VIDEO1 
        //         || lctx.opt.layoutType===FT_LAYOUT_VIDEO ){
        //     lctx.opt=nopt;
        // }

       return super.doInitSubBody(lctx)
    }

    // @reinit(layoutType:string,opt:FtLayoutOpts){
    //     let nopt={...opt, layoutType:layoutType}
    //     super.doUpdateUi(nopt)
    // }

}//class



 

 

import { rzlog } from "../../inc"
import { RzBtnLet,RzUiLet,RzOnFn, RzUiOpts,scale } from "../../inc"
import { RzEventNotier,RzNote_setDefPenSize } from "../../inc"
import { FtoEditLock, FtoUser, FtoUserTypeEnum } from "../../dto/ftclass.dto"

import './ftnote-icons.css'
import { FT_PALETTE_DEF_COLOR, FT_PALETTE_DEF_COLORS, FT_PALETTE_DEF_SIZE, FT_PALETTE_FONT_LISTS, FT_PALETTE_FONT_SIZE, FT_PALETTE_FONT_SIZES, FT_PALETTE_SIZES } from "../../consts"



/************* */
const rzIs=rzlog.makeDefs()
export const FtClassTop_setDbg=rzIs.setDbg

let isDbgUi=false;
export function FtClassTop_setDbgUi(b:boolean){
    isDbgUi=b;
}

/************* */
let _defPenSize=FT_PALETTE_DEF_SIZE;
let _defPenColor=FT_PALETTE_DEF_COLOR;
const _FT_PALETTE_BTN_SIZES=[10,12,14,16,18,20,22,24,26]

//const DEF_PEN_SIZES=FT_PALETTE_SIZES//[9,10,11,12,13,20]
const DEF_FONT_SIZES=FT_PALETTE_FONT_SIZES//[9,10,11,12,13,20]
const DEF_FONT_SIZE=FT_PALETTE_FONT_SIZE//12

/************* */
export class FtPaletteEnum {
    static PEN='pen'
    static ERASER='eraser'
    static TEXT='text'
    static UNDO='undo'
    static MEMO='memo'
    static NULL='null'
    static CAST='cast'
}
export class FtPaletteEventEnum {
    static TOOL='tool'
    static SIZE='size'
    static COLOR='color'
    static FONT='font'
    static CAST='cast'
}

export class FtPenTools {
    static TEXT='text'
    static PEN='pen'
    static MARKER='marker'
    static ERASER='eraser'
    static UNDO='undo'
    static DRAG='drag'
    static MEMO='memo'
    static CAST='cast'
}



export class FtPaletteEvent{
    cmd?:string
    type?:string
    prevType?:string
    palType?:string
    color?:string
    size?:number
    font?:string
    fontSize?:number

    castType?:string      // student|teacher
    isMyCastOn?:boolean
    isOtherCastOn?:boolean
    casterSesId?:string   // cast sesId

}//class

export class FtCastEventEnum {
    static CHANGED='changed';
    static LOCK='lock';
};
export interface FtCastEvent {
    cmd:string;
    editLock?:FtoEditLock;
}

export interface FtCastRepo {
    getUserInfo():FtoUser|undefined;
    getEditLock():FtoEditLock|undefined;

    addOnEditLock(fn:(e:FtCastEvent)=>void):void;
    delOnEditLock(fn:(e:FtCastEvent)=>void):void;
 
}
/*********************************
 *  FtNoteTop
 ********************************/
 export class FtClassTop  extends RzUiLet {
    paletteType?:string
    userType?:string
    isMyCastOn:boolean=true;

    leftPnl?:RzUiLet
    rightPnl?:RzUiLet

    curPalette?:RzUiLet

    penPalette?:FtPalettePnl
    textPalette?:FtPalettePnl
    eraserPalette?:FtPalettePnl
    undoPalette?:RzUiLet
    nullPalette?:RzUiLet
    castPalette?:FtCastPalettePnl

    paletteAttr:Record<string,any>={}
    toolButtons:Record<string,any>={}
    curToolType=FtPenTools.MARKER
    castRepo?:FtCastRepo;
    defToolType=FtPenTools.MARKER

    doPreOpts(opt:RzUiOpts){
         return {...opt, className:'ftclass_top', 
                cssText:(opt?.cssText||'')+`display:flex; flex-direction:row; padding-top:7px;`}
     }

     setUserRepo(repo:FtCastRepo){
        this.castRepo=repo
        rzlog.debug('FtClassUi.Top(doLoadClassInfo) : castRepo=',repo,
        ',plt=',this.castPalette)

        if(this.castPalette) this.castPalette.setCastRepo(repo);
     }


     setMyCastOn(b:boolean){
        this.isMyCastOn=b
        if(this.castPalette) this.castPalette.setMyCastOn(b);
     }


     setPaletteType(type:string ){
        if(rzIs.d) rzlog.debug('FtClassTop.setPaletteType: type=',type,',curType=',this.curToolType)
        let otype=FtPaletteEnum.PEN
        if(this.curToolType!==type){
            otype=this.curToolType
            if(rzIs.d) rzlog.debug('FtClassTop.setPaletteType: otype=',this.toolButtons[otype])
            this.toolButtons[otype].setSelected(false)
            this.toolButtons[type].setSelected(true)
            
            this.curToolType=type
        }

        this.notiPaletteEvent({cmd:FtPaletteEventEnum.TOOL,type:type,prevType:otype})


        let palType=FtPaletteEnum.NULL
        if(type===FtPenTools.TEXT) palType=FtPaletteEnum.TEXT
        else if(type===FtPenTools.PEN) palType=FtPaletteEnum.PEN
        else if(type===FtPenTools.MARKER) palType=FtPaletteEnum.PEN
        else if(type===FtPenTools.ERASER) palType=FtPaletteEnum.ERASER
        else if(type===FtPenTools.UNDO) palType=FtPaletteEnum.UNDO
        else if(type===FtPenTools.MEMO) palType=FtPaletteEnum.MEMO
        else if(type===FtPenTools.CAST) palType=FtPaletteEnum.CAST

         let old=this.paletteType
         this.paletteType=palType

       

        if(old!==type)  this.doUpdateUiPalette()
        //this.setPaletteAttr(attr)
     }

     onKeyDown= (e: KeyboardEvent) => {
        rzlog.debug("Top,KEYDOWN !!! : e=",e);
        if(e.key=='h'){
            this.onPaletteType({palType:FtPenTools.DRAG})
            e.stopPropagation()
        } else         if(e.key=='p'){
            this.onPaletteType({palType:FtPenTools.PEN})
            e.stopPropagation()
        } else         if(e.key=='t'){
            this.onPaletteType({palType:FtPenTools.TEXT})
            e.stopPropagation()
        } else         if(e.key=='e'){
            this.onPaletteType({palType:FtPenTools.ERASER})
            e.stopPropagation()
            
        } else         if(e.key=='m'){
            this.onPaletteType({palType:FtPenTools.MARKER})
            e.stopPropagation()
        }

     }

     doInit(opt:RzUiOpts){
        RzNote_setDefPenSize(FT_PALETTE_DEF_SIZE);
        window.removeEventListener("keydown",this.onKeyDown)

        this.doInitLeft(opt)

        this.doInitRight(opt)

        this.doUpdateAttrs()

        window.addEventListener("keydown",this.onKeyDown)

        this.penPalette!.setSelectedSize(FT_PALETTE_DEF_SIZE)
         return true
     }


     doInitLeft(opt:RzUiOpts){
         if(this.leftPnl) return

            let leftPnl= new RzUiLet()
            leftPnl.setParent(this)
            leftPnl.init({cssText:'width:406px; display:flex; flex-direction:row; align-items:center; text-align:left;'})

            this.leftPnl=leftPnl
            let types=['text','pen','marker','eraser','undo','drag','memo','cast']
            let titles=['T','P','M','E','Un','Dg','Ne','Ct']
            let icons=[ 'ftnote-text-icon','ftnote-pen-icon','ftnote-marker-icon','ftnote-eraser-icon',
                        'ftnote-undo-icon','ftnote-hand-icon','ftnote-memo-icon',
                        'ftnote-cast-icon']
                        
            for(let i=0;i<titles.length;i++){
               let pbtn=new FtTopBtn()
               let topt:{[key:string]:string}={}

               if(i===0){ //pbtn.setSelected(true); 
                     //   this.curToolType=types[i]
                     
                        topt.cssText='margin-left:15px;' 
                    }
                if(types[i]=== this.defToolType ) pbtn.selected=true

               pbtn.setParent(leftPnl)
               pbtn.setTitle(titles[i])
               pbtn.setIcon(icons[i])
               pbtn.init(topt)
               pbtn.setNavAttr('srcObj',pbtn) //nativeDiv.srcObj=pbtn
               pbtn.setNavAttr('palType',types[i])
               
               pbtn.onClick=(e:any)=>{   
                rzlog.debug('tool click=....'); 
                   let el:any=e.target
                   if(!el.srcObj) el=e.target.parentNode
                   this.onPaletteType({palType:el.palType})
                }
                this.toolButtons[types[i]]=pbtn
            }//for
     }

    onPaletteType(e:FtPaletteEvent){
        if(rzIs.d) rzlog.debug('FtClassTop.onPaletteType: e=',e,',this=',this)

        this.setPaletteType(e.palType!)
    }


    setPaletteAttr(attr:any){
        this.paletteAttr=attr
        this.doUpdateAttrs()
    }

    doUpdateAttrs(){
        if(this.curToolType=== FtPaletteEnum.PEN){
            let color=this.paletteAttr.color
            let width=this.paletteAttr.width
           
            this.penPalette?.unselectAll()
            this.penPalette?.setSelectedColor(color)
            this.penPalette?.setSelectedSize(width)
        } else if(this.curToolType === FtPaletteEnum.TEXT){
            let color=this.paletteAttr.color
            let width=this.paletteAttr.width
            let font=this.paletteAttr.font
            this.textPalette?.unselectAll()
            this.textPalette?.setSelectedFont(font)
            this.textPalette?.setSelectedColor(color)
            this.textPalette?.setSelectedSize(width)

        }else if(this.curToolType === FtPaletteEnum.ERASER){
 
            let width=this.paletteAttr.width
            this.eraserPalette?.unselectAll()
            this.eraserPalette?.setSelectedSize(width)
        }
    }


     doInitRight(opt:RzUiOpts){
        let rightPnl= new RzUiLet()
        rightPnl.setParent(this)

        let xscroll_on='overflow-x:auto;'
        let css=`margin-left:13px; padding-left:15px; align-items:center;
                      border-left:2px solid #717171; 
                      ${xscroll_on}
                height:100%;  text-align:left;`
        rightPnl.init({className:'fttop_right_pnl',
                    cssText:css});
        
        this.rightPnl=rightPnl

        this.paletteType=FtPaletteEnum.PEN
        this.doUpdateUiPalette()
     }

     doUpdateUiPalette(){
        if(this.curPalette) {
            //this.curPalette.setVisible(false)
            this.rightPnl!.removeChild(this.curPalette)
        }

        if(this.paletteType===FtPaletteEnum.PEN) {
            this.curPalette=this.doInitPenPalette()
            
        } else if(this.paletteType===FtPaletteEnum.TEXT) {
            this.curPalette=this.doInitTextPalette()
        } else if(this.paletteType===FtPaletteEnum.ERASER) {
            this.curPalette=this.doInitEraserPalette()
        } else if(this.paletteType===FtPaletteEnum.UNDO) {
            this.curPalette=this.doInitUndoPalette()
        } else if(this.paletteType===FtPaletteEnum.MEMO) {
            this.curPalette=this.doInitMemoPalette()
        } else if(this.paletteType===FtPaletteEnum.CAST) {
            this.curPalette=this.doInitCastPalette()
        }   else {
            this.curPalette=this.doInitNullPalette()
        }
        // this.curPalette=this.doInitTextPalette()
        // this.curPalette=this.textPalette

        this.rightPnl!.addChild(this.curPalette)
        this.curPalette.setVisible(true)
        this.doUpdateAttrs()
     }

     
     doInitPenPalette(){
         if(this.penPalette)   {

            this.penPalette.setSelectedColor(this.paletteAttr.color)
            this.penPalette.setSelectedSize(this.paletteAttr.size)
            return this.penPalette
         }   
         
        let pnl=new FtPalettePnl()
        pnl.init()
        pnl.addPaletteEvent((e)=>{this.notiPaletteEvent(e)})
        this.penPalette=pnl
        return  pnl
     }

     doInitTextPalette(){
        if(this.textPalette)   {
            this.textPalette.setSelectedColor(this.paletteAttr.color)
            this.textPalette.setSelectedSize(this.paletteAttr.size)
           return this.textPalette as RzUiLet
        }
         
        let pnl=new FtPalettePnl()
            pnl.setSizeOn(false)
            pnl.setColorOn(true)
            pnl.setTextOn(true)
            pnl.addPaletteEvent((e:FtPaletteEvent)=>{this.notiPaletteEvent(e)})
            pnl.init()
            

        this.textPalette=pnl
        return  pnl as RzUiLet

     }

     doInitEraserPalette(){
        if(this.eraserPalette)      return this.eraserPalette
        
        
        let pnl=new FtPalettePnl()
        pnl.setSizeOn(true)
        pnl.setColorOn(false)
        pnl.setTextOn(false)
        pnl.init()
        pnl.addPaletteEvent((e)=>{this.notiPaletteEvent(e)})

        this.eraserPalette=pnl
        return  pnl

     }

     doInitUndoPalette(){
        if(this.undoPalette)      return this.undoPalette
        
        
        let pnl=new RzUiLet()
         pnl.init({cssText:'width:100%;flex-grow:1;align-self:center; justify-content:center; display:flex; flex-direction:row; ', 
            innerHTML:`<div style="display:flex; margin-top:10px; flex-direction:row; justify-content:center; background-color:#222222;  align-self:center; width:160px;">
                    <span style="color:#E2E2E2; height:32px;
                    font-family: 'Spoqa Han Sans Neo';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;">되돌리기</span></div>`})
  
        this.undoPalette=pnl
        return  pnl

     }

    doInitMemoPalette(){
        if(this.undoPalette)      return this.undoPalette
        
        let pnl=new RzUiLet()
         pnl.init({cssText:'width:100%;flex-grow:1;align-self:center; justify-content:center; display:flex; flex-direction:row; ', 
            innerHTML:`<div style="display:flex;   flex-direction:row; justify-content:center; border-radius: 5px; background-color:#222222;  align-self:center; width:480px;">
                    <span style="color:#E2E2E2; height:32px;  margin-top:8px; 
                    font-family: 'Spoqa Han Sans Neo';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;">메모 할 곳을 포인트하면 메모를 작성할 수 있습니다.</span></div>`})
  
        this.undoPalette=pnl
        return  pnl
    }

    doInitCastPalette(){
        if(this.castPalette)      return this.castPalette
        

        let pnl= new FtCastPalettePnl()
        
        
        pnl.setMyCastOn(this.isMyCastOn);
        pnl.onMyCastOn=(ev:FtMyCastEvent)=>{
            const ne:FtPaletteEvent= {
                cmd:FtPaletteEventEnum.CAST,
                castType:ev.userType,
                isMyCastOn:ev.isMyCastOn,
                isOtherCastOn:ev.isOtherCastOn,
            };
            rzlog.debug("FtCastPalette.onMyCastOn : notiPaletteEvent > e=",ne)
            this.notiPaletteEvent(ne)
        }
        rzlog.debug('FtClassUi.Top (doLoadClassInfo) : setCastRepo -rp=',this.castRepo)
        pnl.setCastRepo(this.castRepo!);
        pnl.init();

        this.castPalette=pnl

        return  pnl
    }




     doInitNullPalette(){
        if(this.nullPalette)      return this.nullPalette
        
        let pnl=new RzUiLet()
        pnl.init()
  
        this.nullPalette=pnl
        return  pnl
     }


     events:any[]=[]
     addPaletteEvent(fn:RzOnFn,pr?:any){
         RzEventNotier.AddOnEvent(this.events,fn,pr)
     }

     notiPaletteEvent(e:FtPaletteEvent){
        if(rzIs.d) rzlog.debug('FtClassTop.notiPaletteEvent :e=',e)
        RzEventNotier.NotiEvent(this.events,e)
     }

     delPalletEvent(fn:RzOnFn){
        RzEventNotier.DelOnEvent(this.events,fn)
     }

}//class

/*********************************/
// interface FtPaletteAttr {
//     type : string;
//     size : number;
//     color: string;
//     font : string;
//     fontSize: number;
// };

class FtPalettePnl extends RzUiLet {
    isColorOn=true
    isTextOn=false
    isSizeOn=true
   
    
    selectedSize?:FtPenBtn;
    selectedColor?:FtPenBtn;

    fontSelLet?:RzUiLet
    fontSizeSelLet?:RzUiLet
    

    //attrs :FtPaletteAttr =null;

    setSizeOn(b:boolean){
        this.isSizeOn=b
    }

    setTextOn(b:boolean){
        this.isTextOn=b
    }


    setColorOn(b:boolean){
        this.isColorOn=b
    }

    doPreOpts(opt:RzUiOpts){
        return {...opt, 
            cssText:`display:flex; height:90%; 
                    flex-direction:row; 
                    align-items:center;${isDbgUi?'border: 1px solid orange;':''}`}
    }

    doInit(opt:RzUiOpts){
        if(this.isTextOn) this.doInitText(opt)
        if(this.isSizeOn) this.doInitSizes(opt)
        if(this.isColorOn) this.doInitColors(opt)

        return true
    }

    setSelectedColor(clr:string){
        let tclr:string=(clr==="black")?"#020202":clr
        //tclr=(tclr==="")?"":tclr

        const vs=this.colorButtons.filter(t=> t.color===tclr)
        if(vs.length>0)vs[0].setSelected(true)
    }
    unselectAll(){
        this.colorButtons.forEach(t=>t.setSelected(false))
        this.sizeButtons.forEach(t=>t.setSelected(false))
    }

    selectedSizeBtn?:FtPenBtn;
    setSelectedSize(size:number){


        if(this.selectedSizeBtn){
            this.selectedSizeBtn.setSelected(false)
            this.selectedSizeBtn=undefined;
        }

        const vs=this.sizeButtons.filter(t=> t.penSize===size)
        if(vs.length>0){
            this.selectedSizeBtn= vs[0]
            this.selectedSizeBtn.setSelected(true)
        }
    }

    setSelectedFont(font:string){
        // const vs=this.fontButtons.filter(t=> t.palSize===size)
        // if(vs.length>0)vs[0].onClick()
    }

    listFonts() {
        let { fonts } = document;
        const it = fonts.entries();
        if(rzIs.d) rzlog.debug('font.entires=',it)
        let arr = [];
        let done = false;
      
        while (!done) {
          const font = it.next();
          if(rzIs.d) rzlog.debug('font.value=',font)
          if (!font.done) {
            arr.push(font.value[0].family);
            
          } else {
            done = font.done;
          }
        }
      
        // converted to set then arr to filter repetitive values
        //return [...new Set(arr)];
        return FT_PALETTE_FONT_LISTS
    }

    doInitText(opt:any){
        let flist=this.listFonts()
        let pbtn=new RzBtnLet()
            pbtn.setTitle('폰트')
            pbtn.setParent(this)
            pbtn.init({cssText:`min-width:42px;height:38px; 
                    background-color:#333333; margin-right: 5px; 
                    border-width:0;
                    color:#e2e2e2;
                    font-family: 'Spoqa Han Sans Neo';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 18px ;`})


        let fontOpts=flist.map(t=>`<option value=${t.val}>${t.name}</option>`).join('')
        if(rzIs.d) rzlog.debug('FontOpts=',flist)
        
        let txt1=new RzUiLet()
            txt1.setParent(this)
            txt1.init({tag:'select',cssText:`width:200px;height:38px; `,
                innerHTML:fontOpts})

            txt1.nativeDiv!.onchange=(e)=>{ this.doOnChange(e);}

            this.fontSelLet=txt1;

        pbtn=new RzBtnLet()
            pbtn.setTitle('크기')
            pbtn.setParent(this)

            pbtn.init({cssText:`min-width:46px;height:38px; padding-left:10px;
                    background-color:#333333;
                    border-width:0;
                    color:#e2e2e2;
                    font-family: 'Spoqa Han Sans Neo';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 18px; `})

        let szlist=DEF_FONT_SIZES
        let sizeOpts=szlist.map(t=>`<option value="${t}" ${t===DEF_FONT_SIZE?'selected':''} >${t}</option>`).join('')
        let txt=new RzUiLet()
            txt.setParent(this)
            txt.init({tag:'select',cssText:`width:93px;height:38px; `,
                    innerHTML:sizeOpts})
            txt.nativeDiv!.onchange=(e)=>{ this.doOnChange(e);         }
        this.fontSizeSelLet=txt
    }

    doOnChange(e:Event){

        let navFont : HTMLSelectElement =  this.fontSelLet!.nativeDiv! as HTMLSelectElement
        let navFontSize : HTMLSelectElement =  this.fontSizeSelLet!.nativeDiv! as HTMLSelectElement
        rzlog.debug('FtTextPalette.fontsize.onchange:',e,',txt.value=',navFont.value)


        this.notiPaletteEvent({cmd:FtPaletteEventEnum.FONT,font:navFont.value, fontSize:parseInt(navFontSize.value)})

    }

    sizeButtons:FtPenBtn[]=[]
    doInitSizes(opt:any){
        let pbtn=new RzBtnLet()
            pbtn.setTitle('크기')
            pbtn.setParent(this)
            pbtn.init({cssText:`width:46px;height:38px; 
                    background-color:#333333;
                    border-width:0;
                    min-width:46px;
                    color:#e2e2e2;
                    font-family: 'Spoqa Han Sans Neo';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 18px; `})
                    

            this.sizeButtons=[]
            let sizes=_FT_PALETTE_BTN_SIZES
            let penSizes=FT_PALETTE_SIZES
            for(let i=0;i<sizes.length;i++){

                let sz = sizes[i];
                let penSz = penSizes[i];
                let ppbtn:FtPenBtn=new FtPenBtn();
                ppbtn.setSizeProp(sz);
                ppbtn.setParent(this);
                ppbtn.setPenSize(penSz);
                ppbtn.init();

                if(penSz===_defPenSize){
                    this.selectedSize=ppbtn;
                }

                (ppbtn.nativeDiv! as any).palSize=penSz;
                (ppbtn.nativeDiv! as any).srcObj=ppbtn;
                ppbtn.onClick=(e:Event)=>{
                    //if(rzIs.d)
                     rzlog.debug('BUTN CLICK:',e)
                     let el : any =e.target;
                    if(!el.srcObj)el=el.parentNode;
                
                    if(el.srcObj.parent.selectedSize)
                        el.srcObj.parent.selectedSize.setSelected(false)

                    el.srcObj.parent.selectedSize=el.srcObj
                    el.srcObj.setSelected(true)
                    this.notiPaletteEvent({cmd:FtPaletteEventEnum.SIZE,size:el.palSize})
                 }
                 this.sizeButtons.push(ppbtn)
            }//for
    }
    
    colorButtons:FtPenBtn[]=[]
    doInitColors(opt:any){
        let pbtn=new RzBtnLet()
            pbtn.setTitle('색상')
            pbtn.setParent(this)
            pbtn.init({cssText:` margin-left:24px;
                    width:46px;height:38px; 
                    min-width:46px;
                    background-color:#333333;
                    border-width:0;
                    color:#e2e2e2;
                    font-family: 'Spoqa Han Sans Neo';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 18px; margin-right:6px;`})


            this.colorButtons=[]
            let colors=FT_PALETTE_DEF_COLORS
                        
            for(let i=0;i<colors.length;i++){

                let pbtn=new FtPenBtn();
                pbtn.setParent(this);
                pbtn.setColorProp(colors[i]);
                pbtn.init();
                //pbtn.init({   innerHTML:this.makeColorInner(colors[i])})

                let clr=colors[i]
                if(_defPenColor===clr) {
                    this.selectedColor=pbtn;
                    
                    pbtn.setSelected(true)
                }
                

                (pbtn.nativeDiv as any).palColor=clr;
                (pbtn.nativeDiv as any).srcObj=pbtn;
                pbtn.onClick=(e:Event)=>{
                    let el:any=e.target;
                    if(!el.srcObj)el=el.parentNode

                    if(el.srcObj.parent.selectedColor)
                        el.srcObj.parent.selectedColor.setSelected(false)

                    el.srcObj.parent.selectedColor=el.srcObj
                    el.srcObj.setSelected(true)

                    this.notiPaletteEvent({cmd:FtPaletteEventEnum.COLOR,color:el.palColor}) 
                }
                this.colorButtons.push(pbtn);
            }//for
    }

    events=[]
    notiPaletteEvent(e:FtPaletteEvent){    
        if(rzIs.d) rzlog.debug('FtPalettePnl.notiPaletteEvent : e=',e)
        RzEventNotier.NotiEvent(this.events,e)
    }

    addPaletteEvent(fn:RzOnFn,pr?:any){
        RzEventNotier.AddOnEvent(this.events,fn,pr)
    }

    delPalletEvent(fn:RzOnFn){
        RzEventNotier.DelOnEvent(this.events,fn)
    }




    makeSizeInner(size:number,selected:boolean){
        let selCss=(!selected?'':` border: 1px solid white; 
                            padding: 1px;`)
        return `<div style=" width: ${size}px; height: ${size}px; border: 1px solid white; border-radius: 50%; 
                            display:flex; justify-content:center; align-items:center;">
                        <div style="  width: ${size-4}px; height: ${size-4}px; 
                        
                            ${selCss}
                        border-radius: 50%;
                        background: #EBEBEB;"/></div>`
    }

    makeColorInner(color:string,selected:boolean){
        let selCss=(!selected?'border-width:0;':` border: 1px solid red; 
                            padding: 2px;`)
        return `<div style="  
                        width: 20px; 
                        height: 20px; 
                        ${selCss}
                        border-radius:50%;
                        background: ${color};"></div>`
    }


}

  class FtPenBtn extends RzBtnLet {
    selected=false
    color?:string
    size?:number
    inBtn?:RzUiLet 
    penSize?:number


    setSelected(b:boolean){
        this.selected=b
        if(rzIs.d) rzlog.trace('FtPenBtn.setSelected: b=',b, ',navDiv=',this.nativeDiv)

        if(!this.nativeDiv) return;
        
        if(this.selected) this.inBtn!.nativeDiv!.style.border=`2px solid #8FC9FF`
        else  this.inBtn!.nativeDiv!.style.borderWidth='0px'
    }

    setColorProp(color:string){
        this.color=color
    }

    setSizeProp(size:number){
        this.size=size
    }

    setPenSize(size:number){
        this.penSize=size
    }

    doPreOpts(opts:RzUiOpts){
//border-width:2px;
        opts.cssText=(opts.cssText?opts.cssText:'')+`
            width: 38px; height:38px;border-width:0px;
            background-color: #333333;
            display:flex; 
            align-items:center;  justify-content:center; 
            cursor:pointer;`

        return super.doPreOpts(opts)
    }
    
    doInit(opts:RzUiOpts){
        let bt=new RzUiLet()
        let selCss=(this.selected)?'border: 2px solid #8FC9FF;':'border-width:0px;'

 

        if(this.size){
            let css=`width: ${this.size}px; height: ${this.size}px;  border-radius: 50%;  ${selCss}
                    display:flex; justify-content:center; align-items:center;`
            let html=`<div style="  width: ${this.size-4}px; height: ${this.size-4}px; border-radius: 50%;
                    background: #EBEBEB;"/>`
            bt.init({parent:this, cssText: css, innerHTML: html})
        }
        if(this.color){

            let css=`width: 22px; height: 22px; border-radius: 50%;  ${selCss}
                    display:flex; justify-content:center; align-items:center;`
            let html=`<div style="  width: 18px; height: 18px; 
                    border-radius:50%;
                    background: ${this.color};"></div>`

            bt.init({parent:this,cssText:css,innerHTML:html})
        }


        this.nativeDiv!.onclick=(e)=>{ 
            rzlog.debug('PEN.BTN.1 : this=',this,'e=',e)
            e.preventDefault()

            let ne = {target:this.nativeDiv!}

            if(this.onClick)this.onClick( ne )
        }
        this.inBtn=bt
        return true
    }


 
}//class

/**************
 * 
 */
export interface FtMyCastEvent {
    userType:string;
    isMyCastOn:boolean;
    isOtherCastOn:boolean;
}


/********** */
 class FtCastPalettePnl extends RzUiLet {
    castRepo?:FtCastRepo;
 

    ownerCheckEl?:HTMLInputElement;
    otherCheckEl?:HTMLInputElement;
    castMsgEl?:HTMLSpanElement;
    userType?:string;

    onMyCastOn?:(e:FtMyCastEvent)=>void;
    
    setCastRepo(repo:FtCastRepo){
        if(this.castRepo) this.castRepo.delOnEditLock(this.onCastEventFromRepo)
        this.castRepo=repo;

        
        let ui=repo.getUserInfo()
        if(ui)this.userType=ui.userType;
        rzlog.debug('FtClassUi.Top.setCastRepo(doLoadClassInfo) : userInfo=',ui)
        if(repo)repo.addOnEditLock(this.onCastEventFromRepo)
    }

    onCastEventFromRepo=(e:FtCastEvent)=>{
        const cmd=e.cmd;
        const el=e.editLock;
        rzlog.debug('FtClassUi.Top.onCastOn : el=',el)

        if(cmd===FtCastEventEnum.CHANGED){
         
            this.doUpdateMsg();
        } else if(cmd===FtCastEventEnum.LOCK) {
            if(el&&this.ownerCheckEl) this.ownerCheckEl.checked=el.isOwnerLock!
            if(el&&this.otherCheckEl) this.otherCheckEl.checked=el.isOtherLock!
        }

    }


    setMyCastOn(b:boolean,tb?:boolean){
      
        if(this.ownerCheckEl) this.ownerCheckEl.checked=b;
        if(this.otherCheckEl) this.otherCheckEl.checked= tb!==undefined?tb : false

        this.doUpdateMsg()
    }

    doUpdateMsg(){
        if(this.nativeDiv){
            const { myChecked,otherChecked, msg}=this.doGetStatAndMsg()
            if(this.castMsgEl)this.castMsgEl.textContent=msg
        }
    }


    doGetStatAndMsg(){
        let msg="학생이 '내필기보기'에 고정되어 있습니다"
        const ui=this.castRepo?.getUserInfo();
        const userType=ui?.userType; 
        const editLock=this.castRepo?.getEditLock()
        //this.userType=userType;

        rzlog.debug("FtClassTop(doLoadClassInfo): UserType=",this.userType,',ui=',ui)
        let myChecked :boolean = (editLock!==undefined) ?editLock.isOwnerLock! : true
        let otherChecked :boolean = (editLock!==undefined)?editLock.isOtherLock! : false; 

        if(this.ownerCheckEl ) myChecked=this.ownerCheckEl.checked
        if(this.otherCheckEl ) otherChecked=this.otherCheckEl.checked

        if(userType===FtoUserTypeEnum.STUDENT){
            if(otherChecked) msg="선생님이 [학생필기 따라가기]기능을 켜면 자동으로 해제됩니다."
            else msg="기능을 켜면 선생님 필기를 따라갈 수 있습니다."
        } else {
            if(!otherChecked && ! myChecked) msg="기능을 켜면 학생의 필기를 따라가거나, 학생이 내 필기를 보도록 고정할 수 있습니다."
            else if(!myChecked && otherChecked) msg="학생이 [선생님 필기 따라가기]기능을 켜면 자동으로 해제됩니다."
            else if(myChecked && !otherChecked) msg="학생의 화면이 ‘내 필기 보기’에 고정되어있습니다."

        }
        return {myChecked:myChecked, otherChecked:otherChecked, msg:msg,userType:userType}
    }

    doPreOpts(opts: RzUiOpts): RzUiOpts {
        let pnl=this

    
        const html=this.makeHtml()
        return {...opts, 
            cssText:`width:100%; height:90%; flex-grow:1;align-items:center; justify-content:flex-start; display:flex; flex-direction:row; ${isDbgUi?'border: 1px solid yellow;':''}`, 
            innerHTML:html

        }
    }

    makeHtml(){
        let pnl=this
        const {myChecked,otherChecked,msg,userType}=this.doGetStatAndMsg();
        rzlog.debug("FtCalssUiTop:myChaeck=",myChecked,',otherCheck=',otherChecked, ',msg=',msg)

        let html='';
        if(userType=== FtoUserTypeEnum.STUDENT)
            html=            `<div style="${isDbgUi?'border: 1px solid red;':''}">
            <span style="color:#E2E2E2; height:32px; margin-top:8px;" >선생님 필기따라하기</span>
            <label class="btlock_switch"> <input id='${pnl.id}.myCast' type="checkbox" ${myChecked?'checked':''} /> <span class="btlock_slider round"/></label>
            </div>
            <div style="margin-left:40px;">
                <span id='${pnl.id}.castMsg' style="color:#E2E2E2; height:32px;  margin-top:8px; 
                    font-family: 'Spoqa Han Sans Neo'; font-style: normal;
                    font-weight: 400; font-size: 18px;
                    line-height: 22px;">${msg}</span>
            </div>`

        else 
            html=   `
            <div style="${isDbgUi?'border: 1px solid red;':''} min-width:140px;">
            <span style="color:#E2E2E2; height:32px; margin-top:8px; " >나만 필기하기</span>
            <label class="btlock_switch"> <input id='${pnl.id}.myCast' type="checkbox" ${myChecked?'checked':''} /> <span class="btlock_slider round"/></label>
            </div>
            <div  style="margin-left:20px;  min-width:180px;">
                <span style="color:#E2E2E2; height:32px;  margin-top:8px;" >학생 필기 따라가기</span>
                <label class="btlock_switch"> <input id='${pnl.id}.otherCast' type="checkbox"  ${otherChecked?'checked':''}/> <span class="btlock_slider round"/></label>
            </div>
            <div style="margin-left:40px;">
                <span id='${pnl.id}.castMsg' style="color:#E2E2E2; height:32px;  margin-top:8px; 
                    font-family: 'Spoqa Han Sans Neo'; font-style: normal;
                    font-weight: 400; font-size: 18px;
                    line-height: 22px;">${msg}</span>
            </div>`
            
        return html;
    }

    updateUi(){
        const  html=this.makeHtml()

        if(this.nativeDiv)this.nativeDiv.innerHTML=html;
    }


    isBound:boolean=false

    onVisible=(b:boolean)=>{
        if(!b) return;

        const userType=this.castRepo?.getUserInfo()?.userType 
        rzlog.debug("VISIBI=",this.nativeDiv?.style.visibility,',this.utype=',this.userType,',utyoe=',userType)
        //if(this.nativeDiv?.style.visibility) return;
        
        if(this.userType!==userType){
           
        }
        this.updateUi();
        this.userType=userType;
        
        this.ownerCheckEl=this.getElementById('myCast') as HTMLInputElement
        if(this.ownerCheckEl && !this.ownerCheckEl.onchange){

            rzlog.debug("FtCalssTop.onVisible")
            this.isBound=true;
            this.ownerCheckEl.onchange=(e:Event)=>{
                rzlog.debug('BT1.check :changed > e=',e)
                e.preventDefault()

                let checked=(e.target as HTMLInputElement).checked;
                if(checked!==undefined){
                    if(this.otherCheckEl) this.otherCheckEl.checked=!checked;
                }
                this.doUpdateMsg()
                rzlog.debug('BT1.check :changed > checked=',checked)
                this.notiOnMyCast()
            }
        }//method



        this.otherCheckEl=this.getElementById('otherCast') as HTMLInputElement
        rzlog.debug("FtCalssTop.onVisible: other=",this.otherCheckEl);

        if(this.otherCheckEl && !this.otherCheckEl.onchange){
            
            this.otherCheckEl.onchange=(e:Event)=>{
                rzlog.debug('BT2.check :changed > e=',e)

                e.preventDefault()
                if((e.target as HTMLInputElement).checked){
                    if(this.ownerCheckEl) this.ownerCheckEl.checked=!(e.target as HTMLInputElement).checked;
                }

                this.doUpdateMsg()

                this.notiOnMyCast()
                
            }
        }

        if(!this.castMsgEl)
            this.castMsgEl=this.getElementById('castMsg') as HTMLSpanElement

        rzlog.debug("FtCalssTop.onVisible: other=",this.otherCheckEl);

    }
    
    notiOnMyCast(){
        if(!this.onMyCastOn) return;
        const userType= this.userType||FtoUserTypeEnum.STUDENT
        //this.castRepo?.getUserInfo()?.userType 
                //|| FtoUserTypeEnum.TEACHER;
        
        let isMyCastOn=this.ownerCheckEl!.checked
        let isOtherCastOn=(this.otherCheckEl)? this.otherCheckEl.checked:false;
        rzlog.debug('BT2.check :notiOnMyCast > isMyCastOn=',
                        isMyCastOn,
                        ',isOtherCastOn=',isOtherCastOn)
 

        const ne:FtMyCastEvent={userType:userType, 
            isMyCastOn: isMyCastOn,
            isOtherCastOn:isOtherCastOn,
        }

        //@this.onMyCastOn((e.target as HTMLInputElement).checked);
        this.onMyCastOn(ne);
    }
 }//class

/*************
 * 
 */

export class FtTopBtn extends RzBtnLet {
    selColor='#25A8F2'
    redSelColor='#DB0202'
    bgColor:string='#6D6D6D'
    selected=false
    pbtnSize=38
    color=''
    iconClass?:string
    popMenuLet?:RzUiLet;
    iconStyle=`margin-left:${scale(0,true,-4)}px`;

    setBtnSize(sz:number){this.pbtnSize=sz}
    setBackColor(clr:string){this.bgColor=clr}
    setIcon(iconClass: string) {
        this.iconClass = iconClass
        if (rzIs.d) rzlog.trace('FtPenBtn.changeIcon: iconClass', iconClass, ',navDiv=', this.nativeDiv)
        if (!this.nativeDiv) return;
        this.nativeDiv.innerHTML = `<i style="${this.iconStyle}" class="${this.iconClass}"></i>`;
    }
    
    doPreOpts(opts:RzUiOpts){
        let bgClr=this.selected? this.selColor:this.bgColor
        opts.cssText=`width:${this.pbtnSize}px;height:${this.pbtnSize}px;
                border-radius:50%; border-width:0px;
                margin-left:${scale(14)}px;
                background-color:${bgClr}; cursor:pointer;` +(opts.cssText?opts.cssText:'')
        opts.className='ft_top_pbtn'
        if(this.iconClass && !opts.innerHTML) opts.innerHTML=`<i style="${this.iconStyle}" class="${this.iconClass}"></i>`
        return super.doPreOpts(opts)
    }
    setSelectColor(clr:string){this.selColor=clr}
    setSelected(b:boolean){

        this.selected=b
        if(rzIs.d) rzlog.trace('FtPenBtn.setSelected: b=',b, ',navDiv=',this.nativeDiv)
        if(!this.nativeDiv) return
        let bgClr=this.selected? this.selColor:this.bgColor

        this.nativeDiv.style.cssText=this.nativeDiv.style.cssText+`background-color:${bgClr};`
    }
}
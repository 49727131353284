 
import { RzNoteShare, RzPenAttr } from "./rznote.ui"
import { rzlog, volatile}  from "./inc"

const rzlogd=rzlog.makeDefs()
export const RzNoteUiDto_setDbg=rzlogd.setDbg



/********************************
 * 
 *******************************/
export class RzoNoteInfo {
    noteId?:string
    pageNo?:number
    type?:string

    ownerId?:string;
    ownerName?:string;
    
    total?:number
    pageCount?:number
    pageWidth?:number;
    pageHeight?:number;

    refType?:string;
    refId?:string;

    constructor(pr?:any){
        Object.assign(this,pr)
    }
}

export class RzoPoint{
    x0?:number;
    y0?:number;
    x?:number;
    y?:number;
}

export class RzoNotePath {
    id?:string;
    
    type?:string;

    seq?:number;
    sesNo?:string;
    pageNo?:number;
     
    drawable?:boolean;
    isOn?:boolean;
    isSkip?:boolean;
    

    ownerId?:string;
    drawerId?:string;
    
    //@ADD
    group?:string;

    targetId?:string;
    target?: RzoNotePath;
    
    isBegin?:boolean;
    isEnd?:boolean
    attr?:RzPenAttr;
    text?: string;
    
    x?:number;
    y?:number;
    w?:number;
    h?:number;
    x0?:number;
    y0?:number;
    rotate?:number;
    isHflip?:boolean;
    isVflip?:boolean;

    points?:RzoPoint[];

    constructor(pr?:any){
        Object.assign(this,pr)
    }

}//



export class RzoPageInfo {
    noteId?:string; 
    pageNo?:number;
    type?:string; 
    subType?:string; 
}


export class RzoNoteShare implements RzNoteShare {
    constructor(pr?:Partial<RzoNoteShare>){
        Object.assign(this,pr);
    }
    id?:string;
    classId?:string;
    noteId?:string;

    //@ADD
    ownerId?:string;
    gid?:string;


    isReadOn?:boolean
    isGroupReadOn?:boolean; //false =>red icon
    isOtherReadOn?:boolean;

    isWritingReadOn?:boolean; // 내 필기보이기(my writing)
    isGroupWritingReadOn?:boolean; // 필기공유(group writing)
    isOtherWritingReadOn?:boolean;
    
    //writing permission
    isWriteOn?:boolean;  // studdent note => isWriteOn=false
    isGroupWriteOn?:boolean; // true 
    isOtherWriteOn?:boolean;


    // none sharing
    @volatile
    isMyWritingAllOn?:boolean;  // 필기 없이 보기
    @volatile
    isMyWritingReadOn?:boolean; // 나의 필기 보기(my writing)
    @volatile
    isMyGroupWritingReadOn?:boolean; // 상대방 필기 보기(group writing)
    @volatile
    isMyOtherWritingReadOn?:boolean;
}

/************** */


import { rzlog,RzRes,NewOk} from "./inc"
import { FtClassClient, FtClassTmpClient } from "./ftclass.client";
import { FtClassRtcClient } from "./ftclass.client.rtc";
import { FtClassWsClient } from "./ftclass.client.ws";
import { FtoConnInfo } from "./dto/ftclass.dto";


const rzlogd=rzlog.makeDefs(true)
export const FtClassClientFac_setDbg=rzlogd.setDbg 

/**
 * FtClassClientOpts
 */ 
 export interface FtClassClientOpts {
    type?:string;           // webrtc, wsocket , tmp
    token?:string;          // xxxxx
    url?:string   ;         // https://localhost/api/v1/
    classId?:string;        // '0'
    connInfo?:FtoConnInfo;
}//class



/********************** 
 * Factory
*/



export class FtClassClientFac {
      
    /**
     * 
     * @param {*} opt  FtClassClientOpt
     * @returns Promise(FtClassClient)
     */
    newClient(type:string,connInfo?:FtoConnInfo) {
        let r:FtClassClient|null = null;
        if(type==='ws') r = new FtClassWsClient();
        else if(type==='webrtc') r= new FtClassRtcClient();
        else r= new FtClassTmpClient();
        
        if(connInfo) r?.init(connInfo);

        return r;
    }

    static Init(opts?:FtClassClientOpts){
        if(rzlogd.d) rzlog.info("test");

        let f=new FtClassClientFac()
        let r=f.newClient("ws",opts)
        return r
    }
}//class

import { FtoClassInfo, FtoMemo } from "../dto/ftclass.dto";
import { RzFindReq, NewRsRes, RzRes, RzRsRes, RzNoteRepo, RzReq, rzlog, NewRes, NewOk, NewFail, IsFail } from "../inc";
import { RzoNoteInfo } from "../note.ui/rznote.ui.dto";
import { FtClassRepoMng } from "./ftclass.repo-mng";
import { FtClass2Event, FtClass2Repo } from "./ftclass2.repo";
import { DefMemos, DefNotes, Def_getDefClassInfo, Def_getDefRepo } from "./ftclass2.repo.default";



let _isDefOn=false;
export const FtClass2_setDefOn=(b:boolean)=>{
    _isDefOn=b;
}

/******************************
 * FtClass2MemRepo
 */

export class FtClass2MemRepo implements FtClass2Repo {
    isDefOn=false;
    constructor(isDefOn?:boolean){
        this.isDefOn=isDefOn||_isDefOn;
    }
    getName(){return 'ClassRepo4Mem'}
    
    addClassListener(id: string, cb: (e: FtClass2Event) => void): void {
        
    }

    getRepoMng(): FtClassRepoMng | null {
        return null;
     }
 

    async findMemoList(rq:RzFindReq<FtoMemo>):Promise<RzRsRes<FtoMemo>>{
        if(! this.isDefOn) return NewRsRes([]);

        let dt:FtoMemo[]=[...DefMemos];
        return NewRsRes<FtoMemo>(dt);
    }
    
    async getClassInfo(rq:RzReq<FtoClassInfo>): Promise<RzRes<FtoClassInfo>>{
        if(this.isDefOn){
            let r=Def_getDefClassInfo(rq);
            if(!IsFail(r)) return r;
        }
        return NewFail('no classInfo');
    }
   
    async  getNoteRepo(rq:RzReq<RzoNoteInfo>):Promise<RzRes<RzNoteRepo>> {

            if(! this.isDefOn) return NewFail('no noteRepo');

            rzlog.debug(">> NoteRepo getNoteRepo=:");

            let k=rq.data?.noteId;
            rzlog.debug(">> NoteRepo found=:",k);
            if(!k) return NewFail('no noteRepo');

            if(this.isDefOn){
                let r=Def_getDefRepo(k);
                if(!IsFail(r)) return r;
            }



            return NewFail("no note");
    }
}//class
import { FtSrcOpt, FtoClassSharing, FtoEditLock, FtoEtcSetting, FtoNoteInfo, FtoPage, FtoPath, FtoSetting } from "../../dto/ftclass.dto"
 

/**
 * FtDeskEventEnum
 */
export class FtDeskEventEnum {
    static PAGES='pages'
    static PAGES_ADD='pages.add'
    static PAGES_PUT='pages.put'
    static PAGES_DEL='pages.del'
    static PAGES_ON='pages.on'
    
    static PAGE='page'
    static PATH='path'
    static EDIT_LOCK='edit.lock'

    static SETTING='setting'
    static SETTING_ETC='setting.etc'

    static SHARE='share'
}

/**
 * FtDeskEvent
 */
export class FtDeskEvent {
    cmd?:string;
    pages?:FtoNoteInfo[];
    pageNo?:number;
    path?:FtoPath;
    editLock?:FtoEditLock;
    setting?:FtoSetting;
    etcSetting?:FtoEtcSetting;
    
    classSharing?:FtoClassSharing;

    srcOpt?:FtSrcOpt;

    constructor(opt:any){
        Object.assign(this,opt)
    }
} //class



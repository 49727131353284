// make annotation
 
export function validate(target: any, propertyKey: string) {

}

export function volatile(target: any, propertyKey: string) {
}

export function differ(target: any, propertyKey: string) {

}
import { rzlog,RzUiLet,scale } from "../../inc";
import { RzSplitLet,RzResizeLet }from '../../inc'
import { FtLayoutCtx, FtLayoutInit, FtLayoutOpts, FtUiLets } from "../ftclass/ftclass.ui.layout";
import { FtFullLayout } from "./ftclass.ui.layout.full";
import { FtClassSide } from "../ftclass/ftclass.ui.side";
import { FtClassChat } from "../ftclass/ftclass.ui.chat";
import { FtDeskLet } from "../ftclass/ftdesk.ui";
import { FtLayoutSubCtx } from "./ftclass.ui.layout.sub.body";
import { FtPageNoBasRepo } from "../ftclass/ftclass.ui.pop";
import { FtClassEvent, FtClassEventEnum } from "../../ftclass.event";
import { FtoEditLock, FtoEtcSetting, FtoSetting, FtoUser } from "../../dto/ftclass.dto";
 



/** dbg */
const rzIs=rzlog.makeDefs()
export const FtClassUiLayoutSubTop_setDbg =rzIs.setDbg


 

/**
 * FtSubTopInit
 */
export class FtSub2BookInit implements FtLayoutInit {
    lman:FtFullLayout;

    pageNoInit?:FtLayoutInit;

    constructor(lman:FtFullLayout){
        this.lman=lman;
        this.pageNoInit=new FtSub3PopPageInit(lman)
    }
    
    init(lctx:FtLayoutCtx,pr?:any){
        let lman=this.lman;
        let lctx2=pr as FtLayoutSubCtx
        this.doInitBook(lctx2)

        this.pageNoInit?.init(lctx)
    }

    doInitBook(lctx2:FtLayoutSubCtx){
        let lctx=lctx2.lctx;

        if(!lctx.opt.isBookOn ) return;
            let w = lctx2.bodyCnt===4? 35  : 
                    lctx2.bodyCnt===3 ? (lctx.opt.isNoteOn? 45: 70 ) : 
                    (lctx2.bodyCnt===2? (lctx.opt.isNoteOn?50:85) : 100 ) 

            let wp = lctx2.clientW*(w/100);
            
            let cssText=`display:flex; flex-direction:column; 
                    top:0px;  width:${wp}px; 
                    background-color: #333333;`

            if(lctx.lets!.book?.nativeDiv)  { 
                lctx.lets!.book.nativeDiv.style.cssText=cssText
                lctx2.sbody.addChild(lctx.lets!.book)
                lctx.lets!.book.setVisible(true)
                
                lctx.lets!.book.onResize({w:wp,h:lctx.lets!.book.nativeDiv.clientHeight})
            } else {
                let ix = (lctx.opt.isSideOn? 1:0)  + (lctx.opt.isChatOn?1:0)
                this.doInitSub2Book(lctx2.sbody,lctx.lets!.book!,ix,cssText,lctx.opt)
            }
            
            lctx2.prevWidth+=wp
    }

     
    doInitSub2Book(p:RzUiLet,bookLet:FtDeskLet,ix:number,cssText:string,opt:any){
        let lman=this.lman;

        if(lman.doUdateVisble(p,bookLet,ix,cssText)) return
        let nopt={...opt, onFocus:lman.doProcBookFocus }

        if(rzIs.d) rzlog.debug('FtFullLayout : sub2book.cssText=',cssText)
        let book=lman.subLetFac.createBookDesk(p,cssText,lman.repoMng!,nopt)
        
        lman.lets!.book=book
    }


}//class





/**
 FtSubPopPageInit
 */
 export class FtSub3PopPageInit implements FtLayoutInit {
    lman:FtFullLayout;

    constructor(lman:FtFullLayout){
        this.lman=lman;
    }
    
    init(lctx:FtLayoutCtx,pr?:any){
        this.doInitPopPage(lctx);
    }


    doInitPopPage(lctx:FtLayoutCtx){
        let lman=this.lman;

        let box=lman.bodyLet!.getBounds()
        let ttTop= box.y+56 //(lctx.opt.height!- 80)
        let ttLeft= (lctx.opt.width!-220) 
        let ttCssTxt=`left:${ttLeft}px; top:${ttTop}px;`

        if(!lctx.lets?.pagePopup){
            this.doInitPagePopup(lctx,lctx.lets.book!,lctx.lets!.pagePopup!,ttCssTxt)
        } else {
            if(lctx.pagePopupPos){
                lctx.lets.pagePopup.setPos(lctx.pagePopupPos.x,
                    lctx.pagePopupPos.y+lman.scrollPos.scrollDy);
            } else {
                lctx.lets.pagePopup.setPos(ttLeft,ttTop);
            }
        }
    }

    doInitPagePopup(lctx:FtLayoutCtx,p:RzUiLet,popLet:RzUiLet,cssText:string){
        let lman=this.lman;

        if(lman.doUdateVisble(p,popLet,0,cssText)) return
        let pop=lman.subLetFac.createPagePopup(p,cssText,lctx.repoMng!)
        lctx.lets!.pagePopup=pop

        let pgRepo=new  FtPageNoRepoRef(lman)
        pgRepo.bind()

        pop.setPageRepo(pgRepo)

     }


}//class



class FtPageNoRepoRef extends FtPageNoBasRepo {
    lman : FtFullLayout
    type?:string='book'
    noteId?:string;
    constructor(lman:FtFullLayout){
        super();
        this.lman=lman;
    }

    onClassEvent=(e:FtClassEvent)=>{ this.doProcClassEvent(e)}

    async bind(){
        this.lman.repoMng?.delOnClassEvent(this.onClassEvent)
        this.lman.repoMng!.addOnClassEvent(this.onClassEvent)
        
        rzlog.debug("FtPageNoRepoRef.bind")
        let r= await this.lman.repoMng!.getCurNoteInfo(this.type!)
        rzlog.debug("FtPageNoRepoRef.bind > repoMng.getCurNoteInfo : r=",r)

        let ni= r!.data!
        if(!ni) return;

        let r2=await this.lman.repoMng!.getCurPageNo(ni.noteId!)
        let pgNo=r2!.data!

        
        rzlog.debug("FtClassUiLayoutSubPops.FtPageNoRepoRef.bind : ni=",ni)
        this.noteId=ni.noteId!;
        this.curPage=pgNo;
        this.totalPage=ni.total!;
    }

    async doProcClassEvent(e:FtClassEvent){
        rzlog.debug("FtPageNoRepoRef.doProcClassEvnet : type=",this.type,',e=',e)
        if(e.cmd===FtClassEventEnum.PAGES_PUT){
            await this.bind()
            this.doNotiChanged({cmd:'reload'})
        }
    }

    setCurPageNo(pg: number): void {
        rzlog.debug('FtClassUiLayoutSub2Book.setCurPage.0: pg=',pg,',lman.repoMng=',this.lman.repoMng)

        super.setCurPageNo(pg)
        rzlog.debug('FtClassUiLayoutSub2Book.setCurPage.1: pg=',pg,',lman.repoMng=',this.lman.repoMng)
        this.lman.repoMng?.putCurPageNo(this.noteId!,pg)

    }
  


    getUserInfo(): FtoUser | undefined {
        let r=this.lman.repoMng!.getUserInfo();
        return r
     }
     getEditLock(): FtoEditLock | undefined {
        let r=this.lman.repoMng!.getEditLock();
         return r;
     }
 

}//class


import EventEmitter from "events";
import {
  RtpCapabilities,
  RtpParameters,
} from "mediasoup-client/lib/RtpParameters";
import { SctpParameters } from "mediasoup-client/lib/SctpParameters";
import {
  DtlsParameters,
  IceCandidate,
  IceParameters,
} from "mediasoup-client/lib/Transport";
import {
  io,
  Socket,
  connect,
  ManagerOptions,
  SocketOptions,
} from "socket.io-client";

declare module "socket.io-client" {
  interface Socket {
    request: (type: string, data?: {}) => Promise<unknown>;
  }
}

const socketPromise = function (socket: Socket) {
  return function request(type: string, data = {}) {
    return new Promise((resolve) => {
      socket.emit("media", { action: type, data: data }, resolve);
    });
  };
};

type ActionType =
  | "getRouterRtpCapabilities"
  | "createWebRtcTransport"
  | "connectWebRtcTransport"
  | "produce"
  | "consume"
  | "restartIce"
  | "requestConsumerKeyFrame"
  | "getTransportStats"
  | "getProducerStats"
  | "getConsumerStats"
  | "getAudioProducerIds"
  | "getVideoProducerIds"
  | "producerClose"
  | "producerPause"
  | "producerResume"
  | "allProducerClose"
  | "allProducerPause"
  | "allProducerResume";

export class SignalingClient {
  // private opts: Partial<ManagerOptions & SocketOptions> = {
  private opts: any = {
    //eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MTM5NDkyOTgsImV4cCI6NDg2NzU0OTI5OCwiYXVkIjoibWVkaWEtc2VydmVyIiwiaXNzIjoiZW5vbGEtcHJvZCJ9.vCoQPSD0p_2wV0HScY_Or7dWAoYoXSnVJyXsUyhW6go
    extraHeaders: {
      authorization:
        "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MTM5NDkyOTgsImV4cCI6NDg2NzU0OTI5OCwiYXVkIjoibWVkaWEtc2VydmVyIiwiaXNzIjoiZW5vbGEtcHJvZCJ9.vCoQPSD0p_2wV0HScY_Or7dWAoYoXSnVJyXsUyhW6go",
    },
    // path: '/',
    // transports: ["websocket"],
  };
  private serverUrl: string;
  private socket: Socket | null = null;
  private roomId: string = "";
  private identificationId: string = "";
  private rtpData: RtpCapabilities | null = null;
  private onEmitter = new EventEmitter();
  private readonly OnConnectEmit = "onConnect";
  private readonly OnDisConnectEmit = "onDisconnect";
  private readonly OnErrorEmit = "onError";
  private readonly OnNewProducerEmit = "onNewProducer";
  private OnMediaPauseEmit = "onMediaPause";
  private OnMediaClientDisconnect = "onMediaClientDisconnect";
  private OnOnOffProducer = "onOnoffProducer";
  private isConnected = false;
  private _isPause: boolean = false;
  private _isRecoding: boolean = false;
  private deviceType: string = "";
  private deviceKind: string = "";
  constructor(serverUrl: string) {
    this.serverUrl = serverUrl;
  }
  public init(
    roomId: string,
    identificationId: string,
    deviceType: string,
    deviceKind: string
  ) {
    this.roomId = roomId;
    this.identificationId = identificationId;
    //this.socket = io(this.serverUrl, { ...this.opts, query: { session_id: roomId, user_id: identificationId } });
    this.deviceType = deviceType;
    this.deviceKind = deviceKind;
    this.socket = io(this.serverUrl, {
      ...this.opts,
      query: {
        session_id: roomId,
        user_id: identificationId,
        deviceType: deviceType,
        deviceKind: deviceKind,
      },
    });
    this.socket.request = socketPromise(this.socket);
    this.rtpData = null;
    this.socket.on("connect", async () => {
      console.log("YDisa", "socket connect");

      if (this.isConnected === false) {
        // this.rtpData = (await this.socket?.request('getRouterRtpCapabilities')) as RtpCapabilities;
        // this.onEmitter.emit(this.OnConnectEmit);
        // this.isConnected = true;
        this.joinRoom();
      } else {
        console.error("YDisa", "already connected");
      }
    });

    this.socket.on("joinRoomAnswer", async (data) => {
      if (this.isConnected === false) {
        this.rtpData = (await this.socket?.request(
          "getRouterRtpCapabilities"
        )) as RtpCapabilities;
        this.onEmitter.emit(this.OnConnectEmit, data);
        this.isConnected = true;
      }
    });

    this.socket.on("disconnect", () => {
      console.log("socket disconnect");
      this.onEmitter.emit(this.OnDisConnectEmit);
    });

    this.socket.on("connect_error", (error) => {
      console.error(
        "could not connect to %s%s (%s)",
        this.serverUrl,
        error.message
      );
      this.onEmitter.emit(this.OnErrorEmit, error);
    });

    this.socket.on("error", (err: any) => {
      // alert(err.message)
      console.log("YDisa", err.message);
      this.onEmitter.emit(this.OnErrorEmit, err);
    });

    this.socket.on("mediaProduce", async ({ user_id, kind }) => {
      console.log("socket new mediaProduce", user_id, kind);
      // const producerIds = kind === "video" ? await this.getVideoProducerIds() : await this.getAudioProducerIds();
      //들어온 유저의 비디오, 오디오 셋팅호출에 따라 순차적으로 프론트에서 추가를 해야하지만, 현재 구조상 구현하기에 공수가 들어가는 부분이기에
      //잠깐의 sleep으로 대체함.
      // await sleep(1000);
      this.onEmitter.emit(this.OnNewProducerEmit, { user_id, kind: kind });
    });

    this.socket.on("mediaClientDisconnect", (e: any) => {
      let { id } = e;
      console.log("YDisa", `mediaClientDisconnect : ${id}`);
      this.onEmitter.emit(this.OnMediaClientDisconnect, { id });
    });

    this.socket.on("mediaPause", async (e: any) => {
      let { user_id, kind, pause } = e;

      this.onEmitter.emit(this.OnMediaPauseEmit, { user_id, kind, pause });
    });

    this.socket.on("onoffProducer", async (e: any) => {
      let { on } = e;
      this.onEmitter.emit(this.OnOnOffProducer, { on: on });
    });
  }

  public joinRoom() {
    console.log("joinRoom");
    this.socket!.emit("joinRoom", {
      roomId: this.roomId,
      userId: this.identificationId,
      deviceType: this.deviceType,
      deviceKind: this.deviceKind,
    });
  }

  public close() {
    this.roomId = "";
    this.identificationId = "";
    this.socket?.close();
    this.isConnected = false;
  }

  public isPause() {
    return this._isPause;
  }

  public isRecoding() {
    return this._isRecoding;
  }

  public async createTransport(data: any): Promise<{
    params: {
      id: string;
      iceParameters: IceParameters;
      iceCandidates: IceCandidate[];
      dtlsParameters: DtlsParameters;
      sctpParameters: SctpParameters;
    };
    type: string;
  }> {
    return (await this.socket?.request("createWebRtcTransport", data)) as {
      params: {
        id: string;
        iceParameters: IceParameters;
        iceCandidates: IceCandidate[];
        dtlsParameters: DtlsParameters;
        sctpParameters: SctpParameters;
      };
      type: string;
    };
  }

  public async transportConnect(data: any): Promise<unknown> {
    return await this.socket?.request("connectWebRtcTransport", data);
  }

  public async produce(data: any): Promise<{ id: string }> {
    return (await this.socket?.request("produce", data)) as { id: string };
  }

  public async produceData(data: any): Promise<{ id: string }> {
    return (await this.socket?.request("produceData", data)) as { id: string };
  }

  public async resume(data: any) {
    return await this.socket?.request("producerResume", data);
  }

  public async consume(data: any): Promise<{
    id: string;
    producerId: string;
    kind: "audio" | "video" | undefined;
    rtpParameters: RtpParameters;
  }> {
    return (await this.socket?.request("consume", data)) as {
      id: string;
      producerId: string;
      kind: "audio" | "video" | undefined;
      rtpParameters: RtpParameters;
    };
  }

  public async getVideoProducerIds() {
    return (await this.socket?.request("getVideoProducerIds")) as string[];
  }

  public async getAudioProducerIds() {
    return (await this.socket?.request("getAudioProducerIds")) as string[];
  }

  public async producerPause(kind: "video" | "audio") {
    const result = await this.socket?.request("producerPause", {
      user_id: this.identificationId,
      kind: kind,
    });
    this._isPause = true;
    return result;
  }

  public async producerResume(kind: "video" | "audio") {
    const result = await this.socket?.request("producerResume", {
      user_id: this.identificationId,
      kind: kind,
    });
    this._isPause = false;
    return result;
  }

  public async startRecoding() {
    const result = await this.socket?.request("recodingStart", {
      user_id: this.identificationId,
    });
    this._isRecoding = true;
    return result;
  }

  public async endRecoding() {
    const result = await this.socket?.request("recodingEnd", {
      user_id: this.identificationId,
    });
    this._isRecoding = false;
    return result;
  }

  async getMediaRoomClients() {
    return new Promise(async (resolve) => {
      this.socket?.emit("mediaRoomClients", {}, resolve);
    });
  }

  public getRtpData() {
    return this.rtpData as RtpCapabilities;
  }
  public removeAllListeners() {
    this.onEmitter.removeAllListeners();
  }
  public onConnect(listner: (...args: any) => void) {
    return this.onEmitter.addListener(this.OnConnectEmit, listner);
  }

  public OnDisConnect(listner: (...args: []) => void) {
    return this.onEmitter.addListener(this.OnDisConnectEmit, listner);
  }

  public onError(listner: (error: any) => void) {
    return this.onEmitter.addListener(this.OnErrorEmit, listner);
  }

  public onNewProducer(
    listner: (data: { user_id: string; kind: "video" | "audio" }) => void
  ) {
    return this.onEmitter.addListener(this.OnNewProducerEmit, listner);
  }
  public onMediaPause(
    lisnter: (data: {
      user_id: string;
      kind: "video" | "audio";
      pause: boolean;
    }) => void
  ) {
    return this.onEmitter.addListener(this.OnMediaPauseEmit, lisnter);
  }

  public onMediaClientDisconnect(listner: (data: { id: string }) => void) {
    return this.onEmitter.addListener(this.OnMediaClientDisconnect, listner);
  }

  onOnOffProducer(listner: (data: any) => void) {
    return this.onEmitter.addListener(this.OnOnOffProducer, listner);
  }

  public getSocketId(): string | undefined {
    return this.socket?.id;
  }
}

import React, { ReactNode, useContext } from "react";
import { CmComponent, RzBasProp, RzBtn, RzCol, RzRow, RzTxt } from "./rzcomp";
import { FtUiContext } from "../ftclass2/ftui.context";
import { rzlog } from "../../inc";
import {
  WavleClientContext,
  WavleMediaClient,
} from "../../../wavle-media-client";
import { Kind } from "graphql";

/**********
 *
 */
interface FtCamFlatProp {
  count?: number;
  focusAt?: number;
  isVertical?: boolean;
}
interface FtCamFlatStat {
  cousumer: { nickName: string; isVideo: boolean; isAudio: boolean }[];
  camCount?: number;
  videoCount?: number;
}

export class FtCamFlat extends CmComponent<FtCamFlatProp, FtCamFlatStat> {
  constructor(pr?: any) {
    super(pr);
    this.state = {
      cousumer: [],
    };
  }

  isDbgOn = false;
  isDlgOn = false;
  isConn = false;

  render(): ReactNode {
    let ctx = this.getCtx() as FtUiContext;

    let cnt = ctx.videoCount || 1;

    let camCnt = ctx.camCount || 1;
    // let camCnt=this.state.camCount ?? 1;
    if (camCnt >= 2) cnt = camCnt;

    let cousumer = ctx.cousumer || [];
    if (this.props.count !== undefined) cnt = this.props.count;

    if (!this.isDlgOn) {
      this.isDlgOn = true;
      // alert('CamFlat cnt='+cnt);
    }

    let width = cnt > 1 ? "48%" : "100%";
    let height = cnt > 1 ? 433 : 728;
    let dbgSt = this.isDbgOn ? { border: "2px solid red" } : {};

    let lw: string | number = width;
    let rw: string | number = width;
    let lh = height;
    let rh = height;
    let rst = {};

    let focusAt = this.props.focusAt !== undefined ? this.props.focusAt : -1;
    let is1stMyOn = true;
    let is2ndMyOn = false;

    if (focusAt == 0) {
      lw = 930; //'80%';
      rw = 356;
      lh = height;
      rh = 300;
      rst = { alignItems: "flex-end" };
      is1stMyOn = true;
      is2ndMyOn = false;
    } else if (focusAt == 1) {
      lw = 930; //'80%';
      rw = 356;
      lh = height;
      rh = 300;
      rst = { alignItems: "flex-end" };
      is1stMyOn = false;
      is2ndMyOn = true;
    }

    let isVertical: boolean = this.props.isVertical ?? false;

    return (
      <RzRow
        style={
          cnt !== 1
            ? {
                justifyContent: "space-around",
                height: height,
                width: "100%",
                ...rst,
                ...dbgSt,
              }
            : {
                justifyContent: "space-around",
                minHeight: 300,
                maxHeight: 728,
                aspectRatio: "2 / 1.1",
                width: "100%",
                ...rst,
                ...dbgSt,
              }
        }
        className="onlyCamBack"
      >
        {/* {(cnt > 0)&&(<FtCamLet isMyCam={is1stMyOn} style={{width:lw, height:lh}} />)} */}
        {cnt > 0 && ctx.isLoad && (
          <FtCamLet
            className={
              isVertical
                ? cnt !== 1
                  ? focusAt !== -1
                    ? "vCamM"
                    : "vCamB"
                  : ""
                : "noVertical"
            }
            isMyCam={is1stMyOn}
            style={
              cnt !== 1
                ? focusAt !== -1
                  ? !isVertical
                    ? { width: "65%", aspectRatio: "938 / 601" }
                    : { aspectRatio: "938 / 601" }
                  : !isVertical
                  ? { width: "48%", aspectRatio: "2 / 1" }
                  : { aspectRatio: "2 / 1" }
                : { aspectRatio: "2 / 1.1" }
            }
          />
        )}
        {cnt >= 2 && ctx.isLoad && (
          <FtCamLet
            className={
              isVertical ? (focusAt !== -1 ? "vCamS" : "vCamB") : "noVertical"
            }
            isMyCam={is2ndMyOn}
            style={
              focusAt !== -1
                ? !isVertical
                  ? { width: "25%", aspectRatio: "356 / 232" }
                  : { aspectRatio: "356 / 232" }
                : !isVertical
                ? { width: "48%", aspectRatio: "2 / 1" }
                : { aspectRatio: "2 / 1" }
            }
          />
        )}
        {/* {(cnt>=2)&&(<FtCamLet  isMyCam={is2ndMyOn} style={{width:rw, height:rh}} />)} */}
      </RzRow>
    );
  }
} //class

/**********
 *  Cam
 */

interface FtCamProp extends RzBasProp {
  isMyCam?: boolean;
  userName?: string;
  client?: WavleMediaClient;
  camId?: string;
}

interface FtCamStat {
  isPresent?: boolean;
  isTabbed?: boolean;
  isMicBtmOn?: boolean;
  cousumer?: { nickName: string; isVideo: boolean; isAudio: boolean }[];
  isLoad?: boolean;
}

export function withWavleClientContext<P = {}, S = {}>(
  WrappedComponent: React.ComponentType<P & { client?: WavleMediaClient }>
) {
  return class extends React.Component<P, S> {
    render() {
      const props = this.props;
      return (
        <WavleClientContext.Consumer>
          {(client) => <WrappedComponent {...props} client={client} />}
        </WavleClientContext.Consumer>
      );
    }
  };
}
function sleep(ms: number) {
  return new Promise((r) => setTimeout(r, ms));
}

let _camCount = 1;

class FtCamLetInside extends CmComponent<FtCamProp, FtCamStat> {
  // wavleClient = useContext(WavleClientContext)
  // static contextType = WavleClientContext;
  localVideoRef;
  constructor(pr?: any) {
    super(pr);
    this.state = {
      cousumer: [],
      isLoad: false,
    };
    this.localVideoRef = React.createRef<HTMLVideoElement>();
    this.camNo = _camCount++;
  }
  camNo: number;
  isDbgOn = false;
  isMouseMove = false;
  mouseX = -1;
  mouseY = -1;

  componentDidMount(): void {
    if (!this.props.client) return;
    const hasListener = this.props.client?.getEmitter();
    let ctx = this.getCtx();
    if (hasListener) {
      const listeners = hasListener.listeners("onConnectEmit");

      if (listeners.length === 0) {
        this.props.client?.onConnect(async () => {
          console.log(
            "cam.mount.onConnect:cli=",
            this.props.client,
            ",uid=",
            this.props.userName
          );
          console.log("nonono", "1");
          if (!this.props.client) return;
          this.setClient();
          // ctx.setGlobalCtx({...ctx, isLoad:true})
        });
      } else {
        console.log("nonono", "2");
        this.setClient();
      }
    }

    if (this.props.client?.getMyClient()) {
      console.log("getMyClient");
      this.setClient();
    }
  }

  componentDidUpdate(
    prevProps: Readonly<FtCamProp>,
    prevState: Readonly<FtCamStat>,
    snapshot?: any
  ): void {
    let ctx = this.getCtx();
    if (prevState.cousumer !== this.state.cousumer) {
      console.log("cam componentDidUpdate");
      if (!this.props.client) {
        console.log("cam componentDidUpdate this.props.client no");
        return;
      }
      if (!this.state.cousumer) {
        console.log("cam componentDidUpdate this.state.cousumer no");
        return;
      }
      if (this.state.cousumer.length > 0) {
        console.log(
          "this.state.cousumer[0].nickName==>>" +
            this.state.cousumer[0].nickName
        );
        const otherNewClient = this.props.client?.createClient(
          this.state.cousumer[0].nickName
        );

        if (!otherNewClient.getStream()) {
          console.log("cam 1");
          otherNewClient?.onInit(async () => {
            // onInit 후 stream객체 셋팅 완료.
            // 해당 사용자의 stream 객체
            await sleep(1000);
            let stream = otherNewClient.getStream();
            console.log(
              "cam.otherNewClient:msdevice.oninit: videoRef=",
              this.localVideoRef.current
            );
            if (this.localVideoRef.current) {
              console.log("cam.setClient.oninit: video.stream=", stream);
              if (stream) {
                this.localVideoRef.current.srcObject = stream;
                this.localVideoRef.current.style.display = "block";
              }
            }
          });

          otherNewClient?.onStreamChange(({ stream, audioOuput }) => {
            if (this.localVideoRef.current) {
              console.log("cam.setClient.oninit: video.stream=", stream);
              if (stream) {
                this.localVideoRef.current.srcObject = stream;
                this.localVideoRef.current.style.display = "block";
              }
            }
          });
        } else {
          console.log("cam 2");
          otherNewClient?.onStreamChange(({ stream, audioOuput }) => {
            if (this.localVideoRef.current) {
              console.log("@@@@!!!!!!!!!!!!@@@@@@@@@");
              console.log("@@@@!!!!!!!!!!!!@@@@@@@@@");
              console.log("@@@@!!!!!!!!!!!!@@@@@@@@@");
              if (stream) {
                this.localVideoRef.current.srcObject = stream;
                this.localVideoRef.current.style.display = "block";
              }
            }
          });

          let stream = otherNewClient.getStream();
          if (this.localVideoRef.current) {
            console.log("cam.setClient.oninit: video.stream=", stream);
            if (stream) {
              this.localVideoRef.current.srcObject = stream;
              this.localVideoRef.current.style.display = "block";
              // setTimeout(() => {
              //     this.localVideoRef.current?.play()
              //       .then(() => {
              //         console.log("Video is playing");
              //       })
              //       .catch(error => {
              //         console.error("Video play failed", error);
              //       });
              //   }, 1000);
              // this.localVideoRef.current.play()
            }
          }
        }
      } else {
        if (this.localVideoRef.current) {
          console.log("Disconnect");
          // this.localVideoRef.current.srcObject = null;
          this.localVideoRef.current.style.display = "none";
        }
      }
    }

    if (prevProps.isMyCam !== this.props.isMyCam) {
      if (!this.props.client) return;
      if (this.props.isMyCam) {
        let my = this.props.client.getMyClient();
        let stream = my?.getStream();
        if (this.localVideoRef.current) {
          this.localVideoRef.current.srcObject = stream;
        }
      } else {
        let other = this.props.client.getOtherClients();
        if (other.length > 0) {
          let stream = other[0].getStream();
          if (this.localVideoRef.current) {
            this.localVideoRef.current.srcObject = stream;
          }
        }
      }
    }
    if (this.localVideoRef.current && this.props.isMyCam) {
      if (ctx.setting?.isCamOff) {
        this.localVideoRef.current.style.display = "none";
      } else {
        this.localVideoRef.current.style.display = "block";
      }
    }

    // if (this.localVideoRef.current !== null && ) {

    // }
  }

  async setConsumer(user_id: string, kind?: string) {
    console.log("setConsumer ==>" + user_id);
    this.setState((prevState) => {
      // 새로운 프로듀서가 이미 cousumer 배열에 있는지 확인
      const idx = prevState.cousumer?.findIndex(
        (item) => item.nickName === user_id
      );

      let newCousumerArray = [];
      // let newVideoCount = prevState.videoCount ?? 0;

      if (kind === "del") {
        if (idx !== -1) {
          newCousumerArray =
            prevState.cousumer?.filter((item) => item.nickName !== user_id) ||
            [];
          return {
            cousumer: newCousumerArray,
            camCount: newCousumerArray.length,
          };
        } else {
          return {
            ...prevState,
          };
        }
      }

      if (idx === -1) {
        // 새로운 프로듀서를 cousumer 배열에 추가
        if (!kind) {
          newCousumerArray = [
            ...(prevState.cousumer || []),
            { nickName: user_id, isVideo: true, isAudio: true },
          ];
        } else {
          newCousumerArray = [
            ...(prevState.cousumer || []),
            {
              nickName: user_id,
              isVideo: kind === "video",
              isAudio: kind === "audio",
            },
          ];
        }
        // newVideoCount += kind === "video" ? 1 : 0;
      } else {
        // 이미 있는 프로듀서의 정보를 업데이트
        newCousumerArray =
          prevState.cousumer!.map((item, index) => {
            if (index === idx) {
              return {
                ...item,
                isVideo: item.isVideo || kind === "video",
                isAudio: item.isAudio || kind === "audio",
              };
            }
            return item;
          }) || [];

        // if (kind === "video" && !prevState.cousumer![idx!].isVideo) {
        //     newVideoCount += 1;
        // }
      }

      // 상태 업데이트
      return {
        cousumer: newCousumerArray,
        camCount: newCousumerArray.length,
        // videoCount: newVideoCount,
      };
    });
  }

  async setClient() {
    let ctx = this.getCtx() as FtUiContext;

    rzlog.debug("cam.setClient:ctx.ui=", ctx.userInfo);
    /* 23.11.15 상대방 이름 추가 */
    //@let uid = ctx.repo?.getRepoMng()?.userInfo?.uid;

    let isMy = Boolean(this.props.isMyCam);

    let camClient = this.props.client;

    let cliId = isMy ? (camClient?.getMyInfo()?.id as string) : "";

    if (!isMy) {
      let ids = await camClient?.getVideoProducerIds();

      console.log("nonono ===>", ids);
      if (ids && ids.length > 0) {
        const other = ids.filter((id) => id !== camClient?.getMyInfo()?.id);
        const newC: string[] = [];
        other.forEach((id) => {
          const check = this.state.cousumer?.some((cId) => cId.nickName === id);
          if (!check) {
            newC.push(id);
          }
        });
        if (newC.length > 0) {
          console.log("other ==> ", cliId);
          cliId = newC[0];

          this.setConsumer(newC[0]);
        }
      }

      const hasListener = this.props.client?.getEmitter();
      if (hasListener) {
        let listeners = hasListener.listeners("onNewProducer");
        if (listeners.length > 0)
          hasListener?.removeAllListeners("onNewProducer");
        camClient?.onNewProducer(
          async ({
            user_id,
            kind,
          }: {
            user_id: string;
            kind: "video" | "audio";
          }) => {
            console.log("YDisa", "new!!!");
            console.log(user_id);
            console.log(kind);
            console.log("YDisa", "new!!!");
            await sleep(1000);
            this.setConsumer(user_id, kind);
          }
        );

        listeners = hasListener.listeners("onMediaClientDisconnect");
        if (listeners.length > 0)
          hasListener?.removeAllListeners("onMediaClientDisconnect");
        camClient?.onMediaClientDisconnect(async (data: { id: string }) => {
          console.log("cam onMediaClientDisconnect", data);
          camClient?.removeClient(data.id);
          this.setConsumer(data.id, "del");
        });

        listeners = hasListener.listeners("onMediaPause");
        if (listeners.length > 0)
          hasListener?.removeAllListeners("onMediaPause");
        camClient?.onMediaPause(
          async (data: {
            user_id: string;
            kind: "video" | "audio";
            pause: boolean;
          }) => {
            if (!this.state.cousumer) return;

            if (this.state.cousumer[0]!.nickName === data.user_id) {
              if (data.kind === "video") {
                if (data.pause) {
                  if (this.localVideoRef.current) {
                    this.localVideoRef.current.style.display = "none";
                  }
                } else {
                  if (this.localVideoRef.current) {
                    this.localVideoRef.current.style.display = "block";
                  }
                }
              }
            }
          }
        );

        listeners = hasListener.listeners("onOnOffProducer");
        if (listeners.length > 0)
          hasListener?.removeAllListeners("onOnOffProducer");

        camClient?.onOnOffProducer(async (data: any) => {
          console.log("data.on", data.on);

          if (data.on) {
            // setIsViewer(false);
            camClient?.reInit();
          } else {
            // setIsViewer(true);
          }
        });
      }
      // ctx.setGlobalCtx({...ctx, isOtherClient:true});
      return;
    }
    console.log("cliId====>>" + cliId);

    let msdevice = camClient?.getClient(cliId);
    let msInit = false;
    if (!msdevice) {
      msdevice = camClient?.createClient(cliId);
      msInit = true;
    }

    if (!msdevice) return;

    if (msInit) {
      console.log(
        "1 cam.setClient:msdevice.oninit: videoRef=",
        this.localVideoRef.current
      );

      msdevice.onInit(async () => {
        // await sleep(1000);
        let stream = msdevice?.getStream();
        console.log(
          "2 cam.setClient:msdevice.oninit: videoRef=",
          this.localVideoRef.current,
          stream
        );
        if (this.localVideoRef.current) {
          // let stream=msdevice?.getStream();
          console.log("cam.setClient.oninit: video.stream=", stream);
          if (stream) {
            this.localVideoRef.current.srcObject = stream;
            this.localVideoRef.current.style.display = ctx.isCam
              ? "none"
              : "block";
          }
        }
      });
    } else if (msdevice) {
      let stream = msdevice?.getStream();
      if (this.localVideoRef.current) {
        this.localVideoRef.current.srcObject = stream;
        this.localVideoRef.current.style.display = ctx.isCam ? "none" : "block";
      }
    }
  }

  render(): ReactNode {
    let ctx = this.getCtx() as FtUiContext;
    let st = this.props.style;

    //let height = 350;

    let dbgSt = this.isDbgOn ? { border: "1px solid yellow" } : {};
    let dbgBdr = this.isDbgOn ? "1" : "0";

    let isPresent = this.state.isPresent || false;
    let isTabbed = this.state.isTabbed || false; //true;
    let isMicBtmOn = this.state.isMicBtmOn || false;

    let height = {};
    let layoutType = ctx.layoutType;
    if (layoutType === "float2") {
      height = { height: 215 };
    }

    // let defSt={ justifyContent: 'space-between', marginTop: 0, backgroundColor: '#000000', alignItems: 'center',
    //          width: '100%' , ...height};
    let defSt = {
      justifyContent: "space-between",
      marginTop: 0,
      backgroundColor: "#000000",
      alignItems: "center",
    };

    let userName = "";

    /* 23.11.15 상대방 이름 추가 */
    // let uid = ctx.userInfo?.uid;
    // let attendants = ctx.repo?.getRepoMng()?.classInfoRepo?.classInfo?.attendants;
    // let username = attendants?.filter((el) => el.uid != uid)[0]?.username || ''; //오류나서 수정함.

    if (this.props.isMyCam) userName = "나";
    else if (!Boolean(this.props.isMyCam)) {
      userName = "상대";
      if (ctx.userInfo?.userType === "teacher") {
        userName = "학생";
      } else if (ctx.userInfo?.userType === "student") {
        userName = "선생님";
      }
    }

    let isCam = ctx.isCam || false;
    let isMic = ctx.isMic || false;
    return (
      <RzCol
        style={{
          ...defSt,
          ...dbgSt,
          marginBottom: 6,
          ...st,
          position: "relative",
        }}
        className={`camBack ${this.props.className}`}
        onMouseDown={(e: MouseEvent) => {
          this.mouseX = e.clientX;
          this.mouseY = e.clientY;
        }}
        onMouseMove={(e) => {
          if (e.button !== 0) return;
          this.isMouseMove = true;
        }}
        onClick={(e: MouseEvent) => {
          if (this.isMouseMove) {
            let x = e.clientX;
            let y = e.clientY;
            this.isMouseMove = false;
            let x0 = this.mouseX;
            let y0 = this.mouseY;
            this.mouseX = -1;
            this.mouseY = -1;

            if (x - x0 > 10 || x - x0 < -10 || y - y0 > 10 || y - y0 < -10) {
              return;
            }
          }
          if (userName === "나") this.doTab();
        }}
      >
        <video
          id={this.props.camId || "cam-" + this.camNo}
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            display: "block",
          }}
          // style={{ width: "100%", height: "100%", transform: this.props.isMyCam ? "scaleX(-1)" : undefined, position: "absolute", display: "none" }}
          muted={this.props.isMyCam}
          ref={this.localVideoRef}
          autoPlay
        ></video>
        <RzRow
          style={{
            height: 0,
            width: "100%",
            zIndex: 3,
            border: `${dbgBdr}px solid red`,
            justifyContent: "flex-start",
          }}
        >
          <RzTxt
            text={userName}
            textStyle={{ fontSize: 13 }}
            style={{
              minWidth: 20,
              width: "auto",
              marginTop: 20,
              marginLeft: 20,
              paddingLeft: 10,
              paddingRight: 10,
              borderRadius: 15,
              height: 20,
              backgroundColor: "rgba(72, 72, 72, 0.8)",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        </RzRow>

        <RzCol style={{ flex: "1 0 auto", justifyContent: "center" }}>
          {!isPresent && (
            <RzRow>
              <span className="ftclass2-ic-smile" />
            </RzRow>
          )}

          {isTabbed && (
            <RzRow
              style={{
                top: -80,
                width: "100%",
                position: "relative",
                height: 0,
                justifyContent: "space-between",
                border: `${dbgBdr}px solid red`,
              }}
            >
              <span
                className={`ftclass2-ic-cam-${!isCam ? "on" : "off"}`}
                onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) =>
                  this.doCam(Boolean(isCam))
                }
              />
              <span
                className={`ftclass2-ic-mic-${!isMic ? "on" : "off"}`}
                onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) =>
                  this.doMic(Boolean(isMic))
                }
              />
            </RzRow>
          )}
        </RzCol>

        <RzRow
          style={{
            justifyContent: "flex-end",
            width: "100%",
            paddingRight: 30,
            height: 0,
            border: `${dbgBdr}px solid red`,
          }}
        >
          {isMicBtmOn && (
            <RzBtn
              style={{
                position: "relative",
                top: -60,
                backgroundColor: "#EA4040",
              }}
              //onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => this.doMic(e)}
              icon={
                <span
                  className="ftclass2-ic-mic-off-btm"
                  style={{ marginLeft: 7, marginTop: 4 }}
                />
              }
            />
          )}
        </RzRow>
      </RzCol>
    );
  }

  doTab() {
    this.setState({ isTabbed: !Boolean(this.state.isTabbed) });
  }

  doCam = (isCam?: boolean) => {
    let ctx = this.getCtx() as FtUiContext;
    //this.setState({isCam: !this.state.isCam});

    if (isCam) {
      let st = { ...ctx.setting, isCamOff: false };
      this.props.client?.producerResume("video").then((_) => {
        ctx.setGlobalCtx({ ...ctx, isCam: !isCam, setting: st });
        if (this.localVideoRef.current) {
          this.localVideoRef.current.style.display = "block";
        }
        console.log(
          "onMediaPause",
          "producerResume",
          this.localVideoRef.current
        );
      });
    } else {
      let st = { ...ctx.setting, isCamOff: true };
      this.props.client?.producerPause("video").then((_) => {
        console.log("onMediaPause", "producerPause");
        if (this.localVideoRef.current) {
          this.localVideoRef.current.style.display = "none";
        }
        ctx.setGlobalCtx({ ...ctx, isCam: !isCam, setting: st });
      });
    }
  };

  doMic = (isMic?: boolean) => {
    let ctx = this.getCtx() as FtUiContext;
    // ctx.setGlobalCtx({ ...ctx, isMic: !isMic });
    //this.setState({isMic: !this.state.isMic, isMicBtmOn:!this.state.isMicBtmOn});
    if (isMic) {
      let st = { ...ctx.setting, isMicOff: false };
      this.props.client?.producerResume("audio").then((_) => {
        ctx.setGlobalCtx({ ...ctx, setting: st, isMic: !isMic });
      });
    } else {
      let st = { ...ctx.setting, isMicOff: true };
      this.props.client?.producerPause("audio").then((_) => {
        ctx.setGlobalCtx({ ...ctx, setting: st, isMic: !isMic });
      });
    }
  };
} //class

export const FtCamLet = withWavleClientContext(FtCamLetInside);

/**********
 *
 */

class WebRTCClient {
  private configuration: RTCConfiguration = {
    iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
  };
  private connection: RTCPeerConnection;
  private dataChannel: RTCDataChannel | null = null;
  private onMessage: ((message: any) => void) | null = null;

  constructor(
    private signalingSocket: WebSocket,
    onMessage: (message: any) => void,
    iceUrls?: string
  ) {
    if (iceUrls) this.configuration.iceServers = [{ urls: iceUrls }];
    this.connection = new RTCPeerConnection(this.configuration);
    this.setupSignalingHandlers();
    this.setupConnectionHandlers();
    this.onMessage = onMessage;
  }

  private setupSignalingHandlers(): void {
    this.signalingSocket.onmessage = (messageEvent) => {
      const message = JSON.parse(messageEvent.data);

      switch (message.type) {
        case "offer":
          this.handleOffer(message.offer);
          break;
        case "answer":
          this.handleAnswer(message.answer);
          break;
        case "candidate":
          this.handleCandidate(message.candidate);
          break;
        default:
          console.error("Unsupported message type:", message.type);
      }
    };
  }

  private async handleOffer(offer: RTCSessionDescriptionInit): Promise<void> {
    await this.connection.setRemoteDescription(
      new RTCSessionDescription(offer)
    );
    const answer = await this.connection.createAnswer();
    await this.connection.setLocalDescription(answer);
    this.signalingSocket.send(JSON.stringify({ type: "answer", answer }));
  }

  private async handleAnswer(answer: RTCSessionDescriptionInit): Promise<void> {
    await this.connection.setRemoteDescription(
      new RTCSessionDescription(answer)
    );
  }

  private handleCandidate(candidate: RTCIceCandidateInit): void {
    this.connection.addIceCandidate(new RTCIceCandidate(candidate));
  }

  private setupConnectionHandlers(): void {
    // Handling ICE candidates
    this.connection.onicecandidate = (event) => {
      if (event.candidate) {
        this.signalingSocket.send(
          JSON.stringify({ type: "candidate", candidate: event.candidate })
        );
      }
    };

    // Data channel
    this.connection.ondatachannel = (event) => {
      this.dataChannel = event.channel;
      this.setupDataChannelHandlers();
    };
  }

  private setupDataChannelHandlers(): void {
    if (!this.dataChannel) {
      return;
    }

    this.dataChannel.onmessage = (event) => {
      console.log("DataChannel Message:", event.data);
      if (this.onMessage) this.onMessage(event.data);
    };

    this.dataChannel.onopen = () => {
      console.log("DataChannel Open");
    };

    this.dataChannel.onclose = () => {
      console.log("DataChannel Closed");
    };
  }

  public createOffer(): void {
    this.dataChannel = this.connection.createDataChannel("channel");
    this.setupDataChannelHandlers();

    this.connection
      .createOffer()
      .then((offer) => this.connection.setLocalDescription(offer))
      .then(() =>
        this.signalingSocket.send(
          JSON.stringify({
            type: "offer",
            offer: this.connection.localDescription,
          })
        )
      )
      .catch((err) => console.error(err));
  }
}

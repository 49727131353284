import { CmComponent, RzBtn, RzCol, RzImg, RzRow, RzTxt } from "./rzcomp";
import { BothStat, FtBothFrame } from "./ftframe.bas.ui";
import { rzlog } from "../../inc";
import '../ftclass2/ftclass2.css';
import { FtBtmStat } from "./ftbtm.ui";
import { FtTopMenu, FtTopPalette, IDS_SETT_CHAT_OFF, ID_MIRROR, ID_SETTING_TOP, ID_SHARE_NOTE } from "./fttop.ui";
import { FtNotesLet } from "./ftnotes.ui";
import { FtChatFlat } from "./ftchat.ui";
import { FtCamLet } from "./ftcam.ui";
import { FtClassStat, ftMenuClickHandler } from "./ftframe.float2.ui";
import { FtCamDlg, FtChatDlg } from "./ftdlg.ui";
import { WavleMediaClient } from "../../../wavle-media-client";

 
/***************************
 * 
 */
interface FtPortProp {
    wavleClient?: WavleMediaClient;
    boundaryRef?: React.RefObject<HTMLDivElement>
}

interface FtPortStat extends FtClassStat, BothStat{
    isCamFloatOn?:boolean;
}

export class FtPortFrame  extends FtBothFrame<FtPortProp,FtPortStat> {
  
    constructor(pr?:any){
        super(pr);
        let h=window.innerHeight;
        let w=window.innerWidth;
        this.minLeft=0;
        this.state={height:h-3,width:w};              
    }

    componentDidMount(){
        super.componentDidMount();
        this.init();                
    }
    
    componentDidUpdate(){
        let ctx=this.getCtx();

        let cIsChatOn=Boolean(ctx.setting?.isChatOff);
        if(this.state.isChatOn !== cIsChatOn  ){
            this.setState({isChatOn:cIsChatOn});
        }
        if(this.state.isCamFloatOn !== ctx.isCamFloatOn){
            this.setState({isCamFloatOn:ctx.isCamFloatOn});
        }
    }
    
    init(){
        let ctx=this.getCtx();
        let cIsChatOn=!Boolean(ctx.setting?.isChatOff);
        this.setState({isChatOn:cIsChatOn});        
    }

    doDrawTop(side:string){
        if(side=='right') return this.doDrawRTop();
        return (<></>);
    }


    isDbgOn=false;
    doDrawBody(side:string){

        if(side=='right') return this.doDrawRBody();
        return (<></>);
    }



    doMenuClick(id:string){
        let ctx=this.getCtx();
        ftMenuClickHandler(id,ctx,(v)=>{ this.setState(v);},this.state);
    }


    isDlgOn=false;
    doDrawBottom(side:string){
        if(side=='right') return this.doDrawRBottom();
        return (<></>);

    }

    /***************************
     * 
 h    */
    rtopId='mainFramRTop';
    doDrawRTop(){
        let ctx=this.getCtx();
        let isCamOn=!Boolean(ctx.setting?.isCamOff);
        
        let isChatOn=true;//this.state.isChatOn;    
        let isCamFloatOn=this.state.isCamFloatOn;            
        let isChatDlgOn=this.state.isChatDlgOn;

        let topHeight=260;
        return (<RzCol style={{flex:1}}>
        <RzRow id={this.rtopId} style={{ width:'100%', height:50,  backgroundColor:'#222'}}>
            <FtTopMenu wavleClient={this.props.wavleClient} onClick={(id)=>{ this.doMenuClick(id);}}/>
        </RzRow>
        
        {/* {(ctx?.isCamFloatOn && !isChatDlgOn) && (
            <RzCol style={{width:'100%', height:159}}>
                <FtChatFlat style={{width:'100%'}} isDlgOn={isChatDlgOn} onToggle={( dlgOn)=>{
                                    //alert('111');
                                this.setState({isChatDlgOn:dlgOn});
                            }}/>
            </RzCol>
        )}         */}
         
        
            <RzRow style={{ width:'100%',  height:topHeight, paddingTop:0, paddingLeft:10, paddingRight:10, boxSizing:'border-box',
                    justifyContent:'center',   backgroundColor:'#222'}}>
                <FtCamLet isMyCam={true} style={{ flex: '1'}}/>
                <RzCol style={{width:10}}></RzCol>
                <FtCamLet style={{ flex: '1'}}/>
            </RzRow>
        
        </RzCol>);
    }

    topHeight=260;

    doDrawRBody(){
        let isChatOn=true;//this.state.isChatOn;
        return (<RzRow style={{width:'100%'}}>
                    {/* {(isChatOn)&&(
                        <RzCol style={{width:this.minLeft}}>
                            <FtChatFlat style={{width:'100%'}}/>
                        </RzCol>
                    )} */}
                    
                    {this.doDrawRBodyMain()}
            </RzRow>
        );
    }

    doDrawRBodyMain(){
        let ctx=this.getCtx();
        let ws=this.calctWidths(0);

        let btmHeight=40;
        let topHeight=50;
        let h=window.innerHeight-btmHeight-topHeight-this.topHeight;
        let w=ws.rightWidth;        

        let isChatDlgOn=this.state.isChatDlgOn;
        let isChatOn=!Boolean(ctx.setting?.isChatOff);
        let isCamFloatOn=this.state.isCamFloatOn;
        let chatMinWidth=300;
        let leftMin=(isChatOn && !isChatDlgOn)?chatMinWidth:0;

        
        let chatX=w-390;
        let chatY=h-60;
        
        return (<RzCol style={{width:w,height:h}}>
                        <RzCol style={{paddingLeft:16,height:50,backgroundColor:'#383838'}}>
                            <FtTopPalette style={{backgroundColor:'#383838',marginTop:3,height:47}} />
                        </RzCol>
                        <RzRow style={{width:w,height:h-btmHeight-10}}>
                            {(isChatOn && !isChatDlgOn) && (
                                <RzCol style={{width:chatMinWidth}}>
                                    <FtChatFlat  isVertical={true} style={{width:chatMinWidth}} onToggle={(dlgOn)=>{
                                        this.setState({isChatDlgOn:dlgOn});
                                    }} />
                                </RzCol>
                            )}
                            <RzCol>
                                
                            <FtNotesLet style={{height:(h-2),width:(w-4-leftMin) ,backgroundColor:'#505050'}}/>
                            {/* <FtNotesLet style={{height:h,width:w ,backgroundColor:'#505050'}}/> */}
                            </RzCol>
                        </RzRow>                        

                        {(isChatOn && isChatDlgOn)&&(<FtChatDlg isDlgOn={isChatDlgOn} 
                            style={{ left:chatX, top:chatY }}
                            onToggle={( dlgOn)=>{
                                this.setState({isChatDlgOn:dlgOn});
                            }} />)}
                        
                    </RzCol>
                    );
    }

    doDrawRBottom(){
        return (<FtBtmStat/>)
    }
   
};//class

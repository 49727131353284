import { CmComponent, RzBtn, RzCol, RzRow, RzTxt } from "./rzcomp";
import { FtBasFrame, FtBothStat } from "./ftframe.bas.ui";
import { FtChatFlat } from "./ftchat.ui";
import { ReactNode, createRef } from "react";
import { FtBtmStat } from "./ftbtm.ui";
import { FtTopMenu, FtTopPalette, IDS_SETT_CHAT_OFF } from "./fttop.ui";
import { FtCamDlg, FtChatDlg, FtNoteDlg } from "./ftdlg.ui";
import { FtNotesLet } from "./ftnotes.ui";
import { ftBindChatOn } from "./ftframe.main.ui";
import { FtUiContext } from "../ftclass2/ftui.context";
import { rzlog } from "../../inc";
import { FtxNote } from "../../dto/ftclass2.dto";
import { WavleMediaClient } from "../../../wavle-media-client";
 

/******************** */

export interface FtClassStat {
    isMyCamFocused?:boolean;
    isOtherCamFocused?:boolean;
    isChatDlgOn?:boolean;
    isChatOn?:boolean;

    chatDlgX?:number;
    chatDlgY?:number;
}

 
/*******************
 * 
 */

interface FtFloat2Prop {
    wavleClient?: WavleMediaClient;
    boundaryRef?: React.RefObject<HTMLDivElement>
}

interface FtFloat2Stat extends FtClassStat, FtBothStat{
    notes?:FtxNote[];
    
}


export class FtFloat2Frame extends FtBasFrame<FtFloat2Prop,FtFloat2Stat>  {

    constructor(pr:any){
        super(pr);
        this.state={ isMyCamFocused:true, isOtherCamFocused:true,isChatOn:true, isChatDlgOn:true};
    }


    componentDidMount(){
        // super.componentDidMount();
        window.addEventListener('resize',(e)=>{
            this.forceUpdate();
        })
    }

    componentDidUpdate(prevProps: Readonly<FtFloat2Prop>, prevState: Readonly<FtFloat2Stat>, snapshot?: any): void {
        let ctx=this.getCtx();
        if(ctx.notes!==this.state.notes){
            this.setState({notes:ctx.notes});
        }
    }

    doDrawTop(){
 

        let topMenuLeftWidth=600;//900
        return (<RzRow className='camPosOff' style={{ width:'100%',  backgroundColor:'#222222', justifyContent:'flex-start', zIndex:8 }}>
                       <FtTopMenu wavleClient={this.props.wavleClient} style={{width: topMenuLeftWidth, border:'0px solid red'}} onClick={(id)=>{ this.doMenuClicked(id)}}/>
                       <FtTopPalette style={{marginTop:4 ,flex:' 0 1 auto'}} className="ft2scroll"/>
                </RzRow>);
    }



    isDlgOn=false;
    chatFlatRef=createRef<FtChatFlat>();
    doDrawBody(){
        let ctx=this.getCtx();
       
        let leftOn=this.state.isMyCamFocused;
        let rightOn=this.state.isOtherCamFocused;
        let bothOn=leftOn && rightOn;
         
        let isChatDlgOn=this.state.isChatDlgOn;
        let isCamDlgOn=true;
   
        let chatW=340;
        let chatH=234;
       
        let h=window.innerHeight-40-50-10;
        let w=window.innerWidth-10;
        
        let chatY=h-390;
        let chatX=w-chatW+8;
        
        let chatDlgY=h-390;
        let chatDlgX=w-362;   

        let isCamOn=!Boolean(ctx.setting?.isCamOff);
        let isChatOn=!Boolean(ctx.setting?.isChatOff)

        rzlog.debug(">>>>> DrawBody : "+'isCamOn='+isCamOn,",ctx.camOff=",ctx.setting?.isCamOff);
        if(!this.isDlgOn &&!isCamOn){
            this.isDlgOn=true;
            //alert('isCamOn='+isCamOn);
        }

        let bodySt={width:w,height:h ,justifyContent:'space-between',border:'0px solid red'  };                

        if (ctx.TP) {
            return (
                <RzCol className="camPosOffNote" style={bodySt}   >
                        <RzCol style={{alignItems:'center',width:'100%',height:'100%',border:'0px solid green'}}>
                            <FtNotesLet style={{width:'100%',height:'100%', alignItems:'center', justifyContent:'center', border:'0px solid red'}}/>
                        </RzCol>
                </RzCol>
            )
        } //현재 태블릿으로 접속되어 있고 동시에 PC로 접속해 있는 경우

        return (<RzCol className="camPosOffNote" style={bodySt}   >
                        <RzCol style={{alignItems:'center',width:'100%',height:'100%',border:'0px solid green'}}>
                            <FtNotesLet style={{width:'100%',height:'100%', alignItems:'center', justifyContent:'center', border:'0px solid red'}}/>
                        </RzCol>
                        <RzRow style={{ position: 'absolute', top:chatY, right:32, height:0, zIndex:8 }}  >
                                {(isChatOn&& !isChatDlgOn)&&(<FtChatFlat ref={this.chatFlatRef} 
                                        style={{width:chatW,height:chatH,}}
                                        onToggle={(b)=>{
                                                if(this.chatFlatRef.current){
                                                    let r=this.chatFlatRef.current.getClientRect();
                                                    this.setState({isChatDlgOn:b,chatDlgX:r?.left, chatDlgY:r?.top});
                                                    
                                                //  alert('client Rect='+JSON.stringify(r));
                                                } else this.setState({isChatDlgOn:b});
                                        }}
                                />)}
                        </RzRow>


                        {(isCamDlgOn)&&(<FtCamDlg style={{left:10}} boundaryRef={this.props.boundaryRef} isResizable={true}/>)}
                        {(isChatOn&& isChatDlgOn)&&(<FtChatDlg 
                                style={{ top:chatDlgY, left:chatDlgX,width:338,height:403,}}
                                     isDlgOn={isChatDlgOn} onToggle={(b)=>{ 
                            
                                      this.setState({isChatDlgOn:b});
                                }}  
                         />)} 
                </RzCol>
        );
    }

    doDrawBottom(){
        return (<FtBtmStat/>);
    }

    /********* */

    doFocus(my:boolean, other:boolean){
        this.setState({isMyCamFocused:my, isOtherCamFocused:other});
    }
    /********** */

    doMenuClicked(id:string){
        let ctx=this.getCtx();
        ftMenuClickHandler(id,ctx,(v)=>{ this.setState(v);},this.state);
    }

}//class


export const ftMenuClickHandler=(id:string, ctx:FtUiContext, setState:(v:any)=>void, state:any)=>{

    if(id==IDS_SETT_CHAT_OFF){
        ftBindChatOn(ctx, setState,state);
    }
}


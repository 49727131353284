export const XAPI_LANG='lang';
export const XAPI_KEY_HEADER='x-api-key';
export const XAPI_LANG_HEADER='x-api-lang';
export const XAPI_URL=process.env['XAPI_URL'] 

export const STORAGE_SECTER_KEY='znjsxmvmfxnwktjqltm!!@@';
export const TOKEN='token';
export const USER='user';

 
 

export const FTCTX_CLIENT='client'

//const DEF_TOKEN="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InJ6YWRtaW4iLCJuYW1lIjoiYWRtaW4iLCJ1dWlkIjoiZWUwMzAwNTAtYzUzNy0xMWVjLWFmM2YtMzdjOTE5YWE3ZWYxIiwiZXhwaXJlQXQiOiIyMDIyLTA0LTI3VDA4OjA3OjM0Ljg2OVoiLCJpYXQiOjE2NTA5NjA0NTQsImV4cCI6MjUxNDg3NDA1NH0.Wi3E3TIZOtk2N342k4Ueo5dPh-8B--qO3LN9IlaLhvU";
const DEF_TOKEN="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InRlc3QxIiwiaWQiOjQsImdyb3VwcyI6W10sInJvbGVzIjpbXSwidXVpZCI6ImUyMWIwYjcwLTRhMDEtMTFlZS1hYjdiLWQ5NWMwNjQ0NWJiMSIsImlwIjoiOjpmZmZmOjE5Mi4xNjguMjUuMzAiLCJhZ2VudCI6Ik1vemlsbGEvNS4wIChXaW5kb3dzIE5UIDEwLjA7IFdpbjY0OyB4NjQpIEFwcGxlV2ViS2l0LzUzNy4zNiAoS0hUTUwsIGxpa2UgR2Vja28pIENocm9tZS8xMTYuMC4wLjAgU2FmYXJpLzUzNy4zNiIsImlhdCI6MTY5MzcwODI0NCwiZXhwIjoxNjkzNzk0NjQ0fQ.6Fwy62M3ZG9q9917oXygh0hmo9xZ3v7X75jgzhdfsLE";

//http://192.168.25.30:3000/join?classId=10083&fromUid=2&type=student&deviceType=pc&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImFkbWluU2VydmljZSIsInV1aWQiOiJlNGI5NjNkMC1iMDE5LTExZWUtYjgxNS02Nzc0YTg2YTVkNTIiLCJ1aWQiOiI2IiwiZXhwaXJlQXQiOiIyMDI2LTEwLTA2VDAwOjM5OjM1LjMwOVoiLCJpYXQiOjE3MDQ5MzM1NzUsImV4cCI6MTcwNTEwNjM3NX0.ws4ITkP3dCJFaLwwc_blVyqyaLE4yB3ksdBgI9OaYPs
//http://192.168.25.30:3000/join?classId=10083&fromUid=1&type=teacher&deviceType=pc&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImFkbWluU2VydmljZSIsInV1aWQiOiJlNGI5NjNkMC1iMDE5LTExZWUtYjgxNS02Nzc0YTg2YTVkNTIiLCJ1aWQiOiI2IiwiZXhwaXJlQXQiOiIyMDI2LTEwLTA2VDAwOjM5OjM1LjMwOVoiLCJpYXQiOjE3MDQ5MzM1NzUsImV4cCI6MTcwNTEwNjM3NX0.ws4ITkP3dCJFaLwwc_blVyqyaLE4yB3ksdBgI9OaYPs
const DEF_HOSTS:any = {
    //"192.168.25.30:3001"
    dev0:    { host:'192.168.25.30:3001', isSecure:false, isRedirect:false, token:DEF_TOKEN},
    //dev0:    { host:'ap.ftnote.rzdesk.com', isSecure:true, isRedirect:false, token:DEF_TOKEN},

    dev:    { host:"115.144.22.180:3001", isSecure:false, isRedirect:false, token:DEF_TOKEN},
    
    stg:   { host:"ftnote.rzdesk.com", isSecure:true, isRedirect:true, token:DEF_TOKEN},
    //stg:   { host:"ap.ftnote.rzdesk.com", isSecure:true, isRedirect:false, token:DEF_TOKEN},
    
    //stg:   { host:"3.38.62.251:3001", isSecure:false, isRedirect:false, token:DEF_TOKEN},
    //stg2:   { host:"13.125.209.187:3003", isSecure:true, isRedirect:true, token:DEF_TOKEN},
    //stg1:   { host:"ap.rzdesk.com:3004", isSecure:false, isRedirect:false, token:DEF_TOKEN},
    //stgx:    { host:"demo.wavle.center", isSecure:true, isRedirect:true, token:DEF_TOKEN},
    prd:    { host:"52.79.172.225:3001", isSecure:true, isRedirect:true, token:DEF_TOKEN},
};

export const FtGetEnv=(cb?:(env:string)=>void)=>{
    let tenv='stg';
    if((window as any).GetRzEnv) {
        tenv=(window as any).GetRzEnv()
        //tenv = 'stg';
        if(tenv!=='prd')  console.log('rzenv.js : env=',tenv)
    }
    let tenv2=process.env['REACT_APP_RZENV'];
    if(!tenv && tenv2){
        tenv=tenv2
    }

    let tenv3=process.env['RZENV'];
    if(!tenv && tenv3){
        tenv=tenv3
    }
    
    if (!tenv) tenv='dev' 

    if(tenv==='prd') {
        if(cb )cb(tenv)
    }
    console.log('FtGetEnv:',tenv);
 //  tenv = 'dev'
    return tenv
}


 

export function FtGetConnInfo(isRedirect?:boolean){
    let rzenv= FtGetEnv() || "stg"


    let inf:any=DEF_HOSTS[rzenv]

    let token=inf.token
    let isSec=inf.isSecure
    let tgtHost=inf.host

    let hostDef=DEF_HOSTS[rzenv];
    if(!isRedirect && hostDef.isRedirect) isRedirect=true;

    let apiPath = (isRedirect && rzenv!=='dev')? "/api":"";
   

    let gqlUrl  = (isSec?'https': 'http') + '://' + tgtHost + apiPath + "/graphql";
    let apiUrl = (isSec?'https': 'http') + '://' + tgtHost + apiPath+ "/";
    let rscUrl = (isSec?'https': 'http') + '://' + tgtHost + apiPath+ "/";
    let wsUrl   = (isSec?'wss': 'ws') + '://' + tgtHost + apiPath+ "/graphql";

    if(rzenv=='dev0'){
       rscUrl="https://ftnote.rzdesk.com/api/";
    }

    let connInfo={
        isActive:true, 
        url:gqlUrl, 
        wsUrl:wsUrl,
        apiUrl:apiUrl,
        rscUrl:rscUrl,
        linkType:'ws', 
        token:token
    }

    return connInfo
}
 
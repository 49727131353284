/**
 * 
 * 
 */

import { FtoNoteInfo, FtoPath } from "../dto/ftclass.dto";
import { FtClassInfoMutEnum, FtClassInfoMutEvent, FtClassInfoRepo } from "./ftclass.inf.repo";
import { FtClassRepoFac, FtClassStoreFac } from "./ftclass.repo.fac";
import { FtClassInfoStore, FtNotesRepoStore } from "./ftclass.repo.store";
import { FtNoteRepo } from "../ui/ftclass/ftnote.ui";
import { rzlog, RzOnFn,RzNoteRepo } from "../inc";
import { NewOk, NewFail, RzEventNotier, RzRepo, RzRes,RzCtx } from "../inc";
import { setContext } from '@apollo/client/link/context';
import { FtUiContext } from "../ui/ftclass2/ftui.context";


/************** */
const rzIs=rzlog.makeDefs()
export const FtClassRepoCmn_setDbg=rzIs.setDbg

 
/******************************************************* 
 *  FtNotesRepo
 */
export class FtNotesRepo implements RzRepo {
    notes:{[k: string]: FtNoteRepo} ={}
    notis=[]
    notiPubs=[]
 
    store?:FtNotesRepoStore
    classInfoRepo?:FtClassInfoRepo

    getClassId(){ 
        if(!this.classInfoRepo) return null
        return this.classInfoRepo?.getClassId() 
    }


    async init(ctx:RzCtx,opt?:any){
        if(this.store) await this.store.init(ctx)
        return NewOk()
    }

    close(): void {
    }

    setClassInfoRepo(repo:FtClassInfoRepo){
        this.classInfoRepo=repo
    }

    setNotesStore(store : FtNotesRepoStore ){
        this.store=store
    }

    procMutEvent(ev:FtClassInfoMutEvent){
        rzlog.debug("FtClassRepoBas.FtNotesRepo.procMutEvent : ev=",ev)

        if(ev.cmd===FtClassInfoMutEnum.NOTE_ADD){
            let ni=ev.noteInfo!;
            this.addNote(ni.noteId!,ni)
        } else if(ev.cmd===FtClassInfoMutEnum.NOTE_PUT){
            let ni=ev.noteInfo!;
            this.putNote(ni.noteId!,ni)
        } else if(ev.cmd===FtClassInfoMutEnum.NOTE_DEL){            
            let ni=ev.noteInfo!;
           this.delNote(ni.noteId!,ni)
        }
    }

    async  load(opt?:any){
        if(this.store && this.classInfoRepo){
            let r=await this.store.load(this.classInfoRepo,this,opt);
            return r 
        } 
        return NewOk()
    }
    


    async  save(opt?:any){
        if(this.store) return await this.store.save(this.classInfoRepo!,this,opt);
        return NewOk()
    }

    async loadNote(id:string,opt?:any){
        let noteRepo:FtNoteRepo=this.notes[id]
        if(!noteRepo){

            return NewFail('no repo')
        }
        //this.notes[id]=noteRepo
        //noteRepo.setStore()
        if(this.store) return await this.store.loadNote(noteRepo,opt);
        return NewOk()
    }

    async saveNote(id:string,opt?:any){
        let noteRepo=this.notes[id]
        if(this.store) return await this.store.saveNote(noteRepo,opt);
        return NewOk()
    }

    updateContext(id:string, ctx: FtUiContext) {
        let noteRepo:FtNoteRepo=this.notes[id]
        noteRepo.setContext(ctx);
    }

    getCurPageNo(noteId:string){
        return this.notes[noteId].curPage
    }
    
    setCurPage(noteId:string,pg:number){
         this.notes[noteId].curPage=pg
    }

    refreshRepos(){
        for(let k in this.notes){
            let r=this.notes[k]
            r.refresh()
        }
    }


    newNoteRepo(id:string,inf?:FtoNoteInfo,opt?:any){
        let r= new RzNoteRepo()
        if(inf) Object.assign(r,inf)
        r.noteId=id;
        rzlog.debug("FtDeskBasRepo.newNoteRepo : inf=",inf,",r=",r)
        return r
    }

    async  addNote(noteId:string, inf?:FtoNoteInfo,opt?:any){
        let repo:RzNoteRepo=this.newNoteRepo(noteId,inf,opt)
        if(inf)repo.putNoteInfo(inf)

        this.notes[noteId]=repo
        let ninf=repo.getNoteInfo()
        //@this.notiNoteMut(noteId,"add",opt)
        return NewOk(ninf)
    }

    async  putNote(noteId:string, inf:FtoNoteInfo,opt?:any){
        let repo=this.notes[noteId]
        let r=repo.putNoteInfo(inf)

        //@this.notiNoteMut(noteId,"put",opt)
        return r
    }

    async  delNote(noteId:string, opt?:any){
        let n=this.notes[noteId]
        delete this.notes[noteId]
        
        let inf=n.getNoteInfo()
        //@this.notiNoteMut(noteId,"del",opt)
        return NewOk(inf)
    }


    hasNote(id:string):boolean{
        return id in this.notes
    }

    mutNoteRepo(id:string,repo:FtNoteRepo){
        this.notes[id]=repo
    }

    getPageCount(noteId:string){
        return this.notes[noteId].getPageCount();
    }

    

    async getNoteRepo(id:string){
        let r= this.notes[id]
        let t:RzNoteRepo=r as RzNoteRepo
        if(r){
            let tc=await r.pathCount(1);
            let pgcnt=r.getPageCount();
            let tt=tc.data;
            rzlog.debug("FtClassBasRepo.getNoteRepo : id=",id,",pgcnt=",pgcnt,",r[0].pathCount=",tt)
        }
        else rzlog.debug("FtClassBasRepo.getNoteRepo : id=",id,",r",r)

        return NewOk(t)
    }

    
    async  iter(){
        return NewOk(this.notes)
    }

    size(id:string){ 
        let note=this.notes[id]
        if(!note) return 0
        return  note.pages[note.curPage].paths.length
    }

    /******** */
    // addNoti(id:string,noti:RzOnFn){
    //     RzEventNotier.AddOnEvent(this.notis,noti,id)
    //     //this.notis.push({id:id, noti:noti})
    // }

    // removeNoti(id:string,noti:RzOnFn){
    //     RzEventNotier.DelOnEvent(this.notis,noti)
    // }

    // addPubNoti(id:string,notiPub:RzOnFn){
    //     RzEventNotier.AddOnEvent(this.notiPubs,notiPub,id)
    // }

    // removePubNoti(id:string,notiPub:RzOnFn){
    //     RzEventNotier.DelOnEvent(this.notiPubs,notiPub)
    // }
    
    /**
     * 
 
     */
    // notiChanged(id:string,cmd:string,page:number,path:FtoPath,opt?:any){

    //     RzEventNotier.NotiEvent(this.notis,{ type:"changed", id:id,cmd:cmd,page:page,paths:[path]})
 

    //     if(opt?.isPub === false) return

    //     RzEventNotier.NotiEvent(this.notiPubs,{type:"changed", id:id, cmd:cmd,page:page,paths:[path]})

 
    // }

    // notiNoteMut(id:string,type:string,opt?:any){
    //     RzEventNotier.NotiEvent(this.notis,{type:type, noteId:id})
 
    //     if(opt?.isPub === false) return
    //     RzEventNotier.NotiEvent(this.notiPubs,{type:"add", noteId:id})
    // }
    /**
     * Publicsh
     * 
     */

    // pubNoteChanged(noteId:string, msg:string){
    //     if(rzIs.d) rzlog.debug('pubNoteChanged:',noteId)
    // }

    // pubClassChanged(classId:string, msg:string){

    // }


    
    // async undoPath(depth:number,pg:number,srcOpt?:any){
    //     let tpg=pg//||this.curPage
    //     return NewOk()
    // }

    // async redoPath(depth:number,pg:number,srcOpt?:any){
    //     return NewOk()
    // }

 }//class

 /******* */
 export class FtClassRepoLocFac implements FtClassRepoFac {
    //newClassInfoRepo(classId:string, store:FtClassInfoStore, pt?:any): FtClassInfoRepo 
    //newNotesRepo(infoRepo:FtClassInfoRepo,store:FtNotesRepoStore,opt?:any): FtNotesRepo
    fac?:FtClassStoreFac;

    init(fac:FtClassStoreFac){this.fac=fac;}
    newClassInfoRepo(classId:string, opt?: any): FtClassInfoRepo {
         let store=this.fac?.newInfoStore(opt)
         let r=new FtClassInfoRepo();
            r.setClassId(classId);
            if(store) r.setInfoStore(store);
         return r
    }
    newNotesRepo(infoRepo:FtClassInfoRepo,opt?: any): FtNotesRepo {
         let store=this.fac?.newNotesStore(opt)
         let r=new FtNotesRepo()
            r.setClassInfoRepo(infoRepo);
            if(store) r.setNotesStore(store);

            infoRepo.onClassInfoChanged=(ev:FtClassInfoMutEvent)=>{
                r.procMutEvent(ev);
            }

         return r
     }
}

 
 






 

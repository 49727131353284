/**
 *  S1xLecture
 */

import { rzlog } from "../../inc"

import './ftclass.ui.css'
import { FtClassTop } from "./ftclass.ui.top"
import { FtClassSide } from "./ftclass.ui.side"
import { FtDeskLet } from "./ftdesk.ui"
import { RzUiLet } from "../../inc"
import { FtClassPop, FtMenuBtn, FtPageNoPopup, FtTimePopup } from "./ftclass.ui.pop"
 
import { FtClassChat } from "./ftclass.ui.chat"
import { FtClassRepoMng } from "../../repo/ftclass.repo-mng"
import { FtClassPopMenu } from "./ftclass.ui.pop-menu"


/** */
const rzlogd=rzlog.makeDefs()
export const FtClassUiSubLetFac_setDbg=rzlogd.setDbg

/*********************************
 *  FtClassLetFac
 ********************************/
 export   class FtClassSubLetFac {

        createTop(p:RzUiLet, cssText:string, repoMng:FtClassRepoMng, opt?:any):RzUiLet{
                let ctrlBar=new FtClassTop()
                ctrlBar.setParent(p)
                ctrlBar.init({cssText:cssText})
                return ctrlBar
        }

        createSide(p:RzUiLet, cssText:string,repoMng:FtClassRepoMng, opt?:any):RzUiLet {
                let side=new FtClassSide()
                side.setParent(p)
                //if(this.bookRepo) book.setRepo(this.bookRepo)
                side.init({className:'ftclass_side',cssText:cssText})
                return side
        }
        
        createPopMenu(p:RzUiLet,cssText:string, repoMng:FtClassRepoMng, opt?:any):RzUiLet {
                let popMenu=new FtClassPopMenu()
                popMenu.setParent(p)
                //if(this.bookRepo) book.setRepo(this.bookRepo)
                popMenu.init({className:'ftclass_pop_menu',cssText:cssText})
                return popMenu
        }
 
        createChat(p:RzUiLet,cssText:string,repoMng:FtClassRepoMng,  opt?:any):RzUiLet {
                let chat=new FtClassChat()
                chat.setParent(p)
    
                chat.init({className:'ftclass_chat',cssText:cssText,...opt})

                return chat
        }
 
        createBookDesk(p:RzUiLet, cssText:string,repoMng:FtClassRepoMng, opt?:any){
                let book=new FtDeskLet()
                book.setType('book')

                book.setParent(p)
                book.init({className:'ftdesk_book',cssText:cssText,...opt})

                return book
        }

        createNoteDesk(p:RzUiLet, cssText:string,repoMng:FtClassRepoMng, opt?:any){
                let note=new FtDeskLet()
                note.setType('note')
                note.setParent(p)
                note.init({className:'ftdesk_note',cssText:cssText, ...opt})
                return note
        }

        createPopupVideo(p:RzUiLet, cssText:string,repoMng:FtClassRepoMng, opt?:any){
                let popup=new FtClassPop()
                 
                popup.setParent(p)
                popup.init({  className:'ftpopup',cssText:cssText})
                return popup
        }

        createTimePopup(p:RzUiLet, cssText:string, repoMng:FtClassRepoMng, opt?:any){
                let popup=new FtTimePopup()
                popup.setParent(p)
                popup.init({  className:'ftpopup',cssText:cssText})
                return popup
        }

        
        createPagePopup(p:RzUiLet, cssText:string, repoMng:FtClassRepoMng, opt?:any){
                let popup=new FtPageNoPopup()
                popup.setParent(p)
                popup.init({  className:'ftpop_page',cssText:cssText})
                return popup
        }



        createMenuBtn(p:RzUiLet, cssText:string, repoMng:FtClassRepoMng, opt?:any){
                let btn=new FtMenuBtn()
                btn.setParent(p)
                btn.init({  className:'ftclass_menu_btn',cssText:cssText})
                return btn
        }
}//class

 
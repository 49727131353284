import { useEffect,  useRef, useState } from "react"
import { rzlog } from "./inc"
import { FtClassTest_IsTest, FtClassTest_ProcTest } from "./test/"

export const FtNote_isTest=()=>{return FtClassTest_IsTest()}

export const FtNoteTest=(props:any)=>{

    const testDivRef= useRef<HTMLDivElement>(null)


    useEffect(()=>{
        if(!testDivRef.current) return ;
        if((testDivRef.current as any)._testPassed) return;

        rzlog.debug('FtNoteTest.init')
        FtClassTest_ProcTest(testDivRef.current);
        (testDivRef.current as any)._testPassed=true;

    },[testDivRef])

    return (<div ref={testDivRef}></div>)
}



 
import { CmComponent, RzBtn, RzCol, RzImg, RzRow, RzTxt } from "./rzcomp";
import { BothStat, FtBothFrame } from "./ftframe.bas.ui";
import { rzlog } from "../../inc";
import '../ftclass2/ftclass2.css';
import { FtBtmStat } from "./ftbtm.ui";
import { FtTopMenu, FtTopPalette, IDS_SETT_CHAT_OFF, ID_MIRROR, ID_SETTING_TOP, ID_SHARE_NOTE } from "./fttop.ui";
import { FtNotesLet } from "./ftnotes.ui";
import { FtChatFlat } from "./ftchat.ui";
import { FtCamLet } from "./ftcam.ui";
import { FtChatDlg } from "./ftdlg.ui";
import { FtUiContext } from "../ftclass2/ftui.context";
import { FtClassStat, ftMenuClickHandler } from "./ftframe.float2.ui";
import { WavleMediaClient } from "../../../wavle-media-client";


/***************************/


export const ftBindChatOn=( ctx:FtUiContext, setState: (v:any)=>void, v:any )=>{
    
    let chatOff=Boolean(ctx.setting?.isChatOff)
    let cIsChatOn=!chatOff;
    
    setState({isChatOn:cIsChatOn});
    ctx.setGlobalCtx({...ctx, setting:{...ctx.setting, isChatOff:cIsChatOn}})
}

/***************************
 * 
 */
interface FtMainProp {
    wavleClient?: WavleMediaClient;
}

interface FtMainStat extends FtClassStat, BothStat{
}

export class FtMainFrame  extends FtBothFrame<FtMainProp,FtMainStat> {
  
    constructor(pr?:any){
        super(pr);
        let h=window.innerHeight;
        let w=window.innerWidth;
        // let w=window.screen.width;
        // let h=window.screen.height;    
        this.state={height:h-3,width:w, isChatOff:false};
    }

    componentDidMount(){
        super.componentDidMount();
        let ctx=this.getCtx();
        let cIsChatOn=!Boolean(ctx.setting?.isChatOff);
        this.setState({isChatOn:cIsChatOn});
    }
    
    componentDidUpdate(prevProps: Readonly<FtMainProp>, prevState: Readonly<FtMainStat>, snapshot?: any): void {        
        let ctx=this.getCtx();
        let cIsChatOn=!Boolean(ctx.setting?.isChatOff);
        if(this.state.isChatOn !== cIsChatOn ){
            this.setState({isChatOn:cIsChatOn});
        }

        if(prevState.isChatOff !== this.state.isChatOff){
            let isChat = this.state.isChatOff ?? false;
            this.onRotate(isChat);
        }
    }

    onRotate(isChatOff:boolean) {
        let ctx=this.getCtx();
        ctx.setGlobalCtx({ ...ctx, setting:{...ctx.setting, isChatOff:isChatOff}});
    }
    
    doDrawTop(side:string){
        if(side=='right') return this.doDrawRTop();
        return (<FtTopMenu wavleClient={this.props.wavleClient} onClick={(id)=>{ this.doMenuClick(id);}}/>);
    }


    isDbgOn=false;
    doDrawBody(side:string){
        if(side=='right') return this.doDrawRBody();

        //222222
        let ctx=this.getCtx();
        let dbgSt=this.isDbgOn?{border:'1px solid red'}:{};
        let chatW=550;
        let chatH=350;


        let isCamOn=!Boolean(ctx.setting?.isCamOff);
        return (<RzCol style={{width:'100%', height:'100%', ...dbgSt, backgroundColor:'#222222', paddingLeft: 25, paddingRight: 25, 
                                alignItems:'center',gap:8}}>                            
                            <FtCamLet isMyCam={true} style={{width:'100%', aspectRatio:'2 / 1.2',   ...dbgSt, backgroundColor:'#111111'}}/>
                            <FtCamLet style={{width:'100%', aspectRatio:'2 / 1.2',   ...dbgSt, backgroundColor:'#111111'}}/>
                 </RzCol>
        )
    }



    doMenuClick(id:string){

        let ctx=this.getCtx();
        ftMenuClickHandler(id,ctx,(v)=>{ this.setState(v);},this.state);

        // if(id==IDS_SETT_CHAT_OFF){
            
        //     ftBindChatOn(ctx,(v)=>{ this.setState(v);},this.state);
        // }
            // if(id===ID_SETTING_TOP)this.doShowSettingDlg() ;
            // else if(id===ID_SHARE_NOTE)this.doShareNote() ;
            // else if(id===ID_MIRROR)this.doMirroring() ;
        //    alert('MenudId='+id);
            //this.forceUpdate();
    }


    isDlgOn=false;
    doDrawBottom(side:string){
        if(side=='right') return this.doDrawRBottom();

        let isChatDlgOn=Boolean(this.state.isChatDlgOn);
        let el=document.getElementById('btmChatCol');
        let chatX=0;
        let chatY=0;
        if(el){
            let w=el.clientWidth;
            chatX=el.offsetLeft+w-380;
            chatY=el.offsetTop - 200;
        }

        let ctx=this.getCtx();
        let chatOn=this.state.isChatOn;

        if(!chatOn){
            this.isDlgOn=true;
           // alert('ChatOn='+chatOn);
        }

        return (<>
                {(!isChatDlgOn&&chatOn)&&(<RzCol id={'btmChatCol'} style={{width:'100%',height:'100%',backgroundColor:'#000000',justifyContent:'space-between'}}>
                            <FtChatFlat  isDlgOn={isChatDlgOn} onToggle={( dlgOn)=>{
                                    //alert('111');
                                this.setState({isChatDlgOn:dlgOn});
                            }}/>     
                    </RzCol>)}
                
                    {(isChatDlgOn&&chatOn)&&(<FtChatDlg isDlgOn={isChatDlgOn} 
                            style={{ left:chatX, top:chatY }}
                            onToggle={( dlgOn)=>{
                                this.setState({isChatDlgOn:dlgOn});
                            }} />)}
                </>)
    }

    /***************************
     * 
 h    */
    rtopId='mainFramRTop';
    doDrawRTop(){
        return (<RzRow id={this.rtopId} style={{ width:'100%', paddingTop:4, height:50,  backgroundColor:'#222222'}}>
                  <FtTopPalette/>
                </RzRow>);
    }

   
    doDrawRBody(){
        let ctx=this.getCtx();
        let ws=this.calctWidths(40);

        let btmHeight=40;
        let topHeight=50;
        let h=window.innerHeight-btmHeight-topHeight-4;
        let w=ws.rightWidth;
 
        return (<RzCol style={{width:w,height:h}}><FtNotesLet style={{height:(h-2),width:(w-4) ,backgroundColor:'#505050'}}/></RzCol>);
    }

    doDrawRBottom(){
        return (<FtBtmStat/>)
    }
   
};//class

import { rzlog,IsFail ,RzEventNotier,RzBtnLet, RzUiLet,RzDlg, RzUiGetSrc,RzUiOpts, RzUi_clearChildren } from "../../inc"
import { NewOk,RzAwtTool,RzOnFn, rzPut,RzPos } from "../../inc";

import  './ftdesk.ui.top.css';
import { FtoEditLock, FtoEtcSetting, FtoNoteInfo, FtoPage, FtoSetting, FtoUser, FtoUserTypeEnum } from "../../dto/ftclass.dto";
import { FtDeskRepo } from "./ftdesk.ui.repo";
import { FT_LAYER_POP, FT_LAYER_TOOL } from "../../consts";
import { FtNoteListDlg, FtNoteTypeDlg, FtNotiDlg } from "./ftclass.ui.popdlg";
import { FtClassSamp_getDefBooks, FtClassSamp_getDefPages } from "../../ftclass.samp";
import { RzoNoteInfo } from "../../note.ui/rznote.ui.dto";
import { FtDeskEvent, FtDeskEventEnum } from "./ftdesk.ui.event";

import { FtEditLockTool, FtPermsTool } from "../../ftclass.perms";



const FT_STUDENT=FtoUserTypeEnum.STUDENT
const FT_TEACHER=FtoUserTypeEnum.TEACHER
const FT_OBSERVER=FtoUserTypeEnum.OBSERVER
/************* */

let isUiDbg=false
const rzIs=rzlog.makeDefs()
export const FtDeskUiTop_setDbg=rzIs.setDbg

/**** test   */
let isTestMode=false
export function FtDeskUiTop_setPagesTestOn(b:boolean){
    isTestMode=b;
}
let defPages=FtClassSamp_getDefPages()


let isBookTestMode=false
export function FtDeskUiTop_setBookTestOn(b:boolean){
    isBookTestMode=b;
}
let defBooks=FtClassSamp_getDefBooks()
/****************
 * 
 */
export class FtDeskTopPageOpts {
    noteId?:string
    type?:string
    title?:string

    pageNo?:number
    selected?:boolean
    visible?:boolean
    constructor(opt:any){
        Object.assign(this,opt)
    }

}

export class FtDeskTopEventEnum{
    static PAGES_ON='pages.on'
    static BOOK_ADD='book.add'
    static BOOK_ETC='book.etc'
    static PAGES_OFF='pages.off'
}

 

export interface FtDeskTopEvent {
    seq :number;
    noteId:string;
}
/**
 *  FtDeskTop
 */
 export class FtDeskTopLet extends  RzUiLet {
    topHeight:number;
    leftPnl?:RzUiLet;
    rightPnl?:RzUiLet;
    
    deskRepo?:FtDeskRepo;
    etcBtn?:RzBtnLet;

    pages?: FtoNoteInfo[]=isBookTestMode ? defBooks: []
    

    constructor(){
        super()
        this.topHeight=50
    }

    setTopHeight(h:number){this.topHeight=h}
    doPreOpts(opt:RzUiOpts){
        let css=`display:flex; flex-direction:row; 
                width:100%; height:${this.topHeight}px;`
        return { className:'ftdesk_top', cssText:css,}
    }

  
    setDeskRepo(deskRepo:FtDeskRepo){
        if(rzIs.d) rzlog.debug("FtDeskTop.setDeskRepo : pages= repo=",deskRepo)
        if(this.deskRepo) this.deskRepo.delOnDeskEvent(this.onDeskEventByRepo)
        this.deskRepo=deskRepo
        

        deskRepo.addOnEvent(this.onDeskEventByRepo)

        new Promise(async ()=>{
            await this.doLoadUnloadNotes(deskRepo)
            this.doUpdateUiSub()
        })
    }

    
    async doLoadUnloadNotes(deskRepo:FtDeskRepo){
        
        if(isTestMode){ this.pages=defPages; return;}
        
        const r=await deskRepo.getNoteInfos()
        if(IsFail(r)) return 

        const ui=   deskRepo.getUserInfo()
        if(ui?.userType){
            let editLock=deskRepo.getEditLock()
            this.editLock=editLock//ui.editLock;
            rzlog.debug('Repo.EditLock(doLoadClassInfo) = ',editLock)
            this.userType=ui!.userType
        } 


        const list=r.data!
        const unVisibles= list.filter(t=> t.selected==false)
        rzlog.debug("FtDeskUiTop.FtDeskTopLet.doLoadUnloadNotes : list=",
            ',unVis=',unVisibles)
        const vs:FtoNoteInfo[]=unVisibles.map(t=>   
                new FtoNoteInfo({title:t.title!, noteId:t.noteId!, type:t.type!, note:t})
              )
        this.pages=vs
    }

    userType?:string;
    editLock?:FtoEditLock;
    otherEditLock?:FtoEditLock;

    doSetEditLock(el:FtoEditLock){
        rzlog.debug('FtDesk.Top.setEditLock(doHasTabClickable) : new.editLock=',el,',olock=',this.otherEditLock)

        this.editLock=el
        // if(el.userType===this.userType) this.editLock=el
        // else this.otherEditLock=el;
    }

    onDeskEventByRepo=async (e:FtDeskEvent)=>{
        if(rzIs.d) rzlog.debug("FtDeskTop.onDeskEvent(unvis):e=",e)
        if(e.cmd===FtDeskEventEnum.EDIT_LOCK){
            if(e.editLock) this.doSetEditLock(e.editLock)
        }

        this.doUpdateUiSub()
        
    }

    doInit(opt:RzUiOpts){
        //this.nativeDiv!.style.height='40px';
        if(rzIs.d) rzlog.debug("FtDeskTopLet.doInit : nav=",this.nativeDiv,',opt=',opt)
        if(!this.leftPnl){
            let leftPnl= new RzUiLet()
            leftPnl.setParent(this)
            let css=`display:flex; flex-direction:row; 
                    flex-grow:1; height:100%;  
                    align-items:center; 
                    ${isUiDbg??"border:1px solid yellow;"}`
            leftPnl.init({cssText:css})//flex:1 0 100%; 
            this.leftPnl=leftPnl
        }

       

        if(!this.rightPnl){
            let rightPnl= new RzUiLet()
            rightPnl.setParent(this)
            let css=`display:flex; flex-direction:row; 
                    height:100%; justify-content:right; 
                    ${isUiDbg??"border:1px solid red;"}`
            rightPnl.init({cssText:css})
            this.rightPnl=rightPnl
        }
        

        this.doUpdateUiSub()
        return true;
    }

    reload() {
        this.doUpdateUiSub();
    }

    
    async doUpdateUiSub(){
        let hasLeft=await this.doInitTabBtns ()
        
        rzlog.debug('FtDeskTop.initTabBtns : hasLeft=',hasLeft)
        this.doInitRightPnl(hasLeft)
    }//method


    doInitRightPnl(hasLeft:boolean){
        this.rightPnl!.removeChildAll()
        let rightPnl= this.rightPnl!
       

        let rbtn:RzBtnLet|null=null
        
            if(!this.etcBtn){
                rbtn=new RzBtnLet()

                rbtn.setTitle(`<i class="ftnote-dot3-icon2"></i>`)
                rbtn.setParent(rightPnl) //width:38px;height:38px;
                rbtn.init({className:'ftdesk_top_btn',
                            cssText:'margin-right:9px;  ',
                            })
                rzPut(rbtn,"selected",false)
                
                
                rbtn.onClick=(e)=>{ 
                    let src=RzUiGetSrc(e)
                    if(!src)return

                    src.selected=!src.selected
                    this.doShowDlgOfEtc(this.etcBtn!,src.selected)
                }
                this.etcBtn=rbtn;
            
            }  else {
                this.rightPnl!.addChild(this.etcBtn)
            }

            if(!this.addBtn){
                rbtn=new RzBtnLet()
                    rbtn.setTitle(`<i class="ftnote-add2-icon"></i>`)
                    rbtn.setParent(rightPnl)
                    rbtn.init({ className:'ftdesk_top_btn',
                                cssText:'margin-right:7px;'})
                    rzPut(rbtn,"selected",false)//.selected=false     

                    const onOutClick0=(e:Event)=>{
                        let src=RzUiGetSrc(e)
                        if(src && src=== this.addBtn) return;
                        if((e.target as HTMLElement).className ==='ftclass_menu_subpopup') return;

                       // document.removeEventListener('click',onOutClick)

                        rzPut(this.addBtn,"selected",false)
                        if(this.bookSelDlg)this.bookSelDlg.setVisible(false)
                    }

                    rbtn.onClick=(e)=>{  
                        let src=RzUiGetSrc(e)
                        if(!src)return


                        this.doShowDlgOfAddBook(this.addBtn!,src.selected)
                    }

                this.addBtn=rbtn
            } else {
                this.rightPnl!.addChild(this.addBtn)
            }
      
            
     
        if(!hasLeft){ 
                rightPnl.removeChild(this.etcBtn);       
         } 
    }
 

    extraPages:FtoNoteInfo[]=[]
    selectedBtn?:RzBtnLet
    isInitTabBtns=false;
    async doInitTabBtns(){
        if(this.isInitTabBtns) return false;
        this.isInitTabBtns=true;


        if(rzIs.d) rzlog.debug('FtDeskTopLet.doInitTabBtns: pages=',
                    this.pages,',left.childs=',this.leftPnl!.children)

        this.leftPnl!.removeChildAll()
        if(rzIs.d) rzlog.debug('FtDeskTopLet.doInitTabBtns: pages= left.childs=',
                    this.leftPnl!.children)

        let hasLeft = await this.doLoadTabsByRepo()
        this.isInitTabBtns=false;
        return hasLeft
    }

    doHasTabClickable(noteInfo:FtoNoteInfo){
        //if(rzIs.d) 
        rzlog.debug('TabBtn.onclick(doLoadClassInfo): doHasTabClickable : editLock=',this.editLock,',noteInfo=',noteInfo)
        if(this.editLock){
            let uinfo= this.deskRepo!.getUserInfo()
            //if(rzIs.d) 
            rzlog.debug('TabBtn.onclick(doLoadClassInfo): doHasTabClickable : editLock.ok=',this.editLock,',ui=',uinfo)
            //return FtoEditLock.hasEditable(uinfo!,noteInfo,this.editLock)
            
            return FtEditLockTool.isEditable(uinfo!,this.editLock)
        }
        return true
    }

    async doLoadTabsByRepo(){
        let hasLeft=false
        this.extraPages=[]
       
        let w=0

        if(!this.deskRepo) return false;

        let setting=this.deskRepo!.getSetting()
        let etcSetting=this.deskRepo!.getEtcSetting()
        let uinfo= this.deskRepo!.getUserInfo()

        let tr= await this.deskRepo!.getNoteInfos()
        if(IsFail(tr)){  return hasLeft; }

        let pgs= tr.data!
        if(rzIs.d)rzlog.debug("FtDeskUiTop.doInitTabBtns : "
            +"Top.pags ====:sett="
            ,setting,',pages=',pgs,',etc=',etcSetting)


        let utype=uinfo?.userType;
 
        let cnt=0
        for(let i  in pgs){
           
            let p= pgs[i]
            if(p.visible===false) continue;
            
            if(setting&& uinfo){
                let isVisible=true
                try{
                    isVisible=this.doIsVisibleBySetting(p, uinfo, setting,etcSetting!)
                
                }catch(e){
                    rzlog.debug('Error: e=',e)
                }
                if(!isVisible) continue;
            }


            if(rzIs.d) rzlog.debug("Top.pags[",i,"]: hasLeft=",hasLeft,",p=",p)

            if(hasLeft){
                this.extraPages.push(p)
                continue
            }
            cnt++
            w=await this.doMakeTabBtn(p,i,w)
        }//for

        if(rzIs.d) rzlog.debug("Top.pages=: hasLeft=",hasLeft,",count=",cnt,",pages=",pgs)


        return hasLeft;
    }

    async doMakeTabBtn(p:FtoNoteInfo,i:string,w:number){

        let r0=this.getBounds()
        let r2=this.rightPnl!.getBounds()

        let btn=new RzBtnLet()
        btn.setTitle(`<p style="display: inline;">${p.title}</p>`)
        btn.setParent(this.leftPnl! as RzUiLet)

        let tabSelectable=true;

        let ui=this.deskRepo?.getUserInfo()
        if(this.deskRepo && ui){
            let etc=this.deskRepo.getEtcSetting()
            //let sett=this.deskRepo.getSetting()
            let el=this.deskRepo.getEditLock()
            tabSelectable =  FtEditLockTool.isTabSelectable(p,ui,el,etc)
        }

        let btnDbgCss='border-width:1px; border-color:red;';

        let clsName=p.selected?`ftdesk_top_tab_sel` : `ftdesk_top_tab`
        let css=`min-width:80px;width:auto;height:100%; ${btnDbgCss}
                display:flex; flex-direction:row;`
        if(! tabSelectable) css+='background-color:gray;';
        btn.init({  cssText:css,className:clsName})



        if(p.selected) this.selectedBtn= btn
        let tbtn:any=btn
        tbtn.param={ ...p,  seq: parseInt(i) }
        tbtn.page=p
        tbtn.deskTop=this
        
        tbtn.onClick=(e:Event)=>{ 
            if(rzIs.d)rzlog.debug('TabBtn.onclick(doLoadClassInfo): p.target=',e.target)

            let src = RzUiGetSrc(e)
            if(!src?.page) return

            const hasClick=src.deskTop.doHasTabClickable(src.page)
            rzlog.debug('FtDeskTopLet(doLoadClassInfo,EditLock).doHasTabClickable: hasClick=',hasClick)
            if(!hasClick){
                rzlog.debug('FtDeskTopLet.doHasTabClickable: SKIP ')
                alert('권한이 없습니다.')
                return 
            }


            let ui=src.deskTop?.deskRepo?.getUserInfo()
            if(ui){
                let etc=src.deskTop?.deskRepo.getEtcSetting();
                let el=src.deskTop?.deskRepo.getEditLock();
                tabSelectable =  FtEditLockTool.isTabSelectable(tbtn.page,ui,el,etc)
                if(tabSelectable===false) {
                    alert('상대방이 읽기 권한이 없습니다.')
                    return;
                }
            }


            src.page.selected=true
            src.nativeDiv.className='ftdesk_tab_sel'
       
            if(src?.xbtn?.nativeDiv){
                src.xbtn.nativeDiv.style.color='black'
            }

            if(src.deskTop.selectedBtn){
                src.deskTop.selectedBtn.page.selected=false
                src.deskTop.selectedBtn.nativeDiv.className=`ftdesk_top_tab`
            }
            src.deskTop.selectedBtn=src
            let evt={ ...src.param , cmd: FtDeskTopEventEnum.PAGES_ON}
            src.deskTop.notiOnClick(evt)
         }

         let r1=btn.getBounds()
         let pd=14
         w=w+r1.width+pd
         let leftSize=(r0.width - r2.width)
         if(rzIs.d) rzlog.debug('FtDeskTopLet.doInitTabBtns : title=',
            p.title,',r1=',r1,',leftSize=', leftSize,',w=',w,
            ',r0.w=',r0.width,',right.w=',r2.width,',r1.w=',r1.width)

         if( leftSize <  w){
             this.leftPnl!.removeChild(btn)
             this.extraPages.push(p)
         }

         //X button
         let xbtn=new RzBtnLet()
         let xcolor=p.selected?'black':'white';
         let css2=`width:16px; height:16px; margin-left:6px;
                   ${btnDbgCss}
                   background:#22222200;color:${xcolor};`
         xbtn.init({parent:btn, cssText:css2,innerHTML:'x'})
         tbtn.xbtn=xbtn;
         xbtn.onClick=async (e)=>{
            let tpr=tbtn.param
            rzlog.debug("FtDeskUiTop :  TBTN.param=",tpr)
            if(!this.deskRepo) return;
             
            let r= await this.deskRepo.getNoteInfo(tpr.noteId);
            if(IsFail(r)) return;
            let ni = r.data!

            let newSelectedNi:FtoNoteInfo|null=null
            ni.visible=false
            if(ni.selected){
                 ni.selected=false;
                 let tr1=await this.deskRepo.getNoteInfos()
                 rzlog.debug("FtDeskUiTop: XBTN , pages.tr1=",tr1)
                 if(!IsFail(tr1)){
                    if(tr1.data && tr1.data.length>0){
                        let dt=tr1.data;
                        for(let i in dt){
                            let v=dt[i]
                            rzlog.trace("FtDeskUiTop: XBTN , pages[",i,"].v=",v)
                            if((v.visible !==undefined && v.visible !==null) 
                                 && !v.visible) continue;
                            if(!v.selected) {
                                newSelectedNi=v;
                                v.selected=true;
                                break;
                            }
                        }
                    }
                    rzlog.debug("FtDeskUiTop: XBTN , pages.newSel=",newSelectedNi)
                 }
            }

            await this.deskRepo.putNoteInfo(tpr.noteId,ni)
            if(newSelectedNi){
                await this.deskRepo.putNoteInfo(newSelectedNi.noteId!,newSelectedNi)
            }

            this.setDeskRepo(this.deskRepo)

            rzlog.debug("FtDeskTop.xbtn.onClick: this=",this);
            let evt={ ...tbtn.param ,visible:false, cmd: FtDeskTopEventEnum.PAGES_OFF}
            this.notiOnClick(evt)
         }

         return w
    }

    doIsVisibleBySetting(ni :FtoNoteInfo, ui:FtoUser, 
                setting:FtoSetting, etcSetting:FtoEtcSetting){
        return FtPermsTool.isVisible(ni,ui,setting,etcSetting)
    }
    
    doAddBookItem(bklist:HTMLUListElement, bk:any) {
        let li=RzAwtTool.createElement('li')
        li.className='ftclass_book_item'
        li.innerHTML=bk.title
        let tli : any= li
        tli.item=bk
        tli.onItemClick=(bklist as any).onItemClick
        tli.onclick=(e:Event)=>{
            let ta:any=e.target
            ta.onItemClick(ta.item)
        }
        bklist.appendChild(li)
    }

    doIsClickAcceptable():boolean{
        let r=true;
        let setting=this.deskRepo!.getSetting()
        let etcSetting=this.deskRepo!.getEtcSetting()
        let uinfo =this.deskRepo!.getUserInfo()
        if(uinfo?.userType === FtoUserTypeEnum.STUDENT){
            //if(setting.)
        }
        return r;
    }

    etcBookDlg?:RzDlg
    etcList?:HTMLUListElement

    doShowDlgOfEtc(btn:RzBtnLet, selected:boolean){
        
        if(this.etcBookDlg) {   
            
            this.doLoadEtcBooksOnDlg(this.etcList!) ; 
            this.etcBookDlg.setVisible(selected); 
            return 
        }

        let pos=btn.getBounds()
        let npos={...pos, y:pos.y+30}
        let dlg=new RzDlg()
        let w=100
        dlg.init({parent:this.rightPnl, className:'ftclass_menu_subpopup', 
                cssText:`position:absolute; left:${npos.x-w}px; top:${npos.y}px; width:${w}px;  
                padding: 8px 8px 8px 8px;  
                z-index:${FT_LAYER_POP};`,
                innerHTML:`<ul id="${dlg.id}.list" class="ftclass_book_list" ></ul>`})
 
        this.etcBookDlg=dlg;

        let t  = dlg.getElementById('list')
        let bklist: HTMLUListElement = t! as HTMLUListElement

        (bklist as any).onItemClick=(it:any)=>{ 
            rzlog.debug('FtDeskTop.etcItemSelected : it=',it)
            if(this.deskRepo && !this.doIsClickAcceptable()){
                return;
            }

            let evt={ cmd: FtDeskTopEventEnum.BOOK_ETC,page:it}
            this.notiOnClick(evt)

            rzPut(this.etcBtn,"selected",false)
            this.etcBookDlg!.setVisible(false)
        }

        this.etcList=bklist
        this.doLoadEtcBooksOnDlg( this.etcList)

    }


    bookSelDlg?:FtNoteTypeDlg
    bookListDlg?:FtNoteListDlg;
   
    addBtn?:RzBtnLet
    doShowDlgOfAddBook(p:RzUiLet,selected:boolean){
        if(this.bookSelDlg) {  
            // if(! this.bookListDlg?.nativeDiv?.parentNode){
            //     p.addChild(this.bookSelDlg)
            // }
            this.bookSelDlg!.setVisible(true); 
            this.doLoadBooksOnDlg()
            //@this.bookSelDlg!.setVisible(selected); 
            
           
            return 
        }


        let yoff=60
        let pos=p.getBounds()
        let npos={...pos, y:pos.y+yoff}

        let dlg =new FtNoteTypeDlg() 
        let w=480
        let h=275
        let hgap=10
        let xgap=20
       
        let css1=`left:${npos.x-w-xgap}px; top:${npos.y+hgap}px; 
                    width:${w}px;height:${h}px;`
       
        dlg.setDeskRepo(this.deskRepo!)

        dlg.onAddNoteClicked=(type:string, ntId?:string)=>{
            dlg.setVisible(false)
            if(type==='book') this.doShowDlgOfBookList(npos)
            else this.doAddNewNote()
        };

        dlg.init({  parent:this.rightPnl,  
                    className:'ft_selpop', 
                    cssText:css1, })
        
        this.bookSelDlg=dlg;
  
        this.doLoadBooksOnDlg()
    }

    nonameCnt=1;
    async doAddNewNote(){
        if(!this.deskRepo) return;

        let ni={ type:'note',group:'book',title:'noname'+this.nonameCnt++,
                 visible:true,
            }

        let r1=await this.deskRepo!.addNoteInfo(ni)
        if(IsFail(r1)) return;
        

    }


    
    doShowDlgOfBookList(npos:RzPos){
        if(this.bookListDlg){
            this.bookListDlg.setVisible(true)
            return
        }

        let dlg=new FtNoteListDlg()

        let w=480
        let h=275
        let hgap=20
        let xgap=20
        let css1=`left:${npos.x-w-xgap}px; top:${npos.y+hgap+h}px; 
                    width:${w}px;`//height:${h}px;
        dlg.onItemSelected=(  noteId:string):void=>{
            rzlog.debug("FtDeskUiTop (unvis) : unreadListDlg.onItemSelected > noteId=",noteId!)
            this.doSetVisibleNote('book',noteId!)
        }
        dlg.setDeskRepo(this.deskRepo!)
        dlg.init({ parent:this.rightPnl, className:'ft_selpop', 
                   cssText:css1, })
        this.bookListDlg=dlg;

        this.doUpdateUi()
    }

    async doSetVisibleNote(type:string, noteId:string){
        if(!this.deskRepo) return;
        rzlog.debug('FtDeskUiTop.doSetVisibleNote(unvis) : type=',type,',noteId=',noteId)
        await this.deskRepo.putNoteInfo(noteId,{noteId:noteId,visible:true})
        this.doUpdateUi()
    }

    async doUpdateUi(){
        let notes=await this.doGetUnvisibleNotes()
        this.bookListDlg!.setNotes(notes)
    }


    async doGetUnvisibleNotes(): Promise<RzoNoteInfo[]>{
        let r=await this.deskRepo!.getNoteInfos()
        let pages=r.data!
        rzlog.debug("FtDeskUiTop.doGetUnvisibleNotes:unvis - pages=",pages)

        if(!pages) return []
        let unvisNotes=pages!.filter(t=> t.visible===false )
        rzlog.debug("FtDeskUiTop.doGetUnvisibleNotes:unvis - unvis=",unvisNotes,",pages=",pages)
        return unvisNotes
    }

    async doLoadBooksOnDlg(){
        if(!(this.deskRepo && this.bookListDlg)) return;
        let unvisNotes=await this.doGetUnvisibleNotes()
        this.bookListDlg.setNotes(unvisNotes)
    }

    async doLoadEtcBooksOnDlg(etcList:HTMLUListElement){
         
        RzUi_clearChildren(etcList)

        for (let i in this.extraPages){
            let p = this.extraPages[i]
            this.doAddEtcItem(etcList,p)
        }
    }


    doAddEtcItem(etcList:HTMLUListElement, bk:FtoNoteInfo){
        let li:HTMLElement=RzAwtTool.createElement('li')
            li.className='ftclass_book_item'
            li.innerHTML=bk.title!
            rzPut(li, "item",bk)
            rzPut(li, "onItemClick",(etcList as any).onItemClick)
            //li.item=bk
            //li.onItemClick=etcList.onItemClick
            li.onclick=(e)=>{
                let it=e.target as any
                it.onItemClick( it.item)
            }
        etcList.appendChild(li)
    }


    doShowDlgOfNoti1(npos:RzPos){

        let dlg=new FtNotiDlg()
        dlg.setTitle('선생님 노트 보이기')
        dlg.setNotiMsg('수강자가 강의자 노트보기를 신청하였습니다.<br/> 수락하시겠습니까?')
        let w=480
        let h=275
        let hgap=20
        let xgap=20
        let css1=`left:${npos.x-w-xgap}px; top:${npos.y+hgap+h}px; 
                    width:${w}px;height:${h}px;`
        //dlg.onAddNote=(type:string, ntId?:string):void=>{

        
        dlg.init({ parent:this.rightPnl, className:'ft_selpop',   cssText:css1, })
       
    }

    doShowDlgOfNoti2(npos:RzPos){

        let dlg=new FtNotiDlg()
        dlg.setTitle('답안지 보이기')
        dlg.setNotiMsg('수강자가 압안지 보기를 신청하였습니다.<br/> 수락하시겠습니까')
        let w=480
        let h=275
        let hgap=20
        let xgap=20
        let css1=`left:${npos.x-w-xgap}px; top:${npos.y+hgap+h}px; 
                    width:${w}px;height:${h}px;`
        //dlg.onAddNote=(type:string, ntId?:string):void=>{

        dlg.onDlgBtnClick=(id:String)=>{

        }

        dlg.init({ parent:this.rightPnl, className:'ft_selpop', 
                   cssText:css1, })
       
    }

    /***********************
     * 
     */

    onClickEvents=[]

    addOnClick(fn:RzOnFn,pr?:any){
        RzEventNotier.AddOnEvent(this.onClickEvents,fn,pr)
    }

    delOnClick(fn:RzOnFn){
        RzEventNotier.DelOnEvent(this.onClickEvents,fn)
    }

    notiOnClick(e:any){
        RzEventNotier.NotiEvent(this.onClickEvents,e)
    }
}//class


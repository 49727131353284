import { FT_LAYER_EDIT_POP, FT_LAYER_POP } from "../../consts"
import { FtoNoteInfo, FtoUserTypeEnum } from "../../dto/ftclass.dto";
import { FtClassSamp_getDefBooks, FtClassSamp_getNoteInfos4Dlg } from "../../ftclass.samp";
import { FtDeskEvent } from "./ftdesk.ui.event";
import { FtDeskRepo } from "./ftdesk.ui.repo";

import { rzlog, RzUiOpts ,RzBtnLet, RzUiLet,RzDragBtn,RzDlg,RzAwtTool} from "../../inc"
import { IsFail }  from "../../inc"



/*********************************/

const rzIs=rzlog.makeDefs()
export const FtClassUiPopDlg_setDbg=rzIs.setDbg;

/*********************************/
export class FtNotePopDlg extends RzDlg {
    headLet?:RzUiLet;
    bodyLet?:RzUiLet;
    bottomLet?:RzUiLet;
    
    doPreOpts(opts: RzUiOpts):RzUiOpts {
       
        let css=`position: absolute;
                z-index:${FT_LAYER_POP};
                display:flex; flex-direction:column;
                justify-content:space-between;
                background: #FFFFFF;  
                box-shadow: 2px 4px 20px 10px rgba(0, 0, 0, 0.4);
                border-radius: 12px;` + (opts.cssText?opts.cssText:'')

        let nopt=super.doPreOpts({...opts,cssText:css})
        return {...nopt, cssText:css}
    }
    
    fontStyle=`font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.2px;
    `

    doInit(opt: RzUiOpts): boolean {
        let head=new RzUiLet()
        let css1=`display:flex; flex-direction:row;
                    margin: 10px;
                    justify-content:space-between;
                    height:50px; width:100%;
                    color: #222222;
                    ${this.fontStyle}
                    `
        head.init({parent:this,cssText:css1})
        this.doInitHead(head)

        let body=new RzUiLet()
        let css=`display:flex; flex-direction:column;
                justify-content:center;
                flex-glow:1;
                margin:10px; color: #222222;
                ${this.fontStyle}
                `
        body.init({parent:this,cssText:css})
        this.bodyLet=body;
        this.doInitBody(body)  
        
        let btm=new RzUiLet()
        let btmCss=`width:100%;height:54px;
                display:flex; flex-direction:row; 
                align-items:center;
                align-self:center;
                justify-content:center;
                margin-bottom:12px; `
        btm.init({parent:this,cssText:btmCss})
        this.bottomLet=btm
        this.doInitBottom(btm)
        
        return true
    }
    titleFontStyle=`font-family: 'Spoqa Han Sans Neo';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 24px;
                        
                        display: flex;
                        align-items: center;
                        letter-spacing: 0.2px;
                        
                        color: #444444;`
    doInitHead(head:RzUiLet){
        let html=`<div style="flex-grow:1; ${this.titleFontStyle}" >${this.title}</div>
                <div id="${head.id}.closeBtn" style="width:60px; height:61px; align-items:flex-start;
                            margin-right:16px;">
                    <i class="ftnote-pop-close"/>
                </div>`
        
        head.nativeDiv!.innerHTML=html;

        let el =head.getElementById('closeBtn')

        if(el) el.onclick=()=>{
            this.doClose()        
        }
    }

    doClose(): void {
        this.setVisible(false)
    }


    doInitBody(body:RzUiLet){
 
    }

    doInitBottom(btm:RzUiLet){
 
    }

}//class

/*********************************/
export class FtNoteTypeDlg extends FtNotePopDlg {
    repo?:FtDeskRepo;
    
    doPreOpts(opts:RzUiOpts){
        this.title="<div>노트/교재 추가하기</div>";
        return super.doPreOpts(opts)
    }

    doInitBody(body:RzUiLet){
        this.doBodyText(body)
    }

    doBodyText(body:RzUiLet){
        let html=`노트 혹은 교재를 추가합니다`
        body.nativeDiv!.innerHTML=html;
    }

    doInitBottom(btm:RzUiLet){
        this.doInitButtons(btm)    
    }

    setDeskRepo(repo:FtDeskRepo){
        this.repo=repo;
    }

    doInitButtons(body:RzUiLet){
  
        let ui=this.repo?.getUserInfo();
        let utype=FtoUserTypeEnum.STUDENT;
        if(ui?.userType) utype=ui.userType;

        let btnDisabled=''
        if(utype===FtoUserTypeEnum.STUDENT) btnDisabled='disabled'
        
        rzlog.debug('FtClassUi.PopDlg.doInitButtons : utype=',utype,',ui=',ui)

        let html=`<button class="ftpop_btn " ${btnDisabled}
                    style="margin-right:20px;" 
                    id="${body.id}.addBook">교재 추가하기</button>
                 <button class="ftpop_btn" id="${body.id}.addNote">노트 추가하기</button>`
        body.nativeDiv!.innerHTML=html;

        let bookBtn=body.getElementById('addBook')
        bookBtn!.onclick=()=>{
            rzlog.debug("AddBook")
            if(this.onAddNoteClicked) this.onAddNoteClicked('book')
        }
        let noteBtn=body.getElementById('addNote')
        noteBtn!.onclick=()=>{
            rzlog.debug("addNote")
            if(this.onAddNoteClicked) this.onAddNoteClicked('note')
        }
    }

    onAddNoteClicked?:(noteType:string, noteId?:string)=>void;
 
 
    onDeskEvent=(e:FtDeskEvent)=>{

    }

}//class

/*********************************/
let isNoteListTestOn=false
export class FtNoteListDlg extends FtNotePopDlg {
    repo?:FtDeskRepo;
    notes?:FtoNoteInfo[] = isNoteListTestOn? FtClassSamp_getNoteInfos4Dlg():[]
    listLet?:RzUiLet;
    okLet?:RzBtnLet;
    selectedId?:string;
    selectedLi?:HTMLLIElement;
    liBgColor?:string;


    doPreOpts(opts:RzUiOpts){
        this.title="교재 목록";
        return super.doPreOpts(opts)
    }
 
    setDeskRepo( deskRepo:FtDeskRepo){
        rzlog.debug("FtClassUiPopDlg.setDeskRepo(unvis) : repo=",deskRepo)
        if(this.repo) this.repo.delOnDeskEvent(this.onRepoChanged)

        this.repo=deskRepo;
        this.repo.addOnDeskEvent(this.onRepoChanged)
        this.notes=[]

        this.doLoadNotes()
    }

    async doLoadRepo(){
        
        let r=await this.repo!.getNoteInfos();
        rzlog.debug('FtClassUiPopDlg.FtNoteListDlg: r=',r);
        if(IsFail(r)) return;

        let notes=r.data!;
        let unVisibles=notes.filter((t:FtoNoteInfo) =>t.visible===false);
        this.notes=unVisibles;
        this.doUpdateUi(true);
    }

    onRepoChanged=async ()=>{
        await this.doLoadRepo();
    }

  
    setNotes(notes:FtoNoteInfo[]){
        this.notes=notes;
        if(this.nativeDiv)this.doUpdateUi(true);
    }

    doInitBody(body:RzUiLet )   {
        this.doInitBooks(body)
        
    }

    doInitBottom(btm: RzUiLet): void {
        this.doInitButtons(btm)
    }


    doInitBooks(body:RzUiLet){
 
        body.nativeDiv!.innerHTML=
        `<ul id="${body.id}.list" class="ftclass_book_list">
        </ul>`

        
        this.listLet=body;        

        this.doUpdateUi()
    }

    doLoadNotes(){
        rzlog.debug("FtClassUiPopDlg.FtNoteListDlg > doLoadNotes : this.notes=",this.notes)
 
        if(!this.notes) return;

        this.notes.forEach(t=>{
            this.doAddItem(t.noteId!,t.title!)
        })
    }

    doAddItem(id:string,title:string){
        let el=this.listLet!.getElementById('list') as HTMLUListElement
        if(!el){
            rzlog.info("FtClassUiPopDlg.doAddItem > el=null : id=",id,',title=',title)
            return
        }
        let li1=RzAwtTool.CreateDiv('li') as HTMLLIElement
        li1.innerHTML=title
        li1.onclick=()=>{
            if(!this.liBgColor) this.liBgColor=li1.style.backgroundColor
            li1.style.backgroundColor='#6ba09a'
            if(this.selectedLi) li1.style.backgroundColor=this.liBgColor;

            this.selectedLi=li1
            this.doItemClicked(id)
        }
        li1.style.width='100%'
        el.appendChild(li1)

    }

    doItemClicked(id:string){
        this.selectedId=id
    }
    

    doInitButtons(btm:RzUiLet){
        let btn=new RzBtnLet()
 
        btn.setTitle("교재 선택하기")
        btn.onClick=()=>{
            this.doSelectItem()
        }
        btn.init({parent:btm,className:'ftpop_btn'})

        this.okLet=btn;
    }

    doUpdateUi(refresh?:boolean){
        if(refresh){
            this.selectedLi=undefined;
            this.selectedId=undefined;
            let navList=this.listLet!.getElementById('list') as HTMLUListElement
            if(navList) RzAwtTool.RemoveChildAll(navList)
        }

        this.doLoadNotes()
    }        

    onDeskEvent=(e:FtDeskEvent)=>{
        this.doUpdateUi(true)
    }

    doSelectItem(){
        rzlog.debug("FtClassUiPopDlg:NotiDlg > selectedId=",this.selectedId)
        this.doNotiItemSelected()
        
        this.doClose()
    }

    doNotiItemSelected(){
        rzlog.debug("FtClassUiPopDlg.FtNoteListDlg.doNotiItemSelected(unvis): selId=",this.selectedId)
        if(this.onItemSelected && this.selectedId) this.onItemSelected(this.selectedId)
    }

    onItemSelected?:(ntId:string)=>void;

}//class


 
/************************* */
/*********************************/
export class FtNotiDlg extends FtNotePopDlg {
    bodyText?:string;
    setNotiMsg(msg:string){
        this.bodyText=msg;
    }

    doInitBody(body:RzUiLet){
        this.doBodyText(body)
    }

    doBodyText(body:RzUiLet){
        let html=this.bodyText||''
        body.nativeDiv!.innerHTML=html;
    }

    doInitBottom(btm:RzUiLet){
        this.doInitButtons(btm)    
    }

    doInitButtons(body:RzUiLet){
  
        let html=`<button class="ftpop_btn" style="margin-right:20px" 
                    id="${body.id}.cancel">거절</button>
                 <button class="ftpop_btn" id="${body.id}.ok">수락</button>`
        body.nativeDiv!.innerHTML=html;

        let noBtn=body.getElementById('cancel')
        noBtn!.onclick=()=>{
            this.doDlgBtnClick('cancel')
        }
        let okBtn=body.getElementById('ok')
        okBtn!.onclick=()=>{
          this.doDlgBtnClick('ok')
        }
    }

    doDlgBtnClick(id:string){
        if(this.onDlgBtnClick)this.onDlgBtnClick(id)
        this.setVisible(false)
    }

    onDlgBtnClick?:(id:string)=>void;

}//class

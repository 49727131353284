import { FtoClassInfo, FtoMemo } from "../dto/ftclass.dto";
import { RzFindReq, NewRsRes, RzRes, RzRsRes, RzNoteRepo, RzReq, rzlog, NewRes, NewOk, NewFail, IsFail } from "../inc";
import { RzoNoteInfo } from "../note.ui/rznote.ui.dto";
import { FtClassRepoMng } from "./ftclass.repo-mng";
import { FtClass2Event, FtClass2Repo } from "./ftclass2.repo";
import { DefMemos, DefNotes, Def_getDefClassInfo, Def_getDefRepo } from "./ftclass2.repo.default";
import { FtClass2RepoFac } from "./ftclass2.repo.fac";



let isDefOn=true;
export const FtClass2RepoMng_setDefOn=(b:boolean)=>{
    isDefOn=b;
}

/******************************
 * FtClass2MemRepo
 */

export class FtClass2Repo4RepoMng implements FtClass2Repo {

    repoMng:FtClassRepoMng|null=null;
    constructor( ){}

    getName(){return 'ClassRepo4RepoMng'}

    getRepoMng(): FtClassRepoMng | null {
       return this.repoMng;
    }

    setRepoMng(mng:FtClassRepoMng){
        this.repoMng=mng;
    }


    addClassListener(id: string, cb: (e: FtClass2Event) => void): void {
        
    }

 

    async findMemoList(rq:RzFindReq<FtoMemo>):Promise<RzRsRes<FtoMemo>>{
            if(!this.repoMng)return NewFail('no repoMng');
            if(! rq.data?.noteId) return NewFail('no noteId');
            
            let r= await this.repoMng.doGetNoteRepo(rq.data.noteId);
            if(IsFail(r)) return NewFail("findMemoList > "+r.message);

            let repo=r.data;
            if(!repo)return NewRsRes([]);

  
                
                let r0=await repo.iterPathAll('memo');
                if(IsFail(r0)) return NewFail("findMemoList > "+r0.message);
                let rs=r0.data;
                if(!rs)return NewRsRes([]);

                rs=rs?.filter(e=>e.type==='memo');
                let rm=rs?.map(e=> {
                    let m=new FtoMemo();
                    Object.assign(m,e);
                    m.body=e.text;
                    m.id=e.id;
                    
                    return m;
                });

                return NewRsRes(rm);
  
    }
    
    async getClassInfo(rq:RzReq<FtoClassInfo>): Promise<RzRes<FtoClassInfo>>{
        if(!this.repoMng)return NewFail('no repoMng');
        if(! rq.data?.classId) return NewFail('no classID');
        let r= await this.repoMng.getClassInfo();
        if(IsFail(r)) return NewFail("getClassInfo > "+r.message);
        return NewOk(r.data);
    }

   
    async  getNoteRepo(rq:RzReq<RzoNoteInfo>):Promise<RzRes<RzNoteRepo>> {
        if(!this.repoMng)return NewFail('no repoMng');
        if(! rq.data?.noteId) return NewFail('no noteId');
        let r= await this.repoMng.getNoteRepo(rq.data.noteId);
        if(IsFail(r)) return NewFail("getClassInfo > "+r.message);
        return NewOk(r.data);
    }


}//class
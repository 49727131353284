 
import { createContext } from "react";
import { FtClass2 } from "./ftclass2.ui";
import { FtClass2Repo }  from "../../repo/ftclass2.repo";
import { FtConfig } from "../../../config2";
import { FtxMirroring, FtxNote, FtxNoteTool, FtxSetting } from "../../dto/ftclass2.dto";
import { FtClassRepoMng } from "../../repo/ftclass.repo-mng";
import { FtoClassSharing, FtoEditLock, FtoEtcSetting, FtoUser } from "../../dto/ftclass.dto";
import { WavleMediaClient } from "../../../wavle-media-client";
 

/*********************
 *  Context 
 */

export interface FtUiContext {
    layoutType?:string;
    deviceType?:string;
    
    setGlobalCtx:(ctx:FtUiContext)=>void;
    // setGlobalCtx: (ctx: FtUiContext | ((currentCtx: FtUiContext) => FtUiContext)) => void;

    ftClassRef:React.RefObject<FtClass2>;
    
    userInfo?:FtoUser;
    
    isDirectJoin?:boolean;

    isClassJoined?:boolean;
    isRepoLoaded?:boolean;

    camCount?:number;
    videoCount?:number;
    camClient?:any;
    camDevice?:any;
    camDeviceOther?:any;

    attendants?:FtoUser[];
    isMyCamFocused?:boolean;
    isOtherCamFocused?:boolean;

    /** 2024-01-18 17:37:48 화상 접속자들(본인 제외) */
    cousumer: { nickName: string, isVideo: boolean, isAudio: boolean }[];

    
    isParentJoinOn?:boolean;
    isParentNotiOn?:boolean;

    isAlarmOn?:boolean;
    alarmMsg?:string;


    setting?:FtxSetting;


    //@ADD  extended
    sharing?:FtoClassSharing;
    mirroring?:FtxMirroring;
    //@ADDEND

    classId?:string;
    isCastOn?:boolean;
    editLock?:FtoEditLock;
    isEditDisabled?:boolean;

    curToolId?:string
    curTool?:FtxNoteTool;
    curTools?:any[];
    
    //curNote?:FtxNote;
    //@ADD for top
    curNoteId?:string;
    

    notes?:FtxNote[];
    notesFrom?:string;
     
    repo?:FtClass2Repo;
    conf?:FtConfig;
    repoMng?:FtClassRepoMng;

    isCam?:boolean;
    isMic?:boolean;
    isCamOther?:boolean;
    isMicOther?:boolean;
    isCamFloatOn?:boolean;

    ///////////////////////////////////////////////
    isCtrlOn?:boolean; /*23.11.23 필기허용 추가 */    
    isWriteAllow?:boolean;  //필기허용
    isTeacherWrite?:boolean;//내필기보이기(선생님)

    isLoad?:boolean;
    isSkipJoin?:boolean;
    isOtherClient?:boolean;

    isBtmDbgOn?:boolean;

    TP?:boolean; //PC+Tablet tablet case
    PT?:boolean; //PC+Tablet pc case    
};


export const ToDbgUserStat = (ctx:FtUiContext,fetchCnt?:number):string=>{
    let vcnt=ctx.videoCount;
    let atts=ctx.attendants?JSON.stringify(ctx.attendants):'[]';
    let camCount = ctx.camCount || 0;
    let ui = ctx.userInfo;
    let fcnt=(fetchCnt==undefined)?'':''+fetchCnt+',';
    let classId = ctx.classId;

    let dbgMsg=':'+fcnt+'ucnt='+camCount+',cid='+(classId||'')+',vcnt='+vcnt+',atts='+atts;
    if(ui?.userType) dbgMsg+=','+ui.userType;
    else dbgMsg+=',no-user';
    return dbgMsg;
}
 
export const FtClassCtx = createContext<FtUiContext>({} as FtUiContext);

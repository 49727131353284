import { rzlog ,IsFail} from "../../inc"
import { RzNote, NewFail, RzUiLet, RzOnFn, RzUiOpts, RzNoteRepo} from "../../inc"

import { RzPenAttr, RzPenTypeEnum } from "../../inc"

import { FtDeskTopEvent, FtDeskTopEventEnum, FtDeskTopLet } from "./ftdesk.ui.top"

import { FtDeskEvent, FtDeskEventEnum } from "./ftdesk.ui.event"
import './ftdesk.ui.css'
import { FtDeskRepo } from "./ftdesk.ui.repo"
import { FtClassEvent } from "../../ftclass.event"
import { FtoEditLock, FtoNoteInfo, FtoUser, FtoUserTypeEnum } from "../../dto/ftclass.dto"
import { FtNoteRepo } from "./ftnote.ui"
import { FtEditLockTool } from "../../ftclass.perms"


/***/
 

const rzIs=rzlog.makeDefs()
export const FtDeskUi_setDbg=rzIs.setDbg

 

let isDbgUi=false
export const FtDeskUi_setDbgUi=(b:boolean)=>{
    isDbgUi=b
}


/*********************************/
export interface FtDeskLetOpts extends RzUiOpts{
    isNoteOn?:boolean;
    isBookOn?:boolean;
    initType?:string; //"book"

    canvasFitType?:string; //width, page
    canvasWGap?:number;
    canvasHGap?:number;
    canvasWidth?:number;
    canvasHeight?:number;
    canvasColor?:string;
} 

/*********************************
 *  FtDeskLet
 ********************************/
 export class FtDeskLet extends RzUiLet {
    isNoteOn=true
    isBookOn =true
    initType:string

    topHeight=40
    deskRepo?:FtDeskRepo
    curRepo?:RzNoteRepo
    curPages?:FtoNoteInfo[];
    notes:RzNote[]=[]

    hasCtrl:boolean=true;
    ctrlLet?:FtDeskTopLet
    noteLet?:RzNote
    bookLet?:RzNote
    onFocusFn?:RzOnFn
    fitType?:string
    noteBgColor?:string="black"
    editLock?:FtoEditLock;

    canvasFitType?:string; 
    canvasWGap?:number;
    canvasHGap?:number;
    canvasWidth?:number;
    canvasHeight?:number; 
    canvasColor?:string;

    constructor(opts?:FtDeskLetOpts){
        super(opts)
 
        this.isNoteOn=opts?.isNoteOn||true
        this.isBookOn=opts?.isBookOn||true
        this.initType=opts?.initType||'note'

        this.canvasFitType=opts?.canvasFitType;
        this.canvasWGap=opts?.canvasWGap;
        this.canvasHGap=opts?.canvasHGap;
        this.canvasWidth=opts?.canvasWidth;
        this.canvasHeight=opts?.canvasHeight;
    }

    setFitType(fitType:string){
        if(this.noteLet) this.noteLet.setFitType(fitType)
        if(this.bookLet) this.bookLet.setFitType(fitType)
    }

  
    setNoteBgColor(color:string){
        this.noteBgColor=color;
    }

    setDeskOpts(opts:FtDeskLetOpts){
        Object.assign(this,opts)
    }




    setCtrlOn(b:boolean){
        this.hasCtrl=b
        if(this.nativeDiv && !b){
            if(this.ctrlLet) this.removeChild(this.ctrlLet!)
        }
    }

    setType(type:string){this.initType=type}
 
    setPencil(penType:string,penAttr?:RzPenAttr){
        let tgtNote= this.bookLet || this.noteLet
        if(tgtNote) tgtNote?.setPencil({type:penType,...(penAttr)?penAttr:{}})
    }

    doPreOpts(opt:RzUiOpts){
        let cssText='display:flex; flex-direction:column;'

        if(opt.cssText) cssText=cssText+' ' +opt.cssText

        
        if(rzIs.d) rzlog.debug('FtDeskLet.doPreOpts:opts=',opt,',css=',cssText)
        return {...opt, className:'ftdesk', cssText:cssText}
    }

    async doInitRepo(opt?:RzUiOpts) :Promise<void>{
        
        if(!this.deskRepo) return;

        let r0=await this.deskRepo.getPages();
        if(!r0 || r0.status>0) return ;

        let pages=r0.data!
        if(rzIs.d) rzlog.debug(`FtDeskRepo[${this.initType}].setDeskRepo > getPages : pages=`,pages);


        let r1=await this.deskRepo.getCurRepo();
        if(r1.status>0){ /*no action !!!! */}


        let repo=r1.data;
        if(rzIs.d) rzlog.debug(`FtDeskRepo[${this.initType}].setDeskRepo : repo= `,repo);


        this.curPages=pages;
        this.curRepo=repo;
        
    }


    doInit(opt?:RzUiOpts) {




        if(opt?.onFocus) this.onFocusFn=opt.onFocus;



        if(this.hasCtrl && !this.ctrlLet){
            rzlog.debug("FtDeskLet.ctrlLet : init ... -topHeight=",this.topHeight);

            this.ctrlLet=new FtDeskTopLet();
            this.ctrlLet.setParent(this as RzUiLet);
            this.ctrlLet.setTopHeight(this.topHeight);
            this.ctrlLet.init({ 
                //cssText:`position:relative; width:100%; height:${this.topHeight}px; background:#333333;`
                cssText:` width:100%; height:${this.topHeight}px; background:#333333;`
            });

            this.ctrlLet.addOnClick(async (e:FtClassEvent)=>{
                if(rzIs.d) rzlog.debug('FtDeskLet.ctrlLet.onClick !!!!!!!!!!!!: e=',e)
                if(e.cmd===FtDeskTopEventEnum.PAGES_ON){
                    if(this.deskRepo) await this.deskRepo.putCurRepoAt(e.seq!) ;
                } else if(e.cmd===FtDeskTopEventEnum.PAGES_OFF) {
                    rzlog.debug("FtDeskUi.onClick : xbtn.FtDeskTopEventEnum.PAGES_OFF , e=",e);
                    let ne= e as FtDeskTopEvent;
                    if(this.deskRepo) {
                        rzlog.debug("FtDeskUi.onClick : xbtn.FtDeskTopEventEnum.PAGES_OFF , e=",e);
                        let tr=await this.deskRepo.getNoteInfo(ne.noteId!);
                        if(IsFail(tr))  return;

                        let ni=tr.data!
                        ni.visible=false;
                        let tr2=await this.deskRepo.putNoteInfo(ne.noteId!,ni);
                        if(IsFail(tr2)) return;
                    }

                } else if(e.cmd===FtDeskTopEventEnum.BOOK_ADD) {
                    if(this.deskRepo) await this.deskRepo.addNoteInfo(e.book!,{type:'ctrl'});
                }
            });
        }


        //books=[{title:'test1', bookId:'b1' }]
        //if(this.curPages) this.ctrlLet.setPages(this.curPages,this.notes)
        
        if(this.deskRepo) this.doUpdateDeskRepo(this.deskRepo);
        
    
        /********** */
        if(this.curRepo){
            if(this.isNoteOn && this.curRepo!.getType()==='note')        this.doAddNoteLet(this.curRepo);
            else if(this.isBookOn && this.curRepo!.getType()==='book')        this.doAddBookLet(this.curRepo);
    
        } else {
            if(this.isNoteOn && this.initType==='note')        this.doAddNoteLet();
            else if(this.isBookOn && this.initType==='book')        this.doAddBookLet();
        }

        return true;
    }

    defOwnUserType=FtoUserTypeEnum.TEACHER;
    doUpdateDeskRepo(repo:FtDeskRepo){
        
        this.ctrlLet!.setDeskRepo(repo);
        const ui= repo.getUserInfo();

        if(!ui) return;
        const ownLock= true;
    }

  
    onFocus=(e:any,b?:boolean)=>{
        if(rzIs.t) rzlog.trace("FtDesk.ui. onFocus e=",e,",b=",b,",fn=",this.onFocusFn);
        if(this.onFocusFn) this.onFocusFn(e,b);
    }

    onResize=(rect:any)=>{
        if(rzIs.t) rzlog.trace("FtDesk.onresize:r=",rect);
        this.setBounds(0,0,rect.w,rect.h);
        this.doLayout(rect);
    }

    doLayout(rect:any){
        let r =this.getBounds();
        let ne={width:r.width,height:r.height-this.topHeight};
        if(rzIs.d) rzlog.debug('FtDesk.doLayout :ne=',ne);

        if(this.noteLet) this.noteLet.onResize(ne);
        if(this.bookLet) this.bookLet.onResize(ne);
    }


    doAddNoteLet(repo?:FtNoteRepo){
        if(rzIs.d) rzlog.debug('FtDeskLet.doAddNoteLet : repo=',repo);
        if(this.noteLet) {
            this.noteLet.doClear();//clearRepo()
            this.removeChild(this.noteLet as RzUiLet);
            this.noteLet=undefined;
        }

        this.noteLet=new RzNote({penType:RzPenTypeEnum.PEN});

        this.doBindCanvasAttr(this.noteLet);

        this.noteLet.setParent(this  as RzUiLet);
        this.noteLet.onFocus=(e:any,b?:boolean)=>{
            //if(rzIs.d) rzlog.debug('FtDesk.Ui.noteLet onfocus=',b);
            this.onFocus(e,b); 
        }
        let bgcolor= this.noteBgColor?'background-color:'+this.noteBgColor+';' : '';
        let css=`display:flex;justify-content:center; 
                 align-items:center; 
                ${bgcolor} border-width:0px;
                position:relative; flex-grow:1;
                width:${isDbgUi?99:100}%; `;
        this.noteLet.init({className:'ftdesk_note',cssText:css});
        
        let ui = this.deskRepo?.getUserInfo();
        if(ui) {
            rzlog.debug("FtDeskUi.doAddNoteLet : ui=",ui);
            this.noteLet.setDrawerUsername(ui.username!);
            this.noteLet.setDrawerUid(ui.uid!);
        }

        if(repo) {
            this.noteLet.setRepo(repo);
             
        }
        //this.doUpdateEditLock(this.noteLet)
    }

    doBindCanvasAttr(note:RzNote){
        if(this.canvasColor) note.setCanvasColor(this.canvasColor);
        if(this.canvasFitType) note.setFitType(this.canvasFitType);

        //@ if(this.canvasHGap && this.canvasWGap ) 
        //@     note.setCanvasGap(this.canvasWGap, this.canvasHGap)

        if(this.canvasWidth && this.canvasHeight) 
            note.setDefCanvasSize(this.canvasWidth, this.canvasHeight);

        rzlog.debug('FtDesk.note=',note);
    }

    doAddBookLet(repo?:FtNoteRepo){
        if(rzIs.d) rzlog.debug('FtDeskLet.doAddBookLet : repo=',repo);

        if(this.bookLet) {
            this.bookLet.doClear();//.clearRepo()
            this.removeChild(this.bookLet as RzUiLet);
            this.bookLet=undefined;
        }

        
        let book= new RzNote() ;
             
        this.bookLet=book;
        this.bookLet.setParent(this);
        this.bookLet.onFocus=(e,b)=>{
            //if(rzIs.d) rzlog.debug('FtDesk.Ui.bookliet onfocus=',b)
            this.onFocus(e,b); 
        };
        
        this.doBindCanvasAttr(book);

        let bgcolor= 'background-color:'+ (this.noteBgColor||'#ffffff')+';' ;
        this.bookLet.init({ className:'ftdesk_book', 
                cssText:`display:flex;justify-content:center; align-items:center; 
                        ${bgcolor} border-width:0px;
                        position:relative; flex: 0 1 100%; width:${isDbgUi?98:100}%; `});

        let ui = this.deskRepo?.getUserInfo();
        if(ui) {
            this.bookLet.setDrawerUsername(ui.username!);
            this.bookLet.setDrawerUid(ui.uid!);
        }


        if(repo) this.bookLet.setRepo(repo);

        if(this.deskRepo){
            const el=this.deskRepo.getEditLock();
            this.doUpdateEditLock(this.bookLet,el);
        }
    }

   

    doClearUiAll(){
        // for(let i in this.children){
        //     let c = this.children[i]
        //     if(c.nativeDiv) this.nativeDiv!.removeChild(c.nativeDiv)
        //     c.doClear()
        // }
        if(this.bookLet && this.nativeDiv ){
            this.removeChild(this.bookLet);
        } 
        if(this.noteLet && this.nativeDiv ){
            this.removeChild(this.noteLet);
        } 

        this.bookLet=undefined;
        this.noteLet=undefined;

        this.children=[];
    }

    /************/ 
    onDeskEventByRepo=async (e:FtDeskEvent,pr?:any)=>{
        let deskLet=pr as FtDeskLet;
        
        if(e.cmd===FtDeskEventEnum.PAGES_PUT){
            if(rzIs.d) rzlog.debug('FtDeskLet.onDeskEventByRepo : PAGES_PUT - e=',e);

            if(e.srcOpt)  deskLet.ctrlLet!.reload();

            if(deskLet.bookLet)deskLet.bookLet.repaint();
            if(deskLet.noteLet)deskLet.noteLet.repaint();

        } else if(e.cmd===FtDeskEventEnum.PAGES_ADD) {
            if(rzIs.d) rzlog.debug('FtDeskLet.onDeskEventByRepo : PAGES_ADD - e=',e,',ctrlLet=',pr.ctrlLet);
            //@if(e.srcOpt)  (pr.ctrlLet as FtDeskTopLet).reload()
            deskLet.ctrlLet!.reload();
        }
         else if(e.cmd===FtDeskEventEnum.PAGES_DEL) {
            if(rzIs.d) rzlog.debug('FtDeskLet.onDeskEventByRepo : PAGES_DEL - e=',e);
            //deskLet.ctrlLet.delPages(e.pages)
            deskLet.ctrlLet!.reload();
        } else if(e.cmd===FtDeskEventEnum.PAGES_ON) {
            if(rzIs.d) rzlog.debug('FtDeskLet.onDeskEventByRepo : PAGES_ON - e=',e);
            pr.doChangeRepo(e.pages);
 
        }   else if(e.cmd===FtDeskEventEnum.EDIT_LOCK) {
            const ui=this.deskRepo!.getUserInfo();
            rzlog.debug("FtDeskLet.onDeskEventByRepo(EditLock) : EDIT_LCOK - e=",e,',ui=',ui);
            deskLet.doUpdateEditLock(this.bookLet!,e.editLock!);
            //pr.doUpdateEditLock(this.noteLet,e.editLock?.userType,e.editLock?.isOwnerLock)
        
        } else if(e.cmd===FtDeskEventEnum.SETTING) {
            if(rzIs.d) rzlog.debug('FtDeskLet.onDeskEventByRepo : SETTING -srcOpt=',e.srcOpt,', ctrlLet=',pr.ctrlLet,',e=',e);
            
              (pr.ctrlLet as FtDeskTopLet).reload();

        } else if(e.cmd===FtDeskEventEnum.SETTING_ETC) {
            if(rzIs.d) rzlog.debug('FtDeskLet.onDeskEventByRepo : SETTING_ETC -srcOpt=',e.srcOpt,', ctrlLet=',pr.ctrlLet,',e=',e);
            (pr.ctrlLet as FtDeskTopLet).reload();
       }
    }

    doUpdateEditLock(blet:RzNote, editLock:FtoEditLock){
        

        if(!this.deskRepo) return;

        const ui=this.deskRepo.getUserInfo();
        rzlog.debug('FtDeskUi.doUpdateEditLock(doLoadClassInfo) : editLock=',editLock,',ui=',ui);
        if(!ui) return;
 
        
        let editable=FtEditLockTool.isEditable(ui,editLock);
        if(blet) blet.setEditLock(!editable);

    }//method
   

    async doChangeRepo(newPages:FtoNoteInfo[]){
         
        this.ctrlLet!.setDeskRepo(this.deskRepo!);

        let r= await  this.deskRepo!.getCurRepo();
         if(r.status>0){ /*no action !!!! */}
         if(!r.data){
             rzlog.error('RzDesk.doChangeepo:repo is null');
             return;
         }
         this.curRepo=r.data;
         
        let group=this.curRepo!.getGroup()
        if(group==='book' && !this.bookLet){
            this.removeChild(this.noteLet  as RzUiLet);
            this.noteLet=undefined;

            this.doAddBookLet(this.curRepo);

          
            return;
        } else if(group==='note' && !this.noteLet){
            this.removeChild(this.bookLet as RzUiLet);
            this.bookLet=undefined;

            this.doAddNoteLet(this.curRepo);
            
            return;
        }


        if(this.bookLet)this.bookLet.setRepo(this.curRepo);
        else if(this.noteLet)this.noteLet.setRepo(this.curRepo);
    }

    async setDeskRepo(deskRepo:FtDeskRepo){
        if(this.deskRepo) this.deskRepo.delOnDeskEvent(this.onDeskEventByRepo);

        deskRepo.addOnDeskEvent(this.onDeskEventByRepo,this);

        this.doClearUiAll();
        this.deskRepo=deskRepo;


        this.doInitRepo();
        this.doInit();

    }

   

}//class

/*********************************/
 
import { Component } from "react";
import { MergeCss, RzUiLet, RzUiOpts } from "../../note.ui";
import { CmComponent, RzRow, RzTxt } from "./rzcomp";
import { CmParentJoinBtn } from "./fttop.ui";
import { rzToDateFormat } from "../../inc";
import { ToDbgUserStat } from "../ftclass2/ftui.context";

/*************
 * 
 */

interface FtBtmProp {
    isJoinOff?: boolean;
}
interface FtBtmStats {
    beginAt: Date;
    diffTime: number;
}
export class FtBtmStat extends CmComponent<FtBtmProp, FtBtmStats>{

    constructor(pr?: any) {
        super(pr);
        this.state = {
            beginAt: new Date(0, 0, 0),
            diffTime: 0,
        };
    }

    componentDidMount(): void {
        this.onClassEvent();
        // this.startTimer();
        // const ctx = this.getCtx();
        // let classId = this.getCtx().classId || '';
        // let classId = ctx.classId || '';
        // if(classId) {
            
        //     const beginAt = ctx.repo?.getRepoMng()?.classInfoRepo?.classInfo?.beginAt;
        //     console.log('beginAt', beginAt);
        //     if(beginAt) {
        //         // var diffTime = (newTime.getTime() - beginAt?.getTime()) / (1000);
        //         // console.log(diffTime)
        //         this.setState({beginAt:beginAt});
        //     }
        //     this.startTimer();
        // }
    }

    componentWillUnmount(): void {
        this.stopTimer();
    }

    async onClassEvent() {
        const ctx = this.getCtx();
        let classId = ctx.classId || '';
        if(classId) {
            let repoMng = this.getRepoMng();
            let beginAt = await repoMng?.getBeginAt();
            if(beginAt) {            
                this.setState({beginAt:beginAt});
                this.startTimer();
            }
        }
    }

    render() {
        let ctx = this.getCtx();
        let isParentBtnOn: boolean = false;
        let isParentOn: boolean = ctx.isParentJoinOn || false;
        let isJoinOff = this.props.isJoinOff;
        let isTimerOn = true;
        // let isTimerOn=false;
        let ui = ctx.userInfo;
        let editLock = ctx.editLock;

        // let isClassJoined: boolean = ctx.isClassJoined || false;
        let dbgStr='';
        
        if(Boolean(ctx.isBtmDbgOn)){
            dbgStr=ToDbgUserStat(ctx);
        }

        let title = "시스템 알림 메세지";
        if (Boolean(isJoinOff)) {
            title = "수업 시간 전 입니다";
            isParentBtnOn = false;
            // isTimerOn=false;
        }
        
        title+=dbgStr;

        /** 미러링 여부를 알아야함 ctx.mirroring  */
        if(editLock) {
            if(ui?.userType === 'student') {
                if(Boolean(editLock?.isOwnerLock)) {
                    title = "내 화면 미러링 중입니다.";
                }else {
                    title = "상대방 화면 미러링 중입니다.";
                }
            }else if(ui?.userType === 'teacher') {
                if(Boolean(editLock?.isOtherLock)) {
                    title = "내 화면 미러링 중입니다.";
                }else {
                    title = "상대방 화면 미러링 중입니다.";
                }
            }
        }        

        if (ctx.isParentNotiOn) isParentBtnOn = true;
        
        // let stime = rzToDateFormat('H:i:s', new Date);
        // if(isClassJoined) this.startTimer();
        let stime = String(formatTime(this.state.diffTime));

        function formatTime(totalSeconds: any): string {
            if (totalSeconds === '') return '';
          
            const hours = Math.floor(totalSeconds / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const seconds = Math.floor(totalSeconds % 60);
          
            let formattedTime = `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
            function pad(value: any) {
              return value < 10 ? "0" + value : value;
            }
            return formattedTime;
          };


        return (<RzRow style={{ width: '100%', height: 40, backgroundColor: '#000000', paddingLeft: 10, justifyContent: 'space-between', fontFamily:'Pretendard' }}>
            <RzRow>
                <span style={{ marginTop: 9 }} className="ftclass2-ic-speaker"></span>
                <RzTxt style={{ marginTop: 12 }} text={title} textStyle={{fontSize: 15}} />
            </RzRow>
            <RzRow>
                {isParentBtnOn && (<CmParentJoinBtn style={{ marginRight: 20, height: 26, width: 140 }} isTextOn={true} isParentJoinOn={isParentOn} />)}
                {isTimerOn && (<RzTxt style={{ marginTop: 10, marginRight: 20 }} text={stime} textStyle={{fontSize: 18}} />)}
            </RzRow>
        </RzRow>);
    }

    timeHd: any | null = null;
    startTimer() {
        this.timeHd = setInterval(() => {
            // this.setState({timerDate:new Date})
            // const newTime = new Date();
            // const beginTime = this.state.beginAt?.getSeconds()||0;
            // newTime.setSeconds(newTime.getSeconds());
            // this.setState({ beginAt: newTime });
            
            const newTime = new Date();
            const beginAt = new Date(this.state.beginAt);
            //console.log('beginAt1', beginAt)

            const diffTime = Math.floor((newTime.getTime() - beginAt.getTime()) / (1000));
            this.setState({ diffTime: diffTime });
        }, 1000);
    }

    stopTimer() {
        if (this.timeHd) clearInterval(this.timeHd);
    }
}//class

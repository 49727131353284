

import rzlog from './rzlog';
export * from './rztool';

export {rzlog}

export class RzOpt {
    extend?:string;
    filter?:string;
    include?:string;
    select?:string;
}//class

export class RzPage{
    pos?:number;
    size?:number;
}//class

export class RzFindReq<T>{
    keyword?:string;
    target?:string;
    data?:T;
    option?:RzOpt;
    page?:RzPage;
}//class

export class RzReq<T>{
    data?:T;
    option?:RzOpt;

}//class


export class RzRes<T> {
    status:number=0;
    message:string='OK';
    data?:T;
    constructor(opt?:any){
        if(opt) Object.assign(this,opt)
    }
}

export class RzRsRes<T> {
    status:number=0
    message:string='OK'
    count?:number
    data?:T[]
    constructor(opt?:any){
        if(opt) Object.assign(this,opt)
    }
}

 export const NewOk=<T=void>(dt?:T)=>{
     return new RzRes<T>({status:0, message:'OK', data:dt})
 }

 export const NewOkRsx=<T>(count:number,dt?:T)=>{
    return new RzRsRes<T>({status:0, message:'OK', count:count, data:dt})
}

export const NewOkRs=<T>(dt?:T[],count?:number)=>{
    return new RzRsRes<T>({status:0, message:'OK', count:count, data:dt})
}
 
 export const NewFail=<T>( msg?:string, st?:number)=>{
    return new RzRes<T>({status:st||1, message:msg||'unknown'})
}


export function IsOk<T>(rs:RzRes<T>):boolean{
    return rs.status===0
}


export function  NewRsRes<T>(dt:T[],total?:number) : RzRsRes<T> {
    let r= {data:dt, status:0, message:'OK', count:total} as RzRsRes<T>;
    return r;
}
export function  NewRes<T>(dt:T) : RzRes<T> {
    let r= {data:dt, status:0, message:'OK' } as RzRes<T>;
    return r;
}

export function IsFail<T>(rs:RzRes<T>,pmsg?:string, log?:(fmt:string)=>void){
    
    let r=false;
    let msg=""
  //  let tlog:(fmt:string)=>void | null = log ||null;

    if(!rs) {
        r=true;
        msg="no response";
    }
    if(rs.status>0){
        r=true;
        msg=rs.message
    } 
     if(r&&log){
         log("RZERR: "+( (pmsg?pmsg:'')+" : "||"")+(msg?msg:''))
     }
    
    return r
}

function merge(fmt:string, ...args:string[]){
    let r=""
    args.forEach(t=>{r=r+t})
    return r
}

export function IsFailLog<T>(rs:RzRes<T>,pmsg?:string, ...args:any[]){
    
    let r=false;
    let msg=""
    let tlog= console.error
    if(!rs) {
        r=true;
        msg="no response";
    }
    if(rs.status>0){
        r=true;
        if(pmsg)msg= merge(pmsg!, ...args) +'>' + rs.message;
        else msg=rs.message
    } 
    
    if(r){
        tlog("RZERR: ",msg, pmsg,...args)
    }

    if(r)  rs.message=msg
    
    return r
}



export type RzOn<T> = (v:T,pr?:any)=>void;
export class RzOnVal<T,P> { fn?:RzOn<T>; pr?:P }

export interface RzRepo {
    init(ctx:RzCtx, opt?:any):Promise<RzRes<void>>;
    close():void;
    load(opt?:any):Promise<RzRes<void>>;
    save(opt?:any):Promise<RzRes<void>>;
}


export const RZERR_UNKNOWN=1


export function rzGet( tgt:any, k:string) {
    return tgt[k]
}

export function rzPut( tgt:any, k:string, v:any ) {
    tgt[k]=v
}


export class RzCtx {
    static _cnt=1
    id:number

    constructor(){
        this.id=RzCtx._cnt++;
    }
    
    attrs:{[k:string]: any}={}

    get(k:string): string | undefined{
        if( ! (k in this.attrs)) return undefined
        return  this.attrs[k] as string
    }

    set(k:string, v:string) {
        this.attrs[k] = v
    }

    getAttr(k:string):any|undefined{
        if( ! (k in this.attrs)) return undefined
        return this.attrs[k]
    }

    setAttr(k:string, v:any){
        this.attrs[k]=v
    }

}//class

/****************
 * 
 */
export * from './rzanno'
import { ReactNode, createRef } from "react";
import { RzNote, RzNoteDragType, RzNoteEventEnum, RzNoteRepo, RzPenAttr, RzPenTypeEnum, RzPoint, RzScale, RzSize } from "../../note.ui";
import { CmComponent, RzBasProp } from "./rzcomp";
import { IsFail, rzlog } from "../../inc";
import { FtxNoteTool, FtxNoteToolEnum } from "../../dto/ftclass2.dto";
import { RzoNoteShare } from "../../note.ui/rznote.ui.dto";
import { FtSrcOpt } from "../../dto/ftclass.dto";



/***************
 * FtNoteLet
 */

export interface FtNoteProp extends RzBasProp {
    isWritingLocked?: boolean
    noteId?: string;
    offx?: number;
    offy?: number;
    count?: number;
    pageFitType?: string;
    pageNo?: number;
    srcBy?: string;
    scale?:RzScale;
    defTool?: FtxNoteTool;

    onLoad?: (cnt: number) => void;
    onFocused?: (note: FtNoteLet) => void;
    onPageMove?: (noteId: string, pgNo: number) => void;
}

export interface FtNoteStat {
    tool?: FtxNoteTool;
    noteId?: string;
    pageFitType?: string;
    dragType?:RzNoteDragType;
    pageNo?: number;
    isFirstLoaded?:boolean;

    isEditDisabled?:boolean;
    /* 23.11.28 */
    sharing?: RzoNoteShare;
}


export class FtNoteLet extends CmComponent<FtNoteProp, FtNoteStat> {
    seq: number = 0;

    constructor(pr?: any) {
        super(pr);
        this.state = { pageFitType: 'width' ,dragType:'vertical'};
        //@this.state = { pageFitType: 'page' };
        this.seq = FtNoteLet.countNote++;
    }
    static countNote = 1;

    noteDivRef = createRef<HTMLDivElement>();
    noteDivContainRef = createRef<HTMLDivElement>();
    noteLet: RzNote | null = null;

    isDbgOn = false;
    loadCnt = 0;
    
    prevPenAttr: RzPenAttr | null = null;


    getClientRect(): DOMRect | null {
        if (this.noteDivRef.current) {
            let r = this.noteDivRef.current.getBoundingClientRect();
            return r;
        }
        return null;
    }

    getPreferRect(): DOMRect | null {
        if (this.noteLet) {
            let r = this.noteLet.getPreferRect();
            return r;
        }
        return null;
    }

    componentDidMount(): void {
        if (!this.noteDivRef.current) return;
        if (this.noteLet) return;

       
        if(this.noteDivContainRef.current){
            rzlog.debug('FtNote.addResize :...>>>>>>>>>>>>>> ');
            this.noteDivContainRef.current.addEventListener('resize',(e)=>{
                rzlog.debug('FtNote.addResize : resize - e=',e);
                this.doResize(e);
            });
        }
       // if(this.seq>1) return;

        this.noteLet = new RzNote;

        this.noteLet.props = {
            onNoteFocused: (nt) => {
                rzlog.debug("FtNote.onNotEFocused:nt=", nt, ',onFoc=', Boolean(this.props?.onFocused));
                if (this.props?.onFocused) this.props.onFocused(this);
            },

        };

        this.noteLet.init({
            nativeDiv: this.noteDivRef.current,
            cssText: `width:100%; height:100%;left:0px; top:0px; 
            `,
            //border:1px solid orange;
            //cssText:`position:absolute; width:100%; height:100%; left:0px; top:0px;`,
        });

        let tlet = this.noteLet as any;
        tlet.parentObj = this;


        if (this.props.noteId) {

            this.setState({ noteId: this.props.noteId , isFirstLoaded:true}, () => {
                if (this.props.noteId) this.loadNote(this.props.noteId);
            });
        }
    }

    doResize(e:any){
        let a=1;
    }

    getScale(): RzScale|undefined {
        let r=this.noteLet?.getScale();
        return r;
    }
    

    setScale(w:number,h:number) {
        if(this.noteLet) this.noteLet.setScale(w,h);
    }


    getOffset(): RzPoint|undefined {
        let r=this.noteLet?.getOffset();
        return r;
    }
    

    setOffset(x:number,y:number) {
        if(this.noteLet) this.noteLet.setOffset(x,y);
    }

    componentDidUpdate(prevProps: Readonly<FtNoteProp>, prevState: Readonly<FtNoteStat>, snapshot?: any): void {
        let ctx = this.getCtx();
        rzlog.debug("ftnote.componentDidUpdate srcBy=",this.props.srcBy||'dlg',", seq=", this.seq, "ctx:", ctx);

        if (this.noteDivRef.current && !this.isDbgOn) {
            this.isDbgOn = true;
            let cx = this.noteDivRef.current.clientLeft;
            let cy = this.noteDivRef.current.clientTop;
            let ox = this.noteDivRef.current.offsetLeft;
            let oy = this.noteDivRef.current.offsetTop;
            // alert('cx='+cx+',cy='+cy+",ox="+ox+",oy="+oy);
            // this.noteLet.setOffset(x,y);
        }

        if (this.noteLet && this.props.offx !== undefined) {
            this.noteLet.offx = -(this.props.offx || 0);
            this.noteLet.offy = -(this.props.offy || 0);
        }
        
        if (this.props.noteId !== this.state.noteId) {
            this.setState({ noteId: this.props.noteId } );
            if(this.props.noteId) this.loadNote(this.props.noteId,{isSkip:true});
        } 
    
        if(ctx.isEditDisabled!== this.state.isEditDisabled){
            this.setState({isEditDisabled:ctx.isEditDisabled});
            if(Boolean(ctx.isEditDisabled)){
                this.prevPenTool=this.state.tool||null;
                this.doSetNoteTool(this.defNullPenTool);
            } else {
                if(this.prevPenTool) this.doSetNoteTool(this.prevPenTool,true);
            }
        }

        if(ctx.isEditDisabled) return;
        
        let tool = ctx.curTool;
        if (tool && tool !== this.state.tool  ){
            rzlog.debug('didUpd.PENTOOL=',tool);
                if(Boolean(this.state.isEditDisabled)) this.prevPenTool=tool;
                else this.doSetNoteTool(tool);
        }  
        
        if(this.props.scale&&this.noteLet&&this.scaled==false){
            this.scaled=true;
            this.noteLet?.setScale(this.props.scale.w,this.props.scale.h,true);
            this.noteLet.repaint();
        }
        /* 23.11.17 tool 변경시 진해져서 주석처리 */
        // else { 
        //     //repaint
        //    if( this.noteLet)  this.noteLet.renderPathAll(true);
        // }
   }
   prevPenTool:FtxNoteTool|null=null;
   defNullPenTool:FtxNoteTool={ toolId:RzPenTypeEnum.NULL};

   scaled=false;

    render(): ReactNode {
        let { style, onLoad, onFocused,onPageMove,srcBy,pageNo, noteId, ...pr } = this.props;
        let st = { ...style, backgroundColor: 'white', 
                     //   border:'1px solid red' 
                    };
        return (<div ref={this.noteDivContainRef} id={'ftNoteCol' + this.seq} {...pr} style={st}>
                    <div ref={this.noteDivRef}></div>
                </div>);
    }

    onRepoEvent = (e: any) => {
        if(e.srcOpt?.isSkip) return;
        if(e.srcOpt?.isCastOn) return;
        
        rzlog.debug("noteRepo.onEvent:", e);
        
        if (e.cmd === RzNoteEventEnum.PAGES_PUT) {
            if (this.props.onPageMove && this.tgtNoteId) {
                let pgs=e.pages;
                let pg=(pgs && pgs.length>0)?pgs[0].pageNo:0;
                let ntId=e.noteId;
                if(ntId && ntId!==this.state.noteId) {
                    this.doChangeNoteId(ntId);
                }

                if(this.tgtNoteId) this.props.onPageMove(this.tgtNoteId, pg);
            }
            
        } else if (e.cmd==RzNoteEventEnum.SHARING){
            let share=e.sharing;

            this.doProcSharing(share);   
        }
    }

   
    doChangeNoteId(noteId: string) {
        //this.bgImg=null;

        this.tgtNoteId = noteId;
        //this.setState({ noteId: noteId });
       // this.loadNote(noteId,{isSkip:true});
    }



    getNoteId(){
        return this.state.noteId;
    }


    doProcSharing(share:RzoNoteShare,isInit?:boolean){

        if(isInit){
            if(this.prevPenTool)  this.doSetNoteTool(this.prevPenTool);
        }  

        let ctx=this.getCtx();
        let rmng = this.getRepoMng();
        if (!rmng) return;            
            
        let ui=rmng.getUserInfo();
        // let ui=ctx.userInfo;
        
        let showOff=false;
        let penOff=false;

        //@NULL PEN 처리 (예: 필기없이 보기)
        if(share&& share.isMyWritingAllOn ==false ) penOff=true;        

        //나의필기보기
        if(share&& share.isMyWritingReadOn ==false ) penOff=true;

        
        let notes = ctx.notes;
        let note = notes?.find(el => el.noteId == share.noteId);
        
        if (note) {
            if (ui?.uid != share.ownerId) {
                    if (note.info?.type === 'note') {
                    // if (!note.isShareOn) {
                    //     penOff=true;
                    //     showOff=true;        
                    // } 
    
                    if(share&& share.isGroupReadOn == false && share.isGroupWriteOn == false) {
                        penOff=true;
                        showOff=true;
                    } else {
                        penOff=false;
                        showOff=false;
                    }                
                } //노트처리

                if (note.info?.subType === 'answer') {
                    if(share&& share.isGroupReadOn == false && share.isGroupWriteOn == false) {
                        penOff=true;
                        showOff=true;
                    } else {
                        penOff=false;
                        showOff=false;
                    }   
                } //답안지처리
            } 
        }

        // 상대방 - 내 노트 보기 여부 
        // if(share&& share.isGroupReadOn ==false && ui?.uid != share.ownerId  ) {
        //     penOff=true;
        //     showOff=true;
        // }        
        
        if(penOff) {
            this.prevPenTool=this.state.tool||null;
            this.doSetNoteTool(this.defNullPenTool);
            //@if(!this.nullPenOn)this.doSetPenNull(true);
        } else {                        
            if(this.prevPenTool) this.doSetNoteTool(this.prevPenTool);
            else this.doSetPenNull(false);
            //if(this.nullPenOn)  this.doSetPenNull(false);
        }

        if (ctx.curNoteId == share.noteId) {
            if(showOff){
                this.doSetShowOff(true);
            } else {
                this.doSetShowOff(false);
            }
        }
    }

 
    
    nullPenOn=false;
    doSetPenNull(b:boolean){
        if(b){            
            this.nullPenOn=true;
            this.prevPenAttr=this.noteLet?.getPencil();
            this.noteLet?.setPencil({type:RzPenTypeEnum.NULL}); 
            this.noteLet?.setPenEnabled(false);
        }else {
            this.noteLet?.setPenEnabled(true);
            if(this.nullPenOn){
                if(this.prevPenAttr){
                    this.noteLet?.setPencil(this.prevPenAttr); 
                } 
                this.nullPenOn=false;
            }
        }
    }

    doSetShowOff(b:boolean){
        if(b){
            this.noteLet?.setDrawOn(false);
        }else {
            this.noteLet?.setDrawOn(true);
        }
    }    

    /**************** */
    isDlgOn = false;
    tgtNoteId: string | null = null;
    async loadNote(noteId: string,srcOpt?:FtSrcOpt) {
        
        if (this.noteLet ) {
            this.noteLet.clearBgImg();
        }
        

        let repo = this.getRepo();
        if(!repo) return;

        if (!this.isDlgOn) {
            this.isDlgOn = false;

            //   alert('load='+noteId);
            //  alert('load='+noteId);
        }

        this.tgtNoteId = noteId;
        let rq = { data: { noteId: noteId } };
        let myname = this.getCtx().userInfo?.username;
        let r = await repo.getNoteRepo(rq);
        //console.log('loadNote ==================> ', r);
        if (IsFail(r)) return;

        let noteRepo = r.data;

        console.log('==================')
        console.log(noteRepo)
        console.log('==================')

        if (this.noteLet && noteRepo) {
            
            

            noteRepo.setContext(this.getCtx())
            let cnt = noteRepo.getPageCount();

            
            let pg = this.props.pageNo || 1;
            if (pg == 0) pg = 1;
            let r0 = await noteRepo?.getPageInfo(pg);
            let pgInfo = r0.data;

            let cpg = noteRepo.getCurPageNo();


            noteRepo.setCurPageNo(pg,srcOpt);
            rzlog.debug("ftnote.update RELOAD : seq=", this.seq, ',noteId=', noteId, ',cpg=', cpg, ",pgInfo=", pgInfo);
            this.setState({ pageNo: pg });
         

            noteRepo.delOnEvent(this.onRepoEvent,this);
            noteRepo.addOnEvent(this.onRepoEvent,this);



            let tr = await noteRepo.load();
            this.noteLet.setRepo(noteRepo);
            this.onRepoLoaded(noteRepo);
            
            let ntType=noteRepo.getType();
            console.log('================== : ntType=',ntType);
            if (this.state.pageFitType) this.noteLet.setFitType(this.state.pageFitType);
            if (this.state.dragType) this.noteLet.setDragType(this.state.dragType);

            if(ntType==='book') {
                this.noteLet.setFitType('width');
                this.noteLet.setDragType('vertical');
            } else {
                this.noteLet.setFitType('page');
                this.noteLet.setDragType('none');
            }

            rzlog.debug("ftnote.update RELOAD : bg.pg=",this.noteLet.bgPageNo," seq=", this.seq, ',noteId=', noteId, ',cpg=', cpg, ",pgInfo=", pgInfo, ",tr=", tr);
//            this.noteLet.clearBgImg();
            let r02 = await noteRepo?.getPageInfo(pg);
            let pgInfo2 = r02.data;
            //this.doSetShowOff(false);
            this.noteLet.renderPathAll(true);
            
        }

        this.forceUpdate();
        if (this.props.onLoad) this.props.onLoad(this.loadCnt++);

    }

 
    onRepoLoaded(noteRepo: RzNoteRepo) {

        let ctx=this.getCtx();
        if(ctx.isEditDisabled) return;

        let r=this.getNoteSharing();
        console.log('================')
        console.log(r)
        console.log('================')

        if(!r) return;

        //@ this.nullPenOn=false;
        //@ this.curPenAttr=null;
        //@ this.curPen=null;

        this.nullPenOn=false;
        this.prevPenAttr=null;
        this.noteLet?.setPenEnabled(true);
//  if repo IsBook
        if(noteRepo.getType()==='book') {
            this.noteLet?.setCanvasColor('#505050');
        }else {
            this.noteLet?.setCanvasColor('#FFFFFF');
        }
            

        this.noteLet?.setBorderWidth(1);

        this.doProcSharing(r,true);
    }

 

    refresh() {
        this.noteLet?.renderPathAll(true);
    }

    setSize(w: number, h: number) {
        if (!this.noteDivRef.current) return;
        this.noteDivRef.current.style.width = w + 'px';
        this.noteDivRef.current.style.height = h + 'px';
    }
    /************** */
    undo() {
        this.doUndo();
    }

    doUndo() {
        this.noteLet?.undoPath(1);
    }

    /**************** */
    doSetNoteTool(tool: FtxNoteTool,isFromNullPen ?:boolean) {
 
        //@if( tool?.toolId!=='null' && this.nullPenOn) {
        if( tool.toolId=='null') {
            this.doSetPenNull(true);
            return;
        } else {
            //false인 경우가 있어서 true 바꿔줌 추가
            this.noteLet?.setPenEnabled(true);
        }

        if(isFromNullPen) this.doSetPenNull(false);

       
        if(Boolean(this.state.isEditDisabled) === true) return;

        this.setState({ tool: tool });

        let pa = this.toPenAttr(tool);
        rzlog.debug("toPenAttr : PENTOOL.attr=",pa);
        if (this.noteLet) {
            this.noteLet.setPencil(pa);
            //@ if(pa.moveOffX!=undefined || pa.moveOffY!=undefined) this.noteLet.setOffset(pa.moveOffX||0,pa.moveOffY||0);
        }
    }

    setCanvasSize(w:number,h:number){
        rzlog.debug("ftnote.SET CANVAS SIZE.0(Resize) : w=",w,',h=',h);
        this.noteLet?.setCanvasSize(w,h);
    }
    getCanvasSize():RzSize|null{
        return this.noteLet?.getCanvasSize()||null;
    }


    toPenAttr(tool?: FtxNoteTool): RzPenAttr {
        let ty = tool?.toolId;
        let pt: RzPenTypeEnum | null = null;
        let defOpacity: number | null = null;


        if (ty == FtxNoteToolEnum.PEN) pt = RzPenTypeEnum.PEN;
        else if (ty == FtxNoteToolEnum.MARKER) {
            pt = RzPenTypeEnum.MARKER;
            defOpacity = 0.3;
        } else if (ty == FtxNoteToolEnum.ERASER) pt = RzPenTypeEnum.ERASER;
        else if (ty == FtxNoteToolEnum.MEMO) pt = RzPenTypeEnum.MEMO;

        else if (ty == FtxNoteToolEnum.TEXT) pt = RzPenTypeEnum.TEXT;
        else if (ty == FtxNoteToolEnum.DRAG) pt = RzPenTypeEnum.DRAG;

        let color: string | null = (tool?.curToolAttr?.color) ? tool.curToolAttr.color : null;
        let width: number | null = (tool?.curToolAttr?.width) ? tool.curToolAttr.width : null;
        let opacity: number | null = (tool?.curToolAttr?.opacity) ? tool.curToolAttr.opacity : null;

        let font: string | null = (tool?.curToolAttr?.fontName) ? tool.curToolAttr.fontName : null;
        let fsize: number | null = (tool?.curToolAttr?.fontSize) ? tool.curToolAttr.fontSize : null;
        let fcolor: string | null = (tool?.curToolAttr?.fontColor) ? tool.curToolAttr.fontColor : null;

        //no used yet
        let offx: number | null = (tool?.curToolAttr?.moveOffX!==undefined) ? tool.curToolAttr.moveOffX : null;
        let offy: number | null = (tool?.curToolAttr?.moveOffY!==undefined) ? tool.curToolAttr.moveOffY : null;

        let pa = {
            type: pt || RzPenTypeEnum.PEN,

            width: width || undefined,
            opacity: opacity || defOpacity || undefined,
            color: color || undefined,

            font: font || undefined,
            fontSize: fsize || undefined,
            fontColor: fcolor || undefined,

            moveOffX: offx || undefined,
            moveOffY: offy || undefined,
        } as RzPenAttr;

        return pa;
    }

    static getFocusedNote() {
        let nt = RzNote.getFocusedNote();
        if (!nt) {
            rzlog.error("no FocusedNote=",nt);
            return null;
        }
        let ftNote = (nt as any).parentObj;
        
        return ftNote;
    }

    setNoteSharing(sharing: RzoNoteShare){
            this.noteLet?.setSharing(sharing);
    }

    getNoteSharing():RzoNoteShare|undefined{
        return this.noteLet?.getSharing();
    }
}//class
import { ReactNode, createRef, CSSProperties } from "react";
import { CmComponent, RzBtn, RzCol, RzRow, RzTxt } from "./rzcomp";
import { FtCamFlat, FtCamLet } from "./ftcam.ui";
import { FtBasFrame, FtBothStat } from "./ftframe.bas.ui";

import { FtBtmStat } from "./ftbtm.ui";
import { FtChatFlat } from "./ftchat.ui";
import { FtTopMenu, ID_BOTH_CAM_ON, ID_MYCAM_ON, ID_OTHER_CAM_ON } from "./fttop.ui";
import { FtClassStat, ftMenuClickHandler } from "./ftframe.float2.ui";
import { FT_LAYOUT2_FRAME, FT_LAYOUT2_PORTRAIT } from "../../consts";
import { FtChatDlg } from "./ftdlg.ui";
import { WavleMediaClient } from "../../../wavle-media-client";


/****************************
 * 
 */
interface FtFloatProp {
    wavleClient?: WavleMediaClient;
    boundaryRef?: React.RefObject<HTMLDivElement>
}

interface FtFloatStat extends FtClassStat, FtBothStat {

}

function isIpad() {
    const userAgent = navigator.userAgent
    if (/Macintosh/.test(userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1) {
      return true; 
    }       
    return /iPad/.test(userAgent);
  }

 function isTablet() {
    const userAgent = navigator.userAgent.toLowerCase();                
    const isGenericTablet = /(tablet|ipad|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
             
    return isGenericTablet || isIpad();
  } 

export class FtPortFloatFrame extends FtBasFrame<FtFloatProp, FtFloatStat>  {

    constructor(pr: any) {
        super(pr);
        this.state = { isMyCamFocused: false, isOtherCamFocused: false };
    }

    componentDidMount() {

        let ctx = this.getCtx();        
        if (ctx.deviceType === 'mobile') {
            document.getElementById('root')!.setAttribute('class', 'mobilecase');
        }
    }

    doDrawTop() {
        let w = 600;//900

        //23.11.15 ctx 학부모 참가여부 판단하여 추가-전웅구
        let ctx = this.getCtx();
        const isParentJoinOn = ctx.isParentJoinOn;        
        
        if (ctx?.deviceType =='mobile' && !isTablet()) {
            return (
                <RzRow style={{ width: '100%', backgroundColor: '#222222', justifyContent: 'space-between' }}>
                    <FtTopMenu wavleClient={this.props.wavleClient} menuType={'floatMobile'} style={{ width: w, border: '0px solid red' }} onClick={(id) => { this.doMenuClick(id); }} />        
                </RzRow>
            )
        }

        return (<RzRow style={{ width: '100%', backgroundColor: '#222222', justifyContent: 'space-between' }}>
            <FtTopMenu wavleClient={this.props.wavleClient} menuType={'float'} style={{ width: w, border: '0px solid red' }} onClick={(id) => { this.doMenuClick(id); }} />
            <RzRow>
                {isParentJoinOn && <RzBtn icon={(<span className="ftclass2-ic-tv" />)}
                    style={{ marginTop: 10, marginLeft: 30, paddingLeft: 10, borderRadius: 13, height: 24, width: 140, border: '2px solid #037DED' }}
                    title="학부모 참관중" />}
                <RzBtn style={{ marginTop: 10, marginLeft: 30, marginRight: 30 }} title="교재/노트 켜기"
                    onClick={() => { this.doShowNotes(); }} />
            </RzRow>
        </RzRow>);
    }

    isDlgOn = false;
    isDbgOn = false;
    chatFlatRef = createRef<FtChatFlat>();
    doDrawBody() {

        let ctx = this.getCtx();
        let leftOn = this.state.isMyCamFocused;//||ctx.isMyCamFocused;
        let rightOn = this.state.isOtherCamFocused;//ctx.isOtherCamFocused;

        let focusAt = (leftOn) ? 0 : ((rightOn) ? 1 : -1);

        let chatFlatW = '100%';
        let chatFlatH = 234;

        let chatOff = Boolean(ctx.setting?.isChatOff);
        if (!this.isDlgOn && focusAt == -1) {
            this.isDlgOn = true;
            //alert('dd focusAt='+focusAt);
        }

        /* 23.11.15 채팅 고정해제 추가 */
        let chatW = 699;
        let chatH = 234;

        let h = window.innerHeight - 40 - 50 - 10;
        let w = window.innerWidth - 10;

        let chatY = h - 210;
        let chatX = w - chatW + 8;

        let chatDlgY = h - 430;
        //let chatDlgX = w - 380;
        let chatDlgX = 57;

        let isChatDlgOn = this.state.isChatDlgOn;
        let isChatOn = !Boolean(ctx.setting?.isChatOff)
        let isNotesOff = ctx.setting?.isNotesOff || false;

        let mt = { marginTop: 0 } as CSSProperties;
        if (isNotesOff) mt = { marginTop : 0};
        let isCamOn = true;
        // let dbgSt=this.isDbgOn?{border:'1px solid red'}:{};

        let vHM = '50%';
        let vHH = '50%';
        let dbgSt= {}; // 기본보기
        let dbgStM = {}
        let dbgStH = {}
        if (leftOn) {
            dbgSt = {
                paddingLeft: 0, paddingRight: 0,
            };
            dbgStM ={
                height: '76%', minWidth: '100%'
            };
            dbgStH ={
                height: '24%'
            };
        } // 나를 크게보기
        if (rightOn) {
            dbgSt = {
                flexDirection: 'column-reverse', paddingLeft: 0, paddingRight: 0,
            };
            dbgStM ={
                height: '24%'
            };
            dbgStH ={
                height: '76%', minWidth: '100%'
            };
        } // 학생 크게보기
        
        if ((isChatDlgOn)) {
            if (leftOn) {
                dbgSt = {
                    paddingLeft: 0, paddingRight: 0, alignItems: isChatOn?'flex-end':'center', justifyContent: 'flex-start'
                };
                dbgStM ={
                    height: '60%', minWidth: '100%'
                };
                dbgStH ={
                    height: 260, marginRight: 20
                };
            } // 나를 크게보기
            if (rightOn) {
                dbgSt = {
                    flexDirection: 'column-reverse', paddingLeft: 0, paddingRight: 0, alignItems: isChatOn?'flex-end':'center', justifyContent: 'flex-end'
                };
                dbgStM ={
                    height: 260, marginRight: 20
                };
                dbgStH ={
                    height: '60%', minWidth: '100%'
                };
            } // 학생 크게보기
            
        } // 채팅창 해제 

        if (ctx?.deviceType =='mobile') {
            return (
                <RzRow style={{ width: '100%', backgroundColor: '#222222', justifyContent: 'space-between', flexDirection: 'column' }} className="mobileBasicH">
                    <RzCol style={{flexDirection:'column', width:'100%', height:'100%', backgroundColor:'#222222', paddingTop: 20, paddingLeft: 25, paddingRight: 25, paddingBottom: 20,
                        alignItems:'center', boxSizing:'border-box', gap:20, ...dbgSt}}>
                    
                    <FtCamLet isMyCam={true} style={{height:vHM, maxWidth:'100%', aspectRatio:'2 / 1.2',   ...dbgStM, backgroundColor:'#111111'}}/>
                    <FtCamLet style={{height:vHH, maxWidth:'100%', aspectRatio:'2 / 1.2',   ...dbgStH, backgroundColor:'#111111'}}/>
                    
                    {/* {(isCamDlgOn)&&(<FtCamDlg style={{left:camDlgX, top:camDlgY, zIndex:9}} boundaryRef={this.props.boundaryRef} isResizable={true}/>)} */}

                    </RzCol>
                    <RzRow style={{ width: '100%', justifyContent: 'center', ...mt }}>
                        {/* {(!chatOff)&&(<FtChatFlat style={{width:chatFlatW,height:chatFlatH}}/>)}  */}
                        {(isChatOn && !isChatDlgOn) && (<FtChatFlat ref={this.chatFlatRef}
                            style={{ width: chatFlatW, 
                                height: chatFlatH + 30 }}
                            onToggle={(b) => {
                                if (this.chatFlatRef.current) {
                                    let r = this.chatFlatRef.current.getClientRect();
                                    this.setState({ isChatDlgOn: b, chatDlgX: r?.left, chatDlgY: r?.top });

                                    //  alert('client Rect='+JSON.stringify(r));
                                } else this.setState({ isChatDlgOn: b });
                            }}
                        />)}
                    </RzRow>
                </RzRow>
            )
        }

        return (<RzCol style={{ width: '100%', height: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
            {/* <RzRow style={{ width: '100%', height: 40 }}></RzRow> */}
            {/* <RzRow style={{ backgroundColor: '#222222' }}>
                <FtCamFlat focusAt={focusAt} />
            </RzRow> */}
            <RzRow style={{ width: '100%', justifyContent: 'center', ...mt }}>
                {/* {(!chatOff)&&(<FtChatFlat style={{width:chatFlatW,height:chatFlatH}}/>)}  */}
                {(isChatOn && !isChatDlgOn) && (<FtChatFlat ref={this.chatFlatRef}
                    style={{ width: chatFlatW, 
                        height: chatFlatH + 30 }}
                    onToggle={(b) => {
                        if (this.chatFlatRef.current) {
                            let r = this.chatFlatRef.current.getClientRect();
                            this.setState({ isChatDlgOn: b, chatDlgX: r?.left, chatDlgY: r?.top });

                            //  alert('client Rect='+JSON.stringify(r));
                        } else this.setState({ isChatDlgOn: b });
                    }}
                />)}
            </RzRow>
            <RzCol style={{flexDirection:'column', width:'100%', height:'100%', backgroundColor:'#222222', paddingTop: 20, paddingLeft: 25, paddingRight: 25, paddingBottom: 20,
                        alignItems:'center', boxSizing:'border-box', gap:20, ...dbgSt}}>
                    {(isCamOn)&&(<>
                    <FtCamLet isMyCam={true} style={{height:vHM, maxWidth:'100%', aspectRatio:'2 / 1.2',   ...dbgStM, backgroundColor:'#111111'}}/>
                    <FtCamLet style={{height:vHH, maxWidth:'100%', aspectRatio:'2 / 1.2',   ...dbgStH, backgroundColor:'#111111'}}/>
                    </>)}
            </RzCol>
            

            {(isChatOn && isChatDlgOn) && (<FtChatDlg
                style={{ top: chatDlgY, left: chatDlgX, width: 340, height: 403, }}
                isDlgOn={isChatDlgOn} onToggle={(b) => {

                    this.setState({ isChatDlgOn: b });
                }}
            />)}

            {/* <RzRow style={{ justifyContent: 'flex-end', height: 1 }}></RzRow> */}
        </RzCol>
        );
    }

    doDrawBottom() {
        return (<FtBtmStat />)
    }

    /********* */
    doMenuClick(id: string) {
        if (id == ID_BOTH_CAM_ON) {
            this.doFocus(false, false);
        } else if (id == ID_OTHER_CAM_ON) {
            this.doFocus(false, true);
        } else if (id == ID_MYCAM_ON) {
            this.doFocus(true, false);
        } else {
            let ctx = this.getCtx();
            ftMenuClickHandler(id, ctx, (v) => { this.setState(v); }, this.state);
        }
    }
    /********* */
    doShowNotes() {
        let ctx = this.getCtx();


        // ctx.setting = { ...ctx.setting, isNotesOff: false };
        // ctx.setGlobalCtx({ ...ctx, layoutType: FT_LAYOUT2_FRAME });

        let sett = ctx.setting || {}
        sett = { ...sett, isNotesOff: false };

        ctx.setGlobalCtx({ ...ctx, layoutType: FT_LAYOUT2_PORTRAIT, setting: sett });
    }

    /********* */

    doFocus(my: boolean, other: boolean) {
        this.setState({ isMyCamFocused: my, isOtherCamFocused: other });
    }
}//class
import { CSSProperties, Component, ReactNode, createRef } from "react";
import { CmBindMouseOutEvent, CmComponent, RzBasProp, RzBtn, RzCol, RzRow, RzTxt, RzTxtIn } from "./rzcomp";
import { FtUiContext } from "../ftclass2/ftui.context";
import { FtxNote, FtxNoteTool } from "../../dto/ftclass2.dto";
import { FtNoteLet } from "./ftnote.ui";
import { DefMemos, DefNotes, cmToFtxNotes } from "../../repo/ftclass2.repo.default";
import { IsFail, RzPenAttr, RzPoint, RzRect, RzReq, RzScale, rzlog } from "../../inc";
import { FtSrcOpt, FtoClassInfo, FtoClassNotiEnum, FtoEditLock, FtoMemo, FtoNoteInfo, FtoPageInfo } from "../../dto/ftclass.dto";
import { FtNoteDlg } from "./ftdlg.ui";
import { RzoNoteInfo } from "../../note.ui/rznote.ui.dto";
import { FtEditLockTool } from "../../ftclass.perms";
import { FtDeskEvent, FtDeskEventEnum } from "../ftclass/ftdesk.ui.event";
import { FtClassEvent, FtClassEventEnum } from "../../ftclass.event";
import { isVisible } from "@testing-library/user-event/dist/utils";
import { FtClassRepoMng } from "../../repo/ftclass.repo-mng";
import { FtClassUiLayoutSubPopBar_setDbg } from "../layout/ftclass.ui.layout.sub.pops";
import { DEF_PEN, DEF_PEN_COLOR, DEF_PEN_WIDTH } from "./fttop.ui";


const rzIs=rzlog.makeDefs();



let isDbgBdr = false;
/*********************** */
class PenToolNd {
    noteId?: string;
    tool?: FtxNoteTool;
}


const cmGetPageFromPages=(note: FtxNote, pages: FtoPageInfo[]): FtoPageInfo | null => {
    let vs = pages.filter(t => t.noteId && t.noteId === note.noteId);
    if (vs.length == 0) return null;
    return vs[0];
}

export const cmToFxNotesFromPages=(pages: FtoPageInfo[], notes: FtxNote[],isEditLock?: FtoEditLock): FtxNote[] =>{
    let newNotes = notes.map(t => {

        let pg = cmGetPageFromPages(t, pages);
        if(rzIs.t) rzlog.debug("doBindNoteInfoByClassEvent(pages.put): pg=", pg, ',editLock=', isEditLock, ',t=', t);
        let bvisible=true;
        if (pg) {
            let floatingOn={isFloatingOn:false};
            let showOn={isShowOn:true};


            if(pg.isFloatingOn===undefined) {
                floatingOn={isFloatingOn:false};
            } else {
                floatingOn={isFloatingOn:pg.isFloatingOn};
            }

            if(t.isFloatingOn) floatingOn={isFloatingOn:true};
        
            if(pg.isShowOn===undefined) {
                showOn={isShowOn:true};
            } else {
                showOn={isShowOn:pg.isShowOn};
            }
            

            let nd:FtxNote={ ...t, visible :bvisible, 
                                ...showOn, ...floatingOn,
                                    isSelected:undefined,
                                    pageAt:undefined,
                                };
            //DONT TOUCH  THIS !!!!!!!!!!!!!!!!!!!!!!!!!!! 
            if (isEditLock) nd= { ...t, 
                                isSelected: (pg.selected==undefined)?t.isSelected:pg.selected, 
                                pageAt: pg.pageNo || t.pageAt, 
                                //pageCount: pg.||0,
                                visible:bvisible, ...showOn, ...floatingOn,};

            //@ curNoteId=nd.noteId;
            return nd;
        } else {
            if (isEditLock) return t;
            // return {... t, isSelected:false};
            return {...t,    isSelected: false} as FtxNote;
        } 
    });

    return newNotes;
}


//pages: FtoPageInfo[], notes: FtxNote[],isEditLock?: FtoEditLock
    
export const cmToPagesFromFtxNotes=( notes: FtxNote[],isCastOn:boolean): FtoPageInfo[] =>{
    
    let newPages = notes.map(t => {
            
            //for castOn
            let sels=isCastOn?{
                noteId:t.noteId,
                selected:t.isSelected, 
                pageNo:t.pageAt } : {}; 

            let {isShareOn,isShowOn,info, 
                 isFloatable,isMemoOn,
                 isSelected,
                 pageAt,pageCount, 
                 isMirrorOn, ...nt}=t;

                return {...nt, ...sels, total:t.pageCount,
                };
        } );

        rzlog.debug("onTab : cmToPagesFromFtxNotes : pages=",newPages, ',notes=',notes)

    return newPages;
}



/*********************** */

const toPopNotes = (notes: FtxNote[], ownerName?: string) => {
    let nt = notes.filter(t => {

        //@ if (!t.isShowOn && (t.isFloatingOn && ownerName)) return true;
        if ( Boolean (t.isFloatingOn)) return true;

        return false;
    });
    nt = nt.sort((t1, t2) => (t2.lastPopSeq || 0) - (t1.lastPopSeq || 0));
   // console.log('toPopNotes  ===> ', nt);
    return nt;

}


const toShowNotes = (nt: FtxNote[],  onwerName?: string) => {
    // console.log("toShowNotes =====================>", nt)
    nt = nt.filter(t => {
        if (t.isShowOn && !Boolean(t.isFloatingOn)) return true;
        
        return false;
    });
    return nt;
}
 
/***************/
const defMemos = DefMemos;
/***************
 * 
 */
type NotePos = { noteId: string, x: number, y: number };

export interface FtNotesProp extends RzBasProp {
    isWritingLocked?: boolean
   
}

export interface FtNotesStat {
    isMemoListOn?: boolean;
    ftxNotes?: FtxNote[];
    selectedId?: string;
    classId?: string;
    isPageEditOn?: boolean;
    isCastOn?: boolean;
    noteId?: string;

    isLoaded?: boolean;

    notePos?: NotePos[];
    isEditLocked?: boolean;

    //toolId?:string;
    tool?:FtxNoteTool;
}


const ID_PAGECTRL_BAR = 'pageCtrlBar';

export class FtNotesLet extends CmComponent<FtNotesProp, FtNotesStat> {
    constructor(pr?: any) {
        super(pr);
        this.state = { classId: '1' , 
        };

    }
    scales:{[k:string]:{w:number,h:number}}={};
    offsets:{[k:string]:RzPoint}={};

    componentDidMount(): void {
        CmBindMouseOutEvent(ID_PAGECTRL_BAR, () => {
            this.setState({ isPageEditOn: false });
        })

       // let ctx = this.getCtx();

        let rmng = this.getRepoMng();
        if (!rmng) return;

        rmng.addOnEditLock((e) => {
            let ctx = this.getCtx();
            let isEditLockOn = !ctx.isCastOn;
            let ui = ctx.userInfo;
            if (ui && e.editLock) isEditLockOn = FtEditLockTool.isEditable(ui, e.editLock);
            let editLock = e.editLock;
            let ctrlLockOn=true;
            // this.setState({isEditLocked:isEditLock});

            if (e.editLock?.isOwnerLock == false && e.editLock?.isOtherLock == false) {
                this.setState({ isCastOn: undefined, isEditLocked: undefined });
                isEditLockOn = true;
                editLock = undefined;
            }

            if (!isEditLockOn) {
                let rmng = this.getRepoMng();
                if (rmng) rmng.isPageNotiOn = true;
                ctrlLockOn=true;
                                
            } else {
                let rmng = this.getRepoMng();
                if (rmng) rmng.isPageNotiOn = false;
                
                if (e.editLock?.userType=='teacher' && ui?.userType=='student'){
                    if( e.editLock && e.editLock.isOtherLock ==true) ctrlLockOn=false;
                    else   ctrlLockOn=true;
                }
            }

            rzlog.debug("OnEditLock:", e, ',isCastOn=', !isEditLockOn);
            ctx.setGlobalCtx({ ...ctx, editLock: editLock, isCastOn: !isEditLockOn , 
                isCtrlOn:ctrlLockOn});
        })

        let ctx = this.getCtx();
        console.log("FtNotesLet.componentDidMount : curTools=",ctx.curTools,',curTool=',ctx.curTool,",curNoteId=",ctx.curNoteId);   
        
        if(ctx.curTools && ctx.curTool){
            
            this.penTools=ctx.curTools as PenToolNd[];

            if(ctx.curNoteId){
                let curTool=this.getPenTool(ctx.curNoteId);
                this.mutPenTool(ctx.curNoteId, curTool,true);
            }
        } 
        // else if (!ctx.curTools && ! ctx.curTool&& ctx.curNoteId){
        //     let curTool={toolId:DEF_PEN,
        //         curToolAttr: { width: DEF_PEN_WIDTH, color: DEF_PEN_COLOR }
        //         };
        // }

        if(this.noteRef.current){
            //@THIS
            //this.noteRef.current.no
        }
        
        if(this.mainColRef.current?.divRef.current){
            rzlog.debug("FtNotes.addResize.. ");
            const resizeObserver = new ResizeObserver(() => {
                rzlog.debug("FtNotes.addResize : doResize2.. ");
                this.doResize();
                // Do what you want to do when the size of the element changes
              });
              resizeObserver.observe(this.mainColRef.current.divRef.current);
            //this.mainColRef.current.addEventListener('resize',(e)=>this.doResize(e));
        }

        rmng.addOnClassEvent(this.onClassEvent)

        this.doUpdateEditLock();
    }

    doResize(){
        if(!(this.mainColRef.current?.divRef.current)) return;
        if(!this.noteRef.current ) return;

        let r=this.mainColRef.current.divRef.current.getBoundingClientRect();
        let r2=this.noteRef.current.getClientRect();
        let r3=this.noteRef.current.getPreferRect();
        let c4=this.noteRef.current.getCanvasSize();
        rzlog.debug("FtNotes.doResize.. divRect.h=",r.height,
            ',noteRect.h=',r2?.height,
            ',prefer=',r3?.height,',canvas.h=',c4?.height);
        

        if(r&&r2 &&r3 && c4){
            // if canvas.height less than perfer.height 
            if(c4.height<r3.height){

                //if div.height bigger than prefer.height
                if(r3 &&r && r.height>r3.height){//
                    this.noteRef.current.setCanvasSize(r3.width,r3.height);      
                }
            }
            rzlog.debug("FtNotes.doResize : setSize(r3) -rect=",r,',r3=',r3);
            //this.noteRef.current.setSize(r3.width,r3.height);
            //this.noteRef.current.setCanvasSize(r3.width,r3.height);
        }
    }

    onClassEvent=(e:FtClassEvent)=>{
            if(e.srcOpt?.isCastOn) return;

            if (e.cmd == FtClassEventEnum.PAGES_PUT) {
                //if(e.srcOpt?.type=='ref') return;
                this.doBindNoteInfoByClassEvent(e.noteId!,e.pages!);
            }
    }

    componentWillUnmount(): void {
        let rmng = this.getRepoMng();
        if (rmng) rmng.delOnClassEvent(this.onClassEvent);    
    }


    penTools:PenToolNd[]=[];
    mutPenTool(noteId:string,tool?:FtxNoteTool,isSetCtx?:boolean) {
        if(!tool) return;
        let vs=this.penTools.filter(t=>t.noteId==noteId);
        if(vs.length==0){
            let nd:PenToolNd ={noteId:noteId, 
                    tool :{toolId:tool.toolId,  curToolAttr:{...tool.curToolAttr}}
                };
            this.penTools.push(nd);
            console.log("mutPenTool (curTools=): addTool!!  noteId=",noteId,',tool=',tool,',nd=',nd);
        }  else {
            let nd=vs[0];
            nd.tool={toolId:tool.toolId,  curToolAttr:{...tool.curToolAttr}};
            console.log("mutPenTool (curTools=): putTool!!  noteId=",noteId,',tool=',tool,',nd=',nd);
        }

        let ctx=this.getCtx();
        console.log("mutPenTool (curTools=): noteId=",noteId,',tool=',tool,',penTools=',this.penTools,',curTools=',ctx.curTools);
 
        //
        if(isSetCtx){ 
            let ctools=this.penTools.map(t=> {return {...t}});  
            ctx.setGlobalCtx({...ctx,
                        curNoteId:noteId,  
                        curTool: tool,
                        curTools:ctools});
        } 
    }

    getPenTool(noteId:string) {
        let vs=this.penTools.filter(t=>t.noteId==noteId);
        if(vs.length==0) return undefined;
        return vs[0].tool;
    }


    doBindNoteInfoByClassEvent(noteId:string,pages: FtoPageInfo[]) {
        rzlog.debug("doBindNoteInfoByClassEvent(pages.put): pages=", pages);
        let ctx = this.getCtx();

        let notes = ctx.notes;
        if (!notes) {
            return null;
        }

        if (pages.length == 0) return null;

        let ui = ctx.userInfo;
        let isEditLock = ctx.editLock;
        //@let curNote:FtxNote|undefined= ctx.curNote;
        let prevNoteId= ctx.curNoteId;
        let curNoteId= noteId||ctx.curNoteId;
        

       
        let newNotes=cmToFxNotesFromPages(pages,notes,isEditLock);
        //@ ctx.setGlobalCtx({ ...ctx, notes: vs,curNote:curNote, curNoteId: curNote?.noteId });

        let selNoteIds=newNotes.filter(t=>t.isSelected);
        let selNoteId=selNoteIds.length>0?selNoteIds[0].noteId:curNoteId!;
        ctx.setGlobalCtx({ ...ctx, notes: newNotes, notesFrom:'doBindNoteInfoByClassEvent', curNoteId: selNoteId });

        rzlog.debug('doBindNoteInfoByClassEvent.0(ppgs) : pages=',pages);
        rzlog.debug('doBindNoteInfoByClassEvent.1(ppgs) : selNtId=',selNoteId,', newNotes=',newNotes);
        rzlog.debug('doBindNoteInfoByClassEvent.2(ppgs) : oldNotes=',notes);

        return notes;
    }


    doUpdateEditLock() {
        let ctx = this.getCtx();
        let ui = ctx.userInfo;

        let editLock = ctx.editLock;
        if (ui && editLock) {
            let isEditLock = FtEditLockTool.isEditable(ui, editLock);

            if (isEditLock !== this.state.isEditLocked) {

                //alert('EditLock : isLock='+isEditLock);
                this.setState({ isEditLocked: isEditLock, isCastOn: !isEditLock });
            }
        }
    }


    componentDidUpdate() {
        let ctx = this.getCtx();

        if (!Boolean(this.state.isLoaded)) {
            this.setState({ isLoaded: true }, async () => {
                await this.fetchNoteRepos();
                //this.setState({isLoading:false});
            })

        } else if (this.state.ftxNotes !== ctx.notes) {
            rzlog.debug(">> float2.nodes.notes.didUptate : ctx.notex!=st.notes", ctx.notes, ',st.note=', this.state.ftxNotes);
            this.setState({ ftxNotes: ctx.notes });
            // alert('NoteLet  : diff notes')
        }

        //@ let noteId = ctx.curNote?.noteId;

        let noteId = ctx.curNoteId;
        rzlog.debug('FtNotesLet.componentDidUpdate (ppgs): doTab cur.noteId=', noteId,',st.noteId=',this.state.noteId,'ctx.notes=',ctx.notes);
        if (noteId && this.state.noteId != noteId) {
            this.setState({ noteId: noteId, ftxNotes: ctx.notes });
        }


        if(this.state.tool !== ctx.curTool) {
            this.setState({tool:ctx.curTool});

            if(ctx.curTools && ctx.curTools.length==0){
                ctx.setGlobalCtx({...ctx,curTools:[{noteId:noteId,tool:ctx.curTool}]});
            }
        }

        this.doUpdateEditLock();
    }





    getSelNote(notes?: FtxNote[]) {
        if (!notes) return null;

        if (notes && notes.length > 0) {
            let vs = notes.filter(t => t.isSelected);
            if (vs.length > 0) {
                return vs[0];
            }
        }

        return null;
    }

    doBindPopNotes(notes?: FtxNote[]) {
        if (!notes) return [];
        if (notes.length == 0) return [];

        let ctx = this.getCtx();
        let ui = ctx.userInfo;
        console.log('doBindPopNotes ===> ', notes);
        let popNotes = toPopNotes(notes, ui?.username);
        let len = 0;
        do {

            let vs = popNotes.filter(t => Boolean(t.isPopFocused));
            let noPops = popNotes.filter(t => !Boolean(t.isPopFocused));

            if (popNotes.length == 0) break;

            if (vs.length == 0) {
                let tv = popNotes[0];
                tv.isPopFocused = true;
                continue;
            }

            len = vs.length;
            popNotes = [...noPops, ...vs];
        } while (len == 0);


        return popNotes;
    }


    mainColRef=createRef<RzCol>();
    rowRef = createRef<RzRow>();
    popFlatRef = createRef<RzCol>();
    noteRef = createRef<FtNoteLet>();

    isResized = false;
    render(): ReactNode {

        let ctx = this.getCtx();
        let st = this.props.style
        let isMemoListOn = Boolean(ctx.setting?.isMemoListOn);

        let h = this.props.style?.height;
        let w = this.props.style?.width;


        let memoSt = { height: '100%', width: 220, backgroundColor: 'white', border: '0px solid red', zIndex: 4 } as CSSProperties;

        let isLocked = this.state.isEditLocked || false;
        // let lockSt=isLocked?{border:'2px solid #00E124'}:{};
        /* 23.11.15 추가 */
        let isCast = this.state.isCastOn || false;
        let lockSt = {};
        if (isLocked) {
            lockSt = { border: '2px solid #00E124' };
        }
        if (isCast) {
            lockSt = { border: '2px solid #FF4C25' };
        }

        /** 23.11.28 노트공유 추가 */
       // let isNoteShareOn = ctx.sharing?.isNoteShareOn||false;

        let noteW = 755;
        let noteH = 1014;

        let memosWidth = 200;


        let notes = this.state.ftxNotes;
        let isNotesPopOn = false;

        let popNotesLeft = 100;//1000;
        let popNotes: FtxNote[] = [];
        let popTop = 100;//200;

        if (notes && notes.length > 0) {
            popNotes = this.doBindPopNotes(notes);
            if (popNotes.length > 0) isNotesPopOn = true;
            rzlog.debug("NOTES.render: popNotes=", popNotes);

            /** 23.11.28 노트공유 추가 */
           // notes[2].isShareOn = isNoteShareOn;
        }

        if (isLocked) { isNotesPopOn = false; }

        let note: FtxNote | null = null;
        note = this.getSelNote(notes);

        if(notes){
            let vs=notes.filter(t=>t.isSelected);
            if(vs.length>0) note=vs[0];
        }
        //let note=this.state.curNote;

        let tw = noteW - (isMemoListOn ? memosWidth : 0) * 0.76;
        let notesWidth = tw;

        if (this.rowRef.current) {
            let rr = this.rowRef.current.getClientRect();
            notesWidth = (rr?.width || 0);
            rzlog.debug("rowRef : width=", notesWidth);
        }

        let pageNo = (note && (note.pageAt !== undefined && note.pageAt !== null)) ? note.pageAt : 1;
        let pageTotal = note?.pageCount || 0;

        let isPageEditOn = Boolean(this.state.isPageEditOn);

        let isMyCastOn = this.state.isCastOn;
        let zIndexLock = 6;

        let isNoteDlgFlatOn = true;
        //let isNoteDlgsOn = false;
        let isNoteDlgsOn = true;

        let dbgBdr = isDbgBdr ? { border: '2px solid orange' } : {};
        //@ let curNote=ctx.curNote;
        let curNoteId=ctx.curNoteId;


//ref={this.mainColRef} flexDirection:'column',
        return (<RzCol ref={this.mainColRef}  style={{   ...st, overflow: 'auto', ...lockSt }}>
            {isLocked && (<RzCol style={{ width: w, height: h, zIndex: zIndexLock, backgroundColor: '#cccccc55', position: 'absolute' }} />)}
            <RzCol style={{ width: '100%', border: '0px solid blue', position: 'relative' }}>
                <FtNotesTabs ftxNotes={notes} isLocked={isLocked} onTabClick={(pNoteId,newNotes) => {
                     rzlog.debug(">>>>>>>>>>>> FtNotes.onTabClick : castOn=",isMyCastOn,",notes=",newNotes);
                    if (isMyCastOn) this.doCastSelected(pNoteId, newNotes);
                    this.doTabClicked(pNoteId,newNotes);
                }}
                    onNotesChanged={(tnotes, showns) => {
                        rzlog.debug(">>>>>>>>>>>> onNoteschanged : notes=", tnotes);
                        let isSkipSelected = !isMyCastOn;
                        this.doCastTabs(tnotes, isSkipSelected);
                        this.doPutScales();
                        ctx.setGlobalCtx({ ...ctx, notes: tnotes ,notesFrom:'onNotesChanged',});
                    }} />
                <RzRow style={{
                    width: '100%', position: 'absolute', top: 30, height: 1, zIndex: 3,
                    border: '0px solid red', justifyContent: 'flex-end'
                }}>
                    {this.doDrawPageCtrl(pageNo, pageTotal, isPageEditOn)}
                </RzRow>
            </RzCol>
            <RzRow style={{ position: 'relative', width: '100%', height: '100%', overflow: 'auto', }} className="ft2scroll">
                {(isMemoListOn) && (<FtMemoList style={memoSt} noteId={curNoteId} />)}
                <RzRow ref={this.rowRef} style={{ height: '100%', flex: '1 0 auto', justifyContent: 'center', alignItems: 'center', border: '0px solid red' }}>
                    <RzCol style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <FtNoteLet noteId={note?.noteId} ref={this.noteRef} pageNo={pageNo} 
                            srcBy={'main'}
                            onPageMove={(noteId, pgNo) => {
                                this.doBindPage(noteId, pgNo);
                            }}
                          
                            style={{ width: tw, height: noteH+1, backgroundColor: '#FFFCFC',
                                     border: '0px solid red' }} />
                    </RzCol>
                    {isNoteDlgFlatOn && (
                        <RzCol ref={this.popFlatRef} style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: 0, ...dbgBdr, zIndex: 0 }} >
                            {(isNotesPopOn && isNoteDlgsOn) && (<>
                                {popNotes && popNotes.map((t, i) => {
                                    let lx = popNotesLeft + i * 20;
                                    let ly = popTop + i * 20;
                                    if (this.state.notePos) {
                                        let vs = this.state.notePos.filter(tt => tt.noteId == t.noteId)
                                        if (vs.length > 0) {
                                            let tv = vs[0]; lx = tv.x; ly = tv.y;
                                        }
                                    }
                                    let pos = { left: lx, top: ly };
                                    if (t.lastPopX !== undefined && t.lastPopY !== undefined) {
                                        pos.left = t.lastPopX;
                                        pos.top = t.lastPopY;
                                    }

                                    let dlgSt = {
                                        ...((this.state.notePos) ? pos : pos), ...dbgBdr, zIndex: (t.isPopFocused) ? 3 : 2,
                                        width: 650, height: 930, borderRadius: 3
                                    };

                                    let scale:RzScale|null=null;
                                    
                                    if(t.noteId){
                                      let tsc=this.scales[t.noteId]||null  
                                      if(tsc)scale={w:tsc.w,h:tsc.h};
                                      rzlog.debug("Note.Pop[",t.noteId,"]. scale=",scale);
                                    } 

                                    let title = (t.isPopFocused) ? { ...t, title: t.title + ':focused' + i } : { ...t, title: t.title + ':nofocus' + i };

                                    return (<FtNoteDlg isRelative={true} visible={isNotesPopOn}
                                        onClose={(allNotes: FtxNote[]) => {
                                            this.doCastTabs(allNotes, true);
                                        }}
                                        onSelected={(dlg, b) => {
                                            rzlog.debug("ftnotes.setFocus:id=", dlg.state?.note?.noteId);
                                            dlg.setFocus();
                                        }}
                                        scale={scale||undefined}
                                        note={t} key={'npop-' + i}
                                        style={dlgSt}
                                    />);
                                }
                                )}
                            </>)}
                        </RzCol>
                    )}

                </RzRow>


            </RzRow>
        </RzCol>);
    }
    /************ */

    doIsEqualsNoteInf(note: FtxNote, pnotes?: FtxNote[]) {
        if (!pnotes) return false;

        let vs = pnotes.filter(t => t.noteId == note.noteId);
        if (vs.length == 0) return false;

        let t = vs[0];

        if (t.isFloatable !== note.isFloatable) return false;
        if (t.isFloatingOn !== note.isFloatingOn) return false;
        if (t.isShowOn !== note.isShowOn) return false;
        if (t.isShareOn !== note.isShareOn) return false;

        return true;

    }

    async doCastSelected(noteId: string, newNotes?: FtxNote[]) {

        let repoMng = this.getRepoMng();
        if (!repoMng) return;

 
        //@ADD
        let notes=newNotes;
        if (!notes) return;
        let tgtNotes=notes;


        //@deprecated
        //let noteInfos = this.cmToNoteInfos(tgtNotes);//noteInfos[this.curRepoIx]
        let noteInfos = cmToPagesFromFtxNotes(tgtNotes,true);//noteInfos[this.curRepoIx]
        //alert('doCastSelected.putNoteInfos:'+JSON.stringify(noteInfos));
        rzlog.debug("FtNotes.doCastSelected :(onTab) noteInfos=", noteInfos);
        let srcOpt:FtSrcOpt={isCastOn:true};
        await repoMng.putNoteInfoPages(noteInfos,srcOpt);

    
    }

    async doCastTabs(notes?: FtxNote[], isSkipSelected?: boolean) {
        if (!notes) return;
 

        let repoMng = this.getRepoMng();
        if (!repoMng) return;

 

        let tgtNotes = notes.map(t => {
            return {
                ...t,
                 isSelected: (isSkipSelected) ? undefined : t.isSelected,
                 pageAt: (isSkipSelected) ? undefined : t.pageAt
            };
        });

        //@RMV
       // let noteInfos = this.cmToNoteInfos(tgtNotes);//noteInfos[this.curRepoIx]
       let noteInfos = cmToPagesFromFtxNotes(tgtNotes,true);//noteInfos[this.curRepoIx]
        //alert('putNoteInfos:'+JSON.stringify(noteInfos));
        rzlog.debug("cmToNoteInfos : noteInfos=", noteInfos);

        let srcOpt:FtSrcOpt={isCastOn:true};
        await repoMng.putNoteInfoPages(noteInfos,srcOpt);
    }

 

    /************ */

    doDlgSelected(ctx: FtUiContext, dlg: FtNoteDlg, t: FtxNote, notes?: FtxNote[]) {

        rzlog.debug("NOTES =", notes);
        if (!notes) return;


        let vs = notes.map(t => {
            if (t.noteId == dlg.props.note?.noteId) return { ...t, isPopFocused: true };
            else return { ...t, isPopFocused: false };
        });

        let ntPos = {};
        let or = { x: 0, y: 0 };
        if (dlg.noteRef.current?.noteDivRef.current) {
            let r = dlg.noteRef.current.noteDivRef.current.getBoundingClientRect();//

            if (this.popFlatRef.current) {
                let tx1 = this.popFlatRef.current.getClientRect();
                if (tx1) {
                    or.x = tx1.left;
                    or.y = tx1.top;
                }
            }

            let tx = r.x - or.x;
            let ty = r.y - or.y - 50;

            let ops = this.state.notePos?.filter(ot => ot.noteId !== t.noteId);

            ntPos = { notePos: [...(ops || []), { noteId: t.noteId, x: tx, y: ty }] };
        }

        rzlog.debug('NOTES ntPos=', ntPos, ',org=', or);
        ctx.setGlobalCtx({ ...ctx, notes: vs });
        this.setState({ ...ntPos, ftxNotes: vs })
    }
    /************ */

    doDrawPageCtrl(pageNo: number, pageTotal: number, isPageEditOn?: boolean) {
        return (<RzRow id={ID_PAGECTRL_BAR} style={{
            position: 'relative', top: 0, width: 100, backgroundColor: '#484848',
            marginRight: 20, marginTop: 20,
            alignItems: 'center', justifyContent: 'center', borderRadius: 9, height: 18
        }}>
            <RzBtn icon={(<span style={{ marginTop: 8, color:'grey' }} className="ftclass2-ic-arw-left" />)}
                onClick={() => {    
                    if(pageNo <= 1) return 
                    else this.doArrowClicked("left"); }} />
            {(!isPageEditOn) && (<RzTxt text={'' + pageNo} textStyle={{ fontSize: 11 }}
                onClick={() => {
                    this.setState({ isPageEditOn: true });
                }} />)}
            {(isPageEditOn) && (<RzTxtIn text={'' + pageNo} style={{ width: 40 }} textStyle={{ color: 'red' }}
                onChange={(spageNo) => { this.doGoPage(spageNo); }}
            />)}
            <RzTxt textStyle={{ fontSize: 11 }} style={{ marginRight: 10, marginLeft: 10 }} text="/" />
            <RzTxt textStyle={{ fontSize: 11 }} text={'' + pageTotal} />

            <RzBtn style={{ marginLeft: 6 }} icon={(<span style={{ marginTop: 8 }} className="ftclass2-ic-arw-right" />)}
                onClick={() => { 
                    if(pageNo >= pageTotal) return 
                    else this.doArrowClicked("right"); }} />
        </RzRow>);
    }

    /******* */

    async doGoPage(pageNo: string) {
        this.setState({ isPageEditOn: false });

        let repoMng = this.getRepoMng();
        if (!repoMng) return;
        let ctx = this.getCtx();
        let note = this.getSelNote(ctx.notes);
        if (!note) return;

        try {
            let pgNo = parseInt(pageNo);
            if (pgNo > (note.pageCount || 0)) { alert('페이지 범위가 벗어남'); return; }
            if (pgNo < 1) { alert('페이지 범위가 벗어남'); return; }

            await repoMng.putNoteInfo(note.noteId!, { pageNo: pgNo });
            this.doBindPage(note.noteId, pgNo);
        } catch (e) {
            alert('페이지번호 오류:' + e);
        }
    }

    /************** */
    async doTabClicked(noteId: string, newNotes?: FtxNote[]) {
 

        let notes=newNotes;
        if (!notes) return;

        let note = notes.filter(t => t.noteId == noteId)[0];
        rzlog.debug("doTabClicked(onTab) : ntId=",noteId," note=", note);

        
        let ctx=this.getCtx();
        let oldTool=ctx.curTool;      
        let repoMng=this.getRepoMng();

        // fix getNoteShare 응답시간확인 오래걸림
        if(repoMng){
            repoMng.setCurNoteId(noteId);
            await repoMng.getNoteShare(noteId);
        }

        let pNoteId:string|null =null;
        if (this.noteRef.current) {
            pNoteId = this.noteRef.current.getNoteId()||null;
            if(pNoteId===noteId) return;

            if(pNoteId) {
                console.log('this.mutPenTool(curTools=) pNoteId=',pNoteId,',oldTool=',oldTool);
                this.mutPenTool(pNoteId, oldTool,false);
            }
            
            await this.noteRef.current.loadNote(noteId,{isSkip:!Boolean(ctx.isCastOn), isCastOn:ctx.isCastOn});
        }

        let newPenTool=this.getPenTool(noteId);
        if(!newPenTool){
            newPenTool={...oldTool};
            //toolId:oldTool?.toolId||DEF_PEN, curToolAttr:{...oldTool?.curToolAttr}
            console.log('this.mutPenTool (curTools=) =',noteId,',pnoteId=',pNoteId,',newPenTool=',newPenTool);
            this.mutPenTool(noteId, newPenTool,false);
        } else {
            console.log('this.mutPenTool.1 (curTools=) =',noteId,',pnoteId=',pNoteId,',newPenTool=',newPenTool);
        }
        
        this.doPutScales();
        this.doPutOffsets();

        let nscale=this.scales[noteId];
        let noffset=this.offsets[noteId];
        if(nscale&& this.noteRef.current) this.noteRef.current.setScale(nscale.w,nscale.h);
        else if(this.noteRef.current)this.noteRef.current.setScale(1,1);
        
        if(noffset&&this.noteRef.current) this.noteRef.current.setOffset(noffset.x,noffset.y);
        else if(this.noteRef.current)this.noteRef.current.setOffset(0,0);
 
        //let nnotes=notes.map(t=>{if(t.noteId==noteId) return {...t,isSelected:true}; return {...t,isSelected:false};}); 
        //ctx.setGlobalCtx({...ctx, curNoteId:noteId,notes:nnotes, notesFrom :'doTabClicked' ,curTool:newPenTool});
        rzlog.debug('doTabClicked : noteId=',noteId,',newNotes=',newNotes);

        ctx.setGlobalCtx({...ctx, 
                curNoteId: noteId,
                notes: newNotes, 
                notesFrom: 'doTabClicked',
                curTool: newPenTool,
                curTools:[...this.penTools],
            });
    }

    doPutScales()
    {
        if(this.noteRef.current){
            let scale=this.noteRef.current.getScale();
            let pNtId=this.noteRef.current.getNoteId();
            if(scale&&pNtId) this.scales={...this.scales,[pNtId]:scale};
        }
    }

    doPutOffsets()
    {
        if(this.noteRef.current){
            let offs=this.noteRef.current.getOffset();
            let pNtId=this.noteRef.current.getNoteId();
            if(offs&&pNtId) this.offsets={...this.offsets,[pNtId]:offs};
        }
    }

    /************* */

    // fix 미러링시 이벤트 확인해보기
    async doArrowClicked(dir: string) {
        let notes = this.state.ftxNotes;
        let note = this.getSelNote(notes);

        if (!note) return;

        let rmng = this.getRepoMng();
        if (!rmng) return;


       
        let noteId = note.noteId;
        if (dir === 'left') {
            //goPrevPage
            if(noteId) this.doGoNextPage(noteId, note, rmng, 'left' );

        } else if (dir === 'right') {
            //goNextPage
            if(noteId) this.doGoNextPage(noteId, note, rmng, 'right' );
        }
    }


    async doGoNextPage(noteId: string,note:FtxNote , rmng:FtClassRepoMng,dir: string) {
        let pgNo=0;
        if(dir =='left'&& (note.pageAt !== null && note.pageAt !== undefined) && note.pageAt > 0){
            pgNo = note.pageAt - 1;
        }else if(dir =='right'&& (note.pageAt !== null && note.pageAt !== undefined) && note.pageCount && note.pageAt <= note.pageCount - 1){
            pgNo = note.pageAt + 1;
        }
        
        if(noteId){
            let trr=await rmng.getNoteRepo(noteId);
            if(IsFail(trr)) return; 
            let repo=trr.data;
            //@FIXNEED
            //repo?.setCurPageNo(pgNo);

            repo?.refRepo?.setCurPageNo(pgNo);
            repo?.notiRepaint();
        }
    }


    doBindPage(noteId?: string, pgNo?: number) {
        let ctx = this.getCtx();
        let notes = ctx.notes;

        if (!notes) return;

        let tnotes = notes.map(t => { if (noteId == t.noteId) return { ...t, pageAt: pgNo }; return t; });
       
    

        ctx.setGlobalCtx({ ...ctx, notes: tnotes  });
    }
    /************* */

    defClassId = '1';
    async fetchNoteRepos() {
        
        let repo = this.getRepo();
        if (!repo) return;

        let classId = this.state.classId || this.defClassId;
        rzlog.debug("FtNotes.ui.fetchNoteRepos: clzId=", classId);

        let rq = { data: { classId: classId } } as RzReq<FtoClassInfo>;

        let r = await repo.getClassInfo(rq);
        if (IsFail(r)) return;

        let ctx = this.getCtx();

        
        let ui=ctx.userInfo;
        let noteShares=r.data?.classSharing?.noteSharings;
        //@let noteShares=ctx.sharing?.noteSharings;
        let repoNotes = r.data?.notes;
        let oldNotes=ctx.notes;


        console.log('******************** note sharing 초기값 **************************')
        console.log('noteShares **********************', noteShares)
        console.log('notes **********************', repoNotes)

        if (repoNotes && repoNotes.length > 0) {
            let ftxNotes = cmToFtxNotes(repoNotes,ui,noteShares,oldNotes); //notes.map(t=> {   return {...t}as FtxNote; });
            let selNo=0;
            if(this.state.ftxNotes){
               let tr= this.doBindOldSelectedNote(ftxNotes,this.state.ftxNotes);
               selNo=tr.selNo;
               ftxNotes=tr.ftxNotes;
            }


            let note = ftxNotes[selNo];
            note.isSelected = true;

            this.setState({ ftxNotes: ftxNotes });
            rzlog.debug("FtNotes.ui.fetchNoteRepos :selNo=",selNo," notes=", ftxNotes);



            ctx.setGlobalCtx({ ...ctx, notes: ftxNotes });
            this.setState({ ftxNotes: ftxNotes });
        }

    }

    doBindOldSelectedNote(notes:FtxNote[],stFtxNotes:FtxNote[] ){
        let selNo=0;
        let tnotes=stFtxNotes;// this.state.ftxNotes;
        let tns=notes.map((t,ix)=>{  
            let vs=tnotes.filter(tt=>tt.noteId==t.noteId);
            if(vs.length>0){
                if(vs[0].isSelected) selNo=ix;
                return {...t,isSelected:vs[0].isSelected};
            } 
            return t;
         });
         let ftxNotes=tns;
         return {selNo, ftxNotes};
    }

}//class

/******************** 
 * FtNotesTabs
*/


interface FtNotesTabsProp extends RzBasProp {
    onTabClick?: (id: string,notes?:FtxNote[]) => void;
    ftxNotes?: FtxNote[];
    isLocked?: boolean;
    onNotesChanged?: (notes?: FtxNote[], showns?: FtxNote[]) => void;
};

interface FtNotesTabsStat {

    ftxNotes?: FtxNote[];
    showNotes?: FtxNote[];
}

export class FtNotesTabs extends CmComponent<FtNotesTabsProp, FtNotesTabsStat>  {
    constructor(pr?: any) {
        super(pr);
        this.state = {};
    }

    static dlgPopCount = 0;

    componentDidMount(): void {
        //window.getSi
    }

    componentDidUpdate(prevProps: Readonly<FtNotesTabsProp>, prevState: Readonly<FtNotesTabsStat>, snapshot?: any): void {
        let ctx = this.getCtx();

        let notes: FtxNote[] | null = null;
        if (ctx.notes !== this.state.ftxNotes) {
            notes = ctx.notes || null;
            rzlog.debug(">>> float2.nodes.tabs.didUpdate.0 : !!! ctx.sh=", notes, ', st.ntz=', this.state.ftxNotes);
        }

        if (notes) {
            let ui = ctx.userInfo;
            let showNotes = toShowNotes(notes, ui?.username);
            let vs=showNotes.filter(t=>t.isSelected);

            if(!Boolean(ctx.isCastOn)){
                if(vs.length==0){
                    showNotes[0].isSelected=true;
                    rzlog.debug("SELECTED changed to 0")
                } 
            }

            if(notes&& notes?.length>0 && notes[0].isSelected==true ){
                let i=1;
            }

            rzlog.debug(">>> float2.nodes.tabs.didUpdate : showns=", showNotes, ',all=', notes);
            this.setState({ ftxNotes: notes, showNotes: showNotes });
        }
    }

    render() {
        let notes = this.state.showNotes;
        let isLocked = this.props.isLocked;
        rzlog.debug(">>> float2.nodes.tabs.render : showns=", notes);
        let rowSt: CSSProperties = {
            width: '100%', justifyContent: 'flex-start', height: 32,
            overflowX: 'auto', overflowY: 'hidden',
            backgroundColor: '#000000', border: '0px solid red'
        };
        return (<RzRow style={rowSt} className="ft2scroll">
            {notes && notes.map((t, i) => this.doDrawTab(t, i))};
        </RzRow>);
    }

    isFloatingBtnOn = false;
    doDrawTab(note: FtxNote, i: number) {
        let ctx = this.getCtx();
        let ui = ctx.userInfo;
        let uid=ui?.uid

        let ownerId = note.info?.ownerId
        let ownerNm=note.ownerName;

        //let isOwn = ownerId==uid ;
        //if(ownerId==undefined && uid ==undefined)isOwn = ownerNm == ui?.username;

        let tail = (<></>);
        let isFloatable = note.isFloatable;
        let exWidth = 0;

        let isDbgCss=false;
        let dbgCss=(isDbgCss)?{border:'1px solid red'}:{};
        // console.log('isFloatable ===> doDrawTab' , isFloatable, isOwn , ui?.username)
        if (isFloatable) {
            tail = (<span className="ftclass2-ic-maximize" style={{ marginLeft: 14, marginTop: 10 , ...dbgCss}} onClick={async (e) => {
                //alert('showFloating:e='+e);
                this.isFloatingBtnOn = true;
                await this.doToggleFloating(note);
                this.isFloatingBtnOn = false;

            }} />);
            exWidth = 20;
        } 

        let isSelected = note.isSelected;

        let btSt = { width: 150 + exWidth, alignItems: 'center', paddingLeft: 10, justifyContent: 'space-between' };
        let txSt = { fontSize: 13, fontWeight: 500, flexGrow: 1 };

        let noShareIcon = (<></>);
        if (!Boolean(note.isShareOn)) noShareIcon = (<span className="ftclass2-ic-tab-no-share" style={{ marginTop: 10, width: 26, marginRight: 0 }} />);
        if (note.isShareOn) noShareIcon = (<span className="ftclass2-ic-tab-share" style={{ marginTop: 10, width: 26, marginRight: 0 }} />);
        
        //if(isOwn) noShareIcon = (<span className="ftclass2-ic-tab-share" style={{ marginTop: 10, width: 26, marginRight: 0 }} />);

        let bgClr = '#000000';
        let isSel = (isSelected !== undefined) ? isSelected : note.isSelected;


        if (isFloatable) bgClr = '#2E2E2E';
        if (isSel) bgClr = '#037DED';

        let title = note.title;
        if (title && title.length > 8) title = title.substring(0, 8) + "...";

        return (<RzBtn key={'tab-' + i} icon={noShareIcon}
                    text={title} textStyle={txSt}
                    style={{ backgroundColor: bgClr, ...btSt }} tail={i>0 ?tail:''}
                    onClick={(e) => {
                        e.preventDefault();
                       // if(isSelected) return;
                        if (this.isFloatingBtnOn) return;
                        
                        this.doSelect(note.noteId);
                    }}
        />);
    }

    /*************** */
    doSelect(noteId?: string) {
        let notes = this.state.ftxNotes;//this.getCtx() as FtUiContext;
        if (!notes) return;

        notes = notes.map((t, i) => {
            if (t.noteId == noteId) {
                return { ...t, isSelected: true };
            }
            return { ...t, isSelected: false };
        })
        let ctx = this.getCtx();
        let ctNotes=ctx.notes;


        rzlog.debug(">>> float2.nodes.tabs.didUpdate.doSelect : !!! ctx.sh=", ctNotes, ', st.ntz=', this.state.ftxNotes);
        rzlog.debug(">>>>>>>>>>>> float2.nodes.tabs.doSelect : notes=", notes,);

        
        let ui = ctx.userInfo;
        
        let showNotes = toShowNotes(notes, ui?.username);
        this.setState({ ftxNotes: notes, showNotes: showNotes });

        let note=notes.filter(t=>t.noteId==noteId)[0];
       //@ ctx.setGlobalCtx({ ...ctx, notes: notes ,curNoteId:noteId});

        if (this.props.onTabClick && noteId) this.props.onTabClick(noteId,notes);
    }

    clearSelect() {
        let ctx = this.getCtx() as FtUiContext;
        if (!ctx.notes) {
            alert('no notes');
            return;
        };

        let notes = ctx.notes.map((t, i) => {
            return { ...t, isSelected: false };
        });
        ctx.setGlobalCtx({ ...ctx, notes: notes });
    }
    /*************** */

    doToggleFloating(nt: FtxNote) {
        let ctx = this.getCtx() as FtUiContext;
        if (!this.state.ftxNotes) {
            alert('no notes');
            return;
        }


        let selAt = -1;
        let tgtAt = -1;

        let notes = this.state.ftxNotes;
        notes = notes.map((t, i) => {
            if (t.isSelected) selAt = i;

            if (t.noteId == nt.noteId) {
                tgtAt = i;
                //alert('same Note='+t.noteId);
                //!Boolean(t.isFloatingOn)
                return { ...t, isFloatingOn: true, isShowOn: false, isSelected: false, isPopFocused: true, lastPopSeq: FtNotesTabs.dlgPopCount++ };
            } else return { ...t, isPopFocused: false };
        });
        rzlog.debug(">>> float2.nodes.tabs.floating.0 : setGlobal notes=", notes);



        // find New Select -------
        if (selAt >= tgtAt && tgtAt >= 0) {
            selAt = selAt - 1;
        }
 
        //------------------
 
        let ui = ctx.userInfo;
        let showns = toShowNotes(notes, ui?.username);
        this.setState({ ftxNotes: notes, showNotes: showns });

        //@ctx.setGlobalCtx({ ...ctx, notes: notes });
        rzlog.debug(">>> float2.nodes.tabs.floating : setGlobal notes=", notes, ',shown=', showns);
        
        if (this.props.onNotesChanged) this.props.onNotesChanged(notes, showns);

    }

}//class
/******************** */
interface FtMemoListProp extends RzBasProp {
    noteId?: string;
}

class FtxMemo { }

interface FtMemoListStat {
    memos?: FtxMemo[];
}

class FtMemoList extends CmComponent<FtMemoListProp, FtMemoListStat> {
    constructor(pr?: any) {
        super(pr);
        this.state = { memos: defMemos };
    }

    procClassEvent=(e:FtClassEvent)=>{
        let ctx=this.getCtx();
        let notes=ctx.notes;
        
        if(e.cmd==FtClassEventEnum.PATH_ADD){
            this.fetchMemoList();
        } else if(e.cmd==FtClassEventEnum.PATH_DEL){
            this.fetchMemoList();
        } else if(e.cmd==FtClassEventEnum.PATH_PUT){
            this.fetchMemoList();
        } else if(e.cmd==FtClassEventEnum.SHARING_PUT){
            this.fetchMemoList();
        } else if(e.cmd==FtClassEventEnum.NOTI_SEND){
            if (e.noti?.type==FtoClassNotiEnum.WRITING_SHARE_RES){
                this.fetchMemoList();
            }
        }
        //ctx.setGlobalCtx({...ctx, notes:vs});
    }

    componentDidMount(): void {
        //window.getSi
        let ctx=this.getCtx();

        if(ctx.repoMng) {            
            ctx.repoMng.addOnClassEvent(this.procClassEvent);
            ctx.repoMng.addOnDeskEvent(this.processDeskEvent);
        }
        
        this.fetchMemoList();
    }

    processDeskEvent=(e:FtDeskEvent)=>{

        rzlog.debug("FtMemoList.processDeskEvent : e=",e);

    }

    componentWillUnmount(): void {
        let ctx=this.getCtx();

        if(ctx.repoMng)ctx.repoMng.delOnClassEvent(this.procClassEvent);
    }

   

    componentDidUpdate(prevProps: Readonly<FtMemoListProp>, prevState: Readonly<FtMemoListStat>, snapshot?: any): void {
        if (prevProps.noteId !== this.props.noteId) {
            this.fetchMemoList();
        }
    }

    render() {
        let st = this.props.style;
        let memos = this.state.memos || [];
        let h = window.innerHeight;

        return (<RzCol style={{ ...st, width: 218, flexShrink: 0 }}>
            <RzCol style={{ height: h, backgroundColor: 'white' }}>
                <RzRow style={{
                    height: 40, paddingLeft: 10, paddingRight: 10, borderBottomStyle: 'solid', borderBottomWidth: 1, borderBottomColor: '#DEDEDE', alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <RzTxt textStyle={{ color: 'black', fontSize: 15, fontWeight: 600 }} text="메모목록" />
                    <RzBtn icon={(<span className="ftclass2-ic-close" style={{ marginTop: 2 }} />)} text=""
                        onClick={() => { this.doCloseMemoFlat() }} />
                </RzRow>
                {memos.map((t, i) => this.doDrawMemo(t, i))}
            </RzCol>
        </RzCol>);
    }

    doDrawMemo(t: FtoMemo, i: number) {
        let m=t.body||'';
        if (m.indexOf('\n') > 0) m = m.substring(0, m.indexOf('\n')) + ' ...';
        if(m.length>20){
            m=m.substring(0,20)+'...';
        }

        return (
            <RzRow key={'mlist=' + i} style={{ height: 40, paddingLeft: 10, paddingRight: 10, alignItems: 'center', borderBottomStyle: 'solid', borderBottomWidth: 1, borderBottomColor: '#DEDEDE', }} onClick={() => {if(t.pageNo) this.doGoPage(t.pageNo)}}>
                <RzTxt textStyle={{ color: 'black', fontSize: 13 }} text={m}/>
            </RzRow>
        )
    }

    doCloseMemoFlat() {
        let ctx = this.getCtx();
        let sett = ctx.setting || { isMemoListOn: false };

        if (sett?.isMemoListOn == true) sett = { ...sett, isMemoListOn: false };
        ctx.setGlobalCtx({ ...ctx, setting: sett });
    }
    /************* */
    async fetchMemoList() {
        let repo = this.getRepo();        
        let repoMng = this.getRepoMng();
        let noteId = this.props.noteId;                

        if (noteId) {
            let ctx = this.getCtx();
            let nts = ctx.notes;
            let note = nts?.find(el => el.noteId === noteId);

            let rmng = this.getRepoMng();
            // let ui = ctx.userInfo;
            let ui = repoMng?.getUserInfo();            
            
            let rq = { data: { noteId: noteId } };
            let r = await repo.findMemoList(rq);
            
            if(IsFail(r)) return;
            let memos = r.data;
            if(!memos) return;

            if (note) {
                let sh = rmng?.getNoteSharing(noteId);                
                if (!note.isShareOn || !sh?.isGroupWritingReadOn) {
                    memos = memos.filter((el:any) => el.drawerId === ui?.uid);
                }
            }
    
            this.setState({ memos: memos });
        }
    }

    /** 24.1.24 Add MemoList Click goPage */
    async doGoPage(pageNo: string) {
        // this.setState({ isPageEditOn: false });

        let repoMng = this.getRepoMng();
        if (!repoMng) return;
        let ctx = this.getCtx();
        let note = this.getSelNote(ctx.notes);
        if (!note) return;

        try {
            let pgNo = parseInt(pageNo);
            if (pgNo > (note.pageCount || 0)) { alert('페이지 범위가 벗어남'); return; }
            if (pgNo < 1) { alert('페이지 범위가 벗어남'); return; }

            await repoMng.putNoteInfo(note.noteId!, { pageNo: pgNo });
            this.doBindPage(note.noteId, pgNo);
        } catch (e) {
            alert('페이지번호 오류:' + e);
        }
    }

    getSelNote(notes?: FtxNote[]) {
        if (!notes) return null;

        if (notes && notes.length > 0) {
            let vs = notes.filter(t => t.isSelected);
            if (vs.length > 0) {
                return vs[0];
            }
        }

        return null;
    }

    doBindPage(noteId?: string, pgNo?: number) {
        let ctx = this.getCtx();
        let notes = ctx.notes;

        if (!notes) return;

        let tnotes = notes.map(t => { if (noteId == t.noteId) return { ...t, pageAt: pgNo }; return t; });

        ctx.setGlobalCtx({ ...ctx, notes: tnotes });
    }
    /** 24.1.24 Add MemoList Click goPage */
    
}//class

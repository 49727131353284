import { FT_LAYOUT_FRAME } from "../../consts";
import { FtLayoutFac } from "../../ftclass";
import { FtNoSplitLayout } from "../layout/ftclass.ui.layout.nosplit"
import { FtLayout } from "./ftclass.ui.layout";



/***********************************************
  * Fac
  */
export class FtLayoutDefFac implements FtLayoutFac{
  
    newLayout(type:string):FtLayout {
        let r  =  new FtNoSplitLayout()
        return r as FtLayout;
    }
}

import { FtoEditLock } from "../../dto/ftclass.dto";
import { FtFullLayout } from "./ftclass.ui.layout.full";
import { FtClassTop, FtPaletteEvent, FtPaletteEventEnum } from "../ftclass/ftclass.ui.top";
import { rzlog,RzUiLet, } from "../../inc";


/** dbg */
const rzIs=rzlog.makeDefs()
export const FtClassUiLayoutHdr_setDbg =rzIs.setDbg


export class FtLayoutHdr {
    lman:FtFullLayout;
    constructor(lman:FtFullLayout){
        this.lman=lman;
    }

 
    procPaletteEventByUI(e:FtPaletteEvent){
        let lman=this.lman;
        if(!lman.lets) return

        
    
        let noteLet=lman.lets!.note?.noteLet ||lman.lets!.note?.bookLet
 
        if(lman.curDeskType==='book')
            noteLet=lman.lets!.book?.bookLet ||lman.lets!.book?.noteLet

        if(rzIs.t) rzlog.debug(`LayoutHdr.procPaletteEventByUI.curDeskType=${lman.curDeskType},cmd=${e.cmd}, noteLet=`,noteLet,`, lets.note=`,lman.lets)
        if(! noteLet){
            rzlog.debug(`LayoutHdr.procPaletteEventByUI.curDeskType=${lman.curDeskType},cmd=${e.cmd}, noteLet=`,noteLet,`, lets.note=`,lman.lets)
            return;
        }

        if(e.cmd===FtPaletteEventEnum.COLOR){
            noteLet!.curPenAttr.color=e.color //'black'
            noteLet!.setPencil(noteLet!.curPenAttr)
        } else if(e.cmd===FtPaletteEventEnum.SIZE){
            noteLet!.curPenAttr.width=e.size //'black'
            noteLet!.setPencil(noteLet!.curPenAttr)
        } else if(e.cmd===FtPaletteEventEnum.CAST){
            //(this.lets!.ctrl! as FtClassTop ).setMyCastOn(e.isMyCastOn)
            let el:FtoEditLock={userType:e.castType,
                        isOwnerLock:e.isMyCastOn,
                        isOtherLock:e.isOtherCastOn, }

            lman.repoMng!.setEditLock(el)
            

        } else if(e.cmd===FtPaletteEventEnum.TOOL){
            if(!noteLet) return;

             

            if(e.type==='pen'){
                noteLet!.setPencil({type:e.type}) 
            }else if(e.type==='marker'){
                noteLet!.setPencil({type:e.type}) 
            }else if(e.type==='eraser'){
                noteLet!.setPencil({type:'eraser'}) 
            }else if(e.type==='drag'){
                noteLet!.setPencil({type:'drag'}) 
            }else if(e.type==='memo'){
                noteLet!.setPencil({type:'memo'}) 
            }else if(e.type==='text'){
                noteLet!.setPencil({type:e.type}) 
            } else if(e.type==='undo'){
                let prevType=e.prevType!
                noteLet!.undoPath(1)
                if(prevType){
                    setTimeout(()=>{
                        let tctrl:FtClassTop = lman.lets!.ctrl! as  FtClassTop
                        tctrl.setPaletteType(prevType)
                    },500)
                }
            }

        } else if(e.cmd===FtPaletteEventEnum.FONT){
           

            noteLet!.curPenAttr.font=e.font //'black'
            noteLet!.curPenAttr.fontSize=e.fontSize //'black'
            noteLet!.setPencil(noteLet!.curPenAttr)
        }
    }

}//class

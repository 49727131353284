/**
 * 
 * 
 */


import { FtSrcOpt, FtoPageInfo, FtoPath } from "../dto/ftclass.dto";
import { FtClassRepo, FtNoteRepoFac } from "./ftclass.repo";
import { FtClassRepoMng } from "./ftclass.repo-mng";
import { FtNotesRepo } from "./ftclass.repo.bas";
import { rzlog,NewFail,RzNoteRepo,RzOnFn, RzPenAttr } from "../inc";
import { NewOk, RzRes } from "../inc";
import { RzNoteEvent, RzNoteEventEnum } from "../inc";
import { RzoNotePath, RzoNoteShare } from "../note.ui/rznote.ui.dto";


/************** */
const rzIs=rzlog.makeDefs()
export const FtClassRepoRef_setDbg=rzIs.setDbg
 
 

/********** */

 export class FtNoteRefRepo extends RzNoteRepo  {
    repoMng:FtClassRepoMng
    pageCount=0
    constructor(repoMng:FtClassRepoMng){
        super()
        this.repoMng=repoMng
        rzlog.debug("FtNoteRefRepo.new ")
    }

    async load(opt?:any):Promise<RzRes<void>>{
        if(!this.repoMng.hasNote(this.noteId!)) {
           return NewFail("no note : noteId="+this.noteId);
        }

        let r=await this.repoMng.loadNote(this.noteId!,opt);
        if(!r || r.status>0) return NewFail<void>("FtClassRepo > repoMng.loadNote > " + r.message);

        let r1=await this.repoMng.getNoteRepo(this.noteId!);
        if(!r1 || r1.status>0) return NewFail<void>("FtClassRepo > repoMng.getNoteRepo > " + r1.message);

        
        let noteRepo=r1.data;
        if(rzIs.d) rzlog.debug('FtNoteRefRepo:noteRepo=',noteRepo)

        if(!noteRepo) return NewFail('no noteRepo in RepoMng : noteId='+this.noteId);
        if(noteRepo!==this){
            rzlog.error('FtNoteRefRepo.load : no noteRepo same repo : noteId=',this.noteId,',new.id=',noteRepo.noteId);
            Object.assign(this,noteRepo);
            this.repoMng.mutNoteRepo(this.noteId!,this);
            if(noteRepo.refRepo) noteRepo.refRepo.refRepo=this;

            //@ return NewFail('no noteRepo same repo : noteId='+this.noteId,2);
        }
       
        return NewOk<void>()
    }

    async save(opt?:any){
        let r= await this.repoMng.saveNote(this.noteId!,opt)
        if(r.status>0) return NewFail("FtClassRepo.save > "+r.message)
        return NewOk()
    }

    async getCurPageNoAsync(){
        let r=await this.repoMng.getCurPageNo(this.noteId!);
        if(r.status>0) return r;

        this.curPage=r.data!;
        return  NewOk(this.curPage);
    }

    async getAttr(pg:number,id:string){
        let tpage=pg||this.curPage;
        if(! this.repoMng) return NewFail("ClassRepoRef.getAttr : no repoMng");

        let r=await this.repoMng.getAttr(this.noteId!,tpage,id);
        return r;
    }
    
    // setPenAttr(attr: RzPenAttr, isNotiEvent?: boolean | undefined): void {
    //     if(this.noteId) this.repoMng.setNotePenAttr(this.noteId,attr,isNotiEvent);
    // }
 
    // getPenAttr(){
    //     return this.repoMng.getNotePenAttr(this.noteId!);
    // }

    getCurPageNo(){
        return this.curPage
    }

    async setCurPageNo(pgNo:number,srcOpt?:FtSrcOpt){
       
        rzlog.debug('FtClassRepoRef.setCurPageNo : pgNo=',pgNo,',pgCnt=',this.pageCount)
        if(pgNo<1) return
        if(pgNo>this.pageCount) return

        this.curPage=pgNo
        if(this.refRepo) this.refRepo.curPage=pgNo;

        await this.repoMng.putCurPageNo(this.noteId!, pgNo,srcOpt||{type:'ref'})
        if(srcOpt?.isCastOn){
            this.notiNoteEvent( new RzNoteEvent({cmd:RzNoteEventEnum.PAGES_PUT,  
                    noteId:this.noteId, page:pgNo , 
                    pages:[{noteId:this.noteId,pageNo:pgNo, selected:true} as FtoPageInfo], 
                    srcOpt:{type:'ref'}}));
            return;
        }
       if(srcOpt?.isSkip) return;
        this.notiNoteEvent( new RzNoteEvent({cmd:RzNoteEventEnum.PAGES_PUT,  noteId:this.noteId, page:pgNo , pages:[{pageNo:pgNo}]}))
    }

    async pathCount(){ return await this.repoMng.pathCount(this.noteId!)}


    async undoPath(pg:number,depth:number,srcOpt?:any):Promise<RzRes<RzoNotePath>>{
        let tpg=pg||this.curPage
        let r= await this.repoMng.undoPath(this.noteId!,tpg,depth,srcOpt)

        this.notiNoteEvent( new RzNoteEvent({cmd:RzNoteEventEnum.PATH_UNDO,  noteId:this.noteId, page:pg , depth:depth} ))
        return r
    }

    async redoPath(pg:number,depth:number,srcOpt?:any){
        let tpg=pg||this.curPage
        await this.repoMng.redoPath(this.noteId!,tpg,depth,srcOpt)
        return NewOk()
    }


    
    async addPath(path:RzoNotePath,pg?:number){ return await this.repoMng.addPath(this.noteId!,pg||this.curPage,path); }
    async putPath(path:RzoNotePath,pg?:number){ return await this.repoMng.putPath(this.noteId!,pg||this.curPage,path); }
    async delPath(path:RzoNotePath,pg?:number){ return await this.repoMng.delPath(this.noteId!,pg||this.curPage,path); }    
    async getPath(pg:number,id:string){ return this.repoMng.getPath(this.noteId!,pg,id) ;} 

    async setSharing(sharing?: RzoNoteShare | undefined, srcOpt?: FtSrcOpt | undefined): Promise<void> {
        if(sharing?.noteId) await this.repoMng.setNoteSharing(sharing.noteId,sharing,srcOpt);
    }

    getSharing():  RzoNoteShare  | undefined {
        let r=this.repoMng.getNoteSharing(this.noteId!);
        rzlog.debug("NOTE.getSharing : noteId=",this.noteId,',r=',r);
        return r;
    }

    
    async iterPath(pg?:number){ return await this.repoMng.iterPath(this.noteId!,pg||this.curPage) }
    async iterPathAll(type?:string){ return await this.repoMng.iterPathAll(this.noteId!,type) ;}    
    async size(){ return this.repoMng.getPageCount(this.noteId!) }

    getPageCount(){ return this.pageCount;}

    /******************************
     * Event
     */
    addOnNoteEvent(fn:RzOnFn, pr:any){
        this.addOnEvent(fn,pr)
    }

    delOnNoteEvent(fn:RzOnFn,pr:any){
        this.delOnEvent(fn,pr)
    }

    notiNoteEvent(ev:RzNoteEvent){
        this.notiEvent(ev)
    }

}//class

/********** */
export class FtNoteRefRepoFac implements FtNoteRepoFac {
     newRepo(repoMng:FtClassRepoMng){
        return new FtNoteRefRepo(repoMng)
    }
}

import { rzlog,RzUiLet,RzSplitLet,RzResizeLet,rzPut,rzGet } from "../../inc";
import { FtClassTop, FtPaletteEvent, FtPaletteEventEnum } from "../ftclass/ftclass.ui.top";
import { FtLayout, FtLayoutCtx, FtLayoutInit, FtLayoutOpts, FtScrollPos, FtUiLets } from "../ftclass/ftclass.ui.layout";
import { scale,RzRect }  from "../../inc";
import { FtSubTopInit } from "./ftclass.ui.layout.sub.top";
import { FtSubPopBarInit } from "./ftclass.ui.layout.sub.popbar";
import { FtLayoutHdr } from "./ftclass.ui.layout.hdr";
import { FtSubBodyInit  } from "./ftclass.ui.layout.sub.body";
import { FtSubPopBtnInit, FtSubPopTimeInit, FtSubPopVidInit } from "./ftclass.ui.layout.sub.pops";
import { FT_LAYOUT_FILLBOTTOM } from "../../consts";

/** dbg */
const rzIs=rzlog.makeDefs()
export const FtClassUiFullLayout_setDbg =rzIs.setDbg
 


/**
 * FtFullLayout
 */
export class FtFullLayout extends FtLayout {
    
    bodyLet?:RzUiLet
    //attrs?:any
  
    //curDeskType='note';
    curDeskType='book';

    eventHdr?:FtLayoutHdr;

    topSubInit?:FtLayoutInit;
    popBarSubInit?:FtLayoutInit;
    bodySubInit?:FtLayoutInit;

    popVidSubInit?:FtLayoutInit;
    popBtnSubInit?:FtLayoutInit;
    popTimeSubInit?:FtLayoutInit;
    popPageSubInit?:FtLayoutInit;

    timePopupPos:RzRect|null=null
    pagePopupPos:RzRect|null=null
    menuBtnPos:RzRect|null=null
    scrollPos:FtScrollPos={scrollY0:0,scrollDy:0};
    
    constructor(){
        super();

        this.topSubInit=new FtSubTopInit(this);
        this.popBarSubInit=new FtSubPopBarInit(this);
        this.bodySubInit=new FtSubBodyInit(this);

        this.popTimeSubInit=new FtSubPopTimeInit(this);
        this.popBtnSubInit=new FtSubPopBtnInit(this);
        this.popVidSubInit=new FtSubPopVidInit(this);
        

        this.eventHdr=new FtLayoutHdr(this);
    }


    doInit(opt:FtLayoutOpts){
        if(rzIs.d) rzlog.debug('FtFullLayout.doInit : targetDiv=',this.targetDiv)
        this.doUpdateUi(opt)

        this.scrollPos.scrollY0=window.scrollY
        window.addEventListener('scroll',(e)=>this.doScroll(e))
        window.addEventListener('resize',(e)=>this.doSize(e))

        return true
    }

    doSize(e:any){
        rzlog.debug("FtClassUiLayoutFull > FtFullLayout.resize: e=",e)
    }
    

    
    doScroll(e:Event){
        rzlog.debug("UlLayoutFull.doScroll : dy=",this.scrollPos.scrollDy," e=",e)
        this.scrollPos.scrollDy=window.scrollY - this.scrollPos.scrollY0;
        if(this.lets?.timePopup){
            if(!this.timePopupPos)this.timePopupPos=this.lets.timePopup.getBounds();

            let p=this.lets.timePopup.getBounds();
            this.lets.timePopup.setPos(p.x,this.timePopupPos!.y+this.scrollPos.scrollDy);
        }

        if(this.lets?.menuBtn){
            if(!this.menuBtnPos)this.menuBtnPos=this.lets.menuBtn.getBounds();

            let p=this.lets.menuBtn.getBounds();
            this.lets.menuBtn.setPos(p.x,this.menuBtnPos!.y+this.scrollPos.scrollDy);
        }
    }
    
    doBindLayoutOpt(opt:FtLayoutOpts){
        Object.assign(this.layoutOpts,opt);
        let nopt=this.layoutOpts;

        rzlog.debug('FtClassUiLayoutFull.doBindLayoutOpt: nopt=',nopt);

        if(nopt.layoutType==='video1'){
            
            nopt.isBookOn=false;
            nopt.isNoteOn=false;
            nopt.isChatOn=false;
            rzlog.debug('FtClassUiLayoutFull[layout] : nopt=',nopt);
        }
 

        nopt.width=document.body.clientWidth;
        nopt.height=document.body.clientHeight;

        return nopt;
    }


    doUpdateUi(opt:FtLayoutOpts) {
       
        let nopt=this.doBindLayoutOpt(opt)
        let fillBottom=FT_LAYOUT_FILLBOTTOM; 
        let p:RzUiLet|null = this.doInitMainBody();

        let lctx:FtLayoutCtx = { opt:nopt, 
            deskType:this.curDeskType,
            body:p,
            lets:this.lets!,
            fillBottom:fillBottom,
            timePopupPos:this.timePopupPos,
            menuBtnPos:this.menuBtnPos,
            pagePopupPos:this.pagePopupPos,
            subBody:null,
            repoMng:this.repoMng!,
        } 

        this.doInitTop(lctx)
        this.doInitPopMenu(lctx)
        this.doInitSubBody(lctx)

        this.doInitPopVid(lctx)
        this.doInitPopTime(lctx)
        this.doInitPopBtn(lctx)
        this.doInitPopPage(lctx)
 
        if(rzIs.d) rzlog.debug('FtFullLayout ; uilets=',this.lets)
    }

    doInitMainBody(){
        let p:RzUiLet|null=null;
        if(this.parentLet){
            p=this.parentLet
        } else {
            let css=`display:flex; flex-direction:column; 
                     width:100vw;height:100vh; `

            p=new RzUiLet({nativeDiv:this.targetDiv})
            if(rzIs.d) rzlog.debug('FullLayout.doUpdateUi :tagetDiv=',this.targetDiv)
            this.targetDiv!.className='ft_layout_full'
            this.targetDiv!.style.cssText=css;
            this.parentLet=p;

            (this.targetDiv as any)._layoutMng=this
        }
        return p;
    }


    doInitTop(lctx:FtLayoutCtx){
        this.topSubInit?.init(lctx);
    }

    doInitPopMenu(lctx:FtLayoutCtx){
        this.popBarSubInit?.init(lctx)
    }

    doInitPopVid(lctx:FtLayoutCtx){
        this.popVidSubInit?.init(lctx)
    }

    doInitPopTime(lctx:FtLayoutCtx){
        this.popTimeSubInit?.init(lctx);
    }

    doInitPopBtn(lctx:FtLayoutCtx){
        this.popBtnSubInit?.init(lctx);
    }

    doInitPopPage(lctx:FtLayoutCtx){
        this.popPageSubInit?.init(lctx);
    }

    doRemoveChild(p:RzUiLet, uilet:RzUiLet){
        uilet.setVisible(false)
        p.removeChild(uilet) 
    }

    doProcPaletteEventByUI(e:FtPaletteEvent){
        this.eventHdr?.procPaletteEventByUI(e)
    }

 

    doInitSubBody(lctx:FtLayoutCtx){
        this.bodySubInit?.init(lctx)
    }

    

    doUdateVisble(p:RzUiLet,uilet:RzUiLet,ix:number,cssText?:string){
        if(!uilet) return false
           // if(uilet.isVisible()) return true
        if(cssText) uilet.nativeDiv!.style.cssText=uilet.nativeDiv!.style.cssText+cssText
        uilet.setVisible(true)
        p.addChildAt(ix,uilet)
        return true
    }

    doUpdatePaletteOnDesk(tgtDesk:string,type:string,attrs:any){
        this.curDeskType=tgtDesk;

        if(attrs){
            if(rzIs.d)rzlog.debug('FtClassUiLayoutFull.doUpdatePaletteOnDesk : attrs=',attrs);
            (this.lets!.ctrl! as FtClassTop).setPaletteAttr(attrs);
            (this.lets!.ctrl! as FtClassTop).setPaletteType(attrs.type);
        }
        
        this.doProcPaletteEventByUI({cmd:FtPaletteEventEnum.TOOL,type:type})
                //         type:ltgt.lets!.book?.bookLet?.curPen.type,
                //     })
     }

    curFocusTgt?:any;
    doProcBookFocus(e:any,b:boolean){
        let tgt:any=e.target._srcLet.parent
        while(tgt.parent) tgt=tgt.parent
        
        if(rzIs.t) rzlog.trace("Layout.book.onFocus e=",e,",b=",b,',curDeskType=',tgt.curDeskType,",tgt=",tgt)
        if(b&& tgt){
            if(this.curFocusTgt!==tgt){
                this.curFocusTgt=tgt;
                let ltgt=tgt.nativeDiv._layoutMng;
                let ty=ltgt.lets!.book?.bookLet?.curPen.type;
                let tattr=ltgt.lets!.book?.bookLet?.curPenAttr!
                ltgt.doUpdatePaletteOnDesk('book',ty,tattr)
            }
        }  
    }
     

    doGetPLetByEvent(e:any){
        let tgt:any=e.target._srcLet.parent
        while(tgt.parent) tgt=tgt.parent
        return tgt
    }

    doProcNoteFocus(e:any,b:boolean){
        let tgt:any=e.target._srcLet.parent
        while(tgt.parent) tgt=tgt.parent
        //tgt=tgt._layoutMng

            if(rzIs.d) rzlog.debug("Layout.note.onFocus e=",e,",b=",b,',curDeskType=',tgt.curDeskType,",tgt=",tgt)
            let ltgt=tgt.nativeDiv._layoutMng;
            if(!ltgt) rzlog.error("not layoutManger!!!!:")
            if(b && ltgt){
                let ty=ltgt.lets.note?.noteLet?.curPen.type;
                let tattr=ltgt.lets.note?.noteLet?.curPenAttr;
                ltgt.doUpdatePaletteOnDesk('note',ty,tattr)
 
            } 
     }



    reinit(layoutType:string,opt:any){
        
        let nopt={...this.layoutOpts,...opt,layoutType:layoutType}

        this.doUpdateUi(nopt)
    }

    clearUi(){
        rzlog.debug('clearUi :!!')
        
        this.bodyLet=undefined;

        if(this.targetDiv&& this.parentLet){
            
            this.parentLet.removeChildAll()
            if(this.lets?.chat) this.parentLet.removeChild(this.lets.chat)

           // this.parentLet=undefined;
        }  
    }



    layout(rect:any){
       // if(this.ftbook) this.ftbook.onResize({})
       if(rzIs.d) rzlog.debug('FullLayout.layout : tgtDiv=',this.targetDiv,',this.lets=',this.lets)
        this.doLayout(rect)
    }

    doLayout(rect:any){
        if(rzIs.d) rzlog.debug('FullLayout.doLayout : tgtDiv=',this.targetDiv,',this.lets=',this.lets)
        this.doUpdateUi(this.layoutOpts)
    }
}//class



 

 
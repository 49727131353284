import { FtGetConnInfo, FtGetEnv } from "./config/consts";
import { FT_LAYOUT2_FLOAT2, FT_LAYOUT2_FRAME, FT_LAYOUT2_JOIN } from "./ftnote/consts";
 
import { FtClassRepoMngOpts } from "./ftnote/repo/ftclass.repo-mng";
import { FtUiContext } from "./ftnote/ui/ftclass2/ftui.context"
import { rzlog } from "./rzcmn";


export const CONF_STORED_USERINFO='storedUserInfo'
export const CONF_STORED_USERINFO_AT='storedUserInfoAt'
export const CONF_STORED_CLASS_ID='storedClassId'


export interface  FtConfig {
        webRtcUrl:string;
        webRtcIceUrls:string;
        isApiRedirect:boolean;
        apiUrl:string;
        repoType?:string;
        repoMemDefOn?:boolean;
        repoMngOpts?:FtClassRepoMngOpts;
}

const devConfig:FtConfig={
    apiUrl:'',
    isApiRedirect:false,
    //webRtcUrl:'wss://wss.wavle.center',
    //webRtcUrl:'ws://192.168.25.30:3005',       //turn
    webRtcUrl:'wss://ap.ftnote.rzdesk.com/vid',       //turn
    webRtcIceUrls:'stun:stun.l.google.com:19302',   //stun
}

const stgConfig:FtConfig={
    apiUrl:'',
    isApiRedirect:true,

    webRtcUrl:'wss://ap.ftnote.rzdesk.com/vid',       //turn
    webRtcIceUrls:'stun:stun.l.google.com:19302',   //stun
    // webRtcUrl:'wss://turn.rzdesk.com:8083',           //turn
    // webRtcIceUrls:'stun:stun.l.google.com:19302', //stun
}

const prdConfig:FtConfig={
    apiUrl:'',
    isApiRedirect:true,
    //webRtcUrl:'wss://wss.wavle.center',
    webRtcUrl:'wss://turn.rzdesk.com:8083',       //turn
    webRtcIceUrls:'stun:stun.l.google.com:19302',   //stun
}


export const getEnv=()=>{
    return FtGetEnv();
}

export const REPO_TYPE_MEM="mem";
export const REPO_TYPE_REPO_MNG="repoMng";
export const FT_JOIN_INTERVAL=200000;
export const FT_JOIN_INTERVAL2=2000;

export const DBG_ON=true;
export const DBG_MIRROR_ON=false;

export const  FtConfig_getConfig=():FtConfig=>{
    let env=getEnv();

    let cnf=devConfig;
    if(env=='stg') cnf=stgConfig;
    else if(env=='prd') cnf=prdConfig;


    let connInf=FtGetConnInfo(cnf.isApiRedirect);

    rzlog.debug("ConnInfo=",connInf);
    //"dev"

    let rcnf={...cnf,
        //repoType:REPO_TYPE_MEM, repoMemDefOn:true,

        repoType:REPO_TYPE_REPO_MNG,
        repoMngOpts:{
            connInfo:connInf,
        }
     };

    return rcnf;
}

/**
 * TODO: 초기데이터 넣는곳인듯?????
 * @param ctx 
 * @returns 
 */
export const FtConfig_initCtx=(ctx:FtUiContext):FtUiContext=>{
    let r={...ctx,
        camCount:1,
        videoCount:1,
        //layoutType:FT_LAYOUT2_FRAME,
        //layoutType:FT_LAYOUT2_FLOAT2,
        layoutType:FT_LAYOUT2_JOIN,
        isParentNotiOn:false,
        isParentJoinOn:false,
        setting:{isChatOff:false,isCamOff:false,},
        
        isBtmDbgOn:false,

        /* 23.11.28 */
        // sharing:{
        //     isWritingOn:false,
        //     isWritingMineOn:true,
        //     isWritingOtherOn:false,
        //     isReqShareWriting:false,         
        //     isReqAnswerShare:false,          
        //     isNoteShareOn:false,             
        //     isOtherWritingOn:false,          
        //     isOtherWritingMineOn:false,
        //     isOtherAnswerOn:false
        // } as FtxNoteSharing,

    }
    return r;
}
import { FtSrcOpt, FtoClassSharing,   } from "../dto/ftclass.dto";
import { IsFail, rzlog } from "../inc";
import { RzoNoteShare } from "../note.ui/rznote.ui.dto";
import { FtClassRepoMng } from "./ftclass.repo-mng";

const rzIs=rzlog.makeDefs();

export class FtClassShareReqProc {

    procSharePutFromRemote(repoMng: FtClassRepoMng, req:FtoClassSharing, srcOpt?:FtSrcOpt){
        rzlog.debug("FtClassShareReqProc.procSharePutFromRemote : req=",req);

        let ui=repoMng.getUserInfo();
        
        let curSharing=repoMng.getClassSharing();

        if(req.noteSharings){
            this.procNoteSharings(repoMng,req.noteSharings,srcOpt);
        } else if(req.isAnswerOn)  {

        }
        
    }


    async procNoteSharings(repoMng: FtClassRepoMng, noteSharings:RzoNoteShare[], srcOpt?:FtSrcOpt){
        rzlog.debug("FtClassShareReqProc.procNoteSharings : noteSharings=",noteSharings);
        if(!noteSharings)return;
        let vs=noteSharings;

        for (let i = 0; i < vs.length; i++) {
            let ns=vs[i];
            let noteId=ns.noteId;
            if(noteId===undefined)continue;

            let r=await repoMng.getNoteRepo(noteId);
            if(IsFail(r))continue;

            let repo=r.data;
            if(!repo)continue;
            repo.setSharing(ns,srcOpt);

    
        };
    }
}
import { CmComponent, RzBtn, RzCol, RzRow, RzTxt } from "./rzcomp";
import { FtBasFrame, FtBothStat } from "./ftframe.bas.ui";
import { FtChatFlat } from "./ftchat.ui";
import { CSSProperties, ReactNode, createRef } from "react";
import { FtBtmStat } from "./ftbtm.ui";
import { FtTopMenu, FtTopPalette, IDS_SETT_CHAT_OFF, ID_BOTH_CAM_ON, ID_MYCAM_ON, ID_OTHER_CAM_ON } from "./fttop.ui";
import { FtCamDlg, FtChatDlg, FtNoteDlg } from "./ftdlg.ui";
import { FtNotesLet } from "./ftnotes.ui";
import { ftBindChatOn } from "./ftframe.main.ui";
import { FtUiContext } from "../ftclass2/ftui.context";
import { rzlog } from "../../inc";
import { FtxNote } from "../../dto/ftclass2.dto";
import { WavleMediaClient } from "../../../wavle-media-client";
 

/******************** */

export interface FtClassStat {
    isMyCamFocused?:boolean;
    isOtherCamFocused?:boolean;
    isChatDlgOn?:boolean;
    isChatOn?:boolean;

    chatDlgX?:number;
    chatDlgY?:number;
}

 
/*******************
 * 
 */

interface FtFloat2Prop {
    wavleClient?: WavleMediaClient;
    boundaryRef?: React.RefObject<HTMLDivElement>
}

interface FtFloat2Stat extends FtClassStat, FtBothStat{
    notes?:FtxNote[];
    
}


export class FtPortFloat2Frame extends FtBasFrame<FtFloat2Prop,FtFloat2Stat>  {

    constructor(pr:any){
        super(pr);
        this.state={ isMyCamFocused:true, isOtherCamFocused:true,isChatOn:true, isChatDlgOn:false};
    }


    componentDidMount(){
        // super.componentDidMount();
        window.addEventListener('resize',(e)=>{
            this.forceUpdate();
        })
    }

    componentDidUpdate(prevProps: Readonly<FtFloat2Prop>, prevState: Readonly<FtFloat2Stat>, snapshot?: any): void {
        let ctx=this.getCtx();
        if(ctx.notes!==this.state.notes){
            this.setState({notes:ctx.notes});
        }
    }

    doDrawTop(){
 

        let w = 600;//900
        let ctx = this.getCtx();
        const isParentJoinOn = ctx.isParentJoinOn;

        return (<RzRow style={{ width: '100%', backgroundColor: '#222222', justifyContent: 'space-between', zIndex:8  }}>
            <FtTopMenu wavleClient={this.props.wavleClient} style={{ width: w, border: '0px solid red'}} onClick={(id) => { this.doMenuClick(id); }} />
            <RzRow>
                {isParentJoinOn && <RzBtn icon={(<span className="ftclass2-ic-tv" />)}
                    style={{ marginTop: 10, marginLeft: 30, paddingLeft: 10, borderRadius: 13, height: 24, width: 140, border: '2px solid #037DED' }}
                    title="학부모 참관중" />}                
            </RzRow>
        </RzRow>);
    }

    /********* */
    doMenuClick(id: string) {
        if (id == ID_BOTH_CAM_ON) {
            this.doFocus(false, false);
        } else if (id == ID_OTHER_CAM_ON) {
            this.doFocus(false, true);
        } else if (id == ID_MYCAM_ON) {
            this.doFocus(true, false);
        } else {
            let ctx = this.getCtx();
            ftMenuClickHandler(id, ctx, (v) => { this.setState(v); }, this.state);
        }
    }
    /********* */


    isDlgOn=false;
    chatFlatRef=createRef<FtChatFlat>();
    doDrawBody(){
        let ctx=this.getCtx();
       
        let leftOn=this.state.isMyCamFocused;
        let rightOn=this.state.isOtherCamFocused;
        let bothOn=leftOn && rightOn;
         
        let isChatDlgOn=this.state.isChatDlgOn;
        let isCamDlgOn=true;
   
        let chatW=340;
        let chatH=234;
       
        let h=window.innerHeight-40-50-10;
        let w=window.innerWidth-10;
        
        let chatY=h-390;
        let chatX=w-chatW+8;
        
        let chatDlgY=h-500;
        let chatDlgX=10;

        let camDlgY=h-500;
        let camDlgX=w-362;   

        let isCamOn=!Boolean(ctx.setting?.isCamOff);
        let isChatOn=!Boolean(ctx.setting?.isChatOff)

        rzlog.debug(">>>>> DrawBody : "+'isCamOn='+isCamOn,",ctx.camOff=",ctx.setting?.isCamOff);
        if(!this.isDlgOn &&!isCamOn){
            this.isDlgOn=true;
            //alert('isCamOn='+isCamOn);
        }
        let chatFlatW = '100%';
        let chatFlatH = 234;
        let mt = { marginTop: 0 } as CSSProperties;
        // if (isNotesOff) mt = { marginTop : 0};        
        // let dbgSt=this.isDbgOn?{border:'1px solid red'}:{};
        

        let bodySt={width:w,height:h ,justifyContent:'space-between',border:'0px solid red'  };
        
        return (<RzCol style={bodySt}>
                    <RzRow style={{ width: '100%', justifyContent: 'center', ...mt }}>
                        {/* {(!chatOff)&&(<FtChatFlat style={{width:chatFlatW,height:chatFlatH}}/>)}  */}
                        {(isChatOn && !isChatDlgOn) && (<FtChatFlat ref={this.chatFlatRef}
                            style={{ width: chatFlatW, 
                                height: chatFlatH + 30, zIndex: 7 }}
                            onToggle={(b) => {
                                if (this.chatFlatRef.current) {
                                    let r = this.chatFlatRef.current.getClientRect();
                                    this.setState({ isChatDlgOn: b, chatDlgX: r?.left, chatDlgY: r?.top });

                                    //  alert('client Rect='+JSON.stringify(r));
                                } else this.setState({ isChatDlgOn: b });
                            }}
                        />)}
                    </RzRow>
                    
                    <RzCol style={{paddingLeft:16,height:50,backgroundColor:'#383838'}}>
                        <FtTopPalette style={{backgroundColor:'#383838',marginTop:3,height:47}} />
                    </RzCol>
                        <RzCol style={{alignItems:'center',width:'100%',height:'100%',border:'0px solid green'}}>
                            <FtNotesLet style={{width:'100%',height:'100%', alignItems:'center', justifyContent:'center', border:'0px solid red'}}/>
                        </RzCol>
                        {/* <RzRow style={{ position: 'absolute', top:chatY, right:32, height:0  }}  >
                                {(isChatOn&& !isChatDlgOn)&&(<FtChatFlat ref={this.chatFlatRef} 
                                        style={{width:chatW,height:chatH,}}
                                        onToggle={(b)=>{
                                                if(this.chatFlatRef.current){
                                                    let r=this.chatFlatRef.current.getClientRect();
                                                    this.setState({isChatDlgOn:b,chatDlgX:r?.left, chatDlgY:r?.top});
                                                    
                                                //  alert('client Rect='+JSON.stringify(r));
                                                } else this.setState({isChatDlgOn:b});
                                        }}
                                />)}
                        </RzRow> */}


                        {(isCamDlgOn)&&(<FtCamDlg style={{left:camDlgX, top:camDlgY, zIndex:9}} boundaryRef={this.props.boundaryRef} isResizable={true}/>)}
                        {(isChatOn&& isChatDlgOn)&&(<FtChatDlg 
                                style={{ top:chatDlgY, left:chatDlgX,width:338,height:403,}}
                                     isDlgOn={isChatDlgOn} onToggle={(b)=>{ 
                            
                                      this.setState({isChatDlgOn:b});
                                }}  
                         />)} 
                </RzCol>
        );
    }

    doDrawBottom(){
        return (<FtBtmStat/>);
    }

    /********* */

    doFocus(my:boolean, other:boolean){
        this.setState({isMyCamFocused:my, isOtherCamFocused:other});
    }
    /********** */

    doMenuClicked(id:string){
        let ctx=this.getCtx();
        ftMenuClickHandler(id,ctx,(v)=>{ this.setState(v);},this.state);
    }

}//class


export const ftMenuClickHandler=(id:string, ctx:FtUiContext, setState:(v:any)=>void, state:any)=>{

    if(id==IDS_SETT_CHAT_OFF){
        ftBindChatOn(ctx, setState,state);
    }
}


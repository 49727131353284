import EventEmitter from "events";
import { Consumer } from "mediasoup-client/lib/Consumer";
import { Producer } from "mediasoup-client/lib/Producer";
import { SctpParameters } from "mediasoup-client/lib/SctpParameters";
import {
  DtlsParameters,
  IceCandidate,
  IceParameters,
  Transport,
} from "mediasoup-client/lib/Transport";
import { WavleMediaClient } from ".";
import { rzlog } from "../rzcmn";

export class DeviceClient {
  private msDevice: WavleMediaClient;
  private deviceStream: any;
  private onEmitter = new EventEmitter();
  private OnInitEmit = "onInit";
  private OnStreamChangeEmit = "onStreamChangeEmit";
  private mediaType: "producer" | "consumer";
  private _identificationId: string;
  private _roomId: string;

  private videoProducer: Producer | null = null;
  private audioProducer: Producer | null = null;
  private videoConsumer: Consumer | null = null;
  private audioConsumer: Consumer | null = null;

  constructor(
    device: WavleMediaClient,
    roomId: string,
    identificationId: string,
    mediaType: "producer" | "consumer"
  ) {
    this.msDevice = device;
    this._identificationId = identificationId;
    this.mediaType = mediaType;
    this._roomId = roomId;
    this.init();
  }

  public get identificationId() {
    return this._identificationId;
  }
  public set identificationId(identificationId: string) {
    this._identificationId = identificationId;
  }
  // private async init(routerRtpCapabilities: RtpCapabilities) {
  private async init() {
    await this.deviceInit();
  }

  private async deviceInit() {
    if (this.mediaType === "producer") {
      try {
        // Produce our webcam video.
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
        const webcamTrack = stream.getVideoTracks()[0];
        this.videoProducer = await this.msDevice.produce(webcamTrack);
        const audioTrack = stream.getAudioTracks()[0];
        this.audioProducer = await this.msDevice.produce(audioTrack);

        this.deviceStream = stream;
        rzlog.debug("cam.video.producer Ok  : stream=", stream);
      } catch (e) {
        console.error("cam.errr=", e);
        alert(
          "카메라 또는 마이크를 찾을 수 없습니다." +
            e +
            ",id=" +
            this._identificationId
        );
      }
    } else {
      // const stream = consume(transport);
      try {
        const consumData = await this.msDevice.signalConsume(
          "video",
          this._identificationId
        );
        rzlog.debug(
          "cam.video.consumeData : id=",
          this._identificationId,
          ",consumData=",
          consumData
        );

        const consumAudioData = await this.msDevice.signalConsume(
          "audio",
          this._identificationId
        );
        rzlog.debug(
          "cam.audio.consumeAudio : id=",
          this._identificationId,
          ",consumeAudioData=",
          consumAudioData
        );

        let codecOptions = {};
        const stream = new MediaStream();
        this.videoConsumer = await this.msDevice.consume({
          ...consumData,
        });
        this.audioConsumer = await this.msDevice.consume({
          ...consumAudioData,
        });
        if (this.videoConsumer !== undefined) {
          stream.addTrack(this.videoConsumer?.track);
        }
        if (this.audioConsumer !== undefined) {
          stream.addTrack(this.audioConsumer?.track);
        }
        rzlog.debug("cam.video.consume Ok  : stream=", stream);
        this.deviceStream = stream;
      } catch (e) {
        console.error("cam.errr=", e);
        alert(
          "카메라 또는 마이크를 찾을 수 없습니다.2" +
            e +
            ",id=" +
            this._identificationId
        );
      }
    }
    rzlog.debug("cam.video.producer .noti onInit :stream ", this.deviceStream);
    this.onEmitter.emit(this.OnInitEmit);
  }

  public async reopen() {
    await this.deviceInit();
  }

  public getStream() {
    return this.deviceStream;
  }

  public onInit(listner: (...args: []) => void) {
    return this.onEmitter.addListener(this.OnInitEmit, listner);
  }

  public onStreamChange(
    listner: (data: { stream: MediaStream; audioOuput?: string }) => void
  ) {
    return this.onEmitter.addListener(this.OnStreamChangeEmit, listner);
  }

  public disconnect() {
    if (this.mediaType === "producer") {
      if (this.videoProducer !== null) this.videoProducer.close();
      if (this.audioProducer !== null) this.audioProducer.close();
    } else {
      if (this.videoConsumer !== null) this.videoConsumer.close();
      if (this.audioConsumer !== null) this.audioConsumer.close();
    }
  }

  public async changeProduce(
    constraints: MediaStreamConstraints,
    audioOutput: string
  ): Promise<void> {
    this.stopStream();
    await this.produceSetting(constraints);
    this.onEmitter.emit(this.OnStreamChangeEmit, {
      stream: this.deviceStream,
      audioOutput: audioOutput,
    });
  }

  private async produceSetting(
    constraints: MediaStreamConstraints
  ): Promise<void> {
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    if (this.videoProducer && stream.getVideoTracks()[0]) {
      await this.videoProducer.replaceTrack({
        track: stream.getVideoTracks()[0],
      });
    }
    if (this.audioProducer && stream.getAudioTracks()[0]) {
      await this.audioProducer.replaceTrack({
        track: stream.getAudioTracks()[0],
      });
    }
    this.deviceStream = stream;
  }

  public stopStream(): void {
    this.deviceStream?.getTracks().forEach((track: any) => track.stop());
  }

  public reStartStream(): void {
    this.deviceInit();
  }

  public getAudioDevice(): MediaStreamTrack | null {
    if (!this.deviceStream) return null;
    return this.deviceStream.getAudioTracks()[0];
  }

  public getVideoDevice(): MediaStreamTrack | null {
    if (!this.deviceStream) return null;
    return this.deviceStream.getVideoTracks()[0];
  }

  public async changeConsumer(): Promise<void> {
    const consumData = await this.msDevice.signalConsume(
      "video",
      this._identificationId
    );
    const consumAudioData = await this.msDevice.signalConsume(
      "audio",
      this._identificationId
    );
    const stream = new MediaStream();
    this.videoConsumer = await this.msDevice.consume({ ...consumData });
    this.audioConsumer = await this.msDevice.consume({ ...consumAudioData });
    if (this.videoConsumer) {
      stream.addTrack(this.videoConsumer.track);
    }
    if (this.audioConsumer) {
      stream.addTrack(this.audioConsumer.track);
    }

    this.deviceStream = stream;
    this.onEmitter.emit(this.OnStreamChangeEmit, { stream: this.deviceStream });
  }

  public isAllSetting(): boolean {
    if (this.mediaType === "producer") {
      return this.videoProducer !== null && this.audioProducer !== null;
    } else {
      return this.videoConsumer !== null && this.audioConsumer !== null;
    }
  }

  // public replaceTestVideo(): void {
  //     const testVideo = document.getElementById('testVideo') as HTMLVideoElement;
  //     const testStream = testVideo.captureStream();
  //     this.videoProducer?.replaceTrack({ track: testStream.getVideoTracks()[0] });
  // }
}

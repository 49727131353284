
import { rzlog, NewOk, NewFail, RzOnFn } from "../../inc"
import { RzUiLet, RzUiOpts,RzDlg,scale } from "../../inc"
import { RzAwtTool, RzEventNotier } from "../../inc";
import { RzChatRepo } from "./ftclass.ui.chat.repo";
import { FteChatMsg } from "../../repo/ftclass.repo.dto";


/*********************************
 * RzChatEvent
 *********************************/
export class RzChatEventEnum {
    static ADD ='add'
    static DEL ='del'
}


export class RzChatEvent {
    cmd?:string
    messages?:RzChatMsg[]
}


export class RzChatMsg {
    classId?:string
    id?:string
    seq?:number
    fromName?:string
    fromSesId?:string
    to?:string
    createdAt?:Date
    body?:string
    isHit?:boolean
}


/*********************************
 *  FtClassChat
 ********************************/
//RzUiLet
export class FtClassChat extends RzDlg {
    chatHeadLet?:RzUiLet
    chatListLet?:RzChatView
    chatBoxLet?:RzUiLet
    repo?:RzChatRepo

    textArea?:HTMLInputElement
    sendBtn?:HTMLElement


    constructor() {
        super();
 
        this.repo=new RzChatRepo();
        this.repo.addOnChatEvent(this.onChatEvent!,this);
    }

    doInit(opt:RzUiOpts) {
        this.doInitAsync(opt);
        return true;
    }

    doInitHeader(opt:RzUiOpts){
        let headLet=new RzUiLet()
        headLet.init({parent:this, className:"ftclass_chat_top",
                        cssText:'display:flex; flex-driection:row; width:100%; justify-content:flex-end;',
                        innerHTML:` <div style="flex-grow:1; margin-left:14px; min-height:40px">
                                        <span class="ftclass_top_title" >메세지 보기</span>
                                    </div>
                                    <div id="${headLet.id}.btn" style="width:32px;"><i class="ftnote-close-icon"/></div>`})
        let closeBtn=headLet.getElementById('btn')!
        if(!closeBtn) return;
        
        closeBtn.onclick=(e)=>{
            this.notiOnClose()
        }

        this.chatHeadLet=headLet
    }

    doInitBody(opt:RzUiOpts,uilet?:RzChatView){
        let viewLet = uilet || new RzChatView()
        if(!uilet){
            viewLet.init({parent:this,
                        className:(opt.className) ? opt.className: "ftclass_chat_body",
                        cssText:(opt.cssText) ? opt.cssText:"flex:0 1 70%; background-color:#F8F8F8;",
             })
        }
     
        this.chatListLet=viewLet as RzChatView
    }

    doInitBottom(opt:RzUiOpts,msgBox?:RzUiLet){
        let chatBoxLet=undefined;
        if(!msgBox){
            chatBoxLet=new RzUiLet()
            let tbtOpt={cssText:'display:flex; flex-direction:column; width:100%; flex:0 1 20%; background:#333333;',
                            innerHTML:`<div class="ftclass_chat_box_div" >
                                    <textarea id="${chatBoxLet.id}.textIn" style="resize: none; width:${scale(97,true,92)}%;" class="ftclass_chat_box"></textarea></div>
                                <div  id="${chatBoxLet.id}.btn"  class="ftclass_chat_div" >
                                    <span class="ftclass_chat_btn" >보내기</span>
                                </div>`}
                            
            chatBoxLet.init({parent:this,  ...tbtOpt})
        } else chatBoxLet=msgBox;

        this.textArea= chatBoxLet.getElementById('textIn')! as HTMLInputElement

        
        let isKeyOn=true;

        if(isKeyOn){
            this.textArea.addEventListener('keydown',(e:KeyboardEvent)=>{
                rzlog.debug('textArea: keyDown ', e.key+',shift=',e.shiftKey);

                if(e.shiftKey && e.key==='Enter'){
                    rzlog.debug('textArea: keyDown2 ', e.key+',shift=',e.shiftKey);
                }  else if(!e.shiftKey&&e.key==='Enter')  {
                    
                    e.preventDefault();
                }
            })
            this.textArea.addEventListener('keyup',(e:KeyboardEvent)=>{
                rzlog.debug('textArea: keyUp ', e.key+',shift=',e.shiftKey);

                if(e.shiftKey && e.key==='Enter'){
                    rzlog.debug('textArea: keyUp2 ', e.key+',shift=',e.shiftKey);
                } else if(!e.shiftKey&&e.key==='Enter') {
                    e.preventDefault();
                
                        let txt=this.textArea?.value;
                        rzlog.debug("keyUp3.txt = ",txt);
                    //alert('clicked:'+v)
                        this.textArea!.value=''
            
                        if(txt) this.repo!.sendMsg(txt);
                
                }
            })
        }

        this.sendBtn= chatBoxLet.getElementById('btn')!
        this.sendBtn.onclick=()=>{ 
            let v=this.textArea!.value
            //alert('clicked:'+v)
            this.textArea!.value=''
        
            this.repo!.sendMsg(v)
        }
        this.chatBoxLet=chatBoxLet
    }

    async doInitAsync(opt:RzUiOpts) {
        this.doInitHeader({})
        /****************************/
        this.doInitBody({})
        /****************************/
        this.doInitBottom({})
    }

    setRepo(repo:RzChatRepo){
        this.repo=repo
        repo.addOnChatEvent(this.onChatEvent, this)
        this.doLoad()
    }

    async doLoad(){
        if(!this.repo) return NewFail()

        let r=await this.repo!.iter()
        if(r.status>0) return r

        let it=r.data!
        for(let i in it){
            let t=it[i]
            rzlog.debug('FtClassChat.doLoad:t=',t)
            this.chatListLet!.addItem(t)
        }//for

        return NewOk()
    }

    onChatEvent=(e:RzChatEvent,pr:any)=>{
        if(e.cmd===RzChatEventEnum.ADD){
            let msgs=e.messages!
            for(let i in msgs){
                //let it={ id:'2', seq:0,from:'강사1',  createdAt:new Date(), body:"" }
                let m = msgs[i]
                pr.chatListLet.addItem(m)
            }
        }
    }

    onCloses=[]
    addOnClose(fn:RzOnFn,pr:any){
        RzEventNotier.AddOnEvent(this.onCloses,fn,pr)
    }
    notiOnClose(){
        RzEventNotier.NotiEvent(this.onCloses,{})
    }

}//class

 

 
/***************************** */

export class RzChatView extends RzUiLet {
    itemList?:HTMLElement

    doPreOpts(opts:RzUiOpts){
        return {...opts,
            cssText:`display:flex; flex-driection:column; width:100%; 
                    background-color:#F8F8F8; 
                    flex-grow:1;justify-content:flex-start;  overflow-y:auto;`,
            innerHTML:`<ul id="${this.id}.list" style="width:100%;" class="ftclass_chat_list"></ul>`
        }
    }

    doInit(opts:RzUiOpts){
        this.itemList=this.getElementById('list')!
       
        return true
    }

    toHour(dt?:Date){
        if(!dt) return 'no time'
        return `${dt.getHours()}:${dt.getMinutes()}`
    }



    addItem(msg:FteChatMsg){
        let ul=this.itemList
        if(!ul) return

        let li1= RzAwtTool.createElement('li')
        let li2= RzAwtTool.createElement('li')
        li1.className="ftclass_chat_item_hd" 
        li1.innerHTML=`${msg.fromName}<span class="ftclass_chat_item_time">${this.toHour(msg.createdAt)}</span>`

        li2.className="ftclass_chat_item" 
        let vs=(msg.body) ? msg.body.split("\n"):[''];

        let tout=""
        vs.forEach( (t:any,ix:number) => { 
            if(ix>0) tout+="<br/>";
            tout+="<span>"+t+"<span>";  
        })
        //li2.innerHTML=`<span>${msg.body}</span><br/><span>111</span>`
        li2.innerHTML=tout;

        ul.appendChild(li1)
        ul.appendChild(li2)
        rzlog.debug('FtChatLet.scrollTo : height=',ul.scrollHeight)
        
        this.nativeDiv!.scrollTo(0,ul.scrollHeight-30)
    }

}//class


 


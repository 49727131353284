import { rzlog } from "../inc"
import { FtClassStoreRemoteFac } from "./ftclass.repo.store.rmt"
import { FtClassStoreTestFac } from "./ftclass.repo.store.test"

import { FtClassInfoRepo } from "./ftclass.inf.repo"
import { FtClassRepoLocFac, FtNotesRepo } from "./ftclass.repo.bas"

import { FtClassInfoStore, FtNotesRepoStore } from "./ftclass.repo.store"
import { FtClassClientRef } from "../ftclass.client"

/**************/
const rzIs=rzlog.makeDefs()
export const FtClassRepoFac_setDbg=rzIs.setDbg
 
/**************
 * 
 */

export class FtClassRepoFacOpts{
    static MEMORY='memory'
    reposType='memory'
    constructor(opt:any){
        Object.assign(this,opt)
    }
}

 
 

// FtClassReposFac
export interface FtClassRepoFac {
    init(fac:FtClassStoreFac):void; 
    newClassInfoRepo(classId:string,opt?:any): FtClassInfoRepo 
    newNotesRepo(infoRepo:FtClassInfoRepo,opt?:any): FtNotesRepo

}//class

export class FtClassRepoFacDef{

    static NewFac( opt:any): FtClassRepoFac|null {
        let r:FtClassRepoFac|null = null
        try{
            let loaderFac=FtClassStoreFac.NewFac(opt?.loaderType||'local')
            let nopt:FtClassRepoFacOpts = new FtClassRepoFacOpts(opt)
            if(nopt.reposType===FtClassRepoFacOpts.MEMORY)  r=new  FtClassRepoLocFac()  
            else r = new  FtClassRepoLocFac() 

            if(r) r.init(loaderFac)
        } catch(e:any) {
            console.log("ERR=",e)

        }

        return r
    }
}

export  interface   FtStoreOpt {
    clientRef?:FtClassClientRef
}

export abstract class FtClassStoreFac  {
    abstract newInfoStore(opt?:FtStoreOpt) : FtClassInfoStore;
    abstract newNotesStore(opt?:FtStoreOpt): FtNotesRepoStore;

    static NewFac(type:string){
        let r:any=null
        if(type==='test'){
            r= new FtClassStoreTestFac()
        } else r= new FtClassStoreRemoteFac()

        if(rzIs.i) rzlog.info('FtClassStore: type= ',type,',ldr=',r)
        return r as FtClassStoreFac
    }
}

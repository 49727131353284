import { CmBindMouseEventForDlg, CmBindMouseEventForDlgDiv, CmCloseForDlgDiv, CmComponent, CmMousePos, CmResize, CmSetFocusForDlgDiv, RzBasProp, RzBtn, RzCol, RzRow, RzTxt } from "./rzcomp";
import { FtCamLet } from "./ftcam.ui";
import { FtChatFlat } from "./ftchat.ui";
import { CSSProperties, Component, ReactNode, createRef, useRef, useState } from "react";

import { FtNoteLet } from "./ftnote.ui";

import { RzScale, rzlog } from "../../inc";
import { FtxNote } from "../../dto/ftclass2.dto";
import { FtUiContext } from "../ftclass2/ftui.context";

const rzIs = rzlog.makeDefs();



/***************
 * 
 */
export interface FtCmnProp {
    children?: ReactNode | JSX.Element;
    style?: CSSProperties;
    isRelative?: boolean;
    isResizable?: boolean;
    xPivot?: number;
    boundaryRef?: React.RefObject<HTMLDivElement>
    yPivot?: number;
    isResizeBottom?: boolean;
    preferHeight?: number;
    onMouseMove?: (e: any) => void;
    onSelected?: (e?: MouseEvent) => void;
}

export interface FtCmnStat {
    config: any;
    topPos?:number;
}



export class FtCmnDlg extends CmComponent<FtCmnProp, FtCmnStat> implements CmMousePos {
    prevMousXPos: number = 0;
    prevMousYPos: number = 0;
    prevElXPos: number = 0;
    prevElYPos: number = 0;
    mousePressed: boolean = false;
    moved: boolean = false;

    constructor(pr?: any) {
        super(pr);
        this.state = {
            config: { x: 0, y: 100, w: 340, h: 440 },
            
        };
    }
    
    static dlgCnt = 1;
    genId: string = "ftCmnDlg-" + FtCmnDlg.dlgCnt++;

    divRef = createRef<HTMLDivElement>();
    btmDivRef = createRef<HTMLDivElement>();
    resizeDivRef = createRef<CmResize>();
    dlgOn = false;



    onSelected(e?: MouseEvent) {
        if (this.props.onSelected) this.props.onSelected(e);
    }

    componentDidMount(): void {
        // alert('xPivot='+this.props.xPivot);

        if (this.divRef.current && !Boolean((this.divRef.current as any).isRefMount)) {
            CmBindMouseEventForDlgDiv(this.divRef.current, this, this.props.isRelative,
                this.props.xPivot, this.resizeDivRef);
        }
        let tpos=this.divRef.current?.getBoundingClientRect().top;
        if(this.state.topPos==undefined && tpos) {
            this.setState({topPos:tpos});
        }else if(this.state.topPos!=undefined && tpos!=undefined) {
            if(this.state.topPos!=tpos) this.setState({topPos:tpos});
        }
    }

    setFocus() {
        rzlog.debug("FtDlg.setFocus : >>>> : id=", (this.divRef.current as any).id);
        if (this.divRef.current) CmSetFocusForDlgDiv(this.divRef.current, this);
        // if(this.divRef.current&& (this.divRef.current as any).setFocusForDlg){

        //         (this.divRef.current as any).setFocusForDlg(this.divRef.current);
        // }
    }

    closeDlg() {
        rzlog.debug("Focus.closeDlg: div.id=", this.divRef.current?.id);

        if (this.divRef.current) CmCloseForDlgDiv(this.divRef.current, this);
    }

    touchX=0;
    touchY=0;
    
    render(): ReactNode {

        let st = this.props.style;
        let bst = { boxShadow: '0px 0px 7px 0px #00000080' };
        let isResizable = this.props.isResizable;
        let pos = this.divRef.current?.getBoundingClientRect();
        let w = 340;
        let dw = 20;
        


        let w1 = this.state.config.w;
        let h = this.state.config.h;
        let x = this.state.config.x;
        let y = this.state.config.y;
        rzlog.debug("BOTTOM FtCmnDlg.render : st.topt=",this.state.topPos,
         ', x=', x, ', y=', y, ', w=', w, ', h=', h, ', w1=', w1);
        let leftPos = (pos?.width || w) - dw;
        let topPos = (pos?.height || h) - dw;

        let resizeBottom=this.props.isResizeBottom;
        if(topPos<=0 && resizeBottom ){
             topPos=(this.props.preferHeight||221)+dw;
        }
        

        return (<div id={this.genId} ref={this.divRef}


            onMouseMove={(e) => {
                let x = this.divRef.current?.offsetLeft;
               
                let w = this.divRef.current?.offsetWidth;
                let h = this.divRef.current?.offsetHeight;
                let y = this.divRef.current?.offsetTop;

              //  if(h&&y) y=y+h;

                let tid = this.divRef.current?.id;

                //if(e.buttons==1)
                if (rzIs.d) rzlog.debug(">> MouseMove : id=", tid, ", x=", x, ', y=', y, ', w=', w, ', h=', h, ',btn=', e.buttons);

                if (this.props.onMouseMove) this.props.onMouseMove(e);
            }}
            // style={{ position: 'absolute', top: 100, width: w, zIndex: 4, ...st }}>
            style={{ position: 'absolute', width: w1, height: h, left: x, top: y, zIndex: 6, ...st }}>
            {this.props.children}
            {Boolean(isResizable) && 
                (<CmResize ref={this.resizeDivRef} isBottom={resizeBottom} 
                    dw={dw} left={leftPos} top={topPos} onResize={(dx, dy) => {
                        rzlog.debug("resize : dx=", dx, ',dy=', dy);
                        this.doResize(dx, dy);
                    }} />
            )}
        </div>)
    }
    
    inrange = (v: number, min: number, max: number) => {
        console.log(v, min, max)
        if (v < min) return min;
        if (v > max) return max;
        return v;
    };

 

    // doResize1(dx: number, dy: number) {
    //     if (!this.divRef.current) return;
    //     let r = this.divRef.current.getBoundingClientRect();

    //     if(!this.props.boundaryRef?.current) return;
    //     let b = this.props.boundaryRef?.current.getBoundingClientRect();

    //     // let w = r.width + dx;
    //     // let h = r.height + dy;

    //     // this.divRef.current.style.width = w + 'px';
    //     // this.divRef.current.style.height =  h + 'px';


    //     const BOUNDARY_MARGIN = 12;
    //     const MIN_W = 340;
    //     const MIN_H = 442;

    //     let w = this.inrange(r.width + dx, MIN_W, b.y + b.bottom - MIN_H)
    //     let h = this.inrange(r.height - dy, MIN_H, b.y + b.bottom - BOUNDARY_MARGIN)
    //     let y = this.inrange(r.y + dy, BOUNDARY_MARGIN, b.y + b.bottom - BOUNDARY_MARGIN);

    //     if(this.resizeDivRef.current?.props.isBottom){
           
    //         w=this.inrange(r.width + dx, MIN_W, b.right -b.left - MIN_H)
    //         h = this.inrange(r.height + dy, MIN_H,  b.bottom -b.top- BOUNDARY_MARGIN)
    //         y = r.y
    //         console.log("BOTTOM RESIZE !!!! >>>>>>>>>>>>:x"+r.x+",y="+y+",w="+w+",h="+h+",dx="+dx+",dy="+dy)
    //     }


    //     this.setState({ config: { w: w, h: h, x: r.x, y: y } })
    // }

    doResize(dx: number, dy: number) {
        if (!this.divRef.current || !this.props.boundaryRef?.current) return;

        let r = this.divRef.current.getBoundingClientRect();
        let b = this.props.boundaryRef.current.getBoundingClientRect();

        const aspectRatio = r.width / r.height; // 너비와 높이의 비율 계산

        const BOUNDARY_MARGIN = 12;
        const MIN_W = 340;
        const MIN_H = 442;
        
        // 옆으로 늘어나는 것을 막기 위해 너비 변경 대신 높이 변경에만 초점을 맞춤
        let newHeight = r.height + dy;
        let newWidth = newHeight * aspectRatio; // 비율에 맞게 너비 조정

        // 경계 조건 확인 및 조정
        newHeight = this.inrange(newHeight, MIN_H, b.bottom - b.top - BOUNDARY_MARGIN);
        newWidth = this.inrange(newWidth, MIN_W, b.right - b.left - BOUNDARY_MARGIN); // 비율에 맞는 너비가 경계를 넘지 않도록 조정

        let newX = r.x;
        let newY = r.y;
        
        // 너비 변경을 허용하지 않으므로, newX는 조정하지 않음
        // newY의 조정 로직은 필요에 따라 추가

        this.setState({ config: { w: newWidth, h: newHeight, x: newX, y: newY } });
    }


}//class

/*************
 * 
 */

export interface FtCamDltProp extends RzBasProp {
    isMyCamOn?: boolean;
    isOtherCamOn?: boolean;
    isResizable?: boolean;
    boundaryRef?: React.RefObject<HTMLDivElement>
}
export interface FtCamDltStat {
    // config: any;
}

export class FtCamDlg extends CmComponent<FtCamDltProp, FtCamDltStat>  {

    constructor(pr?: any) {
        super(pr);
        // this.state = {
        //     config: { x: 0, y: 0, w: 0, h: 0 }
        // };
    }

    componentDidMount() {
        console.log(this.props.boundaryRef)
    }

    // boundaryRef = createRef<HTMLDivElement>();



    render(): ReactNode {
        let ctx = this.getCtx() as FtUiContext;
        let layoutType = ctx.layoutType;
        let bst = { boxShadow: '0px 0px 7px 0px #00000080' };
        
        return (
            <FtCmnDlg {...this.props} isResizeBottom={true} preferHeight={221}>
                {/* <RzCol style={{ position: 'relative', backgroundColor: 'gray', width: 340, height: '100%', ...bst }}> */}
                <RzCol className="camPosOff" style={{ position: 'relative', backgroundColor: 'gray', width: '100%', height: '100%', aspectRatio: '2 / 1.3', ...bst }}>
                    <FtCamLet isMyCam={true} style={{ aspectRatio: '2 / 1.3' }} />
                    <FtCamLet style={{ aspectRatio: '2 / 1.3' }} />
                </RzCol>
            </FtCmnDlg>);
    }
} //class


/************
 * 
 */
export interface FtChatDlgProp {
    onToggle?: (b: boolean) => void;
    isDlgOn?: boolean;
    style?: CSSProperties;
}

export interface FtChatDlgStat {

}

export class FtChatDlg extends CmComponent<FtChatDlgProp, FtChatDlgStat>  {


    constructor(pr?: any) {
        super(pr);
        this.state = {}
    }

    componentDidMount() {
        //  BindDlg("ftCamDlg",this);
    }

    render(): ReactNode {
        let st = this.props.style;
        let h = window.innerHeight;
        // style={{ top:(this.state.chatDlgY||0)-200, left:this.state.chatDlgX,width:340,height:403,}}
        return (<FtCmnDlg style={{ left: 240, bottom: h - 40, width: 340, height: 403, ...st }} >
            <FtChatFlat isDlgOn={this.props.isDlgOn} onToggle={this.props.onToggle} />
        </FtCmnDlg>);
    }
} //class


/*************
 * 
 */

interface FtNoteDlgProp extends RzBasProp {
    note?: FtxNote;
    xPivot?: number;
    isRelative?: boolean;
    visible?: boolean;
    scale?:RzScale;
    onSelected?: (e: FtNoteDlg, isSel?: boolean) => void;
    onClose?: (allNotes: FtxNote[]) => void;
}

interface FtNoteDlgStat {
    note?: FtxNote;
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    loadCnt?: number;
}

export class FtNoteDlg extends CmComponent<FtNoteDlgProp, FtNoteDlgStat>  {

    constructor(pr?: any) {
        super(pr);
        this.state = {}
    }

    componentDidMount() {

        rzlog.debug("FtNoteDlg.mount(Focus) : pr.note=", this.props.note);
        this.setState({ note: this.props.note });

    }

    componentDidUpdate(prevProps: Readonly<FtNoteDlgProp>, prevState: Readonly<{}>, snapshot?: any): void {
        if (this.state.note !== this.props.note) {
            this.setState({ note: this.props.note });
        }
    }


    setFocus() {
        this.doFocus(this);
    }

    doFocus(dlg: FtNoteDlg) {
        this.refDlg.current?.setFocus();
    }


    refDlg = createRef<FtCmnDlg>();
    isDlgOn = false;
    noteRef = createRef<FtNoteLet>();
    render(): ReactNode {

        let hdHt = 30;
        let st = { ...this.props.style, display: 'flex', flexFlow: 'column' };
        let note = this.state.note;



        let isLocked = false;

        let postfix = ''
        let title = note?.title;

        if (this.refDlg.current && this.refDlg.current.divRef.current) {
            // this.isDlgOn=true;
            // alert('ox='+ox+',oy='+oy);
            let posfix = this.doGetPosStr();
        }

        let stNote = { width: '100%', height: '100%', marginTop: 0, backgroundColor: '#FFFCFC', border: '0px solid yellow' };
        let stTopPnl = {
            backgroundColor: 'black', width: '100%', height: hdHt, borderStyle: 'solid',
            justifyContent: 'space-between', borderTopLeftRadius: 6, borderTopRightRadius: 6
        };



        return (<FtCmnDlg ref={this.refDlg} style={st} isRelative={this.props.isRelative}
            onSelected={() => { if (this.props.onSelected) this.props.onSelected(this) }} >
            <RzRow style={stTopPnl}>
                <RzTxt style={{ paddingTop: 4 }} text={title + postfix} />
                <RzBtn icon={(<span className="ftclass2-ic-minimize" style={{ marginTop: 6 }}
                    onClick={() => { this.doCloseNote(); }} />)} />
            </RzRow>
            <RzCol style={{ flex: "1 1 auto" }}>

                <FtNoteLet ref={this.noteRef} noteId={note?.noteId} count={this.state.loadCnt} style={stNote}
                    scale={this.props.scale}
                    onFocused={(nt) => {
                        if (this.props.onSelected) this.props.onSelected(this);
                    }}
                    onLoad={(cnt) => {
                        rzlog.debug("ftnote.update Load.Focus : seq=", this.noteRef.current?.seq, ', ntRef=', Boolean(this.noteRef.current), ',nt.isFoc=', note?.isPopFocused);
                        this.noteRef.current?.forceUpdate();

                        if (note?.isPopFocused) {
                            this.setFocus();
                        }
                    }} />

            </RzCol>

        </FtCmnDlg>);
    }

    /****************** */
    doGetPosStr() {
        if (this.refDlg.current && this.refDlg.current.divRef.current) {
            let ox = this.refDlg.current.divRef.current.offsetLeft;
            let oy = this.refDlg.current.divRef.current.offsetTop;
            let cx = this.refDlg.current.divRef.current.clientLeft;
            let cy = this.refDlg.current.divRef.current.clientTop;

            // offx=ox;
            // offy=oy;

            let postfix = ': ox=' + ox + ',oy=' + oy + ',cx=' + cx + ',cy=' + cy;

            rzlog.debug(" >> " + postfix);
            return postfix;
        } else return '';
    }

    /****************** */
    doCloseNote() {
        let ctx = this.getCtx();

        let nt = this.state.note;
        if (!ctx.notes) return;

        let r = this.refDlg.current?.divRef.current?.getBoundingClientRect();

        let x: number | null = null;
        let y: number | null = null;

        if (r) {
            let p = this.refDlg.current?.divRef.current?.parentElement?.getBoundingClientRect();
            x = r.left - (p?.x || 0);
            y = r.top - (p?.y || 0);

            rzlog.debug("doCloseNote(Focus) : x=", x, ',y=', y, ',px=', p?.x, ',py=', p?.y);
        }

        let notes = ctx.notes.map(t => {
            if (t.noteId == nt?.noteId) {
                return { ...t, isFloatingOn: false, isShowOn: true, lastPopX: x || undefined, lastPopY: y || undefined };
            }
            return t;
        });

        ctx.setGlobalCtx({ ...ctx, notes: notes });
        if (this.refDlg.current) this.refDlg.current.closeDlg();
        if (this.props.onClose) this.props.onClose(notes);
    }
} //class




/**
 *  S1xLecture
 */
 import { ReactNode } from "react"
import { NewOk, RzCtx, RzRes, } from "../../rzcmn"
import {rzlog , RzRepo } from "./inc"
 import './rzcmn.ui.css'

 
 /************** */
 
 const rzIs=rzlog.makeDefs(true)
 export const RzCmnUi_setDbg=rzIs.setDbg

 let isDbgUi=false;
 export function RzCmnUi_setDbgUi(b:boolean){
    isDbgUi=b;
 }


 const _isTest=false;

/*********************************/
export type RzOnFn = (e:any,pr?:any)=>void
export interface OnFn {
    fn : RzOnFn;
    param?:any;
    id?:string;
}
 /*********************************
  *  RzNote
  ********************************/
 export interface RzAwtParam {
    tag?:string;
    tagType?:string;
    id?:string;
 }

 export interface RzAwtDlgHolder {
    showDlg(title:string, msg:string, okFn?:(b?:boolean)=>void, btn?:any):void;
 }


 export class RzAwtDlgDefHolder {
    showDlg(title:string, msg:string, okFn?:(b?:boolean)=>void){  
        alert(msg);
        if(okFn) okFn(true)
    }
 }



 export class RzAwtTool {
    static defDlgHolder:RzAwtDlgHolder=new RzAwtDlgDefHolder;


    static CreateDiv(uiType?:string,subType?:string): HTMLElement{

        return RzAwtTool.CreateDivByPram({tag:uiType, tagType:subType})
    }

    static CreateDivByPram(pr:RzAwtParam): HTMLElement{


         let uiType=pr.tag;
         let subType=pr.tagType;

         let ttype=uiType||'div'
         let r=document.createElement(ttype)
         if(subType) r.setAttribute('type',subType)
         if(pr?.id) r.setAttribute('id',pr.id)
         return r
     }

     static createElement(type: string){
        let r=document.createElement(type)
        return r
     }

     static createTextNode(title:string){
         let r=document.createTextNode(title)
         return r
     }

     static RemoveChildAll(el:HTMLElement){
         
        while (el.lastElementChild) {
            //rzlog.debug('remoteChild:',el.lastElementChild)
            el.removeChild(el.lastElementChild);
        }
     }

     static ContainChilds(p:ChildNode, tgt:HTMLElement):boolean{
        if(p===tgt) return true;

        let nds=p.childNodes
        if(nds.length===0) return false;

        
        let r=false;
        for(let v in nds){
            let nd=nds[v]
           
            if(nd.childNodes) r=RzAwtTool.ContainChilds(nd,tgt)
            if(r) break;
        }
        return r;
     }


     static showDlg(title:string, msg:string, okFn?:(b?:boolean)=>void, btn?:any){
        RzAwtTool.defDlgHolder.showDlg(title,msg,okFn,btn);
     }

     static setDlgHolder(holder:RzAwtDlgHolder){
        RzAwtTool.defDlgHolder=holder;
     }
 }//class


/*********************************/
export interface RzPos {
    x:number;
    y:number;
}

export interface RzSize {
    width:number;
    height:number;
}

export interface RzRect extends RzPos {
    width:number;
    height:number;
    drawable?:boolean;
}

 export interface RzUiOpts{
    id?: string;
    nativeDiv?: HTMLElement  ;
    title?: string;
    
    className?: string;
    classNameForced?: string;
    
    cssText?: string|string[];
    cssTextForced?: string;
    onFocus?:(e:any)=>void;
    onClick?:(e:any)=>void;
    parent? : RzUiLet;
    parentDiv?:HTMLElement ;
    
    draggable?:boolean;
    isDraggable?:boolean;
    isTouchable?:boolean;
    style?: any;
    innerHTML?:string;
    drawNode?:JSX.Element|ReactNode;
    tag?:string;
    tagType?:string;
    isVisible?:boolean;
    isResizable?:boolean;
    isClosable?:boolean;
    onVisible?:(b:boolean )=>void
 }

 export interface RzSwipeEvent {
    isNext:boolean;
    dx:number;
    dy:number;
 }

export interface RzZoomEvent {
    isMagified:boolean;
}

export interface RzTouchPos{
    x:number;
    y:number;
}

 export function MergeCss(cssText?:string|string[],css?:string){
    if(!cssText) return '' + ((css)?css:'');
    
    if ( typeof cssText === 'string') return cssText + ((css)?css:'');
    
    let r=''
    for(let i in cssText){
        let v= cssText[i]
        r= r+ v
    }

    if(css)r=r+css
    return r
 }
 
 export class RzUiLet<P={}> {
    static focusElement:RzUiLet|null =null
    static _idCnt=1
    id?: string
    props?:P;
    isDraggable=false;
    isDragging=false;
    nativeDiv?: HTMLElement 
    nativeNode?: Node 
   
    parent?: RzUiLet
    children:RzUiLet[]=[]
    initOpts?:RzUiOpts
    isInit:boolean=false;

    x:number =0
    y:number =0
    width:number =0
    height:number =0

    preferHight:number|string=0;
    preferWidth:number|string=0;

    isSkipMouseEvent : boolean =false;
    _isVisible=false;
    hasFocus:boolean=false;
   

    onVisible?: ((b:boolean)=>void);
    onPaint?:(e?:Event)=>void;
    onMouseMove?:(e:MouseEvent)=>void;
    onMouseDown?:(e:MouseEvent)=>void;
    onMouseUp?:(e:MouseEvent)=>void;
    onMouseOut?:(e:MouseEvent)=>void;


    onPointerDown?:(e:PointerEvent)=>void;
    onPointerUp?:(e:PointerEvent)=>void;
    onPointerMove?:(e:PointerEvent)=>void;


    onSwipe?:(e:RzSwipeEvent)=>void;
    onZoom?:(e:RzZoomEvent)=>void;

    onMouseWheel?:(e:WheelEvent)=>void;
    onChange?:(e:Event)=>void;
    onKeyDown?:(e:KeyboardEvent)=>void;
    onKeyUp?:(e:KeyboardEvent)=>void;
    onClick?:(e:Event|any)=>void;
    onFocus?:(e:Event,b?:boolean)=>void;
    onResize?:(rect:any)=>void;
    

     constructor(opts?:RzUiOpts){
        if(opts?.nativeDiv) this.nativeDiv=opts?.nativeDiv;
        if(opts) this.initOpts=opts;
         this.id=""+RzUiLet._idCnt++;
     }

     native() {return this.nativeDiv; }

     setInitOpts(opts: RzUiOpts){
         this.initOpts=opts;
     }

     
     getElementById(id:string){
         return document.getElementById(this.id+'.'+id);
     }
     
     doClear(){ 
        
     }
     
     genInitCss(opts:RzUiOpts, css?:string, clsNm?:string){
        let clz=clsNm? {className: (opts.className?opts.className+' ':'')+clsNm}:{};
        return {...opts,cssText:(opts.cssText||'')+(css||''), ...clz };
     }

     addChild(uilet:RzUiLet){
         if(rzIs.d)rzlog.debug('RzUiLet.addChild:',uilet,',this=',this);
         this.children.push(uilet);
         if(this.nativeDiv&&uilet.nativeDiv){
             this.nativeDiv.appendChild(uilet.nativeDiv);
         }
     }

     addChildAt(ix:number, uilet:RzUiLet){
        if(rzIs.d)rzlog.debug('RzUiLet.addChild:',uilet,',ix=',ix,',nativeChilds=',this.nativeDiv?.children);
        let childs:RzUiLet[]=[];
        this.children.forEach((t,i)=>{ if(i===ix)childs.push(uilet); childs.push(t);});
        this.children=childs;

        if(this.nativeDiv&&uilet.nativeDiv && this.nativeDiv.children.length>ix){
            //@this.nativeDiv.children[ix].before(uilet.nativeDiv)

            if(this.nativeDiv?.children[ix]) this.nativeDiv.children[ix].before(uilet.nativeDiv);
            else {
                rzlog.error('RzCmnUi.RzUiLet.addChildAt : no child at ix=',ix,',navDiv=',this.nativeDiv);
            }
            //else this.nativeDiv.appendChild(uilet.nativeDiv)
        } else  if(this.nativeDiv&&uilet.nativeDiv && this.nativeDiv.children.length<=ix){
            this.nativeDiv.appendChild(uilet.nativeDiv);
        }
    }

    indexOfChild(uilet:RzUiLet){
        let r=-1;
        this.children.forEach((v,ix)=> {if(uilet===v) r=ix;});
        return r;
    }

    hasChildNative(pEl:HTMLElement, childEl:HTMLElement){
         let r=false;
        for (let child = pEl.firstChild; child; child = child.nextSibling) {
                if(child===childEl) {r=true; break;}
        }
        return r;
     }

     removeChild(uilet:RzUiLet){
        this.children=this.children.filter(t=> t!==uilet);
        if(this.nativeDiv&&uilet.nativeDiv){
            if(this.hasChildNative(this.nativeDiv,uilet.nativeDiv))
                    this.nativeDiv.removeChild(uilet.nativeDiv);
        }
    }

    removeChildAll(){
        //RzAwtTool.RemoveChildAll(this.nativeDiv!)
        this.children.forEach(t=>  {
                rzlog.debug("removeChildAll : t.nav=",t.nativeDiv);
                if(this.nativeDiv && t.nativeDiv && this.nativeDiv.contains(t.nativeDiv)) this.nativeDiv.removeChild(t.nativeDiv);
         })

        this.children=[];
    }

     setVisible(b:boolean){
        if(b && !this.isInit) {
            this.init();
        }
        
        this._isVisible=b;

        if(!this.nativeDiv?.parentNode){
            this.parent!.addChild(this as RzUiLet);
        }
        
        if(this.nativeDiv)this.nativeDiv.style.visibility=(b)?'visible':'hidden';
        if(this.nativeDiv && this.onVisible)  this.onVisible(this._isVisible);
     }

     isVisible(){
         if(this.nativeDiv && this.nativeDiv.style?.visibility)
            this._isVisible=this.nativeDiv.style.visibility==='visible' ;
        return this._isVisible;
     }


     getBounds():RzRect{
         let r={x:this.x ,y:this.y, width:this.width, height:this.height};

         if(this.nativeDiv) {
            let tr= this.nativeDiv.getBoundingClientRect();
            r.x=tr.x;
            r.y=tr.y;
            r.width=tr.width;
            r.height=tr.height;
         }
         return  r;
     }

     cssText?:string;
     setCssText(cssText:string){
        this.cssText=cssText;

        if(this.nativeDiv) this.nativeDiv.style.cssText=cssText;
     }


    doPreOpts(opts:RzUiOpts){
        return opts;
    }

    setPos(x:number,y:number){
        this.x=x;
        this.y=y;    
        if(this.nativeDiv){
            this.nativeDiv.style.left=x+'px';
            this.nativeDiv.style.top=y+'px';
        }
    }

    getSize():RzSize {
        let r={ width:this.width, height:this.height};

        if(this.nativeDiv) {
           let tr= this.nativeDiv.getBoundingClientRect();
           r.width=tr.width;
           r.height=tr.height;
        }
        return  r;
    }


    setSize(w:number,h:number){
        this.width=w;
        this.height=h;

        if(this.nativeDiv){
            this.nativeDiv.style.width=w+'px';
            this.nativeDiv.style.height=h+'px';
        }        
    }

    setBounds(x:number,y:number,w:number,h:number){
        this.x=x;
        this.y=y    ;
        this.width=w;
        this.height=h;

        if(this.nativeDiv){
            this.nativeDiv.style.left=x+'px';
            this.nativeDiv.style.top=y+'px';
            this.nativeDiv.style.width=w+'px';
            this.nativeDiv.style.height=h+'px';
        }
    }

    doInit(pOpts:RzUiOpts) {
        return true;
    }

    setNavAttr(k:string, v:any){
        if(this.nativeDiv) (this.nativeDiv as any)[k]=v;
    }

    init(pOpts?:RzUiOpts){
        let opts=pOpts || this.initOpts || {};

        if(opts && opts.isDraggable !==undefined) this.isDraggable=opts.isDraggable;

        opts=this.doPreOpts(opts);
 
        if(rzIs.d)rzlog.debug('RzUiLet.1.nativeDiv=',this.nativeDiv);
 
        this.nativeDiv=this.nativeDiv || opts?.nativeDiv || RzAwtTool.CreateDivByPram({tag:opts?.tag,tagType:opts?.tagType,id:opts?.id});
        if(this.nativeDiv) (this.nativeDiv as any)._srcLet = this;
         

         this._isVisible=true;
         if(rzIs.d)rzlog.debug('RzUiLet.2.init: navDiv=',this.nativeDiv,',opts=',opts,',doInit=',this.doInit);

         if(opts?.parent) this.parent=opts.parent;
         else if(opts?.parentDiv && (opts.parentDiv as any)._srcLet ) this.parent=(opts.parentDiv as any)._srcLet;
         else if(opts?.parentDiv ) this.parent=new RzUiLet({nativeDiv:opts.parentDiv});


         // if(rzIs.d)rzlog.debug('parent:',this.parent)
         if(this.parent)  this.parent.addChild(this as RzUiLet);
 
        
         let className=null;
         let cssText=null;
         if(opts?.cssText) cssText =opts.cssText;
         if(opts?.style&& this.nativeDiv){
            Object.assign(this.nativeDiv.style,opts.style);
        } 

 
         if(opts?.className) className=opts.className;


         if((this.nativeDiv as any).hasFocus) {
             if(className) className+=' rzcmn-ui-focus';
             else className='rzcmn-ui-focus';
         }

         if(className &&  this.nativeDiv) {
            let vs=className.split(' ');
            if(vs.length > 1) this.nativeDiv.classList.add(...vs);
            else this.nativeDiv.className=className;
         }
         if(cssText &&  this.nativeDiv) this.nativeDiv.style.cssText=MergeCss(cssText);

         if(  this.width > 0 && this.height>0){
              // this.nativeDiv.style.position='absolute'
               //this.setBounds(this.x,this.y,this.width,this.height)
         }

         
         if(opts?.innerHTML &&  this.nativeDiv){
            this.nativeDiv.innerHTML=opts.innerHTML;
        }
 
        
         if(this.doInit){
             let b=this.doInit(opts);
             if(rzIs.d)rzlog.debug('RzUiLet.4: doInit r=',b,',this=',this);
             if(!b) return b;
         } 
 
 
        if(this.onPaint) this.nativeDiv?.addEventListener('paint',(e:Event)=>{
            rzlog.debug("repaint EVENT");
            if(this.onPaint) this.onPaint(e)
        });

        if(this.isDraggable && this.nativeDiv){
            this.nativeDiv.onmousedown  = (e:MouseEvent)=>{
                if(rzIs.d)rzlog.debug('RzUiLet.raw.onmousedown : this=',this, ',tgt=',e.target)

                if(this.onMouseMove) {
                    (document as any)._focusLet=this;
                    (document as any)._focusLet.isDragging=true;
                    document.onmousemove=(e)=>{
                        e.preventDefault()
                        let focusLet=(document as any)._focusLet;
                        let myDoc=(document as any);
                        if(rzIs.t)rzlog.trace('RzUiLet.raw.onmousemove: e=',e,',focus=', focusLet)
                        if( !(e.buttons===1 && e.button===0)){
                            document.onmouseup=document.onmousemove=null 
                            myDoc._focusLet=null
                            return    
                        }

                        if(focusLet?.isTouchStart) return;

                        //if(rzIs.d)rzlog.debug("RzUiLet.raw.onmousedown : donMouseMov=",document._focusLet.doMouseMove);
                        focusLet.doMouseMove(e,true)
                    }

                    this.nativeDiv!.onmouseup=document.onmouseup=(e)=>{
                        e.preventDefault()
                        
                        let focusLet:RzUiLet=(document as any)._focusLet;
                        if(rzIs.d)rzlog.trace('RzUiLet.raw.onmouseup=',e,',focus=', focusLet)
                        if(focusLet?.onMouseUp) focusLet.doMouseUp(e);

                        document.onmouseup=document.onmousemove=null;
                        if(focusLet)focusLet.isDragging=false;
                        (document as any)._focusLet=null
                    }
                }

                if(this.onMouseDown) this.doMouseDown(e)
            }
        }//if(this.isDraggable && this.nativeDiv)
         

        if(this.onChange && this.nativeDiv) this.nativeDiv.onchange=this.doOnChange;

        if(!this.isDraggable && this.nativeDiv) this.nativeDiv.onmouseup =  (e:MouseEvent)=>{this.doMouseUp(e)};

        if(this.onClick && this.nativeDiv) this.nativeDiv.onclick=(e:Event)=>{ if(rzIs.d)rzlog.debug('MOUSE CLICK:this=',this); this.doClick(e) };
        

        if(this.onMouseWheel && this.nativeDiv )
            (this.nativeDiv as any).onmousewheel =(e:WheelEvent)=>{ if(this.onMouseWheel) this.onMouseWheel(e)};
            

        

        if( opts?.isTouchable){
            this.nativeDiv.addEventListener('touchmove',(e)=>{
                if(rzIs.d)rzlog.debug('cmn.navDiv.touchmove:e=',e)
                e.preventDefault()
               
              //  this.doMouseMove(e)
            },false);
            this.nativeDiv.addEventListener('touchstart',(e)=>{
                if(rzIs.d)rzlog.debug('cmn.navDiv.touchstart:e=',e)
                e.preventDefault()
            },false);
            this.nativeDiv.addEventListener('touchend',(e)=>{
                if(rzIs.d)rzlog.debug('rzcmnui.touchend:e=',e)
                e.preventDefault();

                (document as any)._focusLet.isDragging=false;
               // this.doMouseUp(e)
            },false);
            this.nativeDiv.addEventListener('touchcancel',(e)=>{
                if(rzIs.d)rzlog.debug('touchcancel:e=',e)
                e.preventDefault();
                (document as any)._focusLet.isDragging=false;
               // this.doMouseOut(e)
            },false);
        }

        //@Key Event
        let isKeyMount=false;
        if(isKeyMount && !this.onChange){
            let isFocusRegistered=false
            if(this.onKeyDown||this.onKeyUp) {
                const downFn = this.onKeyDown ? (e:KeyboardEvent)=>{ this.onKeyDown!(e) }: undefined;
                const upFn = this.onKeyUp ? (e:KeyboardEvent)=>{ this.onKeyUp!(e) }:undefined;

               RzUiBindFocusAndKeyEvent(this.nativeDiv!, downFn, upFn);
                isFocusRegistered=true;
            }

            if(this.onFocus && !isFocusRegistered){
                RzUiBindFocusAndKeyEvent(this.nativeDiv!, undefined, undefined);
            }
        }

        if(opts?.onVisible) this.onVisible=opts.onVisible;
        
        if(this.nativeDiv && this.onVisible)  this.onVisible(this._isVisible);
        if(opts?.isVisible===false){
            this.setVisible(false);
         }

         this.isInit=true;
         return true;
     }    


 
     end(){}


     setFocus(focus:boolean){
         this.hasFocus=focus;
     }


    doOnChange(e:any){
        if(this.onChange) this.onChange(e);
    }

    doClick(e:any){
         if(this.isDragging) return;
         if(this.onClick) this.onClick(e);
    }
    
    doMouseDown(evt:MouseEvent){
        if(this.isSkipMouseEvent)   {
            if(rzIs.d)rzlog.debug('skip mouse :this=',this);
            return ;
        }  
         
        let e= evt||window.event;
        if(e?.stopPropagation) e.stopPropagation();
        //e.preventDefault()
        if(rzIs.d)rzlog.debug('doMouseDown:isSkipEvent=',this.isSkipMouseEvent,',this=',this);
 

        this.hasFocus=true;
 

        if(this.onMouseDown) {
            this.onMouseDown(e);
          
        }
    }


    doMouseMove(evt:MouseEvent){
        let e= evt||window.event;
        if(e?.stopPropagation)  e.stopPropagation();
        
 
        if(rzIs.d) rzlog.debug(`MouseMove : x=${e.clientX},y=${e.clientY}`);
        if(this.onMouseMove) this.onMouseMove(e);
    }
 
    doMouseUp(e:MouseEvent, b?:boolean){
        if(rzIs.d)rzlog.debug('rzcmnui.doMouseUp:onMouseup',this.onMouseUp,',skip=',this.isSkipMouseEvent,',e=',e);
        if(this.isSkipMouseEvent) return;
        if(e?.stopPropagation) e.stopPropagation();
        if(rzIs.d)rzlog.debug('doMouseUp:');

        this.hasFocus=false;
        //(document as any)._focusLet.isDragging=false;
        if(this.onMouseUp) this.onMouseUp(e);
    }

    doMouseOut(e:MouseEvent){
        if(e?.stopPropagation)  e.stopPropagation();
        
        if(this.onMouseOut) this.onMouseOut(e);
    }

    touchStartAt?:number;
   
    allowedTime = 500;
    allowedSwipeLimit=200;
    prevTouchPos?:RzTouchPos;
    prevTouchPos2?:RzTouchPos;
    lastTouchPos?:RzTouchPos;
    lastTouchPos2?:RzTouchPos;

    isTouchStart=false;
    isSwipe=false;
    prevDist?:number;
    orgDist?:number;
    doTouchStart(e:TouchEvent ){
        rzlog.debug("doTouchStart:e=",e,);
        let te=e.touches[0];
        
        this.prevDist=undefined;
        this.orgDist=undefined;
        this.prevTouchPos=undefined;
        this.prevTouchPos2=undefined;
        this.lastTouchPos=undefined;
        this.lastTouchPos2=undefined;

        this.touchStartAt=new Date().getTime();
        this.prevTouchPos={x:te.pageX, y:te.pageY};

        let te2=e.touches.length>1?e.touches[1]:undefined;
        if(te2) this.prevTouchPos2={x:te2.pageX, y:te2.pageY};

        this.isTouchStart=true;
        this.isSwipe=true;
    }

    toDistance(p1 :RzTouchPos ,p2:RzTouchPos):number{
        let dx=p1.x-p2.x;
        let dy=p1.y-p2.y;
        return Math.sqrt(dx*dx + dy*dy);
    }

    doTouchMove(e:TouchEvent ){
        
        let te=e.touches[0];
        let te2=(e.touches.length>1) ? e.touches[1]:undefined;
        this.lastTouchPos={x:te.pageX, y:te.pageY}
        if(te2) this.lastTouchPos2={x:te2.pageX, y:te2.pageY};
        else return;
        
        let distPrev=this.toDistance(this.prevTouchPos!,this.prevTouchPos2!);
        let distLast=this.toDistance(this.lastTouchPos,this.lastTouchPos2);
        rzlog.debug('doTouchMove : distLast=',distLast,',distPrev=',distPrev);

        if(distPrev+20 > distLast && distPrev-20 <distLast ){
            this.isSwipe=true;
        }else {
            this.isSwipe=false;
            let newDistFromOrg=distLast;//this.toDistance(this.prevTouchPos!,this.lastTouchPos);
            let dt=0;
            if(this.orgDist){

                dt=newDistFromOrg-this.orgDist;
                let incDt=newDistFromOrg - (this.prevDist||0);
                this.prevDist=newDistFromOrg;
                const ZOOM_IN_LIMIT=10;
                const ZOOM_OUT_LIMIT=-15;
                if(incDt<ZOOM_IN_LIMIT && incDt>ZOOM_OUT_LIMIT) return;

                rzlog.debug('doTouchMove : dt=',dt,',curDt=',newDistFromOrg,',orgDt=',this.orgDist);
                if(dt>ZOOM_IN_LIMIT){
                    if(this.onZoom) this.onZoom({isMagified:true});
                }else if(dt<ZOOM_OUT_LIMIT) {
                    if(this.onZoom) this.onZoom({isMagified:false});
                } 
                else return ;
            }
            else this.orgDist=distPrev;
            
        }

    }

    doTouchEnd(e:TouchEvent,isCancel?:boolean){
        rzlog.debug("doTouchEnd:isCancel=",isCancel,",e=",e);

        if(e.touches.length==1) {

            return;
        }

        if(isCancel==true){
            this.doClearTouch();
            return;
        }
        let  touchEndAt=new Date().getTime();
        const dt= touchEndAt - this.touchStartAt!;
        rzlog.debug("doTouchEnd:dt=",dt);
        if(dt>this.allowedTime){
            this.doClearTouch();
            return;
        }
        
        
        if(!this.isSwipe){
            this.doClearTouch();
            return;
        }
        if(!this.prevTouchPos){
            this.doClearTouch();
            return;
        }

        let pgx=this.lastTouchPos!.x;
        let pgy=this.lastTouchPos!.y;
        let dx= pgx-this.prevTouchPos!.x;
        let dy= pgy-this.prevTouchPos!.y;
 
        
        
        rzlog.debug("doTouchEnd:dx=",dx,',dy=',dy);
        //e.preventDefault()
        e.stopPropagation();
        if(dx > this.allowedSwipeLimit ){
            rzlog.debug("swipe next !!!!");
            if(this.onSwipe)this.onSwipe({isNext:true,dx:dx,dy:dy});

        }
        else if( dx < -this.allowedSwipeLimit){
            rzlog.debug("swipe prev !!!!");
            if(this.onSwipe)this.onSwipe({isNext:false,dx:dx,dy:dy});
        }
        this.doClearTouch();
    }
    doClearTouch(){
        this.touchStartAt=undefined;
        setTimeout(()=>{this.isTouchStart=false},200);
        this.lastTouchPos=undefined;
        this.lastTouchPos2=undefined;
        this.prevTouchPos=undefined;
        this.prevTouchPos2=undefined;
        
        this.orgDist=undefined;
        this.prevDist=undefined;

        this.isSwipe=false;
        this.isTouchStart=false;
        this.isDragging=false;
    }

    setParent(uiLet:RzUiLet){
         this.parent=uiLet;
    }

    async repaint(){
        if(rzIs.d)rzlog.trace('UiLet.repaint...:navDiv=',this.nativeDiv,',this.onPaint=',this.onPaint);

         
        if(this.nativeDiv)this.nativeDiv.style.visibility="hidden";
        if(this.nativeDiv)this.nativeDiv.style.visibility="visible";
        if(this.onPaint) this.onPaint();
        //let  evt = document.createEvent("paint");
        
        if(this.onPaint){
            let evt=new Event('paint')
            if(this.nativeDiv)this.nativeDiv.dispatchEvent(evt)
        }
        // or : document.dispatchEvent(e)
    }


    getParentRect(){
        let p=this.parent?.nativeDiv;
        if(!p) return null;
        let rect=p.getBoundingClientRect();
        if(rzIs.d)rzlog.debug('getParentRect:rect=',rect,',p=',p);
        return rect;
    }

    layout(p?:RzRect):void{}

    _isResizable:boolean=false;
    isResizable():boolean{
        return this._isResizable;
    }

    setResizable(b:boolean){
        this._isResizable=b;
    }

}//class

/******************/
export interface RzResizeUi {
    setBounds(r:RzRect):void;
    getBounds():RzRect;
    setPos(p:RzPos):void;

    layout(p?:RzRect):void;
    isResizable():boolean;
}

/****************** 
 * Label
 */
 export class RzLabelLet  extends RzUiLet  {
    doInit(opts?:RzUiOpts){
        let el=RzAwtTool.createTextNode(opts?.title||"");
        if(this.nativeDiv) this.nativeDiv.appendChild(el);
        //this.addChild(el)
        return true;
    }
 }  

 export class RzBtnLet  extends RzUiLet  {
    
    title?:string;
    btnId?:string;
    
    constructor(opts?:RzUiOpts){
        super(opts);
        this.isSkipMouseEvent=true;
    }

    doPreOpts(opts:RzUiOpts){
        let nopt=super.doPreOpts(opts);
        let topts={tag:'button',...nopt};
        return topts;
    }

    setTitle(t:string){
         this.title=t;
         if(this.nativeDiv) this.nativeDiv.innerHTML=t;
    }

    getTitle(){
        if(this.nativeDiv) return this.nativeDiv.innerHTML;
        return this.title;
    }

    doInit(opts?:RzUiOpts){
        if(opts?.cssText && this.nativeDiv ) this.nativeDiv.style.cssText=MergeCss(opts.cssText);
        if(!this.nativeDiv ) return true;

        this.nativeDiv.innerHTML  = opts?.innerHTML||this.title||'';
        this.nativeDiv.onclick=(e:Event)=>{
            if(rzIs.d)rzlog.debug('nateiveDiv=',e);
            if(this.onClick)this.onClick(e);
            if(opts?.onClick) opts.onClick(e);
        }
       
        return true;
    }

 }  //RzBtnLet

 export class RzInputLet  extends RzUiLet  {
    
    value:string|null=null;
    doPreOpts(opts:RzUiOpts){
        opts.tag='input';
        return super.doPreOpts(opts);
    }

    setValue(t:string){
         this.value=t;
         if(this.nativeDiv)(this.nativeDiv as HTMLInputElement).value=t;
    }
    
    getValue(){
        if(this.nativeDiv) this.value=(this.nativeDiv as HTMLInputElement).value;
        return this.value;
    }



    doInit(opts:RzUiOpts){
        if(this.nativeDiv&& this.value) (this.nativeDiv as HTMLInputElement).value  = this.value;
       
        return true;
    }

 }  //RzBtnLet

 export interface RzDlgOpts extends RzUiOpts {
    title?:string;
    hasTitle?:boolean;
    topCssText?:string;
    initSub?:(opt:RzDlgOpts)=>void;
 }

 /****************** */
 export class RzDlg extends RzUiLet {
    pos1=0;
    pos2=0;
    pos3=0;
    pos4=0;
    isDragged=false;
    isDragging=false;
    borderDef='';
    borderFocus='1px solid black';
    hasTitle=false;
    title?:string;
    topLet?:RzUiLet;

    constructor(opts?:RzDlgOpts){
         super(opts);
         this.pos1=0;
         this.isDragged=false;
         this.isDraggable=true;
         this.hasTitle=opts?.hasTitle||false;
         this.title=opts?.title
     }

    setHasTitle(b:boolean){
        this.hasTitle=b;
    }

    setTitle(title:string){
        this.title=title;
    }

    doPreOpts(opts:RzUiOpts):RzUiOpts{
        this.borderDef=(opts?.style)?opts.style.border:'1px solid gray';
        let nopt={...opts, isDraggable:true};

        if(this.hasTitle) {
            let css=`display:flex;flex-direction:column;`;
            nopt= { ...opts, isDraggable:true, 
                cssText: css + (opts.cssText? opts.cssText:'') 
            };
        }

        return nopt;
    }


    doInitSub(opts:RzUiOpts){
        let dopts:RzDlgOpts=opts as  RzDlgOpts;

        if(_isTest){
            let pnl= new RzUiLet();

            pnl.setParent(this);
            pnl.init({cssText:`background:red;width:100%;height:100%`});
        }

        rzlog.debug('RzDlg.doInitSub>dopts.initSub: opts=',opts);
        if(dopts.initSub) dopts.initSub(dopts);
    }

    onDlgClosed?: ()=>void;

    doInit(opt:RzUiOpts){
  
        if(this.hasTitle){
            this.doInitTop(opt);
            this.doInitSub(opt);
        } 

        return true;
    }

 

    doInitTop(opt:RzUiOpts){
        let dopt : RzDlgOpts = opt as  RzDlgOpts;
        let topHeight=36;
        let top=new RzUiLet();
        top.setParent(this);
        let css=`display:flex;flex-direction:row; 
            background-color:#a6a6a6; 
            align-items:center;
            height:${topHeight}px;width:100%;`  ;
        
            if(dopt.topCssText)      css=css+dopt.topCssText;

        let html=`<div id="${top.id}.topTitle" 
                    style="flex-grow:1; margin-left:10px; color:white;">
                    ${this.title}</div>
                  <button id="${top.id}.topCloseBtn" 
                    style="border-width:0px; background:transparent; color:white;width:20px;height:20px;">x</button>`;

        top.init({cssText:dopt.topCssText||css,
            innerHTML:html});

        let btnEl= top.getElementById("topCloseBtn");
        btnEl!.onclick=(e)=>{this.doCloseClick(e)};
    }

    doCloseClick(e:Event){

        if(this.parent)this.parent.removeChild(this);
        if(this.onDlgClosed) this.onDlgClosed();
    }

     
    onMouseDown=(e:MouseEvent)=>{
        e.stopPropagation()
        if(rzIs.d)rzlog.debug('RzDlg.onmousedown:e=',e)
        this.isDragged=false

        this.pos3 = e.clientX;
        this.pos4 = e.clientY;
        if(this.hasFocus &&  this.nativeDiv) {
            this.nativeDiv.style.border=this.borderFocus;
        } 
    }

    isSkipMouseMove=false;
    onMouseMove=(e:MouseEvent)=>{
        e.stopPropagation();
        if(rzIs.d)rzlog.debug(`move : x=${e.clientX},y=${e.clientY},btn=${e.button}`);

        if(this.isSkipMouseMove) return;

           if(e.button===0)   {
                this.isDragged=true;
           }  

           // calculate the new cursor position:
           this.pos1 = this.pos3 - e.clientX;
           this.pos2 = this.pos4 - e.clientY;
           this.pos3 = e.clientX;
           this.pos4 = e.clientY;

           let elmnt= this.nativeDiv ;
           if(!elmnt) return;

           //let p=elmnt.parentNode.parentNode? elmnt.parentNode.parentNode:elmnt.parentNode
           //let rect=p.getBoundingClientRect()
           let rect=this.getParentRect() || {width:0, height:0};
           let w=elmnt.clientWidth;
           let h= elmnt.clientHeight;
           let pw=rect.width;
           let ph=rect.height;

            if(rzIs.d) rzlog.debug('mx=', e.clientX,',my=', e.clientY,', w=',w,'h=',h,',elmnt=',elmnt, ',rect=',rect);

           // set the element's new position:
           let ty=elmnt.offsetTop - this.pos2;
           //elmnt.style.top = ty + "px";
           if( ty>=0 ){
                if(ph >0 ){
                    if(ph >= ty+h ){
                        elmnt.style.top = ty + "px";
                        this.y=ty;
                    } 
                } else  {
                    elmnt.style.top = ty + "px";
                    this.y=ty;
                } 
            }

            let tx=(elmnt.offsetLeft - this.pos1);
            //elmnt.style.left = tx + "px";

            if( tx >=0 ){
                if(pw > 0){
                    if(pw>=tx+w) {
                        elmnt.style.left = tx + "px";
                        this.x=tx;
                    }
                } else {
                    elmnt.style.left = tx + "px";
                    this.x=tx;
                }
            }
    }
 
    onMouseUp=(e:MouseEvent)=>{
        e.stopPropagation();
        if(!this.hasFocus && this.nativeDiv)  this.nativeDiv.style.border=this.borderDef;
    }   



 

 }//class
 
 /**********************
  * RzUi 
  *********************/
export  class RzSizeDlg extends RzDlg {
    isClosable=true;
    isResizeBtnOn=false;
    resizeBtnBgColor?:string;

    setResizeBtnOn(b:boolean){
        this.isResizeBtnOn=b;
    }

    doPreOpts(opts:RzUiOpts){
        //let bgColor= 'lightgray'
        let bgColor= 'transparent';
        let nopt=super.doPreOpts(opts);
        let tsize=this.width==0?{}:
             {width:`${this.width}px`,height:`${this.height}px`,};

        return { ...nopt,
            className:'rzcmn-ui-resizable',
            style:{ position:'absolute', 
                    left:`${this.x}px`,top:`${this.y}px`,
                    ...tsize,       
                    border :`1px solid #929393`,
                    backgroundColor:`${bgColor}` ,
                    ...(nopt?.style ? nopt.style:{})
            }//style
        };//ret
    }//method
    


    doInit(opts:RzUiOpts){
        if(opts?.isClosable !==undefined) this.isClosable=opts.isClosable;

        let rect=this.getBounds() ;
 
            if(this.isClosable){
                let closeBtn=  new RzUiLet();
                closeBtn.setParent(this as RzUiLet);
                closeBtn.setBounds(4,4,32,32);
                let css=`position:absolute; left:4px;top:4px;
                        width:32px;height:32px;cursor:pointer;
                        background:gray`;
                closeBtn.init({cssText:css});
                if(closeBtn.nativeDiv) 
                closeBtn.nativeDiv.onclick=(e)=>{
                    if(rzIs.d)rzlog.debug('this=',this)
                    if(this.parent?.nativeDiv&& this.nativeDiv) this.parent.nativeDiv.removeChild(this.nativeDiv) 
                };
            }
   
           let tx=rect.width-this.resizeBoxSize;
           let ty=rect.height-this.resizeBoxSize;
           
           
           if(this.isResizeBtnOn) this.doAddResizeBtn(this,tx,ty);
   
        return true;
    }
    resizeBoxSize=16;

    onResize=(rect:any)=>{
       // this.memoLet.onResize(this)
    }


    doAddResizeBtn(pLet:RzUiLet,tx:number,ty:number){
        let btn2=new RzUiLet();
        btn2.isDraggable=true;
        let pbox=pLet.getBounds();

        let tx1=pbox.x+pbox.width -tx;
        let ty1=pbox.y+pbox.height -ty;
        btn2.setParent(pLet as RzUiLet);
  
        btn2.setBounds(tx1,ty1,16,16);

        btn2.onMouseDown=(e:MouseEvent)=>{
            
            let tobj=btn2 as any
            tobj.xpos= e.clientX
            tobj.ypos= e.clientY
            tobj.orgXpos= tobj.nativeDiv.style.left? parseFloat(tobj.nativeDiv.style.left.substring(0,tobj.nativeDiv.style.left.length-2)):0
            tobj.orgYpos= tobj.nativeDiv.style.top? parseFloat(tobj.nativeDiv.style.top.substring(0,tobj.nativeDiv.style.top.length-2)):0
            // not work
            // tobj.orgXpos= tobj.nativeDiv.left
            // tobj.orgYpos= tobj.nativeDiv.top

            let p=tobj.parent
             tobj.orgWidth= p.nativeDiv.style.width? parseFloat(p.nativeDiv.style.width.substring(0,p.nativeDiv.style.width.length-2)):0
             tobj.orgHeight= p.nativeDiv.style.height? parseFloat(p.nativeDiv.style.height.substring(0,p.nativeDiv.style.height.length-2)):0
             // not work
            // tobj.orgWidth= p.nativeDiv.width
            // tobj.orgHeight= p.nativeDiv.height

            tobj.pressed=true

            if(rzIs.d)rzlog.debug('resize.down: e.x=',e.clientX,',e.y=',e.clientY,
            ',c.x=',tobj.orgXpos,',c.y=',tobj.orgYpos,',w=',tobj.orgWidth,',h=',tobj.orgHeight)
        };
        
        btn2.onMouseUp=(e:MouseEvent)=>{
            let tobj=btn2 as any
            tobj.pressed=false
        };

        btn2.onMouseMove=(e:MouseEvent)=>{
            if(this.isSkipMouseMove) return;

            let tobj=btn2 as any
            //if(!tobj.hasFocus) return
            if(tobj.pressed){
                //if(isTrc)
                 if(rzIs.d)rzlog.debug('resize.move: e.x=',e.clientX,',e.y=',e.clientY,',e.btn=',e.button)
                let dx=e.clientX-tobj.xpos
                let dy=e.clientY -tobj.ypos
                let tx=tobj.orgXpos  + dx
                let ty=tobj.orgYpos  + dy
                tobj.nativeDiv.style.left= (tx)+'px'
                tobj.nativeDiv.style.top= (ty)+'px'
                tobj.xpos= e.clientX
                tobj.ypos= e.clientY
                tobj.orgXpos=tx
                tobj.orgYpos=ty

                let tw=tobj.orgWidth + dx
                let th=tobj.orgHeight + dy

                tobj.parent.nativeDiv.style.width=tw+'px'
                tobj.parent.nativeDiv.style.height=th+'px'
                tobj.orgWidth=tw
                tobj.orgHeight=th
                tobj.parent.onResize(tobj)
            }
        };
        rzlog.debug("new SIZEBOX");
        /*
        border-left: 0px solid transparent;            
        border-bottom: 0px solid green;
        border-right: 16px solid transparent;*/
//width:32px; height:32px;
        let bgClr=this.resizeBtnBgColor? this.resizeBtnBgColor:'lightgray';
        let css=`position:absolute; 
                left:${tx-2}px;top:${ty-2}px; 
                ${isDbgUi?'border: 1px solid red;':''}
                cursor:se-resize;
                width:0px;height:0px;
                border-left: 16px solid transparent;            
                border-bottom: 16px solid ${bgClr};
                border-right: 0px solid transparent;
                `;
        btn2.init({isDraggable:true, cssText: css});

        return btn2;
    }

}//class


/**********
 * 
 */


 export class RzEventNotier{
    listeners:OnFn[]=[];
    
    addOnEvent(fn:RzOnFn,pr?:any){
        let l:OnFn={fn:fn, param:pr};
        this.listeners.push(l);
    }
 
    delOnEvent(fn:RzOnFn,pr?:any){
        this.listeners=this.listeners.filter(t=>{
                    if(pr && t.param && pr!==t.param) return true;
                    else return t.fn!==fn
                }
            );
    }
 
    async notiEvent(evt:any){
         for(let i in this.listeners){
             let l=this.listeners[i];
             console.log('notiEvent ===> ' , l);
             l.fn(evt,l.param);
         }
    }

    delOnEventAll(){
        this.listeners=[];
    }

    static NotiEvent(lis:OnFn[], ev:any){
        for(let i in lis){
            let l=lis[i];
            l.fn(ev,l.param);
        }
   }

   static AddOnEvent(lis:OnFn[], fn:RzOnFn, pr?:any){
       lis.push({fn:fn,param:pr});
   }

   static DelOnEvent(lis:OnFn[] , fn:RzOnFn){
       lis=lis.filter(t=> t.fn!==fn);
       return lis;
   }

}


/**************
 * RzBasRepo
 */

 export class RzBasRepo extends RzEventNotier implements RzRepo {

    async init(ctx:RzCtx, opt?: any): Promise<RzRes<void>> {
       return NewOk();
    }
     
    close(): void {
    }

    async load(opt?: any): Promise<RzRes<void>> {
        return NewOk();
    }

    async save(opt?: any): Promise<RzRes<void>> {
        return NewOk();
    }
 
}//class




export function RzUiGetSrc(nativeEvent:Event){
    let r=nativeEvent.target as any;

    while(!r._srcLet) r=r.parentNode;

    return r?._srcLet;
}



export function RzUiBindFocusAndKeyEvent(nativeDiv:HTMLElement,downFn?:(e:KeyboardEvent)=>void,upFn?:(e:KeyboardEvent)=>void){
    if(rzIs.d) rzlog.debug('RzUiBindFocusAndKeyEvent.: Focus  !!!', downFn);

    let tdiv=(nativeDiv as any);
    nativeDiv.addEventListener('mouseenter',(e)=>{
        if(rzIs.d)rzlog.debug('RzUiBindFocusAndKeyEvent.OnFocusIn : !!! - nav=',nativeDiv)
        if(downFn)   window.addEventListener('keydown',downFn)
        if(upFn)   window.addEventListener('keyup',upFn)

        if(tdiv._srcLet && tdiv._srcLet.onFocus) tdiv._srcLet.onFocus({target:nativeDiv},true)
    },true);
    nativeDiv.addEventListener('mouseleave',(e)=>{
        if(rzIs.d)rzlog.debug('RzUiBindFocusAndKeyEvent.OnFocusOut : !!! - nav=',nativeDiv)
        if(downFn) window.removeEventListener('keydown',downFn)
        if(upFn) window.removeEventListener('keyup',upFn)

        if(tdiv._srcLet && tdiv._srcLet.onFocus) tdiv._srcLet.onFocus({target:nativeDiv},false)
    },true);
}


export function RzUi_getMobile() {
    
    let b1=navigator.userAgent.match(/Android/i)?'android':null;
    let b2=navigator.userAgent.match(/BlackBerry/i)?'berry':null;
    let b3=navigator.userAgent.match(/iPhone|iPad|iPod/i)?'apple':null;
    let b4=navigator.userAgent.match(/Opera Mini/i)?'opera':null;
    let b5=navigator.userAgent.match(/IEMobile/i)?'mobile':null;

    return b1||b2||b3||b4||b5;
}

export function RzUi_clearChildren(el:HTMLElement){
    while (el && el.lastElementChild) {
        el.removeChild(el.lastElementChild);
    }

    return el;
}

let _isMobInit=false;
let _isMobile=false;

export function scale(v:number,isMob?:boolean,mobVal?:number){
    if(isMob==true){
        if(!_isMobInit) _initMob();
        if(!_isMobile) return v;
        if(mobVal!==undefined) return mobVal;
    }
    return v;
}

function _initMob(){
    if(!_isMobInit){
        _isMobInit=true;
        //let b1=RzCmnUi_isMobileTablet()
        let b=RzCmnUi_isMobile();
        rzlog.debug("isMobile=",b);
        _isMobile=b; 
    }
}


export function toCommaNumber(x:number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}



export function RzCmnUi_isMobile() {
    let check = false;
    (function(a){
      if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |Mac|maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4))) 
        check = true;
    })(navigator.userAgent||navigator.vendor);
    console.log('userAgent=',navigator.userAgent)
    return check;
}

export function RzCmnUi_isMobileTablet(){
    var check = false;
    (function(a){
        if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4))) 
            check = true;
    })(navigator.userAgent||navigator.vendor);
    return check;
}


export function rzDlgShow(title:string, msg:string, okFn?:(b?:boolean)=>void, btn?:any){
    RzAwtTool.showDlg(title,msg,okFn, btn);
}

import { ReactNode, createRef, CSSProperties } from "react";
import { CmComponent, RzBtn, RzCol, RzRow, RzTxt } from "./rzcomp";
import { FtCamFlat } from "./ftcam.ui";
import { FtBasFrame, FtBothStat } from "./ftframe.bas.ui";

import { FtBtmStat } from "./ftbtm.ui";
import { FtChatFlat } from "./ftchat.ui";
import { FtTopMenu, ID_BOTH_CAM_ON, ID_MYCAM_ON, ID_OTHER_CAM_ON } from "./fttop.ui";
import { FtClassStat, ftMenuClickHandler } from "./ftframe.float2.ui";
import { FT_LAYOUT2_FRAME } from "../../consts";
import { FtChatDlg } from "./ftdlg.ui";
import { WavleMediaClient } from "../../../wavle-media-client";


/****************************
 * 
 */
interface FtFloatProp {
    wavleClient?: WavleMediaClient;
}

interface FtFloatStat extends FtClassStat, FtBothStat {

}



export class FtFloatFrame extends FtBasFrame<FtFloatProp, FtFloatStat>  {

    constructor(pr: any) {
        super(pr);
        this.state = { isMyCamFocused: false, isOtherCamFocused: false };
    }

    componentDidMount(){
        super.componentDidMount();
    }

    doDrawTop() {
        let w = 600;//900

        //23.11.15 ctx 학부모 참가여부 판단하여 추가-전웅구
        let ctx = this.getCtx();
        const isParentJoinOn = ctx.isParentJoinOn;
        
        return (<RzRow style={{ width: '100%', backgroundColor: '#222222', justifyContent: 'space-between' }}>
            <FtTopMenu wavleClient={this.props.wavleClient} menuType={'float'} style={{ width: w, border: '0px solid red' }} onClick={(id) => { this.doMenuClick(id); }} />
            <RzRow>
                {isParentJoinOn && <RzBtn icon={(<span className="ftclass2-ic-tv" />)}
                    style={{ marginTop: 10, marginLeft: 30, paddingLeft: 10, borderRadius: 13, height: 24, width: 140, border: '2px solid #037DED' }}
                    title="학부모 참관중" />}
                {!ctx.PT && (
                    <RzBtn style={{ marginTop: 10, marginLeft: 30, marginRight: 30 }} title="교재/노트 켜기"
                        onClick={() => { this.doShowNotes(); }} />
                )}
            </RzRow>
        </RzRow>);
    }

    isDlgOn = false;
    chatFlatRef = createRef<FtChatFlat>();
    doDrawBody() {

        let ctx = this.getCtx();
        let leftOn = this.state.isMyCamFocused;//||ctx.isMyCamFocused;
        let rightOn = this.state.isOtherCamFocused;//ctx.isOtherCamFocused;

        let focusAt = (leftOn) ? 0 : ((rightOn) ? 1 : -1);

        let chatFlatW = 699;
        let chatFlatH = 234;

        let chatOff = Boolean(ctx.setting?.isChatOff);
        if (!this.isDlgOn && focusAt == -1) {
            this.isDlgOn = true;
            //alert('dd focusAt='+focusAt);
        }

        /* 23.11.15 채팅 고정해제 추가 */
        let chatW = 699;
        let chatH = 234;

        let h = window.innerHeight - 40 - 50 - 10;
        let w = window.innerWidth - 10;

        let chatY = h - 210;
        let chatX = w - chatW + 8;

        let chatDlgY = h - 430;
        let chatDlgX = w - 380;

        let isChatDlgOn = this.state.isChatDlgOn;
        let isChatOn = !Boolean(ctx.setting?.isChatOff)
        let isNotesOff = ctx.setting?.isNotesOff || false;

        let mt = { marginTop: 0 } as CSSProperties;
        if (isNotesOff) mt = { marginTop : 0};

        let st = {};
        if(focusAt==0){
            // 나를크게보기
            st = {}
        } else if(focusAt==1) {
            // 상대를크게보기
            st = {}
        } else {
            // 기본보기
            st = {}
        }
        let isVertical = this.state.isVertical ?? false;
        
        return (<RzCol style={{ width: '100%', height: '100%', justifyContent: 'space-between' }} className="notNoticeLayout">
            <RzRow style={{ width: '100%', height: 40 }}></RzRow>
            <RzRow className={`camBackArea ${focusAt == 0 || focusAt == 1 ? 'notvCamB' : ''}`} style={{ backgroundColor: '#222222', ...st }}>
                <FtCamFlat focusAt={focusAt} isVertical={isVertical}/>
            </RzRow>
            <RzRow style={{ width: '100%', justifyContent: 'flex-end', ...mt }}>
                {/* {(!chatOff)&&(<FtChatFlat style={{width:chatFlatW,height:chatFlatH}}/>)}  */}
                {(isChatOn && !isChatDlgOn) && (<FtChatFlat ref={this.chatFlatRef}
                    style={{ width: chatFlatW, height: chatFlatH + 30 }}
                    onToggle={(b) => {
                        if (this.chatFlatRef.current) {
                            let r = this.chatFlatRef.current.getClientRect();
                            this.setState({ isChatDlgOn: b, chatDlgX: r?.left, chatDlgY: r?.top });

                            //  alert('client Rect='+JSON.stringify(r));
                        } else this.setState({ isChatDlgOn: b });
                    }}
                />)}
            </RzRow>

            {(isChatOn && isChatDlgOn) && (<FtChatDlg
                style={{ top: chatDlgY, left: chatDlgX, width: 340, height: 403, }}
                isDlgOn={isChatDlgOn} onToggle={(b) => {

                    this.setState({ isChatDlgOn: b });
                }}
            />)}

            {/* <RzRow style={{ justifyContent: 'flex-end', height: 1 }}></RzRow> */}
        </RzCol>
        );
    }

    doDrawBottom() {
        return (<FtBtmStat />)
    }

    /********* */
    doMenuClick(id: string) {
        if (id == ID_BOTH_CAM_ON) {
            this.doFocus(false, false);
        } else if (id == ID_OTHER_CAM_ON) {
            this.doFocus(false, true);
        } else if (id == ID_MYCAM_ON) {
            this.doFocus(true, false);
        } else {
            let ctx = this.getCtx();
            ftMenuClickHandler(id, ctx, (v) => { this.setState(v); }, this.state);
        }
    }
    /********* */
    doShowNotes() {
        let ctx = this.getCtx();


        // ctx.setting = { ...ctx.setting, isNotesOff: false };
        // ctx.setGlobalCtx({ ...ctx, layoutType: FT_LAYOUT2_FRAME });

        let sett = ctx.setting || {}
        sett = { ...sett, isNotesOff: false };

        ctx.setGlobalCtx({ ...ctx, layoutType: FT_LAYOUT2_FRAME, setting: sett });
    }

    /********* */

    doFocus(my: boolean, other: boolean) {
        this.setState({ isMyCamFocused: my, isOtherCamFocused: other });
    }
}//class
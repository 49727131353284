import { rzlog } from "../rzcmn";
import { FtEditLockHdr, FtoEditLock, FtoEtcSetting, FtoNoteInfo, FtoSetting, FtoUser, FtoUserTypeEnum } from "./dto/ftclass.dto";


/************
 * 
 * 
 */
export class FtPermsTool {

    static isVisible(ni:FtoNoteInfo, ui:FtoUser,setting:FtoSetting,etc:FtoEtcSetting){
        let utype=ui.userType;
        
        if (utype===FtoUserTypeEnum.STUDENT){
            if(ni.type==='book' && ni.subType==='answer' ){
                if (setting.isAnswerNoteAllowed &&   etc.isAnswerBookShown)  return true;
                else return false;
            }
            else if(ni.type==='book' && ni.subType!=='answer'  ) {
                if( etc?.isTeachingBookShown) return true;
                else return false;
            }
            else if(ni.type==='note'){
                if(ni.ownerId && ui.uid){
                    if(ni.ownerId === ui.uid) return true;
                    else if( ni.ownerId !== ui.uid &&setting.isTeacherNoteAllowed && etc.isTeacherNoteShown) 
                        return true;
                }

                if(ni.ownerName === ui.username) return true;
                else if( ni.ownerName !== ui.username &&setting.isTeacherNoteAllowed && etc.isTeacherNoteShown) 
                    return true;
                else return false;
            }

            return false;
        }
        else if (utype===FtoUserTypeEnum.TEACHER){
            if(ni.type==='note'){
                if(ni.ownerId!==ni.uid ) return false;
                if(ni.ownerName !== ui.username) return false;
            }
        }
    

        rzlog.debug("FtPermsTool.isVisible : ui=",ui,',noteinf=',ni);

        return true;
    }
}


/************
 * 
 * 
 */
export class FtEditLockTool {

    static newDefault(userType:string){
        if(userType===FtoUserTypeEnum.TEACHER){
            return new FtoEditLock(
                    {   userType:userType,
                        isOwnerLock:false,
                        isOtherLock:true,
                    })
       } else {
        return new FtoEditLock(
            {userType:userType,
                    isOwnerLock:true,
                    isOtherLock:false,
            })
       }
    }

    static hasEditablePage(ui:FtoUser, ninfo: FtoNoteInfo, el:FtoEditLock){
        let r=false;


        if(ninfo.ownerId==ui.uid || ninfo.ownerName===ui.username){
           return r=true; 
        } else {
           if(!el.isOtherLock) r=true;
        }
        rzlog.debug('HasEditble :r=',r)
        return r
    }

    static isEditable(ui:FtoUser,  el:FtoEditLock){
        let r=true;
 

        if(ui.userType===FtoUserTypeEnum.STUDENT){
                r=(el)? ! el.isOwnerLock:true;
                rzlog.debug('hasEditable(doLoadClassInfo,EditLock) : student editable(! ownLk)=',r,',el=',el)
                if(!el.isOtherLock && !el.isOwnerLock ) r=false;
              //  alert('isStu='+r+',oth='+el.isOtherLock+',own='+el.isOwnerLock);
                return r
        } else if(ui.userType===FtoUserTypeEnum.TEACHER){
                r=(el)? !el.isOtherLock:true;
                if(!el.isOtherLock && !el.isOwnerLock ) return false;
                rzlog.debug('hasEditable(doLoadClassInfo,EditLock) : teacher editable(! othLk)=',r,',el=',el)
                return r
        } else  if(ui.userType===FtoUserTypeEnum.OBSERVER){
            return false;
        }

        rzlog.debug('HasEditable :r=',r)
        return r
    }

    static isTabSelectable(ni:FtoNoteInfo, ui:FtoUser,  el:FtoEditLock,etc?:FtoEtcSetting){
        let r=true;
                

        if(ui.userType===FtoUserTypeEnum.STUDENT){

            r=(el)? ! el.isOwnerLock:false;
            if(r === true && ni.type==='note' &&
                ( ni.ownerId== ui.uid || ni.ownerName===ui.username )){
                return false;
            }

            rzlog.debug('isTabSelectable : student editable(! ownLk)=',r,',el=',el)
            return r
        } else if(ui.userType===FtoUserTypeEnum.TEACHER){
      
                r= (el) ?   el.isOwnerLock! : true;

            if(r===true){
                if( etc && !etc.isTeacherNoteShown  && ni.type==='note' && 
                ( ni.ownerId== ui.uid || ni.ownerName===ui.username )){
                    return false;
                }
                if( etc && !etc.isAnswerBookShown  && ni.subType==='answer' && 
                ( ni.ownerId== ui.uid || ni.ownerName===ui.username )){
                    return false;
                }
            }

            rzlog.debug('isTabSelectable : teacher editable(! othLk)=',r,',el=',el)
            return r
        }

        rzlog.debug('HasEditable :r=',r)
        return r
    }


    static  procPausedEvent(hdr:FtEditLockHdr,el:FtoEditLock,ui:FtoUser,){
        
        if(ui?.userType===FtoUserTypeEnum.STUDENT && el.userType===FtoUserTypeEnum.TEACHER){
            if(el.isOwnerLock) hdr.setEventPaused(true);
            else hdr.setEventPaused(false);

        } else if(ui?.userType==FtoUserTypeEnum.TEACHER && el.userType===FtoUserTypeEnum.TEACHER){
            if(el.isOwnerLock) hdr.setEventPaused(false);
            else hdr.setEventPaused(true);
        }
    }
}//class
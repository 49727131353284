import { rzlog, RzUiLet,RzOnFn,RzEventNotier,OnFn,RzRes } from "../../inc";

import { FtLayoutCtx, FtLayoutInit, FtLayoutOpts, FtUiLets } from "../ftclass/ftclass.ui.layout";
import { FtFullLayout } from "./ftclass.ui.layout.full";
import { FT_LAYER_POP } from "../../consts";

/** dbg */
const rzIs=rzlog.makeDefs()
export const FtClassUiLayoutSubPopBar_setDbg =rzIs.setDbg

 
/**
 FtSubPopVidInit
 */
export class FtSubPopVidInit implements FtLayoutInit {
    lman:FtFullLayout;

    constructor(lman:FtFullLayout){
        this.lman=lman;
    }
    
    init(lctx:FtLayoutCtx,pr?:any){
        rzlog.debug("FtSubPopVidInit.init : pr=",pr)
        this.doInitPopVid(lctx)
    }


    doInitPopVid(lctx:FtLayoutCtx){
        let lman=this.lman;
        if(rzIs.d ||true) rzlog.debug('FtSubPopVidInit : isSideON=',lctx.opt.isSideOn,',isPopOp=',lctx.opt.isPopVideoOn)
        
        if(!lctx.opt.isSideOn  && lctx.opt.isPopVideoOn){
            let layer_vid=99;
            let cssTxt=`position:absolute; left:100px;bottom:400px; 
            width:600px;height:300px;z-index:${layer_vid}; 
                        display:flex; flex-direction:row;`
                        + ( rzIs.d?'border:solid; border-color:yellow;':'')
            this.doInitSubPopVid(lctx,lctx.body,lctx.lets!.popup!, cssTxt,lctx.opt)
        } else if(lctx.opt.isSideOn){
            if(lctx.lets?.popup) lman.doRemoveChild(lctx.body,lctx.lets!.popup)
        }
    }

     doInitSubPopVid(lctx:FtLayoutCtx,p:RzUiLet,popLet:RzUiLet,cssText:string,opt?:any){
        let lman=this.lman;
        if(lman.doUdateVisble(p,popLet,0,cssText)) return
        let pop=lman.subLetFac.createPopupVideo(p,cssText,lctx.repoMng!,opt)
        lctx.lets!.popup=pop
     }
}//class

 
/**
 FtSubPopTimeInit
 */
 export class FtSubPopTimeInit implements FtLayoutInit {
    lman:FtFullLayout;

    constructor(lman:FtFullLayout){
        this.lman=lman;
    }
    
    init(lctx:FtLayoutCtx,pr?:any){
        this.doInitPopTime(lctx);
    }


    doInitPopTime(lctx:FtLayoutCtx){
        let lman=this.lman;

        let ttTop=(lctx.opt.height!- 80)
        let ttLeft= (lctx.opt.width!-180)/2
        let ttCssTxt=`left:${ttLeft}px; top:${ttTop}px;`

        if(!lctx.lets?.timePopup){
            this.doInitTimePopup(lctx,lctx.body,lctx.lets!.timePopup!,ttCssTxt)
        } else {
            if(lctx.timePopupPos){
                lctx.lets.timePopup.setPos(lctx.timePopupPos.x,
                    lctx.timePopupPos.y+lman.scrollPos.scrollDy);
            } else {
                lctx.lets.timePopup.setPos(ttLeft,ttTop);
            }
        }
    }

    doInitTimePopup(lctx:FtLayoutCtx,p:RzUiLet,popLet:RzUiLet,cssText:string){
        let lman=this.lman;

        if(lman.doUdateVisble(p,popLet,0,cssText)) return
        let pop=lman.subLetFac.createTimePopup(p,cssText,lctx.repoMng!)
        lctx.lets!.timePopup=pop
     }

     /************************* 
      * 
     */
     doInitPopPage(lctx:FtLayoutCtx){
        let lman=this.lman;

        let ttTop=(lctx.opt.height!- 80)
        let ttLeft= (lctx.opt.width!-180)/2
        let ttCssTxt=`left:${ttLeft}px; top:${ttTop}px;`

        if(!lctx.lets?.timePopup){
            this.doInitTimePopup(lctx,lctx.body,lctx.lets!.timePopup!,ttCssTxt)
        } else {
            if(lctx.timePopupPos){
                lctx.lets.timePopup.setPos(lctx.timePopupPos.x,
                    lctx.timePopupPos.y+lman.scrollPos.scrollDy);
            } else {
                lctx.lets.timePopup.setPos(ttLeft,ttTop);
            }
        }
    }

    doInitPagePopup(lctx:FtLayoutCtx,p:RzUiLet,popLet:RzUiLet,cssText:string){
        let lman=this.lman;

        if(lman.doUdateVisble(p,popLet,0,cssText)) return
        let pop=lman.subLetFac.createTimePopup(p,cssText,lctx.repoMng!)
        lctx.lets!.timePopup=pop
     }

}//class


/**
 FtSubPopBtnInit
 */
 export class FtSubPopBtnInit implements FtLayoutInit {
    lman:FtFullLayout;

    constructor(lman:FtFullLayout){
        this.lman=lman;
    }
    
    init(lctx:FtLayoutCtx,pr?:any){

        this.doInitPopBtn(lctx)
    }


    doInitPopBtn(lctx:FtLayoutCtx){
        let lman=this.lman;
        let popBtHeight=100
        let popBtWidth=80
        let btTop=(lctx.opt.height! - popBtHeight)
        let btLeft= (lctx.opt.width! - popBtWidth )
        let btCssTxt=`z-index:${FT_LAYER_POP}; left:${btLeft}px; top:${btTop}px;`

        if(!lctx.lets?.menuBtn) {
            this.doInitMenuBtn(lctx,lctx.body,lctx.lets!.menuBtn!,btCssTxt)
        } else {
            if(lctx.menuBtnPos){
                lctx.lets.menuBtn!.setPos(lctx.menuBtnPos.x,lctx.menuBtnPos.y+lctx.scrollPos!.scrollDy);
            } else {
                lctx.lets.menuBtn!.setPos(btLeft,btTop);
            }
        }
    }

    doInitMenuBtn(lctx:FtLayoutCtx,p:RzUiLet,btnLet:RzUiLet,cssText:string,opt?:any){
        let lman=this.lman;

        if(lman.doUdateVisble(p,btnLet,0,cssText)) return btnLet
        
        let btn=lman.subLetFac.createMenuBtn(p,cssText,lctx.repoMng!,opt)
         
        lctx.lets!.menuBtn=btn

        btn.onClick=()=>{
            if(rzIs.d) rzlog.debug('FtMenuBtn:sel=',btn.selected)
            btn.setSelected(! btn.selected)
            //this.doUpdateUi( {...this.attrs, isMenuOn:! this.attrs.isMenuOn})
            
            lman.doUpdateUi( {  isMenuOn:! lman.layoutOpts!.isMenuOn})
        }

        return btn
     }
}//class


import { rzlog } from "../../inc"
import { NewOk, RzOn } from "../../inc";
import { RzEventNotier } from "../../inc";
import { RzChatEvent, RzChatEventEnum, RzChatMsg } from "./ftclass.ui.chat";

/**********************************/
const rzlogd=rzlog.makeDefs()
export const RzClassUiChatRepo_setDbg =rzlogd.setDbg
 
/**********************************
 * 
 *********************************/

 export class  RzChatRepo {
    messages:RzChatMsg[]=[]
    idCount=0
    onChatEvents=[]
    username?:string="test1"
    userSesId?:string

    setUserName(uname:string){
        this.username=uname
    }
    setMessages(msgs:RzChatMsg[]){
        this.messages=msgs
    }

    async iter(){
        return NewOk(this.messages)
    }
    
    async getItmes(){
        return NewOk(this.messages)
    }

    doAddItem(it:RzChatMsg){
        if(it.seq! >= this.idCount)this.idCount=it.seq! + 1
        this.messages.push(it)
    }

    addItem(it:RzChatMsg,srcOpt?:any){
        this.doAddItem(it)
        this.notiChatEvent({cmd:RzChatEventEnum.ADD, messages:[it]})
    }


    sendMsg(msg:string){
        let id=this.idCount++;
        //msg=msg.replaceAll("\\n","\\\\n");
        let m:RzChatMsg={id:id.toString(), seq:id, fromName: this.username,   body:msg, }
        this.addItem(m)
    }


    addOnChatEvent(fn:RzOn<RzChatEvent>, pr:any){
        RzEventNotier.AddOnEvent(this.onChatEvents,fn,pr)
    }

    notiChatEvent(e:RzChatEvent){
        RzEventNotier.NotiEvent(this.onChatEvents,e)
    }

    delOnChatEvent(fn:RzOn<RzChatEvent>){
        RzEventNotier.DelOnEvent(this.onChatEvents,fn)
    }

 }//class

export interface RzChatRepoFacOpts{
    type?:string
}

export interface RzChatRepoFac {
     newChatRepo(opts?:RzChatRepoFacOpts):RzChatRepo
}


/**
 * FtClassInfRepoMng
 * 
 */

import { RzRsRes, rzlog, } from "../inc";
import { NewOk,RzRes, RzRepo,NewFail,IsFail,IsOk,RzCtx } from "../inc";
import { FtSrcOpt, FtoClassInfo, FtoClassSharing, FtoEtcSetting, FtoNoteInfo, FtoSession, FtoSessionShare, FtoSetting, FtoUser } from "../dto/ftclass.dto";
 
import { FtClassInfoStore } from "./ftclass.repo.store";
import { RzoNoteShare } from "../note.ui/rznote.ui.dto";
 


/************** */
const rzlogd=rzlog.makeDefs()
export const FtClassInfRepo_setDbg=rzlogd.setDbg
 
export enum  FtClassInfoMutEnum  {
    NOTE_ADD='note.add',
    NOTE_PUT='note.put',
    NOTE_DEL='note.del',
}
export class FtClassInfoMutEvent {
    cmd?:FtClassInfoMutEnum;
    noteInfo?:FtoNoteInfo;
    constructor(pr?:Partial<FtClassInfoMutEvent>){
        Object.assign(this,pr)
    }
}

/*****************
 * 
 */
 export  class FtClassInfoRepo implements RzRepo {
    classId?:string;
    classInfo?:FtoClassInfo;
    store?:FtClassInfoStore;

    constructor(){
        //this.classInfo=new FtoClassInfo()
    }

    setClassId(clsId:string){ this.classId=clsId}
    getClassId(){return this.classId!}

    setCurNoteId(noteId:string){ 
        //this.classInfo!.curNoteId=noteId
        this.classInfo?.notes?.forEach(t=> {
            if(t.noteId===noteId) t.selected=true;
            else t.selected=false;
        });
    }

    setInfoStore(store?:FtClassInfoStore){
        this.store=store
    }

    getSetting():FtoSetting|undefined {
        return this.classInfo?.setting;
    }

    setSetting(stt:FtoSetting) {
        this.classInfo!.setting={...stt};
    }

    getEtcSetting():FtoEtcSetting|undefined {
        return this.classInfo?.etcSetting;
    }

    setEtcSetting(stt:FtoEtcSetting) {
        let org={...this.classInfo?.etcSetting}
        this.classInfo!.etcSetting={...this.classInfo!.etcSetting, ...stt};
        rzlog.debug('classInfo.etc=',this.classInfo!.etcSetting,',org=',org)
        
    }

    getClassSharing():FtoClassSharing|undefined {
        return this.classInfo?.classSharing;
    }

    setClassSharing(stt:FtoClassSharing) {
        let {sessionSharings:ses, noteSharings:notes, ...orgAttr }=this.classInfo?.classSharing||{};
        let {sessionSharings:nSes, noteSharings:nNotes, ... newAttr}= stt;


        if(stt.sessionSharings && nSes) {
            let ks=nSes.map(t=> t.sesId);
            let tses:FtoSessionShare[]=[];
            stt.sessionSharings.forEach(t=> {
                if(!ks.includes(t.sesId)) tses.push(t);
            });

            nSes.forEach(t=> tses.push(t));
            ses=tses;
        }

        if(stt.noteSharings && nNotes){
            let ks=nNotes.map(t=> t.noteId);
            let tses:RzoNoteShare[]=[];
            stt.noteSharings.forEach(t=> {
                if(!ks.includes(t.noteId)) tses.push(t);
            });

            nNotes.forEach(t=> tses.push(t));
            notes=tses;
        }

        this.classInfo!.classSharing={...orgAttr, ...newAttr, sessionSharings:ses, noteSharings:notes};

        rzlog.debug('classInfo.clzSharing=',this.classInfo!.classSharing,',org=',stt)
        
    }

    getNoteShare(noteId:string){
        let {noteSharings:notes, ...orgAttr }=this.classInfo?.classSharing||{};
        
        if(notes===undefined) {
            return undefined;
        }

        let ntSh=notes.find(t=> t.noteId===noteId);
        return ntSh;
    }

    setNoteShare(noteId:string,share?:RzoNoteShare,srcOpt?:FtSrcOpt){
        if(share===undefined) return;

        //merge note sharing
        let {noteSharings:notes, ...orgAttr }=this.classInfo?.classSharing||{};
        
        if(notes===undefined) {
            notes=[];
            this.classInfo!.classSharing={...orgAttr, noteSharings:notes};
        }

        let ntSh=notes.find(t=> t.noteId===noteId);

        if(ntSh ){
            Object.assign(ntSh,{...ntSh,...share});
        } else {
            notes.push(share);
        }
    }


    async init(ctx:RzCtx,opt?:any):Promise<RzRes<void>>{
        if(!this.store) return NewFail("FtClassinfoRepo.init > no infoStore")

        let r = await this.store!.init(ctx)
        if(r.status>0) return NewFail("FtClassinfoRepo.init > infoStroe.init() > "+r.message)
        return NewOk()
    }

    close(): void {
    }

    async load(opt?:any):Promise<RzRes<void>>{
        if(!this.store) return NewFail("FtClassinfoRepo.load > no infoStore")

        if(this.store) return this.store.load(this,opt)
        return NewOk()
    }

    async save(opt?:any):Promise<RzRes<void>>{
        if(!this.store) return NewFail("FtClassinfoRepo.save > no infoStore")
        if(this.store) return this.store.save(this,opt)
        return NewOk()
    }

    setClassInfo(clsInf:FtoClassInfo,srcOpt?:FtSrcOpt){
        this.classInfo=this.dupInfo(clsInf)
        rzlog.debug("FtClassInfRepo.setClassInfo : clasInf=",clsInf,',ninf=',this.classInfo)
    }
    

    dupInfo(clsInfo:any){
        let ninfo=new FtoClassInfo();


        if(clsInfo.attendants) clsInfo.attendants.forEach((t:any) =>{ ninfo.attendants.push( new FtoUser(t))});
        if(clsInfo.mySessions) clsInfo.mySessions.forEach((t:any) =>{ ninfo.mySessions.push( new FtoSession(t))});
        if(clsInfo.notes) clsInfo.notes.forEach((t:any) =>{ ninfo.notes.push( new FtoNoteInfo(t))});
        if(clsInfo.pages) clsInfo.pages.forEach((t:any) =>{ ninfo.pages.push( new FtoNoteInfo(t))});
         
        ninfo.classId=clsInfo!.classId;
        ninfo.host=new FtoUser(clsInfo.host);
        ninfo.myInfo=new FtoUser(clsInfo.myInfo);
        ninfo.title=clsInfo.title;
        ninfo.type=clsInfo.type;
        ninfo.mySession=new FtoSession(clsInfo.mySession);
        if(clsInfo.setting) {
            rzlog.debug("clsInfo.setting=",clsInfo.setting);
            ninfo.setting=new FtoSetting(clsInfo.setting);
            rzlog.debug("ninfo.setting=",ninfo.setting);
        }
        if(clsInfo.etcSetting) ninfo.etcSetting=new FtoEtcSetting(clsInfo.etcSetting);
        if(clsInfo.classSharing) ninfo.classSharing=new FtoClassSharing(clsInfo.classSharing);

        // 1.26 임시 추가
        // if(clsInfo) ninfo.beginAt= new Date();

        return ninfo;
    }


    async getClassInfo(id:string,pathOn?:boolean):Promise<RzRes<FtoClassInfo>>{
        if(this.store) {
           // alert('fetchClassInfo:'+id);
           let r= await this.store.fetchClassInfo(id,this,pathOn);

           if(IsFail(r)) return r;
           if(r.data) r.data=this.doMergeShare(r.data,this.classInfo);
            return r;
        } 

        return NewOk(this.classInfo)
    }

    doMergeShare(src:FtoClassInfo,org?:FtoClassInfo,){
        if(org===undefined) return src;

        if(org.classSharing?.noteSharings){
            org.classSharing.noteSharings.forEach(e=>{
                        let t=src.classSharing?.noteSharings?.find(t=> t.noteId===e.noteId);
                        if(t) {
                            t.isMyGroupWritingReadOn= e.isMyGroupWritingReadOn;
                            t.isMyOtherWritingReadOn=e.isMyOtherWritingReadOn;
                            t.isMyWritingAllOn=e.isMyWritingAllOn;
                            t.isMyWritingReadOn=e.isMyWritingReadOn;
                        }
            });
        }
        return src;
    }

    async putClassInfo(classId:string,clsInf:FtoClassInfo):Promise<RzRes<FtoClassInfo>>{
        if(this.store) {
           // alert('fetchClassInfo:'+id);
            return this.store.putClassInfo(classId,clsInf);
        } 

        return NewOk(this.classInfo)
    }


    async getNoteInfos():Promise<RzRes<FtoNoteInfo[]>>{
        if(!this.classInfo) return NewOk([])
        return NewOk(this.classInfo!.notes)
    }


    async putNoteInfos(notes:FtoNoteInfo[]):Promise<RzRsRes<FtoNoteInfo>>{
        if(this.store){
             let r=await this.store.putNoteInfos(notes);
            return r;
        }

        return NewFail('no store');
    }


    getMySession():FtoSession|null{
        if(!this.classInfo?.mySession) return null
        return this.classInfo!.mySession;
    }


    async getMySessions():Promise<RzRes<FtoSession[]>>{
        if(!this.classInfo) return NewOk([])
        return NewOk(this.classInfo!.mySessions)
    }



    noteCnt=1;
    async addNoteInfo(inf:FtoNoteInfo,srcOpt?:FtSrcOpt){
        let noteId =""+this.noteCnt++;
        
        if(inf.noteId) noteId=inf.noteId;

        let ninf:FtoNoteInfo=new FtoNoteInfo({...inf,noteId:noteId})
        if(this.store){
            let r=await this.store.addNoteInfo(inf)
            if(IsOk(r)) ninf=r.data!
        } 

  

        if(this.classInfo) this.classInfo.notes.push(ninf)

        let ev= new FtClassInfoMutEvent({cmd:FtClassInfoMutEnum.NOTE_ADD,noteInfo:ninf})
        this.notiClassInfoChanged(ev)

        return NewOk(ninf)
    }


    async getNoteInfo(noteId:string):Promise<RzRes<FtoNoteInfo>>{
        let infs=this.classInfo?.notes.filter(p=> p.noteId===noteId)
        if(!infs ||infs?.length===0) return NewFail("no note info : noteId="+noteId)

        let v=infs[0]
        let rv:FtoNoteInfo={...v}

        return NewOk(rv)
    }


    async putNoteInfo(noteId:string, inf:FtoNoteInfo,srcOpt?:FtSrcOpt):Promise<RzRes<FtoNoteInfo>>{
        let infs=this.classInfo?.notes.filter(p=> p.noteId===noteId)
        if(!infs || infs?.length===0) return NewFail("no note info : noteId="+noteId)

        let v:FtoNoteInfo=infs[0]
        Object.assign(v,inf)       
        
        let ev=new FtClassInfoMutEvent({cmd:FtClassInfoMutEnum.NOTE_PUT,noteInfo:v})
        this.notiClassInfoChanged( ev)

        let rv:FtoNoteInfo=v
        return NewOk(rv)
    }

    async delNoteInfo(noteId:string, inf:FtoNoteInfo,srcOpt?:FtSrcOpt):Promise<RzRes<FtoNoteInfo>>{
        let infs=this.classInfo?.notes.filter(p=> p.noteId===noteId)
        if(!infs || infs?.length===0) return NewFail("no note info : noteId="+noteId)

        let tinfs=this.classInfo!.notes.filter(p=> p.noteId!==noteId)
        this.classInfo!.notes=tinfs

        let v=infs[0]
        let rv:FtoNoteInfo={...v}

        let ev=new FtClassInfoMutEvent({cmd:FtClassInfoMutEnum.NOTE_DEL,noteInfo:rv})
        this.notiClassInfoChanged( ev)

        return NewOk(rv)
    }

   

    async setCurPageNo(noteId: string,pageNo:number,srcOpt?:FtSrcOpt){
        let vs=this.classInfo!.notes.filter(t=> t.noteId===noteId)
        if(vs.length===0) return NewFail("no note")
        
       
        vs[0].pageNo=pageNo

        return NewOk()
    }

    async getCurPageNo(noteId: string):Promise<RzRes<number>> {
        let vs=this.classInfo!.notes.filter(t=> t.noteId===noteId)
        if(vs.length===0) return NewFail("no note")

        return NewOk(vs[0].pageNo!)
    }



    async setSessions(sess:FtoSession[],srcOpt?:FtSrcOpt){
        this.classInfo!.mySessions=sess.map(t=> new FtoSession(t))
        return NewOk()
    }

    async addSession(ses:FtoSession,srcOpt?:FtSrcOpt){
        this.classInfo?.mySessions?.push(ses)
        return NewOk()
    }

    async putSession(ses:FtoSession,srcOpt?:FtSrcOpt){
        let vs=this.classInfo!.mySessions.filter(t=>t.id===ses.id)
        //@let vs=this.classInfo!.mySessions.filter(t=>t.sesId===ses.sesId)
        if(vs.length===0) return NewFail("no ses")

        Object.assign(vs[0],ses)

        return NewOk()
    }
 
    async delSession(ses:FtoSession,srcOpt?:FtSrcOpt){
        let vs=this.classInfo!.mySessions.filter(t=>t.id===ses.id)
        //@let vs=this.classInfo!.mySessions.filter(t=>t.sesId===ses.sesId)
        if(vs.length>0) {
            let nvs=this.classInfo!.mySessions.filter(t=>t.id!==ses.id)
            this.classInfo!.mySessions=nvs
        }
        
        return NewOk()
    }
    
    notiClassInfoChanged( ev:FtClassInfoMutEvent ){
        if(this.onClassInfoChanged) this.onClassInfoChanged(ev);
    }

    onClassInfoChanged?:(ev:FtClassInfoMutEvent)=>void;
}//FtClassInfoRepo
 
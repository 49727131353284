import {rzlog,IsFail,IsOk } from "./inc"
import './rznote.ui.css'
import { RzDlg, RzPos, RzRect, RzSizeDlg, RzUiLet, RzUiOpts} from './rzcmn.ui'
import { RzNote, RzPenAttr } from "./rznote.ui"
import { RzoNotePath } from "./rznote.ui.dto"
import { RzDrawPen } from "./rznote.ui.edit"


/****************/
const rzIs=rzlog.makeDefs()
export const RzNoteUiEditText_setDbg=rzIs.setDbg
 
let isDbgUi=false;
export function RzNoteUiEditText_setDbgUi(b:boolean){
    isDbgUi=b
}
const isDbgBorder=false;
/******************
 * 
 */
 
const LAYER_EDIT=3;

 /****************
 * 
 */
 

/***************** */
export class RzEditSizeDlg extends RzSizeDlg {
    edit?:RzUiLet
    dotBtn?:RzUiLet
    fontSize?:number;
    fontColor?:string;
    fontFamily?:string;
    bgColor?:string;
    isMemoStyle:boolean=false;

    setMemoStyle(b:boolean){
        this.isMemoStyle=b;
        if(b)this.resizeBtnBgColor='transparent';
        else this.resizeBtnBgColor=undefined;
    }

    setText(txt:string){
        if(this.nativeDiv && this.edit?.nativeDiv) {
            (this.edit.nativeDiv as HTMLTextAreaElement).value=txt
        }
    }

    setEditBackColor(clr:string){
        this.bgColor=clr;
    }

    setFont(family?:string, fontSize?:number,fontColor?:string){
        
        this.fontFamily=family;
        this.fontColor=fontColor;
        
        if(fontSize) this.fontSize=parseInt(fontSize.toFixed());

        if(this.nativeDiv){
            if(family) this.edit!.nativeDiv!.style!.fontFamily=family;
            if(fontColor) this.edit!.nativeDiv!.style!.color=fontColor;
            if(fontSize) this.edit!.nativeDiv!.style!.fontSize=parseInt(fontSize.toFixed())+"px";
        }
    }


    doInit(opts:RzUiOpts){
        super.doInit(opts)
    
        this.borderFocus=(isDbgBorder)?`3px dashed black`:
                 (this.isMemoStyle)?'':'1px dashed lightgray'
        this.borderDef=(isDbgBorder)?`3px dashed black`:
                 (this.isMemoStyle)?'':'1px dashed lightgray'
        
        this.onFocus=(isFocus)=>{
            if(rzIs.d)rzlog.debug('RzEditSizeDlg.onFocus : isFocus=',isFocus)
        }

        let tdlg=this
        this.onResize=(e)=>{
            let r0=tdlg.getBounds()
            if(tdlg?.edit) {
                let r=tdlg.edit.getBounds()
                let btr=tdlg.dotBtn!.getBounds()
                let leftWidth=r0.width-btr.width
                //let dx=leftWidth-r.width;
                tdlg?.edit?.setBounds(r.x,r.y,leftWidth-1,r0.height-9)
            }
        }

        if(this.dotBtn) {

            this.borderFocus=`0px dashed black`
            this.borderDef=`0px dashed black`

            this.dotBtn.setParent(this)
            this.dotBtn.init()
        }
    
        let tedit=new RzUiLet()
        
        let fontDef=""
        if(this.fontFamily) fontDef+=`font-family:${this.fontFamily}; `;
        if(this.fontSize) fontDef+=`font-size:${this.fontSize}; `;
        if(this.fontColor) fontDef+=`font-color:${this.fontColor}; `;

        rzlog.debug('Edit.Text > fontSz : fontDef=',fontDef)
        //let bgColor="background: transparent;"
        // let bgColor=(this.isMemoStyle)?"background: #FFE5B180;":"background: transparent;";
        let bgColor=(this.isMemoStyle)?"background: #FAFAFA;":"background: transparent;";
        if(this.bgColor) bgColor=this.isMemoStyle? `background:#FFE5B1`
                    :'background: transparent;';
        
        // let bdColor=(this.isMemoStyle)?"border-color:blue;"
        let bdColor=(this.isMemoStyle)?"border-color:#2869A5;"
            :"border:1px dashed lightgray; outline: 1px dashed lightgray;";
        
        if(this.nativeDiv?.style && !this.isMemoStyle) 
            this.nativeDiv.style.border='1px dashed lightgray';
        let dbgTextStyle=(isDbgBorder)?'border:1px solid black;':''
        // let css=`width:100%;${bgColor} ${bdColor} ${fontDef} ${dbgTextStyle} `;
        let css=`width:200px; height:150px;${bgColor} ${bdColor} ${fontDef} ${dbgTextStyle} `;
        tedit.init({parent:this as RzUiLet,
                    className:'rznote_edit' ,
                    isDraggable:true,
                    tag:'textarea',
                    cssText:css,
                });
        if(tedit.nativeDiv){
            rzlog.debug('Note.Edit.On : fontSz=',fontDef)
            tedit.nativeDiv.focus()
            //tedit.nativeDiv.style.fontSize='20px';
        }
                 
        this.edit=tedit
        return true
    }
}

/******************
 * RzTextPen
 */
export class RzTextPen extends RzDrawPen {
    curSizeDlg?:RzEditSizeDlg;
    isEditing=false;
    isPressed=false;
    curPath?:RzoNotePath;

        attrChanged(){
            if(rzIs.t)rzlog.debug("attrChanged: !!")
            if(this.curSizeDlg){
                let fsz=this.fontSize
                
                rzlog.debug("attrChanged : fontSz=",fsz)
                fsz=this.note.toViewSize(fsz)
                this.curSizeDlg.setFont(this.font,fsz,this.color);
            }
        }

        isBox(path:RzoNotePath, np:RzPos){
            let r=true;
            if(path.type!=='text') return false;

            if(np.x < path.x!) r=false;
            if(np.x > path.x! + path.w!) r=false;
            if(np.y < path.y!) r=false;
            if(np.y > path.y! + path.h!) r=false;

            return r;
        }


        doShowEditDlg(note:RzNote , vp:RzPos,path?:RzoNotePath){
            let fsz=note.toViewPos(0,0,100,100)
            let vpos={...vp,w:fsz.width,h:fsz.height};
            let bgcolor=''
            let text=undefined;
            
            let curPen=note.getPencil()
            let curAttr=note.getPenAttr()

            let vfont_size= note.toViewSize(this.fontSize)
            let vfont=this.font;
            let vcolor=this.color;
            if(curPen && curAttr){
                vfont_size=note.toViewSize(curAttr.fontSize)
                vfont=curAttr.font
                vcolor=curAttr.fontColor!
            }

            if(path){
                let pp=note.toViewPos(path.x!,path.y!,path.w,path.h)

                vpos={x:pp.x!, y:pp.y!, w:pp.width, h:pp.height};
                if(isDbgUi) bgcolor='background:green;';
                
                if( path.attr?.fontSize) vfont_size= note.toViewSize(path.attr?.fontSize);
                if( path.attr?.font)vfont=path.attr?.font;
                if(path.attr?.color)vcolor=path.attr?.color;
                text=path.text!;
            }
            rzlog.debug('Text.fontSize: fontSz=',
                vfont_size,',vfont=',vfont,
                ',vclr=',vcolor, ',path=',path)

            let tdlg=new RzEditSizeDlg()
            tdlg.setParent(note as RzUiLet)
            tdlg.setFont(vfont,vfont_size,vcolor);

            tdlg.onResize=(e)=>{
                let r=tdlg.getBounds()
                if(rzIs.d)rzlog.debug('onResize=',r)
                tdlg?.edit?.setBounds(0,0,r.width-2,r.height)
            }

     
            const isDbg=true;

            tdlg.onMouseDown=(e:MouseEvent)=>{
                (tdlg as any).isPressed=true;
                let tx= e.clientX
                let ty= e.clientY

                let p=tdlg.getBounds()

                if( tx >= p.x +p.width - 16  &&  tx <= p.x +p.width  
                    &&
                    ty >= p.y +p.height - 16  &&  ty <= p.y +p.height){

                    (tdlg as any).isResizing=true;
                    if(rzIs.d)rzlog.debug("TextEditDlg.mouseDown: ON tx=",tx,',ty=',ty,',p=',p);
                    return;
                }

                if(rzIs.d)rzlog.debug("TextEditDlg.mouseDown: OFF tx=",tx,',ty=',ty,',p=',p);

                let tp=tdlg.parent!.getBounds()
                if(rzIs.d)rzlog.debug("TextEdit.down P=",p)
                
                const p2={x:p.x-tp.x, y:p.y-tp.y}
                let pos = {x0:p2.x, y0:p2.y,clientX:e.clientX,clientY:e.clientY};
                (tdlg as any).pos=pos;
                e.stopPropagation();
            }

            tdlg.onMouseMove=(e:MouseEvent)=>{
                if(!(tdlg as any).isPressed) return;
                if((tdlg as any).isResizing==true) return;

                (tdlg as any).isMove=true;
                
                let pos=(tdlg as any).pos;
                if(rzIs.d)rzlog.debug("TextEdit.Dlg.onMouseMove: e=",e,',pos=',pos)
                if(!pos) return;
                e.stopPropagation();
                
                let dx= e.clientX- pos.clientX
                let dy= e.clientY- pos.clientY
                
                let tx= pos.x0+dx
                let ty= pos.y0+dy
                if(rzIs.d)rzlog.debug("move Edit: tx=",tx,',ty=',ty,',dx=',dx,',dy=',dy);

                tdlg.setPos(tx,ty)
            }
    
            tdlg.onMouseUp=(e:MouseEvent)=>{
                (tdlg as any).isPressed=false;
                (tdlg as any).isResizing=false;

                if((tdlg as any).isMove) {
                    e.stopPropagation();
                    (tdlg as any).isMove=false;
                }
            }
            
            let ds=8
            let tx=vpos.x + ((path)?ds:0);
            let ty=vpos.y + ((path)?ds:0);

        
            tdlg.init({  isDraggable:true, isClosable:false,
                    cssText:`cursor:grab; ${bgcolor}
                        position:absolute; z-index:${LAYER_EDIT}; 
                        display:flex; flex-direction:row;
                        left:${tx}px;top:${ty}px;
                        ${isDbg?'border:3px dashed black;':
                            'border:1px dashed lightgray;'}`,
            })
            tdlg.edit!.nativeDiv!.style.width=vpos.w+'px';
            tdlg.edit!.nativeDiv!.style.height=vpos.h+'px';
            //tdlg.edit!.nativeDiv!.style.fontSize=20+'px';
            
            if(text) tdlg.setText(text);
            return tdlg;
        }

        async penDown(note:RzNote,e:MouseEvent){
            let vp=note.getPos(e)
            this.isPressed=true;

            let np=note.toNotePos(vp.x,vp.y)
            let r=await note.getPathAt(np.x,np.y,2,2);

            let path=null
            if(IsOk(r) ){
                path=r.data!
                if(path==null) return;

                if(rzIs.d)rzlog.debug("Text.path=",path);
                if(path.type==='text' && !this.curSizeDlg){
                    this.curPath=path
                    let dlg=this.doShowEditDlg(note,vp,path);
                    this.curPath.drawable =false
                    this.curSizeDlg=dlg;
                    this.isEditing=true
                    //note.setPencil({fontSize:path.attr?.fontSize, font:path.attr?.font, })
                    note.repaint();
                    return ;
                }
            }

 
            if(this.curSizeDlg){
                let dlg=this.curSizeDlg
                if(rzIs.d)rzlog.debug('drawing down :!!! dlg=',dlg)
                
                let editText=(this.curSizeDlg?.edit?.nativeDiv as HTMLInputElement)?.value
                this.curSizeDlg=undefined
                
                /******************************/
                let p2=dlg.getBounds()
                let vp2=note.transCanvasPos(p2)
                let np3=note.toNotePos(vp2.x,vp2.y,vp2.width,vp2.height) //
                if(true ||rzIs.d) 
                    rzlog.debug(`DrawText :  p2=`,p2,',xoff=',note.offx,'vp2=',vp2,',np3=',np3,',color=',this.color);

                if(editText && editText!==''){
                    let fsize=note.toNoteSize(this.fontSize)
                    let newPath={drawable:true,type:'text', 
                            x:np3.x,y:np3.y, w:np3.width,h:np3.height, 
                            text:editText, 
                            attr:{  width:this.width,
                                    color:this.color,
                                    font:this.font,
                                    fontSize:fsize, }}
                    if(rzIs.d)rzlog.debug('drawing text :!tp=',newPath,editText)

                    //@ let t_dx=16;
                    //@ let t_dy=-2;
                    let t_dx=0;
                    let t_dy=0;
                    if(!this.isEditing){
                        newPath.x+=t_dx;
                        newPath.y+=t_dy;
                        note.drawPen(note.ctx, newPath)

                        
                        note.addDrawPath(newPath);
                    } else {
                        this.curPath!.text=editText;
                        this.curPath!.isSkip =false;
                        this.curPath!.drawable =true;

                        //@let tp= note.transCanvasPos(dlg.getBounds())
                        let tp=np3;
                        this.curPath!.x=tp.x+t_dx;
                        this.curPath!.y=tp.y+t_dy;
                        this.curPath!.w=tp.width;
                        this.curPath!.h=tp.height;

                        note.putDrawPath(this.curPath!);
                        this.curPath=undefined;
                        this.isEditing=false;
                    }
                }//if
                
                dlg.setVisible(false)
                note.removeChild(dlg as RzUiLet)

            } else {
                let tdlg =this.doShowEditDlg(note,vp)
                this.curSizeDlg=tdlg
            }

        if(rzIs.d)rzlog.debug('shape.down:vp=',vp)
    }

    penEnd(note:RzNote){
        if(this.curPath && this.curPath.id){
            this.curPath.drawable=true;
        }
        
        if(!this.curSizeDlg) return


        this.curSizeDlg.setVisible(false)
        note.removeChild(this.curSizeDlg as RzUiLet)
        this.curSizeDlg=undefined
    }
}//class

 
/**
 *  S1xLecture
 */

import { rzlog, IsFail, RzDlg, RzRes, RzUiLet, RzReq, rzDlgShow, NewFail } from "../../inc";
import { FtClassMngRepoEnum, FtClassRepoEnum, FtClassRepoEvent, FtClassRepoEventEnum, FtClassRepoMng, FtClassRepoMngFac } from '../../repo/ftclass.repo-mng';
import {
    OPT_FT_LAYOUT, OPT_FT_REPO_MNG, FT_LAYOUT2_FRAME, FT_LAYOUT2_FLOAT, FT_LAYOUT2_MOBILE, FT_LAYOUT2_JOIN,
    FT_LAYOUT2_MOBILE_JOIN, FT_LAYOUT2_DEFAULT, FT_LAYOUT2_FLOAT2, FT_LAYOUT2_PORTRAIT, FT_LAYOUT2_PORTRAIT_FLOAT, FT_LAYOUT2_PORTRAIT_FLOAT2
} from "../../consts";
import { FtLayout, FtLayoutOpts, FtUiLets } from "../ftclass/ftclass.ui.layout";
import { FtClassEvent, FtClassEventEnum } from "../../ftclass.event";
import { FtClassClientFac } from "../../ftclass.client.fac";
import { FtClassClient } from "../../ftclass.client";
import { FtoClassInfo, FtoClassNoti, FtoClassNotiEnum, FtoDevTypeEnum, FtoEditLock, FtoNoteInfo } from "../../dto/ftclass.dto";
import { FtClassMirrorDlg } from "../ftclass/ftclass.ui.tool.mirror";
import { FtClassClientLogDlg } from "../ftclass/ftclass.ui.tool.client";
import { FtClassApiLogDlg } from "../ftclass/ftclass.ui.tool.api-log";
import { FtClassChat } from "../ftclass/ftclass.ui.chat";

import { FtMainFrame } from "../wiz2/ftframe.main.ui";
import { FtPortFrame } from "../wiz2/ftframe.port.ui";
import { FtJoinFrame } from "../wiz2/ftframe.join.ui";
import { FtFloatFrame } from "../wiz2/ftframe.float.ui";
import { Component, ReactNode, createContext, createRef } from "react";

import { FtClassCtx, FtUiContext } from "./ftui.context";
import { FtFloat2Frame } from "../wiz2/ftframe.float2.ui";
import { RzBasProp } from "../wiz2/rzcomp";
import { FtNotesRepo } from "../../repo/ftclass.repo.bas";
import { cmToFtxNotes } from "../../repo/ftclass2.repo.default";
import { FtxNote } from "../../dto/ftclass2.dto";
import { FtGetConnInfo } from "../../../config/consts";
import { FtPortFloatFrame } from "../wiz2/ftframe.port.float.ui";
import { FtPortFloat2Frame } from "../wiz2/ftframe.port.float2.ui";
import { AsyncLocalStorage } from "async_hooks";
import { CONF_STORED_CLASS_ID, CONF_STORED_USERINFO, CONF_STORED_USERINFO_AT } from "../../../config2";
import { WavleMediaClient } from "../../../wavle-media-client";


/*******************/
const rzIs = rzlog.makeDefs()
export const FtClassUi_setDbg = rzIs.setDbg

/*******************
 * 
 */

let isDefLayoutBySession = true;

export function FtClassUi_setSessionLayoutOn(b: boolean) {
    isDefLayoutBySession = b;
}

/******* 
 * 
 */
export class FtClassConnCmdEnum {
    static CONN_ON = 'conn.on'
    static CONN_OFF = 'conn.off'
    static MIRROR_ON = 'mirror.on'
    static MIRROR_OFF = 'mirror.off'
    static SEND = 'send'
    static RECV = 'recv'
}

export class FtClientCmdEnum {
    static CONN_ON = 'conn.on'
    static CONN_OFF = 'conn.off'

    static MIRROR_ON = 'mirror.on'
    static MIRROR_OFF = 'mirror.off'

    static PAGES_PUT = 'pages.pu'
    static CLASS_INFO = 'classInfo'
}

export class FtClientDlgEnum {
    static MIRROR = 'mirror'
    static CLIENT = 'client'
    static API_LOG = 'apiLog'
}

/*********************************
 *  FtClass2
 ********************************/

//const FtClassCtx = createContext(FtUiContext);

interface FtClass2Prop extends RzBasProp {
    context: FtUiContext;
    layoutType?: string;
    wavleClient?: WavleMediaClient;

}

interface FtClass2Stat {
    layoutType?: string;


}

/********************** */
export class FtClass2 extends Component<FtClass2Prop, FtClass2Stat>  {
    sessions:string[]=[];
    classJoined:boolean=false;

    _cilentLogDlg?: RzDlg;
    clientFac?: FtClassClientFac;
    ftClient?: FtClassClient;
    initOpts?: any;


    constructor(pr: any) {
        super(pr);
        this.state = { }
    }



    componentDidMount(): void {
        if (this.props.layoutType !== this.state.layoutType) this.setState({ layoutType: this.props.layoutType });
       //@ window.addEventListener('resize', (e) => this.doResize(e));
  
    }

    doResize(e: any) {
        let a=1;
        
    }

    componentDidUpdate() {

        if (this.props.layoutType !== this.state.layoutType) this.setState({ layoutType: this.props.layoutType });
    }

    boundaryRef = createRef<HTMLDivElement>();

    render(): ReactNode {
        let ctx = this.props.context;
        let ty = this.state.layoutType;
        if (!ty) ty = ctx.layoutType;
        
        rzlog.debug("render : ty==", ty, ' ctx.layoutTy=', ctx.layoutType);
        return (<FtClassCtx.Provider value={ctx}>
            <div id={this.props.id} style={{ width: '100%', height: '100%', backgroundColor: 'gray' }} ref={this.boundaryRef}>
                {(ty == FT_LAYOUT2_FRAME) && (<FtMainFrame wavleClient={this.props.wavleClient}/>)}
                {(ty == FT_LAYOUT2_PORTRAIT) && (<FtPortFrame boundaryRef={this.boundaryRef} wavleClient={this.props.wavleClient} />)}
                {(ty == FT_LAYOUT2_FLOAT) && (<FtFloatFrame wavleClient={this.props.wavleClient} />)}
                {(ty == FT_LAYOUT2_PORTRAIT_FLOAT) && (<FtPortFloatFrame wavleClient={this.props.wavleClient} boundaryRef={this.boundaryRef} />)}
                {(ty == FT_LAYOUT2_FLOAT2) && (<FtFloat2Frame wavleClient={this.props.wavleClient} boundaryRef={this.boundaryRef}/>)}
                {(ty == FT_LAYOUT2_PORTRAIT_FLOAT2) && (<FtPortFloat2Frame wavleClient={this.props.wavleClient} boundaryRef={this.boundaryRef} />)}
                {(ty !== FT_LAYOUT2_FLOAT && ty !== FT_LAYOUT2_FRAME && ty !== FT_LAYOUT2_FLOAT2 
                    && ty !== FT_LAYOUT2_PORTRAIT && ty !== FT_LAYOUT2_PORTRAIT_FLOAT && ty !== FT_LAYOUT2_PORTRAIT_FLOAT2) && (<FtJoinFrame />)}
            </div>
        </FtClassCtx.Provider>);
    }


    async setLayoutType(lt: string) {
        //let ctx = this.props.context;        
        let ctx = this.getCtx();
        rzlog.debug("setLayoutType.layoutType(class.beg)=", lt, ',ctx=', ctx);
        let repoMng = this.getRepoMng();
        let notes = await repoMng?.getNoteInfos()
        let dt = notes?.data;
        let curNoteId:any;
        if (dt && dt.length > 0 ) {
            curNoteId = dt[0].noteId;
        }        

        ctx.setGlobalCtx({ ...ctx, layoutType: lt, curNoteId:curNoteId });
        this.setState({ layoutType: lt });
    }

    async setInitLayoutType(lt: string) {
        //let ctx = this.props.context;        
        let ctx = this.getCtx();
        rzlog.debug("setLayoutType.layoutType(class.beg)=", lt, ',ctx=', ctx);
        let repoMng = this.getRepoMng();
        let notes = await repoMng?.getNoteInfos()
        let dt = notes?.data;
        let curNoteId:any;
        if (dt && dt.length > 0 ) {
            curNoteId = dt[0].noteId;
        }        

        let sett = ctx.setting;
        if (lt === FT_LAYOUT2_PORTRAIT) {
            sett = {...ctx.setting, isChatOff:true};
        }
        let isSkipJoin = true;
        if (lt === FT_LAYOUT2_JOIN) {
            isSkipJoin = false;
        }

        let att = await repoMng?.getAttendants();
        let mySes = await repoMng?.getMySession();
        let sess = await repoMng?.getMySessions();
        let devType = mySes?.devType;
        let mySesId = mySes?.id;

        //현재 태블릿으로 접속되어 있고 동시에 PC로 접속해 있는 경우, 
        //현재 PC로 접속되어 있고 동시에 Mobile로 접속해 있는 경우,
        //현재 태블릿으로 접속되어 있고 동시에 Mobile로 접속해 있는 경우
        let TP = false;
        let PT = false;//현재 PC로 접속되어 있고 동시에 태블릿으로 접속해 있는 경우
        
        if (sess) {
            let odSes = sess.filter(el => el.id !== mySesId).map(el => el.devType);
            if (devType === 'tablet' && odSes.includes('desktop')) {
                console.log('현재 태블릿으로 접속되어 있고 동시에 PC로 접속해 있는 경우')
                TP = true;
                lt = FT_LAYOUT2_FLOAT2;
            }//현재 태블릿으로 접속되어 있고 동시에 PC로 접속해 있는 경우

            if (devType === 'tablet' && odSes.includes('mobile')) {
                console.log('현재 태블릿으로 접속되어 있고 동시에 Mobile로 접속해 있는 경우')
                TP = true;
                lt = FT_LAYOUT2_FLOAT2;
            }//현재 태블릿으로 접속되어 있고 동시에 Mobile로 접속해 있는 경우

            if (devType === 'desktop' && odSes.includes('tablet')) {
                console.log('현재 PC로 접속되어 있고 동시에 태블릿으로 접속해 있는 경우')
                PT = true;
                lt = FT_LAYOUT2_FLOAT;
            }//현재 PC로 접속되어 있고 동시에 태블릿으로 접속해 있는 경우

            if (devType === 'desktop' && odSes.includes('mobile')) {
                console.log('현재 PC로 접속되어 있고 동시에 Mobile로 접속해 있는 경우')
                TP = true;
                lt = FT_LAYOUT2_FLOAT2;
            }//현재 PC로 접속되어 있고 동시에 Mobile로 접속해 있는 경우

                
            console.log('ses',odSes);
        }
        console.log('ses', sess, mySes);

        ctx.setGlobalCtx({ ...ctx, camCount:att?.length||1, setting:sett, layoutType: lt, curNoteId:curNoteId, isLoad:true, isSkipJoin:isSkipJoin, 
            TP, PT, });
        this.setState({ layoutType: lt });
    }

    setContextAttr(pr: any) {
        let ctx = this.props.context;
        ctx.setGlobalCtx({ ...ctx, ...pr });
    }

    /**************
     * 
     */

    doBindEvent() {
        let repoMng = this.getRepoMng();
        if (!repoMng) return;

        if (rzIs.d) rzlog.debug('FtClassLet.setClassRepoMng : repoMng=', repoMng);
        repoMng.addOnClassEvent((e, pr) => { this.onClassEvent(e, pr); }, this);
        repoMng.addOnClassRepo((e, pr) => { this.onClassRepoEvent(e, pr); }, this);
    }


    notiSessionAddAck(sesId: string, targetId: string) {
        let repoMng = this.getRepoMng();
        if (!repoMng) return;

        let classId=repoMng.getClassId();
        repoMng.notiClassEvent({ cmd: FtClassEventEnum.SESSION_ADD_ACK, classId:classId, session: { id: sesId, targetSesId: targetId } });
    }

    notiClassBegAck(sesId: string) {
        let repoMng = this.getRepoMng();
        if (!repoMng) return;

        let classId=repoMng.getClassId();
        repoMng.notiClassEvent({ cmd: FtClassEventEnum.CLASS_BEG_ACK,classId:classId, sesId:sesId});
    }

    /*********************
     *  Extra Events
     */

    async onClassEvent(e: FtClassEvent, _this?: any) {
        

        //if(rzIs.d)
        rzlog.info('FtClass2.onClassEvent: e=', JSON.stringify(e), ',isClassBeg', e.cmd === FtClassEventEnum.CLASS_BEG);

        let repoMng = this.getRepoMng();
        if (!repoMng) { alert('no repoMng'); return; }

        rzlog.info('FtClass2.onClassEvent.2: e=', JSON.stringify(e), ',isClassBeg', e.cmd === FtClassEventEnum.CLASS_BEG);
        if (e.cmd === FtClassEventEnum.CONN_ON) {
            //FtClassLet.setConnStat(true)
        } else if (e.cmd === FtClassEventEnum.CONN_OFF) {
        } else if (e.cmd === FtClassEventEnum.PAGES_PUT) {
            let pages=e.pages;
            if(!pages) return;
            repoMng.notiClassEvent({ cmd: FtClassEventEnum.PAGE_PUT,pages:pages });  
        } else if (e.cmd === FtClassEventEnum.CLASS_BEG) {
            if( this.classJoined){
                return;
            }


            rzlog.info('FtClass2.onClassEvent.3: e=', JSON.stringify(e), ',isClassBeg', e.cmd === FtClassEventEnum.CLASS_BEG);
            const ui = repoMng.getUserInfo();
            this.classJoined=true;
            //this.notiClassBegAck(ui?.sesId);
            
            //alert('ClassBeg:'+JSON.stringify(ui));
            let ctx=this.getCtx();            

            rzlog.info('FtClass2.class.beg : ui=', ui, ',rpMng=', repoMng,',ctx.devType=',ctx.deviceType);
            
            let isIpad = () => {
                const userAgent = navigator.userAgent
                if (/Macintosh/.test(userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1) {
                  return true; 
                }       
                return /iPad/.test(userAgent);
             }
       
             let isTablet = () => {
                const userAgent = navigator.userAgent.toLowerCase();                
                const isGenericTablet = /(tablet|ipad|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
                         
                return isGenericTablet || isIpad();
            }
            let ly = FT_LAYOUT2_FRAME;
            if(ctx?.deviceType==='mobile' && !isTablet()) {
                ly = FT_LAYOUT2_PORTRAIT_FLOAT;                                           
             } else {
                ly = FT_LAYOUT2_FRAME;
                if (isTablet()) {
                //    ly = FT_LAYOUT2_PORTRAIT;
                }                
            }
            this.setInitLayoutType(ly);
            // if(ctx?.deviceType =='mobile' ) {
            //     //CHANGE THIS TO FT_LAYOUT2_MOBILE
            //     this.setLayoutType(FT_LAYOUT2_PORTRAIT_FLOAT);
            // }    else  this.setLayoutType(FT_LAYOUT2_FRAME);

        } else if (e.cmd === FtClassEventEnum.SESSION_ADD) {                        

            if(! e.session?.id)  {
                alert('SESSION_ADD : no sessionId');
                return;
            }

            if( this.sessions.includes(e.session.id)){
                return;
            } 


            const ui = repoMng.getUserInfo();
            rzlog.info('FtClass2.Session.Add : ui=', ui, ',e.ses=', e.session, ',rpMng=', repoMng);

            //alert('new session'+JSON.stringify(e.session));

            if (!isDefLayoutBySession) return;
            if (ui?.sesId === undefined) return;

            if (ui?.sesId === e.session?.id) {
                //alert('2 attend: same user');
                rzlog.debug('FtClass2.Session.Add  : skip mysession');
                // return;
            } else if (e.session?.username === ui?.username) {
                //alert('2 attend: same user');
                rzlog.debug("other session  joined");
            }

            
            this.sessions.push(e.session.id);
            //this.notiSessionAddAck(ui.sesId, e.session.id);


            let tr = await repoMng.getClassInfo();
            
            
            rzlog.debug("FtClass2.onClassEvent : SESSION_ADD - atts=",tr.data?.attendants);            
            //alert('attend2 - classInfo.attend:clsId='+tr.data?.classId+", atts="+JSON.stringify(tr.data?.attendants));
            let sesCnt=0;
            console.log('attz1 ', tr.data?.attendants)
            if (tr.data?.attendants && tr.data.attendants.length >= 2) {
                sesCnt=tr.data.attendants.length;
                console.log('attz ', tr.data.attendants)
                let vs = tr.data.attendants.filter(u => u.isOnline);
                if (vs.length >= 2) {

                    // alert('attend3 -'+JSON.stringify(tr.data?.attendants));
                    let ctx = this.getCtx();

                    let cnt = tr.data.attendants.length;
                    let attz=tr.data.attendants.map((t)=>{ 
                        return {username:t.username}
                    });
                    
                    let mySes = await repoMng?.getMySession();
                    let sess = await repoMng?.getMySessions();
                    let devType = mySes?.devType;
                    let mySesId = mySes?.id;

                    //현재 태블릿으로 접속되어 있고 동시에 PC로 접속해 있는 경우, 
                    //현재 PC로 접속되어 있고 동시에 Mobile로 접속해 있는 경우,
                    //현재 태블릿으로 접속되어 있고 동시에 Mobile로 접속해 있는 경우
                    let TP = false;
                    let PT = false;//현재 PC로 접속되어 있고 동시에 태블릿으로 접속해 있는 경우
                    let lt = ctx.layoutType;
                    if (sess) {
                        let odSes = sess.filter(el => el.id !== mySesId).map(el => el.devType);
                        if (devType === 'tablet' && odSes.includes('desktop')) {
                            console.log('현재 태블릿으로 접속되어 있고 동시에 PC로 접속해 있는 경우')
                            TP = true;
                            lt = FT_LAYOUT2_FLOAT2;
                        }//현재 태블릿으로 접속되어 있고 동시에 PC로 접속해 있는 경우

                        if (devType === 'tablet' && odSes.includes('mobile')) {
                            console.log('현재 태블릿으로 접속되어 있고 동시에 Mobile로 접속해 있는 경우')
                            TP = true;
                            lt = FT_LAYOUT2_FLOAT2;
                        }//현재 태블릿으로 접속되어 있고 동시에 Mobile로 접속해 있는 경우

                        if (devType === 'desktop' && odSes.includes('tablet')) {
                            console.log('현재 PC로 접속되어 있고 동시에 태블릿으로 접속해 있는 경우')
                            PT = true;
                            lt = FT_LAYOUT2_FLOAT;
                        }//현재 PC로 접속되어 있고 동시에 태블릿으로 접속해 있는 경우

                        if (devType === 'desktop' && odSes.includes('mobile')) {
                            console.log('현재 PC로 접속되어 있고 동시에 Mobile로 접속해 있는 경우')
                            TP = true;
                            lt = FT_LAYOUT2_FLOAT2;
                        }//현재 PC로 접속되어 있고 동시에 Mobile로 접속해 있는 경우

                            
                        console.log('ses',odSes);
                    }
                    ctx.setGlobalCtx({ ...ctx, camCount: cnt, attendants:attz, layoutType:lt, TP, PT});
                }
                
            }

            rzlog.debug('FtClass2.onClassEvent : SESSION_ADD - clz.sesCnt=',sesCnt,' devType=', e.session?.devType);
            // if(e.session?.devType===FtoDevTypeEnum.MOBILE) this.setLayoutType(FT_LAYOUT2_MOBILE);
            // else if(e.session?.devType===FtoDevTypeEnum.PC) this.setLayoutType(FT_LAYOUT2_FRAME);

        } else if (e.cmd === FtClassEventEnum.SESSION_DEL) {
            const ui = repoMng.getUserInfo();
            rzlog.info('FtClass2.Session.Del:ui=', ui, ',e.ses=', e.session);

            if (!isDefLayoutBySession) return;
            if (e.session?.username !== ui?.username) return;

            // if (e.session?.devType === FtoDevTypeEnum.MOBILE) this.setLayoutType(FT_LAYOUT2_MOBILE_JOIN);
            // else if (e.session?.devType === FtoDevTypeEnum.PC) this.setLayoutType(FT_LAYOUT2_JOIN);
            let ctx = this.getCtx();
            let mySes = await repoMng?.getMySession();
            let sess = await repoMng?.getMySessions();
            let devType = mySes?.devType;
            let mySesId = mySes?.id;
            
            let TP = false;
            let PT = false;
            let lt = ctx.layoutType;
            console.log('sess del', sess, devType)
            if (sess) {
                if (sess.length > 1) return;

                let odSes = sess.filter(el => el.id !== mySesId).map(el => el.devType);
                if (devType !== 'mobile') {                    
                    lt = FT_LAYOUT2_FRAME;
                }
                console.log('ses',odSes);
            }
            ctx.setGlobalCtx({ ...ctx, layoutType:lt, TP, PT});


        } else if (e.cmd === FtClassEventEnum.REFRESH) {
            //repoMng.
            console.log('onClassEvent === > ', 'REFRESH');

            //let ctx = this.getCtx();
            //repoMng?.notesRepo?.updateContext(ctx?.curNote?.noteId||'', ctx);

            this.forceUpdate();
        } else if (e.cmd === FtClassEventEnum.SETTING_ETC) {
            // alert('calss2.ui.tsx FtClassEventEnum.SETTING_ETC)');

            // let ctx = this.getCtx();
            // let isCtrlOn = e?.etcSetting?.isPenToolShown;
            // const ui = repoMng.getUserInfo();
            // if (ui?.userType === 'teacher') return
            // ctx.setGlobalCtx({ ...ctx, isCtrlOn: isCtrlOn, isEditDisabled: true });
        } else if (e.cmd === FtClassEventEnum.NOTI_SEND) {
            const ui = repoMng.getUserInfo();
            // console.log("FtClassEventEnum.NOTI_SEND=========>", e, "ui========>", ui, "e.noti", e.noti, e.noti?.fromId)            
            
            if (e?.noti?.fromId === ui?.username) return;
            this.doProcClassNoti(e);
        }
    }

    // Sharing Request
    doProcClassNoti(e: FtClassEvent) {
        if (!e.noti) return;

        let repoMng = this.getRepoMng();

        if (e.noti.type == FtoClassNotiEnum.WRITING_SHARE_REQ) {
            let noteId = e.noti?.targetId;
            
            if (!noteId) return
            
            let ctx = this.getCtx();
            let nts = ctx.notes?.find(el => el.noteId === noteId);            

            if (nts) {
                if (nts.isFloatingOn) {
                    const ui = repoMng?.getUserInfo();
                    let rmng = this.getRepoMng();
                    if (!rmng) return;

                    let bookType = '노트';
                    if (nts.info?.type === 'book') bookType = '교재';
                    else if (nts.info?.type === 'note') bookType = '노트';

                    if (nts.info?.subType === 'answer') bookType = '답안지';

                    let m = { ...e.noti, type: FtoClassNotiEnum.WRITING_SHARE_RES, fromId: ui?.username, body: `상대방의 ${bookType}가 팝업되어있어 공유가 불가합니다.\n${bookType}를 상태창에 돌린 후 공유가 가능합니다.`, targetType: 'reject' } as FtoClassNoti;

                    rmng.sendClassNoti(m);
                    return;
                }
            }            

            rzDlgShow('공유요청',e.noti.title||'',(b)=>{                
                if (b) {
                    const ui = repoMng?.getUserInfo();
                    let rmng = this.getRepoMng();
                    if (!rmng) return;

                    let m = { ...e.noti, type: FtoClassNotiEnum.WRITING_SHARE_RES, fromId: ui?.username, title: '필기 공유를 허용합니다' } as FtoClassNoti;

                    rmng.sendClassNoti(m);
                } 
            });               

            // alert('doProcClassNoti WRITING_SHARE_REQ')
        } else if (e.noti.type == FtoClassNotiEnum.WRITING_SHARE_RES) {
            const ui = repoMng?.getUserInfo();
            //console.log("ui===============================>", ui)
            
            if (e.noti.targetType === 'reject') {
                rzDlgShow('공유요청',e.noti?.body||'',(b)=>{
                    if (b) {
                        
                    }
                }, {isCancel:false});
                return;
            }

            let rmng = this.getRepoMng();
            let noteId = e.noti.targetId;

            // isGroupWritingReadOn 상대방 필기 
            if (noteId) {
                let sh = rmng?.getNoteSharing(noteId);                
                rmng?.setNoteSharing(noteId, { ...sh, isGroupWritingReadOn: true }, {type:'local'});
            }
        } else if (e.noti.type == FtoClassNotiEnum.ANSWER_ALLOWED) {
            /** repo ---> 
             * classSharing isAnsweron ->
             * answer isReadon -> 
             * isShareOn(ctx) -> setGlobalCtx
             * 
             */

            let ctx = this.getCtx();
            let nts = ctx.notes;            
            const ui = repoMng?.getUserInfo();
            if (ui?.userType === 'observer') return;
            let rmng = this.getRepoMng();
            if (!rmng) return;
            
            let answerSheet = rmng.allNotes.filter(el => el.subType === 'answer');

            if (e.noti?.targetId) {
                answerSheet = answerSheet.filter(el => el.noteId === e.noti?.targetId);
            }            

            let cs = repoMng?.getClassSharing();
            let isAnswerOn = !cs?.isAnswerOn;
            
            answerSheet.map(async (item:FtoNoteInfo) => {
                if (item.noteId) {                    
                    let sh = rmng?.getNoteSharing(item.noteId);
                    rmng?.setNoteSharing(item.noteId, { ...sh, isGroupReadOn:isAnswerOn, isGroupWriteOn:isAnswerOn}, {type:'local'}); // isGroupWritingReadOn:true

                    nts = nts?.map(el => {
                        if (el.noteId === item.noteId) {
                            return {
                                ...el,
                                isShareOn:isAnswerOn
                            }
                        } 
                        return el;
                    })
                }
            });
            ctx.setGlobalCtx({ ...ctx, notes: nts });
                        
            // repoMng?.setClassSharing({...cs, isAnswerOn:isAnswerOn}, {isSkip:true, isAnswer:true});          
            repoMng?.setClassSharing({...cs, isAnswerOn:isAnswerOn});          

        } else if (e.noti.type == FtoClassNotiEnum.ANSWER_SHARE_REQ) {
            
            let ctx = this.getCtx();
            const ui = repoMng?.getUserInfo();
            let nts = ctx.notes?.filter(el => {
                if (el.info?.type === 'book' && el.info?.subType === 'answer' && !el.isFloatingOn) return true;
                else return false;
            });
            

            if (nts?.length === 0) {
                let rmng = this.getRepoMng();
                if (!rmng) return;
                let m = { ...e.noti, fromId: ui?.username, type: FtoClassNotiEnum.ANSWER_SHARE_RES, title: '답안지 공유', body: `답안지가 팝업되어있어 공유가 불가합니다.\n답안지를 상태창에 돌린 후 공유가 가능합니다.`, targetType: 'reject' } as FtoClassNoti;
                rmng.sendClassNoti(m);
            } else {
                rzDlgShow(e.noti.title||'',e.noti.body||'',(b)=>{
                    if (b) {                                            
                        let rmng = this.getRepoMng();
                        if (!rmng) return;
                        //const ui = repoMng?.getUserInfo();
                        let m = { ...e.noti, type: FtoClassNotiEnum.ANSWER_SHARE_RES, title: '답안지 공유를 허용합니다', targetId: nts?.length === 1 ? nts[0].noteId : undefined } as FtoClassNoti;
                        rmng.sendClassNoti(m);            
                    } 
                });            
            }
        } else if (e.noti.type == FtoClassNotiEnum.ANSWER_SHARE_RES) {            
            let ctx = this.getCtx();
            let nts = ctx.notes;            
            const ui = repoMng?.getUserInfo();
            
            if (ui?.userType === 'observer') return;

            if (e.noti?.targetType === 'reject') {
                rzDlgShow(e.noti?.title||'',e.noti?.body||'',(b)=>{
                    if (b) {
                        
                    }
                }, {isCancel:false});
                return;
            }

            let rmng = this.getRepoMng();
            if (!rmng) return;
            let answerSheet = rmng.allNotes.filter(el => el.subType === 'answer');

            if (e.noti?.targetId) {
                answerSheet = answerSheet.filter(el => el.noteId === e.noti?.targetId);
            }

            answerSheet.forEach(async (item:FtoNoteInfo) => {
                if (item.noteId) {                    
                    let sh = rmng?.getNoteSharing(item.noteId);
                    rmng?.setNoteSharing(item.noteId, { ...sh, isGroupReadOn:true, isGroupWriteOn:true}); // isGroupWritingReadOn:true

                    nts = nts?.map(el => {
                        if (el.noteId === item.noteId) {
                            return {
                                ...el,
                                isShareOn:true
                            }
                        } 
                        return el;
                    })
                }
            });
            ctx.setGlobalCtx({ ...ctx, notes: nts });            


            let cs = repoMng?.getClassSharing();            
            // repoMng?.setClassSharing({...cs, isAnswerOn:true}, {isSkip:true, isAnswer:true});
            repoMng?.setClassSharing({...cs, isAnswerOn:true});
        } else if (e.noti.type == FtoClassNotiEnum.NOTE_SHARE_REQ) {            
            let ctx = this.getCtx();
            let nts = ctx.notes;            
            const ui = repoMng?.getUserInfo();
            
            if (ui?.userType === 'observer') return;

            let rmng = this.getRepoMng();
            if (!rmng) return;
            let answerSheet = rmng.allNotes.filter(el => el.noteId === e.noti?.targetId);
            answerSheet.map(async (item:FtoNoteInfo) => {
                if (item.noteId) {                    
                    let sh = rmng?.getNoteSharing(item.noteId);
                    rmng?.setNoteSharing(item.noteId, { ...sh, isGroupReadOn:true, isGroupWriteOn:true}); // isGroupWritingReadOn:true

                    nts = nts?.map(el => {
                        if (el.noteId === item.noteId) {
                            return {
                                ...el,
                                isShareOn:true
                            }
                        } 
                        return el;
                    })
                }
            });
            ctx.setGlobalCtx({ ...ctx, notes: nts });
            
            let m = { ...e.noti, type: FtoClassNotiEnum.NOTE_SHARE_RES, fromId: ui?.username, title: '노트 공유', body: '노트 공유' } as FtoClassNoti;
            rmng.sendClassNoti(m);
            
        } else if (e.noti.type == FtoClassNotiEnum.NOTE_SHARE_CANCEL_REQ) {            
            let ctx = this.getCtx();
            let nts = ctx.notes;            
            const ui = repoMng?.getUserInfo();
            
            if (ui?.userType === 'observer') return;

            let rmng = this.getRepoMng();
            if (!rmng) return;
            let answerSheet = rmng.allNotes.filter(el => el.noteId === e.noti?.targetId);
            answerSheet.forEach(async (item:FtoNoteInfo) => {
                if (item.noteId) {                    
                    let sh = rmng?.getNoteSharing(item.noteId);
                    rmng?.setNoteSharing(item.noteId, { ...sh, isGroupReadOn:false, isGroupWriteOn:false}, {isSkip:false}); // isGroupWritingReadOn:true

                    nts = nts?.map(el => {
                        if (el.noteId === item.noteId) {
                            return {
                                ...el,
                                isShareOn:false
                            }
                        } 
                        return el;
                    })
                }
            });
            ctx.setGlobalCtx({ ...ctx, notes: nts });
            
            let m = { ...e.noti, type: FtoClassNotiEnum.NOTE_SHARE_CANCEL_RES, fromId: ui?.username, title: '노트 공유', body: '노트 공유 취소' } as FtoClassNoti;
            rmng.sendClassNoti(m);
        } else if (e.noti.type == FtoClassNotiEnum.NOTE_SHARE_RES) {
            let ctx = this.getCtx();
            let nts = ctx.notes;            
            const ui = repoMng?.getUserInfo();
            
            if (ui?.userType === 'observer') return;

            let rmng = this.getRepoMng();
            if (!rmng) return;
            let answerSheet = rmng.allNotes.filter(el => el.noteId === e.noti?.targetId);
            answerSheet.forEach(async (item:FtoNoteInfo) => {
                if (item.noteId) {
                    nts = nts?.map(el => {
                        if (el.noteId === item.noteId) {
                            return {
                                ...el,
                                isShareOn:true
                            }
                        } 
                        return el;
                    })
                }
            });            
            ctx.setGlobalCtx({ ...ctx, notes: nts });
        } else if (e.noti.type == FtoClassNotiEnum.NOTE_SHARE_CANCEL_RES) {
            let ctx = this.getCtx();
            let nts = ctx.notes;            
            const ui = repoMng?.getUserInfo();
            
            if (ui?.userType === 'observer') return;

            let rmng = this.getRepoMng();
            if (!rmng) return;
            let answerSheet = rmng.allNotes.filter(el => el.noteId === e.noti?.targetId);
            answerSheet.forEach(async (item:FtoNoteInfo) => {
                if (item.noteId) {
                    nts = nts?.map(el => {
                        if (el.noteId === item.noteId) {
                            return {
                                ...el,
                                isShareOn:false
                            }
                        } 
                        return el;
                    })
                }
            });            

            ctx.setGlobalCtx({ ...ctx, notes: nts });
        } else if (e.noti.type == FtoClassNotiEnum.MIRROR_MINE_REQ) {            
            rzDlgShow(e.noti.title||'',e.noti.body||'',(b)=>{                
                if (b) {                    
                    const ui = repoMng?.getUserInfo();
                    let rmng = this.getRepoMng();
                    if (!rmng) return;
        
                    let m = { ...e.noti, type: FtoClassNotiEnum.MIRROR_MINE_RES, fromId: ui?.username, title: '미러링을 허용합니다' } as FtoClassNoti;
                    rmng.sendClassNoti(m);
                } 
            });  


            // alert('doProcClassNoti WRITING_SHARE_REQ')
        } else if (e.noti.type == FtoClassNotiEnum.MIRROR_MINE_RES) {
            let rmng = this.getRepoMng();
            if (!rmng) return;

            let ctx = this.getCtx();
            let ui = ctx.userInfo;

            let el: FtoEditLock = {
                    userType: ui?.userType,
                    isOwnerLock: ui?.userType === 'student' ? true : false,
                    isOtherLock: ui?.userType === 'student' ? false : true,
            };
            rmng.setEditLock(el);
            // this.setState({ isMyEditOn: true });

        } else if (e.noti.type == FtoClassNotiEnum.MIRROR_OTHER_REQ) {
            const ui = repoMng?.getUserInfo();
            let rmng = this.getRepoMng();
            if (!rmng) return;

            let m = { ...e.noti, type: FtoClassNotiEnum.MIRROR_OTHER_RES, fromId: ui?.username, title: '미러링을 허용합니다' } as FtoClassNoti;
            rmng.sendClassNoti(m);

            // alert('doProcClassNoti WRITING_SHARE_REQ')
        } else if (e.noti.type == FtoClassNotiEnum.MIRROR_OTHER_RES) {
            let rmng = this.getRepoMng();
            if (!rmng) return;

            let ctx = this.getCtx();
            let ui = ctx.userInfo;
            let el: FtoEditLock = {
                userType: ui?.userType,
                isOwnerLock: ui?.userType === 'student' ? false : true,
                isOtherLock: ui?.userType === 'student' ? true : false,
            };
            rmng.setEditLock(el);
            // this.setState({ isOtherEditOn: true });
        } else if (e.noti.type == FtoClassNotiEnum.OVBSERVER_JOIN_REQ) {
            const ui = repoMng?.getUserInfo();

            //alert(ui?.uid);

            let rmng = this.getRepoMng();
            if (!rmng) return;

            let m = { ...e.noti, type: FtoClassNotiEnum.OVBSERVER_JOIN_RES, title: '참관수업 허용합니다.' } as FtoClassNoti;
            rmng.sendClassNoti(m);
        } else if (e.noti.type == FtoClassNotiEnum.OVBSERVER_JOIN_RES) {
            
            const ui = repoMng?.getUserInfo();
            let ctx = this.getCtx();
            if (ui?.userType !== 'observer') {
                ctx.setGlobalCtx({ ...ctx, isParentJoinOn:true} as FtUiContext);
                return;
            }
            ctx.setGlobalCtx({ ...ctx, layoutType: FT_LAYOUT2_FRAME ,isEditDisabled:true, isParentJoinOn:true} as FtUiContext);
        } else if (e.noti.type == FtoClassNotiEnum.PARENT_JOIN_OUT_REQ) {            
            let rmng = this.getRepoMng();
            
            if (!rmng) return;
            let m = { ...e.noti, type: FtoClassNotiEnum.PARENT_JOIN_OUT_RES, title: '참관수업 내보냅니다.' } as FtoClassNoti;
            rmng.sendClassNoti(m);
            
        } else if (e.noti.type == FtoClassNotiEnum.PARENT_JOIN_OUT_RES) {
            let rmng = this.getRepoMng();
            
            if (!rmng) return;

            let ctx = this.getCtx();
            const ui = repoMng?.getUserInfo();
            
            if (ui?.userType !== 'observer') {
                ctx.setGlobalCtx({ ...ctx, isParentJoinOn:false} as FtUiContext);
            } else {
                rmng.leaveClass();
                rmng.close();
                ctx.setGlobalCtx({ ...ctx, layoutType: FT_LAYOUT2_JOIN, userInfo: {} });
            }

        } else if (e.noti.type == FtoClassNotiEnum.OVBSERVER_JOIN_CANCEL_REQ) {
            const ui = repoMng?.getUserInfo();

            let rmng = this.getRepoMng();
            if (!rmng) return;

            let m = { ...e.noti, type: FtoClassNotiEnum.OVBSERVER_JOIN_CANCEL_RES, fromId: ui?.username, title: '학부모 참관수업 요청', body:'참관수업 거절합니다.' } as FtoClassNoti;
            rmng.sendClassNoti(m);
        } else if (e.noti.type == FtoClassNotiEnum.OVBSERVER_JOIN_CANCEL_RES) {
            // 참관수업 거절 이후 학부모 ....
            const ui = repoMng?.getUserInfo();
        } else if (e.noti.type == FtoClassNotiEnum.NOTE_WRITE_STOP_REQ) {
            const ui = repoMng?.getUserInfo();
            if (ui?.userType !== 'student') return;
            
            let ctx = this.getCtx();            
            
            let rmng = this.getRepoMng();
            if (!rmng) return;            

            let notes = rmng.allNotes;
            notes.forEach(item => {
                if (item.noteId) {
                    let sh = rmng?.getNoteSharing(item.noteId);
                    rmng?.setNoteSharing(item.noteId, { ...sh, isWriteOn:false}, {type:'local'});
                }
            });            

            //isCtrlOn pentool, isEditDisabled nullpen
            ctx.setGlobalCtx({ ...ctx, isCtrlOn: false, isEditDisabled: true, isWriteAllow: false });
        } else if (e.noti.type == FtoClassNotiEnum.NOTE_WRITE_ALLOW_REQ) {
            const ui = repoMng?.getUserInfo();
            if (ui?.userType !== 'student') return;
            
            let ctx = this.getCtx();            
            
            let rmng = this.getRepoMng();
            if (!rmng) return;            
            
            let notes = rmng.allNotes;
            notes.forEach(item => {
                if (item.noteId) {
                    let sh = rmng?.getNoteSharing(item.noteId);
                    rmng?.setNoteSharing(item.noteId, { ...sh, isWriteOn:true}, {type:'local'});
                }
            });            
            
            //isCtrlOn pentool, isEditDisabled nullpen
            ctx.setGlobalCtx({ ...ctx, isCtrlOn: true, isEditDisabled: false, isWriteAllow: true });
        } else if (e.noti.type == FtoClassNotiEnum.WRITING_TEACHER_SHOW_REQ) {
            const ui = repoMng?.getUserInfo();
            if (ui?.userType !== 'student') return;     
            
            let rmng = this.getRepoMng();
            if (!rmng) return;
            
            let notes = rmng.allNotes;
            notes.forEach(item => {
                if (item.noteId) {
                    let sh = rmng?.getNoteSharing(item.noteId);
                    rmng?.setNoteSharing(item.noteId, { ...sh, isWritingReadOn:true}, {type:'local'});
                }
            });
        } else if (e.noti.type == FtoClassNotiEnum.WRITING_TEACHER_HIDE_REQ) {
            const ui = repoMng?.getUserInfo();
            if (ui?.userType !== 'student') return;     
            
            let rmng = this.getRepoMng();
            if (!rmng) return;
            
            let notes = rmng.allNotes;
            notes.forEach(item => {
                if (item.noteId) {
                    let sh = rmng?.getNoteSharing(item.noteId);
                    rmng?.setNoteSharing(item.noteId, { ...sh, isWritingReadOn:false}, {type:'local'});
                }
            });
        }
    }

    /********* */
    onClassRepoEvent(e: FtClassRepoEvent, pr?: any) {
        rzlog.debug('FtClass2.onClassRepoEvent :e=', e)


        if (e.cmd === FtClassRepoEventEnum.SESSION_START) {


            if (!isDefLayoutBySession) return;

            let sess = e.load!.mySessions;
            let myses = e.load!.mySession;
            let vs = sess.filter(it => (it.id !== myses!.id && it.username === myses!.username))
            rzlog.debug("FtClass2.FtClassRepoMng.loadClassInfo.doUpdateSessions: my=", myses
                , "sess=", sess, ',filter=', vs)
            if (vs.length === 0) return;


        } else if (e.cmd === FtClassRepoEventEnum.SESSION_END) {


        } else if (e.cmd === FtClassRepoEventEnum.LAYOUT) {
            if (e.layout) {
                console.log("onClassRepoEvent======>", e)
                let layoutType = cmToLayoutType(e.layout);
                let ctx = this.getCtx();
                ctx.setGlobalCtx({ ...ctx, layoutType: layoutType });
            }

        } else if (e.cmd === FtClassRepoEventEnum.REPO_LOAD) {
            if (rzIs.i) rzlog.info('FtClass2.FtClassLet.onClassRepoEvent: REPO_LOAD > doBindSubRepos > pr=', pr, ',repoMng=', pr.repoMng)
  
                this.doBindNewNotes();
            
        } else if (e.cmd === FtClassRepoEventEnum.REPO_LOAD_DONE) {
            if (rzIs.i) rzlog.info('FtClass2.FtClassLet.onClassRepoEvent: REPO_LOAD > doBindSubRepos > pr=', pr, ',repoMng=', pr.repoMng)
            let ctx = this.getCtx();
            ctx.setGlobalCtx({ ...ctx, isRepoLoaded: true});
            //@ 
      } else if (e.cmd === FtClassRepoEventEnum.REPO_CLOSE) {
            if (rzIs.i) rzlog.info('FtClass2.FtClassLet.onClassEvent: REPO_CLOSE > doBindSubRepos > pr=', pr);
            //ftClassLet.clearUi()
        }
    }//method

    getCtx() {
        return this.props.context;
    }


    async doBindNewNotes() {
        let repoMng = this.getRepoMng();
        if (!repoMng) return;

        //let ui=repoMng?.getUserInfo()
        //if(ui) ftClassLet.setUserInfo(ui)
        let ctx = this.getCtx();
        let ui = ctx.userInfo;

        let r = await repoMng.getClassInfo();
        let clzInf = r.data;

        ui = repoMng.getUserInfo();
        let notes = clzInf?.notes;
        let sharedNotes = clzInf?.classSharing?.noteSharings;
        if (notes) {
            let ftxNotes = cmToFtxNotes(notes, ui, sharedNotes);
            rzlog.debug("FtClass2.REPO_LOAD : ftxNotes = ", notes);
            let ctx = this.getCtx();

            let vs = ftxNotes.filter(t => t?.isSelected == true);
            let note: FtxNote | null = null;
            if (vs.length > 0) {
                note = ftxNotes[0];
                note.isSelected = true;
            } else {
                note = vs[0];
            }
            

            //@ctx.setGlobalCtx({ ...ctx, notes: ftxNotes, curNote: note });
            ctx.setGlobalCtx({ ...ctx, notes: ftxNotes, curNoteId: note?.noteId });
        }
    }

    /****************
     * Exports Function
     */
    isEventMounted = false;
    setEventPaused = (b: boolean) => {
        let repoMng = this.getRepoMng();
        if (!repoMng) { alert('FtClass2.setEventPaused: no repoMng'); return; }
        repoMng.setEventPaused(b);
    };

    setLoginInfo = (userId: string, accToken: string, userType: string, devType: string,classId:string,loginMode?:string) => {
            
        rzlog.debug("FtCalss2 >SetLoginInfo for :userId=", userId);
        let repoMng = this.getRepoMng();
        if (!repoMng) { alert('setLoginInfo:no repoMng'); return; }

        repoMng.setLoginInfo(userId, accToken, userType, devType,loginMode);
        
        
       
        repoMng.setOnRepaint(() => {
            let ctx = this.getCtx();
            ctx.setGlobalCtx({ ...ctx});
        })
        
        let ctx=this.getCtx();
        ctx.setGlobalCtx({...ctx,classId:classId,
             userInfo:{username:userId, userType:userType,devType:devType,mode:loginMode}});       
        rzlog.debug("setLogin. GLBCTX=",ctx);
    };


    isRepoMngConnected = () => {
        let repoMng = this.getRepoMng();
        return Boolean(repoMng?.isConnected());
    };

    joinClass = async (classId: string):Promise<RzRes<FtoClassInfo>> => {

        rzlog.info('FtClass2.joinClass : classid=', classId, ',isCon=', this.isRepoMngConnected());
        let repoMng = this.getRepoMng();

        if (!repoMng) {
            alert('joinClass: no repoMng');
            return NewFail('joinClass: no repoMng');
        }

        if (!this.isEventMounted) {
            this.isEventMounted = true;
            this.doBindEvent();
        }

        
        // repoMng.loginInfo();
        let ctx = this.getCtx();
        let connInfo = FtGetConnInfo();//ctx.conf?.connInfo;

        repoMng.setClassId(classId);
        repoMng.setConnInfo(connInfo);
        let r = await repoMng!.load({ isConn: true });
        if (IsFail(r)) {
            alert("FtClass2.repoMng : can't load " + r.message);
            return NewFail('cant load RepoMng'+r.message);
        }

        let rci = await repoMng.getClassInfo(true);
        let ui = repoMng.getUserInfo();
        
        rzlog.info('FtClass2.joinClass(fetchClass) : classInfo=', rci.data);
        if (ui) {
            
            let ctx = this.getCtx();
            let ci = rci.data;

            let notes=ci?.notes;
            let curNoteId:string|null=null;
            
            if(notes && notes.length>0){
                let vs= notes.filter(t=>t.selected==true);
                if(vs.length>0){
                    curNoteId=vs[0].noteId||null;
                }else {
                    if(notes.length > 0)
                        curNoteId=notes[0].noteId||null;
                }
            }

            
            //notes?.length>0?notes[0].noteId:'';

            ctx.setGlobalCtx({
                ...ctx, 
                deviceType: ui.devType,
                classId: classId, 
                userInfo: ui,
                sharing: ci?.classSharing,
                curNoteId:curNoteId||undefined,
            });
                        
            //alert('bind ui='+JSON.stringify(ui));
        }

        let chatRepo = repoMng.getChatRepo();

        // if(this.uiLets!.chat) (this.uiLets!.chat as FtClassChat).setRepo(chatRepo!);
        return rci as RzRes<FtoClassInfo>;
    };

    getRepoMng() {

        let rmng = this.props.context.repoMng;//?.getRepoMng();
        //@ alert('FtCalss2.getRepoMng : repo='+this.props.context.repo+",rp.name="+this.props.context.repo?.getName())
        return rmng;
    }


    mirrorRepoMng: FtClassRepoMng | null = null;
    async getMirrorRepoMng() {
        // if(!this.mirrorRepoMng){
        //     let fac=new FtClassRepoMngFac()
        //     this.mirrorRepoMng= await fac.newRepoMng(FtClassMngRepoEnum.MIRROR);
        // }
        //let repo=FtClassRepoMngFac.Init

        let rmng = this.props.context.repo?.getRepoMng();
        return rmng;
    }

    hasStoredInfo() {
        //stored by RepoMng 
        let ci= sessionStorage.getItem(CONF_STORED_USERINFO);
        if(!ci) return false;
        return true;
    }

    clearStoreInfo(){
        //stored by RepoMng 
        sessionStorage.removeItem(CONF_STORED_USERINFO);
        sessionStorage.removeItem(CONF_STORED_USERINFO_AT);
        sessionStorage.removeItem(CONF_STORED_CLASS_ID);
    }


    getStoredClassId() {
        let ci= localStorage.getItem(CONF_STORED_CLASS_ID);
        return ci;
    }


    leaveClass = async () => {
        let repoMng = this.getRepoMng();
        if (!repoMng) { alert('FtClass2.leaveClass:no repoMng'); return; }


        let clsId = repoMng.getClassId();
        rzlog.info('FtClass2..leaveClass : classid=', clsId);
        let r = await repoMng.leaveClass();
        if (IsFail(r)) return r;

        let r2 = await repoMng.close();
        return r2;
    };


    setCurPage = (pg: number, type: string) => {
        let repoMng = this.getRepoMng();
        if (!repoMng) { alert('FtClass2.leaveClass:no repoMng'); return; }

        repoMng.putCurPageNoByType(pg, type)
    };

    movePage = async (pgOff: number, type: string) => {
        let repoMng = this.getRepoMng();
        if (!repoMng) { alert('FtClass2.movePage:no repoMng'); return; }


        let r = await repoMng.getCurPageNoByType(type) as RzRes<number>;
        let pg = r.data!;
        if (pg + pgOff >= 0) repoMng.putCurPageNoByType(pg + pgOff, type);
    };

    /**** */
    fetchClientCmd = async (cmd: string, opt?: any) => {

        if (cmd === FtClientCmdEnum.CONN_ON) {
            let tclient = this.doGetExtClient();
            tclient.connect();
        } else if (cmd === FtClientCmdEnum.CONN_OFF) {
            let tclient = this.doGetExtClient();
            tclient.close();
        } else if (cmd === FtClientCmdEnum.PAGES_PUT) {
            //tclient.reqPagesPut(opt)
        } else if (cmd === FtClientCmdEnum.MIRROR_ON) {

        } else if (cmd === FtClientCmdEnum.CLASS_INFO) {
            let repoMng = this.getRepoMng();
            if (repoMng) {
                // let r=await repoMng.getClassInfo();
                // alert('DD= '+ JSON.stringify(r.data?.attendants));

                let r = await repoMng.putClassInfo("1", { classId: "1", stat: "open" } as FtoClassInfo);
                alert('R=' + JSON.stringify(r));
            }

        } else if (cmd === FtClientCmdEnum.MIRROR_OFF) {
        }
    };

    showClientToolDlg = (toolId: string, isOn: boolean) => {
        if (this.props.id === undefined) return;

        let targetDiv = document.getElementById(this.props.id) as any;

        let nId = '_' + toolId
        //rzlog.debug('showClientLogDlg:  Dlg !!!: curDlg=',this._clientLogDlg)
        if (rzIs.d) rzlog.debug('showClientLogDlg:  Dlg !!!: isOn=', isOn, ', targetDiv.curDlg=', targetDiv[nId])
        if (targetDiv[nId]) {
            targetDiv[nId].setVisible(isOn)
            return
        }

        let dlg: RzDlg | null = null

        //let repoMng=this.getRepoMng()
        //if(!repoMng)return;

        if (toolId === FtClientDlgEnum.CLIENT) {
            dlg = new FtClassClientLogDlg() as RzDlg
        }
        else if (toolId === FtClientDlgEnum.MIRROR) {
            let tdlg = new FtClassMirrorDlg()
            dlg = tdlg as RzDlg;

        } else if (toolId === FtClientDlgEnum.API_LOG) {
            let tdlg = new FtClassApiLogDlg();
            // tdlg.setRmtRepoMnt(repoMng);
            dlg = (tdlg as any) as RzDlg;
        }
        if (!dlg) return;

        dlg!.init({ parentDiv: targetDiv })
        // this._clientLogDlg=dlg
        targetDiv[nId] = dlg
        if (rzIs.d) rzlog.debug('showClientLogDlg:new Dlg :', dlg)
        if (rzIs.d) rzlog.debug('showClientLogDlg:new Dlg.targetDiv:', targetDiv)

        if (!isOn) dlg!.setVisible(false)

    }

    /*******************
     * 
     */

    doGetExtClient() {
        if (!this.ftClient) {
            this.ftClient = this.clientFac!.newClient(this.initOpts);
  
        }
        return this.ftClient;
    }
}//class





export const cmToLayoutType = (opt?: FtLayoutOpts) => {
    alert('cmTolayoutType: opt=' + JSON.stringify(opt));
    if (opt?.layoutType === '') {
        return FT_LAYOUT2_FLOAT;
    } else if (opt?.layoutType === '') {
        return FT_LAYOUT2_FLOAT;
    }

    return FT_LAYOUT2_DEFAULT;
}
/**
 *  rzbook.ui
 */

 import {rzlog } from "./inc"
 import {RzUiLet, RzUiOpts} from './rzcmn.ui'

import { RzNote } from "./rznote.ui"
import './rzbook.ui.css'
import { RzNoteEvent, RzNoteRepo } from "./rznote.ui.repo"


/*********************************/
 
const rzIs=rzlog.makeDefs()
export const RzBook_setDbg=rzIs.setDbg

/*********************************
 *  RzBook
 ********************************/
export class RzBook extends RzNote {

    imageDiv=null

    doPreOpts(opt:RzUiOpts){
        let cssText1=''+((opt.cssText)? opt.cssText:'')  + ((rzIs.d)?'border:solid; border-color:yellow;':'')
        let nopt=super.doPreOpts(opt)
        return {...nopt,className:opt.className ||'rzbook', cssText:cssText1}
    }

    doInit(opts:RzUiOpts){
        super.doInit(opts)
        if(rzIs.d)rzlog.debug('RzBook.doInit:opt=',opts,',this.navDiv=',this.nativeDiv)

        if(this.nativeDiv) this.nativeDiv.innerHTML='<i class="ic-no-image"></i>'
       
        return true
    }

    setRepo(repo:RzNoteRepo){
        repo.delOnNoteEvent(this.onNoteEvent,this)
        repo.addOnNoteEvent(this.onNoteEvent,this);
        
        this.noteRepo=repo
        this.repaint()
    }

    // onBookEvent=(e:any)=>{
    //     rzlog.debug('onBookEvent :e=',e)
    // }
    onNoteEvent=async (e:RzNoteEvent,pr?:any)=>{
        rzlog.debug('onNoteEvent:e=',e)
    }

}//class


export class RzEditBook  extends RzUiLet {
    noteLet?:RzNote
    bookLet?:RzBook
    bookRepo?:RzNoteRepo
    isNoteOn=true
    constructor(opt:any){
        super(opt)
        this.isNoteOn= true
        if(opt?.isNoteOn===false) this.isNoteOn=false
        if(rzIs.d)rzlog.debug('RzEditBook:new:opt=',opt,',isNoteOn=',this.isNoteOn)
    }   
 

    doPreOpts(opt:RzUiOpts){
         let cssTextAttr={ cssText:'position:relative; '}//cssText:'display:'
        //@if(opt.cssTextNew)cssTextAttr={cssText:opt.cssText}
        
        return {...opt,className:'rzEditBook', ...cssTextAttr }
    }
    //
    doInit(opt:RzUiOpts){
        if(rzIs.d)rzlog.debug('RzEditBook:doInit:opt=',opt,',isNoteOn=',this.isNoteOn)
        let isInnerHtml=false
        if(isInnerHtml){
            let imgSrc='https://icar.inje.ac.kr/_attach/inje/editor-viewer//out//_attach/old/icar/105/32_631bd941db1cdd62673bc3d20908e2c8.pdf.files/00001.png'
            let imgCss=` max-width: 100%;max-height: 100%; height: auto;`
            if(this.nativeDiv) this.nativeDiv.innerHTML=`<div style="position:relative; left:0px; top:0px; width:100%; height:100%; z-index:1">
                    <img src="${imgSrc}" style="${imgCss}" /> 
                </div>
                <div style="position:relative; cursor:crosshair; left:0px;   top:-100%; width:100%; height:100%;  z-index:2;">
                    <canvas style="position:relative; left:0px;  top:0; border:solid; border-color:green;" id='mycanvas'></canvas>
                </div>`
            return true
        }

        const fragment = document.createDocumentFragment();
        let p=new RzUiLet()
        p.nativeNode=fragment
        
        //if(opt?.cssText) p.nativeDiv.style.cssText=opt.cssText
        //===========        
        this.bookLet=new RzBook()
        this.bookLet.setParent(p)
        this.bookLet.init({className:'rzbook_main',
                cssText:`position:relative; left:0px; top:0px; width:100%; height:100%; z-index:1`
        })


 
        //===========      
        if(this.isNoteOn){
            this.noteLet=new RzNote({noteType:'main'})
            this.noteLet.setParent(p)

  
            this.noteLet.init({className:'rzbook_note',
                //cssText:`cursor:crosshair; left:0px; top:0px; width:100%;height:100%; zIndex:2`
                //@cssText:`position:relative; cursor:crosshair; left:0px;  top:-100%; width:100%; height:100%; z-index:2;`
                cssText:`position:relative; cursor:crosshair; left:0px; top:0px; width:100%; height:100%; z-index:2;`
            })
       }  

       this.nativeDiv!.appendChild(fragment)

       if(this.noteLet){
           let r0= this.nativeDiv!.getBoundingClientRect()
           //this.noteLet.onResize({width:r0.width,height:r0.height})
       }

       return true
    }

    async setRepo(repo:RzNoteRepo){
        this.bookRepo=repo
        if(this.bookLet) this.bookLet.setRepo(repo)
    }

}//class


/***************** */


import { rzlog } from "./inc"
import { RzSwipeEvent, RzZoomEvent } from "./rzcmn.ui";
import { RzNote } from "./rznote.ui"
/********************** */
const rzIs=rzlog.makeDefs();
export const RzNoteUiEvent_setDbg=rzIs.setDbg;

/********************** */
export function BindNativeEvents(uiLet:RzNote,isDbg:boolean){

    uiLet.nativeDiv!.onresize=(e:any)=>{
        rzlog.debug('note resize : e=',e);
    };

    uiLet.nativeDiv!.addEventListener('paste',(e:any)=>{
        if(isDbg) rzlog.debug('paste : ');

        // const clipboardItems = e.clipboardData.items;
        // const items = [].slice.call(clipboardItems).filter(function (item) {
        //     // Filter the image items only
        //     return item.type.indexOf('image') !== -1;
        // });
        // if (items.length === 0) {
        //     return;
        // }

        // const item = items[0];
        // // Get the blob of image
        // const blob = item.getAsFile();

        // const imageEle = document.getElementById('preview');
        // imageEle.src = URL.createObjectURL(blob);
    });
    uiLet.nativeDiv!.addEventListener('touchstart',(e:any)=>{
        if(isDbg) rzlog.debug('nt.navDiv.touchstart:equal=',(e.target===uiLet.canvas),',e=',e);
       
        if(e.target!==uiLet.canvas) return;
        e.preventDefault();

        (document as any)['_touchStarted']=uiLet;

        
        if(e.touches.length===1){
            if(isDbg) rzlog.debug('touchstart.mouseDown');
            e.clientX=e.touches[0].clientX;
            e.clientY=e.touches[0].clientY;
            
            e.isTouch=true;
            e.touches[0].isTouch=true;
            uiLet.doMouseDown(e.touches[0]);    
        }else if(e.touches.length===2){
            uiLet.doTouchStart(e);

        }
        
    },false);
    uiLet.nativeDiv!.addEventListener('touchmove',(e:any)=>{
        if(isDbg)  rzlog.debug('cmn.navDiv.touchmove:e=',e,',equal=',(e.target===uiLet.nativeDiv));
        
        //e={altKey:false,bubbles:true, pageX:434,pageY;698,layerX:302,layerY:582,scale:2.x,touches:{0,touch,1:touch, len:2,item:func}}
        if(e.target!==uiLet.canvas) return;
        e.preventDefault();
        if(uiLet.isTouchStart) {
            uiLet.doTouchMove(e);
            return;
        }

        if(e.touches.length===1 ){
            if(isDbg) rzlog.debug('touchmove.mouseMove');
            e.clientX=e.touches[0].clientX;
            e.clientY=e.touches[0].clientY;
            e.isTouch=true;
            e.touches[0].isTouch=true;
            uiLet.doMouseMove(e.touches[0])    ;
        }
        

      //  uiLet.doMouseMove(e)
    },false)

    uiLet.onSwipe=(e:RzSwipeEvent)=>{
        if(isDbg)rzlog.debug("RzNoteUi.Event.onSwipe : e=",e);
        uiLet.movePageNext(e.isNext);
    }
    uiLet.onZoom=(e:RzZoomEvent)=>{
        rzlog.debug("Zoom:e=",e);
        uiLet.setZoomIn(e.isMagified);
    }

    uiLet.nativeDiv!.addEventListener('touchend',(e:any)=>{
        (document as any)['_touchStarted']=undefined;

        if(isDbg) rzlog.debug('rznotui.event.touchend:e=',e, 
                    ',isSkip=',(e.target!==uiLet.canvas),',touch.len=',e.touches.length);
        if(e.target!==uiLet.canvas) return;

        if(e.touches.length==0){

            uiLet.doMouseUp(e);
            uiLet.doClearTouch();
        }else  if(e.touches.length===1 && !uiLet.isTouchStart){
            uiLet.doMouseUp(e);
            uiLet.doClearTouch();
        } else if(uiLet.isTouchStart){
            e.preventDefault();
            uiLet.doTouchEnd(e);
           
        }
        
    // uiLet.doMouseUp(e)
    },false);
    
    uiLet.nativeDiv!.addEventListener('touchcancel',(e:any)=>{
        if(isDbg) rzlog.debug('touchcancel:e=',e);
        if(e.target!==uiLet.canvas) return;
        e.preventDefault();
        
        if(e.touches.length===1){
            e.touches[0].isTouch=true;
            e.clientX=e.touches[0].clientX;
            e.clientY=e.touches[0].clientY;
            uiLet.doMouseOut(e.touches[0]);

        } else if(e.touches.length===2){
            uiLet.doTouchEnd(e,true);
            
        }
    // uiLet.doMouseOut(e)
    },false);

}
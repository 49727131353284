import {rzlog } from "./inc"
import './rznote.ui.css'
import {RzRect, RzSizeDlg,} from './rzcmn.ui'
import { RzoNotePath } from "./rznote.ui.dto"
import { RZNOTE_PEN_MEMO_COLOR_DOT, RZNOTE_PEN_MEMO_DOT_OFF, RZNOTE_PEN_MEMO_DOT_SIZE } from "./rznote.ui.edit.memo"
 
/************************ 
 * 
 */

const rzIs=rzlog.makeDefs()
export const RzNoteUiDraw_setDbg=rzIs.setDbg

let isDbgUi= false; 
export function RzNoteUiDraw_setDbgUi(b:boolean){
    isDbgUi=b;
}

//메모공유수정
const isMemoOwnerValid= false
/************************** */

export interface RzNoteView {
    toNotePos(x:number,y:number, w?:number, h?:number):RzRect;
    toViewPos(x?:number,y?:number, w?:number, h?:number):RzRect;
    toViewSize(sz?:number):number;
    toNoteSize(sz?:number):number;
    getDrawerId():string|undefined;
    getOwnerId():string|undefined;
}

/************************ 
 * 
 */

/************************ */
export class RzDrawRender {
    note:RzNoteView;
    constructor(nt:RzNoteView){
        this.note=nt;
    }


    draw(ctx:any, p:RzoNotePath, pr:any) {

    }
    ctxz:any[]=[]
    pushCtx(ctx:any){
        this.ctxz.push({globalAlpha:ctx.globalAlpha})
    }
    popCtx(ctx:any){
        if(this.ctxz.length==0) return;

        const v=this.ctxz.pop();
        ctx.globalAlpha=v.globalAlpha;
    }

    bindPenAttrs(ctx:any,p:RzoNotePath,pr?:any){
        return RzDrawRender.BindPenAttrs (this.note,ctx,p,pr);
    }
    static  BindPenAttrs(note:RzNoteView, ctx:any,p:RzoNotePath,pr?:any){

        if(p.attr?.opacity !== undefined && p.attr.opacity>=0) {
            if(rzIs.d)rzlog.debug("RzDrawRender : p.addr.opacity= ",p.attr.opacity);
            ctx.globalAlpha = p.attr.opacity;
        }
        else   ctx.globalAlpha=1;
   
        if(p.attr?.color){
            ctx.strokeStyle=p.attr.color;
            ctx.fillStyle = p.attr.color;
            if(pr?.focused){
                ctx.strokeStyle='red';
                ctx.fillStyle = 'red';
            }
        }
        if(p.attr  && p.attr.width && p.attr.width>=0){
            ctx.lineCap='round';
            ctx.lineWidth=note.toViewSize(p.attr.width);
        }

        let fheight= note.toViewSize(4);
        let font='serif';

        if(p.attr?.font) font=p.attr.font;
        if(p.attr?.fontSize) fheight=note.toViewSize(p.attr.fontSize);
        //@else if(p.attr) p.attr.fontSize= fheight

        ctx.font = `${fheight}px ${font}`;
    }
}//class

export class RzDrawMoveRender extends RzDrawRender {
    draw(ctx:any,p:RzoNotePath,pr:any){
        rzlog.debug('drawPencil.Move : p=',p,',ga=',ctx.globalAlpha);
        if(p.isEnd) {  ctx.closePath();     ctx.stroke(); }
        else if(p.isBegin)  ctx.beginPath();  
    }
}

export class RzDrawPenRender extends RzDrawRender {
    defDs=7
    draw(ctx:any,p:RzoNotePath,pr:any){

       if(rzIs.d) rzlog.debug('drawPencil.Line :p=',p,',ga=',ctx.globalAlpha);
       
 
        if(p.points){
             
            this.bindPenAttrs(ctx,p,pr);
            ctx.beginPath();

            for(let i in p.points){
                let np=p.points[i];

                let ds=0;
                if(ctx.lineWidth)ds=ctx.lineWidth/2;
                
    
                let tp0=this.note.toViewPos(np.x0,np.y0);
                let tp=this.note.toViewPos(np.x,np.y);
                //if(p.attr!.width! <=3 ) 
                ds=this.defDs;
                if(rzIs.t) rzlog.debug("DS !!! : ds=",ds,',w=',p.attr?.width,',x0=',tp0.x,',y0=',tp0.y)    ;            

                ctx.moveTo(tp0.x-ds,tp0.y-ds);
                ctx.lineTo(tp.x-ds,tp.y-ds);
            }
            ctx.closePath();
            ctx.stroke();
            
        } else {
            // drawing directly
            this.bindPenAttrs(ctx,p,pr);
           

            let ds=0;
            if(ctx.lineWidth)ds=ctx.lineWidth/2;
            
            console.log(p);
            if(rzIs.d) rzlog.debug("test : ds=",ds,',w=',p.attr?.width,',px0=',p.x0,',py0=',p.y0, 'px=',p.x,',py=',p.y);

            let tp0=this.note.toViewPos(p.x0!,p.y0!);
            let tp=this.note.toViewPos(p.x!,p.y!);

            if(rzIs.t) rzlog.debug("Dx.DS !!! : ds=",ds,',w=',p.attr?.width,',x0=',tp0.x,',y0=',tp0.y);

            //if(p.attr!.width! <=3 ) 
            ds=this.defDs;
            
            //ctx.globalCompositeOperation = "lighter";
            //ctx.globalAlpha = 0.3;
            //ctx.strokeStyle = "rgba(255, 0, 0, 0.5)";  // 형광펜 색상 및 투명도 설정
            //ctx.lineWidth = 10;  // 형광펜 두께 설정
            
            ctx.lineCap = "round";  // 형광펜 끝 모양 설정
            ctx.beginPath();
            ctx.moveTo(tp0.x - ds, tp0.y - ds);
            ctx.lineTo(tp.x! - ds, tp.y! - ds);
            if(rzIs.d) rzlog.debug("Dx.DS !!! : ds=",ds,',w=',p.attr?.width,',x0=',tp0.x,',y0=',tp0.y, 'x=',tp.x,',y=',tp.y);
            
            ctx.stroke();
            //ctx.globalCompositeOperation = "source-over";
            ctx.lineCap = "butt";
            ctx.closePath();
        }
    }
   
}//class

/*****
 * 
 */

export class RzShapeRender extends RzDrawRender {
    type?:string
    constructor(nt:RzNoteView, type:string){
        super(nt)
        this.type=type;
    }
    draw(ctx:any,p:RzoNotePath,pr:any){
        rzlog.debug('RzShapeRender.draw :p=',p)
        ctx.save()
        //this.pushCtx(ctx)
        this.bindPenAttrs(ctx,p,pr)
        let np=this.note.toViewPos(p.x,p.y,p.w,p.h)

        if(this.type==='rect') this.doDrawRect(ctx,np)
        else if(this.type==='hexagon') this.doDrawHexagon(ctx,np)
        else if(this.type==='triangle') this.doDrawTriangle(ctx,np)
        else if(this.type==='circle') this.doDrawCircle(ctx,np)

        //this.popCtx(ctx)
        ctx.restore()
    }
    
    doDrawRect(ctx:any,np:RzRect){
        //rect
        ctx.beginPath()
        ctx.rect(np.x,np.y,np.width,np.height)
        ctx.closePath()
        ctx.stroke()
    }

    doDrawTriangle(ctx:any,np:RzRect){
        //triangle
        ctx.beginPath();
        ctx.moveTo(np.x+np.width/2, np.y);
        ctx.lineTo(np.x+np.width, np.y+np.height);
        ctx.lineTo(np.x, np.y+np.height);
        ctx.closePath();
        ctx.stroke();
        
    }


    doDrawCircle(ctx:any,np:RzRect){
        //circle
        ctx.beginPath();
        ctx.arc(np.x+np.width/2,np.y +np.width/2, np.width/2, 0, 2 * Math.PI);
        ctx.closePath();
        ctx.stroke();
    }

    doDrawHexagon(ctx:any,np:RzRect){
        //Hexagon
        const a = 2 * Math.PI / 6;
        let r=np.width/2;
        let x=np.x+r;
        let y=np.y+r;
        ctx.beginPath();
        for (var i = 0; i < 6; i++) {
          ctx.lineTo(x + r * Math.cos(a * i), y + r * Math.sin(a * i));
        }
        ctx.closePath();
        ctx.stroke();

    }    

}//class

/**********************  */
export class RzDrawRectRender extends RzShapeRender {
    constructor(nt:RzNoteView){
        super(nt,'rect');
    }
}//class


/**********************  */ 
export class RzDrawTextRender extends RzDrawRender {
    draw(ctx:any,p:RzoNotePath,pr:any){
        if(rzIs.d) rzlog.debug('RzDrawTextRender.draw : drawText > p=',p)
        
        if(pr?.focused || isDbgUi){   
            ctx.save()
            ctx.beginPath()

            let np=this.note.toViewPos(p.x,p.y,p.w,p.h)
            ctx.rect(np.x,np.y,np.width,np.height)

            if(p.attr?.color){
                ctx.strokeStyle=p.attr.color
              //  ctx.fillStyle = p.color
            }
            if(p.attr?.width && p.attr.width>=0){
                ctx.lineWidth=p.attr.width
            }
            ctx.lineWidth=1;
            
            ctx.stroke()
            ctx.restore()
        }
        
        //this.pushCtx(ctx)
        //@
        ctx.save()
       
        this.bindPenAttrs(ctx,p,pr)
      
        ctx.beginPath()
        if( p.text && p.x && p.y){
            let np=this.note.toViewPos(p.x,p.y)
            let fheight= this.note.toViewSize(p.attr?.fontSize||10)

            let lines = p.text.split('\n');
            for (var i = 0; i<lines.length; i++){
                this.doDrawTextLine(ctx, np.x, np.y + fheight*(i+1), lines[i] )
            }
        }
        ctx.closePath()
        ctx.stroke()
       
        //@
        ctx.restore()

       // this.popCtx(ctx)
    }

    doDrawTextLine(ctx:any, x:number,y:number, text:string ){
        ctx.fillText(text, x, y);
    }
}//class


export class RzDrawMemoRender extends RzDrawRender {

    doIsOwner(p:RzoNotePath){
        if(isMemoOwnerValid &&  p.attr?.drawerId ){
            let curDrawerId=this.note.getDrawerId()
            return p.attr.drawerId  === curDrawerId 
        }

        return true
    }

    draw(ctx:any,p:RzoNotePath,pr:any){
        if(rzIs.d) rzlog.debug('RzDrawMemoRender.draw : drawMemo > p=',p)

        if(p?.isSkip) return
        if(!this.doIsOwner(p)) return;

        this.pushCtx(ctx)

        
        let vp=this.note.toViewPos(p.x,p.y,p.w,p.h)
        const isDbg=false;
        if(isDbg){
            ctx.strokeStyle='skyblue';
            ctx.fillStyle='skyblue';
            ctx.fillRect(vp.x,vp.y, vp.width, vp.height);
        }

        if(p.isOn){
            this.doDrawDot(ctx,p,pr)
            this.doDrawTextBox(ctx,p, pr)
        } else {
            this.doDrawDot(ctx,p,pr)
        }
        this.pushCtx(ctx)
    }

    doDrawDot(ctx:any,p:RzoNotePath,pr:any){
        ctx.save()
            ctx.beginPath();
            ctx.lineWidth=3 
            ctx.fillStyle = p.attr?.dotColor ||RZNOTE_PEN_MEMO_COLOR_DOT
            ctx.strokeStyle=p.attr?.dotColor ||RZNOTE_PEN_MEMO_COLOR_DOT

            let vp=this.note.toViewPos(p.x,p.y,p.w,p.h)
            const dotSize=this.note.toViewSize(RZNOTE_PEN_MEMO_DOT_SIZE)
            
            let ds=0
            let tx=vp.x!+dotSize +ds
            let ty=vp.y!+dotSize +ds
            let hdotSize=dotSize/2
            ctx.fillStyle='#C6C6C6'
            ctx.strokeStyle='#C6C6C6'
            ctx.arc(tx-hdotSize,ty-hdotSize, hdotSize, 0,  Math.PI*2);
            ctx.fill();
            //ctx.arc(tx+2,ty+2, dotSize-6, 0,  2*Math.PI);
            ctx.closePath();
            ctx.stroke();
     
        
        
            ctx.globalAlpha=1.0;
            ctx.beginPath();
            ctx.lineWidth=1;
            ctx.fillStyle=RZNOTE_PEN_MEMO_COLOR_DOT
            ctx.strokeStyle=RZNOTE_PEN_MEMO_COLOR_DOT
            ctx.shadowBlur = 0;
            tx=vp.x! + dotSize
            ty=vp.y! + dotSize
            ctx.arc(tx-hdotSize,ty-hdotSize, hdotSize, 0, 2 * Math.PI);
            ctx.fill()
            ctx.closePath();
            ctx.stroke();

            if(isDbgUi){
                let vp=this.note.toViewPos(p.x,p.y)
                
                let varea_sz=dotSize
                ctx.fillStyle='red'
                ctx.strokeStyle='red'
                ctx.rect(vp.x,vp.y,varea_sz,varea_sz);
                ctx.stroke();
            }
            
        ctx.restore()
    }


    doDrawTextBox(ctx:any,p:RzoNotePath,pr:any){
       
        ctx.save()
            ctx.beginPath()
            if(p.attr?.boxColor){
                ctx.strokeStyle=p.attr.boxColor
                //  ctx.fillStyle = p.color
            }
            if(p.attr?.width && p.attr.width>=0){
                ctx.lineWidth=p.attr.width
            }

            let vp=this.note.toViewPos(p.x!+RZNOTE_PEN_MEMO_DOT_SIZE+4,p.y,
                p.w!- (RZNOTE_PEN_MEMO_DOT_SIZE+4),p.h)


            // const tvx=this.note.toViewSize(p.x!+RZNOTE_PEN_MEMO_DOT_SIZE+4)
            // const tw= this.note.toViewSize(p.w! - (RZNOTE_PEN_MEMO_DOT_SIZE+4)) ;
            
            const tvx=vp.x;
            const tw= vp.width;


            ctx.globalAlpha=0.8;
            ctx.fillStyle=p.attr?.boxBgColor || "#FFE5B1"
            ctx.rect(tvx,vp.y,tw,vp.height)
            ctx.fill()
            ctx.closePath()
            ctx.stroke()
        ctx.restore()

        ctx.save()
            this.bindPenAttrs(ctx,p,pr)
            ctx.globalAlpha=1.0;
            //ctx.rect(vp.x,vp.y,vp.width,vp.height)
            
            ctx.clip()
            ctx.beginPath()
                if( p.text && p.x && p.y){
                    let height= this.note.toViewSize(p.attr?.fontSize||10)

                    let lines = p.text.split('\n');
                    for (var i = 0; i<lines.length; i++){
                        this.doDrawLine(ctx, tvx, vp.y+ height*(i+1), lines[i] )
                    }
                }
            ctx.stroke()
        ctx.restore()
        
    }

    doDrawLine(ctx:any, x:number,y:number, line:string ){
        ctx.fillText(line, x, y);
    }
}


/*********************************************
 *  RzDrawRenderFac
 */

export  class RzDrawRenderFac {
    moveRender:RzDrawMoveRender;
    penRender: RzDrawPenRender;
    rectRender: RzDrawRectRender;
    textRender: RzDrawTextRender;
    memoRender: RzDrawMemoRender;
    circleRender: RzShapeRender;
    hexagonRender: RzShapeRender;
    triangleRender: RzShapeRender;
    //lineRender: RzDrawLineRender;

    constructor(noteView:RzNoteView){

        this.moveRender=new RzDrawMoveRender(noteView)
        this.penRender=new RzDrawPenRender(noteView)
        this.rectRender=new RzDrawRectRender(noteView)
        this.textRender=new RzDrawTextRender(noteView)
        this.memoRender=new RzDrawMemoRender(noteView)
        this.triangleRender=new RzShapeRender(noteView,'triangle')
        this.hexagonRender=new RzShapeRender(noteView,'hexagon')
        this.circleRender=new RzShapeRender(noteView,'circle')
    }


    getRender(type:string,opts:any){
        if(type==='move') return this.moveRender
        else if(type==='line') return this.penRender
        else if(type==='rect')return this.rectRender
        else if(type==='text')return this.textRender
        else if(type==='memo')return this.memoRender
        else if(type==='triangle')return this.triangleRender
        else if(type==='hexagon')return this.hexagonRender
        else if(type==='circle')return this.circleRender
        return null
    }
}//class



/*********************************************
 *  FtSizeDlg
 */
export class FtSizeDlg extends RzSizeDlg {

}
import { rzlog } from "./inc"
import './rznote.ui.css'

import {RzUiLet,RzDlg, RzSizeDlg, RzBtnLet, RzUiOpts} from './rzcmn.ui'
import { RzDrawNote, RzNote } from "./rznote.ui"


const rzIs=rzlog.makeDefs()
export const RzNoteUiTools_setDbg=rzIs.setDbg

 
 

/**************************************
 * 
 *************************************/
export class RzToolbarDlg extends RzDlg {
    tools:any[]=[]
    onBtnClick?:(id:string)=>void

 

    setButtons(bts:any[]){
        this.tools=bts
    }

    doPreOpts(opts:RzUiOpts){
        opts=super.doPreOpts(opts)
        return {...opts, 
                isDraggable:true,
                cssText: `position:absolute; 
                        left:${this.x}px; top:${this.y}px; 
                        width:${this.width}px; height:${this.height}px; 
                        border: 1 solid #223333; background-color:lightgray;`
        }
    }

    doInit(opts:RzUiOpts){
        if(!super.doInit(opts)) return false
        

        this.updateBtns()
        return true
    }

    updateBtns(){
        let w=40,   h=40, hgap=10,ml=10,mt=10, y=0,vgap=10,x=0


        for(let i in this.tools){
            for (let j in this.tools[i]){
                let btDesc=this.tools[i][j]
                this.addBtn(btDesc,x+mt,y+mt,w,h)
                x+=w +hgap
            }
            x=0
            y+=h +vgap 
        }
    }

    addBtn(btnDesc:any, x:number,y:number,w:number,h:number){

        let btn=new RzBtnLet({title:btnDesc.title, 
            cssText:`position:absolute;cursor:pointer;
            left:${x}px;top:${y}px; width:${w}px; height:${h}px`})

            btn.setTitle(btnDesc.title)
            btn.btnId=btnDesc.id
            btn.init()
            btn.onClick=(e)=>{
                if(this.onBtnClick)this.onBtnClick(btn.btnId || btn.id ||'')
            }
        this.addChild(btn)

    }


    removeChildAll(){
        super.removeChildAll()
        this.tools=[]
    }
}//class

/*************************** 
 * RzDragBtn
*/
export class RzDragBtn extends RzDlg{
    title:string=''
    // constructor(opts:RzUiOpts){
    //     super(opts)
    //     this.title=''
    // }

    setTitle(title:string){this.title=title}

    doPreOpts(opts:RzUiOpts){
        let topts=super.doPreOpts(opts) 
        if(topts.isDraggable) topts.isDraggable=false
        let isDbg=false;
        return {...topts,  cssText:`position:absolute;
         left:${this.x}px; top:${this.y}px; cursor:pointer;
         ${isDbg?'border: 1 solid black;':''} background-color:lightgray;
        width:${this.width}px; height:${this.height}px;`}
    }
    doInit(opts:RzUiOpts){
        if(!super.doInit(opts)) return false
        
        if(opts.onClick && this.nativeDiv) {
            this.nativeDiv.onclick=this.doClick
            this.onClick=opts.onClick
        }

        let pr = document.createTextNode("p");
         pr.textContent = this.title
        if(this.nativeDiv) this.nativeDiv.appendChild(pr)
        return true
    }

    doClick(e:Event){
        let tgt=(this as any)._srcLet
        if(rzIs.d) rzlog.debug(`doClicck, isDragged=`,tgt.isDragged)

        if(tgt.onClick && !tgt.isDragged){
           
            tgt.onClick(e)
        }
    }

}//class

export function MakeToolbarDlg(noteLet:RzNote,x:number,y:number){
 
        let btns=[
                [{id:'pen', title:'Pen'},         {id:'marker', title:'Mark'},
                {id:'rect', title:'Box'},          {id:'text', title:'Text'},
                {id:'eraser', title:'Eras'},              {id:'hide', title:'X'},
                ],[
                    {id:'black', title:'Blk'},
                    {id:'red', title:'Red'},  {id:'blue', title:'Blue'},
                    {id:'yellow', title:'Yel'},  {id:'green', title:'Gre'},
                ],[
                    {id:'2px', title:'2px'},  {id:'4px', title:'4px'},
                    {id:'6px', title:'6px'},  {id:'8px', title:'8px'},
                ]
        ]

        let togBtns=[[{id:'toggle', title:'+'}]]
        let pb=noteLet.getBounds()

        let dlg=new RzToolbarDlg({isResizable:true,})
        dlg.setParent(noteLet)
        dlg.setBounds(pb.width-80,pb.height-80,56,56)
      //  dlg.setBounds(x,y,320,56*3)
        dlg.onBtnClick=(id:string)=>{
            rzlog.debug('toolbar.id=',id)
            if(id==='toggle'){
                let b=dlg.getBounds()
                dlg.setBounds( b.x -(320-56) ,b.y-(56*3-56),320,56*3)
                dlg.removeChildAll()
                dlg.setButtons(btns)
                dlg.updateBtns()
            } else if(id==='hide'){
                let b=dlg.getBounds()
                
                dlg.setBounds(b.x+(320-56),b.y+(56*3-56),56,56)
                dlg.removeChildAll()
                dlg.setButtons(togBtns)
                dlg.updateBtns()
            }
            else {

                
                if(id==='pen'){
                    noteLet.setPencil({type:'pen'})
                } else if(id==='marker'){
                    noteLet.setPencil({type:'marker'})
                } else if(id==='rect'){
                    noteLet.setPencil({type:'rect'})  
                } else if(id==='text'){
                    noteLet.setPencil({type:'text'}) 
                } else if(id==='memo'){
                    noteLet.setPencil({type:'memo'}) 
                } else if(id==='erase'||id==='eraser'){
                    noteLet.setPencil({type:'eraser'}) 
                } else if(id==='blue'||id==='red'||id==='yellow'||id==='green'||id==='black'){
                    if(id==='blue' && noteLet.curPenAttr) noteLet.curPenAttr.color='rgb(0,0,200)'
                    else if(id==='green'&& noteLet.curPenAttr) noteLet.curPenAttr.color='rgb(0,200,0)'
                    else if(id==='red'&& noteLet.curPenAttr) noteLet.curPenAttr.color='rgb(200,0,0)'
                    else if(id==='yellow'&& noteLet.curPenAttr) noteLet.curPenAttr.color='yellow'
                    else if(id==='black'&& noteLet.curPenAttr) noteLet.curPenAttr.color='black'

                    if(noteLet.curPenAttr) noteLet.setPencil(noteLet.curPenAttr)
                } else if(id==='2px'||id==='4px'||id==='6px'||id==='8px'){
                    if(id==='2px'&& noteLet.curPenAttr)  noteLet.curPenAttr.width=2
                    else if(id==='4px'&& noteLet.curPenAttr)  noteLet.curPenAttr.width=4
                    else if(id==='6px'&& noteLet.curPenAttr)  noteLet.curPenAttr.width=6
                    else if(id==='8px'&& noteLet.curPenAttr)  noteLet.curPenAttr.width=8
                    if( noteLet.curPenAttr) noteLet.setPencil(noteLet.curPenAttr)
                } else {

                }
        }

        }
        dlg.setButtons(togBtns)

        dlg.init({style:{cursor:'grab'}})

        // dlg.removeChildAll()
        // dlg.setButtons(btns)
        // dlg.updateBtns()

        return dlg
    }


/**********************
 * RzMemoDlg
 */
 export class RzMemoDlg extends RzSizeDlg {
    memoLet?:RzUiLet

    doPreOpts(opts:RzUiOpts){
        this.isDraggable=true;
        let topts=super.doPreOpts(opts)
        if(topts.style) {
            topts.style.backgroundColor='lightgray'
            topts.style.cursor='grab'
        }
        else topts.style={backgroundColor:'lightgray', cursor:'grab'}
        return { ...topts,}
    }



    doInit(opts:RzUiOpts){
        let memoTop=40
        let rect=this.nativeDiv?.getBoundingClientRect()||{width:0,height:0}
        let th=rect.height-memoTop
        let memoNote= new RzDrawNote({noteType:'popup'})
            memoNote.isDraggable=true;
            memoNote.setParent(this as RzUiLet)
           
            memoNote.init({ 
                style:{ top:`${memoTop}px`, height:`${th}px`, backgroundColor:'blue',}
            })

           memoNote.setPencil({width:2,color:'rgb(0,0,0)'})
           //memoNote.draw()
           memoNote.repaint()

           this.memoLet=memoNote

        if(!super.doInit(opts)) return false

 
        return true
    }


    onResize=(rect:any)=>{
        //this.memoLet!.onResize(rect)
    }
}//class




 


import { FtoClassInfo, FtoNoteInfo } from "../dto/ftclass.dto"
import { FtClassInfoRepo } from "./ftclass.inf.repo"
import { FtNotesRepo } from "./ftclass.repo.bas"
import { FtClassStoreFac } from "./ftclass.repo.fac"
import { FtClassInfoStore, FtNotesRepoStore } from "./ftclass.repo.store"
import { FtNoteRepo } from "../ui/ftclass/ftnote.ui"
import { NewFail, NewOk,rzlog,RzRes, RzRsRes } from "../inc"
import { RzoNoteInfo } from "../note.ui/rznote.ui.dto"

/********************
 * 
 */
export class FtClassInfoTestStore implements FtClassInfoStore {
    async putNoteInfos(noteInfs: FtoNoteInfo[]): Promise<RzRsRes<FtoNoteInfo>> {
        throw new Error("Method not implemented.")
    }
    async putClassInfo(classId: string, infoRepo: FtoClassInfo): Promise<RzRes<FtoClassInfo>> {
        throw new Error("Method not implemented.")
    }
    
    async fetchClassInfo(classId: string, infoRepo: FtClassInfoRepo): Promise<RzRes<FtoClassInfo>> {
        //throw new Error("Method not implemented.")
        let  dt=this.defClassInfo;// {} as FtoClassInfo;
        return NewOk(dt);
    }

    async addNoteInfo(noteInf: FtoNoteInfo): Promise<RzRes<FtoNoteInfo>> {
        //throw new Error("Method not implemented.")
        return NewOk(noteInf);
    }
    async putNoteInfo(noteInf: FtoNoteInfo): Promise<RzRes<FtoNoteInfo>> {
        throw new Error("Method not implemented.")
    }
    async delNoteInfo(noteId: string, noteInf?: FtoNoteInfo | undefined): Promise<RzRes<FtoNoteInfo>> {
        throw new Error("Method not implemented.")
    }

    async init(ctx:any) :Promise<RzRes<void>> {
        return NewOk()
    }

    defClassInfo:FtoClassInfo={
        classId: '1',
        title: 'test1',
        detail:{},
       
        host: { uid:"1", username:'test01', },
               
        notes: [ 
            {noteId:'1',title:'book1',type:'note', bookId:'4', ownerId:'1',ownerName:'test01',total:4,pageNo:0, selected:true,visible:true},
            {noteId:'2',title:'note1',type:'note', ownerId:'1',ownerName:'test01',total:4,pageNo:0,visible:true,selected:true,},
            {noteId:'3',title:'note2',type:'note', ownerId:'2',ownerName:'test02',total:4,pageNo:0,visible:true}
        ],
        pages:[ 
            {noteId:'1',title:'book1',type:'note', bookId:'4', ownerId:'1',ownerName:'test01',total:4,pageNo:0, selected:true,visible:true},

        ],
        attendants:[],
         
        myInfo:     { uid:"1", username:'test01', },
        mySession:  { id:"1", subType:'main',devType:'pc',uid:"1", username:'test01' },
        mySessions:[ 
            {id:"1", subType:'main',devType:'pc',uid:"1", username:'test01'},
            {id:"2", subType:'sub',devType:'phone',uid:"1", username:'test01'} 
        ],

    };

    async load(infoRepo:FtClassInfoRepo, opt?:any){
       // if(!infoRepo.classId || infoRepo.classId==='0') return  NewFail()

        let clsInfo=this.defClassInfo;
        infoRepo.setClassInfo(clsInfo)
        rzlog.info('FtClassInfoTestStore.load : classId=',infoRepo.classId,'infoRepo=',infoRepo)

        //rzlog.debug('FtClassInfoMemRepo.load : notes=',infoRepo.notes)

        return NewOk()
    }

    async save(infoRepo:FtClassInfoRepo, opt?:any){
        return NewOk()
    }      
}//class

/********************
 * 
 */
export class  FtNotesRepoTestStore implements FtNotesRepoStore {

    async init(ctx:any) :Promise<RzRes<void>> {
        return NewOk()
    }

    async load(infoRepo:FtClassInfoRepo,repos:FtNotesRepo, opt?:any){

        let r=await infoRepo.getNoteInfos()

        let infos=r.data!
        let urls=[
            {src:'https://icar.inje.ac.kr/_attach/inje/editor-viewer//out//_attach/old/icar/105/32_631bd941db1cdd62673bc3d20908e2c8.pdf.files/00001.png'},
            {src:'https://icar.inje.ac.kr/_attach/inje/editor-viewer//out//_attach/old/icar/105/32_631bd941db1cdd62673bc3d20908e2c8.pdf.files/00002.png'},
            {src:'https://icar.inje.ac.kr/_attach/inje/editor-viewer//out//_attach/old/icar/105/32_631bd941db1cdd62673bc3d20908e2c8.pdf.files/00003.png'},
            {src:'https://icar.inje.ac.kr/_attach/inje/editor-viewer//out//_attach/old/icar/105/32_631bd941db1cdd62673bc3d20908e2c8.pdf.files/00004.png'},    ]
    
        for(let i in infos!){
            let inf=infos[i]
             
            let r1= await repos.addNote(inf.noteId!,inf)
            
            let r2=await repos.getNoteRepo(inf.noteId!)
            r2.data!.addPage(1)
            r2.data!.addPage(2)
            r2.data!.addPage(3)
            r2.data!.addPage(4)
            
            r2.data?.setAttr(1,"bg",urls[0])
            r2.data?.setAttr(2,"bg",urls[1])
            r2.data?.setAttr(3,"bg",urls[2])
            r2.data?.setAttr(4,"bg",urls[3])
            //await r1.data!.load(opt)
        }

 

        rzlog.info('FtClassReposTestStore.load : classId=',infoRepo.classId)

        return NewOk()
    }

    async save(infoRepo:FtClassInfoRepo, repos:FtNotesRepo, opt:any){
        return NewOk()
    }

    async loadNote( noteRepo:FtNoteRepo, opt:any):Promise<RzRes<void>>{
        return NewOk()
    }

    async saveNote( noteRepo:FtNoteRepo, opt:any):Promise<RzRes<void>>{
        return NewOk()
    }

}//class



/********************
 * 
 */
export class FtClassStoreTestFac implements FtClassStoreFac {

    newInfoStore(){
        return new FtClassInfoTestStore()
    }

    newNotesStore(){
        return new FtNotesRepoTestStore()
    }
}
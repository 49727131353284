import {rzlog,IsFail } from "./inc"
import './rznote.ui.css'
import { RzDlg, RzPos, RzRect, RzSizeDlg, RzUiLet, RzUiOpts} from './rzcmn.ui'
import { RzNote, RzPenAttr } from "./rznote.ui"
import { RzoNotePath } from "./rznote.ui.dto"
import { RzNoteView } from "./rznote.ui.draw"
import { RzTextPen } from "./rznote.ui.edit.text"
import { RzMemoPen } from "./rznote.ui.edit.memo"


/****************/
const rzIs=rzlog.makeDefs()
export const RzNoteUiEdit_setDbg=rzIs.setDbg
 
let isDbgUi=false;
export function RzNoteUiEdit_setDbgUi(b:boolean){
    isDbgUi=b
}
const isDbgBorder=false;
 
 /****************
 * 
 */
export class RzDrawPen {
    static OPACITY_MARKER=0.30
    static OPACITY_PEN=1.0
    type?:string //'pencil'
    width:number=1
    color:string="black";
    opacity:number=1.0
    font?:string
    fontColor:string="black";
    fontSize:number=12;
    pressed:boolean =false;
    note:RzNoteView;
    moveOffX:number=0;
    moveOffY:number=0;
  

    constructor(note:RzNoteView,type:string, pr:RzPenAttr){
        this.note=note;
        this.type=type
        this.pressed=false
        this.setAttr(pr)
    }

    // bindOwner(p:RzoNotePath){
    //     if(!this.note) return;
    //     p.ownerId=this.note.getDrawerUid();
    //     p.drawerId=this.note.getOwnerId();
    // }
    

    setAttr(attr:RzPenAttr){
      
        this.width=attr.width||1
        this.color=attr.color||"black"
        this.opacity=attr.opacity||1.0
        this.font=attr.font||"gulim"
        this.fontColor=attr.fontColor||"black"
        this.fontSize=attr.fontSize||12

        //@ADD
        this.moveOffX=attr.moveOffX||0
        this.moveOffY=attr.moveOffY||0

        this.attrChanged()
    }

    attrChanged(){}

    penInit(note:RzNote){}
    penEnd(note:RzNote){}
    penMove(note:RzNote,e:MouseEvent){ }
    penDown(note:RzNote,e:MouseEvent){ }
    penUp(note:RzNote,e:MouseEvent){ }

    saveNoteOffsets(note:RzNote){
        let offs=note.getOffset();
        this.moveOffX=offs.x;
        this.moveOffY=offs.y;
       // if(this.note) this.note.setOffset(note.getOffset())
    }
    
}
 
 


/*******************************/
 /*** */
 export class RzDragPen extends RzDrawPen {
    oldPos?: any
    oldOff?: any
    isDragging = false

    penInit(note: RzNote) {
        if (note.pos) note.pos.drawable = true
        if (rzIs.d) rzlog.debug('drag.on/init : note.pos=', note.pos, ',nt=', note)
        if (note.nativeDiv) note.nativeDiv.style.cursor = 'grab'
        if (note.canvas) note.canvas.style.cursor = 'grab'
        this.isDragging = true
        rzlog.debug('drag down')

    }

    penMove(note: RzNote, e: MouseEvent) {
        //rzlog.debug('drag move : pressed=',this.pressed,',e=',e)  
        if (!this.isDragging) {
            rzlog.debug('drag move : pressed=', this.pressed, ',e=', e)
            if (note.nativeDiv) note.nativeDiv.style.cursor = 'grab'
            if (note.canvas) note.canvas.style.cursor = 'grab'
            this.isDragging = true
            this.oldPos = null
            this.oldOff = null
        }

        if (this.pressed) {

            let npos = note.getPos(e)
            if (!this.oldPos) {
                this.oldPos = npos
                return
            }
            //note.ctx2d.addOffset(dx,dy)
            let dx = -this.oldPos.x + npos.x
            let dy = -this.oldPos.y + npos.y
            rzlog.debug('drag move : pressed=', this.pressed, ',dx=', dx, ',dy=', dy)
            let poff = this.oldOff
            //note.setOffset(poff.x+dx,poff.y+dy)
            note.addOffset(dx, dy)
          

            note.renderPathAll()
            this.oldPos = npos
        } else {
            //note.setFocusPath(obj)
            // note.renderPathAll()
        }
    }

    penDown(note: RzNote, e: MouseEvent) {
        this.pressed = true
        let old = note.getPos(e)
        rzlog.debug('drag down : pos=', old)
        let poff = note.getOffset()
        this.oldPos = old
        this.oldOff = poff
        //note.ctx.stoke()
    }
    penUp(note: RzNote, e: MouseEvent) {
        this.pressed = false
        note.pos = { drawable: true, ...note.getPos(e) }
    }

    penEnd(note: RzNote) {
       
        this.isDragging = false;
        if (note.nativeDiv) note.nativeDiv.style.cursor = 'crosshair'
        if (note.canvas) note.canvas.style.cursor = 'crosshair'
    }

};


import { FtClassClient } from "./ftclass.client"
import { getMainDefinition } from '@apollo/client/utilities';
import { rzlog, NewOk,RzOnFn, RzNotePage, } from "./inc"
import { split, HttpLink, DocumentNode, NormalizedCacheObject } from '@apollo/client';
import { ApolloClient,     InMemoryCache  } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';

import gql from 'graphql-tag'
import { WebSocketLink } from "@apollo/client/link/ws";
import { GQL_QUERIES, SUBSCRIB_CMDS } from "./ftclass.client.ws.graphql";
import { FtClassEvent, FtClassEventEnum } from "./ftclass.event";
import { IsFail, NewFail, RzRes, RzRsRes } from "../rzcmn";
import { FteClassJoinReq,  FteNoteInfo,  FtePage } from "./repo/ftclass.repo.dto";
import { FtoClassInfo, FtoNoteInfo } from "./dto/ftclass.dto";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { Alert } from "react-bootstrap";
  
/***************** */
const rzIs=rzlog.makeDefs(false)
export const FtClassClientWs_setDbg=rzIs.setDbg
 
/***************** */
function   toClassEvent(dt:RzRes<FtClassEvent>){
  if(dt.status>0) return dt
  if(dt.data!.cmd){
    let cmd=   SUBSCRIB_CMDS[dt.data!.cmd]
      dt.data!.cmd=cmd
  }


  if(dt?.data?.paths){
    dt.data.paths=dt.data.paths.map(t=>{  
      let r={...t};
      let jattrs={}
       if(r.attrs){
        jattrs=JSON.parse(r.attrs)
        delete r.attrs
       }
       r={...r,...jattrs}
       return r
     })
  }
  return dt
}


/*********
 * Msg
*/
export interface FtClassIn{
  nouse?:string
};

export interface FtClassMutReq {
  target?:string
  data?:FtClassIn
};
/**********
 * 
 */
export class FtClassWsClient extends FtClassClient {
 
    client?: ApolloClient<NormalizedCacheObject>
    
    accToken?:string;

 
    /**
     * 
     * @param {*} opt FtClassConnOpt
     * @returns 
     */
    async connect(){
        //9990d, rzadmin
        let accToken= this.connInfo?.token || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InJ6YWRtaW4iLCJuYW1lIjoiYWRtaW4iLCJ1dWlkIjoiZWUwMzAwNTAtYzUzNy0xMWVjLWFmM2YtMzdjOTE5YWE3ZWYxIiwiZXhwaXJlQXQiOiIyMDIyLTA0LTI3VDA4OjA3OjM0Ljg2OVoiLCJpYXQiOjE2NTA5NjA0NTQsImV4cCI6MjUxNDg3NDA1NH0.Wi3E3TIZOtk2N342k4Ueo5dPh-8B--qO3LN9IlaLhvU'
        let gqlUrl=this.connInfo?.url || `http://192.168.25.51:3001/graphql`
        let wsUrl= this.connInfo?.wsUrl ||`ws://192.168.25.51:3001/graphql`

         if(this.token) accToken=this.token;
         
        // let gqlUrl= `http://192.168.25.51:3001/graphql`
        // let wsUrl= `ws://192.168.25.51:3001/graphql`
        const authLink=setContext((_,{headers})=>{
          const token = localStorage.getItem('token')||accToken;
          rzlog.debug('AuthLink :token=',token)
          return { 
            headers: {...headers, 
              authorization: token?`Bearer ${token}`:'' }
          }

        });

        rzlog.debug('connect : gqlUrl =',gqlUrl)
        const httpLink = new HttpLink({
            uri: gqlUrl,
           // headers:{ Authorization: 'Bearer '+this.accToken}
          });
          
 
        const authOpt={  
            lazy: true,
            
            connectionParams: async () => {
                const token = localStorage.getItem('token')||accToken;
                rzlog.debug('Ws.AuthOpt :token=',token)
                return {
                  headers: {
                    Authorization: token ? `Bearer ${token}` : "",
                  },
                }
              },
        }

        let isReConnOn=true;

        /********************* */
        let wsLink = new WebSocketLink(
          {
              uri: wsUrl,
              options: {
                 reconnect: true,
                 ...authOpt, 
                 lazy:false,
              }
          });
          
   
        /********************* */
        const cli=new SubscriptionClient( wsUrl , {
          reconnect: true,
          connectionParams: async () => {
            const token = localStorage.getItem('token')||accToken;
            rzlog.debug('Ws.AuthOpt :token=',token)
            return {
              headers: {
                Authorization: token ? `Bearer ${token}` : "",
              },
            }
          },
        });
        cli.onDisconnected(()=>{
            rzlog.debug("WSCLI. disconnected !!!!!!!!!!!!!!!!!!!!");
        });
        cli.onConnected((e)=>{
          rzlog.debug("WSCLI. Connected !!!!!!!!!!!!!!!!!!!!");
        });
        cli.onReconnected(()=>{
            rzlog.debug("WSCLI. Reconnected !!!!!!!!!!!!!!!!!!!!");
            if(this.onReconnected) this.onReconnected();
        });

        const wsLink0 = new WebSocketLink( cli);

        /********************* */
        wsLink=wsLink0;

        const splitLink = split(
            ({ query }) => {
                  const definition = getMainDefinition(query);
                  if(rzIs.d) rzlog.debug('definition=',JSON.stringify(definition))
                  return (
                    definition.kind === 'OperationDefinition' &&
                    definition.operation === 'subscription'
                  );
            },  
            wsLink  ,
            authLink.concat(httpLink),
            //httpLink,
          );

          this.client = new ApolloClient({
            link: splitLink,
            cache: new InMemoryCache(),
            defaultOptions:{query:{fetchPolicy:'no-cache'}}
            
          });
    
          
          

          this._isConnected=true
          return NewOk(true)
     }



    async close(){
        if(this.client) this.client.stop()
        this.client=undefined
        this._isConnected=false
        return NewOk()
    }

    async getHello(){
        return await this.doQuery('cmn_hello',null,gql`
                query cmn_hello {
                    cmn_hello
                }
            `)
    }

    async ping(){
      return await this.doMutation('ping',null,gql`
              mutation ping  {
                  ping
              }
          `)
   }


    async onPong(fn:(m:string)=>void,efn:(e:any)=>void){
        const q= gql`subscription pong {
                    pong 
            }`

        const r = this.client!.subscribe( { query:q , variables:{}}).subscribe({
                  next({ data }) {
                      if(rzIs.d) rzlog.debug("onPong : dt=",data.pong)
                      if(fn) fn(data.pong)
                  },
                  error(err) { rzlog.error('error',err);  if(efn) efn(err)},
        })
        
        //(dt)=>{if(rzIs.d) rzlog.debug("dt=",dt)},(err)=>{if(rzIs.d) rzlog.debug('err=',err)},()=>{if(rzIs.d) rzlog.debug('done')}
        //r.closed
        //if(rzIs.d) rzlog.debug('subscripb  bind : r=',loading)
        return true
        
    }
    
    async subscribClassEvent(clsId:string,fn:RzOnFn,efn:RzOnFn){
      const q= GQL_QUERIES['onClassEvent']
      rzlog.debug('subscribClassEvent : classId=',clsId)

      let sub =this.client!.subscribe( {query:q, variables:{classId:clsId} }).subscribe({
              next({ data }) {
                  if(rzIs.d) rzlog.debug("onClassEvent : dt=",data.onClassEvent,',this=',this)

                  let evt= toClassEvent(data.onClassEvent)
                  if(fn) fn(evt)
              },
              error(err) { rzlog.error('error',err);  if(efn) efn(err)},
          })
      
      if(rzIs.d) rzlog.debug('subscribe  bind : sub=',sub)
      return true
      
  }




    async t1(){
       let rs= await this.client!.query( { query:gql`
            query cmn_hello {
                cmn_hello
            }
      `})

       if(rzIs.d) rzlog.debug('res=',rs)
       if(rzIs.d) rzlog.debug('res.data.cmn_hello=',rs.data.cmn_hello)
       return rs.data.cmn_hello
    }

    async doQuery(cmd:string,req:any,gql?:DocumentNode){
        let lets:{[key:string]:any}={}
        if(req) lets['req']=req

        let ql= gql||GQL_QUERIES[cmd]

        let rs= await this.client!.query( { query:ql,variables:lets})
        
        let r=rs?.data[cmd]
        if(rzIs.d) rzlog.debug('FtClassWsClient.query : res=',rs,',r=',r)
        return r
    }


    async doMutation(cmd:string,req:any,gql?:DocumentNode){
      let lets:{[key:string]:any}={}
      if(req) lets['req']=req

      let ql= gql||GQL_QUERIES[cmd]
      let q= { mutation:ql,variables:lets}
      if(rzIs.d) rzlog.debug('mutation q=',q)
      let rs= await this.client!.mutate(q)//.query(q)
      if(rzIs.d) rzlog.debug('res=',rs)
      let r=rs?.data[cmd]
      if(rzIs.d) rzlog.debug('FtClassWsClient.mutation : req=',req,', res=',rs,',r=',r)
      return r
    }

    /******************************
     * 
     */


    fetchNoteInfos(classId: string, pg: number, opt?: any): Promise<RzRsRes<FteNoteInfo>> {
      throw new Error("Method not implemented.");
    }

    fetchNotePage(noteId: string, pg: number, opt?: any): Promise<RzRes<FtePage>> {
      throw new Error("Method not implemented.");
    }    
    async fetchNotePages(noteId: string,   opt?: any): Promise<RzRes<RzNotePage[]>> {
      let req={noteId:noteId }
      let r= await this.doQuery('ftnote_getNote',req)

      rzlog.debug("fetchNotePages:r=",r);
      if(IsFail(r)) return NewFail(r.message);

      if(!r.data) return NewOk([]);
      
      return NewOk(r.data.page);
    }    

    
    async fetchClassInfo(classId: string, opt?: any): Promise<RzRes<FtoClassInfo>> {
      let req={classId:classId }
      let r= await this.doQuery('ftclass_getClass',req)
      rzlog.debug("fetchGetClassInfo:r=",r.data);
      return r
    }

  
    async addNoteInfo(classId:string, ntInf:FteNoteInfo): Promise<RzRes<FteNoteInfo>> {
      let req={classId:classId,data:ntInf}
      let r= await this.doMutation('ftclass_addNoteInfo',req)
      return r
    }


    async fetchJoinClass(req:FteClassJoinReq){
        let {classId,username,devType,userType,accToken} =req
        this.classId=classId;
        this.username=username;
        this.devType=devType
        this.userType=userType
        //this.accToken=accToken
        let r=await this.doMutation( 'ftclass_joinClass',req)
        if(r.status==0){
          this.accToken=r.data!.token
          if(this.accToken) localStorage.setItem('token',this.accToken)
          rzlog.debug("JoinClass(new session) : token=",this.accToken)
        }
      return r
  }

   
  //rq={classId,sesId}
    async fetchLeaveClass(rq:FteClassJoinReq){
      let req={sesId:rq.sesId,classId:rq.classId }
      rzlog.debug('FtClassClient.leaveClass : req=',req)
      let r= await this.doMutation('ftclass_leaveClass',req)
      return r
    }


    async fetchGetClassInfo(classId:string,pathOn?:boolean,opt?:any){
        let req={classId:classId ,option:{extend:pathOn?"paths":undefined}};
        
        let r= await this.doQuery('ftclass_getClass',req)
        rzlog.debug("fetchGetClassInfo:r=",r);
        return r
    }

    
    async fetchPutClassInfo(dt:FtoClassInfo,opt?:any){
      let req={data:dt };
      let r= await this.doMutation('ftclass_putClass',req)
      rzlog.debug("fetchPutClassInfo:r=",r);
      return r
  }

    async fetchGetNote(noteId:string,opt?:any){
        let req={noteId:noteId} //, page:pg
        let r= await this.doQuery('ftnote_getNote',req)
        return r
    }

    async fetchGetClassPages(classId:string, pg:number, opt?:any){
        let req={classId:classId}
        let r= await this.doQuery('ftclass_getClassPages',req)

        return r
    }

    async fetchPutNoteInfos(infs:FtoNoteInfo[],opt?:any){
      let req={data:infs };
      let r= await this.doMutation('ftclass_putNoteInfos',req)
      rzlog.debug("ftclass_putNoteInfos:r=",r);
      return r
  }


    async fetchNotiClassEvent(evt:FtClassEvent){
      let r= await this.doMutation('ftnoti_notiClassEvent',evt)
      return r
    }

    async fetchSendChat(req:any){
      let r= await this.doMutation('ftnoti_sendChat',{data:req})
      return r
    }

    async fetchGetChats(classId:string){
      let req={classId:classId}
      let r= await this.doQuery('ftnoti_getChats',req)

      return NewOk(r.data)
    }

    /***********
     * 
     */
    async addClass(req:FtClassMutReq){
      let r= await this.doMutation('ftclass_addClass',{data:req})
      return r
    }

    async delClass(req:any){
      let r= await this.doMutation('ftclass_delClass',{data:req})
      return r
    }

    async putClass(req:any){
      let r= await this.doMutation('ftclass_putClass',{data:req})
      return r
    }


    async addBook(req:any){
      let r= await this.doMutation('ftbook_addBook',{data:req})
      return r
    }

    async putBook(req:any){
      let r= await this.doMutation('ftbook_putBook',{data:req})
      return r
    }


    async delBook(req:any){
      let r= await this.doMutation('ftbook_delBook',{data:req})
      return r
    }


    async addNote(req:any){
      let r= await this.doMutation('ftnote_addNote',{data:req})
      return r
    }

    async putNote(req:any){
      let r= await this.doMutation('ftnote_putNote',{data:req})
      return r
    }


    async delNote(req:any){
      let r= await this.doMutation('ftnote_delNote',{data:req})
      return r
    }



}//class



/**********************
 * 
 */

 

import { rzlog ,NewOk,NewFail} from "../inc"
 
import { RzChatRepo } from "../ui/ftclass/ftclass.ui.chat.repo";
import { FtClassClient, FtClassClientRef } from "../ftclass.client";
import { RzChatMsg } from "../ui/ftclass/ftclass.ui.chat";
import { FtClassWsClient } from "../ftclass.client.ws";

const rzIs=rzlog.makeDefs(true)
export const FtClassRepoChat_setDbg=rzIs.setDbg
 
/**********************************
 * RzChatRmtRepo
 *********************************/

 export class  RzChatRmtRepo  extends RzChatRepo {
    clientRef?:FtClassClientRef
    isLoaded=false
    classId?:string
    userName?:string

    sesId?:string

    setClient(cliRef:FtClassClientRef){
        this.clientRef=cliRef
    }

    setClassUserInfo(clsId:string,userName:string,sesId:string){
        this.classId=clsId
        this.userName=userName
        this.sesId=sesId
    }

    async iter(){
        rzlog.debug('FtClassChatRmtRepo.iter : isLoaded=',this.isLoaded)
        if(!this.isLoaded){
            this.isLoaded=true
            await this.doLoadChats()
            rzlog.debug('FtClassChatRmtRepo.iter : doLoadChats done.')
        }

        return NewOk(this.messages)
    }

    async doLoadChats(){
        let r0 = await this.clientRef!.getClient()
        if(rzIs.d) rzlog.debug('FtClassChatRmtRepo.doLoadChats :r0=',r0)
        if(!r0) return NewFail("FtClassChatRepo.doLoadChats : no client");

        let client=r0 as FtClassWsClient

        let r=await client!.fetchGetChats(this.classId!)
        if(rzIs.d) rzlog.debug(`ClassChatRmtRepo.doLoadChats :classId=${this.classId!} r=`,r)
        if(r.status===0){
            let msgs=r.data!

            for(let i in msgs){
                let m = msgs[i]
                let nm={...m}
                if(nm.createdAt) nm.createdAt=new Date(nm.createdAt)
                this.addItem(nm)
            }//for
        }
        return r
    }

   

    async sendMsg(msg:string){
        let r0 = await this.clientRef!.getClient()
        //if(r0.status>0) return NewFail(r0.message);
        //let client=r0.data! as FtClassWsClient

        let client=r0! as FtClassWsClient

        let uinf = client.getUserInfo()

        let m:RzChatMsg={  fromName:uinf.username, fromSesId:uinf.sesId,  body:msg, classId:uinf.classId ,createdAt:new Date()}
        await client!.fetchSendChat(m)
        //m.createdAt=new Date()
        this.addItem(m)
    }
 

 }//class
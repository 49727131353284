import React, { CSSProperties, Component, ReactNode, createRef, useContext } from "react";

import { CmBindMouseOutEvent, CmComponent, RzBasProp, RzBtn, RzBtnProp, RzCol, RzRow, RzTxt } from "./rzcomp";
import { RzPop } from "./ftpop.ui";
import { RzBasRepo, RzNoteRepo, RzPenAttr, RzPenTypeEnum, rzDlgShow } from "../../note.ui";
import { FT_LAYOUT2_FLOAT, FT_LAYOUT2_FLOAT2, FT_LAYOUT2_FRAME, FT_LAYOUT2_JOIN, FT_LAYOUT2_PORTRAIT, FT_LAYOUT2_PORTRAIT_FLOAT, FT_LAYOUT2_PORTRAIT_FLOAT2 } from "../../consts";
import { FtUiContext, } from "../ftclass2/ftui.context";
import { FtxNoteTool, FtxNoteToolAttr, FtxNoteToolEnum } from "../../dto/ftclass2.dto";
import { IsFail, IsFailLog, rzlog } from "../../inc";
import { FtNoteLet } from "./ftnote.ui";
import { FtoClassInfo, FtoClassNoti, FtoClassNotiEnum, FtoClassSharing, FtoEditLock, FtoEtcSetting, FtoNoteInfo } from "../../dto/ftclass.dto";
import { FtClassEvent, FtClassEventEnum } from "../../ftclass.event";
import { WavleClientContext, WavleMediaClient } from "../../../wavle-media-client";

/*************************/
export const ID_SETTING_TOP = 'settingTop';
export const ID_SHARE_NOTE = 'shareNote';
export const ID_MIRROR = 'mirror';
export const ID_CAMERA = 'camera';
export const ID_PARENT_JOIN = 'parentJoin';

export const ID_MYCAM_ON = 'myCamOn';
export const ID_OTHER_CAM_ON = 'otherCamOn';
export const ID_BOTH_CAM_ON = 'bothCamOn';


// type MenuId = "settingTop" | "shareNote" | "mirror" | "myCamOn" | 'otherCamOn' | 'bothCamOn';
type MenuId = "settingTop" | "shareNote" | "mirror" | "myCamOn" | 'otherCamOn' | 'bothCamOn' | 'camera' | 'parentJoin';


export const IDS_SPLIT = 'split';
/*** */
export const IDS_SETT_HORIZONTAL_MODE = 'horizontalMode';
export const IDS_SETT_VERTICAL_MODE = 'verticalMode';
export const IDS_SETT_NOTES_OFF = 'settNotesOff';
export const IDS_SETT_CAM_OFF = 'settCamOff';
export const IDS_SETT_MIC_OFF = 'settMicOff';
export const IDS_SETT_FULLSCREEN = 'settFullscreen';
export const IDS_SETT_MEMO_LIST = 'settMemoList';
export const IDS_SETT_CHAT_OFF = 'settChatOff';
export const IDS_SETT_EXIT = 'settExit';
/*** */
export const IDS_WRITING_MINE = 'writingMine';
export const IDS_WRITING_OTHER = 'writingOther';
export const IDS_WRITING_OFF = 'writingOff';
export const IDS_WRITING_SHARE_REQ = 'writingShareReq';
export const IDS_ANSWER_ON_REQ = 'answerOnReq';
export const IDS_NOTE_SHARE = 'noteShare';
export const IDS_WRITING_ALLOWED = 'writingAllowed';
export const IDS_WRITING_MINE_ON = 'writingMineOn';
export const IDS_ANSWER_ON_ALLOWED = 'answerOnAllowed';
/*** */
export const IDS_MIRROR_MINE = 'mirrorMine';
export const IDS_MIRROR_OTHER = 'mirrorOther';

export const IDS_MIRROR_MINE_REQ = 'mirrorMineReq';
export const IDS_MIRROR_OTHER_REQ = 'mirrorOtherReq';

/***************** */
interface MenuItm { id: string, title?: string, stat?: string, group?: string };
let defMenus = {
    [ID_SETTING_TOP]: [
        // { id: IDS_SETT_HORIZONTAL_MODE, title: '가로모드' },
        // { id: IDS_SETT_VERTICAL_MODE, title: '세로모드' },
        { id: IDS_SETT_NOTES_OFF, title: '교재/노트 가리기' },
        { id: IDS_SETT_CAM_OFF, title: '카메라 끄기' },
        { id: IDS_SETT_MIC_OFF, title: '마이크 끄기' },
        { id: IDS_SETT_FULLSCREEN, title: '전체화면으로 보기' },
        { id: IDS_SETT_MEMO_LIST, title: '메모 목록 보기' },
        { id: IDS_SETT_CHAT_OFF, title: '채팅 끄기' },        
        { id: IDS_SETT_EXIT, title: '수업 종료' }
    ] as MenuItm[],

    [ID_SHARE_NOTE]: [
        { id: IDS_WRITING_MINE, title: '나의 필기 보기', stat: 'on' },
        { id: IDS_WRITING_SHARE_REQ, title: '필기 공유 요청' },
        { id: IDS_WRITING_OTHER, title: '상대방의 필기 보기', stat: 'on' },
        { id: IDS_WRITING_OFF, title: '필기 없이 보기', stat: 'off' },
        { id: IDS_NOTE_SHARE, title: '노트 공유', stat: 'off' },
        { id: IDS_SPLIT, title: '----------------------', group: 'teacher' },
        { id: IDS_WRITING_ALLOWED, title: '필기 허용', group: 'teacher', stat: 'on' },
        { id: IDS_WRITING_MINE_ON, title: '내 필기 보이기', group: 'teacher', stat: 'on' },
        { id: IDS_ANSWER_ON_REQ, title: '답안지 보기 요청', group: 'student' },
        { id: IDS_ANSWER_ON_ALLOWED, title: '답안지 보기 허용', group: 'teacher', stat: 'off' }
    ] as MenuItm[],

    [ID_MIRROR]: [
        { id: IDS_MIRROR_MINE, title: '내 화면 미러링', group: 'teacher' },
        { id: IDS_MIRROR_OTHER, title: '상대방 화면 미러링', group: 'teacher' },

        { id: IDS_MIRROR_MINE_REQ, title: '내 화면 미러링 신청', group: 'student' },
        { id: IDS_MIRROR_OTHER_REQ, title: '상대방 화면 미러링 신청', group: 'student' },
    ] as MenuItm[],

    [ID_MYCAM_ON]: [],
    [ID_OTHER_CAM_ON]: [],
    [ID_BOTH_CAM_ON]: [],

    [ID_CAMERA]: [],
    [ID_PARENT_JOIN]: [],

};

/*************************
 * 
 */
interface FtTopProp {
    wavleClient?: WavleMediaClient;
    onClick?: (id: string) => void;
    style?: CSSProperties;
    isTeacher?: boolean;
    isParentBtnOn?: boolean;
    isParentJoinOn?: boolean;
    menuType?: string;
}

interface FtTopStat {
    popOn?: boolean;
    popX?: number;
    popY?: number;
    selectedId?: MenuId;

    isMyEditOn?: boolean;
    isOtherEditOn?: boolean;
    menu: any;


    isPenToolShown?: boolean;
    noteId?: string;

    isFull?: boolean;

    myNoteShare?: boolean; //노트공유    
}
export class FtTopMenu extends CmComponent<FtTopProp, FtTopStat>{
    constructor(pr?: any) {
        super(pr);
        this.state = { ...this.state, menu: defMenus, isFull: false, myNoteShare:false, }
    }

    rowRef = createRef<RzRow>();

    componentDidMount() {
        CmBindMouseOutEvent('topMenu', () => {
            this.setState({ popOn: false });            
        });


        // alert('rmng='+rmng);
        let ctx = this.getCtx();
        //@let noteId = ctx.curNote?.noteId;
        let userType = ctx.userInfo?.userType;
        
        if(userType === 'teacher'){
            defMenus = {
                [ID_SETTING_TOP]: [
                    // { id: IDS_SETT_HORIZONTAL_MODE, title: '가로모드' },
                    // { id: IDS_SETT_VERTICAL_MODE, title: '세로모드' },
                    ...(ctx.PT ? [] : ctx.TP ? [] : [{ id: IDS_SETT_NOTES_OFF, title: '교재/노트 가리기' }]),
                    ...(ctx.TP ? [] : [{ id: IDS_SETT_CAM_OFF, title: '카메라 끄기' }]),
                    ...(ctx.TP ? [] : [{ id: IDS_SETT_MIC_OFF, title: '마이크 끄기' }]),
                    { id: IDS_SETT_FULLSCREEN, title: '전체화면으로 보기' },
                    ...(ctx.PT ? [] : [{ id: IDS_SETT_MEMO_LIST, title: '메모 목록 보기' }]),
                    ...(ctx.TP ? [] : [{ id: IDS_SETT_CHAT_OFF, title: '채팅 끄기' }]),        
                    { id: IDS_SETT_EXIT, title: '수업 종료' }
                ] as MenuItm[],
            
                [ID_SHARE_NOTE]: [
                    { id: IDS_WRITING_MINE, title: '나의 필기 보기', stat: 'on' },
                    { id: IDS_WRITING_SHARE_REQ, title: '필기 공유 요청' },
                    { id: IDS_WRITING_OTHER, title: '학생의 필기 보기', stat: 'on' },
                    { id: IDS_WRITING_OFF, title: '필기 없이 보기', stat: 'off' },
                    { id: IDS_NOTE_SHARE, title: '노트 공유', stat: 'off' },
                    { id: IDS_SPLIT, title: '----------------------', group: 'teacher' },
                    { id: IDS_WRITING_ALLOWED, title: '필기 허용', group: 'teacher', stat: 'on' },
                    { id: IDS_WRITING_MINE_ON, title: '내 필기 보이기', group: 'teacher', stat: 'on' },
                    { id: IDS_ANSWER_ON_REQ, title: '답안지 보기 요청', group: 'student' },
                    { id: IDS_ANSWER_ON_ALLOWED, title: '답안지 보기 허용', group: 'teacher', stat: 'off' }
                ] as MenuItm[],
            
                [ID_MIRROR]: [
                    { id: IDS_MIRROR_MINE, title: '내 화면 미러링', group: 'teacher' },
                    { id: IDS_MIRROR_OTHER, title: '학생 화면 미러링', group: 'teacher' },
            
                    { id: IDS_MIRROR_MINE_REQ, title: '내 화면 미러링 신청', group: 'student' },
                    { id: IDS_MIRROR_OTHER_REQ, title: '학생 화면 미러링 신청', group: 'student' },
                ] as MenuItm[],
            
                [ID_MYCAM_ON]: [],
                [ID_OTHER_CAM_ON]: [],
                [ID_BOTH_CAM_ON]: [],
            
                [ID_CAMERA]: [],
                [ID_PARENT_JOIN]: [],
            
            };
        }else if(userType === 'student'){
            defMenus = {
                [ID_SETTING_TOP]: [
                    // { id: IDS_SETT_HORIZONTAL_MODE, title: '가로모드' },
                    // { id: IDS_SETT_VERTICAL_MODE, title: '세로모드' },                    
                    ...(ctx.PT ? [] : ctx.TP ? [] : [{ id: IDS_SETT_NOTES_OFF, title: '교재/노트 가리기' }]),
                    ...(ctx.TP ? [] : [{ id: IDS_SETT_CAM_OFF, title: '카메라 끄기' }]),
                    ...(ctx.TP ? [] : [{ id: IDS_SETT_MIC_OFF, title: '마이크 끄기' }]),
                    { id: IDS_SETT_FULLSCREEN, title: '전체화면으로 보기' },
                    ...(ctx.PT ? [] : [{ id: IDS_SETT_MEMO_LIST, title: '메모 목록 보기' }]),
                    ...(ctx.TP ? [] : [{ id: IDS_SETT_CHAT_OFF, title: '채팅 끄기' }]),          
                    { id: IDS_SETT_EXIT, title: '수업 종료' }
                ] as MenuItm[],
            
                [ID_SHARE_NOTE]: [
                    { id: IDS_WRITING_MINE, title: '나의 필기 보기', stat: 'on' },
                    { id: IDS_WRITING_SHARE_REQ, title: '필기 공유 요청' },
                    { id: IDS_WRITING_OTHER, title: '선생님의 필기 보기', stat: 'on' },
                    { id: IDS_WRITING_OFF, title: '필기 없이 보기', stat: 'off' },
                    { id: IDS_NOTE_SHARE, title: '노트 공유', stat: 'off' },
                    { id: IDS_SPLIT, title: '----------------------', group: 'teacher' },
                    { id: IDS_WRITING_ALLOWED, title: '필기 허용', group: 'teacher', stat: 'on' },
                    { id: IDS_WRITING_MINE_ON, title: '내 필기 보이기', group: 'teacher', stat: 'on' },
                    { id: IDS_ANSWER_ON_REQ, title: '답안지 보기 요청', group: 'student' },
                    { id: IDS_ANSWER_ON_ALLOWED, title: '답안지 보기 허용', group: 'teacher', stat: 'off' }
                ] as MenuItm[],
            
                [ID_MIRROR]: [
                    { id: IDS_MIRROR_MINE, title: '내 화면 미러링', group: 'teacher' },
                    { id: IDS_MIRROR_OTHER, title: '선생님 화면 미러링', group: 'teacher' },
            
                    { id: IDS_MIRROR_MINE_REQ, title: '내 화면 미러링 신청', group: 'student' },
                    { id: IDS_MIRROR_OTHER_REQ, title: '선생님 화면 미러링 신청', group: 'student' },
                ] as MenuItm[],
            
                [ID_MYCAM_ON]: [],
                [ID_OTHER_CAM_ON]: [],
                [ID_BOTH_CAM_ON]: [],
            
                [ID_CAMERA]: [],
                [ID_PARENT_JOIN]: [],
            
            };
        }
        this.setState({menu:defMenus});
        let noteId = ctx.curNoteId;
        

        if (noteId) {
            this.setState({ noteId: noteId });
            this.doLoadMenuStat(noteId);
        } else {
            this.doLoadDefRepo();
        }

        window.addEventListener('fullscreenchange', (e) => {
            this.setState({ isFull: document.fullscreen })
        })
    }

    async doLoadDefRepo() {
        let rmng = this.getRepoMng();
        if (!rmng) return;
        let r=await rmng.getCurNoteRepo();
        if(IsFail(r)) return;
        let noteRepo=r.data;
        if(!noteRepo)return;
        
        let noteId=noteRepo.getNoteId();
        this.setState({ noteId: noteId });
        this.doLoadMenuStat(noteId);
    }

    componentDidUpdate(prevProps: Readonly<FtTopProp>, prevState: Readonly<FtTopStat>, snapshot?: any): void {
        let ctx = this.getCtx();
        //@ let noteId = ctx.curNote?.noteId;

        let noteId = ctx.curNoteId;
        rzlog.debug('FtTopUi.componentDidUpdate (ppgs): doTab cur.noteId=', noteId,',st.noteId=',this.state.noteId);
        if (noteId && this.state.noteId != noteId) {
            this.setState({ noteId: noteId });
            
            // 수정필요 
            this.doLoadMenuStat(noteId);
        }

    }

    async doCtxMenuStat() {
        
        // 노트공유, 답안지 공유 체크
        let rmng = this.getRepoMng();
        if (!rmng) return;
        
        let ctx = this.getCtx();
        let ui = rmng.getUserInfo();
        // let ui = ctx.userInfo;        

        let isWriteAllow = ctx.isWriteAllow ?? true;
        let isTeacherWrite = ctx.isTeacherWrite ?? true;
        
        // let notes = await rmng.getNoteInfos();
        // console.log(notes)

        let noteShare:boolean = false;
        let cs = rmng?.getClassSharing();
        let curNoteId = ctx.curNoteId || '';
        let curNote = rmng.getNoteSharing(curNoteId);
        
        let noteId = rmng.allNotes.filter(el => {
            if (el.type === 'note' && el.ownerId === ui?.uid) return true;
            else return false;
        })[0]?.noteId;
       
        if (noteId) {            
            let n = ctx.notes?.find(el => el.noteId === noteId);
            if (n) {
                noteShare = Boolean(n.isShareOn);
            }
            // let note = cs?.noteSharings?.filter(el => {
            //     if (el.isGroupReadOn && el.noteId === noteId) return true;
            //     else return false;
            // }) || [];
            
            // if (note.length > 0) noteShare = true;
        }
                
        if (ui?.userType == 'teacher') {
            //답안지 공유
            this.setChgMenuStat(ID_SHARE_NOTE, IDS_ANSWER_ON_ALLOWED, Boolean(cs?.isAnswerOn) ? 'on' : 'off');
            this.setChgMenuStat(ID_SHARE_NOTE, IDS_WRITING_ALLOWED, isWriteAllow ? 'on' : 'off');
            this.setChgMenuStat(ID_SHARE_NOTE, IDS_WRITING_MINE_ON, isTeacherWrite ? 'on' : 'off');
            // jjchoi 2040314 추가
        } 

        //노트공유
        this.setState({myNoteShare:noteShare});
        this.setChgMenuStat(ID_SHARE_NOTE, IDS_NOTE_SHARE, noteShare ? 'on' : 'off');
        this.setChgMenuStat(ID_SHARE_NOTE, IDS_WRITING_SHARE_REQ, curNote.isGroupWritingReadOn ? 'on' : 'off');

        
        if (ctx.curNoteId) {
            // let sh = rmng?.getNoteSharing(ctx.curNoteId);
            // console.log('@@@@@@@@@@')
            // console.log(ctx.curNoteId)
            // console.log(sh);
            // console.log('@@@@@@@@@@')
        }

    }

    //@doLoadMenuStat
    async doLoadMenuStat(noteId: string) {
        let rmng = this.getRepoMng();
        if (!rmng) return;

        let ctx = this.getCtx();
        let userType = ctx.userInfo?.userType;

        let rci = await rmng.getNoteShare(noteId);
        if (IsFail(rci)) return;

        let ci = rci.data;
        if (!ci) return;
  
        rzlog.debug("top.ui : init Sharing - noteId=", noteId);
        // let curNote = rmng.getNoteSharing(noteId);
        /////// note tab에 따라 작업필요
        if (userType == 'teacher') {            
        } else {            
        }
        this.setChgMenuStat(ID_SHARE_NOTE, IDS_WRITING_MINE, Boolean(ci.isMyWritingReadOn) ? 'on' : 'off');
        // 상대방 필기 보기 변경 cjj 2024-03-14
        this.setChgMenuStat(ID_SHARE_NOTE, IDS_WRITING_OTHER, ci.isGroupWritingReadOn ? Boolean(ci.isMyGroupWritingReadOn)? 'on' : 'off' : 'off ');
        this.setChgMenuStat(ID_SHARE_NOTE, IDS_WRITING_OFF, Boolean(ci.isMyWritingAllOn) ? 'off' : 'on');
        this.setChgMenuStat(ID_SHARE_NOTE, IDS_NOTE_SHARE, this.state.myNoteShare ? 'on' : 'off');
       
        if (ctx.curNoteId) {
            // let sh = rmng?.getNoteSharing(ctx.curNoteId);
            // console.log('@@@@@@@@@@')
            // console.log(sh);
            // console.log('@@@@@@@@@@')
        }
    }

    render(): ReactNode {
        let ctx = this.getCtx();
        let ui = ctx.userInfo;

        let st = this.props.style;
        let popOn = this.state.popOn;
        let x = this.state.popX || 0;
        let y = this.state.popY || 0;

        let menu: null | any = null;

        let isTeacher = this.props.isTeacher || ui?.userType === "teacher" ? true : false;; //||true; 

        let selId = this.state.selectedId;
        //if (selId) menu = defMenus[selId];
        if (selId) menu = this.state.menu[selId];


        if ((selId == ID_SHARE_NOTE || selId === ID_MIRROR) && !isTeacher) {
            menu = menu.filter((t: any) => t.group != 'teacher');
        }

        if ((selId == ID_SHARE_NOTE || selId === ID_MIRROR) && isTeacher) {
            menu = menu.filter((t: any) => t.group != 'student');
        }

        let h = (menu) ? menu.length * 28 : 180;

        if (this.isClicked) {
            //alert('dlg x='+x+',y='+y);
            this.isClicked = false;
        }

        let isParentBtnOn = false;
        let isParentOn = false;

        if (ctx.isParentNotiOn) isParentBtnOn = true;
        if (ctx.isParentJoinOn) isParentOn = true;

        if (this.props.isParentBtnOn) isParentBtnOn = true;
        if (this.props.isParentJoinOn) isParentOn = true;

        let menuType = this.props.menuType;
        let isMainMenu = (menuType === 'main' || menuType == undefined);
        let isFloatMenu = (menuType === 'float');
        let isFloatMobileMenu = (menuType === 'floatMobile');


        //border:'1px solid red'
        // return (<><RzRow id={'topMenu'} ref={this.rowRef} style={{ width: '100%'}} className="mainFrameTop">
        return (<><RzRow id={'topMenu'} ref={this.rowRef} className="mainFrameTop">
            {(isMainMenu) && (this.doDrawMainMenu())}
            {(isFloatMenu) && (this.doDrawFloatMenu())}
            {(isFloatMobileMenu) && (this.doDrawFloatMobileMenu())}
            {(isParentBtnOn) && (<CmParentJoinBtn isParentJoinOn={isParentOn} />)}


            {(popOn) && (<RzPop zIndex={5} className={'settingMn'} style={{ left: x, top: y, height: h, width: 130, backgroundColor: 'white', borderRadius: 3, padding: '12px 4px 12px 8px' }}>
                {menu && (menu.map((t: any, i: number) => {
                    if (t.id == IDS_SPLIT) return (<div key={'pop-' + i} id={'pop-' + i} style={{ borderTopStyle: 'solid', marginBottom: 10, height: 0, borderTopWidth: 1, borderColor: '#CBCBCB' }} title={t.title} />);
                    // if (t.id == IDS_WRITING_OTHER) return (<></>)
                    return (<RzRow key={'pop-' + i} style={{ justifyContent: 'space-between', paddingRight: 4, alignItems: 'center' }}>
                        <RzBtn style={{ height: 30 }} id={"pop-" + i}
                            // textStyle={{ color: '#222222', fontWeight: 400, fontSize: 13 }} text={t.title}
                            textStyle={{ color: `${this.doSubSelected(t.id) ? '#037DED' : '#222222'}`, fontWeight: 400, fontSize: 13 }} text={t.title}
                            onClick={() => { this.doSubClick(t.id); }}
                        />
                        {t.stat && (<RzTxt style={{ height: 30, justifyContent: 'center' }} textStyle={{ color: `${t.stat === 'on' ? '#037DED' : t.stat === 'off' ? '#ED0303' : 'C4C4C4'}` }} text={t.stat} />)}
                    </RzRow>);
                }
                ))}
            </RzPop>)}
        </RzRow>
        </>);
    }

    /** 서브 메뉴가 선택 됐을 경우 */
    doSubSelected(id: string) {
        let ctx = this.getCtx();

        if (id === IDS_SETT_NOTES_OFF) {
            return ctx.setting?.isNotesOff;
        } else if (id === IDS_SETT_CAM_OFF) {
            return ctx.setting?.isCamOff;
        } else if (id === IDS_SETT_MIC_OFF) {
            return ctx.setting?.isMicOff;
        } else if (id === IDS_SETT_FULLSCREEN) {
            // return ctx.setting?.isFullscreen;
            return this.state.isFull;
        } else if (id === IDS_SETT_MEMO_LIST) {
            return ctx.setting?.isMemoListOn;
        } else if (id === IDS_SETT_CHAT_OFF) {
            return ctx.setting?.isChatOff;
        } else if (id === IDS_SETT_HORIZONTAL_MODE) {            
            if (IDS_SETT_HORIZONTAL_MODE === ctx.setting?.isMode || ctx.layoutType === FT_LAYOUT2_FRAME) return true;
        } else if (id === IDS_SETT_VERTICAL_MODE) {
            if (IDS_SETT_VERTICAL_MODE === ctx.setting?.isMode || ctx.layoutType === FT_LAYOUT2_PORTRAIT) return true;            
        } else {
            return false;
        }
    }

    doDrawMainMenu() {

        let ctx = this.getCtx();

        let isMirrorOn = ctx.mirroring?.isMirrorOn || false;

        let layoutType = ctx.layoutType;
        let isCamera = layoutType === FT_LAYOUT2_FLOAT2 ? true : false;
        if(layoutType===FT_LAYOUT2_PORTRAIT){
            isCamera=false;
            // isCamera=Boolean(ctx.isCamFloatOn);
        }
        if(layoutType===FT_LAYOUT2_PORTRAIT_FLOAT2)isCamera=true;
        const isParentJoinOn = ctx.isParentJoinOn;

        console.log('ses', ctx.TP)
        // return (<RzRow style={{ width: 400 }}>
        return (<RzRow className={'topMainMn'} style={{ width: '100%' }}>
            <RzBtn id={ID_SETTING_TOP} icon={(<span className="ftclass2-ic-setting" />)}
                onClick={() => { this.doMenuClick(ID_SETTING_TOP); }} text=" " />
            <RzBtn id={ID_SHARE_NOTE} icon={(<span className="ftclass2-ic-sharing" />)}
                onClick={() => { this.doMenuClick(ID_SHARE_NOTE); }} text="필기/노트 공유하기" />
            <RzBtn id={ID_MIRROR} icon={(<span className="ftclass2-ic-mirror" />)}
                tail={(!isMirrorOn) ? (<></>) : (<RzTxt text="ON" style={{ width: 40, borderRadius: 16, height: 24, marginLeft: 4, justifyContent: 'center', backgroundColor: '#037DED' }} />)}
                onClick={() => { this.doMenuClick(ID_MIRROR); }} text="화면 미러링" />
            
            {/* {(layoutType !== FT_LAYOUT2_PORTRAIT_FLOAT2 && layoutType !== FT_LAYOUT2_PORTRAIT && layoutType !== FT_LAYOUT2_PORTRAIT_FLOAT) && ( */}
                
            {!ctx.TP && (
                <RzBtn id={ID_CAMERA} icon={(<span className="ftclass2-ic-camera" />)}
                    // tail={(!isCamera) ? (<></>) : (<RzTxt text="ON" style={{ width: 40, borderRadius: 16, height: 24, marginLeft: 4, justifyContent: 'center', backgroundColor: '#037DED' }} />)}
                    onClick={() => { 
                        let ctx=this.getCtx();
                        if(ctx.layoutType!==FT_LAYOUT2_PORTRAIT && ctx.layoutType!==FT_LAYOUT2_PORTRAIT_FLOAT && ctx.layoutType!==FT_LAYOUT2_PORTRAIT_FLOAT2){
                            this.doMenuClick(ID_CAMERA);
                        } else {
                            const layoutType = ctx.layoutType;
                            if (layoutType === FT_LAYOUT2_PORTRAIT) ctx.setGlobalCtx({...ctx,isCamFloatOn: !Boolean(ctx.isCamFloatOn), layoutType: FT_LAYOUT2_PORTRAIT_FLOAT2 })
                            if (layoutType === FT_LAYOUT2_PORTRAIT_FLOAT2) ctx.setGlobalCtx({...ctx,isCamFloatOn: !Boolean(ctx.isCamFloatOn), layoutType: FT_LAYOUT2_PORTRAIT })
                        }
                        
                    }} text={`카메라 고정 ${isCamera ? '하기' : '해제'}`} />
            )}   
            {/* )} */}
            {/* {(layoutType === FT_LAYOUT2_PORTRAIT_FLOAT2 || layoutType === FT_LAYOUT2_PORTRAIT || layoutType === FT_LAYOUT2_PORTRAIT_FLOAT) && (
                <RzBtn id={ID_CAMERA} icon={(<span className="ftclass2-ic-message" />)}
                    // tail={(!isCamera) ? (<></>) : (<RzTxt text="ON" style={{ width: 40, borderRadius: 16, height: 24, marginLeft: 4, justifyContent: 'center', backgroundColor: '#037DED' }} />)}
                    onClick={() => { 
                        let ctx=this.getCtx();
                        if(ctx.layoutType!==FT_LAYOUT2_PORTRAIT && ctx.layoutType!==FT_LAYOUT2_PORTRAIT_FLOAT && ctx.layoutType!==FT_LAYOUT2_PORTRAIT_FLOAT2){
                            this.doMenuClick(ID_CAMERA);
                        } else {
                            const layoutType = ctx.layoutType;
                            if (layoutType === FT_LAYOUT2_PORTRAIT) ctx.setGlobalCtx({...ctx,isCamFloatOn: !Boolean(ctx.isCamFloatOn), layoutType: FT_LAYOUT2_PORTRAIT_FLOAT2 })
                        }
                        
                    }} text={`카메라 고정 ${isCamera ? '하기' : '해제'}`} />
            )} */}
            {!isCamera && <RzBtn id={ID_PARENT_JOIN} icon={(<span className="ftclass2-ic-tv" />)}
                tail={(!isParentJoinOn) ? (<></>) : (<RzTxt text="ON" style={{ width: 25, borderRadius: 16, height: 15, marginLeft: 4, justifyContent: 'center', backgroundColor: '#037DED' }} />)}
                onClick={() => { this.doMenuClick(ID_PARENT_JOIN); }} text={`학부모 참관 ${isParentJoinOn ? '' : 'OFF'}`} />}
        </RzRow>)
    }

    doDrawFloatMenu() {
        let ctx = this.getCtx();

        let leftOn = ctx.isMyCamFocused;
        let rightOn = ctx.isOtherCamFocused;
        let bothOn = leftOn && rightOn;

        /* 23.11.15 userType에 따른 문구 변경 */
        let userType = ctx.userInfo?.userType;

        // return (<RzRow style={{ width: 700 }}>
        return (<RzRow>
            <RzBtn id={ID_SETTING_TOP} icon={(<span className="ftclass2-ic-setting" />)} style={{ marginLeft: 20, marginTop: 10, fill: 'white' }}
                onClick={() => { this.doMenuClick(ID_SETTING_TOP); }} title="" />
            <RzBtn style={{ marginTop: 10, marginLeft: 30 }}
                textStyle={{ color: (!bothOn && leftOn) ? '#5BACF8' : '#ffffff' }}
                onClick={() => { this.doMenuClick(ID_MYCAM_ON); }} title="나를 크게보기" />
            <RzBtn style={{ marginTop: 10, marginLeft: 30 }}
                textStyle={{ color: (!bothOn && rightOn) ? '#5BACF8' : '#ffffff' }}
                onClick={() => { this.doMenuClick(ID_OTHER_CAM_ON); }} title={userType === 'teacher' ? '학생을 크게 보기' : '선생님을 크게 보기'} />
            <RzBtn style={{ marginTop: 10, marginLeft: 30 }}
                textStyle={{ color: (bothOn) ? '#5BACF8' : '#ffffff' }}
                onClick={() => { this.doMenuClick(ID_BOTH_CAM_ON); }} title="기본 보기" />
        </RzRow>)
    }

    doDrawFloatMobileMenu() {
        let ctx = this.getCtx();

        let leftOn = ctx.isMyCamFocused;
        let rightOn = ctx.isOtherCamFocused;
        let bothOn = leftOn && rightOn;

        /* 23.11.15 userType에 따른 문구 변경 */
        let userType = ctx.userInfo?.userType;

        let sett = ctx.setting || {}
        let chatOn = false
        if (sett.isChatOff) chatOn = true;
        else chatOn = false;

        //return (<RzRow style={{ width: 700 }}>            
        return (<RzRow>       
            <RzBtn style={{ marginTop: 10, marginLeft: 30 }}
                textStyle={{ color: (!bothOn && leftOn) ? '#5BACF8' : '#ffffff' }}
                onClick={() => { this.doSubClick(IDS_SETT_EXIT); }} title="수업종료" />
            <RzBtn style={{ marginTop: 10, marginLeft: 30 }}
                textStyle={{ color: (!bothOn && leftOn) ? '#5BACF8' : '#ffffff' }}
                onClick={() => { this.doMenuClick(ID_MYCAM_ON); }} title="나를 크게보기" />
            <RzBtn style={{ marginTop: 10, marginLeft: 30 }}
                textStyle={{ color: (!bothOn && rightOn) ? '#5BACF8' : '#ffffff' }}
                onClick={() => { this.doMenuClick(ID_OTHER_CAM_ON); }} title={userType === 'teacher' ? '학생을 크게 보기' : '선생님을 크게 보기'} />
            <RzBtn style={{ marginTop: 10, marginLeft: 30 }}
                textStyle={{ color: (bothOn) ? '#5BACF8' : '#ffffff' }}
                onClick={() => { this.doMenuClick(ID_BOTH_CAM_ON); }} title="기본 보기" />
            <RzBtn style={{ marginTop: 10, marginLeft: 30 }}
            
                textStyle={{ color: (chatOn) ? '#5BACF8' : '#ffffff' }}
                onClick={() => { this.doSubClick(IDS_SETT_CHAT_OFF); }} title={chatOn ? '채팅켜기' : '채팅끄기'} />
        </RzRow>)
    }

    async doSubClick(id: string) {
        this.setState({ selectedId: undefined, popX: undefined, popY: undefined, popOn: false });
        let ctx = this.getCtx();
        if (id === IDS_SETT_VERTICAL_MODE) {            
            let sett = {...ctx.setting, isNotesOff: false, isChatOff: true, isCamFloatOn:false, isMode:IDS_SETT_VERTICAL_MODE} || {};
            ctx.setGlobalCtx({ ...ctx, setting: sett, layoutType:FT_LAYOUT2_PORTRAIT });
        } else if (id === IDS_SETT_HORIZONTAL_MODE) {
            let sett = {...ctx.setting, isNotesOff: false, isChatOff: false, isMode:IDS_SETT_HORIZONTAL_MODE} || {};
            ctx.setGlobalCtx({ ...ctx, setting: sett, isCamFloatOn:false, layoutType:FT_LAYOUT2_FRAME });
        } else if (id === IDS_SETT_MEMO_LIST) {
            let sett = ctx.setting || {}
            if (sett.isMemoListOn) sett = { ...sett, isMemoListOn: false };
            else sett = { ...sett, isMemoListOn: true };

            ctx.setGlobalCtx({ ...ctx, setting: sett });
        } else if (id === IDS_SETT_CHAT_OFF) {            
            let sett = ctx.setting || {}
            if (sett.isChatOff) sett = { ...sett, isChatOff: true };
            else sett = { ...sett, isChatOff: false };
            
            ctx.setGlobalCtx({ ...ctx, setting: sett });
        } else if (id === IDS_SETT_MIC_OFF) {
            let sett = ctx.setting || {}

            // if (sett.isMicOff) sett = { ...sett, isMicOff: true };
            // else sett = { ...sett, isMicOff: false };

            if (sett.isMicOff) {
                sett = { ...sett, isMicOff: false };
                this.props.wavleClient?.producerResume("audio").then(_ => {
                    ctx.setGlobalCtx({ ...ctx, setting: sett, isMic: false });
                })
            } else {
                sett = { ...sett, isMicOff: true };
                this.props.wavleClient?.producerPause("audio").then(_ => {
                    ctx.setGlobalCtx({ ...ctx, setting: sett, isMic: true });
                })
            }
        } else if (id === IDS_SETT_CAM_OFF) {
            let sett = ctx.setting || {}

            if (sett.isCamOff) {
                sett = { ...sett, isCamOff: false };
                this.props.wavleClient?.producerResume("video").then(_ => {                    
                    ctx.setGlobalCtx({ ...ctx, setting: sett, isCam:false });
                })
            } else {
                sett = { ...sett, isCamOff: true };
                this.props.wavleClient?.producerPause("video").then(_ => {
                    console.log('onMediaPause', 'producerPause')
                    ctx.setGlobalCtx({ ...ctx, setting: sett, isCam:true });            
                })
            }
            
            // alert('IDSETT_CAM_OFF'+JSON.stringify(sett));
            //ctx.setGlobalCtx({ ...ctx, setting: sett });

            
        } else if (id === IDS_SETT_NOTES_OFF) {
            /** 교재/노트 가리기 */
            let sett = ctx.setting || {}
            // if (sett.isNotesOff) sett = { ...sett, isNotesOff: true };
            // else sett = { ...sett, isNotesOff: false };
            const isNotesOff = sett.isNotesOff;
            if (sett.isNotesOff) sett = { ...sett, isNotesOff: false };
            else sett = { ...sett, isNotesOff: true };

            if (ctx.layoutType === FT_LAYOUT2_PORTRAIT) {                
                ctx.setGlobalCtx({ ...ctx, layoutType: FT_LAYOUT2_PORTRAIT_FLOAT, setting: sett, isMyCamFocused: true, isOtherCamFocused: true });
            } else if (ctx.layoutType === FT_LAYOUT2_PORTRAIT_FLOAT) {
                ctx.setGlobalCtx({ ...ctx, layoutType: FT_LAYOUT2_PORTRAIT, setting: sett, isMyCamFocused: true, isOtherCamFocused: true });
            } else {    
                /** isMyCamFocused:true, isOtherCamFocused:true 기본보기 추가 */
                ctx.setGlobalCtx({ ...ctx, layoutType: !isNotesOff ? FT_LAYOUT2_FLOAT : FT_LAYOUT2_FRAME, setting: sett, isMyCamFocused: true, isOtherCamFocused: true });
            }
        } else if (id === IDS_MIRROR_MINE) {
            let rmng = this.getRepoMng();
            if (!rmng) return;
            if (this.state.isMyEditOn) {
                // let ui = ctx.userInfo;
                let ui = rmng.getUserInfo();
                let el: FtoEditLock = {
                    userType: ui?.userType,
                    isOwnerLock: false, isOtherLock: false
                };
                rmng.setEditLock(el);
                this.setState({ isMyEditOn: false });
              
            } else {
                // let ui = ctx.userInfo;
                let ui = rmng.getUserInfo();
                let el: FtoEditLock = {
                    userType: ui?.userType,
                    isOwnerLock: ui?.userType === 'student' ? true : false,
                    isOtherLock: ui?.userType === 'student' ? false : true,
                };
                rmng.setEditLock(el);
                this.setState({ isMyEditOn: true });
                let notes=ctx.notes
                
                if(notes) rmng.notiMyPagesOnCast(notes)
            }

            //  alert('editlock - '+IDS_MIRROR_MINE);
        } else if (id === IDS_MIRROR_OTHER) {
            let rmng = this.getRepoMng();
            if (!rmng) return;
            if (this.state.isOtherEditOn) {
                // let ui = ctx.userInfo;
                let ui = rmng.getUserInfo();
                let el: FtoEditLock = {
                    userType: ui?.userType,
                    isOwnerLock: false, isOtherLock: false,
                };
                rmng.setEditLock(el);
                this.setState({ isOtherEditOn: false });
            } else {
                // let ui = ctx.userInfo;
                let ui = rmng.getUserInfo();
                let el: FtoEditLock = {
                    userType: ui?.userType,
                    isOwnerLock: ui?.userType === 'student' ? false : true,
                    isOtherLock: ui?.userType === 'student' ? true : false,
                };
                rmng.setEditLock(el);
                this.setState({ isOtherEditOn: true });
            }

            //  alert('editlock - '+IDS_MIRROR_OTHER);

        } else if (id === IDS_MIRROR_MINE_REQ) {
            let rmng = this.getRepoMng();
            if (!rmng) return;        
            
            // let ui = ctx.userInfo;
            let ui = rmng.getUserInfo();

            if (ui?.userType === 'student') {
                let gel = rmng.getEditLock();

                if (gel.isOwnerLock && !gel.isOtherLock) return; // 내화면미러링
                if (!gel.isOwnerLock && gel.isOtherLock) return; // 상대방미러링
            }

            let m = { type: FtoClassNotiEnum.MIRROR_MINE_REQ, fromId: ui?.username, title: '미러링 요청', body: '수강자의 화면 미러링\n요청이 들어왔습니다.\n허용하시겠습니까?' } as FtoClassNoti;
            rmng.sendClassNoti(m);
        } else if (id === IDS_MIRROR_OTHER_REQ) {
            let rmng = this.getRepoMng();
            if (!rmng) return;

            // let ui = ctx.userInfo;
            let ui = rmng.getUserInfo();
            if (ui?.userType === 'student') {
                let gel = rmng.getEditLock();
                if (gel.isOwnerLock && !gel.isOtherLock) return; // 내화면미러링
                if (!gel.isOwnerLock && gel.isOtherLock) return; // 상대방미러링
            }

            let m = { type: FtoClassNotiEnum.MIRROR_OTHER_REQ, fromId: ui?.username, title: '미러링 요청', body: '내 화면 미러링\n요청이 들어왔습니다.\n허용하시겠습니까?' } as FtoClassNoti;
            rmng.sendClassNoti(m);
        } else if (id === IDS_SETT_FULLSCREEN) {
            // let sett = ctx.setting || {}
            // if (sett.isFullscreen) {
            //     sett = { ...sett, isFullscreen: false };
            //     document.exitFullscreen();
            // } else {
            //     sett = { ...sett, isFullscreen: true };
            //     document.documentElement.requestFullscreen();
            // }

            // ctx.setGlobalCtx({ ...ctx, setting: sett });

            const element = document.documentElement;
            const { isFull } = this.state;
            this.setState({ isFull: !isFull })
            if (!isFull) {
                element.requestFullscreen();
            } else {
                this.setState({ isFull: false })
                document.exitFullscreen();
            }

        } else if (id === IDS_SETT_EXIT) {
            /** 수업종료 */
            let repoMng = this.getRepoMng();

            if (!repoMng) return;

            if (repoMng) {
                // console.log(ctx)
                // console.log(repoMng)
                let classInfoRepo = await repoMng.getClassInfo();

                let classId = classInfoRepo.data?.classId || '';
                // endTime ?
                let r = await repoMng.putClassInfo(classId, { classId: classId, stat: "close" } as FtoClassInfo);

                // alert('R=' + JSON.stringify(r));
                repoMng.leaveClass();
                repoMng.close();
                ctx.setGlobalCtx({ ...ctx, layoutType: FT_LAYOUT2_JOIN, userInfo: {} });
                
                this.props.wavleClient?.disconnect();
                
                // 종료시 창닫기
                window.close();
            }
        } else if (id === IDS_WRITING_MINE) {
            /** 나의 필기보기 */            
            this.doPutSharing((noteRepo, ownerId, drawerId) => {

                let sh = noteRepo.getSharing()
                let noteId = noteRepo.getNoteId();                
                noteRepo.setSharing({
                    isReadOn: true,
                    noteId: noteId,
                    //isWritingReadOn:!Boolean(sh?.isWritingReadOn),
                    isMyWritingReadOn: !Boolean(sh?.isMyWritingReadOn),                    
                }, { type: 'local' });

                this.setChgMenuStat(ID_SHARE_NOTE, IDS_WRITING_MINE);
            });
        } else if (id === IDS_WRITING_OTHER) {
            /** 상대방의 필기 보기 */
            let rmng = this.getRepoMng();
            if (!rmng) return;
            
            let ctx = this.getCtx();
            let curNoteId = ctx.curNoteId || '';
            let curNote = rmng.getNoteSharing(curNoteId);
    
            if(!curNote.isGroupWritingReadOn) return;
            this.doPutSharing((noteRepo, ownerId, drawerId) => {

                let sh = noteRepo.getSharing()
                let noteId = noteRepo.getNoteId();
                noteRepo.setSharing({
                    noteId: noteId,
                    isReadOn: true,
                    // isOtherWritingReadOn: ! Boolean(sh?.isOtherWritingReadOn),
                    isMyGroupWritingReadOn: !Boolean(sh?.isMyGroupWritingReadOn),
                }, { type: 'local' });
                // if(ownerId==drawerId){
                //     noteRepo.setSharing({
                //         noteId:noteId,
                //         isReadOn:true,                        
                //         // isOtherWritingReadOn: ! Boolean(sh?.isOtherWritingReadOn),
                //         isMyOtherWritingReadOn:!Boolean(sh?.isMyOtherWritingReadOn),
                //     },{type:'local'});                            
                // } else {                    
                //     noteRepo.setSharing({isReadOn:true,noteId:noteId,                        
                //         // isWritingReadOn:! Boolean(sh?.isWritingReadOn),
                //         isMyWritingReadOn:!Boolean(sh?.isMyWritingReadOn),
                //     },{type:'local'}); 
                // }

                this.setChgMenuStat(ID_SHARE_NOTE, IDS_WRITING_OTHER);
            });

        } else if (id === IDS_WRITING_OFF) {
            /** 필기없이 보기 */

            this.doPutSharing((noteRepo, ownerId, drawerId) => {
                let sh = noteRepo.getSharing();
                let noteId = noteRepo.getNoteId();
                // let b=Boolean(sh?.isMyWritingAllOn);
                // noteRepo.setSharing({noteId:noteId, isMyWritingAllOn: !b});

                noteRepo.setSharing({
                    isReadOn: true, noteId: noteId,
                    // isWritingReadOn:! Boolean(sh?.isWritingReadOn),
                    isMyWritingAllOn: !Boolean(sh?.isMyWritingAllOn),
                }, { type: 'local' });

                this.setChgMenuStat(ID_SHARE_NOTE, IDS_WRITING_OFF);
            });

        } else if (id === IDS_WRITING_SHARE_REQ) {
            //필기공유요청
            
            let rmng = this.getRepoMng();
            if (!rmng) return;
            // let ui = ctx.userInfo;            
            let ui = rmng.getUserInfo();

            let r1 = await rmng.getCurNoteRepo();
            if (IsFail(r1)) return;

            let noteRepo = r1.data;
            if (!noteRepo) return;

            if (ui?.userType === 'student') {
                let sh = noteRepo.getSharing();
                if (!sh?.isWritingReadOn) {
                    rzDlgShow('공유요청','선생님이 공유하기를 비활성화 해두었습니다.\n선생님의 “내 필기 보이기" 메뉴를 켜면 공유하기를 요청할 수 있습니다.',(b)=>{
                        if (b) {
                            
                        }
                    }, {isCancel:false});
                    return;
                }                
            }            
            
            let curNoteId = ctx.curNoteId;
            if (!curNoteId) return;            
                        
            // let m = { type: FtoClassNotiEnum.WRITING_SHARE_REQ, fromId: ctx.userInfo?.uid, title: '필기 공유를 요청합니다' } as FtoClassNoti;
            let m = { type: FtoClassNotiEnum.WRITING_SHARE_REQ, fromId: ui?.username, title: '필기 공유를 요청합니다', targetId: curNoteId, targetType: 'note' } as FtoClassNoti;
            rmng.sendClassNoti(m);

        } else if (id === IDS_ANSWER_ON_REQ) {
            /** 답안지 보기 요청 */            
            let rmng = this.getRepoMng();
            if (!rmng) return;
            // let ui = ctx.userInfo; 
            let ui = rmng.getUserInfo();           
            if (ui?.userType !== 'student') return;
            
            let cs = rmng?.getClassSharing();

            if (cs?.isAnswerOn) return;
            
            let r1 = await rmng.getNoteInfos();

            if (IsFail(r1)) return;
            let noteRepo = r1.data?.filter(el => {
                if (el.type === 'book' && el.subType === 'answer' && !el.isFloatingOn) return true;
                else return false;
            });
            if (noteRepo?.length === 0) {
                rzDlgShow('답안지 요청',`답안지가 없습니다.`,(b)=>{
                    if (b) {
                        
                    }
                }, {isCancel:false});
                return;
            }
            
            let m = { type: FtoClassNotiEnum.ANSWER_SHARE_REQ, fromId: ui?.username, title: '답안지 보기 요청', body: '답안지 보기 요청이 들어왔습니다.\n허용하시겠습니까?' } as FtoClassNoti;
            rmng.sendClassNoti(m);
        } else if (id === IDS_NOTE_SHARE) {
            /** 노트 공유 */
            /** TODO:
             * 자신의 노트(3)는 공유표시가 되어야하고
             * 상대방에게 자신의 노트가 공유되어야함
             * 상대방에게 공유가 안되어있을경우는 팝업이 떠야하나?
             * -------------------------- figma -------------------------
             * 노트 공유시에는 내가 보고있는 페이지만 상대방에게 공유
             * 필기 허용시에는 공유된 페이지에만 필기 가능
             * 노트가 팝업되어있어 공유가 불가합니다.
             */

            // this.setChgMenuStat(ID_SHARE_NOTE, IDS_NOTE_SHARE);

            // let rmng = this.getRepoMng();
            // if (!rmng) return;

            // let sh= rmng.getClassSharing();
            // let utype=ctx.userInfo?.userType;
            // if(utype=='teacher')  rmng.setClassSharing({isTeacherNoteOn: true});
            // else  rmng.setClassSharing({isStudentNoteOn: true});

            let rmng = this.getRepoMng();
            if (!rmng) return;
            // let ui = ctx.userInfo;                        
            let ui = rmng.getUserInfo();

            // let r1 = await rmng.getCurNoteRepo();
            let r1 = await rmng.getNoteInfos();
            
            if (IsFail(r1)) return;
            let noteRepo = r1.data?.filter(el => {
                if (el.type === 'note' && el.ownerId === ui?.uid) return true;
                else return false;
            });            
            
            if (noteRepo) {                                
                if (this.state.myNoteShare) {
                    let note = noteRepo[0];
                    let m = { type: FtoClassNotiEnum.NOTE_SHARE_CANCEL_REQ, fromId: ui?.username, title: '노트 공유', body: '노트를 공유 취소 합니다.', targetId: note.noteId, targetType: 'note' } as FtoClassNoti;
                    rmng.sendClassNoti(m);
                } else {
                    if (noteRepo[0].isFloatingOn) {
                        rzDlgShow('노트공유','노트가 팝업되어있어 공유가 불가합니다.\n노트를 상태창으로 돌려주세요.',(b)=>{
                            if (b) {
                                
                            }
                        }, {isCancel:false});
                        return;
                    }
                    let note = noteRepo[0];
                    let m = { type: FtoClassNotiEnum.NOTE_SHARE_REQ, fromId: ui?.username, title: '노트 공유', body: '노트를 공유 합니다.', targetId: note.noteId, targetType: 'note' } as FtoClassNoti;
                    rmng.sendClassNoti(m);
                }
            }

        } else if (id === IDS_WRITING_ALLOWED) {
            //@ 필기 전체 NullPEN on/off 처리 
            // ctx.setGlobalCtx({ ...ctx, isEditDisabled: ! Boolean(ctx.isEditDisabled) });
            /** 필기 허용 */
            let rmng = this.getRepoMng();
            if (!rmng) return;
            // let ui = ctx.userInfo;
            let ui = rmng.getUserInfo();

            let isWriteAllow = ctx.isWriteAllow ?? true;
            if (isWriteAllow) {
                rzDlgShow('필기 허용', '학생이 수업에만 집중하도록 \n필기를 하지 못하게 막으시겠습니까?', async (btn) => {
                    if (btn) {
                        let m = { type: FtoClassNotiEnum.NOTE_WRITE_STOP_REQ, fromId: ui?.username, title: '필기 제어', body: 'NULL PEN' } as FtoClassNoti;
                        rmng!.sendClassNoti(m);
                        ctx.setGlobalCtx({ ...ctx, isWriteAllow:false });
                    } 
                });
            } else {
                let m = { type: FtoClassNotiEnum.NOTE_WRITE_ALLOW_REQ, fromId: ui?.username, title: '필기 제어', body: 'OPEN PEN' } as FtoClassNoti;
                rmng!.sendClassNoti(m);
                ctx.setGlobalCtx({ ...ctx, isWriteAllow:true });
            }

        } else if (id === IDS_WRITING_MINE_ON) {
            //내 필기 보이기
            let rmng = this.getRepoMng();
            if (!rmng) return;
            // let ui = ctx.userInfo;
            let ui = rmng.getUserInfo();

            let isTeacherWrite = ctx.isTeacherWrite ?? true;
            if (isTeacherWrite) {
                let m = { type: FtoClassNotiEnum.WRITING_TEACHER_HIDE_REQ, fromId: ui?.username, title: '필기 제어', body: '선생님 필기 안보이기' } as FtoClassNoti;
                rmng!.sendClassNoti(m);
                ctx.setGlobalCtx({ ...ctx, isTeacherWrite:false });
            } else {
                let m = { type: FtoClassNotiEnum.WRITING_TEACHER_SHOW_REQ, fromId: ui?.username, title: '필기 제어', body: '선생님 필기 보이기' } as FtoClassNoti;
                rmng!.sendClassNoti(m);
                ctx.setGlobalCtx({ ...ctx, isTeacherWrite:true });
            }

            /*
            this.doPutSharing((noteRepo, ownerId, drawerId) => {

                let sh = noteRepo.getSharing();
                let noteId = noteRepo.getNoteId();

                noteRepo.setSharing({
                    isReadOn: true, noteId: noteId,
                    isWritingReadOn: !Boolean(sh?.isWritingReadOn),
                });

                this.setChgMenuStat(ID_SHARE_NOTE, IDS_WRITING_MINE_ON);
            });
            */
        } else if (id === IDS_ANSWER_ON_ALLOWED) {
            
            let rmng = this.getRepoMng();
            if (!rmng) return;

            // let r1 = await rmng.getCurNoteRepo();
            let r1 = await rmng.getNoteInfos();

            if (IsFail(r1)) return;
            let noteRepo = r1.data?.filter(el => {
                if (el.type === 'book' && el.subType === 'answer' && !el.isFloatingOn) return true;
                else return false;
            });
            if (noteRepo?.length === 0) {
                rzDlgShow('답안지 보기 허용',`답안지가 없습니다.`,(b)=>{
                    if (b) {
                        
                    }
                }, {isCancel:false});
                return;
            } else {
                noteRepo = noteRepo?.filter(el => !el.isFloatingOn);
                if (noteRepo?.length === 0) {
                    rzDlgShow('답안지 보기 허용',`답안지가 팝업되어있어 공유가 불가합니다.\n답안지를 상태창으로 돌려주세요.`,(b)=>{
                        if (b) {
                            
                        }
                    }, {isCancel:false});
                    return;                
                } else if (noteRepo?.length === 2) {
                    let m = { type: FtoClassNotiEnum.ANSWER_ALLOWED } as FtoClassNoti;
                    rmng.sendClassNoti(m);
                } else {
                    let noteId = noteRepo![0].noteId;
                    let m = { type: FtoClassNotiEnum.ANSWER_ALLOWED, targetId: noteId } as FtoClassNoti;
                    rmng.sendClassNoti(m);
                }
            }            
        }

        if (this.props.onClick) this.props.onClick(id);
    }//method

    async doPutSharing(fn: (noteRepo: RzNoteRepo, ownerId?: string, drawerId?: string) => void) {
        let rmng = this.getRepoMng();
        if (!rmng) return;


        let ctx = this.getCtx();
        //let curNote = ctx.curNote;
        //if (curNote?.noteId) {
        let noteRepo: RzNoteRepo | null = null;
        let curNoteId = ctx.curNoteId;
        if (curNoteId) {
            let r0 = await rmng.getNoteRepo(curNoteId);
            if (!IsFail(r0)) noteRepo = r0.data || null;
        }

 
        if (!noteRepo) return;

        let oid = noteRepo.getOwnerId();
        let did = noteRepo.getDrawerId();

        fn(noteRepo, oid, did);
    }

    async doPutSharing0(fn: (noteRepo: RzNoteRepo, ownerId?: string, drawerId?: string) => void) {
        let rmng = this.getRepoMng();
        if (!rmng) return;
        
        //let curNote = ctx.curNote;
        //if (curNote?.noteId) {         
        
        let ctx = this.getCtx();
        
        let noteRepo: RzNoteRepo | null = null;
        let curNoteId = ctx.curNoteId;
        if (curNoteId) {
            let r0 = await rmng.getNoteRepo(curNoteId);
            if (!IsFail(r0)) noteRepo = r0.data || null;
        }
        
        if (!noteRepo) return;

        let oid = noteRepo.getOwnerId();
        let did = noteRepo.getDrawerId();

        fn(noteRepo, oid, did);
    }


    setChgMenuStat(group: string, id: string, defVal?: string) {
        let amenu = this.state.menu;
        let menus = amenu[group];
        let txt = '';
        menus.forEach((element: MenuItm) => {
            if (element.id === id) {

                if (defVal) {
                    element.stat = defVal; txt = defVal;
                } else if (element.stat === 'on') {
                    element.stat = 'off'; txt = 'off';
                } else { element.stat = 'on'; txt = 'on'; }
            }
        });
        amenu[group] = menus;
        this.setState({ menu: amenu });
    }



    isClicked = false;
    doMenuClick(id: MenuId) {
        let ctx = this.getCtx();

        let el = document.getElementById(id);
        let x: number | null = null;
        let y: number | null = null;
        if (el) {
            let p = el.getBoundingClientRect();
            x = p.x;
            y = p.y + 35;
            //alert('dd= x='+x+',y='+y);
            this.isClicked = true;
        }
        let menu = defMenus[id];

        if (menu.length == 0) {

            if (id == ID_MYCAM_ON || id == ID_OTHER_CAM_ON || id == ID_BOTH_CAM_ON) {

                /** 23.11.29 추가 */
                if (id === ID_BOTH_CAM_ON) ctx.setGlobalCtx({ ...ctx, isMyCamFocused: true, isOtherCamFocused: true });
                if (id === ID_MYCAM_ON) ctx.setGlobalCtx({ ...ctx, isMyCamFocused: true, isOtherCamFocused: false });
                if (id === ID_OTHER_CAM_ON) ctx.setGlobalCtx({ ...ctx, isMyCamFocused: false, isOtherCamFocused: true });

                if (this.props.onClick) this.props.onClick(id);
            }

            if (id === ID_CAMERA) {

                const layoutType = ctx.layoutType;

                let sett = ctx.setting || {}

                if (sett.isFullscreen) sett = { ...sett, isFullscreen: true };
                else sett = { ...sett, isFullscreen: false };

                const isFloat = layoutType === FT_LAYOUT2_FLOAT2 ? true : false;


                ctx.setGlobalCtx({ ...ctx, layoutType: isFloat ? FT_LAYOUT2_FRAME : FT_LAYOUT2_FLOAT2, setting: sett });
            }

            if (id === ID_PARENT_JOIN) {
                
                // 학부모 내보내기
                let ui = ctx.userInfo;
                if (ui?.userType !== 'teacher' || !ctx.isParentJoinOn) return;                

                rzDlgShow('학부모 참관수업 내보내기','학부모를 내보내시겠습니까?',(b)=>{
                    if (b) {
                        let rmng = this.getRepoMng();
                        if (!rmng) return;

                        let m = { type: FtoClassNotiEnum.PARENT_JOIN_OUT_REQ, title: '학부모 참관수업 내보내기', body: '학부모를 내보내시겠습니까?' } as FtoClassNoti;                
                        rmng.sendClassNoti(m);
                    }
                },);
            }

            return;
        }

        if (id === ID_SHARE_NOTE) {
            this.doCtxMenuStat();
            this.doLoadMenuStat(ctx.curNoteId||'');
        }

        //if(Boolean(this.state.popOn)) alert('close');
        this.setState({ selectedId: id, popOn: !Boolean(this.state.popOn), popX: x || undefined, popY: y || undefined });
    }
}//class



/********************
 * 
 */

interface FtTopPaletteProp extends RzBasProp {
    onClick?: (id: string, pr?: string) => void;
    style?: CSSProperties;
}

interface FtTopPaletteStat {
    colorSelAt?: number;
    selSizeAt?: number;
    sizeSelAt?: number;
    color?: string;
    width?: number;
    toolId?: string;
    prevToolId?: string;

    colorOn?: boolean;
    textOn?: boolean;
    sizeOn?: boolean;
    memoOn?: boolean;
    helpOn?: boolean;
    helpBtnOn?: boolean;
    helpText?: string;

    tool?: FtxNoteTool;
}

const ID1_TEXT = 'text';
const ID2_PEN = 'pen';
const ID3_MARKER = 'marker';
const ID4_ERASER = 'eraser';
const ID5_UNDO = 'undo';
const ID6_DRAG = 'drag';
const ID7_MEMO = 'memo';

const defSizes = [1, 3, 5, 8, 15, 22, 25, 30];
const defColors = ['white', 'black', 'yellow', '#EDA139',
    '#DA4833', '#D3716E', '#A62A21', '#A3B9D5',
    '#5CC4DA', '#100572', '#BCFD53', '#31723E',
    '#AD9DF8', '#8457C7', '#59308D', '#BC7522',
];

const defToolIds = [{ id: ID1_TEXT, clsNm: 'ftclass2-ic-text' },
{ id: ID2_PEN, clsNm: 'ftclass2-ic-pen' },
{ id: ID3_MARKER, clsNm: 'ftclass2-ic-marker' },
{ id: ID4_ERASER, clsNm: 'ftclass2-ic-eraser' },
{ id: ID5_UNDO, clsNm: 'ftclass2-ic-undo' },

{ id: ID6_DRAG, clsNm: 'ftclass2-ic-hand' },
{ id: ID7_MEMO, clsNm: 'ftclass2-ic-memo' }
];

export const DEF_PEN=FtxNoteToolEnum.PEN;
export const DEF_PEN_WIDTH=8;
export const DEF_PEN_COLOR='#EDA139';


export class FtTopPalette extends CmComponent<FtTopPaletteProp, FtTopPaletteStat> {
    constructor(pr: any) {
        super(pr);

        this.state = {
            // toolId: FtxNoteToolEnum.MARKER, width: 10, color: '#EDA139',
            toolId: DEF_PEN, width: DEF_PEN_WIDTH, color: DEF_PEN_COLOR,
            sizeOn: true, colorOn: true, sizeSelAt: 2, colorSelAt: 3
        };
    }


   penAttrs: RzPenAttr[] = [];
   onClassEvent = async (e:FtClassEvent)=>{
        // if(e.cmd!==FtClassEventEnum.PEN_PUT) return;
        // if(!e.penAttr) return;
        //    // alert('pen put:'+e.penAttr);
        // //    if (id === ID1_TEXT) {
        // //     st = { ...def, textOn: true, colorOn: true };
        // // } else if (id === ID2_PEN) {
        // //     st = { ...def, colorOn: true, sizeOn: true };
        // // } else if (id === ID3_MARKER) {
        // //     st = { ...def, colorOn: true, sizeOn: true };
        // // } else if (id === ID4_ERASER) {

        // let ctx=this.getCtx();
        // let noteId=ctx.curNoteId;

        // if(noteId !== e.penAttr?.noteId) return;

        // if (e.penAttr.type=='pen'){
        //     this.doToolClick(ID2_PEN, undefined, true);
        // }else  if (e.penAttr.type=='marker'){            
        //     this.doToolClick(ID3_MARKER,undefined, true);
        // } else if(e.penAttr.type=='text') {
        //     this.doToolClick(ID1_TEXT,undefined, true);
        // } else if (e.penAttr.type=='memo') {
        //     this.doToolClick(ID7_MEMO,undefined, true);
        // }  
    }

    componentDidMount() {

        let ctx = this.getCtx();

        let defColr = {}
        let st = this.state;
        let defTool = { toolId: st.toolId, curToolAttr: { width: st.width, color: st.color } } as FtxNoteTool;
        let toolId=st.toolId;

        this.setState({ tool: defTool });
        if(ctx.curTool) {
            defTool=ctx.curTool;
            toolId=defTool.toolId;
            console.log('ftTop : curTools> curTool=',defTool);
        }

        ctx.setGlobalCtx({ ...ctx, curTool: defTool,curToolId:toolId });


        let repoMng=ctx.repoMng;
        if(repoMng) repoMng.addOnClassEvent(this.onClassEvent);
    }

    
    componentWillUnmount(): void {
        let ctx = this.getCtx();
        let repoMng=ctx.repoMng;
        if(repoMng) repoMng.delOnClassEvent(this.onClassEvent);
    }

    componentDidUpdate(prevProps: Readonly<FtTopPaletteProp>, prevState: Readonly<FtTopPaletteStat>, snapshot?: any): void {
        let ctx=this.getCtx();
         if(ctx.curTool !==this.state.tool){            
            let tst=this.doGetSubMenuStat(ctx.curTool?.toolId);
             this.setState({tool:ctx.curTool,toolId:ctx.curTool?.toolId,
                colorSelAt:ctx.curTool?.curToolAttr?.color?defColors.indexOf(ctx.curTool?.curToolAttr?.color):0,
                sizeSelAt:ctx.curTool?.curToolAttr?.width?defSizes.indexOf(ctx.curTool?.curToolAttr?.width):0,
                ...tst
            });
   
         }
    }
    
    defToolAttrs={ textOn: false, colorOn: false, sizeOn: false, helpOn: false, helpBtnOn: false, helpText: undefined, memoOn: false }

    doGetSubMenuStat(id?: string) {
        let def = this.defToolAttrs;
        let st={}
        if(!id) return st;

        if (id === ID1_TEXT) {
            st = { ...def, textOn: true, colorOn: true };
        } else if (id === ID2_PEN) {
            st = { ...def, colorOn: true, sizeOn: true };
        } else if (id === ID3_MARKER) {
            st = { ...def, colorOn: true, sizeOn: true };
        } else if (id === ID4_ERASER) {
            st = { ...def, sizeOn: true };
        } else if (id === ID6_DRAG) {
            st = { ...def, helpOn: true, helpText: "" };
        } else if (id === ID7_MEMO) {
            st = { ...def, helpOn: true, memoOn: true, helpText: "메모할 곳을 클릭/탭 하면 메모를 작성할 수 있습니다." };

        } else if (id === ID6_DRAG) {
            st = { ...def, helpOn: true, helpText: "드래그." };
        } else if (id === ID7_MEMO) {
            st = { ...def, helpOn: true, memoOn: true, helpText: "메모할 곳을 클릭/탭 하면 메모를 작성할 수 있습니다." };
        }
        return st;
    }

    render() {
        let st = { borderRadius: 15, backgroundColor: '#6D6D6D', width: 30, height: 30, marginLeft: 0, justifyContent: 'center', alignItems: 'center' };
        let st2 = { ...st, marginLeft: 10 };
        let { style, ...prs } = this.props;
        let colors = defColors;
        let st0 = this.props.style;

        let sizes = defSizes;

        let bst = { width: 17, height: 17, borderRadius: 22 };
        //@let colorSelAt = this.state.colorSelAt;
        let colorSelAt = defColors.indexOf(this.state.tool?.curToolAttr?.color||'#EDA139')??0;

        //@let selSizeAt = this.state.sizeSelAt;
        let selSizeAt =  defSizes.indexOf(this.state.tool?.curToolAttr?.width||1)??0;

        let textOn = this.state.textOn;
        let sizeOn = this.state.sizeOn;
        let colorOn = this.state.colorOn;

        let helpOn = this.state.helpOn;
        let helpTxt = this.state.helpText;
        let memoOn = this.state.memoOn;
        let helpBtnOn = this.state.helpBtnOn;
        let toolIds = defToolIds;

        /** 23.11.23 필기허용 추가(임시) */
        let ctx = this.getCtx();
        let isCtrlOn = ctx.isCtrlOn ?? true;
        
        // console.log('setsetsetsetset ===> ', textOn, sizeOn, colorOn)

        //let stx={...st, backgroundColor:'#037DED'}as CSSProperties;
        return (
            <RzRow style={{ width: '100%', overflowX: 'auto', backgroundColor: '#222222', ...st0 }} className="ft2scroll edtiorFull">
                {(isCtrlOn) &&
                    <>
                        <RzRow className="editorTool">
                            {toolIds.map((t, i) => {
                                let tst = i == 0 ? st : st2;
                                if (t.id === this.state.toolId) tst = { ...tst, backgroundColor: '#037DED' }
                                return (<RzBtn key={'ftPal-' + i} icon={(<span className={t.clsNm} />)} style={tst}
                                    onClick={() => { this.doToolClick(t.id) }} />);
                            }
                            )}
                        </RzRow>

                        <RzRow style={{ overflowX: 'auto', marginTop: 2, minWidth: 400, paddingRight: 10 }} className={"ft2scroll"}>
                            {textOn && (this.doDrawTextFlat())}
                            {sizeOn && (this.doDrawSizeFlat(sizes, bst, selSizeAt))}
                            {colorOn && (this.doDrawColorFlat(colors, bst, colorSelAt))}
                            {helpOn && (
                                <RzRow style={{ marginLeft: 20, marginTop: 8, justifyContent: 'space-between', fontSize: 15 }}  >
                                    {(helpOn) && (<RzTxt style={{ minWidth: 400 }} textStyle={{ color: '#E2E2E2' }} text={helpTxt} />)}
                                    {(memoOn) && (<RzBtn style={{ marginRight: 10, minWidth: 140, justifyContent: 'right' }} textStyle={{ color: '#5FB2FF' }} title="메모 목록 보기"
                                        onClick={() => { this.doToggleMemoList() }} />)}
                                </RzRow>
                            )}
                            {helpBtnOn && (
                                <RzRow style={{ marginLeft: 20, justifyContent: 'space-between' }}  >
                                    {(helpBtnOn) && (<RzBtn
                                        style={{ backgroundColor: '#ADADAD', width: 160, height: 30, borderRadius: 5 }}
                                        textStyle={{ marginTop: 5, marginLeft: 5, color: '#333333', fontSize: 15, fontWeight: 600, fontFamily: 'Spoqa Han Sans Neo' }} title={helpTxt} />)}
                                </RzRow>
                            )}
                        </RzRow>
                    </>
                }
            </RzRow>);
    }

    /************* */

    doDrawTextFlat() {
        return (<RzRow style={{ marginTop: -2, marginLeft: 20, alignItems: 'center' }}>
            <RzRow style={{ alignItems: 'center'}}><RzTxt textStyle={{ marginTop: 0, fontSize: 13, whiteSpace: 'nowrap', alignItems: 'center' }} text="폰트" />
                <select style={{ marginLeft: 10, width: 80, height: 28, borderRadius: 4 }} >
                    <option>돋움</option>
                    <option>고딕</option>
                </select>
            </RzRow>
            <RzRow style={{ alignItems: 'center'}}><RzTxt style={{ width: 50 }} textStyle={{ marginLeft: 20, marginTop: 0, fontSize: 13 }} text="크기" />
                <select style={{ marginLeft: 10, height: 28, width: 50, borderRadius: 4 }}  >
                    <option value={10}>10pt</option>
                    <option value={11}>11pt</option>
                    <option value={12}>12pt</option>
                    <option value={13}>13pt</option>
                    <option value={14}>14pt</option>
                    <option value={15}>15pt</option>
                    <option value={16}>16pt</option>
                    <option value={17}>17pt</option>
                    <option value={18}>18pt</option>
                    <option value={19}>19pt</option>
                    <option value={20}>20pt</option>
                </select>
            </RzRow>
        </RzRow>);
    }


    doDrawSizeFlat(sizes: any[], bst: CSSProperties, selAt?: number) {
        return (<RzRow style={{ marginTop: 0, marginLeft: 20, alignItems:'center' }}>
            <RzTxt style={{ width: 30 }} textStyle={{ marginTop: 0, fontSize: 13 }} text="크기" />
            <RzRow  >
                {sizes.map((t, i) => { return this.doDrawSize(i, t, bst, i == selAt); })}
            </RzRow>
        </RzRow>);
    }

    doDrawColorFlat(colors: any[], bst: CSSProperties, selAt?: number) {
        return (<RzRow style={{ marginTop: 0, marginLeft: 20,  alignItems:'center' }}>
            <RzTxt style={{ width: 30 }} textStyle={{ marginTop: 0, fontSize: 13 }} text="색상" />
            <RzRow   >
                {colors.map((t, i) => { return this.doDrawColor(i, t, bst, i == selAt); })}
            </RzRow>
        </RzRow>)
    }
    /************* */

    doDrawColor(i: number, clr: string, bst: any, isSel: boolean) {
        let bdrSt = '2px solid transparent';
        if (isSel) bdrSt = '2px solid skyblue';

        return (<RzCol key={'p' + i} style={{
            border: bdrSt, borderRadius: 22, width: 21, height: 21,
            marginLeft: 10, justifyContent: 'center', alignItems: "center",
        }}>
            <RzBtn style={{ ...bst, backgroundColor: clr }} onClick={() => { this.doSelClr(i, clr) }} />
        </RzCol>)
    }

    doDrawSize(i: number, sz: number, bst: any, isSel: boolean) {
        let bdrSt = '2px solid transparent';
        if (isSel) bdrSt = '2px solid skyblue';

        //rzlog.debug('click. drawSizeFlat sz='+sz+',isSel='+isSel);

        let rad = parseInt((sz * 2).toFixed());
        //let tsz = sz;
        let tsz = parseInt(sz.toFixed()) + "2";
        return (
            <RzCol key={'p' + i} style={{
                width: tsz, height: 30,
                marginLeft: 10, justifyContent: 'center', alignItems: "center",
            }}>
                <RzCol key={'p' + i} style={{
                    border: bdrSt, borderRadius: rad, width: tsz, height: tsz, padding: 1,
                    justifyContent: 'center', alignItems: "center",
                }}>
                    <RzBtn style={{ ...bst, width: sz+4, height: sz+4, backgroundColor: 'white' }} onClick={() => { this.doSelSize(i, sz) }} />
                </RzCol>
            </RzCol>)
    }
    /***************/
    doSelClr(i: number, clr: string) {
        rzlog.debug('TopBtn.doSelClr click=....:clr='+clr); 

        let st = { ... this.state, colorSelAt: i, color: clr };
        this.setState(st);
        this.doPutGlobalCtx(st);
    }
    /***************/
    doSelSize(i: number, sz: number) {
        rzlog.debug('TopBtn.doSelSize click=....:sz='+sz); 
        let st = { ... this.state, sizeSelAt: i, width: sz };
        this.setState(st);
        this.doPutGlobalCtx(st);
    }

    /********* */
    doToggleMemoList() {
        let ctx = this.getCtx();
        let memoListOn = !Boolean(ctx.setting?.isMemoListOn);        
        let sett = { ...ctx.setting, isMemoListOn: memoListOn };
        ctx.setGlobalCtx({ ...ctx, setting: sett });
    }

    doPutGlobalCtx(st: any) {
        let ctx = this.getCtx();

        let defAttr = { width: 10, color: '#000000', fontColor: '#000000' };


        let { toolId, color, width, } = st;


        let attr = {
            color: color,
            // fontSize?:number;
            // fontName?:string;
            // fontColor?:string;

            width: width,
            //opacity?:number
        } as FtxNoteToolAttr;
        let tool = {  toolId: toolId, curToolAttr: attr } as FtxNoteTool;
        let tools=ctx.curTools||[];
        // if(ctx.curTools){
        //     if(ctx.curTool){
        //         let vs=ctx.curTools.filter(el=>el.noteId !== ctx.curNoteId);
        //         if(vs.length===0) tools.push({noteId:ctx.curNoteId , tool:tool});
        //         else vs[0].tool={...tool};
        //     } else tools.push({noteId:ctx.curNoteId , tool:{...tool}});
        // }
        
        ctx.setGlobalCtx({ ...ctx, curTool: tool,curTools:tools});
        
    }
    /***************
     * 
     */
    doToolClick(id: string, pr?: string, isSkipSet?:boolean) {
        let st0 = this.state;
        rzlog.debug('doToolClick click=....'); 
        let def = this.defToolAttrs;
        let st= this.doGetSubMenuStat(id);
        if (id === ID5_UNDO) {
            st = { ...def, helpBtnOn: true, helpText: "한 단계 전으로 돌아감", prevToolId: this.state.toolId };

            let ftNote = FtNoteLet.getFocusedNote();

            if (ftNote) {
                // alert('NftNote.id='+ftNote.state.noteId);
                ftNote.undo();
            } //

            setTimeout(async () => {
                let repo = this.getRepo();
                let ctx = this.getCtx();

                // cmUndoNoteByRepo(ctx,repo);

                if (this.state.prevToolId) this.doToolClick(this.state.prevToolId);
                else this.setState({ toolId: undefined });
            }, 1000);
            return;
        }


        let stx = { ...st0, ...st, toolId: id };
        this.setState(stx);

        if(isSkipSet) return;

        this.doPutGlobalCtx(stx);
        if (this.props.onClick) this.props.onClick(id, pr);
    }


 
}//class

/*******************
 * 
 */
interface FtParentBtnProp extends RzBasProp {
    isParentJoinOn?: boolean;
    isTextOn?: boolean;
}

export class CmParentJoinBtn extends Component<FtParentBtnProp> {

    render() {


        let isParentOn = false;

        if (this.props.isParentJoinOn) isParentOn = true;
        let st = this.props.style;
        let tailEl: ReactNode | null = (<RzTxt textStyle={{ color: isParentOn ? '#037DED' : '#ED0303', marginBottom: 2 }} text={isParentOn ? "ON" : "OFF"} />);
        let tailTxt = ''
        if (this.props.isTextOn) {
            tailEl = null;
            tailTxt = (isParentOn) ? '참관 중' : '미참관';
        }

        return (<RzRndBtn style={{ width: 160, paddingLeft: 10, paddingRight: 10, marginTop: 4, marginLeft: 30, justifyContent: 'space-between', ...st }}
            icon={(<span className="ftclass2-ic-mirror" style={{ marginLeft: 4, marginTop: 4, borderRadius: 20, color: 'blue' }} />)} text={"학부모 " + tailTxt}
            tail={tailEl || undefined} />)
    }
}

/*********************
 * 
 */
interface RzRndBtnProp extends RzBtnProp {
    icon?: string | ReactNode;
    tail?: string | ReactNode;
}

export class RzRndBtn extends Component<RzRndBtnProp> {
    constructor(pr?: any) {
        super(pr);
    }

    render() {
        let { text, style, ...pr } = this.props;
        let st = { border: '2px solid #037DED', justifyContent: 'center', alignItems: 'center', height: 30, borderRadius: 15, ...style };
        let iconEl: ReactNode | null = null;
        let iconStr: string | null = null;
        let tailEl: ReactNode | null = null;
        let tailStr: string | null = null;

        if ('string' == typeof this.props.icon) iconStr = null;
        else iconEl = this.props.icon;

        if ('string' == typeof this.props.tail) tailStr = null;
        else tailEl = this.props.tail;

        return (<RzRow {...pr} style={st} onClick={this.props.onClick}>{(iconEl != null) && (iconEl)}<RzTxt text={text} />{(tailEl != null) && (tailEl)}</RzRow>);
    }
}//class


import {rzlog ,NewOk, NewFail,RzRes } from "./inc"
import { RzOnFn } from "./rzcmn.ui"
import { RzNoteRepo } from "./rznote.ui.repo"

var isDbg=false


export class RzBookEventEnum{
    static PAGE_PUT='page.put'
    static PAGES_PUT='pages.put'
    static NOTE_PUT='note.put'
}
/*********** */
export class RzBookEvent {
    cmd?: string;
    pages?:any[];
    page?:number;
    note?:any;
}


/*************
 * 
 */
export class RzBookRepo extends RzNoteRepo {
   bookId?:string
   curPage:number=0
  // pages=[{url:''}]

   async load(){
       this.notiChanged('load')
       return NewOk()
   }
   async save(){
        this.notiChanged('save')
        return NewOk()
   }

   setBookId(bookId:string){
       this.bookId=bookId
       this.noteId=bookId
       this.notiChanged('bookId')
   }

   setCurPage(page:number){this.curPage=page}

   async getAttr(pg:number,id:string):Promise<RzRes<any>>{
       if(id==='bg'){
            return NewOk({src:this.pages[pg].url})
       }

       return NewFail<any>("not found")
   }

   getUrl(){
       if(isDbg) rzlog.debug('repo.getUrl.this=',this)

       return this.pages[this.curPage].url
   }

   

   notiBookEvent(evt:RzBookEvent){
        this.notiEvent(evt)
   }

   addOnBookEvent(fn:RzOnFn,pr?:any){
        this.addOnEvent(fn,pr)
   }
   delOnBookEvent(fn:RzOnFn){
        this.delOnEvent(fn)
   }

}//class


export class RzEditBookRepo extends RzBookRepo {
   //noteRepo:RzNoteRepo=null

   async load(){
       var r=await super.load()
       if(r.status !==0) return r
       
       return NewOk()
   }

   async save(){
        var r=await super.save()
        if(r.status !==0) return r

        return NewOk()
   }


//    setNoteRepo(ntRepo:RzNoteRepo){
//        this.noteRepo=ntRepo
//        this.notiChanged('noteRepo',ntRepo)
//    }

//    getNoteRepo(){
//        return this.noteRepo
//    }

}//class

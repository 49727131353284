import { FtClassWsClient } from "../ftclass.client.ws"
import { FtClassEvent, FtClassEventEnum } from "../ftclass.event"
import { FtClassRepoMng } from "./ftclass.repo-mng"
import { rzlog,NewOk } from "../inc"

const rzIs=rzlog.makeDefs()
export const FtClassRepoMngSendHdr_setDbg=rzIs.setDbg

/************************* */


  
  
  function newPath(t:any ){
    let {attrs,isOn,isSkip,...nv}=t ; // delete nv.attrs 
    let {points,attr, ...nvv}=nv;

    return   {...nvv, type:t.type, 
            noteId:t.noteId,pageNo:t.pageNo,pageId:t.pageId,
            id:t.id,
            sesNo:t.sesNo, seq:t.seq,attrs:JSON.stringify(nv)}
  }
/************************ */


export class FtClassRepoMngSendHdr {
    isEventPaused: boolean = false
    isPausedEventProcessing:boolean =false
    pausedEvents:FtClassEvent[]=[]
    

    setEventPaused(repoMng:FtClassRepoMng, b : boolean){
        
        if(!b){
            new Promise(async ()=>{
                rzlog.debug("setEventPaused: !!!!!!!!!!!!!!!!!!!!!")
                this.isPausedEventProcessing=true
                await this.doProcPausedEvent(repoMng)
                this.isPausedEventProcessing=false

                this.isEventPaused=b;
            })
        }else this.isEventPaused=b
    }

    doAddPausedEvent(e:FtClassEvent){
        rzlog.debug("FtClassRepoMngSendHdr.doAddPausedEvent : ",e)
         this.pausedEvents.push(e)
    }

    doPutPausedEvent(e:FtClassEvent){
        rzlog.debug("FtClassRepoMngSendHdr.doPutPausedEvent : ",e)
        let es=this.pausedEvents.filter(t=> t.scmd!==e.scmd)
        es.push(e)
        this.pausedEvents=es
    }


 
    async doProcPausedEvent(repoMng:FtClassRepoMng){
         let vs:FtClassEvent[]=this.pausedEvents
         this.pausedEvents=[]
         for(let i in vs){
             let te=vs[i]
             rzlog.debug("FtClassRepoMngSendHdr.doProcPausedEvent : pe=",te)
             await this.doProcClassEvent(repoMng,te)
         }
    }

    getPausingType(e:FtClassEvent):string{
        //if(e.scmd===FtClassEventEnum.PAGES_ON)return "put";
        //else if(e.scmd===FtClassEventEnum.PAGES_PUT) return "skip";

        //else if(e.scmd===FtClassEventEnum.PAGES_ON)return "add";
        return  "skip";
    }


    async procClassEventByUi(repoMng:FtClassRepoMng, e: FtClassEvent ){

        if(rzIs.d) rzlog.debug('FtClassRepoMngHdr.procEvent : e=',e)
        if(this.isEventPaused || this.isPausedEventProcessing ) {
            const ty=this.getPausingType(e)
             if(ty==='add') { this.doAddPausedEvent(e); return; }
             else if(ty==='put') { this.doPutPausedEvent(e); return; }
        } else {
             if( !this.isPausedEventProcessing && this.pausedEvents.length > 0 ) {
                 this.isPausedEventProcessing=true
                 await this.doProcPausedEvent(repoMng)
                 this.isPausedEventProcessing=false
             }
        }
        await this.doProcClassEvent(repoMng, e)
    }

    async doProcClassEvent(repoMng:FtClassRepoMng, evt: FtClassEvent){
        let tpr=await repoMng.clientRef?.getClient();
        let pr:FtClassWsClient = tpr as FtClassWsClient;//.data! as FtClassWsClient

        if(evt.srcOpt && evt.srcOpt.type==='net' ) return
        if(evt.sesId===pr.sesId && (evt.cmd!==FtClassEventEnum.PAGES_ON && evt.cmd!==FtClassEventEnum.PAGES_PUT)) return 
    
        if(evt.srcOpt) delete evt.srcOpt
        let nevt={...evt,sesId:pr.sesId,classId:pr.classId}
        let cmd=nevt.cmd
        delete nevt.cmd
        delete nevt.book
        delete nevt.conn
    
        if(rzIs.d) rzlog.debug("SEND EVENT : cmd=",cmd,',e=',nevt,',evt.srcOpt',evt.srcOpt)
        
        
        if(cmd===FtClassEventEnum.PATH_ADD&& nevt.paths){
                nevt.scmd=FtClassEventEnum.PATH_ADD
    
                //noteId: null, pageNo: null, pageId: null, 
                nevt.paths=nevt.paths.map((t:any)=> {
                    return newPath(t)
                })
                
                if(rzIs.d) rzlog.debug("WsClient.procClassEventByUi : evt=",nevt)
    
                let r= await pr.fetchNotiClassEvent(nevt)
                return r
        } else if (cmd===FtClassEventEnum.PATH_PUT&& nevt.paths){
                nevt.scmd=FtClassEventEnum.PATH_PUT
                nevt.paths=nevt.paths.map((t:any)=> {
                    return newPath(t)
                })
                if(rzIs.d) rzlog.debug("WsClient.procClassEventByUi : evt=",nevt)
    
                let r= await pr.fetchNotiClassEvent(nevt)
                return r
            
            } else if (cmd===FtClassEventEnum.PATH_DEL&& nevt.paths){
                nevt.scmd=FtClassEventEnum.PATH_DEL
                nevt.paths=nevt.paths.map((t:any)=> {
                    return newPath(t)
                })
    
                if(rzIs.d) rzlog.debug("WsClient.procClassEventByUi : evt=",nevt)
    
                let r= await pr.fetchNotiClassEvent(nevt)
                return r
            
        } else if (cmd===FtClassEventEnum.PAGES_PUT){
           
                nevt.scmd=FtClassEventEnum.PAGES_PUT
                let sesId=repoMng.sesId;

                if(!Boolean(repoMng.isPageNotiOn)){
                    rzlog.debug('PAGES_PUT (ppgs): NotiOff , pr.sesId= ',pr.sesId ,'- pages=',nevt?.pages);
                    return NewOk();
                    // rzlog.debug('PAGES_PUT (ppgs): NotiOn , pr.sesId= ',pr.sesId ,'- pages=',nevt?.pages);
                    
                    // nevt.pages=nevt.pages!.map((t:any)=> { 
                    //         delete t.__typename;  
                    //         let { isFloatingOn, isShowOn, 
                    //                 pageCount,isSeleced,pageId,
                                      
                    //                 isFloatable, isShowable, isShareOn,
                    //             ...nt}=t;  return nt;
                    // });
                } else {
                    nevt.pages=nevt.pages!.map((t:any)=> {
                                  delete t.__typename;   
                                 // delete t.selected;
                                 // delete t.pageNo; 
                                  let { isFloatingOn, isShowOn,
                                    pageCount,isSeleced,pageId,
                                    
                                    isFloatable, isShowable, isShareOn,
                                     ...nt}=t;  return nt;
                                  return nt;})
                }

                
        
                let r= await pr.fetchNotiClassEvent(nevt)
                return r
                //return NewOk();
            
        } else if (cmd===FtClassEventEnum.PAGES_ON){
            nevt.scmd=FtClassEventEnum.PAGES_ON
            nevt.pages=nevt.pages!.map((t:any)=> {  delete t.__typename;   return t})
            let r= await pr.fetchNotiClassEvent(nevt)
            return r
    
        } else if (cmd===FtClassEventEnum.PAGES_ADD){
            nevt.scmd=FtClassEventEnum.PAGES_ADD
            nevt.pages=nevt.pages!.map((t:any)=> {  delete t.__typename;  return t})
            let r= await pr.fetchNotiClassEvent(nevt)
            return r
        } else if (cmd===FtClassEventEnum.SETTING){
            nevt.scmd=FtClassEventEnum.SETTING
            if(!nevt.setting)rzlog.info("no setting")
            rzlog.info("FtCalssRepoMng.SHdr : send setting=",nevt.setting)
            
            let r= await pr.fetchNotiClassEvent(nevt)
            return r
        } else if (cmd===FtClassEventEnum.SETTING_ETC){
            nevt.scmd=FtClassEventEnum.SETTING_ETC
          
            if(!nevt.etcSetting)rzlog.info("no etcSetting")
            let r= await pr.fetchNotiClassEvent(nevt)
            return r
        } else if (cmd===FtClassEventEnum.EDIT_LOCK){
            nevt.scmd=FtClassEventEnum.EDIT_LOCK
            if(!nevt.editLock)rzlog.info("no editLock")
            let r= await pr.fetchNotiClassEvent(nevt)
            return r
        } 
        else if (cmd===FtClassEventEnum.SHARING_PUT){
            nevt.scmd=FtClassEventEnum.SHARING_PUT
            if(!nevt.classSharing)rzlog.info("no ShareReq body")                        
            if (nevt.srcOpt?.type == 'local') return NewOk()
            if (nevt.classSharing?.noteSharings){
                let tns=nevt.classSharing.noteSharings.map((t:any)=> {
                    let {isMyGroupWritingReadOn,isMyOtherWritingReadOn,
                        isMyWritingAllOn,isMyWritingReadOn,
                        __typename,
                         ...nv}=t; 
                    return nv
                })
                nevt.classSharing.noteSharings=tns
            }
            
            let r= await pr.fetchNotiClassEvent(nevt)
            return r
        }   
        else if (cmd===FtClassEventEnum.NOTI_SEND){
            nevt.scmd=FtClassEventEnum.NOTI_SEND
            if(! nevt.noti ) rzlog.info("no NotiSend body");

            if(nevt.noti) nevt.noti.fromId=pr.username;
            let r= await pr.fetchNotiClassEvent(nevt)
            return r
        }  else if (cmd===FtClassEventEnum.CLASS_BEG_ACK){
            nevt.scmd=FtClassEventEnum.CLASS_BEG_ACK
            if(! nevt.sesId ) {
                rzlog.info("no sesId");
                alert('no event.sesId')
                return;
            }
            let r= await pr.fetchNotiClassEvent(nevt)
            return r
        } 
        // else if (cmd===FtClassEventEnum.PEN_PUT){
        //     //no operation
        // }
        return NewOk()
    }
    
}//class
    
 
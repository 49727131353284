/**
 *  S1xLecture
 */

import { rzlog,IsFail ,RzDlg,RzRes} from "../../inc"

import { FtClassRepoEnum, FtClassRepoEvent, FtClassRepoEventEnum, FtClassRepoMng, FtClassRepoMngFac} from '../../repo/ftclass.repo-mng'

import { FT_LAYOUT_BOOK, FT_LAYOUT_FULL, FT_LAYOUT_NOTE,
         FT_LAYOUT_VIDEO, FT_LAYOUT_VIDEO1, OPT_FT_LAYOUT, 
         FT_LAYOUT_FRAME, OPT_FT_REPO_MNG, FT_LAYOUT_DEFAULT } from "../../consts"
import { FtLayout,  FtUiLets } from "./ftclass.ui.layout"

 
import {  FtClassEvent, FtClassEventEnum } from "../../ftclass.event"
import './ftclass.ui.css'
 
import { FtClassClientLogDlg } from "./ftclass.ui.tool.client"
import { FtClassMirrorDlg } from "./ftclass.ui.tool.mirror"
import { FtClassApiLogDlg }from "./ftclass.ui.tool.api-log"
import { FtClassChat } from "./ftclass.ui.chat"
import { FtClassClientFac } from "../../ftclass.client.fac"
import { FtClassClient } from "../../ftclass.client"
import { FtLayoutFac } from "../../ftclass"
import { FtLayoutDefFac } from "./ftclass.ui.layout.fac"

import { FtoDevTypeEnum } from "../../dto/ftclass.dto"
import { FtUiContext } from "../ftclass2/ftui.context"
import { cmToFtxNotes } from "../../repo/ftclass2.repo.default"



const rzIs=rzlog.makeDefs()
export const FtClassUi_setDbg=rzIs.setDbg
 

let isSessionLayoutOn=true; 

export function FtClassUi_setSessionLayoutOn(b:boolean){
    isSessionLayoutOn=b;
}

/*******
 * 
 */
export class FtClassConnCmdEnum {
    static CONN_ON = 'conn.on'
    static CONN_OFF = 'conn.off'
    static MIRROR_ON='mirror.on'
    static MIRROR_OFF='mirror.off'
    static SEND = 'send'
    static RECV = 'recv'
}

export class FtClientCmdEnum {
    static CONN_ON = 'conn.on'
    static CONN_OFF = 'conn.off'

    static MIRROR_ON='mirror.on'
    static MIRROR_OFF='mirror.off'

    static PAGES_PUT = 'pages.pu'
    static CLASS_INFO = 'classInfo'
}

export class FtClientDlgEnum{
    static MIRROR='mirror'
    static CLIENT='client'
    static API_LOG='apiLog'
}


 /*********************************
 *  FtClassLet
 ********************************/
 export   class FtClassLet {
    layoutMng?:FtLayout;
    uiLets:FtUiLets=new FtUiLets();
    repoMng?:FtClassRepoMng;
    targetDiv?:HTMLElement;
    nativeDiv?:HTMLElement;
    layoutFac?:FtLayoutFac;
    _cilentLogDlg?:RzDlg;
    initOpts?:any;
    clientFac?:FtClassClientFac;
    ftClient?:FtClassClient;

    defLayoutType=FT_LAYOUT_BOOK;
    defLayoutOpts={isSideOn:true,isBookOn:true, isChatOn:true};
    ctx?:FtUiContext;


    constructor(){
        ///this.layoutFac=new FtLayoutDefFac()
        this.layoutFac=new FtLayoutDefFac();
    }

    setLayoutFac(fac:FtLayoutFac){
        this.layoutFac=fac;
    }

    setUiLets(uilets:FtUiLets){
        this.uiLets=uilets;
    }
    
    setClassRepoMng(repoMng:FtClassRepoMng){
        if(this.repoMng) this.repoMng.delOnClassEvent(this.onClassEvent)

        this.repoMng=repoMng
        if(rzIs.d)rzlog.debug('FtClassLet.setClassRepoMng : repoMng=',repoMng)
        repoMng.addOnClassEvent(this.onClassEvent,this)
        repoMng.addOnClassRepo(this.onClassRepoEvent,this)

        this.doBindSubRepos()
    }

    async doBindSubRepos(){
        //if(rzIs.d)
        rzlog.debug(`FtClassLet.doBindSubRepos:lets=`,this.uiLets)
        const keys = Object.keys(this.uiLets)
        for(let k in keys){
            let tk=keys[k]
            let l=Reflect.get(this.uiLets,keys[k])

            //if(rzIs.d)
            rzlog.debug(`FtClassLet.doBindSubRepos:type=`,k,',tk=',tk,',l=',l)
            if( (tk===FtClassRepoEnum.BOOK|| tk===FtClassRepoEnum.NOTE ) && l){
                let deskRepo= this.repoMng!.getDeskRepo(tk)
                
                if(rzIs.d)rzlog.debug(`FtClassLet.doBindSubRepos:type=`,tk,',deskRepo=',deskRepo)
                deskRepo.delOnEventAll()
                l.setDeskRepo(deskRepo)
            }//if 
        }//for

        this.doInitLayoutType()
    }

    onClassRepoEvent(e:FtClassRepoEvent,pr?:any){
        rzlog.debug('FtClassUi.onClassRepoEvent :e=',e)
        let ftClassLet=pr as FtClassLet
 
        if(e.cmd===FtClassRepoEventEnum.SESSION_START){

            
            if(!isSessionLayoutOn) return;

            let sess=e.load!.mySessions;
            let myses=e.load!.mySession;
            let vs=sess.filter(it=> (it.id!==myses!.id && it.username===myses!.username) )
            rzlog.debug("FtClassRepoMng.loadClassInfo.doUpdateSessions: my=",myses
                ,"sess=",sess,',filter=',vs)
            if(vs.length===0) return;
    
            if(vs[0].devType===FtoDevTypeEnum.MOBILE && myses?.devType===FtoDevTypeEnum.PC){  
                rzlog.debug("FtClassRepoMng.loadClassInfo.doUpdateSessions: other.MOBILE, my=",myses)
                ftClassLet.setLayoutType(FT_LAYOUT_BOOK,{isSideOn:false})
            } else if(vs[0].devType===FtoDevTypeEnum.PC && myses?.devType===FtoDevTypeEnum.MOBILE){
                rzlog.debug("FtClassRepoMng.loadClassInfo.doUpdateSessions: other.PC, my=",myses)
                ftClassLet.setLayoutType(FT_LAYOUT_VIDEO,{isSideOn:false})
            }
        } else if(e.cmd===FtClassRepoEventEnum.SESSION_END){
            
        } else if(e.cmd===FtClassRepoEventEnum.LAYOUT){
            let ty=ftClassLet.getLayoutType()
            rzlog.debug('FtClassLet.onClassRepoEvent : layoutType=',ty,',layopt=',e.layout)
            ftClassLet.setLayoutType(ty,e.layout)

        } else if(e.cmd===FtClassRepoEventEnum.REPO_LOAD){
            if(rzIs.i)rzlog.info('FtClassLet.onClassEvent: REPO_LOAD > doBindSubRepos > pr=',pr,',repoMng=',pr.repoMng)
            
            //let ui=this.repoMng?.getUserInfo()
            //if(ui) ftClassLet.setUserInfo(ui)

            ftClassLet.doBindSubRepos()
        } else if(e.cmd===FtClassRepoEventEnum.REPO_CLOSE){
            if(rzIs.i)rzlog.info('FtClassLet.onClassEvent: REPO_CLOSE > doBindSubRepos > pr=',pr);
            ftClassLet.clearUi()
        }
    }//method

    clearUi(){
        this.uiLets=new FtUiLets()
        
         if(this.layoutMng) {
             
             this.layoutMng.clearUi();

             this.layoutMng.setUiLets(this.uiLets)
             this.layoutMng.reinit(this.defLayoutType,this.defLayoutOpts)
         }
    }
 

    onClassEvent(e:FtClassEvent,pr?:any){
        //if(rzIs.d)
        rzlog.info('FtClassLet.onClassEvent: e=',JSON.stringify(e))

        let ftClassLet=pr

        
        if(e.cmd===FtClassEventEnum.CONN_ON){
            //FtClassLet.setConnStat(true)
        } else if(e.cmd===FtClassEventEnum.CONN_OFF){
            //FtClassLet.setConnStat(false)
        } else if(e.cmd===FtClassEventEnum.SESSION_ADD){
            const ui=ftClassLet.repoMng?.getUserInfo();
            rzlog.info('FtClassUi.Session.Add : ui=',ui,',e.ses=',e.session,',rpMng=',this.repoMng)
            if(!isSessionLayoutOn) return;
            if(ui?.sesId === undefined) return;

         
            //if(ui?.sesId=== e.session?.sesId){

            if(ui?.sesId=== e.session?.id){
                rzlog.debug('FtClassUi.Session.Add  : skip mysession')
                return;
            } 
            if(e.session?.username !== ui?.username) return;

            rzlog.debug('onClassEvent : SESSION_ADD - devType=',e.session?.devType)
            if(e.session?.devType===FtoDevTypeEnum.MOBILE) ftClassLet.setLayoutType('book', { isSideOn: false });
            else if(e.session?.devType===FtoDevTypeEnum.PC) ftClassLet.setLayoutType('video',{isSideOn:true});
 
        }else if(e.cmd===FtClassEventEnum.SESSION_DEL){
            const ui=ftClassLet.repoMng?.getUserInfo();
            rzlog.info('FtClassUi.Session.Del:ui=',ui,',e.ses=',e.session)

            if(!isSessionLayoutOn) return;
            if(e.session?.username !== ui?.username) return;

            if(e.session?.devType===FtoDevTypeEnum.MOBILE) ftClassLet.setLayoutType(FT_LAYOUT_BOOK,{isSideOn:true});
            else if(e.session?.devType===FtoDevTypeEnum.PC) ftClassLet.setLayoutType(FT_LAYOUT_BOOK,{isSideOn:true});
        }
    }

    async init(targetDiv:HTMLElement,pRepoMng?:FtClassRepoMng,opts?:any){

        if(rzIs.d)rzlog.debug('FtClass.init:tgtDiv=',targetDiv)

        this.initOpts=opts;
        let repoMng=pRepoMng;
        //opts.repo={}
        if(opts && opts[OPT_FT_REPO_MNG]) repoMng= opts.repoMng
        else{
            let r = await FtClassRepoMngFac.Init('memory',opts)

            repoMng=r.data!
        } 

        if(repoMng) this.setClassRepoMng(repoMng)

        window.addEventListener('resize',(e:Event)=>{
           if(rzIs.d)rzlog.debug('FtClass.window.resize:this=',this,',evt=',e)
            //let tobj=window._ftClassObj
            this.onResize(e)
        })


        let r=targetDiv.getBoundingClientRect()
        if(rzIs.d)rzlog.debug('ftclass.ref.tgtDiv.rect=',r,',lets=',this.uiLets)
        this.targetDiv=targetDiv

        this.bindExports(targetDiv)

        let layout=opts[OPT_FT_LAYOUT] || FT_LAYOUT_DEFAULT
        if(rzIs.d)rzlog.debug('FtClassLet.init : layout=',layout,',opts=',opts)
        this.setLayoutType(layout)
        
        if(this.repoMng)  this.doBindSubRepos();

        (targetDiv as any)._ftClass=this

        this.doBindResize()
    }

    doBindResize(){
        window.addEventListener("resize",()=>{
            rzlog.debug("WINDOW.ON_RESIZE")
            this.layoutMng!.layout()
        })
    }


    doGetExtClient(){
        if(!this.ftClient){
            this.ftClient= this.clientFac!.newClient(this.initOpts)
        } 
        return this.ftClient
    }


    bindExports(_targetDiv:HTMLElement){
        let targetDiv:any=_targetDiv

        targetDiv.setLayoutType=(ty:string,opt:any)=>{
            if(rzIs.d)rzlog.debug(`FtClass.navDiv.setLayoutType : ty=${ty},this=`,this)

            this.setLayoutType(ty,opt)
        }

        targetDiv.setEventPaused=(b:boolean )=>{
            if(this.repoMng) this.repoMng.setEventPaused(b)
        }

        targetDiv.setLoginInfo=   ( userId:string,accToken:string,userType:string,devType:string,loginMode?:string)=>{
            this.repoMng!.setLoginInfo(userId,accToken,userType,devType,loginMode);
        }
        // targetDiv.setUserInfo=   ( userId:string,accToken:string,userType:string,devType:string)=>{
        //     this.repoMng!.setUserInfo(userId,accToken,userType,devType)
        // }

        targetDiv.isRepoMngConnected=()=>{
            return this.repoMng!.isConnected()
        }
 
        targetDiv.joinClass=async (classId:string)=>{
            rzlog.info('FtClass.fetchClass : classid=',classId)
            
            this.repoMng!.setClassId(classId)
            let r=await this.repoMng!.load()
            if(IsFail(r)){
                alert("repoMng : can't load "+r.message)
                return 
            }

            if(this.repoMng) this.doInitCtx();
            let chatRepo=this.repoMng!.getChatRepo();
            if(this.uiLets!.chat) (this.uiLets!.chat as FtClassChat).setRepo(chatRepo!)

            return r
        }


        targetDiv.leaveClass=async ()=>{
            let clsId=this.repoMng!.getClassId()
            rzlog.info('FtClass.leaveClass : classid=',clsId)
            let r=await this.repoMng!.leaveClass()
            if(IsFail(r)) return r;

            let r2= await this.repoMng!.close();
            return r2
        }


        targetDiv.setCurPage=(pg:number, type:string)=>{
            this.repoMng!.putCurPageNoByType(pg,type)
        }

        targetDiv.movePage=async (pgOff:number, type:string)=>{
            let r=await this.repoMng!.getCurPageNoByType(type) as RzRes<number>
            let pg=r.data!
            if(pg+pgOff>=0)
                this.repoMng!.putCurPageNoByType(pg+pgOff,type)
        }

        /**** */
        targetDiv.fetchClientCmd=(cmd:string, opt?:any)=>{
            let tclient=this.doGetExtClient()
            if(cmd===FtClientCmdEnum.CONN_ON){
                tclient.connect()
            } else if(cmd===FtClientCmdEnum.CONN_OFF){
                tclient.close()
            } else if(cmd===FtClientCmdEnum.PAGES_PUT){
                //tclient.reqPagesPut(opt)
            } else if(cmd===FtClientCmdEnum.MIRROR_ON){

            }else if(cmd===FtClientCmdEnum.MIRROR_OFF){
            } 
        }
        
        targetDiv.showClientToolDlg=(toolId:string,isOn:boolean)=>{

            let nId='_'+toolId
            //rzlog.debug('showClientLogDlg:  Dlg !!!: curDlg=',this._clientLogDlg)
            if(rzIs.d)rzlog.debug('showClientLogDlg:  Dlg !!!: isOn=',isOn,', targetDiv.curDlg=',targetDiv[nId])
            if(targetDiv[nId]){
                targetDiv[nId].setVisible(isOn)
                return
            }

            let dlg:RzDlg|null=null
            
            if(toolId===FtClientDlgEnum.CLIENT) dlg=new FtClassClientLogDlg() as RzDlg
            else if(toolId===FtClientDlgEnum.MIRROR){
                let tdlg=new FtClassMirrorDlg()
                //tdlg.setRmtRepoMnt(this.repoMng!)
                dlg=tdlg as RzDlg;
                
            } else if(toolId===FtClientDlgEnum.API_LOG) {
                let tdlg=new FtClassApiLogDlg()
                //tdlg.setRmtRepoMnt(this.repoMng!)
                dlg= (tdlg as any) as RzDlg;
            }
            
            dlg!.init({parentDiv:targetDiv})
           // this._clientLogDlg=dlg
            targetDiv[nId]=dlg
            if(rzIs.d)rzlog.debug('showClientLogDlg:new Dlg :',dlg)
            if(rzIs.d)rzlog.debug('showClientLogDlg:new Dlg.targetDiv:',targetDiv)

            if(!isOn) dlg!.setVisible(false)
        }
    }//exports

    async doInitCtx(){
        let r=await this.repoMng?.getNoteInfos();
        if(!r) return;
        if(r &&IsFail(r)) return;

        if(!this.ctx) return;
        
        let notes=r.data;
        if(!notes) return ;

        let newNotes=cmToFtxNotes(notes);
        let curNoteId:string|null=null;

        if(newNotes&&newNotes.length>0){
            let vs=newNotes.filter(t=> t.isSelected);
            if(vs.length>0) curNoteId=vs[0].noteId||null;
        }
        this.ctx.setGlobalCtx({...this.ctx,notes:newNotes,curNoteId:curNoteId||undefined});
    }

    async doInitLayoutType(){
        let ui=this.repoMng?.getUserInfo();
        let sess=await this.repoMng?.getMySessions();

        if(ui === undefined && sess ==undefined ) return;
        
        if(ui?.devType==='mobile'){
            let vs=sess?.filter(t=> t.devType==='pc')
            if( vs?.length !==undefined && vs?.length >0){
                this.setLayoutType(FT_LAYOUT_VIDEO)
            }
        }
    }

    layoutType?:string;
    getLayoutType(){
        return this.layoutType||FT_LAYOUT_BOOK;
    }
    
    setLayoutType(layout:string, opt?:any){
        rzlog.debug('FtClassUi.setLayouttype:layout=',layout,',opt=',opt)
        this.layoutType=layout;
        if(this.layoutMng){
            rzlog.debug('FtClassUi.setLayouttype:opt=',opt)
            let nopt={...opt}
            if(layout===FT_LAYOUT_FULL){
                nopt.isNoteOn=true
                nopt.isBookOn=true
            }else if(layout===FT_LAYOUT_FRAME){
                //nopt.isNoteOn=false
                //nopt.isBookOn=true
            }else if(layout===FT_LAYOUT_BOOK){
                nopt.isNoteOn=false
                nopt.isBookOn=true
            }else if(layout===FT_LAYOUT_NOTE){
                nopt.isNoteOn=true
                nopt.isBookOn=false
            }else if(layout===FT_LAYOUT_VIDEO||layout===FT_LAYOUT_VIDEO1){
                nopt.isSideOn=true
                nopt.isChatOn=false
                nopt.isNoteOn=false
                nopt.isBookOn=false
                if(FT_LAYOUT_VIDEO1===layout){
                    nopt.videoCount=1
                } else  nopt.videoCount=2
            }
            //this.layoutMng.targetDiv=this.targetDiv
            this.layoutMng!.reinit(layout,nopt)
            
        } else {
            let layoutFac=this.layoutFac
            rzlog.info("LayoutFac=",layoutFac);

            if(layoutFac){
                this.layoutMng=layoutFac!.newLayout(layout);
                if(this.layoutMng){
                    this.doInitLayoutMng(this.layoutMng)
                    rzlog.debug('FtClassUi.newLayout:layoutMng.targetDiv=',this.layoutMng.targetDiv)
                } 
            }
        }//else

        if(rzIs.d)rzlog.debug('FtClassLet.setLayoutType:uilet=s',this.uiLets)
    }

    doInitLayoutMng(layMng:FtLayout,opt?:any){
        layMng.setUiLets(this.uiLets);
        layMng.setRepoMng(this.repoMng!)
        layMng.init(this.targetDiv!,opt);
    }

 
    /* ui ********** */
    onResize=(e:any)=>{
        if(rzIs.d)rzlog.trace('FtClass.resize:',e)
        let r=this.targetDiv!.getBoundingClientRect()
        if(rzIs.d)rzlog.trace('FtClass.resize: bounds=',r)
        if(this.layoutMng) this.layoutMng.layout({width:r.width, height:r.height})
    }

    updateUiCtrls(){
        if(this.layoutMng) this.layoutMng.layout()
    }



}//class

 

import { FtGetConnInfo } from "../../../config/consts";
import { FtClassEvent } from "../../ftclass.event";
import { FtClassRepoEnum, FtClassRepoMng, FtClassRepoMngFac } from "../../repo/ftclass.repo-mng";
import { FtDeskLet } from "./ftdesk.ui";
import { IsFail, RzDlg, rzlog } from "../../inc";
import { RzUiLet,RzUiOpts } from "../../inc";
import { FtDeskRepo } from "./ftdesk.ui.repo";
import { FtoUser } from "../../dto/ftclass.dto";



export class FtClassMirrorDlg extends RzDlg {
    
    noteLet1?:FtDeskLet
    noteLet2?:FtDeskLet
    repoMng1?:FtClassRepoMng

    isDualMirror?:boolean =true;
    noteLetx1?:FtDeskLet
    noteLetx2?:FtDeskLet
    repoMng2?:FtClassRepoMng
    rightPnlOn=false;
    

    doPreOpts(opt:RzUiOpts){
        //(400x2)x600 > 800x600
        let w=800;
        let colSt='display:flex; flex-direction:column;';
        if(this.isDualMirror) {
            if(this.rightPnlOn) w=1600;
            colSt='display:flex; flex-direction:row;';
        }

        let cssText=`position:absolute; left:30px; top:84px; width:${w}px; height:600px;
                    border:solid; border-width:1px; border-color:black;background-color:white;  
                    ${colSt}
                    z-index:99;  `;

        return {...opt,isDraggable:true,cssText:cssText};
    }

    doInit(opt:RzUiOpts){
        let plet=this;
        
        if(this.isDualMirror){
            let lpnl=new RzUiLet();
            lpnl.setParent(plet);
            lpnl.init({cssText:`width:100%; margin-top:0px; height:100%; display:flex; flex-direction:column; `});
            let rpnl=new RzUiLet();
            rpnl.setParent(plet);
            rpnl.init({cssText:`width:100%; margin-top:0px; height:100%; display:flex; flex-direction:column; `});

            this.doSubInit(lpnl,0,opt);
            this.doSubInit(rpnl,1,opt);
        } 
        else this.doSubInit(plet,0,opt);  

        return true
    }//doInit

    doSubInit(plet:RzUiLet , cnt:number, opt:RzUiOpts){
        let w=100;
        let clr=cnt==0?'rgba(132, 197, 255, 0.5)':'rgba(244, 197, 255, 0.5)';

        let top=new RzUiLet();
            top.setParent(plet);
            top.init({cssText:`width:${w}%; margin-top:0px; height:40px; display:flex; flex-direction:row;
                background-color:${clr};`});
            

        let body=new RzUiLet();
            body.setParent(plet);
            body.init({cssText:`top:0px; margin-top:0px; padding-top:0px; width:${w}%; hight:100%; flex:0 1 100%; display:flex; flex-direction:row;`});


        let sw=100;
        if(this.rightPnlOn) sw=50;

        let leftPnl=new RzUiLet();
            leftPnl.setParent(body);
            leftPnl.init({cssText:`width:${sw}%;height:100%; 
                            border:solid; border-width:1px; border-color:tray; background-color:lightgray; `});
        
        let noteLet1=new FtDeskLet();
            noteLet1.setType('book');
            noteLet1.setParent(leftPnl);
            noteLet1.init({className:'ftbook',cssText:'width:100%;height:100%;'});
        
        let noteLet2:FtDeskLet|null=null;

        if(this.rightPnlOn) {
            let rightPnl=new RzUiLet();
                rightPnl.setParent(body);
                rightPnl.init({cssText:`width:50%;height:100%; background-color:lightgray`});

            noteLet2=new FtDeskLet();
                noteLet2.setType('note');
                noteLet2.setParent(rightPnl);
                noteLet2.init({className:'ftnote',cssText:'width:100%;height:100%;'});
        } 

        if(cnt==0){
            this.noteLet1=noteLet1;
            if(noteLet2) this.noteLet2=noteLet2;
        } else {
            this.noteLetx1=noteLet1;
            if(noteLet2) this.noteLetx2=noteLet2;
        }

       this.open(cnt);
        if(cnt>0) this.doBindMirror(this.repoMng1!,this.repoMng2!);
        return true;
    }//doInit
 
   
    async open(cnt:number) {

        let clientFacType='fake';

        let r= await FtClassRepoMngFac.Init("mirror",{loaderType:'test',isConnOn :false, clientFacType:clientFacType});
        if(IsFail(r)) return ;
        if(!r.data)  return ;

        let repoMng=r.data;
        if(cnt==0)this.repoMng1=repoMng;
        else  this.repoMng2=repoMng; 

        let repo1:FtDeskRepo|null= repoMng.getDeskRepo(FtClassRepoEnum.BOOK);//this.repoMng.getDeskRepo(FtClassRepoEnum.BOOK);
        if(repo1){

            let rq={id:"1",classId:"1",title:"test1",type:"book",ownerId:"1",ownerName:"test1",ownerType:"teacher",ownerDevType:"mobile"};
            let r0=await repo1.addNoteInfo(rq);
            //repo1.addNotePage('1',1);
            
            let u1=new FtoUser({username:'test1',uid:'1',sesId:'1',type:'teacher',devType:'mobile'});
            repo1.setUserInfo(u1);
            this.noteLet1?.setDeskRepo(repo1);
        } 

        let repo2On=this.rightPnlOn;
        if(repo2On){
                let repo2:FtDeskRepo|null= repoMng.getDeskRepo(FtClassRepoEnum.NOTE);
                if(repo2) {
                    let rq={id:"2",classId:"1",title:"test1",type:"book",ownerId:"1",ownerName:"test1",ownerType:"teacher",ownerDevType:"mobile"};
                    let r0=await repo2.addNoteInfo(rq);
                    //repo2.addNotePage('2',1);

                    let u1=new FtoUser({username:'test2',uid:'2',sesId:'2',type:'student',devType:'mobile'});
                    repo2.setUserInfo(u1);
                    this.noteLet2?.setDeskRepo(repo2);
                }
        }
        
        return repoMng;
    }

 
    doBindMirror(rmng1 : FtClassRepoMng, rmng2:FtClassRepoMng){
 
    }//doBindMirror
    

    async close(){

    }

    setRepoMng(rmtRepoMng:FtClassRepoMng){
        this.repoMng1=rmtRepoMng
    }

    onClassEvent(e:FtClassEvent,pr?:any){
        rzlog.debug('FtClassMirrorDlg.onClassEvent : e=',e,pr)
        let repoMng=pr
        e.srcOpt={type:'mirror'}
        if(repoMng)repoMng.procClassEventByClient(e)
        else rzlog.error("DBGTool.Mirror.onClassEvent : no REPOMNG:")
    }

}//class

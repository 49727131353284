import { rzlog,RzUiLet,scale } from "../../inc";
import { FtLayoutCtx, FtLayoutInit, } from "../ftclass/ftclass.ui.layout";
import { FtFullLayout } from "./ftclass.ui.layout.full";
import { FtClassChat } from "../ftclass/ftclass.ui.chat";
import { FtLayoutSubCtx } from "./ftclass.ui.layout.sub.body";
import { FT_LAYER_POP, FT_LAYER_TOOL } from "../../consts";
 

/** dbg */
const rzIs=rzlog.makeDefs()
export const FtClassUiLayoutSubTop_setDbg =rzIs.setDbg

/**
 * FtSubTopInit
 */
export class FtSub2ChatInit implements FtLayoutInit {
    lman:FtFullLayout;


    constructor(lman:FtFullLayout){
        this.lman=lman;
    }
    
    init(lctx:FtLayoutCtx,pr?:any){
        let lman=this.lman;
        let lctx2=pr as FtLayoutSubCtx
        this.doInitChat(lctx2)
    }

    doInitChat(lctx2:FtLayoutSubCtx){
        let isChatFloating=lctx2.isChatFloating;
        let lctx=lctx2.lctx;
        if(!lctx.opt.isChatOn) return;

            let w=lctx2.bodyCnt===4? 15 :lctx2.bodyCnt===3 ?  15 :
                     (lctx2.bodyCnt===2? (lctx.opt.isSideOn?50:15) : 100 ) 
            let wp= lctx2.clientW*(w/100 )

            let cssText=`display:flex; flex-direction:column;
                        top:0px; left:0px; width:${wp}px; 
                        height:100%; background-color: #333333;`

            let nopt={isDraggable:false}
            if(isChatFloating){
                cssText=`position:absolute; z-index:${FT_LAYER_TOOL};
                        display:flex; flex-direction:column;
                        top:110px; left:${lctx2.prevWidth}px;width:${wp}px; 
                        min-width:160px;
                        height:${scale(400)}px; background-color: #333333;`
                        lctx2.bodyCnt--;
                nopt.isDraggable=true
            }
            
            if(lctx.lets!.chat?.nativeDiv) {
                lctx.lets!.chat.nativeDiv.style.width=`${wp}px`;
                
                lctx2.sbody.addChild(lctx.lets!.chat)  
                lctx.lets!.chat.setVisible(true)
            } else {
                let ix=lctx.opt.isSideOn? 1:0  
                this.doInitSub2Chat(lctx2.sbody,lctx.lets!.chat!,ix,cssText,
                        {isChatFloating:lctx2.isChatFloating})
            }

            if(isChatFloating) wp=0;
            lctx2.prevWidth+=wp
    }


    doInitSub2Chat(p:RzUiLet,chatLet:RzUiLet,ix:number,cssText:string,opt:any){
        let lman=this.lman;
        if(lman.doUdateVisble(p,chatLet,ix)) return
        
        let chat=lman.subLetFac.createChat(p,cssText,opt,lman.repoMng) as FtClassChat
            chat.addOnClose((e,pr)=>{
                if(opt?.isChatFloating){
                    //pr.doUpdateUi({isMenuOn:true, isChatOn:e.selected})
                    chat.setVisible(false)
                    if(pr.lets?.isChatOn) pr.lets.isChatOn=false;
                    chat.parent!.removeChild(chat)
                    if(pr.lets.menu?.chatBtn) 
                        pr.lets.menu!.chatBtn.setSelected(false)
                }else        pr.doUpdateUi({isChatOn:false})
                
                rzlog.debug("FtClassLayout.chatBtn=",pr.lets)
                pr.chatSelected=false;
                pr.lets.menu?.chatBtn.setSelected(false)
            },lman)
        lman.lets!.chat=chat
    }


}//class
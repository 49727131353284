/*********
 * 
 */
 
export const zero = (value: number | string) => value.toString().length === 1 ? `0${value}` : value;

type DateType = Date | string | number;

export const  rzToDateFormat = (format: string, date: DateType = Date.now(),opt?:any): string => {
    const allowForm = date;
    const _date = new Date(allowForm);

    return format.replace(/(yyyy|mm|dd|YY|MM|DD|H|h|i|s|w|pm)/g, (t: string): any => {
        switch (t) {
            case "yyyy":
                return _date.getFullYear();
			case "YY":
				return zero( (_date.getFullYear()%100).toFixed(0));
            case "mm":
                return _date.getMonth() + 1;
            case "dd":
                return _date.getDate();
            case "w":
                {   let w=_date.getDay()
                    return (w===0)? '일':
                           (w===1)? '월':
                           (w===2)? '화':
                           (w===3)? '수':
                           (w===4)? '목':
                           (w===5)? '금':'토'
                }
    
            case "MM":
                return zero(_date.getMonth() + 1);
            case "DD":
                return zero(_date.getDate());
            case "H":
                return zero(_date.getHours());
            case "h":
                { let h=_date.getHours()
                    if(h>12) h-=12;
                    return zero(h);                
                }
            case "pm":
                {
                    let h=_date.getHours()
                    return h>12?'오후':'오전'
                }
            case "i":
                return zero(_date.getMinutes());
            case "s":
                return zero(_date.getSeconds());
            default:
                return "";
        }
    });
};
 
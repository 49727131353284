

export const OPT_FT_REPO='repo'         // memory, local, remote, cached_remote
export const OPT_FT_REPO_MNG='repoMng'
export const OPT_FT_CLIENT='client'
export const OPT_FT_CLIENT_FAC='clientFac'
export const OPT_FT_CONN_INFO='connInfo'
export const OPT_FT_LAYOUT='layout'
export const OPT_FT_LAYOUT_OPT='layoutOpt'


export const FT_LAYOUT_FULL='full'
export const FT_LAYOUT_BOOK='book'

export const FT_LAYOUT_NOTE='note'
export const FT_LAYOUT_VIDEO='video'
export const FT_LAYOUT_VIDEO1='video1'
export const FT_LAYOUT_DEFAULT=FT_LAYOUT_BOOK


export const FT_LAYOUT2_JOIN='join';
export const FT_LAYOUT2_FRAME='frame';
export const FT_LAYOUT2_FLOAT='float';
export const FT_LAYOUT2_FLOAT2='float2';
export const FT_LAYOUT2_PORTRAIT='port';
export const FT_LAYOUT2_PORTRAIT_FLOAT='portFloat';
export const FT_LAYOUT2_PORTRAIT_FLOAT2='portFloat2';

export const FT_LAYOUT2_MOBILE='mobile';
export const FT_LAYOUT2_MOBILE_JOIN='mobileJoin';
export const FT_LAYOUT2_DEFAULT=FT_LAYOUT2_JOIN

export const FT_LAYOUT_FRAME='frame'
export const FT_LAYOUT_AUTOHEIHT='flex-grow:1;'
export const FT_LAYOUT_FILLBOTTOM='flex: 0 1 100%;'


//export const FT_PALETTE_SIZES=[4,6,8,10,12,14,16,20,24]
export const FT_PALETTE_SIZES=[2,3,4,6,8, 10,12,14,16]
export const FT_PALETTE_DEF_SIZE=4
export const FT_PALETTE_DEF_COLOR='#100572'
export const FT_PALETTE_DEF_COLORS=[
'#FFFFFF','#020202','#FEFF54','#EDA139','#DA4833',
'#D3716E','#A62A21', '#A3B9D5', '#5CC4DA','#100572',
'#BCFD53','#31723E','#AD9DF8','#8457C7','#59308D',  '#BC7522']

export const FT_PALETTE_FONT_SIZES=[9,10,11,12,13, 20]
export const FT_PALETTE_FONT_SIZE=12
export const FT_PALETTE_FONT_LISTS=[
            {name:'Serif',val:'Serif'},
            {name:'굴림',val:'Gulim'}]
            
// FtClassEvent 
export const FT_EVT_CONNECT='connect'
export const FT_EVT_DISCONNECT='disconnect'
export const FT_EVT_SESSION='session'
export const FT_EVT_CLASS_INFO='class'
export const FT_EVT_PAGE_INFO='page' 
export const FT_EVT_NOTE_INFO='note' 
export const FT_EVT_BOOK_INFO='book'  


export const FT_LAYER_EDIT_POP=4
export const FT_LAYER_POP=8
export const FT_LAYER_TOOL=7
export const FT_LAYER_VIDEO=9
export const FT_LAYER_EDIT=3;
import React from 'react';
import { useState} from 'react';
import './App.css';
import FtNoteView from './ftnote/FtNoteView';
import FtNoteView2 from './ftnote/FtNoteView2';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { WavleClientContext, WavleMediaClient } from './wavle-media-client';
 

function App() {
  // let [text, setText] = useState('1');
  // return (<div><input type='text' value={text} onChange={(t)=>{
  //   setText(t.target.value);}} /></div>); 
  if (process.env.RZENV === 'stg'||process.env.RZENV === 'prd') {
    if((window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__) 
      (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__.inject = function () {};
  }
  
  return (
    <WavleClientContext.Provider value={new WavleMediaClient('wss://wss.wavle.center')}>
  <BrowserRouter>
          <Routes>
            <Route path="/" element={<FtNoteView2 />}></Route>
            <Route path="/join" element={<FtNoteView2 />}></Route>
          </Routes>
        </BrowserRouter>
    </WavleClientContext.Provider>
        );
}

export default App;

import EventEmitter from "events";
import { Device } from "mediasoup-client";
import { ConsumerOptions } from "mediasoup-client/lib/Consumer";
import { RtpCapabilities } from "mediasoup-client/lib/RtpParameters";
import { SctpParameters } from "mediasoup-client/lib/SctpParameters";
import {
  DtlsParameters,
  IceCandidate,
  IceParameters,
  Transport,
} from "mediasoup-client/lib/Transport";
import { DeviceClient } from "./device-client";
import { SignalingClient } from "./signaling-client";
import React from "react";
import { rzlog } from "../rzcmn";

export interface IWavleMediaMyInfo {
  roomId: string;
  id: string;
  deviceType: string;
  deviceKind: string;
}

export class WavleMediaClient {
  private msDevice: Device;
  private signalingClient: SignalingClient;
  public sendParamData: any;
  public recvParamData: any;
  public option: any;
  private isConnected: boolean = false;
  private isInit: boolean = false;

  private serverUrl: string;

  private onEmitter = new EventEmitter();
  private OnConnectEmit = "onConnectEmit";
  private OnConnectSendTransPortEmit = "onConnectSendTransPortEmit";
  private OnConnectRecvTransPortEmit = "onConnectRecvTransPortEmit";
  private OnDisconnectEmit = "onDisconnectEmit";
  private OnNewProducerEmit = "onNewProducer";
  private OnMediaPauseEmit = "onMediaPause";
  private OnMediaClientDisconnect = "onMediaClientDisconnect";
  private OnOnOffProducer = "onOnOffProducer";

  private sendTransport: Transport | null = null;
  private recvTransport: Transport | null = null;

  private myInfo: IWavleMediaMyInfo | null = null;

  private clients: Map<string, DeviceClient> = new Map<string, DeviceClient>();

  constructor(serverUrl: string) {
    this.serverUrl = serverUrl;
    if (this.isUrl(this.serverUrl) === false) {
      throw new Error("올바른 URL이 아닙니다.");
    }
    this.msDevice = new Device();
    this.signalingClient = new SignalingClient(this.serverUrl);
    this.option = null;
    this.isConnected = false;
    this.isInit = false;
    rzlog.debug(
      "cam.WvMediaClient.init- msDevice=",
      this.msDevice,
      ",signalingClient=",
      this.signalingClient
    );
  }

  public getMyInfo() {
    return this.myInfo;
  }

  public async rinit() {
    if (this.isInit == true) return;
    const data = this.signalingClient.getRtpData();

    await this.deviceInit(data);
    if (this.option.isViewer !== true) {
      const producerTransInfo = await this.signalingClient.createTransport({
        sctpCapabilities: this.msDevice.sctpCapabilities,
        rtpCapabilities: this.msDevice.rtpCapabilities,
        type: "producer",
        forceTcp: false,
      });
      await this.createSendTransport(producerTransInfo.params);
    }

    const consumerTransInfo = await this.signalingClient.createTransport({
      sctpCapabilities: this.msDevice.sctpCapabilities,
      rtpCapabilities: this.msDevice.rtpCapabilities,
      type: "consumer",
      forceTcp: false,
    });
    await this.createRecvTransport(consumerTransInfo.params);

    if (this.option.isViewer !== true) {
      this.startListenPST();
    }
    this.startListenPRT();
    this.isInit = true;
  }

  public async init(myInfo: IWavleMediaMyInfo) {
    if (this.isInit == true) return;
    if (this.isConnected === true) {
      throw new Error("Already Connected");
    }
    this.myInfo = myInfo;

    this.signalingClient.init(
      this.myInfo.roomId,
      this.myInfo.id,
      this.myInfo.deviceType,
      this.myInfo.deviceKind
    );

    this.signalingClient.onConnect(async ({ isOnAir, socketId }) => {
      rzlog.debug("cam.WvMediaClient.init.onConnect ...");
      const data = this.signalingClient.getRtpData();
      //on connect..
      await this.deviceInit(data);
      const producerTransInfo = await this.signalingClient.createTransport({
        sctpCapabilities: this.msDevice.sctpCapabilities,
        rtpCapabilities: this.msDevice.rtpCapabilities,
        type: "producer",
        // type:"consumer",
        forceTcp: false,
      });
      const consumerTransInfo = await this.signalingClient.createTransport({
        sctpCapabilities: this.msDevice.sctpCapabilities,
        rtpCapabilities: this.msDevice.rtpCapabilities,
        type: "consumer",
        // type:"consumer",
        forceTcp: false,
      });
      await this.createSendTransport(producerTransInfo.params);
      await this.createRecvTransport(consumerTransInfo.params);
      this.startListenPST();
      this.startListenPRT();
      this.onEmitter.emit(this.OnConnectEmit);
      this.isConnected = true;
      this.isInit = true;
    });

    this.signalingClient.onNewProducer(
      ({ user_id, kind }: { user_id: string; kind: "video" | "audio" }) => {
        // this.onEmitter.emit(this.OnNewProducerEmit, { user_id, kind })
        const preClient = this.clients.get(user_id);
        if (preClient == undefined) {
          this.onEmitter.emit(this.OnNewProducerEmit, { user_id, kind });
        } else if (preClient.isAllSetting() === false) {
          this.onEmitter.emit(this.OnNewProducerEmit, { user_id, kind });
        } else {
          preClient.changeConsumer();
        }
      }
    );

    this.signalingClient.OnDisConnect(() => {
      this.onEmitter.emit(this.OnDisconnectEmit);
    });

    this.signalingClient.onError((error: any) => {
      console.error(error);
      this.onEmitter.emit(this.OnDisconnectEmit);
    });
    this.signalingClient.onMediaPause((data) => {
      this.onEmitter.emit(this.OnMediaPauseEmit, data);
    });
    this.signalingClient.onMediaClientDisconnect((data) => {
      this.onEmitter.emit(this.OnMediaClientDisconnect, data);
    });
    this.signalingClient.onOnOffProducer((data) => {
      this.onEmitter.emit(this.OnOnOffProducer, data);
    });
  }

  public getMediaDevice() {
    return this.msDevice;
  }

  public async stopProduce() {
    this.sendTransport?.close();
    this.endListenPST();
    this.sendParamData = null;
  }
  public async stopConsume() {
    this.recvTransport?.close();
    this.endListenPRT();
    this.recvParamData = null;
  }

  public async reInit() {
    this.isInit = false;
    this.stopProduce();
    this.stopConsume();
    this.clients.clear();
    await this.rinit();
  }

  /** 현재 접속중인 사용자들의 Video Producer Ids */
  public getVideoProducerIds() {
    return this.signalingClient.getVideoProducerIds();
  }

  /** 현재 접속중인 사용자들의 Audio Producer Ids */
  public getAudioProducerIds() {
    return this.signalingClient.getAudioProducerIds();
  }

  private isUrl(url: string) {
    const reg = new RegExp(
      /(ws(s)?):\/\/[(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
      "ig"
    );
    return reg.test(url);
  }

  private async deviceInit(routerRtpCapabilities: RtpCapabilities) {
    if (this.msDevice.loaded === false) {
      await this.msDevice.load({ routerRtpCapabilities });
    }
    if (!this.msDevice?.canProduce("video")) {
      console.warn("cannot product video");
    } else if (!this.msDevice?.canProduce("audio")) {
      console.warn("cannot product audio");
    }
  }

  public createSendTransport(data: {
    id: string;
    iceParameters: IceParameters;
    iceCandidates: IceCandidate[];
    dtlsParameters: DtlsParameters;
    sctpParameters?: SctpParameters;
  }) {
    this.sendParamData = data;
    this.sendTransport = this.msDevice.createSendTransport(data);
  }

  public createRecvTransport(data: {
    id: string;
    iceParameters: IceParameters;
    iceCandidates: IceCandidate[];
    dtlsParameters: DtlsParameters;
    sctpParameters?: SctpParameters;
  }) {
    this.recvParamData = data;
    this.recvTransport = this.msDevice.createRecvTransport(data);
  }

  public createClient(id: string) {
    if (this.myInfo === null) {
      throw new Error("not init wavle media client");
    }
    let preDevice = this.clients.get(id);
    if (preDevice === undefined) {
      preDevice = new DeviceClient(
        this,
        this.myInfo?.roomId,
        id,
        this.myInfo.id === id ? "producer" : "consumer"
      );
      this.clients.set(id, preDevice);
    }
    return preDevice;
  }

  // 재연결을 위한 삭제
  public removeClient(id: string) {
    this.clients.delete(id);
  }

  // 연결된 다른 클라이언트
  public getOtherClients() {
    const otherClients = Array.from(this.clients.entries())
      .filter(([clientId, client]) => clientId !== this.myInfo?.id)
      .map(([clientId, client]) => client);
    console.log("otherClients===>", otherClients, this.clients);
    return otherClients;
  }

  public getEmitter() {
    return this.onEmitter;
  }

  public getClient(id: string) {
    return this.clients.get(id);
  }

  public getMyClient() {
    if (this.myInfo === null) {
      return null;
    }
    return this.clients.get(this.myInfo.id) ?? null;
  }

  /** 내 비디오 또는 오디오 일시정지 */
  public async producerPause(kind: "video" | "audio") {
    return this.signalingClient.producerPause(kind);
  }

  /** 내 비디오 또는 오디오 재활성화 */
  public async producerResume(kind: "video" | "audio") {
    return this.signalingClient.producerResume(kind);
  }

  /** 녹화시작 */
  public async startRecoding() {
    return this.signalingClient.startRecoding();
  }

  /** 녹화종료 */
  public async endRecoding() {
    return this.signalingClient.endRecoding();
  }

  public async disconnect() {
    const myClient = this.getMyClient();
    if (myClient !== undefined) {
      myClient?.disconnect();
    }
    this.signalingClient.close();
    this.endListenPST();
    this.endListenPRT();
    this.sendParamData = null;
    this.recvParamData = null;
    this.myInfo = null;
    this.clients.clear();
    this.isConnected = false;
    this.onEmitter.removeAllListeners();
    this.signalingClient.removeAllListeners();
    this.isInit = false;
  }

  public isPause() {
    return this.signalingClient.isPause;
  }

  public isRecoding() {
    return this.signalingClient.isRecoding;
  }

  public produce(track: MediaStreamTrack) {
    if (this.sendTransport === null) {
      throw new Error("not create send transport");
    }
    return this.sendTransport?.produce({ track: track });
  }

  public consume(option: ConsumerOptions) {
    if (this.recvTransport === null) {
      throw new Error("not create recv transport");
    }
    return this.recvTransport?.consume({ ...option });
  }

  public signalConsume(kind: "video" | "audio", id: string) {
    return this.signalingClient.consume({
      rtpCapabilities: this.msDevice.rtpCapabilities,
      kind: kind,
      user_id: id,
    });
  }

  /** producer send transport 시작. */
  private startListenPST() {
    if (this.sendTransport === null) {
      throw new Error("not create send transport");
    }
    this.sendTransport.on(
      "connect",
      async ({ dtlsParameters }, callback, errback) => {
        // Here we must communicate our local parameters to our remote transport.
        try {
          await this.signalingClient.transportConnect({
            transportId: this.sendTransport?.id,
            dtlsParameters,
            type: "producer",
          });

          // Done in the server, tell our transport.
          callback();
        } catch (error: any) {
          // Something was wrong in server side.
          errback(error);
        }
      }
    );

    // Set transport "produce" event handler.
    this.sendTransport.on(
      "produce",
      async ({ kind, rtpParameters, appData }, callback, errback) => {
        // Here we must communicate our local parameters to our remote transport.
        try {
          const { id } = await this.signalingClient.produce({
            transportId: this.sendTransport?.id,
            kind,
            rtpParameters,
            appData,
          });

          // Done in the server, pass the response to our transport.
          callback({ id });
        } catch (error: any) {
          // Something was wrong in server side.
          errback(error);
        }
      }
    );

    // Set transport "producedata" event handler.
    this.sendTransport.on(
      "producedata",
      async (
        { sctpStreamParameters, label, protocol, appData },
        callback,
        errback
      ) => {
        // Here we must communicate our local parameters to our remote transport.
        try {
          // const { id } = await this.signalServer.produceData(
          //     {
          //         transportId: sendTransport.id,
          //         sctpStreamParameters,
          //         label,
          //         protocol,
          //         appData
          //     });
          // // Done in the server, pass the response to our transport.
          // callback({ id });
        } catch (error: any) {
          // Something was wrong in server side.
          errback(error);
        }
      }
    );

    this.sendTransport.on("connectionstatechange", (state) => {
      switch (state) {
        case "connecting":
          break;

        case "connected":
          this.onEmitter.emit(this.OnConnectSendTransPortEmit);
          break;

        case "failed":
          this.sendTransport?.close();
          break;

        default:
          break;
      }
    });
  }

  private endListenPST() {
    if (this.sendTransport !== null) {
      this.sendTransport.close();
      this.sendTransport = null;
    }
  }

  private startListenPRT() {
    if (this.recvTransport === null) {
      throw new Error("not create recv transport");
    }
    this.recvTransport?.on(
      "connect",
      async ({ dtlsParameters }, callback, errback) => {
        try {
          await this.signalingClient.transportConnect({
            transportId: this.recvTransport?.id,
            dtlsParameters,
            type: "consumer",
          });

          // Done in the server, tell our transport.
          callback();
        } catch (error: any) {
          // Something was wrong in server side.
          errback(error);
        }
      }
    );

    this.recvTransport?.on("connectionstatechange", async (state) => {
      // console.log("video recv state", state);

      switch (state) {
        case "connecting":
          break;

        case "connected":
          await this.signalingClient.resume({});
          this.onEmitter.emit(this.OnConnectRecvTransPortEmit);
          break;

        case "failed":
          this.recvTransport?.close();
          break;

        default:
          break;
      }
    });
  }

  private endListenPRT() {
    if (this.recvTransport !== null) {
      this.recvTransport.close();
      this.recvTransport = null;
    }
  }

  public onConnect(listner: (...args: any) => void) {
    return this.onEmitter.addListener(this.OnConnectEmit, listner);
  }
  public onSendTransportConnect(listner: (...args: []) => void) {
    return this.onEmitter.addListener(this.OnConnectSendTransPortEmit, listner);
  }
  public onRecvTransportConnect(listner: (...args: []) => void) {
    return this.onEmitter.addListener(this.OnConnectRecvTransPortEmit, listner);
  }
  public onNewProducer(
    listner: ({
      user_id,
      kind,
    }: {
      user_id: string;
      kind: "video" | "audio";
    }) => void
  ) {
    return this.onEmitter.addListener(this.OnNewProducerEmit, listner);
  }
  public onMediaPause(
    lisnter: (data: {
      user_id: string;
      kind: "video" | "audio";
      pause: boolean;
    }) => Promise<void>
  ) {
    return this.onEmitter.addListener(this.OnMediaPauseEmit, lisnter);
  }
  public onMediaClientDisconnect(
    listner: (data: { id: string }) => Promise<void>
  ) {
    return this.onEmitter.addListener(this.OnMediaClientDisconnect, listner);
  }
  public onOnOffProducer(listner: (data: any) => Promise<void>) {
    return this.onEmitter.addListener(this.OnOnOffProducer, listner);
  }
  public async getMediaRoomClients() {
    if (this.isConnected === false) {
      throw new Error("not connect[getAudioProducerIds]");
    }
    return await this.signalingClient.getMediaRoomClients();
  }

  public async changeProduceDevice(idInfo: any) {
    const myClient = this.getMyClient();
    alert("changeProduceDevice");
    // this.selectedAudioInputDeviceId = idInfo.audioInput;
    // this.selectedAudioOuputDeviceId = idInfo.audioOuput;
    // this.selectedVideoDeviceId = idInfo.video;
    // if (myClient) {
    //   const newConstraints = {
    //     audio: {
    //       deviceId: idInfo.audioInput
    //         ? { exact: idInfo.audioInput }
    //         : myClient.getAudioDevice().id,
    //     },
    //     video: {
    //       deviceId: idInfo.video
    //         ? { exact: idInfo.video }
    //         : myClient.getVideoDevice().id,
    //     },
    //   };
    //   await myClient.changeProduce(newConstraints, idInfo.audioOuput);
    // } else {
    // }
  }
  public getSelectedAudio() {
    const myClient = this.getMyClient();
    return myClient?.getAudioDevice();
  }
  public getSelectedVideo() {
    const myClient = this.getMyClient();
    return myClient?.getVideoDevice();
  }
  //   public getSelectedAudioInputDeviceId() {
  //     return this.selectedAudioInputDeviceId;
  //   }
  //   public getSelectedAudioOuputDeviceId() {
  //     return this.selectedAudioOuputDeviceId;
  //   }
  //   public getSelectedVideoDeviceId() {
  //     return this.selectedVideoDeviceId;
  //   }
  public getSocketId() {
    this.signalingClient.getSocketId();
  }
}

export const SignalingContext = React.createContext(
  new SignalingClient("wss://wss.wavle.center")
);
export const WavleClientContext = React.createContext(
  new WavleMediaClient("wss://wss.wavle.center")
);

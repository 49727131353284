import { rzlog, IsFail } from "./inc"
import './rznote.ui.css'
import { RzDlg, RzPos, RzRect, RzSizeDlg, RzUiLet, RzUiOpts } from './rzcmn.ui'
import { RzNote, RzPenAttr } from "./rznote.ui"
import { RzoNotePath } from "./rznote.ui.dto"
import { RzDrawRender, RzNoteView } from "./rznote.ui.draw"
import { RzDrawPen } from "./rznote.ui.edit"
import { FtUiContext } from "../ui/ftclass2/ftui.context"


/****************/
const rzIs = rzlog.makeDefs(true, false)
export const RzNoteUiEditLine_setDbg = rzIs.setDbg

let isDbgUi = true;
export function RzNoteUiEditLine_setDbgUi(b: boolean) {
    isDbgUi = b
}
const isDbgBorder = false;



/****************
 * 
 */
export class RzLinePen extends RzDrawPen {

    paths: RzoNotePath[] = []
    movePos: any = {}
    oldOpacity?: number;

    constructor(note: RzNoteView, type: string, pr: RzPenAttr) {
        super(note, type, pr)
        this.opacity = (type === 'marker') ? RzDrawPen.OPACITY_MARKER : RzDrawPen.OPACITY_PEN
        this.paths = []
    }//constructor

    setAttr(attr: RzPenAttr) {
        super.setAttr(attr)
        if (this.type === 'marker') {
            this.opacity = RzDrawPen.OPACITY_MARKER
        } else {
            this.opacity = RzDrawPen.OPACITY_PEN
        }
    }


    penInit(note: RzNote) {
        note.ctx?.save()

        this.oldOpacity = note.ctx?.globalAlpha

        if (this.type === 'marker') this.opacity = RzLinePen.OPACITY_MARKER
        else this.opacity = RzLinePen.OPACITY_PEN

        //if(note.ctx) note.ctx.globalAlpha= this.opacity;

        this.paths = []
    }

    bindPenAttrs(note: RzNote, p: RzPenAttr) {
        if (this.color) p.color = this.color
        if (this.width >= 0) p.width = this.width
        if (this.opacity >= 0) p.opacity = this.opacity
    }

    penDown(note: RzNote, e: MouseEvent) {
        if (rzIs.t) rzlog.trace('LinePen.penMove ');
        this.pressed = true

        let vpos = note.getPos(e);

        note.beginPen()
        note.pos = { drawable: true, ...vpos }
        let attr = {};
        this.bindPenAttrs(note, attr);
        //if(this.type==='marker'&& note.ctx)   note.ctx.globalAlpha=0.01;//RzLinePen.OPACITY_MARKER;

        let np = note.toNotePos(vpos.x, vpos.y)

        let p = {
            drawable: true, type: 'move', isBegin: true,
            x: np.x, y: np.y,
            attr: attr
        }
        let p2 = {
            drawable: true, type: 'line',
            x: np.x, y: np.y, x0: np.x, y0: np.y,
            attr: {}
        }

        if (rzIs.d) rzlog.debug('drawPen.down:pos=', note.pos)
        RzDrawRender.BindPenAttrs(note, note.ctx, p);

        this.paths.push(p)
        this.paths.push(p2)
        // note.ctx.lineCap='round'
        if (note.ctx) {
            note.ctx.beginPath()
            note.drawPen(note.ctx, p)
        }


        this.movePos = { x0: np.x, y0: np.y }
    }

    penMove(note: RzNote, e: MouseEvent) {
        if (rzIs.t) rzlog.trace('LinePen.penMove ');


        if (!this.pressed) return


        let vpos = note.getPos(e)
        let npos = note.toNotePos(vpos.x, vpos.y);
        if (rzIs.t) rzlog.trace('LinePen.penMove : tpos=', npos, ',e=', e)

        //@OLD
        // if(note.ctx) {
        //     note.ctx.beginPath();
        // }
        // if(this.paths.length>0){
        //     let ppos=this.paths[this.paths.length-1];
        //     note.ctx?.moveTo(ppos.x0||0,ppos.y0||0);
        // }
        // RzDrawRender.BindPenAttrs(note,note.ctx,p);
        // note.ctx?.lineTo( p.x0||0, p.y0||0);
        // note.ctx.stroke();
        // note.ctx.closePath();
        //@OLDEND


        let attr = {};
        this.bindPenAttrs(note, attr);

        let p = {
            drawable: true, type: 'line',
            ...npos, ...this.movePos,
            attr: attr
        }
        this.movePos = { x0: npos.x, y0: npos.y }
        note.drawPen(note.ctx, p);

        this.paths.push(p)
    }


    penUp(note: RzNote, e: MouseEvent) {
        rzlog.debug('LinePen.penUp: pressed=', this.pressed)
        if (this.pressed) {
            if (note.ctx) {
                note.ctx.closePath();
                note.ctx.stroke();
            }

            let p = { drawable: true, type: 'move', isEnd: true, }
            this.paths.push(p)
            note.drawPen(note.ctx, p)

            let points = []
            for (let i in this.paths) {
                let tp = this.paths[i]
                if (tp.isBegin) continue
                if (tp.isEnd) continue
                points.push({ x: tp.x, y: tp.y, x0: tp.x0, y0: tp.y0 })

            }


            //   let drawerId=note.getRepo()?.getDrawerId();
            let np = {
                drawable: true, type: 'line',
                points: points,

                attr: { opacity: note.ctx?.globalAlpha },
                //drawerId:drawerId
            };

            console.log('penup ---> ', np);
            this.bindPenAttrs(note, np.attr)
            note.addDrawPath(np)
            note.endPen()
            this.paths = []
            note.repaint()
        }
        this.pressed = false
    }

    penEnd(note: RzNote) {

        if (this.type === 'marker' && note.canvas) {
            note.canvas.style.cursor = 'crosshair'
        }
        note.ctx?.restore()
    }

}//class

/************ */
export class RzRectPen extends RzDrawPen {
    curSizeDlg?: RzSizeDlg

    penDown(note: RzNote, e: MouseEvent) {
        let p = note.getPos(e)
        if (this.curSizeDlg) {
            let dlg = this.curSizeDlg
            if (rzIs.d) rzlog.debug('drawing donw :!!! dlg=', dlg)

            this.curSizeDlg = undefined

            // note.addDrawPath({type:'box', x:note.pos.x,y:note.pos.y, 
            //                             width:this.width,color:this.color})
            let p2 = dlg.getBounds()
            let cp = note.transCanvasPos(p2)
            let np = note.toNotePos(cp.x, cp.y, cp.width, cp.height)
            let lsz = note.toNoteSize(this.width)
            let tp = {
                drawable: true, type: 'rect', x: np.x, y: np.y,
                w: np.width, h: np.height,
                attr: { width: lsz, color: this.color }
            }
            // let tp2={drawable:true,type:'rect', x:np.x+1,y:np.y+1,
            //         w:np.width-2,h:np.height-2, 
            //     attr:{ width:3,color:this.color} }                    
            if (rzIs.d) rzlog.debug('drawing box :!tp=', tp)


            note.drawPen(note.ctx, tp)

            note.addDrawPath(tp)


            dlg.setVisible(false)
            note.removeChild(dlg as RzUiLet)

        } else {
            let tdlg = new RzSizeDlg()
            //let tdlg=new RzEditSizeDlg()
            tdlg.setResizeBtnOn(true)

            tdlg.setParent(note as RzUiLet)
            tdlg.setBounds(p.x, p.y, 100, 100)
            tdlg.borderFocus = `1px dashed black`
            tdlg.borderDef = `1px dashed black`
            tdlg.init({
                isClosable: false,
                style: {
                    cursor: 'grab',
                    backgroundColor: 'transparent',//`rgba(0,0,0,0.1)`, 
                    border: `1px dashed black`
                },
            })


            this.curSizeDlg = tdlg

            if (note.ctx) {
                let vp = note.getPos(e)
                let np = note.toNotePos(vp.x, vp.y)
                note.ctx.beginPath()
                note.pos = { drawable: true, ...np }
                note.ctx.moveTo(np.x + 1, np.y + 1)
            }
        }

        if (rzIs.d) rzlog.debug('shape.down:p=', p)
    }

}//class


/************ */

export class RzShapePen extends RzDrawPen {
    curSizeDlg?: RzSizeDlg
    subType?: string

    setSubType(stype: string) {
        this.subType = stype;
    }

    penDown(note: RzNote, e: MouseEvent) {
        let p = note.getPos(e)
        if (this.curSizeDlg) {
            let dlg = this.curSizeDlg
            if (rzIs.d) rzlog.debug('drawing donw :!!! dlg=', dlg)

            this.curSizeDlg = undefined

            let p2 = dlg.getBounds()
            let cp = note.transCanvasPos(p2)
            let np = note.toNotePos(cp.x, cp.y, cp.width, cp.height)
            let lsz = note.toNoteSize(this.width)
            let tp = {
                drawable: true,
                type: this.subType,
                x: np.x, y: np.y,
                w: np.width, h: np.height,
                attr: { width: lsz, color: this.color }
            } as RzoNotePath;

            if (rzIs.d) rzlog.debug('drawing box :!tp=', tp)


            note.drawPen(note.ctx, tp)

            note.addDrawPath(tp)


            dlg.setVisible(false)
            note.removeChild(dlg as RzUiLet)

        } else {
            let tdlg = new RzSizeDlg()
            //let tdlg=new RzEditSizeDlg()
            tdlg.setResizeBtnOn(true)

            tdlg.setParent(note as RzUiLet)
            tdlg.setBounds(p.x, p.y, 100, 100)
            tdlg.borderFocus = `1px dashed black`
            tdlg.borderDef = `1px dashed black`
            tdlg.init({
                isClosable: false,
                style: {
                    cursor: 'grab',
                    backgroundColor: 'transparent',//`rgba(0,0,0,0.1)`, 
                    border: `1px dashed black`
                },
            })


            this.curSizeDlg = tdlg

            if (note.ctx) {
                let vp = note.getPos(e)
                let np = note.toNotePos(vp.x, vp.y)
                note.ctx.beginPath()
                note.pos = { drawable: true, ...np }
                note.ctx.moveTo(np.x + 1, np.y + 1)
            }
        }

        if (rzIs.d) rzlog.debug('shape.down:p=', p)
    }

}//class



/*** */
export class RzErasePen extends RzDrawPen {


    penInit = (note: RzNote) => {
        if (note.pos) note.pos.drawable = true
        if (rzIs.d) rzlog.debug('ErasePEn.on/init : note.pos=', note.pos, ',nt=', note)
    }

    async penMove(note: RzNote, e: MouseEvent) {
        if (!this.pressed) return

        if (rzIs.d) rzlog.trace('erase move : pressed=', this.pressed)
        let vpos = note.getPos(e)
        let npos = note.toNotePos(vpos.x, vpos.y)

        let r = await note.getPathAt(npos.x, npos.y, 4, 4, 'circle')
        //let r2 = await note.getAllPath();

        if (IsFail(r)) return
        if (!r.data) return

        let obj: RzoNotePath = r.data

        if (rzIs.d) rzlog.trace('erase move : pathAt.obj=', obj)
        if (!obj) {
            note.setFocusPath(null)
            note.renderPathAll()
            return
        }

        // const rect = {x : npos.x - ((obj?.attr?.width||0)/2) , y: npos.y - ((obj.attr?.width||0)/2), x1: npos.x + ((obj?.attr?.width||0)/2) 
        //         , y1: npos.y + ((obj.attr?.width||0)/2)};

        // const aa = obj.points?.filter((el,idx) => {  
        //     if( ((el.x||0) >= rect.x && (el.x||0) <= rect.x1 &&  (el.y||0) >= rect.y && (el.y||0) <= rect.y1 ) || ((el.x0||0) >= rect.x && (el.x0||0) <= rect.x1 &&  (el.y0||0) >= rect.y && (el.y0||0) <= rect.y1)) {
        //         return false;
        //     }
        //     return true;
        // });

        // const bb = obj.points?.filter((el,idx) => {  
        //     if( (el.x||0) >= rect.x && (el.x||0) <= rect.x1 &&  (el.y||0) >= rect.y && (el.y||0) <= rect.y1 ) {
        //         return true;
        //     }
        //     return false;
        // });

        //let bobj = {...obj};

        //obj.points = aa;
        //if(this.pressed && (bb||[]).length > 0){
        // if(this.pressed ){
        //     if( bobj?.points?.length !== aa?.length) {
        //         obj.drawable=true;
        //         let tp0={type:'erase',drawable:true, targetId: obj.id, target:bobj } as RzoNotePath;
        //         //console.log('chpoint --> ' , obj , bobj)
        //         note.putDrawPath(obj)

        //         note.addDrawPath(tp0);
        //         note.setFocusPath(null)
        //         note.repaint()
        //     }

        // }else {
        //     note.setFocusPath(obj)
        //     note.repaint()
        // }


        if (this.pressed) {            
            // obj.drawable = false
            // let tp={type:'erase',drawable:false, targetId: obj.id, target:obj }
            // note.addDrawPath(tp)
            note.putDrawPath({...obj, drawable:false})
            note.setFocusPath(null)
            note.repaint()
        } else {
            note.setFocusPath(obj)
            note.repaint()
        }

    }

    async penDown(note: RzNote, e: MouseEvent) {
        this.pressed = true
        rzlog.debug('erase down')
        let pos = note.getPos(e)
        note.pos = { drawable: true, ...pos }

        let r = await note.getPathAt(pos.x, pos.y, 1, 1, 'circle')
        if (IsFail(r) || !r.data) return;

        let obj: RzoNotePath = r.data

        // const rect = {x : pos.x - ((obj?.attr?.width||0)/2) , y: pos.y - ((obj.attr?.width||0)/2), x1: pos.x + ((obj?.attr?.width||0)/2) 
        // , y1: pos.y + ((obj.attr?.width||0)/2)};
        // const aa = obj.points?.filter((el) => { 

        //     if( (el.x||0) >= rect.x && (el.x||0) <= rect.x1 &&  (el.y||0) >= rect.y && (el.y||0) <= rect.y1 ) {
        //         return false;
        //     }
        //     return true;
        // });

        // const bb = obj.points?.filter((el,idx) => {  
        //     if( (el.x||0) >= rect.x && (el.x||0) <= rect.x1 &&  (el.y||0) >= rect.y && (el.y||0) <= rect.y1 ) {
        //         return true;
        //     }
        //     return false;
        // });


        // let bobj = {...obj};
        // obj.points = aa;

        // if( bobj?.points?.length !== aa?.length) {
        //     obj.drawable=true;
        //     let tp0={type:'erase',drawable:true, targetId: obj.id, target:bobj } as RzoNotePath;
        //     note.putDrawPath(obj)

        //     note.addDrawPath(tp0)
        // }

        // obj.drawable = false
        note.putDrawPath(obj)
        // let tp={type:'erase',drawable:false, targetId: obj.id,target:obj }
        // note.addDrawPath(tp)

        //   note.putDrawPath(obj)
        // let tp={type:'erase',drawable:false, targetId: obj.id,target:obj }
        // let tp={type:'erase',drawable:true, targetId: obj.id,target:obj }
        // note.addDrawPath(tp)

        note.setFocusPath(null)
        note.renderPathAll()
    }


    penUp(note: RzNote, e: MouseEvent) {
        this.pressed = false
        note.pos = { drawable: true, ...note.getPos(e) }
    }

}//class


/*******************************/

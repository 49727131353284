/**
 * FtClass Sample
 */

import { FtoNoteInfo } from "./dto/ftclass.dto";


 
let defPages=[
        {  noteId:'1', type:'book',  title:'Test',            pageNo:0,  selected:true,  visible:true},
        {  noteId:'2', type:'book',  title:'한글과컴퓨터',     pageNo:0, selected:false,  visible:true},
        {  noteId:'3', type:'book',  title:'한글과 컴퓨터2',   pageNo:0, selected:false,  visible:true},
        {  noteId:'3', type:'book',  title:'한글과 컴퓨터3',   pageNo:0, selected:false,  visible:true},
        {  noteId:'3', type:'book',  title:'한글과 컴퓨터4',   pageNo:0, selected:false,  visible:true},
        {  noteId:'3', type:'book',  title:'한글과 컴퓨터5',   pageNo:0, selected:false,  visible:true},
        {  noteId:'3', type:'book',  title:'한글과 컴퓨터6',   pageNo:0, selected:false,  visible:true},
    ];

//FtDeskUiTop_setBookTestOn
export function FtClassSamp_getDefPages(){
    return defPages
}

let defBooks=[  new FtoNoteInfo({title:'test1', noteId:'b1',type:'book'}),
                new FtoNoteInfo({title:'test2', noteId:'b2',type:'book'}),
                new FtoNoteInfo({title:'test3', noteId:'b3',type:'book'}),
                new FtoNoteInfo({title:'test4', noteId:'b4',type:'book'})    
            ];
export function FtClassSamp_getDefBooks(){ return defBooks }


/************************
 * 
 */
 export function FtClassSamp_getRepo(){
    return null
}

/********** */
export function FtClassSamp_getRepoMng(){
    return null
}


/************* */

const defNoteInfos=[
    new FtoNoteInfo({noteId:'1', title:'귀가 트이는 영어1/출판사'}),
    new FtoNoteInfo({noteId:'2', title:'귀가 트이는 영어2/출판사'}),
    new FtoNoteInfo({noteId:'3', title:'귀가 트이는 영어3/출판사'}),
    new FtoNoteInfo({noteId:'4', title:'귀가 트이는 영어4/출판사'}),
]
export function FtClassSamp_getNoteInfos4Dlg(){
    return defNoteInfos;
}



import { FT_LAYER_EDIT, FT_LAYER_TOOL, FT_LAYER_VIDEO } from "../../consts";
import { FtoEditLock, FtoEtcSetting, FtoSetting, FtoUser, FtoUserTypeEnum } from "../../dto/ftclass.dto";
import { FtEditLockTool, FtPermsTool } from "../../ftclass.perms";

import { DeviceClient, FtVideoLet, MsClient, SignalingClient } from "./ftclass.ui.side"
import { rzlog, RzUiOpts ,RzBtnLet, RzUiLet,RzDragBtn,RzDlg,RzOnFn,toCommaNumber,OnFn} from "../../inc"
import { RzRes, NewOk, RzEventNotier} from '../../inc'

const rzIs=rzlog.makeDefs(true)
export const FtClassUiPop_setDbg=rzIs.setDbg;
/*******************************
 * 
 */

 export class FtPopMenuEnum{
    static VIDEO='video'
    static AUDIO='audio'
    static LOCK='lock'

    static VIDEO2='vidoe2'
    static AUDIO2='audio2'
    static LOCK2='lock2'

    static CHAT='chat'
    static NOTE='note'
    static SETTING='setting'
    static ETC='etc'
    static EXIT='exit'

}

export class FtPopMenuEvent {
    cmd?:string
    selected=false
    constructor(pr:any){
        Object.assign(this,pr)
    }
}
/****************************
 * 
 */
function sleep(ms:number) {
    return new Promise((r) => setTimeout(r, ms));
}

/*********************************
 *  FtClassPop
 ********************************/
export class FtClassPop extends RzDragBtn {
    signalingClient?:any;
    msClient?:any;
    cousumer:any[] = [];
    teacherVideo?:any;
    studentVideo?:any;
    isVideoPause:boolean = false;
    isAudioPause:boolean = false;
    roomId?:string;
    type?:string;
    user_id?:string;
    kind?:string;
    isSingle:boolean=false;
    topHeight:number=0;
    
    hasCloseBtn:boolean=false;
    topTitle?:string;

    constructor(opt?:any) {
        super(opt)
        this.signalingClient = new SignalingClient();
        this.msClient = new MsClient();
        
        if(opt?.isSingle!==undefined) this.isSingle=opt.isSingle;
        if(opt?.topHeight!==undefined) this.topHeight=opt.topHeight;
        if(opt?.hasCloseBtn!==undefined) this.hasCloseBtn=opt.hasCloseBtn;

        this.x = opt?.x || 260 
        this.y = opt?.y ||300
        this.width =opt?.width || 480
        this.height = opt?.height ||220

        this.signalingClient.onConnect(async () => {
            this.signalingClient.getVideoProducerIds().then(async (ids:string[]) => {

                const other = ids.filter(id => id !== this.signalingClient.getNickName())
                const newC : any[]= [];
                if (this.cousumer.length >= 2) {
                    return;
                }
                other.forEach( (id:string) => {
                    const check = this.cousumer.some(cId => cId.nickName === id);
                    if (!check) {
                        newC.push(id)
                    }
                })
                if (newC.length > 0) {
                    this.cousumer = [...this.cousumer, ...newC.map(item => ({ nickName: item, isVideo: !this.isVisible, isAudio: !this.isAudioPause }))]
                    await sleep(1000);
                    const stduentClient = new DeviceClient(this.msClient, this.roomId!, newC[0], this.signalingClient, false);
                    this.studentVideo.setDevice(stduentClient)
                }
            })
            const data = this.signalingClient.getRtpData();
            //on connect..
            await this.msClient.init(data)
            const producerTransInfo = await this.signalingClient.createTransport(
                {
                    sctpCapabilities: this.msClient.device.sctpCapabilities,
                    rtpCapabilities: this.msClient.device.rtpCapabilities,
                    type: "producer",
                    // type:"consumer",
                    forceTcp: false,
                });
            const consumerTransInfo = await this.signalingClient.createTransport(
                {
                    sctpCapabilities: this.msClient.device.sctpCapabilities,
                    rtpCapabilities: this.msClient.device.rtpCapabilities,
                    type: "consumer",
                    // type:"consumer",
                    forceTcp: false,
                });
            await this.msClient.createSendTransport(producerTransInfo.params)
            await this.msClient.createRecvTransport(consumerTransInfo.params)

            await sleep(1000);
            const teacherClient = new DeviceClient(this.msClient, this.roomId!, this.user_id!, this.signalingClient, true);
            this.teacherVideo.setDevice(teacherClient)
        })

        if(this.isSingle) return ;

        this.signalingClient.onMediaClientDisconnect(async (id:string) => {
            this.studentVideo.disconnect();
        })

        this.signalingClient.onNewProducer(async (e:any) => {
            let { user_id, kind }=e

            if (this.cousumer.length >= 2) {
                return;
            }
            const target = this.cousumer.find(item => item.nickName === user_id);

            if (target === undefined) {
                this.cousumer = [...this.cousumer, { nickName: user_id, isVideo: kind === "video", isAudio: kind === "audio" }]
            } else {
                switch (kind) {
                    case "video":
                        target.isVideo = true
                        break;
                    case "audio":
                        target.isAudio = true
                        break;
                    default: 
                        target.isVideo = false
                        break;
                }
                const oth = this.cousumer.filter(item => item.nickName !== user_id);
                this.cousumer = [...oth, target]
                // //상대방 접속.
                // let video2 = new FtVideoLet()
                // video2.setParent(this)
                // video2.setType('student')
                // video2.init({ cssText: 'width:100%; height:30%; background-color:wheat; display:flex;  align-items:center;justify-content:center;' })
                // await sleep(1000);
                const stduentClient = new DeviceClient(this.msClient, this.roomId!, user_id, this.signalingClient, false);
                this.studentVideo.setDevice(stduentClient)
            }
        })
    }

    onPopupMenu(e:FtPopMenuEvent){
        console.log("YDisa","pop event!!!!!");
        if(!this.isVisible()) return 

        if(e.cmd===FtPopMenuEnum.AUDIO){
            let selected = e.selected
            this.audioPause(selected)
        } else if (e.cmd === FtPopMenuEnum.VIDEO) {
            let selected = e.selected
            this.videoPause(selected);
        }else  if(e.cmd===FtPopMenuEnum.LOCK){
            let selected=e.selected

        }
    }

    doPreOpts(opt:RzUiOpts) {
        let topt = super.doPreOpts(opt)
        let css2=`display:flex; flex-direction:row;
            z-index:${FT_LAYER_VIDEO}; align-items:end; `
        let css1=`display:flex; flex-direction:column;
            padding:2px;
            z-index:${FT_LAYER_VIDEO}; background-color:#656565;  `

        let css=this.isSingle?css1:css2;
        return { ...topt, className: 'ftclass_pop', 
            cssText: (topt?.cssText ||'')
                +   css}
    }

    async view(){
        const teacherClient = this.signalingClient.getDevice("teacher") ?? new DeviceClient(this.msClient, this.roomId!, this.user_id!, this.signalingClient, true);
        this.teacherVideo.setDevice(teacherClient)

        if(this.isSingle) return;

        const studentDevice = this.signalingClient.getDevice(FtoUserTypeEnum.STUDENT);
        if (studentDevice === null) {
            this.signalingClient.getVideoProducerIds().then(async (ids:string[]) => {
                const other = ids.filter(id => id !== this.signalingClient.getNickName());
                if (other.length > 0) {
                    const stduentClient = new DeviceClient(this.msClient, this.roomId!, other[0], this.signalingClient, false);
                    this.studentVideo.setDevice(stduentClient)
                }
            });
        } else {
            this.studentVideo.setDevice(studentDevice)
        }
    }

    doMouseDown(evt: MouseEvent): void {
        if(rzIs.d)rzlog.debug("VideoPop.MouseDown:e=",evt)
        evt.preventDefault()
    }

    onClose?:()=>void;
    doClose(){
        if(this.onClose) this.onClose()
    }


    doInit(opt:RzUiOpts) {
        
        let r=this.parent!.getBounds()
        if(rzIs.d)rzlog.debug("FtClassPop.doInit : r=",r,',opt=',opt)
        this.nativeDiv!.style.top=(r.height-300)+'px'



        if(this.isSingle && this.hasCloseBtn){
          //  this.onMouseDown=(e)=>{this.doMouseDown(e)}
            let bt=new RzBtnLet();
            bt.setTitle('x')
            
            bt.init({parent:this,
                className:'vidtop_cls_btn',
                cssText:`align-self:flex-end;
                    border-width:0px;
                    width:30px;height:30px;
                    background-color:transparent;
                    color:white; font-weight:600;
                 `,
                onClick:(e)=>{this.doClose()}
            })
 
        }

        let vid1CssText=`width:100%; height:${this.isSingle?'90':'95'}%; 
            cursor:default;background-color:black; 
            display:flex; align-items:center; 
            justify-content:center; position:relative;`
        let video1 = new FtVideoLet()
        video1.setParent(this)
        if(this.isSingle) video1.hasDeco=false;

        video1.init({ 
            cssText: vid1CssText })
        this.teacherVideo = video1;
        
        if(this.isSingle) return true;
        

        let video2 = new FtVideoLet()
        video2.setParent(this)
        video2.setType(FtoUserTypeEnum.STUDENT)
        video2.init({ cssText: 'width:100%; height:95%; cursor:default;background-color:black; display:flex;  align-items:center;justify-content:center; position:relative;' })
        this.studentVideo = video2;
        

        return true
    }

    audioPause(selected:boolean) {
        this.isAudioPause = selected;
        if (!this.signalingClient.isConnected) {
            return;
        }
        if (selected === true) {
            this.signalingClient.producerPause("audio")
                .then(() => {
                    if (this.type !== FtoUserTypeEnum.STUDENT) {
                        this.teacherVideo.audioPause(selected);
                    } else {
                        if(this.isSingle) return;

                        this.studentVideo.audioPause(selected)
                    }
                })
        } else {
            this.signalingClient.producerResume("audio")
                .then(() => {
                    if (this.type !== FtoUserTypeEnum.STUDENT) {
                        this.teacherVideo.audioPause(selected);
                    } else {
                        if(this.isSingle) return;

                        this.studentVideo.audioPause(selected)
                    }
                })
        }

    }

    videoPause(selected:boolean) {
        this.isVideoPause = selected;
        if (!this.signalingClient.isConnected) {
            return;
        }
        if (selected === true) {
            this.signalingClient.producerPause("video")
                .then( ( e:any ) => {
                    if (this.type !== FtoUserTypeEnum.STUDENT) {
                        this.teacherVideo.videoPause(selected);
                    } else {
                        if(this.isSingle) return;

                        this.studentVideo.videoPause(selected)
                    }
                })
        } else {
            this.signalingClient.producerResume("video")
                .then( (e:any) => {
                    if (this.type !== FtoUserTypeEnum.STUDENT) {
                        this.teacherVideo.videoPause(selected);
                    } else {
                        if(this.isSingle) return;
                        this.studentVideo.videoPause(selected)
                    }
                })
        }

    }



    onVisible=(b:boolean)=>{
        if(rzIs.d)rzlog.debug('POP visible : ~~~!!!!!!!!!!!!!!!!!!!!!!!! : b=',b)        
        if (b === true) {
            this.view();
        }
    }
     
}//class

/*********************************/


export class FtTimePopup extends RzDragBtn {

    doPreOpts(opt:RzUiOpts) {
        let topt = super.doPreOpts(opt)

        let css=`display:flex; flex-direction:row;
        position:absolute; width:180px;height:30px;
        z-index:${FT_LAYER_TOOL}; 
        background: rgba(72, 72, 72, 0.5);
        box-shadow: 0px 0px 10px rgba(121, 121, 121, 0.4);
        border-radius: 30px;`
    
        return { ...topt, className: 'ftclass_timepop', 
                cssText: opt.cssText + css }
    }

    doLayout(e:any){
        let left=(e.width - 180)/2
        let top=e.height - 30*2        
        this.nativeDiv!.style.left=left+'px'
        this.nativeDiv!.style.top=top+'px'
    }

    onResize=(e:any)=>{
        this.doLayout(e)
    }
    
    doInit(opt:RzUiOpts) {
 

        let curLet=new RzUiLet()
        curLet.setParent(this)
        curLet.init({cssText:`
            margin-left:17px;
            width: 46px;
            height: 27px;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 27px;
            text-align: center;

            margin-right:17px;
            
            color: #FFFFFF;`,innerHTML:'TIME'})
 
        let totalLet=new RzUiLet()
        totalLet.setParent(this)
        totalLet.init({cssText:`
                width: 80px;
                height: 27px;
                
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 27px;
                text-align: center;
                
                color: #FFFFFF;`,innerHTML:'00:00:00'})

        return true
    }
}//class


export interface FtPageNoRepo {
    getTotal(): number;
    getCurPageNo(): number;
    setCurPageNo(pg:number):void;
    getUserInfo(): FtoUser |undefined;
    getEditLock():FtoEditLock|undefined;
//    getEtcSetting():FtoEtcSetting|undefined;

    addOnChanged(fn:RzOnFn,pr?:any):void;
    delOnChanged(fn:RzOnFn):void;
}


export class FtPageNoBasRepo implements FtPageNoRepo {
    getUserInfo(): FtoUser | undefined {
       return undefined;
    }
    getEditLock(): FtoEditLock | undefined {
        return undefined;
    }
 

    curPage:number=0;
    totalPage:number=10;

    getTotal() {
      return this.totalPage;
    }

    getCurPageNo() {
        return this.curPage
    }

    setCurPageNo(pg: number) {
        this.curPage=pg;
    }

    events:OnFn[]=[];
    addOnChanged(fn: RzOnFn, pr?: any): void {
        RzEventNotier.AddOnEvent(this.events,fn,pr)    
    }
    delOnChanged(fn: RzOnFn): void {
        RzEventNotier.DelOnEvent(this.events,fn)
    }

    doNotiChanged(e:any){
        RzEventNotier.NotiEvent(this.events,e)
    }
}

/**********
 * 
 */
export class FtPageNoPopup extends RzDragBtn {
    fontStyle=`font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: center;

    margin-right:17px;
    
    `
    repo?: FtPageNoRepo 

    totalLet?:RzUiLet;
    editLet?:RzUiLet;
    curPgBtn?:RzBtnLet;

    total?:number;
    curPage?:number;

    constructor(){
        super();
        this.setPageRepo( new FtPageNoBasRepo());
    }


    onRepoChanged=async (e:any)=>{
        rzlog.debug("FtClassUiPop.FtPageNoPopup.onRepoChanged :e=",e)
        
        this.doBindRepo()
        this.doUpdateUi()
    }

    setPageRepo(repo?:FtPageNoRepo){
        if(this.repo) this.repo.delOnChanged(this.onRepoChanged)
        this.repo=repo;
        this.repo?.addOnChanged(this.onRepoChanged)
        this.doBindRepo()    

        if(this.nativeDiv)this.doUpdateUi()
    }

    doBindRepo(){
        this.curPage=this.repo!.getCurPageNo()
        this.total=this.repo!.getTotal()
    }


      doUpdateUi(){
        let cpg= this.curPage||0
        let tpg= this.total||0
        rzlog.debug("FtClassUiPop.FtPageNoPopup.doUpdateUi : cpg=",cpg)

        if(this.curPgBtn?.nativeDiv) (this.curPgBtn.nativeDiv as HTMLButtonElement).innerText=toCommaNumber(cpg)
        if(this.editLet?.nativeDiv) (this.editLet.nativeDiv as HTMLInputElement).innerText=cpg.toString()
        if(this.totalLet?.nativeDiv) (this.totalLet.nativeDiv as HTMLElement).innerText=toCommaNumber(tpg)
    }


    doPreOpts(opt:RzUiOpts) {
        let topt = super.doPreOpts(opt)
        let css=`display:flex; flex-direction:row;
                position:absolute; height:30px;
                right:30px;
                z-index:${FT_LAYER_EDIT}; 
                background: rgba(72, 72, 72, 0.5);
                box-shadow: 0px 0px 10px rgba(121, 121, 121, 0.4);
                border-radius: 30px;`
        return { ...topt, className: 'ftclass_timepop', 
                cssText: opt.cssText + css }
    }

    
    doInit(opt:RzUiOpts) {
        this.doInitLeft()
        this.doInitCurPg()
        this.doInitSplit()
        this.doInitTotal()
        this.doInitRight()
        return true
    }

    doInitLeft(){
        let css0=`
        margin-left:12px;
        width: 16px;
        height: 27px;
        ${this.fontStyle}
        `

        let leftLet=new RzUiLet()
        leftLet.setParent(this)
        leftLet.onClick=()=>{this.doGoLeft()}
        leftLet.init({cssText:css0,innerHTML:'<i class="ft-pg-left">'})
    }

    doIsEditable(){
        let r=true;

        let ui=this.repo!.getUserInfo()
        let el=this.repo!.getEditLock();
        if(ui && el ){
            r=FtEditLockTool.isEditable(ui,el)
        }
        return r;
    }


    async doGoLeft(){
        if(rzIs.d)rzlog.debug('goLeft')
        if(!this.repo){
            rzlog.info("FtPagePop.doGoLeft : no repo")
            return;
        } 

        if(!this.doIsEditable()){
            return;
        }

        let pg=  this.curPage!;
        
        if(pg-1>0) {
            pg--
            this.curPage=pg;
            this.repo.setCurPageNo(pg)
            this.doUpdateUi();
           
        }
    }

 
    doGoRight(){
        if(rzIs.d)rzlog.debug('goRight : curPg=',this.curPage,',tpag=',this.total,',repo=',this.repo)
        if(!this.repo){
            rzlog.info("FtPagePop.doGoLeft : no repo")
            return;
        } 

        if(!this.doIsEditable()){
            return;
        }

        let pg=this.curPage!
        let tpg=this.total!

        if(pg+1<=tpg) {
            pg++;
            this.curPage=pg;
            this.repo.setCurPageNo(pg)
            if(rzIs.d)rzlog.debug('goRight.2 : curPg=',this.curPage,',tpag=',this.total,',repo=',this.repo)            

            this.doUpdateUi();
        }
    }


    doGoPage(pg:number){
        if(rzIs.d)rzlog.debug('FtClassUiPop.FtPageNoPopup.goPage : pg=',pg)
        if(!this.repo) return;


        let cur=this.curPage!
        let tpg=this.total!
        if(pg==cur) return;
        
        if(pg>0 && pg<=tpg) {
            this.curPage=pg
            this.repo.setCurPageNo(pg)
            this.doUpdateUi();
        
        }
    }


    doInitCurPg(){

        let curPg=this.curPage!;

        let css1=`
        width: 32px; height: 27px;  
        border-width:0; color:#0037FC;
        background-color:transparent; 
        ${this.fontStyle}`

        let curLet=new RzBtnLet()
        curLet.setParent(this)
        curLet.setTitle( toCommaNumber(curPg))
        curLet.onClick=()=>{

            this.doStartEdit()
        }
        curLet.init({cssText:css1})        

        this.curPgBtn=curLet;
    }



    doStartEdit(){
        if(rzIs.d)rzlog.debug("StartEdit");

        if(!this.doIsEditable()){
            rzlog.debug('PageNoCtrl : no editable')
            return;
        } 
        

        let curPg=this.curPage!;

        let ix=this.indexOfChild(this.curPgBtn!)
        this.removeChild(this.curPgBtn!)

        let edit=new RzUiLet()
        let html=toCommaNumber(curPg)
        edit.init({
                tag:'input', tagType:'text',
                cssText:`position:relative; border-width:0px;
                        top:0px;left:0px;margin-top:2px;
                        margin-right:12px;
                        width: 24px; height: 23px; `,
                innerHTML:html
         })
 
         edit.nativeDiv!.onmouseleave=()=>{
            if(rzIs.d)rzlog.debug('mouseleave')
            

            this.doReBtn()
         }
         edit.nativeDiv!.onkeydown=(e)=>{
            if(!(e.key>='0' &&e.key<='9') ){
                e.preventDefault()
            }
         }

         this.addChildAt(ix,edit)
         if(edit.nativeDiv){
            edit.nativeDiv.focus()
            if(rzIs.d) rzlog.debug('Focus!!!!')
         } 
         this.editLet=edit;
    }



    doReBtn(){
        if(! this.editLet) return;
        let updated=false
        let val=(this.editLet.nativeDiv as HTMLInputElement).value;
        let curPg=this.curPage!;
        if(val){
            if(rzIs.d)rzlog.debug("FtClassUiPop.PageNo : New Val=",val)
                
            let nv=parseInt(val!)
            if(!isNaN(nv)){

                if(nv!==curPg && nv <= this.total!){
                    this.curPgBtn!.setTitle(toCommaNumber(nv))
                    this.curPage=curPg;
                    curPg=nv;
                    updated=true
                }
            }
        }

        let ix= this.indexOfChild(this.editLet);
        
        if(ix>=0) this.removeChild(this.editLet);
        

        if(this.curPgBtn && ix>=0) this.addChildAt(ix,this.curPgBtn);

        if(updated)  this.doGoPage(curPg)

    }


    doInitSplit(){
        let splitLet=new RzUiLet()
        let css3x2=`
        width: 16px;
        height: 27px;
        color:#FFFFFF;
        ${this.fontStyle}`

        splitLet.setParent(this)
        splitLet.init({cssText:css3x2,innerHTML:'/'})
    }

    doInitTotal(){

        let total=this.total! //this.repo?.getTotal()||0
        let totalLet=new RzUiLet()
        let css3=`width:70px; height: 27px;
            color: #FFFFFF;
            ${this.fontStyle}`
        let html=toCommaNumber(total)
        totalLet.setParent(this)
        totalLet.init({cssText:css3,innerHTML:html}) 
        this.totalLet=totalLet;       
    }

    doInitRight(){
        let rightLet=new RzUiLet()
        let css4=`
        width: 16px;
        height: 27px;
        margin-right: 10px;
        ${this.fontStyle}`

        rightLet.setParent(this)
        rightLet.onClick=()=>{
            this.doGoRight()
        }
        rightLet.init({cssText:css4,innerHTML:'<i class="ft-pg-right"/>'})        
    }

}//class


/**********
 *  
 */

export class FtMenuBtn  extends RzBtnLet {
 
    selected:boolean=true

    setSelected(b:boolean){
        this.selected=b
    }


    doPreOpts(opt:RzUiOpts) {
        let topt = super.doPreOpts(opt)
        return { ...topt, cssText:opt.cssText||'',
            className: 'ftclass_menu'+(this.selected?' ftclass_menu_selected':''), 
                innerHTML:`<i class="ftnote-menu-icon${this.selected?' ftclass_menu_selected':''}"></i>`}
    }



    doInit(opt:RzUiOpts) {
        this.nativeDiv!.onclick=(e)=>{ if(this.onClick)this.onClick(e)}
        let r=this.parent!.getBounds()
        // let left =   (window.outerWidth - 50*2)
        // let top =  (window.outerHeight - 50*2 )
        
        this.doLayout({width:r.width,height:r.height})
        return true
    }

    onResize=(e:any)=>{
        this.doLayout(e)
    }

    doLayout(e:any){
        rzlog.debug('MenuBtn.doLayout : e=',e)
        let left=e.width - 50 -30
        let top=e.height - 50 -30

        this.nativeDiv!.style.left=left+'px'
        this.nativeDiv!.style.top=top+'px'
    }

}//class




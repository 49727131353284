import { FtGetConnInfo } from "../../../config/consts";
import { FtClassClient } from "../../ftclass.client";
import { FtClassWsClient } from "../../ftclass.client.ws";
import { rzlog, RzUiLet ,RzUiOpts } from "../../inc";
import { RzBtnLet, RzInputLet ,RzDlg} from "../../inc";

const rzIs=rzlog.makeDefs()
export const FtClassUiToolClient_setDbg=rzIs.setDbg;
const isUnit=false;

/**
 * FtClassClientLogDlg
 */
export class FtClassClientLogDlg extends RzDlg {
    
    wsclient?:FtClassWsClient
    logPanel?:RzUiLet
    logs:any[]=[]
    classId?:string;

    doPreOpts(opt:RzUiOpts){
        let nopt=super.doPreOpts(opt)
        let cssText=`position:absolute; 
                top:84px; width:600px; height:400px;border:solid; 
                border-width:1px; border-color:black;background-color:white;  
                display:flex; flex-direction:row;  
                z-index:99;`
        return {...nopt,cssText:cssText}
    }

    showLogs(){
        if(!this.logPanel) return
        this.logPanel.removeChildAll()
        let l=this.logs.length<10? 0: (this.logs.length -10)
        for(let i=l ; i<this.logs.length;i++){
            let el=new RzUiLet()
            el.init({parent:this.logPanel,innerHTML:`<span style="overflow-wrap:break-word;">${i}:${this.logs[i]}</span>`})
        }
    }   

    addLog(log:any){
        this.logs.push(log)
        this.showLogs()
    }

    doInit(opt:RzUiOpts){

        let panel=new RzUiLet()
            panel.init({parent:this,cssText:'width:100%;height:100%;display:flex;flex-direction:column;'})
        
         let topPanel=new RzUiLet()
             topPanel.init({parent:panel,
                cssText:`width:100%; flex-grow:1; 
                    display:flex; flex-direction:row;
                    border:solid; border-width:1px; border-color:darkgray; background-color:lightgray;`})

         let topSubPanel1=new RzUiLet()
            topSubPanel1.init({parent:topPanel,cssText:'width:80%; height:100%; border:solid; border-width:1px; border-color:darkgray; background-color:lightgray;'})
            this.logPanel=topSubPanel1

         let topSubPanel2=new RzUiLet()
            topSubPanel2.init({parent:topPanel,cssText:`width:20%; height:100%; b
                            display:flex; flex-direction:column;
                            order:solid; border-width:1px; border-color:darkgray; background-color:lightgray;`})


          let btmPanel=new RzUiLet()
              btmPanel.init({parent:panel,cssText:'height:28px; width:100%; display:flex; flex-direction:row;'})

             let inEl1=new RzInputLet({parent:btmPanel,cssText:'width:70%;' })
                 inEl1.init()

            let inEl11=new RzBtnLet({parent:btmPanel,cssText:'width:10%;' })
                inEl11.setTitle('Conn')
                inEl11.onClick=async ()=>{
                    if(this.wsclient && this.wsclient.isConnected()){
                        this.wsclient.close()
                        inEl11.setTitle('Conn')
                        return
                    }
                    let wsclient=new FtClassWsClient()
                  
                     let connInfo1 = FtGetConnInfo()
 
                    wsclient.setConnInfo(connInfo1)
                    //client.setToken('token')
                    await wsclient.connect()
                    inEl11.setTitle('Close')

                    this.wsclient=wsclient
                    

                    if(rzIs.d) rzlog.debug('RzBtn.11 : title=', inEl11.getTitle())
                }

                inEl11.init()
            /******************
             * 
             */
            let btns=[
                {id:'hello',        title:'Hello'},
                {id:'onPong',       title:'onPong'}, 
                {id:'ping',         title:'Ping',},
                {id:'join',         title:'Join'},
                {id:'onEvent',      title:'onEvent'},
                {id:'classId',      value:"r1", type:'input'},
                {id:'newClass',     title:'NewClass', },
                {id:'getClass',     title:'GetClass'},
                {id:'delClass',     title:'DelClass'},
                {id:'bookId',       value:"b1", type:'input'},
                {id:'addBook',      title:'AddBook'},
                {id:'bookPdf',      type:'file'},
                {id:'addBookPdf',   title:'AddPdf'},
             ]
            
            btns.forEach(t=>{
                let inEl2:RzBtnLet|RzUiLet|null=null;
                if(t.type==='file'){
                    inEl2=new RzUiLet({parent:topSubPanel2,
                        id:t.id,
                        tag:"input",
                        tagType:'file',
                        cssText:'width:94%;' });
                } else if(t.type==='input'){
                            inEl2=new RzUiLet({parent:topSubPanel2,
                                id:t.id,
                                tag:"input",
                                cssText:'width:94%;' });
                } else {
                    inEl2=new RzBtnLet({parent:topSubPanel2,cssText:'width:100%;'});
                    (inEl2 as RzBtnLet).setTitle(t.title!);
                } 

                
                (inEl2 as any).tid=t.id;
                
                inEl2.onClick=async (e:Event)=>{
                    if(rzIs.d) rzlog.debug('RzBtn : el=', inEl2)
                    this.procOnClick((inEl2 as any).tid, e)
                }
                inEl2.init()

            })

        return true

    }


   async procOnClick(id:string, e:Event){
        if(this.wsclient==null){
            this.addLog('not connected');
            return;  
        } 
        

        if(id==='hello'){
            let tr1 = await this.wsclient!.getHello()
            if(rzIs.d) rzlog.debug('RzBtn : getHello=', tr1)
            this.addLog(tr1)
        } else if(id==='onPong'){
            let tr1 = await this.wsclient!.onPong((dt:any)=>{
                this.addLog(dt)
            },(er)=>{})
            if(rzIs.d) rzlog.debug('RzBtn : pong=', tr1)
            this.addLog('start onPoing')
        } else if(id==='ping'){
            let tr1 = await this.wsclient!.ping()
            if(rzIs.d) rzlog.debug('RzBtn : pong=', tr1)
            this.addLog('ping > '+tr1)           
        } else if(id==='onEvent'){
            let tr1 = await this.wsclient!.subscribClassEvent(
                this.classId!,
                (dt:any)=>{
                if(dt.status>0){
                    this.addLog(dt.message)    
                }
                let ts=JSON.stringify(dt.data)
                this.addLog(ts)
            },(er)=>{})
            if(rzIs.d) rzlog.debug('RzBtn : classEvent=', tr1)
           // this.addLog(tr1)
        } else if(id==='join'){

            if(!this.wsclient?.isConnected()) { this.wsclient?.connect(); }

            let tr1=await this.wsclient!.fetchJoinClass({classId:'1', username:'test01', userType:'teacher', devType:'pc'})
            if(tr1.status>0) return
            this.addLog("join > " + tr1.data!)

        } else if (id==='newClass'){
            this.doNewClass()
        } else if (id==='getClass'){
            this.doGetClass()
        } else if (id==='delClass'){
            this.doDelClass()
        } else if (id==='addBook'){
            this.doAddBook()
        } else if (id==='addBookPdf'){
            this.doAddBookPdf()
        }
    }

    getClassId(){
        let el=document.getElementById('classId') as HTMLInputElement
        let clzId=el?.value;
        return clzId
    }

    getBookId(){
        let el=document.getElementById('bookId') as HTMLInputElement
        let clzId=el?.value;
        return clzId
    }


    async doNewClass(){
        let clzId=this.getClassId();

        if(isUnit) {
            let tr1 = await this.wsclient!.getHello()
            if(rzIs.d) rzlog.debug('RzBtn : doNewClass=', tr1,',classId.val=',clzId)
        }
        else {
            let pr={}
            let tr1 = await this.wsclient!.addClass(pr)
            if(rzIs.d) rzlog.debug('RzBtn : doNewClass=', tr1,',classId.val=',clzId)

        }

        this.addLog('RzBtn : doNewClass ='+clzId)
    }

    async doGetClass(){
        let clzId=this.getClassId();

        if(isUnit) {
            let tr1 = await this.wsclient!.getHello()
            if(rzIs.d) rzlog.debug('RzBtn : doGetClass=', tr1)
        }
        else {
            let tr1 = await this.wsclient!.fetchGetClassInfo(clzId)
            if(rzIs.d) rzlog.debug('RzBtn : doGetClass=', tr1,',classId.val=',clzId)

        }

        this.addLog('RzBtn : doGetClass ='+clzId)
    }

    async doDelClass(){
        let clzId=this.getClassId();
        if(isUnit) {
            let tr1 = await this.wsclient!.getHello()
            if(rzIs.d) rzlog.debug('RzBtn : doDelClass=', tr1)
        } else {
            let pr={classId:clzId}
            let tr1 = await this.wsclient!.delClass(pr);
            if(rzIs.d) rzlog.debug('RzBtn : doDelClass=', tr1,',classId.val=',clzId)
        }
        this.addLog('RzBtn : doDelClass ='+clzId)
    }

    async doAddBook(){
        let bookId=this.getBookId();
        
        if(isUnit) {
            let tr1 = await this.wsclient!.getHello()
            if(rzIs.d) rzlog.debug('RzBtn : doAddBook > bookId= ',bookId,', doAddBook=', tr1)
        
        } else {
            let pr={title:bookId, }
            let tr1 = await this.wsclient!.addBook(pr);
            if(rzIs.d) rzlog.debug('RzBtn : doAddBook=', tr1,',bookId.val=',bookId)
        }

        this.addLog('doAddBook : '+bookId)
    }

    async doAddBookPdf(){
        let bookId=this.getBookId();
        let msg='doAddBookPdf : '+bookId;

        if(isUnit) {
            let tr1 = await this.wsclient!.getHello()
            if(rzIs.d) rzlog.debug('RzBtn : doAddBookPdf > doAddBookPdf=', tr1)
        } else {
            let pr={title:bookId, }
            
            //if(rzIs.d) rzlog.debug('RzBtn : doAddBook=', tr1,',classId.val=',clzId)
        }
        
        this.addLog(msg)
        
    }

}//class
import { RzoNoteShare } from "../note.ui/rznote.ui.dto";
import { FtoClassSharing, FtoNoteInfo } from "./ftclass.dto";


//setting
export interface FtxSetting {
    isNotesOff?:boolean;
    isCamOff?:boolean;
    isMicOff?:boolean;
    isFullscreen?:boolean;

    isMemoListOn?:boolean;
    isChatOff?:boolean;

    isExit?:boolean;

    isMode?:string;
}
 
//sharing
// export interface  {

   
//     isVisiableOn?:boolean;                      // 보기공유

//     isWritingVisibleOn?:boolean;               // 필기보기
    
//     isWritingMineVisibleOn?:boolean;           // 나의필기보기
//     isWritingOtherVisibleOn?:boolean;          // 상대방필기보기
//     isWritingGuestVisibleOn?:boolean;          // 기타 필기보기

//     isWritingOn?:boolean;                       // 쓰기 가능
//     isWritingOtherOn?:boolean;                  // 상대 쓰기 가능
//     isWritingGuestOn?:boolean;                  // 기타 쓰기 가능

//     noteSharings?:RzoNoteShare[];            // 보기공유 리스트

// }


//mirroring;
export interface FtxMirroring {
    isMirrorOn?:boolean;
    isReqMyNotes?:boolean;
    isReqOtherNotes?:boolean;
}

/*********************
 *  Note 
 */
export enum FtxNoteToolEnum {
    TEXT='text',
    PEN='pen',
    MARKER='marker',
    ERASER='eraser',
    UNDO='undo',
    DRAG='drag',
    MEMO='memo',
}

export interface FtxNoteTool {
    toolId?:string;
    curToolAttr?:FtxNoteToolAttr;

    prevToolId?:string;
    prevToolAttr?:FtxNoteToolAttr;
 
}

export interface FtxNoteToolAttr{
    color?:string;
    fontSize?:number;
    fontName?:string;
    fontColor?:string;

    width?:number;
    opacity?:number;

    moveOffX?:number;
    moveOffY?:number;
}


 
/******************** 
 * FtxNote
 */
export type FtxNoteType='book'|'note'|'answer';

export interface FtxNote{
    type?:FtxNoteType;
    
    noteId?:string;
    bookId?:string;

   
    tool?:FtxNoteTool; 

    title?:string;

    visible?:boolean;
    
    //@differ
    isShowOn?:boolean;      

    //@differ
    isShareOn?:boolean;
    //@differ
    isMirrorOn?:boolean;    
    //@differ
    isFloatingOn?:boolean;  
    //@differ
    isFloatable?:boolean;   

    isMemoOn?:boolean;

    ownerName?:string;
    ownerId?:string;

    isSelected?:boolean;
    pageCount?:number;

    //@differ
    pageAt?:number;

    //@volatile
    isPopFocused?:boolean;

    //@volatile
    lastPopSeq?:number;

    //@volatile
    lastPopX?:number;

    //@volatile
    lastPopY?:number;

    
    info?:FtoNoteInfo;

    //23.11.27 추가
    sharing?:FtoClassSharing;
}//class




import { rzlog,RzUiLet,scale } from "../../inc";
import { RzSplitLet,RzResizeLet }from '../../inc'
import { FtLayoutCtx, FtLayoutInit, FtLayoutOpts, FtUiLets } from "../ftclass/ftclass.ui.layout";
import { FtFullLayout } from "./ftclass.ui.layout.full";
import { FtClassSide } from "../ftclass/ftclass.ui.side";
import { FtClassChat } from "../ftclass/ftclass.ui.chat";
import { FtDeskLet } from "../ftclass/ftdesk.ui";
import { FtLayoutSubCtx } from "./ftclass.ui.layout.sub.body";
 

/** dbg */
const rzIs=rzlog.makeDefs()
export const FtClassUiLayoutSub2Split_setDbg =rzIs.setDbg


 

/**
 * FtSubTopInit
 */
export class FtSub2SplitInit implements FtLayoutInit {
    lman:FtFullLayout;

    constructor(lman:FtFullLayout){
        this.lman=lman;
    }
    
    init(lctx:FtLayoutCtx,pr?:any){
        let lman=this.lman;
        let lctx2=pr as FtLayoutSubCtx
        this.doInitSplit(lctx2)
    }

    doInitSplit(lctx2:FtLayoutSubCtx){
        let splitWidth=lctx2.splitWidth;
        let lctx=lctx2.lctx;
        let lman=this.lman;
       
        if(!(lctx.opt.isNoteOn && lctx.opt.isBookOn) ) return

            if(lctx.lets!.bookSplit) {
                lctx2.sbody.addChild(lctx.lets!.bookSplit) 
                
                lctx.lets!.bookSplit.setVisible(true)
            } else {
                let split= new RzSplitLet()
                
                let leftRef:RzResizeLet ={ native:()=>{return lctx.lets!.book?.nativeDiv!},onResize:(e:any)=>lctx.lets!.book?.onResize(e)}
                let rightRef:RzResizeLet={ native:()=>{return lctx.lets!.note?.nativeDiv!},onResize:(e:any)=>lctx.lets!.note?.onResize(e)}
                split.setLets(leftRef,rightRef)
                split.onQuickClick=(b:boolean)=>{
                    rzlog.debug("Split.Quick.clicked : b=",b,',rref=',rightRef);
                    if(rightRef && b){
                        
                        lctx.lets!.leftWidth! +=lctx.lets!.rightWidth!;
                        lctx.lets!.prevRightWidth=lctx.lets!.rightWidth;
                        lctx.lets!.rightWidth=0;
                    } else if(rightRef && !b){
                        lctx.lets!.rightWidth=lctx.lets!.prevRightWidth!;
                        lctx.lets!.leftWidth! -=lctx.lets!.prevRightWidth!;
                        //lctx.lets.prevRightWidth=lctx.lets.rightWidth;
                    } 
                    lman.doUpdateUi(lman.layoutOpts)
                }
                
                
                let css=`width:${splitWidth}px;height:100%;  background:#E1E1E1;`
                split.init({
                        parent:lctx2.sbody,
                        cssText:css,
                })
                lctx.lets!.bookSplit=split
            }
            lctx2.prevWidth+=splitWidth
    }
}
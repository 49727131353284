import { FtClassEvent, FtClassEventEnum } from "../ftclass.event"
import { FtClassRepoMng } from "./ftclass.repo-mng"
import { rzlog } from "../inc"
import { cmToFtxNotes } from "./ftclass2.repo.default"
import { RzoNotePath } from "../note.ui/rznote.ui.dto"

const rzIs=rzlog.makeDefs()
export const FtClassRepoMngRecvHdr_setDbg=rzIs.setDbg
 
let isDirectEraseOn=true

export class FtClassRepoMngRecvHdr {
    isEventPaused: boolean = false
    isPausedEventProcessing:boolean =false
    pausedEvents:FtClassEvent[]=[]
    

    setEventPaused(repoMng:FtClassRepoMng, b : boolean){
        
        if(!b){
            new Promise(async ()=>{
                rzlog.debug("setEventPaused: !!!!!!!!!!!!!!!!!!!!!")
                this.isPausedEventProcessing=true
                await this.doProcPausedEvent(repoMng)
                this.isPausedEventProcessing=false

                this.isEventPaused=b;
            })
        }else this.isEventPaused=b
    }

    getPausingType(e:FtClassEvent):string{
        if(e.scmd===FtClassEventEnum.PAGES_ON)return "put";
        else if(e.scmd===FtClassEventEnum.PAGES_PUT)return "put";
        //else if(e.scmd===FtClassEventEnum.PAGES_ON)return "add";
        return  "skip";
    }

   async procEvent(repoMng:FtClassRepoMng,e:FtClassEvent){
       //if(rzIs.d) 
       rzlog.debug('FtClassRepoMngHdr.procEvent(class.beg) : e=',e)

       if(  e.scmd===FtClassEventEnum.SESSION_ADD 
            || e.scmd===FtClassEventEnum.SESSION_DEL
            || e.scmd===FtClassEventEnum.SESSION_PUT){
                //if(rzIs.d) 
                rzlog.debug('FtClassRepoMngHdr.procEvent(session) : e=',e)
            await this.doProcEvent(repoMng, e)
            return;
       }

       if(this.isEventPaused || this.isPausedEventProcessing ) {
            const ty=this.getPausingType(e)
            if(ty==='add') { this.doAddPausedEvent(e); return; }
            else if(ty==='put') { this.doPutPausedEvent(e); return; }

       } else {
            if( !this.isPausedEventProcessing && this.pausedEvents.length > 0 ) {
                this.isPausedEventProcessing=true
                await this.doProcPausedEvent(repoMng)
                this.isPausedEventProcessing=false
            }
       }
       await this.doProcEvent(repoMng, e)
   }
   
   doAddPausedEvent(e:FtClassEvent){
       rzlog.debug("FtClassREpoMng.doAddPausedEvent : ",e)
        this.pausedEvents.push(e)
   }

   doPutPausedEvent(e:FtClassEvent){
        rzlog.debug("FtClassREpoMng.doPutPausedEvent : ",e)
        //this.pausedEvents.push(e)
        let es=this.pausedEvents.filter(t=> t.scmd!==e.scmd)
        es.push(e)
        this.pausedEvents=es
   }

   async doProcPausedEvent(repoMng:FtClassRepoMng){
        let vs:FtClassEvent[]=this.pausedEvents
        this.pausedEvents=[]
        for(let i in vs){
            let te=vs[i]
            rzlog.debug("doProcPausedEvent : pe=",te)
            await this.doProcEvent(repoMng,te)
        }
   }

   // proc event 이벤트 처리
   async doProcEvent(repoMng:FtClassRepoMng,e:FtClassEvent){
        rzlog.debug("RcvHdr.procEvent.1(class.beg):e=",e);

        if(e.cmd===FtClassEventEnum.PATH_ADD|| e.scmd===FtClassEventEnum.PATH_ADD){
            if(rzIs.d) rzlog.debug('FtClassRepoMngHdr.procClassEvent :e=',e)
            await this.procPathAdd(repoMng,e)

        }else  if(e.cmd===FtClassEventEnum.PATH_PUT|| e.scmd===FtClassEventEnum.PATH_PUT){
            await this.procPathPut(repoMng,e)
        }else  if(e.cmd===FtClassEventEnum.PATH_DEL|| e.scmd===FtClassEventEnum.PATH_DEL){
            await this.procPathDel(repoMng,e)
        }else  if(e.cmd===FtClassEventEnum.CLASS_BEG|| e.scmd===FtClassEventEnum.CLASS_BEG){
            rzlog.debug("RcvHdr.procEvent.2(class.beg)");
            await this.procClassBeg(repoMng,e)
        
        } else if(e.cmd===FtClassEventEnum.CLASS_PUT){            
            rzlog.debug("RcvHdr.procEvent.2(class.put)");
            await this.procClassPut(repoMng,e)
        }else  if( e.scmd===FtClassEventEnum.CHAT_ADD){
            await this.procChatAdd(repoMng,e)
        } else if (e.cmd===FtClassEventEnum.PAGES_ON|| e.scmd===FtClassEventEnum.PAGES_ON){
            if(rzIs.d) rzlog.debug('FtClassRepoMngHdr.procClassEvent > PAGES_ON : e=',e)
            if(e.sesId===repoMng.getSesId()) return 
            
            await repoMng.changeDeskRepoOn(e.pages![1],e.pages![0],e.srcOpt)

        } else if (e.cmd===FtClassEventEnum.PAGES_PUT|| e.scmd===FtClassEventEnum.PAGES_PUT){
            if(rzIs.d) rzlog.debug('FtClassRepoMngHdr.procClassEvent > PAGES_PUT(ppgs) : e=',e)
            if(e.sesId && e.sesId=== repoMng.getSesId()) return

            if(e.pages?.length===1){
                if(rzIs.d) rzlog.debug('FtClassRepoMngHdr.procClassEvent > PAGES_PUT(ppgs) : pageChanged ',e.pages)
                this.doNotePageChanged(repoMng,e);
            }else if(e.pages && e.pages.length>1){
                // this for tab changed
                if(rzIs.d) rzlog.debug('FtClassRepoMngHdr.procClassEvent > PAGES_PUT(ppgs) : Tab Changed',e.pages)
                this.doNotesTabChanged(repoMng,e);
            }
        
        } else if (e.cmd===FtClassEventEnum.PAGES_ADD|| e.scmd===FtClassEventEnum.PAGES_ADD){
            //if(rzIs.d) 
            rzlog.debug('FtClassRepoMngHdr.procClassEvent > PAGES_ADD : e=',e)
            
            if(e.pages?.length===1)  {
                if(e.sesId && e.sesId=== repoMng.getSesId()) return;

                if(e.pages[0].type===undefined) e.pages[0].type='book'
                await repoMng.addNoteInfo(e.pages[0],e.srcOpt)
            }  
        } else if (e.cmd===FtClassEventEnum.SETTING|| e.scmd===FtClassEventEnum.SETTING){
            //if(rzIs.d) 
            rzlog.debug('FtClassRepoMngHdr.procClassEvent > SETTING : e=',e)
            
            if((e.setting as any).__typename ) delete (e.setting as any).__typename;

            repoMng.setSetting(e.setting!,e.srcOpt)
   
        } else if (e.cmd===FtClassEventEnum.SETTING_ETC|| e.scmd===FtClassEventEnum.SETTING_ETC){
            if(rzIs.d) rzlog.debug('FtClassRepoMngHdr.procClassEvent > SETTING_ETC : e=',e)
            if((e.etcSetting as any).__typename ) delete (e.etcSetting as any).__typename;
            repoMng.setEtcSetting(e.etcSetting!,e.srcOpt)
   
        } else if (e.cmd===FtClassEventEnum.EDIT_LOCK|| e.scmd===FtClassEventEnum.EDIT_LOCK){
            if(rzIs.d) rzlog.debug('FtClassRepoMngHdr.procClassEvent > EDIT_LOCK : e=',e)
            if((e.editLock as any).__typename ) delete (e.editLock as any).__typename;
            repoMng.setEditLock(e.editLock!,e.srcOpt)
        } else if( e.cmd===FtClassEventEnum.SESSION_ADD||e.scmd===FtClassEventEnum.SESSION_ADD ) {
            if(rzIs.d) rzlog.debug('FtClassRepoMngHdr.procClassEvent(session) > SESSION_ADD : e=',e)
            if((e.session as any).__typename ) delete (e.session as any).__typename;
            let ses={...e.session}

            repoMng.addSession(ses,e.srcOpt)
        
        } else if(e.cmd===FtClassEventEnum.SESSION_DEL|| e.scmd===FtClassEventEnum.SESSION_DEL ) {
            //if(rzIs.d) 
                rzlog.debug('FtClassRepoMngHdr.procClassEvent > SESSION_DEL : e=',e)
            if((e.session as any).__typename ) delete (e.session as any).__typename;
            let ses={...e.session}
            repoMng.delSession(ses,e.srcOpt)
        } else if(e.cmd === FtClassEventEnum.SHARING_PUT|| e.scmd===FtClassEventEnum.SHARING_PUT) {
            //2023.11.17
            if(rzIs.d) rzlog.debug('FtClassRepoMngHdr.procClassEvent > SHARING_PUT : e=',e)
            if((e.classSharing as any).__typename ) delete (e.classSharing as any).__typename;
            let sh = {...e.classSharing};
            
            repoMng.procSharePutFromRemote(sh,e.srcOpt);
        } else if(e.cmd === FtClassEventEnum.NOTI_SEND|| e.scmd===FtClassEventEnum.NOTI_SEND) {
            //2023.11.17
            if(rzIs.d) rzlog.debug('FtClassRepoMngHdr.procClassEvent > NOTI_SEND : e=',e)
            if((e.noti as any).__typename ) delete (e.noti as any).__typename;
            let sh = {...e.noti};
            repoMng.notiClassNotiRecv(sh,e.srcOpt);
           // repoMng.procSharePutFromRemote(sh,e.srcOpt);
        }
    }

    async doNotePageChanged(repoMng:FtClassRepoMng,e:FtClassEvent){
        if(!e.pages) return;
        if(!Boolean(e.pages[0].pageNo)) return
        //@this is important !!!!! - when first page_put, no rendering
        if(e.pages && e.pages.length>0 && !Boolean(e.pages[0].pageNo)) {
            e.pages[0].pageNo=1;
        }
        await repoMng.putNoteInfo(e.pages![0].noteId!,e.pages![0],e.srcOpt)
    }

    
   async doNotesTabChanged(repoMng:FtClassRepoMng,e:FtClassEvent){
        if(!e.pages) return;

        let npages= e.pages.map(t=> {
            let tt=t;
            if((tt as any).__typename) delete (tt as any).__typename;
            return tt;//{...tt,isSelected:selected} ;
        })
        
        await repoMng.putNoteInfoPages(npages,e.srcOpt);

    }

    async procClassBeg(repoMng:FtClassRepoMng,e:FtClassEvent){
        //alert('repoMng');
        if(repoMng) repoMng.notiClassEvent(e);
    }

    async procClassPut(repoMng:FtClassRepoMng,e:FtClassEvent){
        if(repoMng) repoMng.notiClassEvent(e);
    }

    async procPathAdd(repoMng:FtClassRepoMng,e:FtClassEvent){
        if(!e.paths) return
        for(let i in e.paths){
            let t= e.paths[i] 
            let noteId=t.noteId!
            let pgNo=t.pageNo!

           
            
            if(rzIs.d) rzlog.debug('FtClassRepoMngHdr.procClassEvent.addPAth : rscID=',noteId,',pgNo=',pgNo)
            if(t.type==='erase' ) {
                if(rzIs.d) rzlog.debug('FtClassRepoMngHdr.procEvent : ERASE!!! - t=',t)
                if(!isDirectEraseOn && t.targetId){
                    
                    let tr0 = await repoMng.getPath(noteId,pgNo,t.id!)
                    if(tr0.status===0){
                        let p=tr0.data!
                        p.drawable=false
                        repoMng.putPath(noteId,pgNo,p,e.srcOpt)
                    }
                }
            } 

            let t2=new RzoNotePath(t);
           if(t.attrs) t2.attr=JSON.parse(t.attrs);

            repoMng.addPath(noteId,pgNo,t2,e.srcOpt)
        }//for
    }

    async procPathPut(repoMng:FtClassRepoMng,e:FtClassEvent){
        if(!e.paths) return
        for(let i in e.paths){
            let t= e.paths[i] 
            let noteId=t.noteId!
            let pgNo=t.pageNo!
            await repoMng.putPath(noteId,pgNo,t,e.srcOpt)
        }
    }


    async procPathDel(repoMng:FtClassRepoMng,e:FtClassEvent){
        if(!e.paths) return
        for(let i in e.paths){
            let t= e.paths[i] 
            let noteId=t.noteId!
            let pgNo=t.pageNo!
            await repoMng.delPath(noteId,pgNo,t,e.srcOpt)
        }
    }

    async procChatAdd(repoMng:FtClassRepoMng,e:FtClassEvent){
        if(rzIs.d) rzlog.debug('FtClassRepoMngHdr.procChatAdd: e=',e)
        if(!e.chats) return
        for(let i in e.chats){

            let t= e.chats[i] 
            if(t.fromSesId=== repoMng.sesId) continue;
            if(t.createdAt && typeof t.createdAt === 'string') t.createdAt=new Date(Date.parse(t.createdAt))
            repoMng.chatRepo?.addItem(t,e.srcOpt)
        }
    }

 

}//class
import { IsFail, NewFail, rzlog,RzRes } from "./inc"
import './rznote.ui.css'

import { RzUiLet, RzAwtTool, RzUiOpts, RzRect, RzPos, RzSize, rzDlgShow } from './rzcmn.ui'
import { RzDrawRenderFac} from './rznote.ui.draw'

import { RzNoteEvent, RzNoteEventEnum,  RzNoteRepo, RzNotePathUtil  } from './rznote.ui.repo'
import { BindNativeEvents } from "./rznote.ui.event"
import { MakeToolbarDlg, RzDragBtn, RzMemoDlg, } from "./rznote.ui.tools"
import { RzoNotePath, RzoNoteShare } from "./rznote.ui.dto"
import { RzDrawPenFac, RzDrawPenFacHolder } from "./rznote.ui.edit.fac"
import { CmHasElChild, RzImg } from "../ui/wiz2/rzcomp"
import { FtSrcOpt } from "../dto/ftclass.dto"
import { off } from "process"
 
 
const IS_DEF_DBG_ON=false;

const rzIs=rzlog.makeDefs(false,false)
export const RzNoteUi_setDbg=rzIs.setDbg

export interface RzScale{
    w:number;
    h:number;
}

export interface RzPoint{
    x:number;
    y:number;
}


const calcXPos=( px :number, cw:number,scw:number):{pw:number,iw:number}=>{
    let dx=0;
    if(px<0)dx=-px;
    else  dx=px;

    let pw=cw-dx;
    let iw=cw-dx;
    if(px+cw>=scw) {
        pw=scw-dx;
        iw=scw-dx;
    }
    if(px<0)  pw=cw-dx;
    return {pw:pw,iw:iw}
}
const calcYPos=( py :number, ch:number,sch:number):{ph:number,ih:number}=>{
    let dy=0;
    if(py<0)dy=-py;
    else  dy=py;

    let ph=ch-dy;
    let ih=ch-dy;
    if(py+ch>=sch) {
        ph=sch-dy;
        ih=sch-dy;
    }
    if(py<0)  ph=ch-dy;
    return {ph:ph,ih:ih}
}


let isDbgUi=IS_DEF_DBG_ON;
export const RzNoteUi_setDbgUi=(b:boolean)=>{
    isDbgUi=b;
}

let isPageInit=false
export function RzNoteUi_setPageInit(b:boolean){
    isPageInit=b;
}

let _isRulerOn=false;
/***************************** */
 
export interface RzNoteRepoFac {
    newRepo(pr?:RzNoteOpts) :RzNoteRepo
} 


/**************************************
 *  RzNote Opts
 *************************************/
export enum RzPenTypeEnum {
        PEN="pen",

        LINE="line",
        RECT="rect",
        CIRCLE='circle',
        HEXAGON='hexagon',
        IMAGE='image',
        MEMO='memo',
        ERASER='eraser',

        MARKER="marker",
        TEXT="text",
        
        DRAG='drag',

        NULL='null',
        NO_PEN='noPen',
}//class


export interface RzNoteUser {
    username?:string;
    uid?:string;
    group?:string;
}

 

 
export interface RzNoteShare {

    id?:string;
    classId?:string;

    noteId?:string;
    ownerId?:string;
    gid?:string;

    isReadOn?:boolean;
    isGroupReadOn?:boolean;
    isOtherReadOn?:boolean;


    isWritingReadOn?:boolean;//내필기보이기
    isGroupWritingReadOn?:boolean;//필기공유요청
    isOtherWritingReadOn?:boolean;


    //volatile
    isMyWritingAllOn?:boolean;//필기없이보기
    isMyWritingReadOn?:boolean;//나의필기보기
    isMyGroupWritingReadOn?:boolean;//상대방필기보기
    isMyOtherWritingReadOn?:boolean;


    isWriteOn?:boolean;
    isGroupWriteOn?:boolean;
    isOtherWriteOn?:boolean;
}


export interface RzNoteShareHolder {
    getShare(noteId:string):RzNoteShare|undefined; 
}


export interface  RzNoteOpts extends RzUiOpts {
        noteId?:string;
        noteType?: string;
        repo?:RzNoteRepo;
        penType?:RzPenTypeEnum;
        canvasTop ?: number;
        drawerUsername?:string;
        drawerId?:string;
        repoFac?:RzNoteRepoFac;

        sharing?:RzNoteShare;
        owner?:RzNoteUser;
        drawer?:RzNoteUser;
}


export interface  RzPenAttr {
        type?:string;
        noteId?:string;
        ownerId?:string;
        drawerId?:string;    
        
        width?:number;
        opacity?:number;
        color?:string;
        font?:string;
        fontSize?:number;
        fontColor?:string;

        dotXOffset?:number;
        dotColor?:string;
        boxBgColor?:string;
        boxColor?:string;
        boxAttr?:string;

        moveOffX?:number;
        moveOffY?:number;
}



let _defNotePenSize=3;
export function RzNote_setDefPenSize(sz:number){
        _defNotePenSize=sz;
}
/**************************************
 *  RzNote
 *************************************/
 interface RzNoteProp {
    onNoteFocused?:(nt:RzNote)=>void;
    onScaleChanged?:(wscale:number,hscale:number)=>void;
 }

export type RzNoteDragType='vertical'|'horizontal'|'both'|'none';

export class RzNote extends RzUiLet<RzNoteProp> {
    static _id:number= 1;

    ctx?: CanvasRenderingContext2D;
    canvas? : HTMLCanvasElement;
     
    type : string ='main';
    //defaultWidth:number=300;    // A4 = 210mm x 297mm
    //defaultHeight:number=600;   
    defaultWidth:number=420*2;    // A4 = 210mm x 297mm
    defaultHeight:number=594*2;   
    

    isScrollable=true;


    isDraw:boolean =false;
    noteId?:string;
 
    
    canvasTop:number=0;
    canvasGap:number=0;
    curPen?: any; //{ type:'pencil',width:10,color:'rgba(190,230,190,0.03)'}
    curPenAttr:RzPenAttr;
    pathTool:RzNotePathUtil;
    
    //@ADD 
    sharing?:RzNoteShare;
    sharingHoler?:RzNoteShareHolder;
    //@ADDEND

    renderFac=new RzDrawRenderFac(this);
    scalew=1.0;
    scaleh=1.0;
    offx:number=0;
    offy:number=0;
    noteRepo?:RzNoteRepo;
    bgImg?: HTMLImageElement;
    bgPageNo?:number;
    bgPrevPageNo?:number;

    bgWidth?:number;
    bgHeight?:number;

    toolbar? : any;
    isPenEnabled : boolean =false;
    onDrawChange? : (e:any,repo?:any)=>void;
    dbgText?:string;
    isEditLock:boolean=false;
    
    isFitToPage=true;
    isFitToWidth=false;

    canvasWGap=0;
    canvasHGap=0;
    pos? : any;
    fitType?:string;
    dragType?:RzNoteDragType;
    canvasColor:string='white';

    fitPageCalc:RzFitPageCalc=new RzFitPageCalc();
    canvasPos?:RzRect;
    
    penFac:RzDrawPenFac= RzDrawPenFacHolder.getDrawPenFac()
    repoFac?: RzNoteRepoFac  =RzNoteRepoFacDef.getDefaultFac();

    //fitPageCalc:RzFitPageCalc=new RzFitPageCalc();

    /* opts ={ repo:RzNotePepo, noteId:xxx } */
    constructor(opts?: RzNoteOpts){
            super(opts);

            this.isDraggable=true;
            if(isDbgUi) {this.canvasTop=16; this.canvasGap=12;};

            //this.title=title
            this.isDraw=true;
            this.type=opts?.noteType||'extra'; //main,extra,popup
            this.pos={x:0,y:0,drawable:false};
            this.toolbar=null;

            this.curPenAttr={type:RzPenTypeEnum.MARKER,width:_defNotePenSize,color:'#100572'};
            this.curPen= this.penFac.createPen(RzPenTypeEnum.MARKER,this.curPenAttr,this,this.curPen);
            this.pathTool=new RzNotePathUtil();

            let repo=this.doInitLet(opts);
            this.noteRepo=repo;
    }


    clearBgImg(){
        this.bgPrevPageNo=this.bgPageNo;
        this.bgImg=undefined;
        this.bgPageNo=undefined;
    }

    getPreferRect():DOMRect|null{
        return {x:0,y:0,
            height:this.defaultHeight*this.scaleh,
            width:this.defaultWidth*this.scalew} as DOMRect;
    }

    doInitLet(opts?:RzNoteOpts){
            
        let nid=(""+ RzNote._id++);
        this.id= nid;
        this.noteId=opts?.noteId || nid;
        this.isPenEnabled=true;

        if(opts?.penType===RzPenTypeEnum.PEN){
            this.curPenAttr={type:RzPenTypeEnum.PEN,width:_defNotePenSize,color:'black'};
            this.curPen= this.penFac.createPen(RzPenTypeEnum.PEN,this.curPenAttr,this,this.curPen);
        }

        if(opts?.repoFac) this.repoFac=opts.repoFac;
        let repo=opts?.repo as RzNoteRepo|undefined;
        if(!repo){
            if(this.repoFac){
                repo = this.repoFac.newRepo();
                if(isPageInit) repo.addPage(1);
            }
        }
        if(repo){
            if(opts?.drawer) repo.setDrawer(opts.drawer);
            else {
                if(opts?.drawerUsername) repo.setOwnerUsername(opts.drawerUsername);
                if(opts?.drawerId) repo.setOwnerId(opts.drawerId);
            } 

            if(opts?.owner) repo.setOwner(opts.owner);

            repo.addOnNoteEvent(this.onNoteEvent, this);
        }
        
        let r=this.getBounds();
     

        if(rzIs.d)rzlog.debug(`RzNote[${this.id}].new :notetype=`,opts?.noteType,',penType=',opts?.penType,',pen=',this.curPen);
        return repo;
    }

    /************* */
    setPenFac(penFac:RzDrawPenFac){
            this.penFac=penFac;
    }


    /************* */
    setEditLock(b:boolean){
            this.isEditLock=b;
    }

    setDragType(dragType:RzNoteDragType){
        this.dragType=dragType;
    }

    setFitType(ftype:string){
            this.fitType=ftype;
            if(ftype==='page'){
                this.isFitToPage=true;
                this.isFitToWidth=false;
            } else if(ftype==='width'){
                this.isFitToPage=false;
                this.isFitToWidth=true;
            }
            let r=this.getBounds();
            //alert('1111 : w='+r.width+', h='+r.height);
            if(this.canvas&& this.nativeDiv) {
                let r1=this.getBounds();
              //  let r2=this.nativeDiv.getBoundingClientRect();
                let r3=this.canvas.getBoundingClientRect();

                //alert('1111 : w='+r1.width+', h='+r1.height+', cw='+r3.width+', ch='+r3.height);
                let w1=r3.width.toFixed();
                let h1=r3.height.toFixed();
                
                this.canvas.width=parseInt(w1);
                this.canvas.height=parseInt(h1);

                //this.canvas.style.marginTop='200px';
                this.canvas.style.width=(w1)+'px';
                this.canvas.style.height=(h1)+'px';
            }

            if(this.nativeDiv) this.repaint();
    }

    setDefCanvasSize(w:number,h:number){
            this.defaultWidth=w;
            this.defaultHeight=h;
    }

    setCanvasGap(w:number,h:number){
            this.canvasWGap=w;
            this.canvasHGap=h;
    }
    
    movePageNext(next:boolean){
            if(!this.noteRepo) return;
            let pgNo=this.noteRepo.getCurPageNo();
            let pgCnt=this.noteRepo.getPageCount();
            if(!next) pgNo++;
            else pgNo--;
            rzlog.debug("movePageNext: pg=",pgNo,',pgCnt=',pgCnt)

            if(pgNo>=0 && pgNo<=pgCnt){
                this.noteRepo.setCurPageNo(pgNo);
                this.repaint();
            }
    }

    setZoomIn(isZoomIn:boolean){
            let scale = isZoomIn? this.scalew*1.1 : this.scalew*0.9
            this.setScale(scale,scale);
            this.repaint();
    }

    reinit(){
            if(this.nativeDiv){
                let r=this.getSize();
                this.onResize(r);
                //this.repaint();
             
               rzlog.debug("rznoteui.setDefCanvasSize : r=",r);
            }
    }

    /***********************
     *  UiLet init members
     ***********************/
    doPreOpts(opts:RzUiOpts){
            //let css=this.isScrollable?`overflow-y:scroll; `:``
            let css=this.isScrollable?
                 `background-color:white;margin-top:0; padding:0;`
                :``;

            return {...opts, className:opts.className||'rz-note',
                cssText:(opts.cssText||'')+ ((isDbgUi)?'border-width:4px;':'')
                        +css,
                isPaintable:true,isTouchable:true};
    }

    isDbgOn=false;

    doInit(oopts:RzUiOpts):boolean {
            let opts = oopts as RzNoteOpts;
            let xpos=0;

            if(opts?.canvasTop) this.canvasTop=opts.canvasTop;

            let r=this.getBounds();
            if(rzIs.d)rzlog.debug(`RzNote[${this.id}].doInit : r=`,r);
            if(this.id && !(this.nativeDiv as any).id) (this.nativeDiv as any).id=this.id;
            
            let el=this.nativeDiv;
            let canvasBodyHeight=0;
            if(el && r){
                el.style.width  = r.width + "px"  ;
                canvasBodyHeight=r.height - this.canvasTop;
                el.style.height = canvasBodyHeight+"px";
            }
            
            if(!this.isDbgOn){
                this.isDbgOn=true;
               // alert('CanvasTop='+this.canvasTop);
            }

            if(r){
                const tr={width:r.width, height:r.height};
                //const tr={width:r.width, height:canvasBodyHeight}
                this.initCanvas(tr,"init");
               
            }

            BindNativeEvents(this,rzIs.d);
            //alert('1111  w='+r.width+', h='+r.height);

            this.repaint();
            return true;
    }

    async load(opt?:RzNoteOpts){
        this.bgPrevPageNo=this.bgPageNo;
        this.bgImg=undefined;
        this.bgPageNo=undefined;

        if(!this.noteRepo) return NewFail('no repo');
        
        let r= await this.noteRepo?.load(opt);
        if(IsFail(r)) return r;

        this.noteId=this.noteRepo.getNoteId();
     
 
        rzlog.debug("loadBgImg(DrawBg) : noteId=",this.noteId);
        this.doLoadBgImg();

        let bx=this.getBounds();

        return r
    }
    

    getRepo(){
        return this.noteRepo;
    }

    getSharing():RzNoteShare|undefined{
        if(this.noteRepo) return this.noteRepo.getSharing();
        if(this.sharingHoler && this.noteId) return this.sharingHoler.getShare(this.noteId!);
        return this.sharing;
    }

    setSharing(sharing:RzNoteShare){
        if(this.noteRepo) this.noteRepo.setSharing(sharing);
        this.sharing=sharing;
    }
    setShareHolder(sharingHoler:RzNoteShareHolder){
        this.sharingHoler=sharingHoler;
    }

    /**********
     * 
     */

    getOwnerUsername(){ return this.noteRepo?.getOwnerUsername();}
    getOwnerId(){ return this.noteRepo?.getOwnerId();}

    getDrawerUsername(){
        let du=this.noteRepo?.getDrawer();
        return du?.username;
    }

    getDrawerId(){  
        let du=this.noteRepo?.getDrawer();
        return du?.uid;
    }
 

    getGroup(){
        return this.noteRepo?.getGroup();
    }

    setDrawerUsername(uname:string){ 
       if(!this.noteRepo) return;
       let ui=this.noteRepo.getDrawer();
       this.noteRepo.setDrawer({...ui, username:uname});
    }

    setDrawerUid(id:string){ 
        if(!this.noteRepo) return;
        let ui=this.noteRepo.getDrawer();
        this.noteRepo.setDrawer({...ui, uid:id});
     }

    scaled=false;
    setScale(scalew :number,scaleh:number,scaled?:boolean){
        rzlog.debug('note.setScale : noteID=',this.noteId,',w=',scalew,',h=',scaleh)
        if(scaled) this.scaled=true;

        this.scalew=scalew;this.scaleh=scaleh;
        if(this.props?.onScaleChanged) this.props.onScaleChanged(this.scalew,this.scaleh);
    }

    getScale():RzScale {return {w:this.scalew,h:this.scaleh};}
    doClear(){
        this.clearRepo();
    }

    /**/
    clearRepo(){
        if(this.noteRepo) {
            this.noteRepo.delOnNoteEvent(this.onNoteEvent,this);
            this.noteRepo=undefined;
        }
    }
    
 
 
    setCanvasColor(color:string){
        this.canvasColor=color;
        if(this.canvas) {
            this.canvas.style.backgroundColor=color;
        }
    }

    borderWidth=1;
    setBorderWidth(w:number){
        this.borderWidth=w;
        if(this.canvas) {
            this.canvas.style.borderWidth=w+'px';
        }
    }
 
    static canvasCnt=1;
 
    /****** 
     * canvas
    */
    initCanvas(r:RzSize,by?:string){
        const divBox=this.getBounds();

        rzlog.debug("RzNote.initCanvas : by=",by,",tgt.rect=",r,',curDiv.rect=',divBox);

        if(this.onScroll) window.removeEventListener('scroll',this.onScroll);

        if(this.canvas) {
            this.nativeDiv?.removeChild(this.canvas);
            this.canvas=undefined;
            this.ctx=undefined;
        }

            let tw = r.width - this.canvasGap;
            let th = r.height - this.canvasTop;
            
            let ttw=tw - ((this.isFitToWidth)?this.canvasWGap:0);
            let pos={x:0,y:0,width:ttw,height:th};

            let bgColor=this.canvasColor;
          //  bgColor='#505050';

            let canvas= RzAwtTool.createElement('canvas') as HTMLCanvasElement;
            let cssTxt=``;
            if(this.isScrollable) cssTxt=`background-color:${bgColor};
                    cursor:crosshair; border-width:${this.borderWidth}px; ` 
                    + (this.isFitToPage?'border: solid; border-color:gray;':'');
            else 
                cssTxt=`top:${pos.y}px;left:${pos.x}px; 
                    width:${pos.width}px; height:${pos.height}px; 
                    background-color:${bgColor};
                    cursor:crosshair; border-width:${this.borderWidth}px;` 
                    + (this.isFitToPage?'border: solid; border-color:gray;':'');

            canvas.style.cssText=cssTxt;
            canvas.id="rznote-canvas-"+RzNote.canvasCnt++;
            canvas.setAttribute("id", canvas.id);

            this.nativeDiv?.appendChild(canvas);
            this.canvasPos=pos;

 

            rzlog.debug("CREATE CANVAS:id=",canvas.id);
            //alert('1111  w='+pos.width+', h='+pos.height);

            //canvas.width=this.isScrollable?divBox.width-20:pos.width;
            //canvas.height=this.isScrollable?divBox.height-20:pos.height;

            canvas.width=this.isScrollable?divBox.width:pos.width;
            canvas.height=this.isScrollable?divBox.height:pos.height;
            
            let tctx=canvas.getContext('2d') as CanvasRenderingContext2D;
            this.ctx=tctx;
            this.canvas=canvas;
            if(rzIs.d)rzlog.debug(`RzNote[${this.id}].bind : this.ctx=`,this.ctx,',w=',this.canvas.width,',h=',this.canvas.height);
            (canvas as any)._srcLet=this;


            this.doLayoutCanvas();

            //@if( this.offx !==0 || this.offy !== 0)   this.ctx.translate(this.offx,this.offy)
            
            this.scrollY0=window.scrollY;
            this.scrollX0=window.scrollX;

            window.addEventListener('scroll',this.onScroll);
            //document.add
    }

    setCanvasSize(w:number,h:number){
        if(this.canvasPos){
            this.canvasPos={...this.canvasPos,width:w,height:h};
        }
        rzlog.debug("rznote.SET CANVAS SIZE.1(Resize) : w=",w,',h=',h);
        if(this.canvas){
            this.canvas.width=w;
            this.canvas.height=h;
            if(this.canvas.style){
                rzlog.debug("rznote.SET CANVAS SIZE.2(Resize) : w=",w,',h=',h);
                this.canvas.style.width=w+'px';
                this.canvas.style.height=h+'px';
                this.ctx=this.canvas.getContext('2d') as CanvasRenderingContext2D;
                this.doClearCanvas(true); 
                this.repaint();
            }
        }
    }

    getCanvasSize():RzSize|null{
        if(this.canvasPos)
            return {width:this.canvasPos.width,height:this.canvasPos.height};
        return null;
    }


    doLayoutCanvas(){
        let r=this.getBounds();
        let tw = r.width - this.canvasGap;
        let th = r.height - this.canvasTop;

        //let ttw=tw - ((this.isFitToWidth)?5:0)
        
        let ttw=tw - ((this.isFitToWidth)?this.canvasWGap:0);
        let pos={x:0,y:0,width:ttw,height:th};

        if(this.isFitToPage){
            //pos=this.fitToPage(pos)
            pos=this.fitPageCalc.calc(this,pos);
        } else if (this.isFitToWidth){
            pos=this.fitPageCalc.calc(this,pos,'width');
        }
        
    }
    
    onScroll=(e:Event)=>{
        this.doScroll(e);
    }

    scrollY0=0;
    scrollDy=0;
    scrollX0=0;
    scrollDx=0;
    doScroll(e:Event){
        //if(this.scrollY0===0) this.scrollY0=window.scrollY
        this.scrollDy=window.scrollY - this.scrollY0;
        this.scrollDx=window.scrollX - this.scrollX0;
        rzlog.debug('Scroll:mov y0=',this.scrollY0,',dy=',this.scrollDy,',yoff=',window.scrollY,'e=',e,);
    }

    setPenEnabled(b:boolean){        
        this.isPenEnabled=b;
    }

    isDrawOn:boolean=true;
    setDrawOn(b:boolean){
        if(this.isDrawOn==b)return; 
        this.isDrawOn=b;
        this.repaint();
    }

    doIsEditOn(){
        if(this.noteRepo){
            let di=this.noteRepo.getDrawerId();
            let oid=this.noteRepo.getOwnerId();
            let sh=this.noteRepo.getSharing();
            if(sh){
                if(di==oid){
                    if(sh.isMyWritingReadOn!=undefined &&sh.isMyWritingReadOn===false) return false;
                    if(sh.isWriteOn!=undefined && sh.isWriteOn===false) return false;
                }
                else if(di!==oid){
                    //
                    // if(sh.isMyGroupWritingReadOn!=undefined &&sh.isMyGroupWritingReadOn===false) return false;
                    if(sh.isGroupWriteOn!=undefined && sh.isGroupWriteOn===false) return false;
                    if(sh.isWriteOn!=undefined && sh.isWriteOn===false) return false;
                }
            }
        }
        return true;
    }

    /***********************
     *  drawing object attributes
     */
    setPencil(penAttr : RzPenAttr,isNotiEvent?:boolean){
        this.doSetPencil(penAttr,isNotiEvent);
    }

    doSetPencil(penAttr : RzPenAttr,isNotiEvent?:boolean){
        if(!this.isPenEnabled) return;

        
        let type ='pen'; //pen, marker, rect
        if(penAttr?.type) type=penAttr.type;

        let otype=this.curPen.type;
        this.curPenAttr={...this.curPenAttr, ...penAttr};
        rzlog.debug("PENTOOL.attr=",this.curPenAttr);
        if(!this.curPen || otype!==type){
            this.curPen= this.penFac.createPen(type,this.curPenAttr,this,this.curPen);
        } else {
            this.curPen= this.penFac.createPen(type,this.curPenAttr,this,this.curPen);
            //@this.curPen.setAttr( this.curPenAttr);
        }

        //@if(this.noteRepo)this.noteRepo.setPenAttr({...this.curPenAttr},isNotiEvent);
    }

    getPenAttr(){
        //if(this.noteRepo)return this.noteRepo.getPenAttr();
        return this.curPenAttr;
    }

    getPencil(){
        rzlog.debug("PENTOOL.get=",this.curPen);
        return this.curPen;
    }

    /**************** */
    undoPath(depth:number,srcOpt?:FtSrcOpt){
        //@this.noteRepo?.undoPath(depth,srcOpt)
        
        this.noteRepo?.undoPath(undefined,depth,srcOpt)
    }

    redoPath(depth:number,srcOpt?:FtSrcOpt){
        //@this.noteRepo?.redoPath(depth,srcOpt)
        let curPg:number=0;
        this.noteRepo?.redoPath(curPg,depth,srcOpt);
    }   
    
 
    /**************** */
    setRepo(noteRepo:any){
        if(rzIs.d)rzlog.debug(`RzNote[${this.id}].setRepo : noteRepo=`,this.noteRepo, ',newRepo=',noteRepo);
        if(this.noteRepo) this.noteRepo.delOnNoteEvent(this.onNoteEvent,this);

        this.noteRepo=noteRepo;

        let ntId=noteRepo.getNoteId();
        this.noteId=ntId;

        noteRepo.addOnNoteEvent(this.onNoteEvent, this);
        this.doLoadBgImg();

    }


    onNoteEvent=async (e:RzNoteEvent,pr?:any)=>{
        //if(rzIs.d) 
        rzlog.debug('RNote.onNoteEvent(Canvas) : e=',e);


        if(e.cmd===RzNoteEventEnum.PATH_ADD){

        } else if(e.cmd===RzNoteEventEnum.PATH_UNDO){

        } else if(e.cmd===RzNoteEventEnum.PAGES_PUT){
           
            if(rzIs.d) rzlog.debug(`RzNote[${pr.id}].onNoteEvent : doLoadBgImg()`);
            if(e.noteId && e.noteId!==this.noteId){
                
            }
            let pg=this.noteRepo?.getCurPageNo();
            if(this.bgPageNo!==pg){
              
                let a=1;
            }
            //@ADD OFFSET
            this.offx=0;
            this.offy=0;

            await pr.doLoadBgImg();
            return;
        }else if( e.cmd===RzNoteEventEnum.SHARING){
            rzlog.debug("RzNote.onNoteEvent : sharing=",e.sharing);
        }

        pr.repaint();
    }

    canvasMargin={x:0,y:0};


    getPos(e:MouseEvent){
        let cvs_xoff=this.canvasMargin.x + this.scrollDx ;
        let cvs_yoff=this.canvasMargin.y + this.scrollDy ;
     
        let el=this.nativeDiv as HTMLElement;
        let isChild=CmHasElChild(el, e.target as HTMLElement);
        let isMine=el===e.target;

        if(el){
            let tr=el.getBoundingClientRect();
             let tx=e.pageX-tr.left;
             let ty=e.pageY-tr.top;
            
            //@RMV
            //@ let tx=e.clientX - el.offsetLeft;
            //@ let ty=e.clientY - el.offsetTop;
 
            if(rzIs.t||this.isTransDbg)
                rzlog.debug("NoteX.getPos : tx=",tx,',ty=',ty,
                            ',cX=',e.clientX,',el.offX=',el.offsetLeft,
                            ',cY=',e.clientY,',el.offY=',el.offsetTop,
                            ',isChild=',isChild,',isMine=',isMine ,',e.tgt=',e.target);

            //@RMV
            if(this.canvas){
                //@
                // tx=tx-this.canvas.offsetLeft + cvs_xoff;
                // ty=ty-this.canvas.offsetTop + cvs_yoff;
            }
            let pos={x:tx , y:ty };
            rzlog.debug('note.GETPOS ', pos);
            return pos;
        }
        let pos={x:e.offsetX,y:e.offsetY};
        rzlog.debug('note.GETPOS.2 ', pos);
      //  alert('pos');
        return pos;
    }

    transClientPos(p:RzRect):RzRect{
            let r={...p};
            if(rzIs.t||this.isTransDbg) rzlog.trace(`RzNote[${this.id}].transClientPos : p=`,p);
            if(this.nativeDiv){
                if(rzIs.t ||this.isTransDbg ) rzlog.trace(`RzNote[${this.id}].transClientPos : p=`,p,
                        ',canvas.offsetLeft=',this.canvas!.offsetLeft,
                        ',navDiv.offsetLeft=',this.nativeDiv!.offsetLeft);
                // let tx=p.x - this.nativeDiv.offsetLeft
                // let ty=p.y - this.nativeDiv.offsetTop
                let tx=p.x ;
                let ty=p.y ;

                if(this.canvas){
                    tx = tx + this.canvas.offsetLeft;
                    ty = ty + this.canvas.offsetTop;
                }
                r.x=tx;
                r.y=ty;
            }

            return r;
    }
    
    isTransDbg=false;
    transCanvasPos(p:RzRect):RzRect{
            let r={...p};
            if(rzIs.t)rzlog.trace(`RzNote[${this.id}].transClientPos : p=`,p);
            if(!this.nativeDiv) return r;
            if(rzIs.t || this.isTransDbg) rzlog.trace(`RzNote[${this.id}].transClientPos : p=`,p,
                    ',canvas.offsetLeft=',this.canvas!.offsetLeft,
                    ',navDiv.offsetLeft=',this.nativeDiv!.offsetLeft);

                    let offx0=this.nativeDiv.offsetLeft;

            let tr=this.nativeDiv.getBoundingClientRect();
            let tx=p.x - tr.left;
            let ty=p.y - tr.top;

            let offx1=0;
            let tx0=tx;
            if(this.canvas){
                offx1=this.canvas.offsetLeft;
                tx = tx - this.canvas.offsetLeft;
                ty = ty - this.canvas.offsetTop;
            }
            
            // r.x=tx-this.offx
            // r.y=ty-this.offy
            r.x=tx;
            r.y=ty;

           // alert('Vp3='+JSON.stringify(r)+', cnv='+Boolean(this.canvas)+',  tx0='+tx0+',tx='+tx+',offx='+offx0+',ofx1='+offx1);

            return r;
    }


 

    /***************** 
     * paint event
    */
    isInCanvas(e:MouseEvent){
        return true;
    }

    doDrawInit(e:Event){
        if(this.isEditLock) return;

        if(rzIs.d)rzlog.debug(`RzNote[${this.id}].doDrawInit:e=`,e,',curPen=',this.curPen)
        if(!this.isInCanvas(e as MouseEvent)) return;

        if(this.isPenEnabled && this.curPen?.penDown) this.curPen.penDown(this,e)
    }

    doDrawMove(e:Event){
        if(this.isEditLock) return;
        if(rzIs.t)rzlog.trace(`RzNote[${this.id}].doDrawMov.pos=`,this.pos)
        if(!this.isInCanvas(e as MouseEvent)) return;
        if(this.isPenEnabled && this.pos?.drawable  &&  this.curPen?.penMove)  this.curPen.penMove(this,e)
    }
    
    doDrawFinish(e:Event){
        if(this.isEditLock) return;
        let isCanvas=this.isInCanvas(e as MouseEvent)
        if(rzIs.d)rzlog.debug(`RzNote[${this.id}].doDrawFinish:isPenOk=`,this.isPenEnabled,`isCanvas=`,isCanvas,',curPen=',this.curPen,',pen.penUp=',this.curPen.penUp!==undefined,',e=',e)
        if(!isCanvas) return;

        let obj=this
        obj.pos={drawable:false,x:0,y:0}
        if(this.isPenEnabled &&this.curPen?.penUp) this.curPen.penUp(this, e)
    }
    /***************** 
     * drawing members
    */
   
    //addDrawPath(dobj:any){
    addDrawPath(dobj:RzoNotePath){
        if(rzIs.d)rzlog.debug(`RzNote[${this.id}].addDrawPath:dobj=`,dobj,',repo=',this.noteRepo,',onDrawChange=',this.onDrawChange)
        if(this.doIsEditOn()===false) return;
        
        let did=this.noteRepo?.getDrawer()?.uid;
        let uid=this.noteRepo?.getOwnerId();
        
        if(dobj && did) dobj.drawerId=did;
        if(dobj && uid) dobj.ownerId=uid;

        this.noteRepo?.addPath(dobj)
        
        if(this.onDrawChange) this.onDrawChange(dobj,this.noteRepo)
    }

    putDrawPath(dobj:RzoNotePath){
        const repo = this.getRepo();
        const drawerId = repo?.getDrawer()?.uid;
        if(drawerId !== dobj.drawerId) return;

      //  dobj.drawable = false;
        this.noteRepo?.putPath(dobj)
    }

    async getAllPath():Promise<RzRes<RzoNotePath[]>> {
        let pg=this.noteRepo!.getCurPageNo();
        let r = await this.pathTool.getAllPath(this.noteRepo!, pg);
        return r;
    }

    async getPathAt(cent_x :number,cent_y:number, w:number, h:number, shape?:string):Promise<RzRes<RzoNotePath>>{
        let pg=this.noteRepo!.getCurPageNo();
        //let r= await   this.pathTool.getPathAt(this.noteRepo!,pg,cent_x-this.offx,cent_y-this.offy,w,h,shape)
        let r= await this.pathTool.getPathAt(this.noteRepo!,pg,cent_x,cent_y,w,h,shape);
        return  r
    }

    curFocusObj:any|null =null
    setFocusPath(pobj:any){
        let old=this.curFocusObj;
        this.curFocusObj=pobj;
    }

    getFocusPath(){ return this.curFocusObj;    }


    /****************
     *  Ui Events
     */

    isNoteResizing=false;
    onResize=( rect:any)=>{
        //if(rect.isMoving && !rect.isEnd) return 
        if(this.isNoteResizing) {
            rzlog.debug("NoteResizing : SKIP!!!!!!");
            return;
        }
        this.isNoteResizing=true; 
        
            
        this.doFillWidth();
        if(rzIs.d)rzlog.debug(`RzNote[${this.id}].onResize : rect=`,rect,',ht=',parseInt(rect.height));
        if(rect){
                const r={width:rect.width, height:rect.height};

                this.initCanvas(r,"resize");
        }

        this.curBox=this.getBounds();
        this.repaint();
        this.isNoteResizing=false; 
    }

    doFillWidth(){
        //!DO NOT TOUCH below : fill with
        this.nativeDiv!.style.width="100%";
    }

    curBox?:RzRect;

    /********
     * Focused Noted
     */
    doBindFocusOnDoc(){
        let doc=document as any;

        if(!(doc._focusedNote)){
                doc._focusedNote=this.nativeDiv;
                rzlog.debug('doBindFocus: New focNt=',doc._focusedNote.id);
                this.doNotiFocused();
        } else if(doc._focusedNote!= this.nativeDiv) {
                doc._focusedNote=this.nativeDiv;
                rzlog.debug('doBindFocus: Replace focNt=',doc._focusedNote.id);
                this.doNotiFocused();
        }else {
            rzlog.debug('doBindFocus: Skip focNt=',doc._focusedNote.id);
        }
    }

    doNotiFocused(){
        //alert('select');
        let doc=document as any;
        //rzlog.debug('doBindFocus: Replace focNt=',doc._focusedNote.id,',onFus=',this.props?.onNoteFocused);
        if(this.props?.onNoteFocused)this.props.onNoteFocused(this);
    }

    static getFocusedNote(){
        let doc=document as any;
        return doc._focusedNote?._srcLet  ;
    }

    /************
     * Events
     */

    onMouseDown=(e:MouseEvent)=>{
        this.doBindFocusOnDoc();
        this.doDrawInit(e);
    }

    onMouseUp=(e:MouseEvent)=>{
        if(rzIs.d)rzlog.debug("onMouseUp:e=",e);
        this.doDrawFinish(e);
    }

    onMouseMove=(e:MouseEvent)=>{
        if(rzIs.t)rzlog.trace('note.mousemove : e=',e);
        this.doDrawMove(e);
    }

    addScale(dw:number,dh:number){
        this.scalew+=dw;
        this.scaleh+=dh;
        if(this.props?.onScaleChanged) this.props.onScaleChanged(this.scalew,this.scaleh);
        if(this.dragType=='vertical'){
            this.offx=0;
            this.offy=0;
        }

        this.repaint();
    }

    onMouseWheel=(e:WheelEvent)=>{
        if(rzIs.t) rzlog.trace('onmousewheel :wheelDelta=',e.deltaY );

        e.preventDefault();
        if(e.ctrlKey){
            let dt=0.25;
            let sc=this.getScale();
            if(e.deltaY>0 && sc.w-dt>0.25)  this.addScale( -dt, -dt);
            else if(e.deltaY<0 ) this.addScale( dt, dt) ;

        }  else {
            let pg=this.noteRepo?.getCurPageNo() ;
            let tpg=this.noteRepo?.getPageCount();
            
            if(rzIs.t)rzlog.trace('onmousewheel :wheelDelta=',e.deltaY,',pg=',pg,',tpg=',tpg );
            let isMoved=false;
            if(e.deltaY>0){
                //MOVE NextPage
               if(pg && (pg+1) <= tpg! ){
                    isMoved=true;
                    this.noteRepo?.setCurPageNo(pg+1);
               }
            } else {
                //MOVE NextPage
                if(pg && pg-1 > 0) {
                    isMoved=true;
                    this.noteRepo?.setCurPageNo(pg-1);
                }
            }
            this.doLoadBgImg();
            if(isMoved) this.repaint();
        }//else
        
    }//method

    /* key event ****************/
    onKeyDown=(e:KeyboardEvent)=>{
        //e.preventDefault()


        if(rzIs.d) rzlog.debug('RzNoteUi.keydown : e=',e);
        if( e.key==='r'){
            if(rzIs.d) rzlog.debug('REPAINT !!!!');
            this.repaint();
            e.preventDefault();
        }
    }

    onKeyUp=(e:KeyboardEvent)=>{
        //e.preventDefault()
        if(rzIs.d)rzlog.debug('RzNoteUi.keyup : e=',e);
    }

    /*****************
     * suporting components
     **/    
   // curPenAttr={type:'pen',width:2, color:'rgb(0,0,0)'}
    addToolbar(x:number,y:number){
        
        let toolbarDlg=MakeToolbarDlg(this,x,y);
        return toolbarDlg;
    }


    addBtn(btnTitle:string,x:number,y:number,onClick:(e:any)=>void ){
        let btn = new RzDragBtn({onClick:onClick});
        btn.setParent(this as RzUiLet );
        btn.setBounds(x,y, 64,64);
        btn.setTitle(btnTitle);
        btn.init();
    }

    async doLoadImg(img:HTMLImageElement){
        return new Promise((res,rej)=>{
            img.onload= async()=>{
                res(true);
            }
            img.onerror=()=>{
                rej(false);
            }
        })
    }
    isImgLoading=false;
    lastPgNoRequested=0;
    async doLoadBgImg(){
        if(!this.noteRepo?.getAttr){
            if(rzIs.d)rzlog.debug('test');
        }
        
        let cpg=this.noteRepo!.getCurPageNo();
        if(!this.noteRepo){
            return;
        }
        this.lastPgNoRequested=cpg;

        if(this.isImgLoading) return;

        this.isImgLoading=true;
        try{
            await this.doProcLoadBgImg(cpg,this.noteRepo);
        } catch(e) {
            rzlog.debug('RzNoteUi.doLoadBgImg : e=',e);
        }
        this.isImgLoading=false;

        if(this.lastPgNoRequested>0){
            cpg=this.lastPgNoRequested;
            this.lastPgNoRequested=0;

            this.isImgLoading=true;
            try {
                await this.doProcLoadBgImg(cpg,this.noteRepo);
            } catch(e) {
                rzlog.debug('RzNoteUi.doLoadBgImg : e=',e);
            }
            this.isImgLoading=false;
        }
    }


     async doProcLoadBgImg(cpg:number,ntRepo:RzNoteRepo){

        let ntId=ntRepo.getNoteId();
        if(rzIs.d)rzlog.debug(`RzNote[${this.id}].doLoadBgImg : noteId=`,ntId,',cpg=',cpg);

        let tr=await ntRepo.getAttr(cpg,'bg');
        if(IsFail(tr)) return ;

        // clear old background
        this.bgPrevPageNo=this.bgPageNo;
        this.bgImg=undefined;
        this.bgPageNo=undefined;

        let bgInfo=tr.data as any;
        if(rzIs.d)rzlog.debug(`RzNote[${this.id}].doLoadBgImg:bgInfo=`,bgInfo);
        
        if((!bgInfo ||!bgInfo.src) && this.bgImg ) {
            this.bgPrevPageNo=this.bgPageNo;
            this.bgImg=undefined; 
            this.bgPageNo=undefined;

            if(rzIs.d)rzlog.debug(`RzNote[`,this.id,`].doLoadBgImg:clear bgImg, bginfo=`,bgInfo);
            setTimeout(() => {this.repaint();}, 500);
            this.isImgLoading=false;
            return;
        }

        if(bgInfo && (!this.bgImg || (this.bgImg && (this.bgImg as HTMLImageElement).src !== bgInfo.src))){
            let timg=new Image();
            timg.src=bgInfo.src;
            let r= await this.doLoadImg(timg);
            
            this.bgPrevPageNo=this.bgPageNo;
            this.bgPageNo=cpg;
            this.bgImg=timg;
            //this.bgWidth=timg.width;
            //this.bgHeight=timg.height;

            this.defaultWidth=timg.width;
            this.defaultHeight=timg.height;

            rzlog.debug(`RzNote[${this.id}].doLoadBgImg DONE :bg.pg=${cpg} will repaint;`,
                 `r=`,r,`, bgimg.src=${timg.src},w=${timg.width},h=${timg.height}`);
        }

        this.doLayoutCanvas();
        await this.repaint();
    }


    /** ****************************
     *  rendering members
     */
    paintCnt=0
    onPaint=()=>{
        this.paintCnt++
        new Promise(()=> this.doRender());
    }


    doRender(){
        if(rzIs.d)rzlog.debug(`RzNote.render[`,this.id,`]]: type=`,this.type,',w=',this.canvas?.width,',h=',this.canvas?.height);
        
        this.renderPathAll();
    }

    isTransBegin=false
    setOffset(xoff:number,yoff:number){
 
        this.offx=xoff
        this.offy=yoff
        
        // if(!this.isTransBegin){
        //     this.ctx.save()
        //     this.isTransBegin=true
        // }

        //this.ctx?.translate(xoff,yoff)
    }   

    getOffset(){
        return {x:this.offx,y:this.offy};
    }



    addOffset(dx:number,dy:number){
        //@this.offy+=dy
        //@this.offx+=dx
        let ox=this.offx;
        let oy=this.offy;

        this.offy+=dy
        this.offx+=dx
        
        if(this.dragType =='vertical'){
            if(this.canvas){
                let w=this.defaultWidth;
                let cw=this.canvas.width;
                let tw=(cw/this.scalew);//to image width
                let toffx=this.offx/this.scalew;// to image offset
                if( toffx < 0 && -toffx+tw > w ) this.offx=ox;
                if( this.offx > 0 ) this.offx=ox;

                let h=this.defaultHeight;
                let ch=this.canvas.height;
                let th=(ch/this.scaleh);
                let toffy=this.offy/this.scaleh;
                if( toffy < 0 && -toffy+th > h ) this.offy=oy;
                if(this.offy > 0) this.offy=oy;
            }
        }
        
        //this.ctx?.translate(dx,dy)
    }

    isDrawing=false;
    isRulerOn=_isRulerOn;
    isOutlierOn=true;
    setRulerOn(b:boolean ){
        this.isRulerOn=b;
    }

    doClearCanvas(isDrawOff:boolean){
        
        if(this.ctx && this.canvas){
            //this.canvas.style.visibility='hidden';
            //this.canvas.style.visibility='visible';
            //let nctx=this.canvas.getContext('2d') as CanvasRenderingContext2D;

            this.ctx.globalAlpha=1.0;
            
            
            if(isDrawOff) {
                this.ctx.fillStyle =  this.canvasColor||'lightgray';
                this.ctx.strokeStyle = this.canvasColor|| 'lightgray';
                this.ctx.fillRect(0,0, this.canvas.width,this.canvas.height);
            } else {
                this.ctx.fillStyle = this.canvasColor||'white';
                this.ctx.strokeStyle = this.canvasColor||'white';
                this.ctx.fillRect(0,0, this.canvas.width,this.canvas.height);
                this.ctx.clearRect(0,0, this.canvas.width,this.canvas.height);
            }
            rzlog.debug("Clear Canvas(Bg) ========================= ");
        }
    }
    async renderPathAll(force?:boolean){
        
            
        if(this.isDrawOn===false){
            this.doClearCanvas(true); 
                return;
        } 

        if(this.isDrawing && !Boolean(force)) return;
        this.isDrawing=true;
       // this.ctx=this.canvas?.getContext('2d') as CanvasRenderingContext2D;
       rzlog.debug('RzNoteUi.renderPathAll : =========================');
        try{
            await this.doRenderPathAll();
            if(this.isPenDrawing && this.curPen?.draw){
                this.curPen.draw(this,this.ctx);
            } 

            if(isDbgUi) await this.doDrawDbg();
        }catch(e){
            rzlog.error('RzNoteUi.renderPathAll : e=',e);
        }
        
        
        this.isDrawing=false;
    }

    
    doDrawGrid(){
        if(! this.ctx) return;
        this.ctx.save();

  
        this.ctx.lineWidth =1;
        this.ctx.globalAlpha=1.0;

        this.ctx!.fillStyle = 'lightgray';
       
        if(!this.curBox) this.curBox=this.getBounds();
        if(rzIs.t)rzlog.debug("RzNoteUi.doDrawGrid : curBox=",this.curBox);
        
        
    
        let box=this.curBox;  // canvas.size
        //let box={width:this.defaultWidth,height:this.defaultHeight} 
        let dsz=this.toViewPos(0,0,10,10);
        let ycnt= box.height/dsz.height;
        let xcnt= box.width/dsz.width;
        for(let i=0;i<ycnt;i++)
            this.ctx!.fillRect(this.offx+1,+this.offy+dsz.height*i,box.width,1);
        for(let i=0;i<xcnt;i++)
            this.ctx!.fillRect(dsz.width*i +this.offx,1+this.offy,1,box.height);

        if(rzIs.d)rzlog.debug("RzNoteUi.doDrawGrid.end : curBox=",this.curBox);
        this.ctx.restore();
     }

    toViewSize(sz?:number):number{
        // console.log('toViewSize==========> res', sz, 'res1', (sz||1), 'scalew', this.scalew, 'result', (sz||1)*this.scalew)
        return (sz||1)*this.scalew;
    }
    toNoteSize(sz?:number):number{
        return (sz||1)/this.scalew;
    }

 
    toViewPos(x:number, y:number,w?:number,h?:number):RzRect{
        
        let np={
            x:(x)*this.scalew + this.offx,
            y:(y)*this.scaleh + this.offy,

           width:(w||0)*this.scalew,
           height:(h||0)*this.scaleh};

           if(rzIs.t) rzlog.debug(`NoteX.toViewPos : org=`,{x:x,y:y}, `np=`,np,',scw=',this.scalew,',xof=',this.offx,',yof=',this.offy);
        return np;
    }


    toNotePos(x:number,y:number,w?:number,h?:number){
        let np={
            x:(x - this.offx)/this.scalew, //- this.offx
            y:(y - this.offy)/this.scaleh, //- this.offy
            // x:(x)/this.scalew + this.offx,
            // y:(y)/this.scaleh + this.offy,
            width: (w||0)/this.scalew,
            height: (h||0)/this.scaleh };

            if(rzIs.t)rzlog.debug(`NoteX.toNotePos : org=`,{x:x,y:y}, `np=`,np,',scw=',this.scalew,',xof=',this.offx);
        return np;
    }


    async doDrawDbg(){
        if(! this.ctx) return;
        this.ctx.save();

        this.ctx.fillStyle = "blue";
        this.ctx.strokeStyle = "blue";
        this.ctx.lineWidth =1;
        this.ctx.globalAlpha=1.0;
        this.ctx.font  = `12px serif`;

        let pgno=0;
        let yoff=10;
        if(this.noteRepo) pgno=this.noteRepo.getCurPageNo();
        let rbg=await this.noteRepo?.getAttr(pgno,'bg');
        let bg=rbg?.data as any
        //let bg=this.bgImg;
        let outstr='note: id='+this.id+',noteId='+this.noteId
            +',oid='+this.getOwnerId() +',did='+this.getDrawerId()
            +', pgno='+pgno+', cnt='+this.paintCnt+',bg.src='+bg?.src
            +',pbgNo='+this.bgPrevPageNo+',thBgNo='+this.bgPageNo
        this.ctx.strokeText(outstr, +this.offx + 10, +this.offy +yoff);
 
        yoff+=20;
        let outstr2='curAttr.color='+this.curPenAttr.color
            +',scalew='+this.scalew.toPrecision(2)
            +',scaleh='+this.scaleh.toPrecision(2)
            +', ft='+ (this.curPenAttr.font||'')
            +', fclr='+ (this.curPenAttr.fontColor||'')
            +', fsz='+ (this.curPenAttr.fontSize||'') 
            +', offx='+ (this.offx) 
            +', offy='+ (this.offy) 
            ;
        this.ctx.strokeText(outstr2, +this.offx + 10, this.offy + yoff);

        if(this.curPen){
            yoff+=20;
            
            let outstr3='cpen '
                
                +'ty='+this.curPen.type
                +', clr='+this.curPen.color
                +', w='+ this.curPen.width
                +', ft='+ (this.curPen.font||'')
                +', fclr='+ (this.curPen.fontColor||'')
                +', fsz='+ (this.curPen.fontSize||'') ;            
            this.ctx.strokeText(outstr3, this.offx + 10, this.offy + yoff);
        }
        
        let r2=await this.noteRepo!.iterPath();
        if(!r2.data) return;
        

        yoff+=20;
        let paths=r2.data!;
        
        let dumpFltStr=this.doFilterDumpString();
        this.ctx.strokeText(dumpFltStr, this.offx + 10, this.offy + yoff);


        yoff+=20;
        paths=this.doFilterPath(paths);
        for(const i in paths){
            let p=paths[i];
            rzlog.debug('p=',p);
            yoff+=20;
            let outstr4='i='+i+',did='+(p.drawerId||'')
                +',id='+p.id+',sq='+p.seq+', d.ty='+p.type;
                if(p.points!==undefined && p.points.length>0) outstr4+=', x='+p.points[0].x +', y='+p.points[0].y;

                if(p.x0!==undefined && p.x0>=0) outstr4+=', x0='+p.x0 +', y0='+p.y0;
                if(p.attr) outstr4+=', a.clr='+p.attr.color;
                if(p.attr?.opacity) outstr4+=', a.al='+p.attr.opacity;
                if(p.attr?.font) outstr4+=', a.ft='+p.attr.font;
                if(p.attr?.fontSize) outstr4+=', a.fsz='+p.attr.fontSize;

            this.ctx.strokeText(outstr4, this.offx + 10, this.offy + yoff);
        }

        this.ctx.restore();
    }

    shareProc = new RzNoteShareProc ()
    doFilterPath(paths:RzoNotePath[]){
         
        paths=this.shareProc.filterPath(this,paths);
        return paths;
    }

    doFilterDumpString(){
        return this.shareProc.dumpSharing(this);
    }

    async doRenderPathAll(){
        
        if(this.ctx && this.canvas){
            
             this.ctx.fillStyle =  this.canvasColor||'white';
             this.ctx.strokeStyle =  this.canvasColor||'white';
             this.ctx.fillRect(0,0, this.canvas.width,this.canvas.height);

            this.ctx.globalAlpha=1.0;
            this.ctx.clearRect(0,0, this.canvas.width,this.canvas.height);
            rzlog.debug("Clear Canvas ========================= :w="+this.canvas.width+',h='+this.canvas.height);
        }


        if(this.bgImg) this.doDrawBg();


        if(this.isRulerOn  ) this.doDrawGrid();
        
        if(this.isOutlierOn||true ) this.doDrawOutlier();

        //this.ctx.fillRect(10,10,100,100)
        if(rzIs.d) rzlog.debug(`RzNote.render[`,this.id,`].renderPathAll > ctx.clear :w=`,this.canvas?.width,',h=',this.canvas?.height);

        if(!this.noteRepo )  return;
        

        let r=await this.noteRepo.pathCount();
        if(r.data===0) return ;
    
        let r2=await this.noteRepo.iterPath();
        if(!r2.data) return;

        let paths : RzoNotePath[] =r2.data;
        let ol=paths?.length;
        paths=this.doFilterPath(paths);

        let nl=paths?.length;
        if(ol !==undefined&& nl!==undefined && ol>0 && nl==0){
            let a=1;
            if(this.ctx && this.canvas){
                return;
            }
        }

        let postPath: RzoNotePath[]=[];
        for(let i in paths){
            let p = paths[i];
            let opt=null;
            if(this.curFocusObj===p) opt={focused:true};
            // console.log('aabb->p',p);
            if(p.drawable){
                
                if(p.type ==='memo') postPath.push(p);
                else {
                    this.doRenderPath(p,opt);
                }
            } 
        }//for

        for(let i in postPath){
            let p = postPath[i];
            let opt=null;
            if(this.curFocusObj===p) opt={focused:true};

            if(p.drawable){
                // console.log('aabb->pp',p);
                this.doRenderPath(p,opt);
            } 
        }//for
       
    }//func



    isPenDrawing=false;
    beginPen(){
        this.isPenDrawing=true;
    }

    endPen(){
        this.isPenDrawing=false;
    }

    doDrawOutlier(){
        if(!this.ctx) return;

        this.ctx.save();
        this.ctx.globalAlpha=1.0;
        let w=(  this.defaultWidth)  ;
        let h=( this.defaultHeight)  ;

        let box=this.toViewPos(0,0,w,h);
        this.ctx.strokeStyle='black';
        this.ctx.lineWidth=0.1;
        //this.ctx.fillStyle='black';
        
        this.ctx.beginPath();
        //this.ctx.rect(310,310,100,100)
        this.ctx.rect(box.x,box.y,box.width,box.height);
        this.ctx.stroke();

        this.ctx.restore();
    }

    doDrawBg(){
        if(!this.ctx || !this.bgImg) return;
            
            if(rzIs.d)rzlog.debug(`RzNote.render[`,this.id,`]renderPathAll.bgImg=`,this.bgImg);
            //let oOpacoity= this.ctx.globalAlpha 
            this.ctx.save();
            this.ctx.globalAlpha=1.0;

            let cw=this.canvas!.width;
            let ch=this.canvas!.height;
            
            let bw=this.bgImg.width;
            let bh=this.bgImg.height;
            //@
            let scw=this.bgImg.width * this.scalew;
            let sch=this.bgImg.height * this.scaleh;

            // let scw=this.bgImg.width;
            // let sch=this.bgImg.height;

            let p=this.toViewPos(0,0);

            rzlog.debug("RzNote.doDrawBg:x=",p.x,",y=",p.y,"w=",cw,",h=",ch,',scw=',scw,',sch=',sch);
   
            this.ctx.drawImage(this.bgImg,
                0,0,bw,bh,   p.x,p.y, scw,sch);
               

            this.ctx.restore();
    }

    doRenderPath(p:RzoNotePath,opt:any) {
        let rdr=this.getRender(p.type!);
        if(rdr) rdr.draw(this.ctx,p,opt);
    }

    getRender(type:string){
        return this.renderFac.getRender(type,{});
    }

    drawPen(ctx:any, p:RzoNotePath){
        let rdr=this.renderFac.getRender(p.type!,{});
        if(!rdr || !p.x || !p.y || !p.x0 || !p.y0) return;

        rdr.draw(ctx,p,{});
        if(rzIs.t) rzlog.trace('rdr=',rdr,',path=',p);
     }

    /**
     * 
     * @param {*} type 
     * @param {*} tgtDiv 
     * @param {*} opts 
     * @returns 
     */
    static AddNoteLetByDiv(type:string,tgtDiv:HTMLElement,opts:RzUiOpts){
            
        let r=new RzNote({noteType:type});

        
        r.init({className:'rz-note',parentDiv:tgtDiv,...(opts?opts:{})});

        return r;
    }
}//class




/***************** */
export class RzDrawNote extends RzNote {
    memos:any[]=[];
    isMemosVisible:boolean =true;
    
    constructor(opts?:RzNoteOpts){
        super(opts);
        this.isDraggable=true;
    }

    doInit(opts:RzUiOpts){
        super.doInit(opts);
        return true;
    }

    setMemosVisible(b:boolean){
        this.isMemosVisible=b;
        for(let i in this.memos){
            let m=this.memos[i];
            m.nativeDiv.style.visibility=b?'visible':'hidden';
        }//for
    }

      
    addMemoNote(x:number,y:number,w:number,h:number){
        let popDlg=new RzMemoDlg();

        popDlg.setParent(this as RzUiLet);
        popDlg.setBounds(x,y,w,h);
        
        popDlg.init();

        this.memos.push(popDlg);
        return popDlg;
    }
    
}//class




export interface RzPageCalc {

    calc(note:RzNote):RzRect ;
}


class RzFitPageCalc {

    calc(note:RzNote,canvasRect:RzRect,dir?:string){
        const canvasHRate=note.defaultHeight/note.defaultWidth; //900:640 = rel(h=883,w=1150=>0.76)
        const canvasWRate=note.defaultWidth/note.defaultHeight; //900:640 = rel(h=883,w=1150=>0.76)
        //const canvasWRate = 1;
        let tscale=1.0;
        const wrate = canvasRect.width/note.defaultWidth;   // w/w0; 640:420
        const hrate = canvasRect.height/note.defaultHeight; // h/h0; 900:592 = (883:592=>1.5)

        // tw= w/w0*h0
        const tw=(wrate)*(note.defaultHeight*canvasWRate)  ;
        // th= h/h0*w0
        const th=(hrate)*note.defaultWidth ;

        let dw=canvasRect.width - tw;
        let dh=canvasRect.height - th;


        
        let trect=null;
        let isWidth=false;
        let top=0;
        if(dir===undefined){
            if(dw>0 && dh>0 ){
                if(dw<dh) {
                    isWidth=false;
                    rzlog.debug("TOSCALE : cvs dw>0,dh>0, WIDTH ");
                } else {
                    isWidth =true;
                    rzlog.debug("TOSCALE : cvs dw>0,dh>0, HIEGHT");
                } 
            }else if(dw>0){
                isWidth=true;
                rzlog.debug("TOSCALE : cvs dw>0");
            } else {
                isWidth=false;
                rzlog.debug("TOSCALE : cvs dh>0");
            }
        }else if(dir==='width') {
            top=30;
            isWidth=true;
        
        }else if(dir==='height') {
            top=0;
            isWidth=false;
        }


        if(isWidth){
            trect={ x:0, y:top, width:canvasRect.width,
                    height: (canvasRect.width*canvasHRate), };
            tscale=wrate;
        } else {
            trect={ x:0, y:top, height:canvasRect.height,
                    width: (canvasRect.height*canvasWRate), };
            tscale=hrate;
        }

        // if(dh<0) dh=canvasRect.height
        // if(dw<0) dw=canvasRect.width
        

        rzlog.debug("RzNote.fitToPage: cvsRate=",canvasHRate,
        ",tw=",tw,",th=",th,"; dw=",dw,",dh=",dh,
        "; wrate=",wrate,",hrate=",hrate,',tgtRct=',trect);

       // this.canvasPos=trect

       if(!note.scaled){
            note.scalew=tscale;
            note.scaleh=tscale;
       }
        
        
        return trect;
    }
}//class

/********************
 * 
 */

class RzNoteRepoFacDef implements RzNoteRepoFac {
    newRepo(pr?: RzNoteOpts ): RzNoteRepo {
        let v=new RzNoteRepo();
        return v;
    }

    static defFac=new RzNoteRepoFacDef();
    static getDefaultFac(){
        return RzNoteRepoFacDef.defFac;
    }
}//class


/********************
 * 
 */
class RzNoteShareProc {
    dumpSharing(note: RzNote){
        let sharing=note.getSharing();
        if(!sharing) return '';
        
        let r='X[';

        if(sharing.isReadOn)r+='u';
        else r+='-';
        if(sharing.isGroupReadOn)r+='g';
        else r+='-';
        if(sharing.isOtherReadOn)r+='o';
        else r+='-';
        r+=']';

        r+=',R['
        if(sharing.isWritingReadOn)r+='u';
        else r+='-';
        if(sharing.isGroupWritingReadOn)r+='g';
        else r+='-';
        if(sharing.isOtherWritingReadOn)r+='o';
        else r+='-';
        r+=']';

        r+=',V['
        if(sharing.isMyWritingAllOn)r+='a';
        else r+='-';
        if(sharing.isMyWritingReadOn)r+='u';
        else r+='-';
        if(sharing.isMyGroupWritingReadOn)r+='g';
        else r+='-';
        if(sharing.isMyOtherWritingReadOn)r+='o';
        else r+='-';
        r+=']';

        r+=',W['
        if(sharing.isWritingReadOn)r+='u';
        else r+='-';
        if(sharing.isGroupWritingReadOn)r+='g';
        else r+='-';
        if(sharing.isOtherWritingReadOn)r+='o';
        else r+='-';
        r+=']';

        return r;
    }

    filterPath(note: RzNote, paths: RzoNotePath[]) {
        //note.getOwnerId();
        //note.getRepo()?.getOwnerId();

        if(note.isVisible !== undefined && note.isVisible() == false) return [];
        let oid=note.getOwnerId();
        let drawerId=note.getDrawerId();
        let sharing=note.getSharing();
        //rzlog.debug("NOTE.getSharing : sharing=",sharing);
        //rzlog.debug("NOTE-FILTER : sharing=",sharing,',oid=',oid,',drawerId=',drawerId,',paths=',paths);
        
console.log('filterPath', sharing)

        if(sharing){
            let srcId=drawerId;
            //if(oid!==drawerId)  srcId=oid;

            if(sharing.isReadOn !== undefined && sharing.isReadOn==false) return [];

            // 필기없이보기
            if(sharing.isMyWritingAllOn !== undefined && sharing.isMyWritingAllOn==false) {
                return [];
            }            

            // 나의 필기보기 OFF && 상대방의 필기 보기 OFF
            if((sharing.isMyWritingReadOn !== undefined && sharing.isMyWritingReadOn==false) &&
                //(sharing.isWritingReadOn !== undefined && sharing.isWritingReadOn==false) && 
                //(sharing.isOtherWritingReadOn !== undefined && sharing.isOtherWritingReadOn==false) &&
                (sharing.isMyGroupWritingReadOn !== undefined && sharing.isMyGroupWritingReadOn==false)) {                    
                return [];                
            }
        
            // 필기공유요청이 안되어 있을때
            if (sharing.isGroupWritingReadOn !== undefined && sharing.isGroupWritingReadOn==false) {
                paths = paths.filter((el) => el.drawerId === srcId);
            } 

            // 필기공유요청이 되어 있을때
            if (sharing.isGroupWritingReadOn !== undefined && sharing.isGroupWritingReadOn==true) {                
                if (!Boolean(sharing.isMyGroupWritingReadOn)) {
                    paths = paths.filter((el) => el.drawerId === srcId);
                }
            } 

            // 내필기보이기 (선생님의 경우에만 사용)
            if (sharing.isWritingReadOn !== undefined && sharing.isWritingReadOn==false) {                
                if (oid !== drawerId) {
                    paths = paths.filter((el) => el.drawerId === srcId);                    
                }
            }            

            // 나의 필기보기
            if (sharing.isMyWritingReadOn !== undefined && sharing.isMyWritingReadOn==false) {                
                paths = paths.filter((el) => el.drawerId !== srcId);                
                return paths;
            }

            // 상대방 필기보기
//@            if (sharing.isMyGroupWritingReadOn !== undefined && sharing.isMyGroupWritingReadOn==false) {                
           if (sharing.isMyGroupWritingReadOn !== undefined && sharing.isMyGroupWritingReadOn==false) {                
                   //@ paths = paths.filter((el) => el.drawerId === srcId);
                // return paths;
                paths = paths.filter((el) => el.drawerId === srcId);
            } 

            // if (sharing.isWritingReadOn !== undefined && sharing.isWritingReadOn==false) {
            //     paths = paths.filter((el) => el.drawerId !== srcId);
            //     return paths;
            // }

            // if (sharing.isOtherWritingReadOn !== undefined && sharing.isOtherWritingReadOn==false) {
            //     paths = paths.filter((el) => el.drawerId === srcId);
            //     return paths;
            // }
        }

        return paths;
    }
}


import { FT_LAYOUT2_FRAME } from "../../consts";
import { IsFail, rzlog } from "../../inc";
import { FtUiContext, ToDbgUserStat } from "../ftclass2/ftui.context";
import { FtCamFlat } from "./ftcam.ui";
import { RzBtn, RzCol, RzRow, RzTxt } from "./rzcomp";
import { FtBasFrame, FtBothStat } from "./ftframe.bas.ui";
import { FtBtmStat } from "./ftbtm.ui";
import { FtClassEvent, FtClassEventEnum } from "../../ftclass.event";
import { FtoClassInfo, FtoClassNoti, FtoClassNotiEnum, FtoUser, FtoUserTypeEnum } from "../../dto/ftclass.dto";
import { FT_JOIN_INTERVAL } from "../../../config2";



/**********
 * 
 */

interface FtJoinFrameProp {
    classId?:string;
    userInfo?:FtoUser;
}


interface FtJoinFrameStat extends FtBothStat {
    fetchCount?:number
    userInfo?:FtoUser
}


export class FtJoinFrame extends FtBasFrame<FtJoinFrameProp,FtJoinFrameStat> {
    constructor(pr?: any) {
        super(pr);
        this.state = {fetchCount:0}
    }
    hd:any
    componentDidMount(): void {

            this.hd=setInterval(async () => {
                //this.doReqJoin('1234');
                let ctx=this.getCtx();
                let repoMng=this.getRepoMng();
                if(!repoMng) return;
                let r=await repoMng.getClassInfo()
                if(IsFail(r)){
                    rzlog.debug('no classInfo:'+r.message)
                    return;
                }

                
                let {token, ...ui} = {... ctx.userInfo} as any;
                rzlog.debug('update UserInfo.0:',ui,',ui=',ui.userType)
                if(ui?.userType ==undefined){
                    rzlog.debug('update UserInfo.1:',ui)
                    let ru=  repoMng.getUserInfo()
                    if(ru){
                        rzlog.debug('update UserInfo.2:',ru)
                        ctx.setGlobalCtx({...ctx,userInfo:ru} as FtUiContext);
                        this.setState({userInfo:ru})
                    }
                } 

                let cls=r.data;
                let cnt=0;
                cls?.attendants?.forEach((t)=>{
                    if(t.isOnline) cnt++;
                });
                let attz=cls?.attendants.map((t)=>{ 
                    return {username:t.username}
                });
                if(ctx.camCount!=cnt) ctx.setGlobalCtx({...ctx,camCount:cnt,attendants:attz} as FtUiContext);

                rzlog.debug("OnlineCnt=",cnt,',atts=',cls?.attendants);
                this.setState({fetchCount: (this.state.fetchCount||0)+1})
            },FT_JOIN_INTERVAL);
    }
    componentWillUnmount(): void {
        clearInterval(this.hd);
    }


    doDrawTop() {
        return (<RzRow style={{ width: '100%', height: 80, backgroundColor: '#222222' }}>
            <RzBtn style={{ marginTop: 30, marginLeft: 30 }} onClick={() => { window.close(); }} title="수업 나가기" />
        </RzRow>)
    }

    doDrawBody() {
        let ctx = this.getCtx();
        let camCount = ctx.camCount || 0;
        let ui = ctx.userInfo;
        let classId = ctx.classId;
        let isDbg=true;

        let dbgMsg='';
        
        
        if(isDbg){
            let vcnt=ctx.videoCount;
            let atts=ctx.attendants?JSON.stringify(ctx.attendants):'[]';
            dbgMsg=ToDbgUserStat(ctx,this.state.fetchCount );
        }
        rzlog.debug("USERINFO=",ui,",st.Ui=",this.state.userInfo);
        return (<RzCol style={{ width: '100%', height: '100%', justifyContent: 'space-between' }}>
            {/* {(ctx.isDirectJoin && !ctx.isLoad) && ( // 로딩처리
                <div className="loading-spinner" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>                
                    <div>Loading...</div> 
                </div>
            )} */}
            <RzRow style={{ width: '100%', height: 40 }}>  
            </RzRow>
            <RzRow style={{ backgroundColor: '#222222' }} className="joinCam">
                {(!ctx.isSkipJoin) && (<FtCamFlat />)}
            </RzRow>
            <RzRow style={{ width: '100%', height: 40, justifyContent: 'center' }}>
                {(camCount >= 2 && ui?.userType == 'teacher' && ctx.isLoad) && ( // ctx.isLoad 추가
                    <RzBtn style={{ backgroundColor: '#25A8F2', borderRadius: 5, width: 200, height: 40, justifyContent: 'center' }}
                        onClick={() => { this.fetchStart(classId); }}
                        textStyle={{ color: 'white', marginTop: 8, fontSize: 15 }} 
                        title={"수업 시작하기"} />
                )}
                {(camCount >= 2 &&( ui?.userType ==FtoUserTypeEnum.OBSERVER)) && ( // ui?.userType == FtoUserTypeEnum.STUDENT
                    <RzBtn style={{ backgroundColor: '#25A8F2', borderRadius: 5, width: 200, height: 40, justifyContent: 'center' }}
                        onClick={() => { this.doReqJoin(classId); }}
                        textStyle={{ color: 'white', marginTop: 8, fontSize: 15 }} 
                        title="참여 요청하기" />
                )}
                {/* { (<RzBtn title='   '  
                    style={{ marginLeft:10, backgroundColor: 'gray', borderRadius: 5, width: 40, height: 40, justifyContent: 'center' }}
                    onClick={() => { 
                        if(this.hd) {
                            clearInterval(this.hd);     
                            this.hd=undefined;
                        }
                    }} />)} */}
            </RzRow>
            <RzRow style={{ width: '100%', height: 40, justifyContent: 'center' }}>
                <RzTxt text={"수업대기중"+ dbgMsg} />
            </RzRow>
        </RzCol>
        )
    }

    doDrawBottom() {
        return (<FtBtmStat isJoinOff={true} />)

    }

    doReqJoin(classId?: string) {

        this.doSendJoinReq((ok)=>{
            if(ok){
                let ctx = this.getCtx();
                let ui = ctx.userInfo;
                rzlog.debug("ctx=", ctx);

                if(ui?.userType==FtoUserTypeEnum.STUDENT){
                    ctx.setGlobalCtx({ ...ctx, layoutType: FT_LAYOUT2_FRAME } as FtUiContext);
                    
                }else {                                        
                    //ctx.setGlobalCtx({ ...ctx, layoutType: FT_LAYOUT2_FRAME ,isEditDisabled:true} as FtUiContext);

                    let rmng = this.getRepoMng();
                    if (!rmng) return;                    

                    let ui = ctx.userInfo;                               
                    let m = { type: FtoClassNotiEnum.OVBSERVER_JOIN_REQ, fromId: ui?.username, title:'학부모 참관수업 요청',body: '참관수업 요청이 들어왔습니다.\n참관을 허용하시겠습니까?', targetId:ui?.uid, targetType:'observer' } as FtoClassNoti;
                    rmng.sendClassNoti(m);
                }
             }
        });
    }


    async doSendJoinReq(cb?:(ok:boolean)=>void) {

        /* 1. 수업 참여 요청
        let repoMng=this.getRepoMng();
        if(!repoMng) {alert('no repoMng'); return;}

        let rq={cmd:'joinReq',classId:this.getCtx().classId} as FtoClassNoti;
        let r=await repoMng?.sendClassNoti(rq);

        if(IsFail(r)){
            if(cb) cb(false);
        } else {
            if(cb) cb(true);
        } */

        if(cb) cb(true);
        
    }
    /********************* */

    async fetchStart(classId?: string) {
        if (!classId) {
            alert('no classID=' + classId);
            return;
        }
        //alert('clasSId='+classId);

        let ctx = this.getCtx();
        rzlog.debug("CTx=", ctx);
        //ctx.setGlobalCtx({ ...ctx, layoutType: FT_LAYOUT2_FRAME } as FtUiContext);
        let repoMng = this.getRepoMng();
        if (!repoMng) { alert('no repoMng'); return; }
        /** 24.1.24 beginAt 추가 */
        let r = await repoMng.putClassInfo(classId, { classId: classId, stat: 'open', beginAt:new Date() } as FtoClassInfo);
        if(IsFail(r)) return;
        
        let sesId = ctx.userInfo?.sesId

        let ev = new FtClassEvent({ cmd: FtClassEventEnum.CLASS_BEG, classId: classId, sesId: sesId });
        //this.notiClassEvent(ev,srcOpt)
        repoMng.notiClassEvent(ev);

    }

 

};//class



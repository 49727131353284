// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("icons/book.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".disableCaret{\n    -webkit-user-select: none;  /* Chrome all / Safari all */     /* Firefox all */      /* IE 10+ */\n     user-select: none;       \n  }\n\n.rz-book {\n\n} \n\n\n.ic-no-image{\n    width: 210px;\n    height: 210px;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n    background-size: contain;\n    display: inline-block;\n    \n  }", "",{"version":3,"sources":["webpack://./src/ftnote/note.ui/rzbook.ui.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB,GAAG,4BAA4B,MAC5B,gBAAgB,OAChB,WAAW;KACtC,iBAAiB;EACpB;;AAEF;;AAEA;;;AAGA;IACI,YAAY;IACZ,aAAa;IACb,6DAAyC;IACzC,wBAAwB;IACxB,qBAAqB;;EAEvB","sourcesContent":[".disableCaret{\n    -webkit-user-select: none;  /* Chrome all / Safari all */\n    -moz-user-select: none;     /* Firefox all */\n    -ms-user-select: none;      /* IE 10+ */\n     user-select: none;       \n  }\n\n.rz-book {\n\n} \n\n\n.ic-no-image{\n    width: 210px;\n    height: 210px;\n    background: url(icons/book.svg) no-repeat;\n    background-size: contain;\n    display: inline-block;\n    \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;


import { on } from "events";
import { FtoClassInfo, FtoMemo, FtoNoteInfo, FtoUser } from "../dto/ftclass.dto";
import { FtxNote } from "../dto/ftclass2.dto";
import { NewFail, NewOk, RzReq, RzRes } from "../inc";
import { RzNote, RzNoteRepo } from "../note.ui";
import { RzoNoteShare } from "../note.ui/rznote.ui.dto";





export const DefMemos = [
    // { id: '1', body: '테스트 1', ownerId: '111' },
    // { id: '2', body: '테스트 2', ownerId: '111' },
    // { id: '3', body: '테스트 2', ownerId: '111' },
    // { id: '4', body: '테스트 2', ownerId: '111' },
    // { id: '5', body: '테스트 2', ownerId: '111' },
    // { id: '6', body: '테스트 2', ownerId: '111' },
] as FtoMemo[];


export const DefNotes =
    [
        { noteId: '1', title: '귀가트이는 영어1', type: 'book', isShareOn: true, isSelected: false, isShowOn: true, pageAt: 0, pageCount: 1 },
        { noteId: '2', title: '귀가트이는 영어2', type: 'book', isShareOn: true, isFloatable: true, isShowOn: true, pageAt: 0, pageCount: 1 },
        { noteId: '3', title: '내 노트', type: 'note', isFloatable: true, isShowOn: true, pageAt: 0, pageCount: 1 },
        { noteId: '4', title: '학생 노트', type: 'note', isFloatable: true, isShowOn: true, pageAt: 0, pageCount: 1 },
        { noteId: '5', title: '귀가트이는 답안', type: 'answer', isShowOn: true, isFloatable: true, isFloatingOn: true, pageAt: 0, pageCount: 1 },
    ] as FtxNote[];


export const DefNoteInfos =
    [
        new FtoNoteInfo({ noteId: '1', title: '귀가 트이는 영어1/출판사', type: 'book', visible: true, total: 4, pageNo: 0 }),
        new FtoNoteInfo({ noteId: '2', title: '귀가 트이는 영어2/출판사', type: 'book', visible: true, total: 4, pageNo: 0 }),
        new FtoNoteInfo({ noteId: '3', title: '내 노트', type: 'note', visible: true, total: 1, pageNo: 0 }),
        new FtoNoteInfo({ noteId: '4', title: '학생 노트', type: 'note', visible: true, total: 1, pageNo: 0 }),
        new FtoNoteInfo({ noteId: '5', title: '귀가트이는 답안', type: 'book', subType: 'answer', visible: true, total: 4, pageNo: 0 }),

        // {noteId:'1', title:'귀가트이는 영어1',type:'book', isShareOn:true, isSelected:false , isShowOn:true, pageAt:0, pageCount:1},
        // {noteId:'2', title:'귀가트이는 영어2',type:'book', isShareOn:true, isFloatable:true, isShowOn:true,pageAt:0, pageCount:1} ,
        // {noteId:'3', title:'내 노트',type:'note', isFloatable:true,  isShowOn:true,pageAt:0, pageCount:1} ,
        // {noteId:'4', title:'학생 노트',type:'note', isFloatable:true, isShowOn:true,pageAt:0, pageCount:1} ,
        // {noteId:'5', title:'귀가트이는 답안',type:'answer', isShowOn:true, isFloatable:true, isFloatingOn:true,pageAt:0, pageCount:1} ,
    ] as FtoNoteInfo[];

let urls = [
    { src: 'https://icar.inje.ac.kr/_attach/inje/editor-viewer/out/_attach/old/icar/105/32_631bd941db1cdd62673bc3d20908e2c8.pdf.files/00001.png' },
    { src: 'https://icar.inje.ac.kr/_attach/inje/editor-viewer/out/_attach/old/icar/105/32_631bd941db1cdd62673bc3d20908e2c8.pdf.files/00002.png' },
    { src: 'https://icar.inje.ac.kr/_attach/inje/editor-viewer/out/_attach/old/icar/105/32_631bd941db1cdd62673bc3d20908e2c8.pdf.files/00003.png' },
    { src: 'https://icar.inje.ac.kr/_attach/inje/editor-viewer/out/_attach/old/icar/105/32_631bd941db1cdd62673bc3d20908e2c8.pdf.files/00004.png' },
];

let defRepos: { [key: string]: RzNoteRepo } = {};
let isInit = false;

export const Def_getDefRepo = (k: string): RzRes<RzNoteRepo> => {
    if (!isInit) {
        isInit = true;
        DefNotes.forEach(t => {
            if (t && t.noteId) {
                let repo = new RzNoteRepo();

                if (t.type == 'book' || t.type == 'answer') {
                    for (let i = 0; i < 4; i++) {
                        repo.addPage(i + 1);
                        repo.setAttr(i + 1, "bg", urls[i]);
                    }
                } else {
                    repo.addPage(1);
                }

                defRepos[t.noteId] = repo;
            }
        })
    }
    let r = defRepos[k];
    if (r) return NewOk(r);

    return NewFail('no repo');
}

/********************** */
export const cmToFtxNotes = (notes: FtoNoteInfo[], ui?: FtoUser, sharings?: RzoNoteShare[], oldNotes?:FtxNote[]): FtxNote[] => {

    let vs = notes.map(t => {
            let sh = sharings?.find(x => x.noteId == t.noteId);
            let onote = oldNotes?.find(x => x.noteId == t.noteId);
            let ntx = cmToFtxNote(t, ui, sh);
            if(onote ){
                delete onote.info;
                ntx={...ntx,...onote}
                // ntx.isShowOn=onote.isShowOn;
                // ntx.isFloatingOn=onote.isFloatingOn;
                // ntx.pageAt=onote.pageAt;
                // ntx.isSelected=onote.isSelected;
            }
            return ntx;
        });

    return vs;
}



const isShareOnByNoteShare = (note: FtoNoteInfo, sh: RzoNoteShare, ui?: FtoUser): boolean => {
    // if (note.type == 'note' && ui?.uid !== note.ownerId) {
    if (note.type == 'note') {
        if (sh.isGroupReadOn) return true;
        //if(sh.isOtherReadOn) return true;
        return false;
    }
    //    if(note.subType=='answer' && ui?.uid !==note.ownerId) {
    if (note.subType == 'answer') {
        if (sh.isGroupReadOn) return true;
        //if(sh.isOtherReadOn) return true;
        return false;
    }

    return true;
}

export const cmToFtxNote = (note: FtoNoteInfo, ui?: FtoUser, sh?: RzoNoteShare): FtxNote => {
    let floating = false;

    let isShowOn = false;

    if (note.visible) isShowOn = true;
    if (note.visible) floating = false;

    /** 2023.11.13 디폴트 float 안되게 처리 by lee */
    //if(note.type=='book' && note.subType!=='answer') {floating=false; isShowOn=true;}
    if (note.type == 'book' || note.type === 'note') { floating = false; isShowOn = true; }
    if (ui?.uid &&( note.ownerId!== ui.uid)) floating = false;
    else if (ui?.username &&( note.ownerName!== ui.username)) floating = false;

 
    
    let r = {
        noteId: note.noteId,
        title: note.title,
        isSelected: note.selected,

        isShowOn: isShowOn,
        /** 24.1.18 all tab init share false - jwg */
        // isShareOn: (note.type==='book' && note.subType !== 'answer'), 
        isShareOn: false,

        isFloatable: true,
        isFloatingOn: floating,

        pageCount: note.total,
        pageAt: note.pageNo,
        ownerName: note.ownerName,
        info: note,

    } as FtxNote;

    if (sh) r.isShareOn = isShareOnByNoteShare(note, sh, ui);

    console.log("cmToFtxNote=====================================", note);
    console.log("cmToFtxNote=====================================", r)

    return r;
}



/********************** */
export const Def_getDefClassInfo = (rq: RzReq<FtoClassInfo>): RzRes<FtoClassInfo> => {
    let r = new FtoClassInfo();
    r.classId = rq.data?.classId;
    r.title = "MyClass1";

    //let notes= cmToFtxNotes(r.notes);
    r.notes = DefNoteInfos;
    //alert('getClassInfo');


    return NewOk(r);
}


import { FT_LAYER_POP } from "../../consts";
import { FtTopBtn } from "./ftclass.ui.top";
import { rzlog } from "../../inc"
import { RzEventNotier, RzUiGetSrc, RzUiLet, RzUiOpts, RzOnFn } from "../../inc";

 
export class FtPopMenuEnum {
    static VIDEO='vidoe'
    static AUDIO='audio'
    static LOCK='lock'

    static VIDEO2='vidoe2'
    static AUDIO2='audio2'
    static LOCK2='lock2'

    static CHAT='chat'
    static NOTE='note'
    static SETTING='setting'
    static ETC='etc'
    static EXIT='exit'
};

export class FtPopMenuEvent {
    cmd?:string
    selected=false
    constructor(pr:any){
        Object.assign(this,pr)
    }
};

/*********************************
 *  FtClassPopMenu
 ********************************/
 export class FtClassPopMenu extends RzUiLet {
 
    events=[]

    settingBtn?:FtTopBtn
    camBtn?:FtTopBtn
    micBtn?:FtTopBtn
    lockBtn?:FtTopBtn
    chatBtn?:FtTopBtn
    addBtn?:FtTopBtn
    etcBtn?:FtTopBtn

    doPreOpts(opt:RzUiOpts) {
        var topt = super.doPreOpts(opt)
        return { ...opt, className: 'ftclass_pop_menu', cssText:opt?.cssText ||   `
                    position:absolute; left:0px;   
                    width:100%; height: 91px; z-index:${FT_LAYER_POP};
                    display:flex; flex-direction:row;
                    align-items:center;  
                    background: linear-gradient(180deg, rgba(11, 11, 11, 0.4) -54.48%, #0B0B0B 108.58%);` };
    }

    doInit(opt:RzUiOpts) {

        var leftPnl=new RzUiLet();
        leftPnl.init({parent:this,cssText:`width:50%; justify-content:flex-start; align-items:center;  
                                                    display:flex; flex-direction:row; padding-left:38px;`});

        var rightPnl=new RzUiLet();
        rightPnl.init({parent:this,cssText:`width:50%; justify-content:flex-end; align-items:center;
                                         display:flex; flex-direction:row; padding-right:25px; `});

        var camBtn=new FtTopBtn();
        camBtn.setParent(leftPnl);
        camBtn.setTitle('C');
        camBtn.setSelectColor(`#EA4040`);
        camBtn.setIcon('ftnote-cam-icon');
        camBtn.popMenuLet=this;
        camBtn.onClick=(e)=>{ var src=RzUiGetSrc(e); 
                 if(src) src.setSelected(!src.selected)
                src?.popMenuLet.notiPopMenuEvent(FtPopMenuEnum.VIDEO,src.selected)};
        camBtn.init({cssText:' align-items:center; justify-content:center; margin-left:0px; '});
        this.camBtn=camBtn;

        var btn=new FtTopBtn( );
        btn.setParent(leftPnl);
        btn.setTitle('M');
        btn.setIcon('ftnote-mic-icon');
        btn.setSelectColor(`#EA4040`);
        btn.popMenuLet=this;
        btn.onClick=(e)=>{ var src=RzUiGetSrc(e);  
            rzlog.debug('tClassPopMenu.btn.click ; src.parent=',src.parent)
            if(src) src.setSelected(!src.selected)
            src?.popMenuLet.notiPopMenuEvent(FtPopMenuEnum.AUDIO,src.selected)};
        btn.setSelected(false);
        btn.init();
        this.micBtn=btn;

        btn=new FtTopBtn();
        btn.setParent(leftPnl);
        btn.setTitle('Lk');
        btn.setSelectColor(`#EA4040`);
        btn.setIcon('ftnote-lock-icon');
        btn.setSelected(true);
        btn.popMenuLet=this;
        btn.onClick=(e)=>{ var src=RzUiGetSrc(e); 
            if(src) src.setSelected(!src.selected);
            src?.popMenuLet.notiPopMenuEvent(FtPopMenuEnum.LOCK,src.selected)};
        btn.init();
        this.lockBtn=btn;
        /******************** 
         * RightPanel
        */
        btn=new FtTopBtn();
        btn.setParent(rightPnl);
        btn.setTitle('Cht');
        btn.setIcon('ftnote-chat-icon');
        btn.setSelected(true);
        btn.popMenuLet=this;
        btn.onClick=(e)=>{ var src=RzUiGetSrc(e); 
               if(src) src.setSelected(!src.selected);
             src?.popMenuLet.notiPopMenuEvent(FtPopMenuEnum.CHAT,src.selected)};
        btn.init();
        this.chatBtn=btn;

        btn=new FtTopBtn();
        btn.setParent(rightPnl);
        btn.setTitle('Add');
        btn.setIcon('ftnote-add-icon');
        btn.popMenuLet=this;
        btn.onClick=(e)=>{ var src=RzUiGetSrc(e); 
            //if(src) src.setSelected(!src.selected);
            src?.popMenuLet.notiPopMenuEvent(FtPopMenuEnum.NOTE,src.selected)};
        btn.init({   cssText:'margin-left:20px;'});
        this.addBtn=btn;

        btn=new FtTopBtn();
        btn.setParent(rightPnl);
        btn.setTitle('Set');
        btn.setIcon('ftnote-setting-icon');
        btn.popMenuLet=this;
        
        const onfunc1= (e:Event)=>{
            let src=RzUiGetSrc(e); 
            
            //if(clsName==="ftclass_menu_subpopup") return;
            if(src?.nativeDiv?.className){
                const clsName=src.nativeDiv!.className;
                if(clsName==="ftclass_menu_subpopup") return;
            }
            //ftclass_menu_subpopup
            
            if(this.settingBtn! !== src){
                this.settingBtn!.setSelected(false);
                
                
                this.notiPopMenuEvent(FtPopMenuEnum.SETTING,false);
                document.removeEventListener("click",onfunc1);
            }
        };

        btn.onClick=(e)=>{
            let src=RzUiGetSrc(e); 
            if(src) src.setSelected(!src.selected);
            src?.popMenuLet.notiPopMenuEvent(FtPopMenuEnum.SETTING,src.selected);

            document.addEventListener("click", onfunc1);
        };
        btn.init({   cssText:'margin-left:20px;'});
        this.settingBtn=btn;

        btn=new FtTopBtn();
        btn.setParent(rightPnl);
        btn.setTitle('etc');
        btn.setIcon('ftnote-dot3-icon');
        btn.popMenuLet=this;
        const onfunc2= (e:Event)=>{
            let src=RzUiGetSrc(e); 
            if(src?.nativeDiv?.className){
                const clsName=src.nativeDiv!.className;
                if(clsName==="ftclass_menu_subpopup") return;
            }

            if(this.etcBtn! !== src){
                this.etcBtn!.setSelected(false);
                this.notiPopMenuEvent(FtPopMenuEnum.ETC,false);
                document.removeEventListener("click",onfunc2);
            }
        };

        btn.onClick=(e)=>{ var src=RzUiGetSrc(e);  
                if(src) src.setSelected(!src.selected);
                src?.popMenuLet.notiPopMenuEvent(FtPopMenuEnum.ETC,src.selected);
                document.addEventListener("click", onfunc2);
            };

        btn.init( {   cssText:'margin-left:20px;'});
        this.etcBtn=btn;

        btn=new FtTopBtn();
        btn.setParent(rightPnl);
        btn.setTitle('수업나가기');
        btn.setIcon('ftnote-quit-icon');
        btn.setBackColor('#DB0202');
        btn.setBtnSize(55);
        btn.popMenuLet=this;
        btn.onClick=(e)=>{ var src=RzUiGetSrc(e); src?.popMenuLet.notiPopMenuEvent(FtPopMenuEnum.EXIT, src.selected)};
        btn.init(
            {   cssText:'margin-left:40px;',
                innerHTML:`<div style="display:flex; flex-direction:column; aligh-items:center;justify-content:center;text-align:cetner;" >
                    <div><i class="ftnote-quit-icon"></i></div><div> <span style="font-family: 'Spoqa Han Sans Neo';
                                font-style: normal;
                                font-weight: 700;
                                font-size: 10px;
                                line-height: 12px;
                                text-align: center;
                                color: #FFFFFF;">수업나가기</span><div></div>`});

        return true;
    }


    notiPopMenuEvent(cmd:string,status:boolean){
        var e=new FtPopMenuEvent({cmd:cmd, selected:status});
        RzEventNotier.NotiEvent(this.events,e);
    }

    addOnPopMenuEvent(fn:RzOnFn,pr?:any){
        RzEventNotier.AddOnEvent(this.events,fn,pr);
    }

    delOnPopMenuEvent(fn:RzOnFn){
        RzEventNotier.DelOnEvent(this.events,fn);
    }

}//class



// import {t01_repaintEvent} from './t01'
// import {t02_rznote_bgImg} from './t02_rznote_bg'
// import {t03_rznote_mpage} from './t03_rznote_mpage'
//import {t04_repo_mng} from './t04_repo_mng'




export function FtClassTest_IsTest(){return false;}
export function FtClassTest_ProcTest(tgtDiv:HTMLDivElement){
    let cmd='t04'
    const args:any[]=[]

    // if(cmd==='t01') t01_repaintEvent(tgtDiv,args);
    // else if(cmd==='t02') t02_rznote_bgImg(tgtDiv,args);
    // else if(cmd==='t03') t03_rznote_mpage(tgtDiv,args);
  //  else if(cmd==='t04') t04_repo_mng(tgtDiv,args);

    //if(cmd==='t03') t03_rznote_mpage(tgtDiv,args);

}
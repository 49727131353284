import { FTCTX_CLIENT, FtGetEnv } from "../../config/consts"
import { FtClassClient, FtClassClientRef } from "../ftclass.client"
import { FtClassWsClient } from "../ftclass.client.ws"
import { FtoClassInfo, FtoNoteInfo, FtoPage, FtoSession, FtoUser } from "../dto/ftclass.dto"
import { FtClassInfoRepo } from "./ftclass.inf.repo"
import { FtNotesRepo } from "./ftclass.repo.bas"
import { FtClassStoreFac, FtStoreOpt } from "./ftclass.repo.fac"
import { FtClassInfoStore, FtNotesRepoStore } from "./ftclass.repo.store"
import { FtNoteRepo } from "../ui/ftclass/ftnote.ui"
import { NewOk, NewFail, IsFail,rzlog,RzNoteRepo, RzRes, RzCtx, RzRsRes } from "../inc"
import { CONF_STORED_CLASS_ID } from "../../config2"

const rzIs=rzlog.makeDefs()
export const FtClassRepoStoreRmt_setDbg=rzIs.setDbg
 
 
/********************
 * 
 */
export class FtClassInfoRemoteStore implements FtClassInfoStore {

    
    clientRef?:FtClassClientRef;
    ctx?:RzCtx;
    constructor(opt?:FtStoreOpt){
        this.clientRef=opt?.clientRef
    }



    async fetchClassInfo(classId: string, infoRepo: FtClassInfoRepo,pathOn?:boolean): Promise<RzRes<FtoClassInfo>> {
        let client=await this.clientRef!.getClient();
        if(!client) return NewFail('no client');
        
        let wsclient=client as FtClassWsClient

        let r=await wsclient!.fetchGetClassInfo(classId,pathOn)
        if(IsFail(r)) return r
        //alert('fetchClassInfo : clz.id='+classId+', czinf='+JSON.stringify(r.data.attendants));
        infoRepo.setClassInfo(r.data);

  
        return r;
    }
    async putClassInfo(classId: string, clzInf: FtoClassInfo): Promise<RzRes<FtoClassInfo>> {
        let client=await this.clientRef!.getClient();
        if(!client) return NewFail('no client');
        
        let wsclient=client as FtClassWsClient

        let r=await wsclient!.fetchPutClassInfo(clzInf);
        if(IsFail(r)) return r
        //alert('fetchClassInfo : clz.id='+classId+', czinf='+JSON.stringify(r.data.attendants));
  
        return r;
    }
   
    

    async init(ctx:RzCtx) : Promise<RzRes<void>> {
        this.ctx=ctx
        let cli=ctx.getAttr(FTCTX_CLIENT)! as  FtClassClientRef
        this.clientRef=cli
        if(rzIs.i) rzlog.info("FtClassInfoRemoteStore.init : ctx.client=",cli)
        return NewOk<void>()
    }

    async load(infoRepo:FtClassInfoRepo, opt?:any){
        let classId=infoRepo.getClassId()
        if(rzIs.d) rzlog.debug(`FtClassInfoRemoteStore.load : classId=`,classId,',opts=',opt)

        let wsclient=await this.clientRef!.getClient() as FtClassWsClient;
        if(!wsclient) return NewFail('no wsclient');
        
        //let wsclient=r0.data! as FtClassWsClient
        let r1=await wsclient.getHello();
        if(rzIs.d) rzlog.debug('FtClassInfoRemoteStore.load: getHello=',r1)


        let r=await wsclient!.fetchGetClassInfo(classId,opt?.pathOn);
        if(IsFail(r)) return r


        let classInfo=r.data
        infoRepo.setClassInfo(classInfo)
        infoRepo.setInfoStore(this);

        
        return NewOk(classInfo);
    }


    async save(infoRepo:FtClassInfoRepo, opt?:any){
        return NewOk()
    }

    

    async putNoteInfos(noteInfs: FtoNoteInfo[]): Promise<RzRsRes<FtoNoteInfo>> {
        if(rzIs.d) rzlog.debug('FtClassInfoRemoteStore.load');

        let wsclient=await this.clientRef!.getClient() as FtClassWsClient;
        if(!wsclient)return NewFail('putNoteInfos: no wsclient');
        //let r=await wsclient!.fetchGetClassInfo(classId)

        let r= await wsclient.fetchPutNoteInfos(noteInfs);
        if(IsFail(r)) return r

        return r;
    }



    async addNoteInfo(noteInf: FtoNoteInfo): Promise<RzRes<FtoNoteInfo>> {
        return NewFail('')
    }
    async putNoteInfo(noteInf: FtoNoteInfo): Promise<RzRes<FtoNoteInfo>> {
        return NewFail('')
    }
    async delNoteInfo(noteId: string, noteInf?: FtoNoteInfo): Promise<RzRes<FtoNoteInfo>> {
        return NewFail('')
    }


 
}

/********************
 * 
 */
export class FtNotesRepoRemoteStore implements FtNotesRepoStore  {
    isLoading=false
    clientRef?:FtClassClientRef;
    constructor(opt?:FtStoreOpt){
        this.clientRef=opt?.clientRef
    }
    ctx?:RzCtx;
    
    async init(ctx:RzCtx) : Promise<RzRes<void>> {
        let cli:FtClassClientRef=ctx.getAttr(FTCTX_CLIENT)! as FtClassClientRef
        this.clientRef=cli;
        this.ctx=ctx

        return NewOk<void>()
    }

    async load(infoRepo:FtClassInfoRepo, repos:FtNotesRepo, opt:any){
        if(this.isLoading) return NewOk()
        this.isLoading=true

        let r=await infoRepo.getNoteInfos()
        let infos=r.data!

        let client=await this.clientRef!.getClient();
        if(!client) return NewFail<void>();
        
        let wsclient=client as FtClassWsClient
        

        for(let i in infos){
            let inf=infos[i]

            let r0= await repos.addNote(inf.noteId!,inf,{type:'load'})
            if(r0.status>0) continue

            let tr0= await repos.getNoteRepo(inf.noteId!)
            let noteMemRepo=tr0.data!
            await noteMemRepo.load(opt)

            //.fetchGetNote(inf.noteId)
            let r=await wsclient.fetchGetNote(inf.noteId!)
            if(IsFail(r)) {
                rzlog.error("can't load Note; noteId=",inf.noteId)
                continue
            }

            rzlog.debug("FtClassRepoStrore.rmt : fetchGetNote - noteId=",inf.noteId!,',r=',r)
            if(inf.type=== 'book')  this.doAddPages(noteMemRepo,r.data.pages,true)
            else if(inf.type==='note') this.doAddPages(noteMemRepo,r.data.pages,false)

        }//for


        if(rzIs.d) rzlog.debug('FtClassReposRemoteStore.load : classId=',repos.getClassId(),',opts=',opt)
        this.isLoading=false
        return NewOk()
    }

 

    doAddPages(memRepo:RzNoteRepo, pages:FtoPage[], hasImage:boolean=true){
            let ntinf=memRepo.getNoteInfo();
            let connInfo=this.clientRef!.connInfo;
            let rscUrl=connInfo?.rscUrl
            rzlog.debug("storeRmt.page : rscUrl=",rscUrl)

            //ntinf.refType;
            //slet refID=ntinf.refId;
        for(let i in pages){
            let v =pages[i]
            let paths=v.paths!
            let pg= v.pageNo! 
            if(pg===null) {
                rzlog.error("PAGE NO ERROR: page=",v)
                continue
            }
            memRepo.doNewPageAt(pg)


            rzlog.debug("RmtStore.addPages :  pg=",pg,",ntId=",ntinf.noteId,",rscUrl=",rscUrl)

            if(hasImage) {

                if(ntinf?.type=='book' && ntinf.refId&&!v.url){
                    let api=rscUrl+'bookPage/'+ntinf.refId+'/'+v.pageNo;

                    //@RMK 
                    //if(FtGetEnv()=='dev0')  api='https://ftnote.rzdesk.com/api/'+'bookPage/'+ntinf.refId+'/'+v.pageNo;
                    

                    memRepo.setAttr(pg,"bg",{src:api});
                } 
                else if(v.url)  memRepo.setAttr(pg,"bg",{src:v.url})
                
            }
            rzlog.debug("RmtStore.doAddPages page : pg=",pg,",v=",v)


            if(rzIs.d) rzlog.debug('RemoteStore.doAddPages : addPateAt > pg=',pg, ',repo.pages=',memRepo.pages)
            for(let j in paths){
                let p=paths[j]
                let jattrs={}
                let tpath={...p}
                if(tpath.attrs){
                    jattrs=JSON.parse(p.attrs!)
                }
                tpath={type:p.type, ...jattrs}
               
                memRepo.addPagePathAt(pg,tpath)
            }//for:paths
        }//for:pages
    }

    async save(infoRepo:FtClassInfoRepo, repos:FtNotesRepo, opt:any){
        return NewOk()
    }

    async loadNote( noteRepo:FtNoteRepo, opt:any):Promise<RzRes<void>>{
        return NewOk()
    }
    
    async saveNote( noteRepo:FtNoteRepo, opt:any):Promise<RzRes<void>>{
        return NewOk()
    }   
}//class

/********************
 * 
 */
export class FtClassStoreRemoteFac implements FtClassStoreFac {

    newInfoStore(opt?:FtStoreOpt){
        return new FtClassInfoRemoteStore(opt)
    }
     
    newNotesStore(opt?:FtStoreOpt){
        return new FtNotesRepoRemoteStore(opt)
    }
}
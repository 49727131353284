import { rzlog,RzUiLet,scale } from "../../inc";
import { RzSplitLet,RzResizeLet }from '../../inc'
import { FtLayoutCtx, FtLayoutInit, FtLayoutOpts, FtUiLets } from "../ftclass/ftclass.ui.layout";
import { FtFullLayout } from "./ftclass.ui.layout.full";
import { FtClassSide } from "../ftclass/ftclass.ui.side";
import { FtClassChat } from "../ftclass/ftclass.ui.chat";
import { FtDeskLet } from "../ftclass/ftdesk.ui";
import { FtLayoutSubCtx } from "./ftclass.ui.layout.sub.body";
 

/** dbg */
const rzIs=rzlog.makeDefs()
export const FtClassUiLayoutSubTop_setDbg =rzIs.setDbg


 

/**
 * FtSubTopInit
 */
export class FtSub2SideInit implements FtLayoutInit {
    lman:FtFullLayout;


    constructor(lman:FtFullLayout){
        this.lman=lman;
    }
    
    init(lctx:FtLayoutCtx,pr?:any){
        let lman=this.lman;
        let lctx2=pr as FtLayoutSubCtx
        this.doInitSide(lctx2)
    }
    

    doInitSide(lctx2:FtLayoutSubCtx){
        let lctx=lctx2.lctx;
        
        // 4=[15,15,35,35] , 3 = [15,15,70] | [15,45,40] , 
        // 2=[15,85] | [50|50]
        // 1=[100]
        rzlog.debug('FtClassUiLayoutSub2Sid.doInitSide[layout]: ',lctx2)
        if(!lctx.opt.isSideOn) return

        let w=lctx2.bodyCnt===4? 15: lctx2.bodyCnt===3 ? 15 : 
                (lctx2.bodyCnt===2? 15 : 100 ) 
        let wp= lctx2.clientW*(w/100 )
        
        let cssText=`display:flex; flex-direction:${lctx2.bodyCnt===1?"row":"column"};  background-color:#333333; top:0px; left:0px;width:${wp}px; height:100%;`
        
        if(lctx.lets!.side?.nativeDiv) { 

            lctx.lets!.side!.nativeDiv!.style.cssText=cssText
            lctx2.sbody.addChild(lctx.lets!.side)
            lctx.lets!.side.setVisible(true)
        }  else {
            this.doInitSub2Side(lctx2.sbody,lctx.lets!.side!,0,cssText,lctx.opt)
        } 
        if(lctx.opt?.videoCount===1){
            (lctx.lets!.side! as FtClassSide).setVideoCount(1)
        } else (lctx.lets!.side! as FtClassSide).setVideoCount(2)
        lctx2.prevWidth+=wp
    }


    doInitSub2Side(p:RzUiLet,sideLet:RzUiLet,ix:number,cssText:string,opt:any){
        let lman=this.lman;
        if(lman.doUdateVisble(p,sideLet,ix)) return
        
        let side=lman.subLetFac.createSide(p,cssText,opt,lman.repoMng)

        lman.lets!.side=side
    }




}
import { rzlog, IsFail } from "./inc"
import './rznote.ui.css'
import { RzDlg, RzPos, RzRect, RzSizeDlg, RzUiLet, RzUiOpts } from './rzcmn.ui'
import { RzNote, RzPenAttr } from "./rznote.ui"
import { RzoNotePath } from "./rznote.ui.dto"
import { RzDrawPen } from "./rznote.ui.edit"
import { RzEditSizeDlg } from "./rznote.ui.edit.text"
import { FT_LAYER_EDIT } from "../consts"
import { FtoPath } from "../dto/ftclass.dto"


/****************/
const rzIs = rzlog.makeDefs(true)
export const RzNoteUiEditMemo_setDbg = rzIs.setDbg

let isDbgUi = false;
export function RzNoteUiEditMemo_setDbgUi(b: boolean) {
    isDbgUi = b
}
const isDbgBorder = false;
/******************
 * 
 */

export const RZNOTE_PEN_MEMO_COLOR_DOT = '#037DED'
export const RZNOTE_PEN_MEMO_COLOR_DOT_OPEN = '#037DED'
export const RZNOTE_PEN_MEMO_COLOR_DOT_CLOSE = '#FDAF3B'
//export const RZNOTE_PEN_MEMO_COLOR_BOX = '#5664E8'
export const RZNOTE_PEN_MEMO_COLOR_BOX = '#2869A5'

// export const RZNOTE_PEN_MEMO_COLOR_BG = '#FFE5B1'
export const RZNOTE_PEN_MEMO_COLOR_BG = '#FAFAFA'
export const RZNOTE_PEN_MEMO_DOT_OFF = 12
// export const RZNOTE_PEN_MEMO_DOT_SIZE = 8
export const RZNOTE_PEN_MEMO_DOT_SIZE = 10


const LAYER_EDIT = FT_LAYER_EDIT;

/****************
* 
*/



/***************** 
 * 
*/
export class RzMemoPen extends RzDrawPen {
    curSizeDlg?: RzEditSizeDlg
    curPath?: RzoNotePath
    isEdting: boolean = false
    isButtonPressed: boolean = false;
    isButtonMoved: boolean = false;
    curPos: RzPos | null = null;


    isButton(path: RzoNotePath, np: RzPos, size: number) {
        let r = true
        let vp = this.note.toViewPos(np.x, np.y)
        let tgt_vp = this.note.toViewPos(path.x, path.y)
        let varea_sz = this.note.toViewSize(size)

        if (path.type !== 'memo') r = false;

        if (vp.x < tgt_vp.x! - 4 || vp.x > tgt_vp.x! + varea_sz + 16) r = false;
        if (vp.y < tgt_vp.y! - 4 || vp.y > tgt_vp.y! + varea_sz + 16) r = false;

        if (rzIs.t) rzlog.trace('MemoEdit.isButton :r=', r, ', np=', np, ',path=', path)
        return r
    }

    isOutArea(path: RzoNotePath, np: RzPos, size: number) {
        let r = false
        if (rzIs.t) rzlog.trace('MemoEdit.isOutArea : np=', np, ',path=', path)
        if (path.type !== 'memo') r = true;
        // icon =18x18
        if (np.x > path.x! - 4 && np.x < path.x! + size * 2) {
            if (np.y > path.y! + size * 2 && np.y < path.y! + path.h!) r = true
        }

        return r
    }

    doCloseDlg(note: RzNote, path?: RzoNotePath) {
        if (this.isEdting && this.curSizeDlg) {
            this.isEdting = false;
            let dlg = this.curSizeDlg
            dlg.setVisible(false)
            note.removeChild(dlg as RzUiLet)


            if (path) note.drawPen(note.ctx, path)
            note.repaint()

        }

    }

    async penDown(note: RzNote, e: MouseEvent) {
        /* FIXME: 메모 입력 후 */
        let vp = note.getPos(e)
        let np = note.toNotePos(vp.x, vp.y)

        const r = await note.getPathAt(np.x, np.y, 2, 2)
        let path: RzoNotePath | null = null
        if (rzIs.d) rzlog.debug("RzMemoPen.note.getPathAt :r= ", r, ',vp=', vp, ",e=", e)
        if (r && r.data) path = r.data

        if (!this.isEdting && path && this.isButton(path, np, RZNOTE_PEN_MEMO_DOT_SIZE)) {
            if (rzIs.d) rzlog.debug("RzMemoPen.note.dot.pressed :r= ", r)
            this.pressed = true;

            note.pos.drawable = true;
            this.isButtonPressed = true;
            this.curPath = path;

            (note as any).curPos = {
                x0: this.curPath!.x,
                y0: this.curPath!.y,
                mx: vp.x, my: vp.y,
            };

            return
        }

        if (path && this.isOutArea(path, np, 1)) {
            let tpath = path
            if (path.type !== 'memo' && this.curPath) {
                this.curPath.isSkip = false;
                tpath = this.curPath
                this.curPath = undefined;
            }

            if (this.curSizeDlg) this.doCloseDlg(note, tpath)
            return;
        }

        if (this.curSizeDlg) {

            let dlg = this.curSizeDlg
            if (rzIs.d) rzlog.debug('drawing down :!!! dlg=', dlg)

            let editText = (this.curSizeDlg?.edit?.nativeDiv as HTMLInputElement)?.value
            this.curSizeDlg = undefined

            if (rzIs.d) rzlog.debug(`drawing text :text='${editText}'`)

            if (editText && editText !== '') {
                let cli_pos2 = dlg.getBounds()

                let vp3 = note.transCanvasPos(cli_pos2)



                let np4 = note.toNotePos(vp3.x, vp3.y, vp3.width, vp3.height)

                let nfsize = note.toNoteSize(this.fontSize)
                const drawerId = note.getDrawerId();
                const ownerId = note.getOwnerId();

                // 메모를 작성한 후에 에디트가 끝나는 순간의 박스 style
                let tp: RzoNotePath = {
                    drawable: true,
                    type: 'memo',
                    x: np4.x, 
                    y: np4.y,
                    w: 200, 
                    h: 150,
                    isOn: true,
                    text: editText,
                    attr: {
                        width: 1,
                        color: this.color,
                        ownerId: ownerId,
                        drawerId: drawerId,
                        boxColor: RZNOTE_PEN_MEMO_COLOR_BOX, //'#5664E8'
                        dotColor: RZNOTE_PEN_MEMO_COLOR_DOT,
                        //dotXOffset: RZNOTE_PEN_MEMO_DOT_OFF,
                        boxBgColor: RZNOTE_PEN_MEMO_COLOR_BG,//'#FFE5B1',
                        font: this.font,
                        fontSize: nfsize,
                    },
                    pageNo:note.noteRepo?.curPage // 24.1.24 add memoList go to page
                }
                if (rzIs.d) rzlog.debug('RzNote.MemoEdit: drawing Memo > tp=', tp, editText)
                note.drawPen(note.ctx, tp)

                if (!this.curPath) {
                    tp.isSkip = false;
                    note.addDrawPath(tp)
                } else {
                    let ntp: RzoNotePath = { ...this.curPath }

                    Object.assign(ntp, tp)
                    ntp.text = editText

                    ntp.isSkip = false;
                    note.putDrawPath(ntp)
                    this.curPath = undefined
                }
            }//if


            if(this.curPath){
                this.curPath.isSkip=false;
                this.curPath.drawable=true;
                note.putDrawPath(this.curPath)
            }

            dlg.setVisible(false)
            note.removeChild(dlg as RzUiLet)
            note.repaint()
            this.isEdting = false;
        } else {
            this.isEdting = true
            if (path && path.type === 'memo') {
                this.curPath = path
            }

            let tdlg = this.doShowEditDlg(note, vp, this.curPath)
            if (this.curPath) {
                this.curPath.isSkip = true;
                tdlg.setText(this.curPath.text!);
                note.repaint();
            }

            this.curSizeDlg = tdlg
        }

        if (rzIs.d) rzlog.debug('shape.down:vp=', vp)
    }

    penMove(note: RzNote, e: MouseEvent): void {
        if (rzIs.d) rzlog.debug("RzMemoPen.penMove : e=", e)
        if (this.isButtonPressed) {
            let vp = note.getPos(e);

            this.isButtonMoved = true
            let tpos = (note as any).curPos;

            let dx = vp.x - tpos.mx;
            let dy = vp.y - tpos.my;
            // this.curPath!.x=tpos.x0+dx;
            // this.curPath!.y=tpos.y0+dy;

            let dp = note.toNotePos(0, 0, dx, dy)

            rzlog.debug("RzMemoPen.penMove : note.atPos.=", dp);

            this.curPath!.x = tpos.x0 + dp.width;
            this.curPath!.y = tpos.y0 + dp.height;

            note.repaint()

        }
    }

    penUp(note: RzNote) {
        if (this.isButtonPressed) {

            let path = this.curPath!


            if (!this.isButtonMoved) path.isOn = !path.isOn;

            this.clearDotButton(note)

            note.putDrawPath(path)
            note.repaint()
        }
    }

    clearDotButton(note: RzNote) {

        this.isButtonMoved = false;
        this.pressed = false;
        this.isButtonPressed = false;
        this.curPath = undefined;
        note.pos.drawable = false;
    }

    penEnd(note: RzNote) {

        if(this.curPath){
            this.curPath.isSkip=false;
            this.curPath.drawable=true;
            note.putDrawPath(this.curPath)
        }

        if (!this.curSizeDlg) return
        this.curSizeDlg.setVisible(false)
        note.removeChild(this.curSizeDlg as RzUiLet)
        this.curSizeDlg = undefined
    }

    doShowEditDlg(note: RzNote, vp0: RzPos, path?: RzoNotePath) {
        /* FIXME: 메모 입력 관련 영역 */
        let tdlg = new RzEditSizeDlg()
        tdlg.setParent(note as RzUiLet)
        tdlg.setResizeBtnOn(true)
        tdlg.setMemoStyle(true);

        tdlg.onMouseDown = (e) => { }
        tdlg.onMouseMove = (e) => {
            (tdlg as any).isMove = true
            e.stopPropagation();
        }

        tdlg.onMouseUp = (e) => {
            if ((tdlg as any).isMove) {
                e.stopPropagation()
            }
        }

        tdlg.onFocus = (isFocus) => {
            if (rzIs.d) rzlog.debug('pen.isFocus=', isFocus)
        }

        /* 23.11.15 dotColor 추가(전웅구) */
        let dotClr = '#FF473C';
        // let dotSz = note.toViewSize(RZNOTE_PEN_MEMO_DOT_SIZE);
        let dotSz = RZNOTE_PEN_MEMO_DOT_SIZE;
        let dotOff = 8//note.toViewSize(12)
        let dotCss = `cursor:grab;
                width:${dotSz}px;
                height:${dotSz}px;
                border: 1px solid ${dotClr};
                // background:#5664E9; 
                background:${dotClr}; 
                margin-right:${dotOff}px; 
                border-radius:50%;
                //box-shadow: 2px 2px 0px rgba(129, 117, 117, 0.5);
                `
        let dotBtn = new RzUiLet({
            isDraggable: true,
            className: "rzmemo_btn", cssText: dotCss
        })

        dotBtn.onMouseDown = (e: MouseEvent) => {
            let tp = tdlg.getBounds()
            if (rzIs.t) rzlog.debug("dotBtn.mouse:", { x0: tp.x, y0: tp.y }, ',e(cx,cy)=', e.clientX, ',', e.clientY);
            (dotBtn as any).isPressed = true;
            (dotBtn as any).isMove = false;



            let clipos = { x: e.pageX, y: e.pageY };
            //@ let clipos={x:e.clientX,y:e.clientY}
            if (dotBtn.nativeDiv) dotBtn.nativeDiv.style.border = "1px solid red";

            tdlg.isSkipMouseMove = true;
            (dotBtn as any).pos = {
                x0: tp.x, y0: tp.y, x: clipos.x, y: clipos.y
            };
        }

        dotBtn.onMouseMove = (e: MouseEvent) => {
            let tp = tdlg.getBounds()
            if (rzIs.t) rzlog.debug("dotBtn.mouse: move ", { x0: tp.x, y0: tp.y }, 'e(cx,cy)=', e.clientX, ',', e.clientY, ',e.tgt=', e.target);
            if (!(dotBtn as any).isPressed) return

            (dotBtn as any).isMove = true

            let pos = (dotBtn as any).pos

            //@deprecated
            // let dx= e.clientX-pos.x
            // let dy= e.clientY-pos.y

            let dx = e.pageX - pos.x
            let dy = e.pageY - pos.y
            let x0 = (pos.x0 + dx);
            let y0 = (pos.y0 + dy);


            //@deprecated
            // let tx=x0 - tdlg.parent!.nativeDiv!.offsetLeft;
            // let ty=y0 - tdlg.parent!.nativeDiv!.offsetTop;

            //@ADD
            let p = tdlg.parent?.nativeDiv?.getBoundingClientRect();

            let tx = x0 - (p?.left || 0);
            let ty = y0 - (p?.top || 0);

            tdlg.nativeDiv!.style.left = (tx) + "px";
            tdlg.nativeDiv!.style.top = (ty) + "px";

            (dotBtn as any).pos = {
                x0: x0, y0: y0,
                x: e.clientX, y: e.clientY
            };
        }

        dotBtn.onMouseUp = (e: any) => {
            tdlg.isSkipMouseMove = false;
            (dotBtn as any).isPressed = false
            if (dotBtn.nativeDiv) dotBtn.nativeDiv.style.border = "0px";
        }

        tdlg.dotBtn = dotBtn


        let vp = null;
        if (path) vp = note.toViewPos(path.x!, path.y!,
            path.w!, path.h!)
        const edit_dx = 9, edit_dy = 10
        const new_dx = 0, new_dy = 0;
        const tx = (vp) ? vp!.x! + edit_dx : vp0.x! + new_dx;
        const ty = (vp) ? vp!.y! + edit_dy : vp0.y! + new_dy;
        const tw = (vp) ? vp.width : note.toViewSize(100);
        const th = (vp) ? vp.height : note.toViewSize(100);

        const wsize = `width:${tw}px;height:${th}px;`

        let dlgCss = `cursor:grab; position:absolute;
                z-index:${LAYER_EDIT};
                left:${tx}px;top:${ty}px;
                ${wsize}
                // display:flex; flex-direction:row;
                background-color:rgba(0,0,0,0.0);
                border:0px solid black;`

        tdlg.init({
            isClosable: false, isDraggable: true,
            cssText: dlgCss,
        })

        return tdlg;
    }
}//class


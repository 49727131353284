import { RzSrcOpt, volatile } from "../inc";
import { RzoNoteShare } from "../note.ui/rznote.ui.dto";
import { FtxNote } from "./ftclass2.dto";

 
export interface  FtSrcOpt extends RzSrcOpt {
    // type?:string;
    // isSkip?:boolean;
    // isNoRepaint?:boolean;
    isCastOn?:boolean;
    
}

/*******************************
 * FtoClassInfo
 */
export class FtoClassInfo {
    classId?:string;
    title?:string;
    type?:string;
    host?:FtoUser;
    
    isObserverChatOn?:boolean;

    detail?:FtoClassDetail;
    attendants:FtoUser[]=[];       // FtoUser

    pages:FtoNoteInfo[]=[];
    notes:FtoNoteInfo[]=[];       // FtoNoteInfo

    mySessions:FtoSession[]=[];    // FtoSession
    mySession?:FtoSession;
    myInfo?:FtoUser;

    setting?:FtoSetting;
    etcSetting?:FtoEtcSetting;
    classSharing?:FtoClassSharing

    ftxNotes?:FtxNote[];
    stat?:string;

    beginAt?:Date //시작시간
    endAt?:Date //종료시간 
    
}//FtoClassInfo

/**
 * FtoConnStat
 */
export class FtoConnStat {
    isConnected?:boolean
}


export class FtoUserTypeEnum{
    static TEACHER:string='teacher';
    static STUDENT:string='student';
    static OBSERVER:string='observer';
};

/*******************************
 * FtoUser
 */
 export class FtoUser {
    uid?:string;
    sesId?:string;
    userType?:string;
    username?:string;
    firstName?:string;
    lastName?:string;
    accToken?:string;
    devType?:string;
    isOnline?:boolean;
    //@ADD
    mode?:string;

    constructor(pr?:any){
        Object.assign(this,pr)
    }
}


/*******************************
 * FtoSession
 */
export class FtoSession  {
    id?:string;
    //@sesId?:string;
    userType?:string;
    subType?:string;
    devType?:string;
    sesType?:string;
    uid?:string;
    username?:string;
    targetSesId?:string;
    constructor(pr?:any){
        Object.assign(this,pr)
    }
}


export class FtoDevTypeEnum{
    static MOBILE='mobile';
    static PAD='pad';
    static PC='pc';
}


/*******************************
 * FtoRsc
 */
 export class FtoNoteInfo  {
    noteId?:string
    pageNo?:number
    type?:string
    total?:number 
    group?:string
    
    //RzoNoteInfo ======
    ownerId?:string;    // 
    ownerName?:string 
    bookId?:string;    // regId  
    subType?:string;     // null, answer
    title?:string;

    uid?:string;
    username?:string;

    visible?:boolean;

    //@ADD2
    refId?:string;
    refType?:string;

    //@ADD
    groupVisible?:boolean;
    otherVisible?:boolean;

    writable?:boolean;
    groupWritable?:boolean;
    //@ADDEND

    selected?:boolean;

    allocated?:boolean;

    pageScaleW?:number;
    pageScaleH?:number;
    pagePosY?:number;
    pagePosX?:number;

    //@ADD
    @volatile
    isFloatingOn?:boolean;
    @volatile
    isShowOn?:boolean;

    constructor(opt:any){
        Object.assign(this,opt)
    }
}//FtoNoteInfo


export class FtoPageInfo {
    noteId?:string; 
    type?:string; 
    subType?:string; 
    group?:string; 
    pageNo?:number;
    visible?:boolean;
    selected?:boolean
    //@ADD
    isFloatingOn?:boolean;
    isShowOn?:boolean;
}


/**
 * FtoPage
 */
export class FtoPage {
    noteId?:string;
    pageId?:string;
    pageNo?:number;
    type?:string;
    title?:string;
    pathTotal?:number;
    url?:string;
    
    paths?:FtoPath[];
}

/**
 * FtoPath
 */
 export class FtoPath {
    id?:string;
    noteId?:string;
    pageId?:string;
    targetId?:string;
    pageNo?:number;
    type?:string;
    attrs?:string;
    writer?:string;    
 }

 
 export class FtoClassDetail {
    noteId?:string;
    constructor(pr?:any){
        Object.assign(this,pr)
    }
 }
 

 export class FtoConnInfo {
    linkType?:string;
    token?:string;
    wsUrl?:string;
    url?:string;
    apiUrl?:string;
    rscUrl?:string;
}

export enum FtoSharingEnum {
    REQ_NOTE_SHARE='req.note.share',
    RES_NOTE_SHARE='res.note.share',

    REQ_ANSWER_SHARE='req.answer.share',
    RES_ANSWER_SHARE='res.answer.share',

    REQ_WRITING_SHARE='req.writing.share',
    RES_WRITING_SHARE='res.writing.share',

    REQ_PARENT_JOIN='req.parent.join',
    RES_PARENT_JOIN='res.parent.join',
}//



export class FtoSessionShare{
    sesId?:string;
    userId?:string;
    username?:string;

    isCamOn?:boolean;
    isAudioOn?:boolean;
    isChatOn?:boolean;
}


export class FtoClassSharing {
    constructor(pr?:Partial<FtoClassSharing>){
        let t:any={}
        Object.assign(t,pr)
        if(t.__typename) delete t.__typename;
        Object.assign(this,t)
    }

    type?  :string;      // FtoSharingEnum
    classId?:string;

    isAnswerOn?:boolean;
    isTeacherNoteOn?:boolean;
    isStudentNoteOn?:boolean;
    isParentOn?:boolean;
    isParentChatOn?:boolean;

    isCamOn?:boolean;
    isAudioOn?:boolean;

    //for session
    sessionSharings?:FtoSessionShare[];   
    noteSharings?:RzoNoteShare[];
}//class

 // repoManage. setClassInfo(class)
 // repomng.setSharing(sharig)
 // repoMng.setNoteShaing(noteID, noteShare);
/*******************************
 * FtoSetting
 */
export class FtoSetting {
    constructor(pr?:Partial<FtoSetting>){
        let t:any={}
        Object.assign(t,pr)
        if(t.__typename) delete t.__typename;
        Object.assign(this,t)
    }

    //isTeacherBookAllowed:boolean=false;
    //@will be deprecated
    isTeacherNoteAllowed:boolean=false;
    isAnswerNoteAllowed:boolean=false;


    isParentsAllowed:boolean=false;
    isParentsChatAllowed:boolean=true;

 }//class

 export class FtoNoteSetting {
    constructor(pr?:Partial<FtoEtcSetting>){
        let t:any={}
        Object.assign(t,pr)
        if(t.__typename) delete t.__typename;
        Object.assign(this,t)
    }

    noteId?:string;
    onwerId?:string;

    isVisible?:boolean;
    isWritingAllowed?:boolean;
    isWritingShown?:boolean;

    isMyWritingAllowed?:boolean;
    isMyWritingShown?:boolean;

    isOtherWritingAllowed?:boolean;
    isOtherWritingShown?:boolean;
}

export class FtoEtcSetting {
    constructor(pr?:Partial<FtoEtcSetting>){
        let t:any={}
        Object.assign(t,pr)
        if(t.__typename) delete t.__typename;
        Object.assign(this,t)
    }

    
    isTeachingBookShown:boolean=true;
    isAnswerBookShown:boolean=false;
    isTeacherNoteShown:boolean=true;
    isPenToolShown:boolean=true;
    
    isCameraAllShown:boolean=true;
    isCameraShown:boolean=true;
    isCameraTeacherShown:boolean=true;


 }
 

export interface FtEditLockHdr {
    setEventPaused(b:boolean):void;
}


 export class FtoEditLock{
    constructor(pr : Partial<FtoEditLock>){
        Object.assign(this,pr)
    }

    userType?:string;       // student, teacher
    isOwnerLock?:boolean;   // admin Lock     
    isOtherLock?:boolean;   // member lock
    
 }//class

 /********************
  * FtClass2 FtoMemo
  */
 
 export class FtoMemo {
    id?:string;

    body?:string;


    pageId?:string;
    noteId?:string;
    ownerId?:string;

    @volatile
    pageNo?:string;

    createdAt?:Date;
    updatedAt?:Date;
};

 
export class FtoClassNotiEnum {
    static NOTE_SHARE_REQ='note.share.req';
    static NOTE_SHARE_RES='note.share.res';

    static NOTE_WRITE_STOP_REQ='note.write.stop.req';
    static NOTE_WRITE_STOP_RES='note.write.stop.res';
    static NOTE_WRITE_ALLOW_REQ='note.write.allow.req';
    static NOTE_WRITE_ALLOW_RES='note.write.allow.res';

    static NOTE_SHARE_CANCEL_REQ='note.share.cancel.req';
    static NOTE_SHARE_CANCEL_RES='note.share.cancel.res';

    static ANSWER_ALLOWED='answer.allowed';
    static ANSWER_SHARE_REQ='answer.share.req';
    static ANSWER_SHARE_RES='answer.share.res';

    static WRITING_SHARE_REQ='writing.share.req';
    static WRITING_SHARE_RES='writing.share.res';

    static WRITING_TEACHER_SHOW_REQ='writing.teacher.show.req';
    static WRITING_TEACHER_HIDE_REQ='writing.teacher.hide.req';

    static PARENT_JOIN_REQ='parent.join.req';
    static PARENT_JOIN_RES='parent.join.res';
    static PARENT_JOIN_CANCEL='parent.join.cancel';
    
    static MIRROR_MINE_REQ='mirror.mine.req';
    static MIRROR_MINE_RES='mirror.mine.res';

    static MIRROR_OTHER_REQ='mirror.other.req';
    static MIRROR_OTHER_RES='mirror.other.res';

    static OVBSERVER_JOIN_REQ='ovbserver.join.req';
    static OVBSERVER_JOIN_RES='ovbserver.join.res';
    static OVBSERVER_JOIN_CANCEL_REQ='ovbserver.join.cancel.req';
    static OVBSERVER_JOIN_CANCEL_RES='ovbserver.join.cancel.res';
    static PARENT_JOIN_OUT_REQ='parent.join.out.req';
    static PARENT_JOIN_OUT_RES='parent.join.out.res';
}

export class FtoClassNoti {
    seq?:string;
    type?:string;
    title?:string;
    body?:string;
    data?:string;

    fromType?:string;
    fromId?:string;

    toType?:string;
    toId?:string;
    
    targetType?:string;
    targetId?:string;

}

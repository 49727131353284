import { FtConfig, REPO_TYPE_REPO_MNG } from "../../config2";
import { NewFail, NewOk, rzlog } from "../inc";
import { FtClassRepoMng, FtClassRepoMngFac } from "./ftclass.repo-mng";
import { FtClass2Repo } from "./ftclass2.repo";
import { FtClass2MemRepo } from "./ftclass2.repo.mem"
import { FtClass2Repo4RepoMng } from "./ftclass2.repo.mng";




export class FtClass2RepoFac {


    static defRepo:FtClass2Repo |null=null;
    static getDefaultRepo(conf?:FtConfig){
       
        return FtClass2RepoFac.defRepo;
    }


    static defRepoMng:FtClassRepoMng |null=null;
    static getDefaultRepoMng(){
        return  FtClass2RepoFac.defRepoMng;
    }

    static cnt=0;
    static  async initRepoMng(conf?:FtConfig){
        
        let doc = document as any;

        let tc =FtClass2RepoFac.cnt++;
        if(Boolean(doc._isRepoMngInit)){
            if(doc._isRepoMngInitDone)            return NewOk( FtClass2RepoFac.defRepo);
            else return NewFail('loading...:cnt='+tc);
        } 

        doc._isRepoMngInit=true;
       //@ alert('new init : repoType='+conf?.repoType);
        doc._isRepoMngInitDone=false;
        //


   
        let repo:FtClass2Repo|null =FtClass2RepoFac.defRepo;
        if(conf?.repoType== REPO_TYPE_REPO_MNG){
                    let repoMng:any=null
                    // if(rzIs.i) rzlog.info('FtClassFac. has RepoType(opts.repo) = ',opts)
                    let r0=await FtClassRepoMngFac.Init(conf.repoType, conf?.repoMngOpts!);
                    if(r0.data) repoMng=  r0.data;
                    
                    repo=new FtClass2Repo4RepoMng();
                    let trepo= repo as FtClass2Repo4RepoMng;
                    trepo.setRepoMng(repoMng);
                    
                    FtClass2RepoFac.defRepoMng=repoMng ;

                    rzlog.info("RpoMng.init ....................: repoMng=",repoMng);
                    
                   //  alert('repoMng Repo : defRepo='+Boolean(FtClass2RepoFac.defRepo)+',repoMng='+Boolean(repoMng));
        }  else {
 
                    repo=new FtClass2MemRepo(conf?.repoMemDefOn);
                   
        }


        //alert('FtClass2RepoFac.initRepoMng : loaded...:cnt='+tc);
        FtClass2RepoFac.defRepo=repo;
        doc._isRepoMngInitDone=true;
        
        return NewOk(repo);
    }
}//class

import gql from 'graphql-tag' 

export const SUBSCRIB_CMDS:{[key:string]:any}=    {
    'CLASS_PUT':'class.put', 
    'CLASS_BEG':'class.beg', 
    'CLASS_END':'class.end', 
    
    'SESSION_PUT':'session.put', 'SESSION_ADD':'session.add', 'SESSION_DEL':'session.del',
    'PAGES_PUT':'pages.put', 'PAGES_ON':'pages.on',
    'BOOK_ADD':'book.add','BOOK_PUT':'book.put','BOOK_DEL':'book.del',
    'NOTE_ADD':'note.add','NOTE_PUT':'note.put','NOTE_DEL':'note.del',
    'PAGE_ADD':'page.add','PAGE_PUT':'page.put','PAGE_DEL':'page.del',
    'PATH_ADD':'path.add','PATH_PUT':'path.put','PATH_DEL':'path.del',
    'SHARING_PUT':'sharing.put',
    'NOTI_SEND':'noti.send',
}

const classCols='classId,type,title, stat, isObserverChatOn,beginAt,endAt';
const userCols='username,userType,uid,name,nickname,stat,isOnline';
const sesCols='id,username,userType,devType,uid,name,nickname,stat,isOnline'

const sessSharingCols='sesId,userId,username,isCamOn,isAudioOn,isChatOn';
const noteSharingsCols=`id,classId,noteId,seq,ownerId,gid, 
isReadOn,isGroupReadOn,isOtherReadOn,
isWritingReadOn,isGroupWritingReadOn,isOtherWritingReadOn,
isWriteOn,isGroupWriteOn,isOtherWriteOn `;

const classSharingCols=`type, classId,
      isAnswerOn,isTeacherNoteOn,isStudentNoteOn,isParentOn,isParentChatOn,isCamOn,isAudioOn
      sessionSharings { ${sessSharingCols} },
      noteSharings { ${noteSharingsCols}} 
`

 

const notiCols=` seq,fromType,fromId,toType,toId,targetType,targetId,type,title,body,data`;

const pageCols=`noteId, group, type, subType, selected, pageNo, visible, pagePosX, pagePosY, pageScaleW, pageScaleH`;
const pageCols4Get=`noteId, pageNo,ownerId,ownerName,total,visible,selected,allocated , pagePosX,pagePosY, pageScaleW,pageScaleH`;
const pathCols=`id, drawable, type , x0, y0, x, y, w, h, ownerId,drawerId, color, width, text `;
const noteInfoCols=`noteId, title,group,type,subType,title,  
  ownerName,ownerId,pageNo,total,allocated,selected,visible,
  refType,refId `;
const etcSettingCols=`isAnswerBookShown,isCameraTeacherShown,
isCameraAllShown,isCameraShown,isPenToolShown,
isTeacherNoteShown,       isTeachingBookShown`;

const settingCols=`isAnswerNoteAllowed,isParentsAllowed,isParentsChatAllowed, isTeacherNoteAllowed`;

//`noteId, group, type, subType, title, total, pageNo ,username,ownerId`;
// noteId, title,group,type,subType,title,    ownerName,ownerId,pageNo,total,allocated,selected,visible

// class'info,books,notes', session'add,del', pages'put(active),sync', book'add,del,put', 
// note'add,del,put(mov)', page'add,del', path'add,del,put'
//userType 
/**
 * FtClassConnEvent
 */
 export const GQL_QUERIES:{[key:string]:any}= {
    'ftclass_joinClass':gql(`
    mutation ftclass_joinClass($req:FtClassJoinReq ) {
      ftclass_joinClass(req:$req){
        status,message , data{
          token,classId, sesId, username,uid,name,userType ,devType,stat,isOnline
        }
      }
    }`),

 
    'ftclass_leaveClass':gql(`
    mutation ftclass_leaveClass($req:FtClassJoinReq ) {
      ftclass_leaveClass(req:$req){
        status,message , data{
          classId,userType,devType,sesId,token,stat,isOnline
        }
      }
    }`),    

    
   'ftclass_getClassPages':gql(`
 query ftclass_getClassPages($req:FtClassPageReq){
    ftclass_getClassPages(req:$req){
        status, msessage
    }
 }`),

 'ftclass_getClass':gql(`query ftclass_getClass($req:FtClassReq) {
    ftclass_getClass(req:$req){
          status,message
          data {
              ${classCols}
              host{${userCols}},
              attendants{ ${userCols}},
              books { id, title,type},
              notes { ${noteInfoCols} },
              pages { ${pageCols4Get}},
              myInfo{${userCols}}
              mySession{${sesCols}}
              mySessions{${sesCols}}
              setting { ${settingCols}}
              etcSetting { ${etcSettingCols} },
              classSharing { ${classSharingCols} }
        }
    }
  }
 `),

 'ftclass_putClass':gql(`mutation ftclass_putClass($req:FtClassMutReq) {
    ftclass_putClass(req:$req){
            status,message
            data { ${classCols} }
      }
  }`
),

 'ftclass_getMySessions' :gql(`
    query ftclass_getMySessions($req:FtClassSessionReq){
        ftclass_getMySessions(req:$req){
        status
        message 
        data {
            sessions {username, uid, id, sesType, devType }
        }
    }}`),
    //"classId":"1","sesId":"900010"
    //"session":{"uid":5,"username":"test01","userType":"student","name":"test01","nickname":"nick_test01","stat":"active","isOnline":true,"sesId":"900008","classId":"1","devType":"pc","accToken":"1111"

'ftclass_subscrib' :gql(`
subscription ftclass_subscrib($req:FtClassEventReq){ 
    ftclass_subscrib(req:$req){
    status
    message 
    data {
        cmd 
        scmd
        classId 
        sesId 
        begSeq
        isRepaint
        
        classInfo   { ${classCols} }
        session     { ${sesCols} }
        pages       { ${pageCols}}
        note        { ${noteInfoCols}}
        page        { noteId, pageNo, pageId ,username,ownerId}
        paths       { noteId, pageNo, pageId, paths { id, drawable, type , x0, y0, x, y, ownerId,drawerId, color, width, text } , username, ownerId}
        classSharing { ${classSharingCols} }
    }
}}`),

'ftclass_subscribx' :gql(`
subscription ftclass_subscrib($req:FtClassEventReq){ 
    ftclass_subscrib(req:$req){
    status
    message 
    data {
        cmd 
        scmd
        classId 
        sesId 
        isRepaint
        
        classInfo   { ${classCols} }
        session     { id, sesType, devType,username, uid, authority }
        pages       { noteId, group, type, subType, selected, pageNo, visible,
                      pagePosX, pagePosY, pageScaleW, pageScaleH }
        note        { noteId, group, type, subType, title, total, pageNo ,username,ownerId}
        page        { noteId, pageNo, pageId ,username,ownerId}
        paths       { noteId, pageNo, pageId, paths { ${pathCols}} , username, ownerId}
    }
}}`),//note.uid, page.uid

'ftnote_getNote':gql(`
query  ftnote_getNote($req:FtNoteReq){
    ftnote_getNote(req:$req){
      status,message, data {
        noteId,type,subType,title,pages {
          noteId,pageNo,pageId , url, paths { type,attrs}
        }
      }
    }
  }
`),


'ftclass_addNoteInfo':gql(`
  mutation  ftclass_addNoteInfo($req:FtNoteMutReq){
    ftclass_addNoteInfo(req:$req){
      status,message, data {
        noteId, title,group,type,subType,title, 
        ownerName,ownerId,pageNo,total,allocated,selected,visible
      }
    }
  }
`),

'ftclass_putNoteInfos':gql(`
  mutation  ftclass_putNoteInfos($req:FtNotesMutReq){
    ftclass_putNoteInfos(req:$req){
      status,message, data {
        noteId, title,group,type,subType,title, 
        ownerName,ownerId,pageNo,total,allocated,selected,visible
      }
    }
  }
`),
//pages:[{noteId:"c1.n1",pageNo:0,selected:true}
'onClassEvent':gql(`subscription onClassEvent($classId:String) {
    onClassEvent(classId:$classId) {
      status, message, data {
        cmd,scmd ,classId, sesId, noteId,pageNo,begSeq,isRepaint
        session  { id, userType, subType, devType,username, uid }
        classInfo { ${classCols} }
        pages { noteId, pageNo, pageId, group, 
              ownerName,ownerId,
              title,selected, visible  }
        paths { noteId, drawable, pageNo, pageId, type,  ownerId,drawerId,attrs }
        chats { id, seq,  fromName, fromSesId, body, createdAt }
        setting { ${settingCols}}
        etcSetting { ${etcSettingCols} }
        editLock { userType,isOwnerLock,isOtherLock }
        classSharing { ${classSharingCols} }
        noti {${notiCols}}
      }
    }
}`),

'onClassEventx':gql(`subscription onClassEvent($classId:String) {
  onClassEvent(classId:$classId) {
    status, message, data {
      cmd,scmd ,classId, sesId, noteId,pageNo,begSeq, isRepaint,
      classInfo { ${classCols} }
      pages { noteId, pageNo, pageId, group, 
            ownerName,ownerId,
            title,selected, visible  }
      paths { noteId, pageNo, pageId, type,  ownerId,drawerId,attrs }
      chats { id, seq,  fromName, fromSesId, body, createdAt }
      setting { isAnswerNoteAllowed,isParentsAllowed,isParentsChatAllowed, isTeacherNoteAllowed}
      etcSetting {isAnswerBookShown,isCameraTeacherShown,
            isCameraAllShown,isCameraShown,isPenToolShown,
            isTeacherNoteShown,       isTeachingBookShown }
      editLock { userType,isOwnerLock,isOtherLock }
      session  { id, userType, subType, devType,username, uid }
      classSharing { ${classSharingCols} }
      noti {${notiCols}}
    }
  }
}`),


'ftnoti_notiClassEvent':gql(`
mutation ftnoti_notiClassEvent($req:FtClassEventIn) {
    ftnoti_notiClassEvent(req:$req){
        status,message
      }
  }`)
,


'ftnoti_sendChat':gql(`
mutation ftnoti_sendChat ($req: FtChatMsgMutReq) {
  ftnoti_sendChat(req:$req){
    status,message,data {
      id, seq, fromName,fromSesId, body, createdAt
    }
  }
}`),

'ftnoti_getChats':gql(`
query ftnoti_getChats ($req: FtChatMsgReq) {
  ftnoti_getChats(req:$req){
    status,message,data {
      id, seq, fromName,fromSesId, body, createdAt
    }
  }
}`)
}
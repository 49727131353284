import {rzlog,IsFail } from "./inc"
import './rznote.ui.css'
import { RzDlg, RzPos, RzRect, RzSizeDlg, RzUiLet, RzUiOpts} from './rzcmn.ui'
import { RzNote, RzPenAttr } from "./rznote.ui"
import { RzoNotePath } from "./rznote.ui.dto"
import { RzNoteView } from "./rznote.ui.draw"
import { RzDrawPen } from "./rznote.ui.edit"

/****************/
const rzIs=rzlog.makeDefs(true,true)
export const RzNoteUiNoPen_setDbg=rzIs.setDbg
 
let isDbgUi=true;
export function RzNoteUiEditLine_setDbgUi(b:boolean){
    isDbgUi=b
}
const isDbgBorder=false;
/****************
 * 
 */
export class RzNullPen extends RzDrawPen{

    paths:RzoNotePath[]=[]
    movePos:any={}
    oldOpacity?:number;

    constructor(note:RzNoteView,type:string,pr:RzPenAttr){
        super(note,type,pr)
       
        this.paths=[]
    }//constructor

    setAttr(attr:RzPenAttr){
        super.setAttr(attr)
 
    }


    penInit(note:RzNote){
        rzlog.debug('NullPen.penInit ' );
        if( note.canvas) {
            note.canvas.style.cursor='hand';
        }
    }                        

    bindPenAttrs(note:RzNote,p:RzPenAttr){
   
    }

    penMove(note:RzNote, e:MouseEvent){
            rzlog.debug('NullPen.penMove ' );
   
    }


    penUp(note:RzNote,e:MouseEvent){
            rzlog.debug('NullPen.penUp: pressed=',this.pressed);
            
 
            this.pressed=false;
    }

    penEnd(note:RzNote){
       
        if( note.canvas) {
            note.canvas.style.cursor='crosshair';
        }
        
    }

}//class

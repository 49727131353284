
 
import { CSSProperties } from "react";
import { rzlog } from "../../inc";
import { CmComponent, RzBtn, RzCol, RzImg, RzRow, RzTxt } from "./rzcomp";
import { FT_LAYOUT2_FLOAT, FT_LAYOUT2_FLOAT2, FT_LAYOUT2_FRAME, FT_LAYOUT2_PORTRAIT, FT_LAYOUT2_PORTRAIT_FLOAT, FT_LAYOUT2_PORTRAIT_FLOAT2 } from "../../consts";
 


/**********
 * 
 */
export interface FtBothStat {    
    isVertical?:boolean;    
}
export class FtBasFrame<P={},T extends FtBothStat={}> extends CmComponent<P,T>  {    
    
    constructor(pr?:any){
        super(pr);        
    }
    mql:any;
    mediaQueryChanged:any;
    componentDidMount(){        
        this.mql = window.matchMedia('(orientation: landscape)');
        this.mediaQueryChanged = () => this.handleOrientationChange();
        this.mql.addListener(this.mediaQueryChanged);
        this.handleOrientationChange();
    }
    
    componentWillUnmount(): void {        
        if (this.mediaQueryChanged) {
            this.mql.removeListener(this.mediaQueryChanged);
        }
    }

    handleOrientationChange() {        
        if (this.mql.matches) {
            // Landscape 모드                        
            this.setState({isVertical:false});
        } else {            
            // Portrait 모드
            this.setState({isVertical:true});
        }        
    }

    render(){
        let ftSt:CSSProperties={display:'flex', width:'100%',height:'100dvh',backgroundColor:'#222222', overflow:"auto" };
        
        return  (<RzCol style={ftSt} className="ft2scroll">
                        <RzRow style={{ width:'100%', flexShrink: 0}}>{this.doDrawTop()}</RzRow>
                        <RzRow style={{ flex:'1 0 auto', width:'100%',overflow:"auto", flexGrow: 1}}>{this.doDrawBody()}</RzRow>
                        <RzRow style={{ width:'100%',height:40, flexShrink: 0}}>{this.doDrawBottom()}</RzRow>
                </RzCol>);
    }

    doDrawTop(){
        return (<RzRow style={{ width:'100%', height:80, backgroundColor:'#222222'}}></RzRow>);
    }

    doDrawBody(){
        console.log('doDrawBody', this.state.isVertical)
        return (<RzCol style={{width:'100%',height:'100%' ,justifyContent:'space-between'}}></RzCol>);
    }

    doDrawBottom(){
        return (<RzRow style={{width:'100%',height:40,backgroundColor:'#000000'}}></RzRow>);
    }
};//class

/**********
 * 
 */
export interface BothStat {
    height:number;
    width:number;
    isVertical?:boolean;
    isChatOff?:boolean;
    isChatDlgOn?:boolean;
}
export class FtBothFrame<T={},S extends BothStat = {height:0,width:0}> extends CmComponent<T,S>  {
    
    
    constructor(pr?:any){
        super(pr);        
    }
    
    
    componentDidMount(){
        rzlog.debug("Frame.RESIZE.init");
        window.addEventListener('resize',(e)=>{
           
            let h=window.innerHeight;
            let w=window.innerWidth;
           //  rzlog.debug("Frame.RESIZE:w=",w,',h=',h);
            //let h=(e.target as any).innerHeight;
            this.setState({height:h+3,width:w});
        })                

        this.mql = window.matchMedia('(orientation: landscape)');
        this.mediaQueryChanged = () => this.handleOrientationChange();
        this.mql.addListener(this.mediaQueryChanged);
        this.handleOrientationChange();
    }
    handlerFunc:any;
    mql:any;
    mediaQueryChanged:any;
    componentWillUnmount(): void {
        //window.removeEventListener('resize',);
        //window.removeEventListener('orientationchange',this.handlerFunc);
        if (this.mediaQueryChanged) {
            this.mql.removeListener(this.mediaQueryChanged);
        }
    }
    
    handleOrientationChange() {        
        if (this.mql.matches) {
            // Landscape 모드            
            this.setState({isChatOff:false, isVertical:false});            
        } else {            
            // Portrait 모드
            this.setState({isChatOff:true, isVertical:true});
        }        
    }

    render(){
        let ctx=this.getCtx();
        let dctx={}
        dctx=this.doInitCtx(dctx);

        return  this.doDrawBoth(dctx);
    }
    
    doInitCtx(dctx:any){
        return dctx;
    }

    doDrawBoth(dctx:any){
        let height = this.state.height+3;

        return (<RzRow style={{ height:height,  width:'100%' }}>
                {this.doDrawLeft(dctx)} 
                {this.doDrawRight(dctx)}
            </RzRow>);
    }

    minLeft=600;
    minRight=800;
    calctWidths(rate:number){
        //let w=window.innerWidth;
        let w=this.state.width;
        
        //let tw= parseInt( (w*(rate/100)).toFixed());
        let tw=this.minLeft;
        if(tw<this.minLeft) tw=this.minLeft;
        
        let rw=w-tw;
        if(rw<this.minRight) rw=this.minRight;
        return  {leftWidth:tw, rightWidth:rw};
    }


    doDrawLeft(dctx:any){
        let ws=this.calctWidths(40);
        console.log('dctx',this.getCtx())
        let ctx = this.getCtx();
        
        // notInCase
        // return (<RzCol style={{ display:'flex', flexShrink:0, width:ws.leftWidth,height:'100%',backgroundColor:'#222222'}}>
        return (<RzCol style={{ display:'flex', flexShrink:0, height:'100%'}} id="mainFrameLeftPanel">
                    <RzRow style={{ width:'100%'}} className="mainFrameTopBack">
                        {this.doDrawTop('left')}
                    </RzRow>
                    <RzRow style={{ width:'100%'}} className="mainFrameCam">
                        {this.doDrawBody('left')}
                    </RzRow>
                    <RzRow style={{ width:'100%'}} className={`mainFrameChat ${ctx.setting?.isChatOff ? 'notInCase':''}  ${this.state.isChatDlgOn ? 'notInCase dlgCase' : ''}`}>
                        {this.doDrawBottom('left')}
                    </RzRow>
            </RzCol>);
    }

    doDrawRight(dctx:any){
        let ctx = this.getCtx();                
        let chatOff=Boolean(ctx.setting?.isChatOff);
        
        let ws=this.calctWidths(40);
        return ( <RzCol id='mainFrameRightPanel' style={{  flexShrink:0, width:ws.rightWidth,height:'100%',backgroundColor:'#222222'}}>
                        <RzRow style={{ width:'100%',  }}>
                            {this.doDrawTop('right')}
                        </RzRow>
                        <RzRow style={{ width:'100%' , flex:1}} className={`noteAreaBack ${!chatOff && !this.state.isChatDlgOn ? 'onChatCase':''}`}>
                            {this.doDrawBody('right')}
                        </RzRow>
                        <RzRow style={{ width:'100%', height:40, flexShrink:0}}>
                            {this.doDrawBottom('right')}
                        </RzRow>
                </RzCol>
        );
    }
 

    doDrawTop(side:string){
        return (<RzRow style={{ width:'100%'}}>
                        
                </RzRow>)
    }

    doDrawBody(side:string){
        let ctx=this.getCtx();
        let camCount=ctx.camCount||0;
        return (<RzCol style={{width:'100%',height:'100%' ,justifyContent:'space-between'}}>
                    
                </RzCol>
        )
    }

    doDrawBottom(side:string){
        return (<RzRow style={{width:'100%',height:40,backgroundColor:'#000000'}}>
                        
                </RzRow>)
    }

    /********************* */

 

};//class



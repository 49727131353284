/**
 *  S1xLecture
 */
 
import '../ftclass/ftclass.ui.css';
import { CSSProperties, Component, ReactNode, createContext, createRef } from "react";
import { FtClassCtx, FtUiContext } from "../ftclass2/ftui.context";
import { FtClass2Repo } from '../../repo/ftclass2.repo';
import { rzlog } from '../../inc';
import { FtCmnDlg } from './ftdlg.ui';
import { FtClassRepoMng } from '../../repo/ftclass.repo-mng';
import resizeEdge from '../ftclass2/images/resize_edge.svg'
 

/********************/ 

class CmBasLet <P={},S={}> extends Component<P,S>{

};

/********************/ 
export class CmComponent<P={},S={}> extends Component<P,S> {
    static contextType = FtClassCtx;

    getCtx():FtUiContext{
        return  this.context as FtUiContext;
    }

    getRepo():FtClass2Repo {
        let ctx= this.context as FtUiContext;
        return ctx.repo!;
    }
    getRepoMng():FtClassRepoMng |null{
        let ctx= this.context as FtUiContext;
        if(ctx.repo) return ctx.repo.getRepoMng();
        return null;
    }

 
}//class


/********************/ 
export interface RzBasProp  {
    id?:string;
    children?:ReactNode| JSX.Element;
    className?:string;
    style?:CSSProperties;
    disabled?:boolean;
    onClick?:(e?:any)=>void;
    onMouseMove?:(e?:any)=>void;
    onMouseDown?:(e?:any)=>void;
    onMouseUp?:(e?:any)=>void;
     
}

/********************** */
export interface RzCmnProp extends RzBasProp {
    
}


export class RzCmnLet<P extends RzBasProp={},S={}> extends Component<P,S> {
    constructor(pr?:any){
        super(pr);
    }

    divRef=createRef<HTMLDivElement>();

    getProps(pr:any){
        return pr;
    }

    render(){
        //let {style:st,  ...prs}=this.props;
        let prs= this.getProps(this.props);
        return (<div {...prs} ref={this.divRef}>{this.props.children}</div>);
    }

    getClientRect():DOMRect|undefined{
        let r=this.divRef.current?.getBoundingClientRect();
        return r;
    }

    scrollTo(x:number,y:number){
        this.divRef.current?.scrollTo(x,y);
    }
    
    scrollEnd(){
        if(!this.divRef.current) return;

        this.divRef.current.scrollTop = this.divRef.current.scrollHeight;
    }
}


/******************** 
 * 
 */

export interface RzColProp extends RzCmnProp {
    
}

export class RzCol extends RzCmnLet<RzColProp> {
    constructor(pr?:any){
        super(pr);
    }

    getProps(props :any){
        let {style:st,  ...prs}=props;
        return {...prs, style:{display:'flex', flexFlow:'column',  ...st}};
    }

    // divRef=createRef<HTMLDivElement>();
    // render(){
    //     let {style:st,  ...prs}=this.props;

    //     return (<div {...prs} ref={this.divRef} style={{display:'flex', flexFlow:'column',  ...st }} 
    //                 onMouseDown={this.props.onMouseDown}
    //                 onMouseUp={this.props.onMouseUp}
    //                 onMouseMove={this.props.onMouseMove}
                     
    //                 onClick={this.props.onClick}>{this.props.children}</div>);
    // }

    // getClientRect():DOMRect|undefined{
    //     let r=this.divRef.current?.getBoundingClientRect();
    //     return r;
    // }

    // scrollTo(x:number,y:number){
    //     this.divRef.current?.scrollTo(x,y);
    // }
    
    // scrollEnd(){
    //     if(!this.divRef.current) return;

    //     this.divRef.current.scrollTop = this.divRef.current.scrollHeight;
    // }
}
/******************** */

// export interface RzRowProp extends RzBasProp {
// }

export interface RzRowProp  extends RzCmnProp {}
// Component<RzRowProp> 
export class RzRow extends RzCmnLet<RzColProp>{
    constructor(pr?:any){
        super(pr);
    }
    getProps(props :any){
        let {style:st,  ...prs}=props;
        return {...prs, style:{display:'flex', flexFlow:'row',  ...st}};
    }
}

/******************** */

export interface RzBtnProp  extends RzBasProp {   
    title?:string;
    text?:string;
    icon?:string|ReactNode;
    textStyle?:CSSProperties;
    

    tail?:string|ReactNode;
}

export class RzBtn extends Component<RzBtnProp> {
    constructor(pr?:any){
        super(pr);
    }

    render(){
        const {style:st,icon, textStyle, ...prs}=this.props;

        let type=typeof icon;
       // alert('type='+type);
        let src=null;
        if(type==='string' )src=icon;

        let text=this.props.text||this.props.title;

        let tailEl:ReactNode|null=null;
        let tailStr:string|null=null;
        
        if('string' !==typeof this.props.tail) tailEl=this.props.tail;
        else  tailStr=this.props.tail;


        return (<RzRow {...prs}  style={{  cursor:'pointer',  ...st }}  >
                    {(this.props.icon && type==='string')&&(<img src={src as string}/>)}
                    {(this.props.icon && type==='object')&&(this.props.icon)}
                    <span style={{color:'white',...textStyle}}>{text}</span>
                    {(Boolean(tailEl))&&(tailEl)}
                </RzRow>);
    }
}//

/******************** */

export interface RzTxtProp extends RzBasProp  {   
    text?:string;
    textStyle?:CSSProperties;
}

export class RzTxt extends Component<RzTxtProp> {
    count=0;
    constructor(pr?:any){
        super(pr);
        this.count=RzTxt.txtCount++;
    }

    static txtCount=1;

    render(){
        const {style:st,text,textStyle, ...prs}=this.props;
        let hasCr= (text)? text.indexOf('\n')>0:false;
        let texts=(text)? text.split('\n'):[];
        let title=text;
        
        if(!hasCr) return (<div {...prs} style={{display:'flex', flexFlow:'row',   ...st }}  >
                        <span style={{color:'white',...textStyle}}>{title}</span>
                    </div>);
        else {
            return (<div  {...prs} style={{display:'flex', flexFlow:'column',   ...st }}  >
                {texts&& texts.map((t,i)=>(<div  key={"rzTxt-"+this.count+'.'+i}style={{display:'flex', flexFlow:'column'}} ><span 
                        style={{color:'white',...textStyle}}>{t}</span></div>))}
            </div>);
        }
    }
}//


/********************
 * 
 */
export interface RzTxtInProp extends RzBasProp  {   
    text?:string;
    textStyle?:CSSProperties;
    onChange?:(txt:string)=>void;
}

export interface RzTxtInStat  {   
    text?:string;
}

export class RzTxtIn extends Component<RzTxtInProp,RzTxtInStat> {
    constructor(pr?:any){
        super(pr);
        this.state={};
    }

    render(){
        const {style:st,text,textStyle,onChange, ...prs}=this.props;
  
        return (<div  {...prs} style={{display:'flex', flexFlow:'column',   ...st }}  >
                   <input value={this.state.text} defaultValue={this.props.text}
                            onKeyDown={(e)=>{
                                if(e.key=='Enter'){
                                    if(this.props.onChange && this.state.text) this.props.onChange(this.state.text);
                                }
                            }}  
                            onChange={(e)=>{ 
                                
                                        //alert('ddd');
                                     this.setState({text:e.target.value});
                                     //if(this.props.onChange) this.props.onChange(e.target.value);
                            }} />
        </div>);
    }
}//
/******************** */

export interface RzImgProp  {   
    src?:string;
    style?:CSSProperties;
    
}

export class RzImg extends Component<RzImgProp> {
    constructor(pr?:any){
        super(pr);
    }

    render(){
        let st=this.props.style;
        return (<img src={this.props.src} style={this.props.style}/>);
    }
}//

/************** */

export const CmHasElChild=(el:HTMLElement|null , tel:HTMLElement|null):boolean=>{
    // console.debug('>>>  el.id=',el?.id,',tgt=',tel);
    if(!tel) return false;
    if(!el)return false;
    //console.debug('el.id=',el.id);
    if(el==tel) return true;

    var cols=el.children;
    for (let i=0;i<cols.length;i++){
        var cel=cols.item(i) as HTMLElement;
        var b=CmHasElChild(cel,tel);
        if(b) return true;
    }
    
    return false;
}

const getParent= (el:HTMLElement , tgtId:string):HTMLElement | null=>{
     
    if(!el) return null;

    if(el.id===tgtId) return el;
    let pel=el.parentElement ;

    if(!pel) return null;
    return getParent(pel,tgtId);
}


export interface CmMousePos {
    prevMousXPos:number;
    prevMousYPos:number;
    prevElXPos:number;
    prevElYPos:number;
    mousePressed:boolean;
    moved:boolean;
    prevZindex?:string;
    onSelected?:(e?:MouseEvent )=>void;
    
}

/***************************
 * 
 */
let isDlgOn=false;
let _dlgs:HTMLElement[]=[];

const cmMutFocusDlg =(dlg:HTMLElement,mpos:CmMousePos)=>{
    rzlog.debug("setFocusDlg:dlgs=",_dlgs);
    let vs=_dlgs.filter(t=> t===dlg);
    if(vs.length==0) {
        
        let ts=_dlgs.map(t=> { t.style.zIndex="3"; return t;} );
        _dlgs =[...ts, dlg ];

        rzlog.debug("setFocusDlg.1:dlgs=",_dlgs,', new Dlg');
    } else {
        rzlog.debug("setFocusDlg.1:dlgs=",_dlgs,', put Dlg');
        let ts=_dlgs.map(t=> { 
            if(t!==dlg) t.style.zIndex="8";
            else t.style.zIndex="9";
         return t;} );
        _dlgs=ts;
    }
}

const cmDelDlg =(  dlg:HTMLElement)=>{
    let vs=_dlgs.filter(t=> t!==dlg);
    _dlgs = vs;

    rzlog.debug("Focus : del dlgs= ",_dlgs);

}



export const CmUnboundMouseEventForDlgDiv=(divEl:HTMLDivElement)=>{
        cmDelDlg(divEl);
}

const cmSetFocus=(rootElement:HTMLElement,mpos:CmMousePos,e:MouseEvent )=>{
        
    if(e.buttons==undefined){
        let ee=e as any;
        if(!ee.changedTouches|| ee.changedTouches.length!=1) return ;

        let e0=ee.changedTouches[0];
        mpos.prevMousXPos=e0.pageX;
        mpos.prevMousYPos=e0.pageY;
        rzlog.debug('>>!! touch > screen.x=', e0.pageX,',y=',e0.pageY);

    } else {
        mpos.prevMousXPos=e.pageX;
        mpos.prevMousYPos=e.pageY;
        rzlog.debug('>>!! screen.x=', e.pageX,',y=',e.pageY);

    }
    

        let r=rootElement.getBoundingClientRect();
        mpos.prevElXPos=r.left;
        mpos.prevElYPos=r.top;

        let pix=rootElement.style.zIndex;
        if(!mpos.prevZindex) mpos.prevZindex=pix;

        rootElement.style.zIndex="8";

        rzlog.debug('>>!! Div.x=', r.left,',y=',r.top,'pix=',pix,',zIx=', rootElement.style.zIndex,
        ',dlg.id=',rootElement.id);

        mpos.mousePressed=true;

        cmMutFocusDlg(rootElement,mpos);
        if(mpos.onSelected) mpos.onSelected(e);
}


export const CmSetFocusForDlgDiv=(divEl:HTMLDivElement,  mpos:CmMousePos)=>{
    cmMutFocusDlg(divEl,mpos);
}

export const CmCloseForDlgDiv=(divEl:HTMLDivElement,  mpos:CmMousePos)=>{
    //cmMutFocusDlg(divEl,mpos);
    rzlog.debug("Focus > CmCloseForDlgDiv: ",divEl?.id );
    cmDelDlg(divEl);
}

const cmResizeEvent_MouseDown=(e:MouseEvent, resizeCtrl:CmResize)=>{
    const el0 = e.target as HTMLElement;
    
     if(e.buttons!==1) return false;

    let ctrlRoot = resizeCtrl.refDiv.current;
    if(CmHasElChild(ctrlRoot, el0)){
        let x0=e.screenX;
        let y0=e.screenY;
        // let r=resizeCtrl.refDiv.current?.getBoundingClientRect();
        // let x=r?.left;
        // let y=r?.top;

        let divX=resizeCtrl.refDiv.current?.offsetLeft;
        let divY=resizeCtrl.refDiv.current?.offsetTop;
        (ctrlRoot as any)._resizeMouseSelected=true;
        
        //(ctrlRoot as any)._resizeMouseY=y0;

        rzlog.debug("Scr.mouseMove(Down) : x0=",x0,',y0=',y0,',dvX=',divX,',dvY=',divY);

        if(resizeCtrl.refDiv.current){
            rzlog.debug("Scr.mouseMove(Down) : divX=",divX,',divY=',divY);
            // (ctrlRoot as any)._resizemouseDivX=x;
            // (ctrlRoot as any)._resizemouseDivY=y;
            (ctrlRoot as any)._resizeMousePos={x:x0,y:y0,divX:divX,divY:divY};
        }
        return true;
    }

    return false;
}

const cmResizeEvent_MouseMove=(e:MouseEvent, resizeCtrl:CmResize)=>{
    const el0 = e.target as HTMLElement;
    if(e.buttons!==1) return false;

    let ctrlRoot = resizeCtrl.refDiv.current;
    if(CmHasElChild(ctrlRoot, el0)){
        if(!(ctrlRoot as any)._resizeMouseSelected) return false;

        let x=e.screenX;
        let y=e.screenY;
        let pos=(ctrlRoot as any)._resizeMousePos;
        let x0=pos.x;
        let y0=pos.y;

        let dx=x-x0;
        let dy=y-y0;
      

        let r=resizeCtrl.refDiv.current?.getBoundingClientRect();
        if(resizeCtrl.refDiv.current){
            // let tx=resizeCtrl.refDiv.current.clientLeft;
            // let ty=resizeCtrl.refDiv.current.clientTop;

            let tx=pos.divX;
            let ty=pos.divY;
            tx=tx+dx;
            ty=ty+dy;

            rzlog.debug("Scr.mouseMove : dvX=",tx,',dvY=',ty,',x=',x,',y=',y,',dx=',dx,',dy=',dy,",dv.st.l=", resizeCtrl.refDiv.current.style.left);

            if(resizeCtrl.refDiv.current){
              //  resizeCtrl.refDiv.current.style.left=''+tx+'px';
              // resizeCtrl.refDiv.current.style.top=''+ty+'px';
                //resizeCtrl.refDiv.current.style.left=tx+'px';
                //resizeCtrl.refDiv.current.style.top=ty+'px';
            }
        }


        if(resizeCtrl.onResize){
            resizeCtrl.onResize(dx,dy);
        }
        return true;
    }

    return false;
}
const cmResizeEvent_MouseUp=(e:MouseEvent, resizeCtrl:CmResize)=>{
    const el0 = e.target as HTMLElement;

    if(e.buttons!=undefined){
        if(e.buttons!==0) return false;
    } else {
        let ee=e as any;
        //if(!ee.changedTouches|| ee.changedTouches.length!=1) return false;
    }
    

    let ctrlRoot = resizeCtrl.refDiv.current;
    if(CmHasElChild(ctrlRoot, el0)){
        if(!(ctrlRoot as any)._resizeMouseSelected) return false;
        (ctrlRoot as any)._resizeMouseSelected=false;
        (ctrlRoot as any)._resizemouseX=0;
        (ctrlRoot as any)._resizemouseY=0;

        return true;
    }

    return false;
}
/***************
 * 
 */

export interface CmResizeProp {
    onResize?:(dx:number,dy:number)=>void;
    left?:number;
    top?:number
    dh?:number;
    dw?:number;
    isBottom?:boolean;
}

export class CmResize  extends Component<CmResizeProp> {

    refDiv=createRef<HTMLDivElement>();
    constructor(pr:CmResizeProp){
        super(pr);
        
    }
    mousePoint: { x: number; y: number } | undefined;
    mousePoint2: { x: number; y: number } | undefined;
    registMouseDownDrag(
        onDragChange: (deltaX: number, deltaY: number) => void,
        stopPropagation?: boolean,
    ) {
        return {
            onPointerDown :(clickEvent: React.PointerEvent<HTMLDivElement>) => {
                this.refDiv.current?.setPointerCapture(clickEvent.pointerId);
                //this.mousePoint={x:clickEvent.clientX, y:clickEvent.clientY};
                this.mousePoint={x:clickEvent.screenX, y:clickEvent.screenY};
            },
            onPointerUp: (clickEvent:React.PointerEvent<HTMLDivElement>) => {
                this.refDiv.current?.releasePointerCapture(clickEvent.pointerId);
                this.mousePoint=undefined;
            },
            onPointerMove: (clickEvent:React.PointerEvent<HTMLDivElement>) => {
                if(! this.mousePoint)return;
                clickEvent.preventDefault();
                //if (stopPropagation) 
                clickEvent.stopPropagation();
                
                

                //this.mousePoint={x:clickEvent.screenX, y:clickEvent.screenY};
                //  const deltaX = clickEvent.clientX - (this.mousePoint?.x||clickEvent.clientX);
                //  const deltaY = clickEvent.clientY - (this.mousePoint?.y||clickEvent.clientY);
                 //this.mousePoint={x:clickEvent.clientX, y:clickEvent.clientY};
                rzlog.debug('BOTTOM >>!! screen.x=', clickEvent.screenX,',y=',
                clickEvent.screenY,',dx=',clickEvent.movementX,',dy=',clickEvent.movementY);
                 const deltaX = clickEvent.screenX - (this.mousePoint?.x||clickEvent.screenX);
                 const deltaY = clickEvent.screenY - (this.mousePoint?.y||clickEvent.screenY);
                 this.mousePoint={x:clickEvent.screenX, y:clickEvent.screenY};
                onDragChange(clickEvent.movementX, clickEvent.movementY);
            },

            //@RMV
            // onMouseDown: (clickEvent: React.MouseEvent<Element, MouseEvent>) => {
            //     if(clickEvent.button===0) return;
            //     if (stopPropagation) clickEvent.stopPropagation();

            //     this.mousePoint2={x:clickEvent.clientX, y:clickEvent.clientY};
            //     const mouseMoveHandler = (moveEvent: MouseEvent) => {
            //         if(! this.mousePoint2)return;
            //         // const deltaX = moveEvent.screenX - clickEvent.screenX;
            //         // const deltaY = moveEvent.screenY - clickEvent.screenY;
            //         if (stopPropagation) moveEvent.stopPropagation();
            //         const deltaX = moveEvent.clientX - (this.mousePoint2?.x||moveEvent.clientX);
            //         const deltaY = moveEvent.clientY - (this.mousePoint2?.y||moveEvent.clientY);
            //         onDragChange(deltaX, deltaY);
            //         this.mousePoint2={x:moveEvent.clientX, y:moveEvent.clientY};
            //     };

            //     const mouseUpHandler = () => {
            //         this.mousePoint2=undefined;
            //         document.removeEventListener('mouseup', mouseUpHandler);
            //         document.removeEventListener('mousemove', mouseMoveHandler);
            //     };

            //     document.addEventListener('mousemove', mouseMoveHandler);
            //     document.addEventListener('mouseup', mouseUpHandler, { once: true });
            // },
        };
    }
    
    render(){
        let leftPos=this.props.left;
        let topPos=0;
        let curSt='ne-resize';
        if(this.props.isBottom && this.props.top){
            topPos=this.props.top;
            curSt='se-resize';
        } 

        let dw=this.props.dw||0;
    
        /*
        {...this.registMouseDownDrag((deltaX, deltaY) => {
                    this.onResize(deltaX, deltaY)
                    }, true)}*/
        return (
            // <div ref={this.refDiv} style={{position:'relative', left:leftPos, top:-dw, width:dw, height:0}} >
            <div ref={this.refDiv} 
            style={{position:'absolute', 
                        top: topPos, right:0,width:20, height:20, 
                        backgroundColor:'transparent',cursor:curSt}} 
                 {...this.registMouseDownDrag((deltaX, deltaY) => {
                            this.onResize(deltaX, deltaY,true)
                 }, true)}
            >
                <div style={{width:dw, height:dw, backgroundImage: `url(${resizeEdge})`, backgroundSize: 'cover', transform: 'rotate(90deg)'}}>

                </div>
            </div>
        );
    }

    onResize(dw:number, dh:number,isPointer?:true){
        if(!Boolean(isPointer))return;
        if(this.props.onResize) this.props.onResize(dw,dh);

    }
}

/***************
 * 
 */


export const CmBindMouseEventForDlgDiv=(divEl:HTMLDivElement,  mpos:CmMousePos, isRelative?:boolean, xPivot?:number,resizeCtrl?:React.RefObject<CmResize>)=>{

    document.addEventListener('contextmenu', event => event.preventDefault());

    window.addEventListener('mousedown',(e:MouseEvent)=>{  

            rzlog.debug('mouseDown : e.buttons=',e.button);
            if(e.button!==0) return; //LBUTTON
           
            if(resizeCtrl?.current ){
                let b= cmResizeEvent_MouseDown(e, resizeCtrl?.current);
                if(b) return;
            }//if

            let pdlg= (document as any)._focusedDlg;
            if(pdlg!==divEl)(document as any)._focusedDlg=divEl;

            const el = e.target as HTMLElement;
            let rootElement = divEl;
            if(CmHasElChild(rootElement, el)){
                cmSetFocus(rootElement,mpos,e);
            }
    });

 
    window.addEventListener('mouseup',(e)=>{
        // rzlog.debug('mouseUp')
          //@   e.preventDefault();
             if(resizeCtrl?.current ){
                 let b= cmResizeEvent_MouseUp( e, resizeCtrl?.current);
                if(b) return;
            }//if


            if(e.button==2) {
              //@   e.preventDefault();
 
                return;
            }
            
            if(e.button!==0) return;
            e.preventDefault();

            if(!mpos.mousePressed) return;
            let rootElement = divEl;
            
            if(mpos.prevZindex) {
               // rootElement.style.zIndex=mpos.prevZindex;
                //mpos.prevZindex=undefined;
            }

            (document as any)._focusedDlg=null;
            mpos.mousePressed=false;
    });

  

    window.addEventListener('mousemove',(e)=>{
        //@ e.preventDefault();

        if(resizeCtrl?.current ){
            let b= cmResizeEvent_MouseMove( e, resizeCtrl?.current);
            if(b) return;
        }//if


        if(e.button!==0) return;
        if(!mpos.mousePressed) return;

        let mx=e.pageX;
        let my=e.pageY;
        let dx=mx-mpos.prevMousXPos;
        let dy=my-mpos.prevMousYPos;

        if(Boolean(isRelative)){
            dx=e.pageX-mpos.prevMousXPos;
            dy=e.pageY-mpos.prevMousYPos;
        } 

        const el = e.target as HTMLElement;

        
        let rootElement = divEl;


        if(Boolean(isRelative)){
                 let ox=mpos.prevElXPos;
                 let oy=mpos.prevElYPos;
 
                
                let xoff=0;
                let yoff=0;
                let pr=rootElement.parentElement?.getBoundingClientRect();
                if(pr){
                    rzlog.debug('>>!! p.Div.x=', pr.width,',y=',pr.top);
                    xoff=pr.left;
                    yoff=pr.top;
                   
                }

                let tx=ox+dx-xoff; 
                let ty=oy+dy-yoff;
                if(!isDlgOn){
                    isDlgOn=true;
                  // alert('ox='+ox+',oy='+oy+', sy.top='+rootElement.style.top+',dy='+dy+',mx='+mx+',my='+my+',dx='+dx+',dy='+dy+',tx='+tx+',ty='+ty+',ow='+ow);
                }

                

                rootElement.style.left=""+ (tx)+"px";
                rootElement.style.top= ""+(ty)+"px";
            
        } else {
                rootElement.style.left= (mpos.prevElXPos+dx)+"px";
                rootElement.style.top= (mpos.prevElYPos+dy)+"px";
        }


    });


   window.addEventListener('touchstart',(e:any)=>{
        rzlog.debug('window.touchstart : e=',e);
        if((document as any)['_touchStarted']) return;

        if(resizeCtrl?.current ){
            let b= cmResizeEvent_MouseDown(e, resizeCtrl?.current);
            if(b) return;
        }//if

        let pdlg= (document as any)._focusedDlg;
        if(pdlg!==divEl)(document as any)._focusedDlg=divEl;

        const el = e.target as HTMLElement;
        let rootElement = divEl;
        if(CmHasElChild(rootElement, el)){
 
            cmSetFocus(rootElement,mpos,e);
        }
   })



   window.addEventListener('touchmove',(e:any)=>{
            rzlog.debug('window.touchmove. : e=',e,',mpos.mousePressed=',mpos.mousePressed);
            if((document as any)['_touchStarted']) return;
            if(resizeCtrl?.current ){
                let b= cmResizeEvent_MouseMove( e, resizeCtrl?.current);
                if(b) return;
            }//if

            if(!mpos.mousePressed) return;
            mpos.moved =true;

            let e0=e.changedTouches[0];
            rzlog.debug('touchmove : e0=',e0);
            let mx=e0.pageX;
            let my=e0.pageY;
            let dx=mx-mpos.prevMousXPos;
            let dy=my-mpos.prevMousYPos;

            if(Boolean(isRelative)){
                dx=e0.pageX-mpos.prevMousXPos;
                dy=e0.pageY-mpos.prevMousYPos;
            } 

            const el = e.target as HTMLElement;

            
            let rootElement = divEl;


            if(Boolean(isRelative)){
                    let ox=mpos.prevElXPos;
                    let oy=mpos.prevElYPos;

                    
                    let xoff=0;
                    let yoff=0;
                    let pr=rootElement.parentElement?.getBoundingClientRect();
                    if(pr){
                        rzlog.debug('>>!! p.Div.x=', pr.width,',y=',pr.top);
                        xoff=pr.left;
                        yoff=pr.top;
                    
                    }

                    let tx=ox+dx-xoff; 
                    let ty=oy+dy-yoff;
                    if(!isDlgOn){
                        isDlgOn=true;
                    // alert('ox='+ox+',oy='+oy+', sy.top='+rootElement.style.top+',dy='+dy+',mx='+mx+',my='+my+',dx='+dx+',dy='+dy+',tx='+tx+',ty='+ty+',ow='+ow);
                    }

                    

                    rootElement.style.left=""+ (tx)+"px";
                    rootElement.style.top= ""+(ty)+"px";
                
            } else {
                    rootElement.style.left= (mpos.prevElXPos+dx)+"px";
                    rootElement.style.top= (mpos.prevElYPos+dy)+"px";

                    rzlog.debug('touchmove.MoteTo : x=',(mpos.prevElXPos+dx),',y=',(mpos.prevElYPos+dy));
            }
   })
   window.addEventListener('touchend',(e:any)=>{
 
       if(resizeCtrl?.current ){
            let b= cmResizeEvent_MouseUp( e, resizeCtrl?.current);
           if(b) return;
        }//if


        //
     
       if(!mpos.mousePressed  ) {
           // mpos.mousePressed=false;
            //mpos.moved=false;
            return;
       }

      // e.preventDefault();
       let rootElement = divEl;
       
       if(mpos.prevZindex) {
          // rootElement.style.zIndex=mpos.prevZindex;
           //mpos.prevZindex=undefined;
       }

       (document as any)._focusedDlg=null;
       mpos.mousePressed=false;
       mpos.moved=false;
   })



}//method    

/****************
 * 
 */

export const CmBindMouseEventForDlg=(dlgId:string ,  mpos:CmMousePos, isRelative?:boolean, xPivot?:number)=>{

    document.addEventListener('contextmenu', event => event.preventDefault());

    window.addEventListener('mousedown',(e:MouseEvent)=>{  
            e.preventDefault();
            //let id=e.target?.id;
            //if(e.button!==1) {this.mousePressed=false ; return;}
            if(e.button==2) {
                e.preventDefault();
                // e.stopPropagation();
                // e.preventDefault();
                return;
            }

            if(e.button!==0) return;
            e.preventDefault();
            // console.log("mousedown-",e.button==0);
            const el = e.target as HTMLElement;
            
            let rootElement = document.getElementById(dlgId) as HTMLElement;
            if(CmHasElChild(rootElement, el)){

                    if(Boolean(isRelative)){

                        mpos.prevMousXPos=e.clientX;
                        mpos.prevMousYPos=e.clientY;
                    } else {
                        mpos.prevMousXPos=e.clientX;
                        mpos.prevMousYPos=e.clientY;
    
                    }

        
                    mpos.prevElXPos=rootElement.offsetLeft;
                    mpos.prevElYPos=rootElement.offsetTop;

                    mpos.mousePressed=true;
            }
    });


    window.addEventListener('mouseup',(e)=>{
            e.preventDefault();
        // console.log("mouseup-",e.button);
            if(e.button==2) {
                e.preventDefault();
                // e.stopPropagation();
                // e.preventDefault();
                return;
            }
            
            if(e.button!==0) return;
            e.preventDefault();

            if(!mpos.mousePressed) return;
            mpos.mousePressed=false;
    });

 


    window.addEventListener('mousemove',(e)=>{
        e.preventDefault();
        //console.log("mousemove-",e.button);
        //if(e.button==0) {mpos.mousePressed=false ; return;}
        if(e.button!==0) return;
        if(!mpos.mousePressed) return;

        let mx=e.clientX;
        let my=e.clientY;
        let dx=mx-mpos.prevMousXPos;
        let dy=my-mpos.prevMousYPos;

        if(Boolean(isRelative)){
            dx=e.clientX-mpos.prevMousXPos;
            dy=e.clientY-mpos.prevMousYPos;
        } 

        const el = e.target as HTMLElement;

        
        let rootElement = document.getElementById(dlgId) as HTMLElement;


        if(Boolean(isRelative)){
                 let ox=mpos.prevElXPos;
                 let oy=mpos.prevElYPos;
                 let ph=(rootElement.parentElement?.clientTop||0)+ (rootElement.parentElement?.clientHeight||0);
                 //let ow=rootElement.parentElement?.clientWidth||0;
                 let ow=rootElement.parentElement?.clientWidth||0;
              //   ow=0;
                // let py=rootElement.parentElement?.offsetTop||0;
    
                let tx=(-830)+ox+dx;//ox+dx-300;
                let ty=oy+dy-100;
                if(!isDlgOn){
                    isDlgOn=true;
                  // alert('ox='+ox+',oy='+oy+', sy.top='+rootElement.style.top+',dy='+dy+',mx='+mx+',my='+my+',dx='+dx+',dy='+dy+',tx='+tx+',ty='+ty+',ow='+ow);
                }
                rootElement.style.left=""+ (tx)+"px";
                rootElement.style.top= ""+(ty)+"px";
            
        } else {
                rootElement.style.left= (mpos.prevElXPos+dx)+"px";
                rootElement.style.top= (mpos.prevElYPos+dy)+"px";
        }


    });


}//method    

 

/**************
 * 
 */

export const CmBindMouseOutEvent=(dlgId:string, outFn:()=>void)=>{

    window.addEventListener('mousedown',(e:MouseEvent)=>{  
    });

    window.addEventListener('mouseup',(e)=>{
            const el = e.target as HTMLElement;
            let rootElement = document.getElementById(dlgId);
            if(!CmHasElChild(rootElement, el)){
                    if(outFn) outFn();
            }
    });

    // window.addEventListener('mousemove',(e)=>{
    // });


}//method    

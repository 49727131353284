import { rzlog,RzUiLet,scale } from "../../inc";
import { FtLayoutCtx, FtLayoutInit, FtLayoutOpts, FtUiLets } from "../ftclass/ftclass.ui.layout";
import { FtFullLayout } from "./ftclass.ui.layout.full";
import { FtDeskLet } from "../ftclass/ftdesk.ui";
import { FtLayoutSubCtx } from "./ftclass.ui.layout.sub.body";
 

/** dbg */
const rzIs=rzlog.makeDefs()
export const FtClassUiLayoutSub2Note_setDbg =rzIs.setDbg

/**
 * FtSubTopInit
 */
export class FtSub2NoteInit implements FtLayoutInit {
    lman:FtFullLayout;

    constructor(lman:FtFullLayout){
        this.lman=lman;
    }
    
    init(lctx:FtLayoutCtx,pr?:any){
        let lman=this.lman;
        let lctx2=pr as FtLayoutSubCtx
        this.doInitNote(lctx2)
    }

    doInitNote(lctx2:FtLayoutSubCtx){
        let lctx=lctx2.lctx;
        let splitWidth=lctx2.splitWidth;

        if(!lctx.opt.isNoteOn ) return;

        let w=lctx2.bodyCnt===4? 35  : lctx2.bodyCnt===3 ? 
                (lctx.opt.isBookOn? 40: 70 ) : 
                (lctx2.bodyCnt===2? (lctx.opt.isBookOn?50:85) : 100 ) 

        let wp= lctx2.clientW*(w/100 )

        if(lctx.opt.isBookOn) wp-=splitWidth;
        //let cssText= `display:flex; flex-direction:column; top:0px; left:0px;width:${w}%; height:100%; background-color: #333333;`
        let cssText = `display:flex; flex-direction:column; top:0px; left:0px;width:${wp}px; height:100%; background-color: #333333;`
        if(lctx.lets!.note)  { 
            let tgtLet=lctx.lets!.note
            tgtLet.nativeDiv!.style.cssText=cssText
            lctx2.sbody.addChild(lctx.lets!.note)
            tgtLet.setVisible(true)


            tgtLet.onResize({w:wp,h:tgtLet.nativeDiv!.clientHeight})
        } else {
            let ix=(lctx.opt.isSideOn? 1:0 ) + 
                (lctx.opt.isChatOn? 1:0 )+ (lctx.opt.isBookOn?2:0 )
            this.doInitSub2Note(lctx2.sbody,lctx.lets!.note!,ix,cssText,lctx.opt)
        }
    }


    doInitSub2Note(p:RzUiLet,noteLet:FtDeskLet,ix:number,cssText:string,opt?:any)  {
        let lman=this.lman;

        if(lman.doUdateVisble(p,noteLet,ix,cssText)) return

        let nopt={...opt , onFocus:lman.doProcNoteFocus}
        let note=lman.subLetFac.createNoteDesk(p,cssText,lman.repoMng!,nopt)

        lman.lets!.note=note
        rzlog.debug('FtClassUi.FtSub2NoteInit.doInitSub2Note: note=',note)
     }
}
import {rzlog,IsFail } from "./inc"
import './rznote.ui.css'
import { RzNote, RzPenAttr } from "./rznote.ui"
import { RzTextPen } from "./rznote.ui.edit.text"
import { RzMemoPen } from "./rznote.ui.edit.memo"
import {  RzErasePen, RzLinePen, RzRectPen, RzShapePen } from "./rznote.ui.edit.shape"
import { RzDrawPen, RzDragPen } from "./rznote.ui.edit"
import { RzNullPen } from "./rznote.ui.edit.null"


/****************/
const rzIs=rzlog.makeDefs()
export const RzNoteUiEdiFac_setDbg=rzIs.setDbg
 
let isDbgUi=false;
export function RzNoteUiEditFac_setDbgUi(b:boolean){
    isDbgUi=b
}
const isDbgBorder=false;
 
 /****************
 * 
 */

export interface RzDrawPenFac {
    createPen(type:string,pr:RzPenAttr,note:RzNote,prevPen?:RzDrawPen):RzDrawPen|null;
}

/******** */
export enum RzDrawPenEnum {
    PEN='pen',
    PENCIL='pencil',
    MARKER='marker',
    RECT='rect',
    TEXT='text',
    MEMO='memo',
    CIRCLE='circle',
    TRIANGLE='triangle',
    HEXAGON='hexagon',
    ERASER='eraser',
    DRAG='drag',
    NULL='null',
}

/******** */
export class RzDrawPenDefFac implements RzDrawPenFac {
    createPen(type:string,pr:RzPenAttr,note:RzNote,prevPen?:RzDrawPen):RzDrawPen|null{
      let pen:RzDrawPen|null=null
      if(rzIs.d)rzlog.debug('PenFac : type= ',type)
      if(type=== RzDrawPenEnum.PENCIL || type===RzDrawPenEnum.PEN
            ||type===RzDrawPenEnum.MARKER){
          pen=new RzLinePen(note,type,pr)
      } else if(  type===RzDrawPenEnum.RECT){
          pen=new RzRectPen(note,type,pr)
      } else if(type===RzDrawPenEnum.NULL){
            rzlog.debug('null Pen')
            pen=new RzNullPen(note,type,pr)          
      } else if(type===RzDrawPenEnum.TEXT){
          rzlog.debug('text Pen')
          pen=new RzTextPen(note,type,pr)
      } else if(type===RzDrawPenEnum.MEMO){
          rzlog.debug('memo Pen')
          pen=new RzMemoPen(note,type,pr)
      } else if(type===RzDrawPenEnum.HEXAGON){
          rzlog.debug('hexagon pen')
          pen=new RzShapePen(note,type,pr);
          (pen as RzShapePen).setSubType(type)
      } else if(type===RzDrawPenEnum.CIRCLE){
          rzlog.debug('pen circle')
          pen=new RzShapePen(note,type,pr);
          (pen as RzShapePen).setSubType(type)
      } else if(type===RzDrawPenEnum.TRIANGLE){
          rzlog.debug('pen triangle')
          pen=new RzShapePen(note,type,pr);
          (pen as RzShapePen).setSubType('triangle')
      } else if(type===RzDrawPenEnum.ERASER){
          rzlog.debug('eraser Pen')
          pen=new RzErasePen(note,type,pr)
      } else if(type===RzDrawPenEnum.DRAG){
          rzlog.debug('drag Pen')
          pen=new RzDragPen(note,type,pr)
      }

      if(pen){
          if(prevPen?.penEnd){
            prevPen.saveNoteOffsets(note);
            prevPen.penEnd(note)
          } 
          
          pen.penInit(note)
      } 
      
      return pen
  }

}

/****************
 * 
 */


export class RzDrawPenFacHolder {
    static defFac:RzDrawPenFac=new RzDrawPenDefFac();

    static getDrawPenFac() :RzDrawPenFac{
        return this.defFac!;
    }

    static setDrawPenFac(fac:RzDrawPenFac){
        this.defFac=fac;
    }
}//class



import { FtSrcOpt, FtoEditLock, FtoEtcSetting, FtoNoteInfo, FtoPageInfo, FtoSetting, FtoUser } from "../../dto/ftclass.dto";
import { FtClassEvent, FtClassEventEnum } from "../../ftclass.event";
import { FtClassRepoMng } from "../../repo/ftclass.repo-mng";
import { FtDeskEvent, FtDeskEventEnum } from "./ftdesk.ui.event";
import { NewFail,RzOnFn, NewOk,IsFail, RzNoteRepo,RzEventNotier, rzlog,RzRes } from "../../inc";

const rzIs=rzlog.makeDefs();
export const FtDeskUiRepo_setDbg=rzIs.setDbg;

/**
 * FtDeskRepo
 */
export abstract class FtDeskRepo extends RzEventNotier {
    static NOTE_UNLOAD="unload";


    abstract getSetting(): FtoSetting|undefined;
    abstract getEtcSetting(): FtoEtcSetting|undefined;
    abstract getUserInfo(): FtoUser|undefined;
    abstract setUserInfo(u:FtoUser|undefined): void;

    abstract getEditLock(): FtoEditLock;
    abstract getEditLockOther(): FtoEditLock;
    abstract getNoteInfos(stat?:string):Promise<RzRes<FtoNoteInfo[]>>;
    abstract getNoteRepo(noteId:string):Promise<RzRes<RzNoteRepo>>;
    abstract getCurRepo():Promise<RzRes<RzNoteRepo>>;
    abstract getCurNoteInfo():Promise<RzRes<FtoNoteInfo>>;
    abstract putCurRepoAt(ix:number,srcOpt?:FtSrcOpt):Promise<RzRes<void>>;

    abstract getNoteInfo(noteId:string,srcOpt?:FtSrcOpt):Promise<RzRes<FtoNoteInfo>>;
    abstract addNoteInfo(info:FtoNoteInfo,srcOpt?:FtSrcOpt):Promise<RzRes<FtoNoteInfo>>;
    abstract putNoteInfo(noteId:string,info:FtoNoteInfo,srcOpt?:FtSrcOpt):Promise<RzRes<FtoNoteInfo>>;
    abstract delNoteInfo(noteId:string,info:FtoNoteInfo,srcOpt?:FtSrcOpt):Promise<RzRes<FtoNoteInfo>>;

    abstract addNotePage(noteId:string,pgNo:number,srcOpt?:FtSrcOpt):Promise<RzRes<FtoPageInfo>>;
 
    abstract addOnDeskEvent(fn:RzOnFn,pr?:any):void;
    abstract delOnDeskEvent(fn:RzOnFn):void;
    abstract getPages():Promise<RzRes<FtoNoteInfo[]>>;
}//class


/**
 * FtDeskRefRepo
 */
export class FtDeskRefRepo extends FtDeskRepo {
    repoMng?:FtClassRepoMng
    curRepoIx=0
    
    type?:string
    userInfo?:FtoUser;
    
    getSetting(){ return this.repoMng!.getSetting()}
    getEtcSetting(){ return this.repoMng!.getEtcSetting()}

    /** init */
    setType(type:string){this.type=type}
    setRepoMng(repoMng:FtClassRepoMng){
        if(this.repoMng) this.repoMng.delOnClassEvent(this.onClassEvent)

         this.repoMng=repoMng
         this.repoMng.addOnClassEvent(this.onClassEvent,this)
        // let ni=this.repoMng.getCurNoteInfo(this.type)

    }

    setCurRepoAt(ix:number){
        this.curRepoIx=ix;
    }
    
    /**************** 
     * 
     */
    onClassEvent(e:FtClassEvent,pr?:any){
        if(e.srcOpt?.type === 'ref') return ;
        
        if(e.cmd === FtClassEventEnum.PAGES_PUT) {
            if(rzIs.d)rzlog.debug('FtDeskRefRepo.onClassEvent : e=',e,',pr=',pr);
            let nevt=new FtDeskEvent({ ...e, cmd:FtDeskEventEnum.PAGES_PUT });
            pr.notiDeskEvent(nevt);

        } else if(e.cmd === FtClassEventEnum.PAGES_ADD) {
            rzlog.debug('FtDeskRefRepo.onClassEvent : type=',pr.type,'e=',e,',pr=',pr)
            if(e.pages && e.pages.length > 0){
                let isOk=false;
                // if(pr.type==='book'&& e.pages[0].type===pr.type && e.pages[0].subType!=='answer') isOk=true
                // if(pr.type==='note'&& (e.pages[0].type===pr.type || e.pages[0].subType==='answer') ) isOk=true

                if(e.pages[0].group==='book')isOk=true;
                else if(e.pages[0].group==='note')isOk=true;
                
                if(!isOk) return;
            }
            

            let nevt=new FtDeskEvent({ ...e, cmd:FtDeskEventEnum.PAGES_ADD });
            pr.notiDeskEvent(nevt);

            rzlog.debug('FtDeskRefRepo.onClassEvent : type=',pr.type,'deskEvt=',nevt,',pr=',pr)
        } else if(e.cmd === FtClassEventEnum.PAGES_DEL) {
            if(rzIs.d)rzlog.debug('FtDeskRefRepo.onClassEvent : e=',e,',pr=',pr);
            let nevt=new FtDeskEvent({ ...e, cmd:FtDeskEventEnum.PAGES_DEL });
            pr.notiDeskEvent(nevt);

        } else if(e.cmd === FtClassEventEnum.PAGES_ON) {
            
            let isSkip=!e.srcOpt;
            //let isSkip1=!e.srcOpt

            if(rzIs.d)rzlog.debug('FtDeskRefRepo.onClassEvent !!!! - PAGES_ON : skip=',isSkip,', e=',e,',pr=',pr);
            if(isSkip) return;

            let nevt1=new FtDeskEvent({ ...e,  cmd:FtDeskEventEnum.PAGES_ON });
            pr.notiDeskEvent(nevt1);
        } else if (e.cmd === FtClassEventEnum.EDIT_LOCK) {
            
            rzlog.debug('FtDeskRefRepo.onClassEvent !!!! - EDIT_LOCK :  e=',e,',pr=',pr);
            let nevt1=new FtDeskEvent({ ...e,  cmd:FtDeskEventEnum.EDIT_LOCK });
            pr.notiDeskEvent(nevt1);
        } else if (e.cmd === FtClassEventEnum.SETTING) {
            
            
            rzlog.debug('FtDeskUiRepo.onClassEvent !!!! - SETTING :  e=',e,',pr=',pr);
            let nevt1=new FtDeskEvent({ setting:{...e.setting},  cmd:FtDeskEventEnum.SETTING });
            pr.notiDeskEvent(nevt1);
        } else if (e.cmd === FtClassEventEnum.SETTING_ETC) {
            
            
            rzlog.debug('FtDeskUiRepo.onClassEvent !!!! - SETTING_ETC :  e=',e,',pr=',pr);
            let nevt1=new FtDeskEvent({ etcSetting:{...e.etcSetting},  cmd:FtDeskEventEnum.SETTING_ETC });
            pr.notiDeskEvent(nevt1);
        }

    }
    /***** */

    getUserInfo(): FtoUser|undefined {
        let r= this.repoMng?.getUserInfo();
        return r
    }

    setUserInfo(ui: FtoUser | undefined): void {
        this.repoMng?.setUserInfo(ui);
    }

    getEditLock(): FtoEditLock{
        let r= this.repoMng!.getEditLock();
        return r
    }

    getEditLockOther(): FtoEditLock{
        let r= this.repoMng!.getEditLockOther();
        return r
    }

    
    /** */
    isUnloadTest=true
    async getNoteInfos(stat?:string) { 
       if(stat === FtDeskRepo.NOTE_UNLOAD) {
            const isTest=true;
            if(this.isUnloadTest) return NewOk([new FtoNoteInfo({title:"note1", pageNo:1, noteId:"n1", group:'book', type:"book",selected:false})]);

            let r0=await this.repoMng!.getNoteInfos(this.type!,stat);
            return r0;
       }

       let r=await this.repoMng!.getNoteInfos(this.type!);
       rzlog.debug("FtDeskUiRepo.getNoteInfos : this.type=",this.type," r=", r);


       return r;
    }
    
    async getNoteRepo(noteId:string):Promise<RzRes<RzNoteRepo>>{
        let r=await this.repoMng!.getNoteRepo(noteId);

        return r  ;
    }

    async getPages(){ 
       let r= await this.repoMng!.getNoteInfos(this.type!);
       return r;
    }

    async indexOfRepo(noteId:string){
        let r= await this.getNoteInfos();

        let infos=r.data!;
        let ix=-1;

        infos.forEach((t,i)=>{ if(t.noteId===noteId) ix=i});

        return NewOk(ix);
    }

    newNoteInfo(info:FtoNoteInfo){
        return {  noteId:info.noteId,  type:info.type,  title:info.title, subType:'',   pageNo:0, selected:false,  visible:true};
    }

    // async addBookInfo(info:FtoNoteInfo,srcOpt?:FtSrcOpt){
    //     let ninfo=this.newNoteInfo(info)
    //     return await this.repoMng!.addBookInfo(ninfo,srcOpt)
    // }


    async putCurRepoAt(ix:number,srcOpt?:FtSrcOpt){
        let oix=this.curRepoIx>=0?this.curRepoIx:0;
        this.curRepoIx=ix>=0?ix:0;

        let t0 = await this.getNoteInfos();
        if(t0.status>0) return NewFail<void>("no noteInfos");
        
        let infos=t0.data;
        if(!infos || infos.length===0) {
            rzlog.error('FtDeskRepo.putCurRepoAt : no infos - ix=',ix,',srcOpt=',srcOpt);
            return NewFail<void>("no noteInfos");
        }
        rzlog.debug('FtDeskRepo.putCurRepoAt :infos ix=',ix,',oix=,',oix,',infos=',infos);
        let oldPg=infos[oix];
        let newPg=infos[ix];
  
        //let pgNo=newPg.pageNo
        oldPg.selected=false;
        newPg.selected=true;

        await this.repoMng!.putNoteInfoOn(oldPg,newPg,srcOpt);
        
         if(!srcOpt){
            let ev=new FtDeskEvent({cmd:FtDeskEventEnum.PAGES_ON, pages:[oldPg,newPg], srcOpt:srcOpt })  ;
            this.notiDeskEvent(ev);
         }
        return  NewOk<void>();
    }


    async getCurNoteInfo(){   
        let r= await this.repoMng!.getNoteInfos(this.type!);
        if(r.status<0) return NewFail<RzNoteRepo>("no note infos");

        let noteInfos= r.data!;
        return NewOk(noteInfos[this.curRepoIx])    ;
    }

    async getCurPageNo(){
        let r= await this.repoMng!.getNoteInfos(this.type!);
        if(r.status<0) return r;

        let noteInfos =r.data;
        if(!noteInfos || noteInfos.length===0) return NewOk(0);

        let r1=noteInfos[this.curRepoIx]    ;
        if(rzIs.d)rzlog.debug('FtDeskRepo.getCurPageNo !!!: noteInfos=',noteInfos);
        return NewOk(r1.pageNo);
    }
    

    async putCurPageNo(pg:number,srcOpt?:FtSrcOpt){
        let r0=await this.repoMng!.getNoteInfos(this.type!);
        if(r0.status<0) return r0;

        let noteInfos =r0.data!;
        if(!noteInfos || noteInfos.length===0) return ;

        let r=noteInfos[this.curRepoIx]
        if(rzIs.d)rzlog.debug('FtDeskRepo.putCurPageNo : newPg=',pg,'rscinf=',r);
        r.pageNo=pg;
        this.repoMng!.putNoteInfo(r.noteId!,r,srcOpt);


        let r1=await this.getNoteRepo(r.noteId!);
        if(r1.status >0) return r1;
        if(rzIs.d)rzlog.debug('FtDeskRepo.putCurPageNo : noteId=',r.noteId,',repo=',r1.data);
        r1.data!.setCurPageNo(pg);


        let ev=new FtDeskEvent({cmd:FtDeskEventEnum.PAGES_PUT, pages:[r], srcOpt:srcOpt})  ;
        this.notiDeskEvent(ev);
        
    }


    async getCurRepo() {

        let t0 =await this.repoMng!.getNoteInfos(this.type!);
        let noteInfos = t0.data;
        if(rzIs.d)rzlog.debug('FtDeskRepo.getNoteInfos !!!! curRepoIx=',this.curRepoIx,',type =',this.type);
       
        if(!noteInfos || noteInfos.length===0) return NewFail<RzNoteRepo>('no repo');

        let noteInfo=noteInfos[this.curRepoIx];
        if(!noteInfo){
            rzlog.error("no note repoinfo : ix=",this.curRepoIx,',repoinfs=',noteInfos);
            return NewFail<RzNoteRepo>( 'no repo info: ');
        }
        if(rzIs.d)rzlog.debug('FtDeskRepo.getNoteInfos:noteInfos=',noteInfos,',curRepoIx=',this.curRepoIx);

        let r=await this.getNoteRepo(noteInfo!.noteId!);
        if(r.status >0) return r;
        if(rzIs.d)rzlog.debug('FtDeskRepo.getNoteRepo : noteId=',noteInfo.noteId,',repo=',r.data);
        let rp:RzNoteRepo =r.data!;
        rp.setCurPageNo(noteInfo!.pageNo!);
        
        return  r;
    }

    async getNoteInfo(noteId:string,srcOpt?:FtSrcOpt){
        const r=await this.repoMng!.getNoteInfo(noteId);
        if(IsFail(r)) return r;

        const ni=r.data!;

        return NewOk(ni);
    }

 

    /* emit methods */
    async addNoteInfo(noteInf:FtoNoteInfo,srcOpt?:FtSrcOpt): Promise<RzRes<FtoNoteInfo>>{
        let r1= await this.repoMng!.addNoteInfo(noteInf);
        if(IsFail(r1)) return NewFail(r1.message);
        let ninf=r1.data!;
        //@ADD
        let ev=new FtDeskEvent({cmd:FtDeskEventEnum.PAGES_ADD, pages:[ninf], srcOpt:srcOpt})  ;
        this.notiDeskEvent(ev);

        return r1;
    }

    async putNoteInfo(noteId:string,noteInf:FtoNoteInfo,srcOpt?:FtSrcOpt):Promise<RzRes<FtoNoteInfo>>{
        let r1=await this.repoMng!.putNoteInfo(noteId,noteInf);
        if(IsFail(r1)) return NewFail(r1.message);
        //@PUT
        let ev=new FtDeskEvent({cmd:FtDeskEventEnum.PAGES_PUT, pages:[noteInf], srcOpt:srcOpt})  ;
        this.notiDeskEvent(ev);

        return NewOk(r1.data!);
    }


    async delNoteInfo(noteId:string,noteInf:FtoNoteInfo,srcOpt?:FtSrcOpt):Promise<RzRes<FtoNoteInfo>>{
        let r=await this.repoMng!.delNoteInfo(noteId,noteInf);
        return NewOk(r.data!);
    }

    async addNotePage(noteId:string,pgNo:number,srcOpt?:FtSrcOpt):Promise<RzRes<FtoPageInfo>>{
        let r=await this.repoMng!.addPage(noteId,{pageNo:pgNo});
        if(IsFail(r)) return NewFail('FtDeskUiRepo.addNotePage'+r.message);
        return NewOk(r.data!);
    }
 


    /**event */
    addOnDeskEvent(fn:RzOnFn, pr?:any){
        if(pr?.id===5){
            this.listeners=this.listeners.filter(t=> t.param!.id!==3);
        }
        super.addOnEvent(fn,pr);
    }
    delOnDeskEvent(fn:RzOnFn){super.delOnEvent(fn)}
    notiDeskEvent(ev:FtDeskEvent){ super.notiEvent(ev)}
    
}//class



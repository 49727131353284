import { rzlog,RzUiLet,scale } from "../../inc";
 
import { FtLayoutCtx, FtLayoutInit, FtLayoutOpts, FtUiLets } from "../ftclass/ftclass.ui.layout";
import { FtFullLayout } from "./ftclass.ui.layout.full";
 
import { FtSub2SideInit } from "./ftclass.ui.layout.sub2.side";
import { FtSub2ChatInit } from "./ftclass.ui.layout.sub2.chat";
import { FtSub2BookInit } from "./ftclass.ui.layout.sub2.book";
import { FtSub2SplitInit } from "./ftclass.ui.layout.sub2.split";
import { FtSub2NoteInit } from "./ftclass.ui.layout.sub2.note";
import { FT_LAYOUT_AUTOHEIHT } from "../../consts";
 

/** dbg */
const rzIs=rzlog.makeDefs()
export const FtClassUiLayoutSubTop_setDbg =rzIs.setDbg


export interface FtLayoutSubCtx{
    lctx:FtLayoutCtx,
    pp:RzUiLet,
    sbody:RzUiLet,
    bodyCnt:number,
    clientW:number,
    prevWidth:number,     
    isChatFloating:boolean,  
    splitWidth:number, 
}

/**
 * FtSubTopInit
 */
export class FtSubBodyInit implements FtLayoutInit {
    lman:FtFullLayout;

    sideSub2Init?:FtLayoutInit ;
    chatSub2Init?:FtLayoutInit ;
    bookSub2Init?:FtLayoutInit ;
    splitSub2Init?:FtLayoutInit;
    noteSub2Init?:FtLayoutInit ;
    

    constructor(lman:FtFullLayout){
        this.lman=lman;
        this.sideSub2Init=new FtSub2SideInit(lman)
        this.chatSub2Init=new FtSub2ChatInit(lman)
        this.bookSub2Init=new FtSub2BookInit(lman)
        this.splitSub2Init=new FtSub2SplitInit(lman)
        this.noteSub2Init=new FtSub2NoteInit(lman)
       
    }
    
    init(lctx:FtLayoutCtx,pr?:any){
        let lman=this.lman;
        this.doInitSubBody(lctx)
    }

    doInitSubBody(lctx:FtLayoutCtx) {
        let lman=this.lman;

        rzlog.debug("FtClassUiLayoutFull.doInitSubBody : lctx.opt=",lctx.opt);
        
        let lctx2= this.doInitMainBody(lctx)

        this.doInitSide(lctx2)
        this.doInitChat(lctx2)
        this.doInitBook(lctx2)

        this.doInitSplit(lctx2)
        this.doInitNote(lctx2)

        if(rzIs.d) rzlog.debug('FtFullLayout.init : done')

        return true
    }

    doInitMainBody(lctx:FtLayoutCtx):FtLayoutSubCtx{
        let lman=this.lman;
        let pp:RzUiLet=lctx.body;
        let opt:FtLayoutOpts =lctx.opt

        let p=null
        if(lman.bodyLet){
            p=lman.bodyLet
        } else {
           
            let cssText=`display:flex; flex-direction:row; 
                        ${FT_LAYOUT_AUTOHEIHT}
                 ${lctx.fillBottom}`;
            p=new RzUiLet()
            p.setParent(pp)
            p.init({className:'ftfull_body',cssText:cssText})
            lman.bodyLet=p
            lctx.subBody=p;
        }

  
        if(rzIs.d) rzlog.debug('FullLayout.init : lets=',lctx.lets);
        let bodyCnt=0;
        if(opt.isSideOn) bodyCnt++;
        if(opt.isChatOn) bodyCnt++;
        if(opt.isBookOn) bodyCnt++;
        if(opt.isNoteOn) bodyCnt++;

        if(lctx.lets!.side) lman.doRemoveChild(p,lctx.lets!.side);
        if(lctx.lets!.chat) lman.doRemoveChild(p,lctx.lets!.chat);
        if(lctx.lets!.book) lman.doRemoveChild(p,lctx.lets!.book);
        
      

        if(lctx.lets!.bookSplit)lman.doRemoveChild(p,lctx.lets!.bookSplit);
        if(lctx.lets!.note)lman.doRemoveChild(p,lctx.lets!.note);
        
        let prevWidth=0;
        let clientW=opt.width  ||  p.getBounds().width;

        let lctx2:FtLayoutSubCtx={
            lctx:lctx,
            pp:pp,
            sbody:p,
            bodyCnt:bodyCnt,
            clientW:clientW,
            prevWidth:prevWidth,
            isChatFloating:true,
            splitWidth:5,
        };
        return lctx2;
    }

    doInitSide(lctx2:FtLayoutSubCtx){
        this.sideSub2Init?.init(lctx2.lctx,lctx2)
    }
    doInitChat(lctx2:FtLayoutSubCtx){
        this.chatSub2Init?.init(lctx2.lctx,lctx2)
    }

    doInitBook(lctx2:FtLayoutSubCtx){
        this.bookSub2Init?.init(lctx2.lctx,lctx2)
    }


    doInitSplit(lctx2:FtLayoutSubCtx){
        this.splitSub2Init?.init(lctx2.lctx,lctx2)
    }

    doInitNote(lctx2:FtLayoutSubCtx){
        this.noteSub2Init?.init(lctx2.lctx,lctx2)
    }

}//class
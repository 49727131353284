export interface RzLogDef {
  d:boolean;
  t:boolean;
  i:boolean;
  setDbg:(isDbg:boolean, isTrc?:boolean, isInf?:boolean)=> void
}

export interface CustomWindow extends Window {
  setRzLogEnable: (b:boolean)=>void;
  trace:(fmt:string,...args:any[])=>void;
  debug:(fmt:string,...args:any[])=>void;
  info:(fmt:string,...args:any[])=>void;
  error:(fmt:string,...args:any[])=>void;
  
  makeDefs:(isDbg?:boolean, isTrc?:boolean, isInf?:boolean) => RzLogDef
}

declare let window: CustomWindow;

class RzLogDefImp implements RzLogDef{
  d:boolean;
  t:boolean;
  i:boolean;
  setDbg:(isDbg:boolean, isTrc?:boolean, isInf?:boolean)=> void;
  //(isDbg:boolean, isTrc?:boolean, isInf?:boolean)=> void;

  constructor(isDbg:boolean=false, isTrc?:boolean, isInf?:boolean)
  {   this.d=isDbg||false;
      this.t=isTrc||false; 
      this.i=isInf||true; 
      this.setDbg = (isDbg:boolean, isTrc?:boolean, isInf?:boolean)=>{
          this.d=isDbg;
          this.t=isTrc||false;
          this.i=isInf||false;
      }
  }

}

const _initRzLog=(isValid:boolean)=>{
    if(isValid){
      window.debug=console.log.bind(console, 'RZDBG:');
      window.info=console.log.bind(console, 'RZINF:');
      window.trace=console.log.bind(console, 'RZTRC:');
      window.error=console.log.bind(console, 'RZERR:');
      
      window.makeDefs=(isDbg?:boolean, isTrc?:boolean, isInf?:boolean)=>{
          let d:RzLogDef=new RzLogDefImp(isDbg,isTrc,isInf) 
          d.setDbg=(isDbg:boolean=true, isTrc?:boolean, isInf?:boolean)=>{
            d.d=isDbg;
            d.t=isTrc||false;
            d.i=isInf||true;
          }
          return d
        };
    } else {
      window.trace=()=>{};
      window.debug=()=>{};
      window.info=()=>{};
      window.error=console.log.bind(console, 'RZERR:');
      window.makeDefs=(isDbg?:boolean, isTrc?:boolean, isInf?:boolean)=>{
        let d:RzLogDef=new RzLogDefImp(isDbg,isTrc,isInf) 
        d.setDbg=(isDbg:boolean=true, isTrc?:boolean, isInf?:boolean)=>{
          d.i=isDbg;
          d.t=isTrc||false;
          d.i=isInf||true;
        }
        return d
      };      
    }
}

 


let _isInit=false
function getRzLog(){
    if(!_isInit) { _initRzLog(true); _isInit=true}
    window.setRzLogEnable=(b:boolean)=>{
        _initRzLog(b)
    }
    return window
}

const rzlog=getRzLog()

export default rzlog 
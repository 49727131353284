
import { rzlog } from "../../inc"
import { RzBtnLet, RzUiLet, RzUiOpts } from "../../inc"
import EventEmitter from 'events';
import { io } from 'socket.io-client'
import { Device } from 'mediasoup-client';

import { FtTopBtn } from "./ftclass.ui.top";
import { FtoUserTypeEnum } from "../../dto/ftclass.dto";
import { FtPopMenuEnum, FtPopMenuEvent } from "./ftclass.ui.pop";

/************ */
const rzIs=rzlog.makeDefs()
export const FtClassUiSide_setDbg=rzIs.setDbg;

/************ */
const STUDENT = FtoUserTypeEnum.STUDENT
const TEACHER = FtoUserTypeEnum.TEACHER


/*********************************
 *  FtNoteTop
 ********************************/
export class FtClassSide extends RzUiLet {

    signalingClient: SignalingClient;
    msClient: MsClient;
    cousumer: any[] = [];
    teacherVideo?: any;
    studentVideo?: any;
    isVideoPause = false;
    isAudioPause = false;
    roomId?: string;
    user_id?: string;
    type?: string;
    videoCount: number = 2;

    constructor() {
        super();
        this.signalingClient = new SignalingClient();
        this.msClient = new MsClient();
        
        this.signalingClient.onConnect(async () => {
            console.log("YDisa", this.signalingClient.getInfo());
            this.signalingClient.getVideoProducerIds().then(async (ids: string[]) => {

                const other = ids.filter(id => id !== this.signalingClient.getNickName())
                const newC: any[] = [];
                if (this.cousumer.length >= 2) {
                    return;
                }
                other.forEach(id => {
                    const check = this.cousumer.some((cId: any) => cId.nickName === id);
                    if (!check) {
                        newC.push(id)
                    }
                })
                if (newC.length > 0) {
                    this.cousumer = [...this.cousumer, ...newC.map(item => ({ nickName: item, isVideo: !this.isVisible, isAudio: !this.isAudioPause }))]
                    await sleep(1000);
                    console.log("YDISA", this.msClient);
                    
                    const stduentClient = new DeviceClient(this.msClient, this.roomId!, newC[0], this.signalingClient, false);
                    console.log("YDISA", this.msClient);
                    this.studentVideo.setDevice(stduentClient);
                    this.signalingClient.setDevice(FtoUserTypeEnum.STUDENT, stduentClient)
                }
            })
            const data = this.signalingClient.getRtpData();
            //on connect..
            await this.msClient.init(data)
            const producerTransInfo = await this.signalingClient.createTransport(
                {
                    sctpCapabilities: this.msClient.device!.sctpCapabilities,
                    rtpCapabilities: this.msClient.device!.rtpCapabilities,
                    type: "producer",
                    // type:"consumer",
                    forceTcp: false,
                });
            const consumerTransInfo = await this.signalingClient.createTransport(
                {
                    sctpCapabilities: this.msClient.device!.sctpCapabilities,
                    rtpCapabilities: this.msClient.device!.rtpCapabilities,
                    type: "consumer",
                    // type:"consumer",
                    forceTcp: false,
                });
            await this.msClient.createSendTransport(producerTransInfo.params)
            await this.msClient.createRecvTransport(consumerTransInfo.params)

            await sleep(1000);
            const teacherClient = new DeviceClient(this.msClient, this.roomId!, this.user_id!, this.signalingClient, true);
            this.teacherVideo.setDevice(teacherClient)
            this.signalingClient.setDevice("teacher", teacherClient)
        })

        this.signalingClient.onNewProducer(async (e: any) => {
            let { user_id, kind } = e

            if (this.cousumer.length >= 2) {
                return;
            }
            const target = this.cousumer.find(item => item.nickName === user_id);

            if (target === undefined) {
                this.cousumer = [...this.cousumer, { nickName: user_id, isVideo: kind === "video", isAudio: kind === "audio" }]
            } else {
                switch (kind) {
                    case "video":
                        target.isVideo = true
                        break;
                    case "audio":
                        target.isAudio = true
                        break;
                    default:
                        break;
                }
                const oth = this.cousumer.filter(item => item.nickName !== user_id);
                this.cousumer = [...oth, target]
                // //상대방 접속.
                // let video2 = new FtVideoLet()
                // video2.setParent(this)
                // video2.setType('student')
                // video2.init({ cssText: 'width:100%; height:30%; background-color:wheat; display:flex;  align-items:center;justify-content:center;' })
                // await sleep(1000);
                const stduentClient = new DeviceClient(this.msClient, this.roomId!, user_id, this.signalingClient, false);
                this.studentVideo.setDevice(stduentClient)
                this.signalingClient.setDevice(FtoUserTypeEnum.STUDENT, stduentClient)
            }
        })



        this.signalingClient.onMediaClientDisconnect(async (id: string) => {
            this.studentVideo.disconnect();
        })

        this.signalingClient.onMediaPauseEmit((e: any) => {
            let { user_id, kind, pause } = e

            if (kind === 'video') {
                this.studentVideo.videoPause(pause)
            } else if (kind === 'audio') {
                this.studentVideo.audioPause(pause)
            }
        })
    }


    setVideoCount(cnt: number) {
        rzlog.debug("FtClassSide.setVideoCount : cnt=", cnt, ',vidCnt=', this.videoCount)
        if (this.videoCount === 2 && cnt === 1) {
            if (this.studentVideo) {
                this.removeChild(this.studentVideo)
                this.studentVideo = undefined
            }
        } else if (this.videoCount === 1 && cnt === 2) {

            this.doInitVideo2()
        }

        this.videoCount = cnt
    }


    onPopupMenu(e: FtPopMenuEvent) {
        if (!this.isVisible()) return

        if (e.cmd === FtPopMenuEnum.AUDIO) {
            let selected = e.selected
            this.audioPause(selected)
        } else if (e.cmd === FtPopMenuEnum.VIDEO) {
            let selected = e.selected
            this.videoPause(selected);
        } else if (e.cmd === FtPopMenuEnum.LOCK) {
            let selected = e.selected
        }
    }

    doPreOpts(opt: RzUiOpts) {
        // let css=this.isRow ? `display:flex; flex-direction:row;  background-color:333333;`:
        //                 `display:flex; flex-direction:column;  background-color:333333;`
        let css = "";
        return {
            ...opt, className: 'ftnotetop',
            cssText: (opt?.cssText || '') + css
        }
    }

    async view() {
        if (this.signalingClient.isConnected === true) {
            const teacherClient = this.signalingClient.getDevice("teacher") ?? new DeviceClient(this.msClient, this.roomId!, this.user_id!, this.signalingClient, true);
            this.teacherVideo.setDevice(teacherClient)
        }

        const studentDevice = this.signalingClient.getDevice(FtoUserTypeEnum.STUDENT);
        if (studentDevice === null) {
            this.signalingClient.getVideoProducerIds().then(async (ids) => {
                const other = ids.filter((id: string) => id !== this.signalingClient.getNickName());
                if (other.length > 0) {
                    const stduentClient = new DeviceClient(this.msClient, this.roomId!, other[0], this.signalingClient, false);
                    this.studentVideo.setDevice(stduentClient)
                }
            });
        } else {
            this.studentVideo?.setDevice(studentDevice)
        }
    }

    doInit(opt: RzUiOpts) {
        let video1 = new FtVideoLet()
        video1.setParent(this)
        let cssTxt1 = `width:100%; cursor:arrow; background-color:black; 
                    display:flex; align-items:center; justify-content:center; position:relative;`
        video1.init({ cssText: cssTxt1 })
        this.teacherVideo = video1;

        this.doInitVideo2()

        this.view();
        return true
    }

    doInitVideo2() {
        let video2 = new FtVideoLet()
        video2.setParent(this)
        video2.setType(STUDENT)
        //height:30%;
        let cssTxt2 = `width:100%;  background-color:black; display:flex; 
                    align-items:center;justify-content:center; position:relative;`
        video2.init({ cssText: cssTxt2 })
        this.studentVideo = video2;
    }


    audioPause(selected: boolean) {
        this.isAudioPause = selected;
        if (!this.signalingClient.isConnected) {
            return;
        }
        if (selected === true) {
            this.signalingClient.producerPause("audio")
                .then(() => {
                    if (this.type !== FtoUserTypeEnum.STUDENT) {
                        this.teacherVideo.audioPause(selected);
                    } else {
                        this.studentVideo.audioPause(selected)
                    }
                })
        } else {
            this.signalingClient.producerResume("audio")
                .then(() => {
                    if (this.type !== FtoUserTypeEnum.STUDENT) {
                        this.teacherVideo.audioPause(selected);
                    } else {
                        this.studentVideo.audioPause(selected)
                    }
                })
        }

    }

    videoPause(selected: boolean) {
        this.isVideoPause = selected;
        if (!this.signalingClient.isConnected) {
            return;
        }
        if (selected === true) {
            this.signalingClient.producerPause("video")
                .then(_ => {
                    if (this.type !== FtoUserTypeEnum.STUDENT) {
                        this.teacherVideo.videoPause(selected);
                    } else {
                        this.studentVideo.videoPause(selected)
                    }
                })
        } else {
            this.signalingClient.producerResume("video")
                .then(_ => {
                    if (this.type !== FtoUserTypeEnum.STUDENT) {
                        this.teacherVideo.videoPause(selected);
                    } else {
                        this.studentVideo.videoPause(selected)
                    }
                })
        }

    }


    onVisible = (b: boolean) => {
        if(rzIs.d) rzlog.debug('SIDE visible : ~~~!!!!!!!!!!!!!!!!!!!!!!!! : b=', b)
        if (b === true) {
            this.view();
        }
    }
}//class


export class FtVideoLet extends RzUiLet {
    type?: FtoUserTypeEnum
    device?: any
    stream?: any
    isViewOverlay?: boolean = false;
    overlayLayout = new FtVideoOverlay();

    setType(ty: FtoUserTypeEnum) { this.type = ty }

    setDevice(d: any) {
        if (!d) return
        this.device = d

        const d_stream = this.device.getStream();
        if (d_stream === undefined || d_stream === null) {
            this.device.onConnect(async () => {
                this.showVideo();
            })

        } else {
            this.showVideo();
        }
    }

    async showVideo() {
        if (!this.device) return
        const newstream = this.device.getStream()
        this.stream = newstream;
        await sleep(300)
        const v = document.getElementById(this.type !== FtoUserTypeEnum.STUDENT ? "myVideo" : "otherVideo");
        const video: HTMLVideoElement = v! as HTMLVideoElement

        video!.style.display = "block"
        const v1 = document.getElementById(this.type !== FtoUserTypeEnum.STUDENT ? "myLoading" : "otherLoading");

        v1!.style.display = "none";

        video!.srcObject = this.stream;

        this.overlayLayout.setParent(this);
        this.overlayLayout.setType(this.type!)

        this.overlayLayout.init();

    }

    videoPause(selected: boolean) {
        if (selected) {
            const v1 = document.getElementById(this.type !== FtoUserTypeEnum.STUDENT ? "myVideo" : "otherVideo");
            const video: HTMLVideoElement = v1! as HTMLVideoElement
            video.style.display = "none"

            const v2 = document.getElementById(this.type !== FtoUserTypeEnum.STUDENT ? "myLoading" : "otherLoading")
            v2!.style.display = "block";
            this.overlayLayout.setActiveVideo(false)
        } else {
            const v1 = document.getElementById(this.type !== FtoUserTypeEnum.STUDENT ? "myVideo" : "otherVideo");
            const video: HTMLVideoElement = v1! as HTMLVideoElement
            video.style.display = "block"

            const v2 = document.getElementById(this.type !== FtoUserTypeEnum.STUDENT ? "myLoading" : "otherLoading")
            v2!.style.display = "none";
            this.overlayLayout.setActiveVideo(true)
        }
    }

    audioPause(selected: boolean) {
        console.log("YDISA", selected);

        if (selected) {
            this.overlayLayout.setActiveAudio(false);
        } else {
            this.overlayLayout.setActiveAudio(true);
        }
    }
    hasDeco:boolean=true;

    doPreOpts(opt: RzUiOpts) {
        let imgSrc = '/images/human.png'
        let imgSrc2 = imgSrc
        if (this.type ===FtoUserTypeEnum.STUDENT) imgSrc = imgSrc2

        let deco=this.hasDeco?'border-radius: 10px;':''
        let optEx = {
            cssText: opt.cssText + `
                position: relative;
                border: 5px solid black;
                box-sizing: border-box;
                ${deco}
                min-height: 150px;
                `,
            innerHTML: `
                <img   style="max-width:98%; max-height:98%; object-fit: contain;"
                       id="${this.type !== FtoUserTypeEnum.STUDENT ? "myLoading" : "otherLoading"}"
                       src='${imgSrc}'> 
                <video style="display:none;max-width:100%; max-height:100%; transform: ${this.type !== FtoUserTypeEnum.STUDENT ? 'scaleX(-1)' : undefined}"
                       id="${this.type !== FtoUserTypeEnum.STUDENT ? "myVideo" : "otherVideo"}"
                       crossOrigin="anonymous"
                       ${this.type !== FtoUserTypeEnum.STUDENT ? "muted" : ''}
                       autoPlay>
                       </video>
                    `
            // ${this.type !== 'student' ? '<source src="https://dev.wavle.io/media/test.webm" type="video/webm" />':""}
        }
        return { ...opt, ...optEx }
    }

    disconnect() {
        const v1 = document.getElementById(this.type !== FtoUserTypeEnum.STUDENT ? "myVideo" : "otherVideo");
        const video: HTMLVideoElement = v1! as HTMLVideoElement
        video.style.display = "none"

        const v2 = document.getElementById(this.type !== FtoUserTypeEnum.STUDENT ? "myLoading" : "otherLoading")
        v2!.style.display = "block";
    }
}
/*********************************/


function sleep(ms: number) {
    return new Promise((r) => setTimeout(r, ms));
}

const socketPromise = function (socket: any) {
    return function request(type: string, data = {}) {
        return new Promise((resolve) => {
            socket.emit("media", { action: type, data: data }, resolve);
        });
    }
};

let signalInstance: any;
export class SignalingClient {
    opts = {
        // path: '/',
        // transports: ["websocket"],
    };
    serverUrl = 'wss://wss.wavle.center';
    // serverUrl = 'ws://localhost:8086';

    roomId = "";
    nickName = "";
    rtpData = null;
    onEmitter = new EventEmitter();
    OnConnectEmit = "onConnect";
    OnDisConnectEmit = "onDisconnect";
    OnErrorEmit = "onError";
    OnNewProducerEmit = "onNewProducer";
    OnMediaPauseEmit = "onMediaPause";
    OnMediaClientDisconnect = "onMediaClientDisconnect";
    isConnected = false;
    isPause = false;
    isRecoding = false;
    userType?: string;
    devType?: string;

    teacherDevice?: any;
    studentDevice?: any;
    socket?: any;

    constructor() {
        // this.roomId = roomId;
        // this.nickName = nickName;
        // this.socket = io(this.serverUrl, { ...this.opts, query: { session_id: roomId, user_id: nickName } });
        // this.socket.request = socketPromise(this.socket);
        // this.rtpData = null;
        // this.init();
        if (signalInstance) return signalInstance;
        signalInstance = this;
    }

    setInfo(roomId: string, nickName: string, userType: string, devType: string) {
        this.roomId = roomId;
        this.nickName = nickName;
        this.userType = userType;
        this.devType = devType;
    }

    setDevice(type: string, device: any) {
        if (type === FtoUserTypeEnum.STUDENT) {
            this.studentDevice = device
        } else {
            this.teacherDevice = device
        }
    }

    getDevice(type: string) {
        return type === FtoUserTypeEnum.STUDENT ? this.studentDevice : this.teacherDevice;
    }

    getInfo() {
        return {
            roomId: this.roomId,
            nickName: this.nickName,
            userType: this.userType,
            devType: this.devType,
        }
    }


    init() {
        // this.roomId = roomId;
        // this.nickName = nickName;
        this.socket = io(this.serverUrl, { ...this.opts, query: { session_id: this.roomId, user_id: this.nickName } });
        this.socket.request = socketPromise(this.socket);
        this.rtpData = null;
        this.socket.on('connect', async () => {
            console.log("YDisa", "socket connect");

            if (this.isConnected === false) {
                this.rtpData = (await this.socket?.request('getRouterRtpCapabilities'));
                this.onEmitter.emit(this.OnConnectEmit);
                this.isConnected = true;
            }
        });

        this.socket.on('disconnect', () => {
            console.log("YDisa", "socket disconnect");
            this.onEmitter.emit(this.OnDisConnectEmit)
        });

        this.socket.on("mediaClientDisconnect", (e: any) => {
            let { id } = e
            console.log("YDisa", `mediaClientDisconnect : ${id}`);
            this.onEmitter.emit(this.OnMediaClientDisconnect, { id })
        })

        this.socket.on("error", (err: any) => {
            // alert(err.message)
            console.log("YDisa", err.message)
        })

        this.socket.on('connect_error', (error: any) => {
            console.error("YDisa", 'could not connect to %s%s (%s)', this.serverUrl, error.message);
            this.onEmitter.emit(this.OnErrorEmit, error)
        });

        this.socket.on('mediaProduce', async (e: any) => {
            let { user_id, kind } = e
            console.log("YDisa", "socket new mediaProduce");
            // const producerIds = kind === "video" ? await this.getVideoProducerIds() : await this.getAudioProducerIds();
            //들어온 유저의 비디오, 오디오 셋팅호출에 따라 순차적으로 프론트에서 추가를 해야하지만, 현재 구조상 구현하기에 공수가 들어가는 부분이기에
            //잠깐의 sleep으로 대체함.
            await sleep(1000);
            this.onEmitter.emit(this.OnNewProducerEmit, { user_id, kind: kind })
        });

        this.socket.on('mediaPause', async (e: any) => {
            let { user_id, kind, pause } = e
            console.log(user_id);
            this.onEmitter.emit(this.OnMediaPauseEmit, { user_id, kind, pause })
        })
    }

    close() {
        this.roomId = "";
        this.nickName = "";
        this.socket?.close();
    }
    // public async request(type: ActionType, data?: any) {
    //     const response = await this.socket.request(type, data);
    //     return response;
    // }

    async createTransport(data: any) {
        return (await this.socket?.request("createWebRtcTransport", data))
    }

    async transportConnect(data: any) {
        return await this.socket?.request("connectWebRtcTransport", data);
    }

    async produce(data: any) {
        return (await this.socket?.request("produce", data))
    }

    async produceData(data: any) {
        return (await this.socket?.request("produceData", data))
    }

    async resume(data: any) {
        return (await this.socket?.request("producerResume", data))
    }

    async consume(data: any) {
        return (await this.socket?.request("consume", data))
    }

    async getVideoProducerIds() {
        return (await this.socket?.request("getVideoProducerIds"))
    }

    async getAudioProducerIds() {
        return (await this.socket?.request("getAudioProducerIds"))
    }

    async producerPause(kind: any) {
        const result = await this.socket?.request("producerPause", { user_id: this.nickName, kind: kind })
        this.isPause = true
        return result
    }

    async producerResume(kind: any) {
        const result = await this.socket?.request("producerResume", { user_id: this.nickName, kind: kind })
        this.isPause = false;
        return result
    }

    async startRecoding() {
        const result = await this.socket?.request("recodingStart", { user_id: this.nickName })
        this.isRecoding = true;
        return result
    }

    async endRecoding() {
        const result = await this.socket?.request("recodingEnd", { user_id: this.nickName })
        this.isRecoding = false;
        return result
    }

    getRtpData() {
        return this.rtpData
    }
    onConnect(listner: any) {
        return this.onEmitter.addListener(this.OnConnectEmit, listner)
    }

    OnDisConnect(listner: any) {
        return this.onEmitter.addListener(this.OnDisConnectEmit, listner)
    }

    onError(listner: any) {
        return this.onEmitter.addListener(this.OnErrorEmit, listner)
    }

    onNewProducer(listner: any) {
        return this.onEmitter.addListener(this.OnNewProducerEmit, listner)
    }

    onMediaPauseEmit(lisnter: any) {
        return this.onEmitter.addListener(this.OnMediaPauseEmit, lisnter)
    }
    onMediaClientDisconnect(listner: any) {
        return this.onEmitter.addListener(this.OnMediaClientDisconnect, listner)
    }

    getNickName() {
        return this.nickName
    }

    // public broadcastNewProducer() {
    //     this.socke
    // }


}

export class DeviceClient {
    device;
    msDevice;
    signalServer;
    deviceStream: any;
    onEmitter = new EventEmitter();
    OnConnectEmit = "onConnect";
    OnNewProducerEmit = "onNewProducer";
    mediaType;
    roomId;
    nickName;
    isMe;
    constructor(device: any, roomId: string, nickName: string, signalingClient: any, isMe = false) {
        this.device = device.device
        this.msDevice = device;
        this.roomId = roomId;
        this.nickName = nickName;
        this.isMe = isMe;
        this.mediaType = isMe === true ? "producer" : "consumer";
        // this.signalServer = new SignalingClient(roomId, nickName);
        this.signalServer = signalingClient;
        // this.signalServer.onConnect(async () => {
        //     const data = this.signalServer.getRtpData();
        //     this.init(data);
        //     //on connect..
        // })
        // if (this.mediaType === "consumer") {
        //     const data = this.signalServer.getRtpData();
        //     this.init(data);
        // }
        // this.signalServer.onNewProducer(async ({ user_id, kind }) => {
        //     if (this.isMe === true) this.onEmitter.emit(this.OnNewProducerEmit, ids)
        // })
        this.init(device.data)
    }
    // private async init(routerRtpCapabilities: RtpCapabilities) {
    async init(data: any) {
        try{
        if (data) await this.videoInit(data);
        }catch(e){
            rzlog.info("init : e",e)
        }

    }

    async videoInit(data: any) {

        if (!this.msDevice) return

        if (this.mediaType === 'producer') {

            // Create the local representation of our server-side transport.
            // const sendTransport = this.device.createSendTransport(data);

            // Set transport "connect" event handler.
            if (this.msDevice.sendTransport !== null) {

                this.msDevice.sendTransport.on('connect', async (v: any, callback: any, errback: any) => {
                    let { dtlsParameters } = v
                    // Here we must communicate our local parameters to our remote transport.
                    try {
                        await this.signalServer.transportConnect(
                            {
                                transportId: this.msDevice.sendTransport?.id,
                                dtlsParameters,
                                type: this.mediaType,
                            });

                        // Done in the server, tell our transport.
                        callback();
                    }
                    catch (error) {
                        // Something was wrong in server side.
                        errback(error);
                    }
                });

                // Set transport "produce" event handler.
                this.msDevice.sendTransport.on(
                    'produce',
                    async (v: any, callback: any, errback: any) => {
                        let { kind, rtpParameters, appData } = v
                        // Here we must communicate our local parameters to our remote transport.
                        try {
                            const { id } = await this.signalServer.produce(
                                {
                                    transportId: this.msDevice.sendTransport?.id,
                                    kind,
                                    rtpParameters,
                                    appData
                                });

                            // Done in the server, pass the response to our transport.
                            callback({ id });
                        }
                        catch (error) {
                            // Something was wrong in server side.
                            errback(error);
                        }
                    });

                // Set transport "producedata" event handler.
                this.msDevice.sendTransport.on(
                    'producedata',
                    async (pr: any, callback: any, errback: any) => {
                        let { sctpStreamParameters, label, protocol, appData } = pr
                        // Here we must communicate our local parameters to our remote transport.
                        try {
                            // const { id } = await this.signalServer.produceData(
                            //     {
                            //         transportId: sendTransport.id,
                            //         sctpStreamParameters,
                            //         label,
                            //         protocol,
                            //         appData
                            //     });

                            // // Done in the server, pass the response to our transport.
                            // callback({ id });
                        }
                        catch (error) {
                            // Something was wrong in server side.
                            errback(error);
                        }
                    });

                this.msDevice.sendTransport.on('connectionstatechange', (state: string) => {
                    switch (state) {
                        case 'connecting':
                            break;

                        case 'connected':
                            break;

                        case 'failed':
                            this.msDevice.sendTransport?.close();
                            console.log("YDisa", "transport connect failed")
                            break;

                        default: break;
                    }
                });
            }

            // sendTransport.on('connectionstatechange', async (state) => {
            //     console.log(state);
            //     switch (state) {
            //         case 'connecting':
            //             break;

            //         case 'connected':
            //             // document.querySelector('remote_video').srcObject = await stream;
            //             const a = await stream;
            //             console.log(a);

            //             await this.signalServer.resume({});
            //             break;

            //         case 'failed':
            //             sendTransport.close();
            //             break;

            //         default: break;
            //     }
            // });

            // Produce our webcam video.
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            const webcamTrack = stream.getVideoTracks()[0];
            const webcamProducer = await this.msDevice.sendTransport?.produce({ track: webcamTrack });
            const audioTrack = stream.getAudioTracks()[0];
            const audioProducer = await this.msDevice.sendTransport?.produce({ track: audioTrack });

            // await this.ref?.current?.play();
            // const videoTag = document.getElementById(this.isMe ? "myVideo" : "otherVideo");
            // await videoTag.play();
            // const stream = videoTag.captureStream();
            // await sleep(2);

            // const webcamTrack = stream.getVideoTracks()[0];

            // const webcamProducer = await this.msDevice.sendTransport?.produce({ track: webcamTrack });
            // const audioTrack = stream.getAudioTracks()[0];
            // const audioProducer = await this.msDevice.sendTransport?.produce({ track: audioTrack });

            this.deviceStream = stream

        } else {
            // await sleep(2000)
            // const webcamProducer = await sendTransport.consume({ track: webcamTrack });



            this.msDevice.recvTransport?.on('connect', async (v: any, callback: any, errback: any) => {
                let { dtlsParameters } = v;
                try {
                    await this.signalServer.transportConnect(
                        {
                            transportId: this.msDevice.recvTransport?.id,
                            dtlsParameters,
                            type: this.mediaType,
                        });

                    // Done in the server, tell our transport.
                    callback();
                }
                catch (error) {
                    // Something was wrong in server side.
                    errback(error);
                }
            });

            this.msDevice.recvTransport?.on('connectionstatechange', async (state: string) => {
                console.log("video recv state", state);

                switch (state) {
                    case 'connecting':

                        break;

                    case 'connected':
                        this.deviceStream = await stream
                        await this.signalServer.resume({});
                        break;

                    case 'failed':
                        this.msDevice.recvTransport?.close();
                        break;

                    default: break;
                }
            });
            // const stream = consume(transport);
            const consumData = await this.signalServer.consume({ rtpCapabilities: this.device.rtpCapabilities, kind: "video", user_id: this.nickName })
            const consumAudioData = await this.signalServer.consume({ rtpCapabilities: this.device.rtpCapabilities, kind: "audio", user_id: this.nickName })
            let codecOptions = {};
            const stream = new MediaStream();
            const consumer = await this.msDevice.recvTransport?.consume({
                ...consumData,
            });
            const audioConsumer = await this.msDevice.recvTransport?.consume({
                ...consumAudioData
            })
            if (consumer !== undefined) {
                stream.addTrack(consumer?.track);
            }
            if (audioConsumer !== undefined) {
                stream.addTrack(audioConsumer?.track)
            }
            this.deviceStream = stream
        }

        // Produce data (DataChannel).
        // const dataProducer =
        //     await sendTransport.produceData({ ordered: true, label: 'foo' });
        this.onEmitter.emit(this.OnConnectEmit)
    }

    getStream() {
        return this.deviceStream;
    }

    onConnect(listner: any) {
        return this.onEmitter.addListener(this.OnConnectEmit, listner)
    }
    onNewProducer(listner: any) {
        return this.onEmitter.addListener(this.OnNewProducerEmit, listner)
    }
}

let Msinstance: any;

export class MsClient {
    device?: Device;
    data?: any;

    sendTransport?: any;
    recvTransport?: any;

    constructor() {
        if (Msinstance) return Msinstance;
        this.device = new Device();
        Msinstance = this;
    }

    async init(routerRtpCapabilities: any) {
        await this.device!.load({ routerRtpCapabilities });
        if (!this.device?.canProduce('video')) {
            console.warn("cannot product video")
        } else if (!this.device?.canProduce("audio")) {
            console.warn("cannot product audio")
        }
    }

    createSendTransport(data: any) {
        this.data = data;
        this.sendTransport = this.device!.createSendTransport(data);
    }

    createRecvTransport(data: any) {
        this.data = data;
        this.recvTransport = this.device!.createRecvTransport(data);
    }
}

export class FtVideoOverlay extends RzUiLet {
    type?: FtoUserTypeEnum; //FtoUserTypeEnum.STUDENT|TEACHER
    isClicked: boolean = false;
    isBtnFocus?: boolean = false;
    isActiveVideo: boolean = true;
    isActiveAudio: boolean = true;
    videoBtn: FtTopBtn = new FtTopBtn()
    audioBtn: FtTopBtn = new FtTopBtn()

    constructor() {
        super();
        this.onClick = ((e: any) => {
            if (this.isBtnFocus === false) this.setClick(!this.isClicked);
        })
    }

    setType(iT?: FtoUserTypeEnum) { this.type = iT }
    doPreOpts(opt: RzUiOpts) {
        //<div style="position: absolute; transition: 0.5s ease; max-width:100%; max-height:100%; top: 0; background-color: lightblue; opacity: 0;" ></div>
        let optEx = {
            cssText: `
                position: absolute; transition: 0.5s ease; width:100%; height:100%; top: 0; opacity: ${this.isClicked ? 1 : 0};
            `,
        }
        console.log("YDISA", "doPreOpt");
        return { ...opt, ...optEx }
    }
    doInit(pOpts: RzUiOpts): boolean {


        this.videoBtn.setParent(this)
        this.videoBtn.setTitle('C')
        this.videoBtn.setBackColor("#25A8F2")
        this.videoBtn.setIcon(this.isActiveVideo === true ? 'ftnote-cam-icon' : 'ftnote-cam-off-icon')
        this.videoBtn.setSelectColor('#EA4040')
        this.videoBtn.setSelected(false)
        this.videoBtn.setBtnSize(55)
        this.videoBtn.popMenuLet = this
        this.videoBtn.onClick = (e) => {
            //@ts-ignore
            this.parent?.parent.videoPause(this.isActiveVideo)
        }
        this.videoBtn.onFocus = (e, b?: boolean) => {
            this.isBtnFocus = b;
        }
        this.videoBtn.init({ cssText: 'position: absolute; top:65%; left:calc(50% - 70px); padding:0px;margin:0px;' })

        this.audioBtn.setParent(this)
        this.audioBtn.setTitle('M')
        this.audioBtn.setBackColor("#25A8F2")
        this.audioBtn.setIcon(this.isActiveVideo === true ? 'ftnote-mic-icon' : 'ftnote-mic-off-icon')
        this.audioBtn.setSelectColor('#EA4040')
        this.audioBtn.setSelected(false)
        this.audioBtn.setBtnSize(55)
        this.audioBtn.popMenuLet = this
        this.audioBtn.onClick = (e) => {
            //@ts-ignore
            this.parent?.parent.audioPause(this.isActiveAudio)
        }
        this.audioBtn.onFocus = (e, b?: boolean) => {
            this.isBtnFocus = b;
        }
        this.audioBtn.init({ cssText: 'position: absolute; top:65%; right:calc(50% - 70px); padding:0px;margin:0px;' })

        return super.doInit(pOpts)
    }
    setClick(inputClieck: boolean) {
        this.isClicked = inputClieck;
        if (!this.nativeDiv) return
        this.nativeDiv.style.cssText = this.nativeDiv.style.cssText + `opacity:${this.isClicked ? 1 : 0};`
    }

    getClick() {
        return this.isClicked
    }

    setActiveVideo(sav: boolean) {
        this.isActiveVideo = sav;
        this.videoBtn.setIcon(this.isActiveVideo === true ? 'ftnote-cam-icon' : 'ftnote-cam-off-icon');
        this.videoBtn.setSelected(!this.isActiveVideo)
    }
    setActiveAudio(saa: boolean) {
        this.isActiveAudio = saa;
        this.audioBtn.setIcon(this.isActiveAudio === true ? 'ftnote-mic-icon' : 'ftnote-mic-off-icon')
        this.audioBtn.setSelected(!this.isActiveAudio)
    }
}
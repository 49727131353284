import { rzlog,RzUiLet,RzPos,RzRect } from "../../inc";
import { FtClassSubLetFac } from "./ftclass.ui.sublet-fac";
import { FtClassRepoMng } from "../../repo/ftclass.repo-mng";
import { FtDeskLet } from "./ftdesk.ui";
 

/** dbg */


const rzIs=rzlog.makeDefs()
export const FtClassUiLayout_setDbg =rzIs.setDbg

 
/*************** */

export interface FtLayoutOpts {
    isCtrlOn?:boolean
    isMenuOn?:boolean
    isChatOn?:boolean
    isBookOn?:boolean
    isNoteOn?:boolean
    isSideOn?:boolean

    isPopVideoOn?:boolean
    
    
    width?:number
    height?:number
    videoCount?:number
    layoutType?:string
}


 

/** */
export interface FtLayoutEvent {
    width?:number
    height?:number
    isRepaint?: boolean
    target?:any
}

 
export class FtUiLets {
    timePopup?:RzUiLet
    pagePopup?:RzUiLet

    menuBtn?:RzUiLet
    menu?:RzUiLet
    popup?:RzUiLet
    ctrl?:RzUiLet

    leftWidth?:number
    rightWidth?:number
    prevRightWidth?:number
    book?:FtDeskLet
    note?:FtDeskLet

    chat?:RzUiLet
    side?:RzUiLet
    sideSplit?:RzUiLet
    
    video1?:any
    video2?:any
    settingDlg?:RzUiLet
    bookSplit?:RzUiLet
    etcDlg?:RzUiLet

}

export interface FtScrollPos{
    scrollDy:number;
    scrollY0:number;
}

export interface FtLayoutCtx {
    opt:FtLayoutOpts;
    body:RzUiLet;
    subBody:RzUiLet|null;

    repoMng?:FtClassRepoMng;
    
    deskType:string;
    lets:FtUiLets;
    fillBottom:string;
    timePopupPos:RzPos|null;
    pagePopupPos:RzPos|null;

    scrollPos?:FtScrollPos;
    menuBtnPos: RzRect|null;
}


export interface FtLayoutInit {
    init(lctx:FtLayoutCtx,pr?:any):void;
}

export interface FtiLayout {
    layout(e?:FtLayoutEvent):void;
    reinit(layoutType:string, opt:FtLayoutOpts):void;
    doUpdateUi(opt:FtLayoutOpts):void; 
    clearUi():void; 
};

/** FtLayout */
export abstract class FtLayout  {
    parentLet?:RzUiLet 
    lets?:FtUiLets
    subLetFac=new FtClassSubLetFac()
    targetDiv?:HTMLElement
    repoMng?:FtClassRepoMng
    layoutOpts:FtLayoutOpts={
                    isCtrlOn:true,
                    isSideOn:true,
                    isChatOn:true,
                    isBookOn:true,
                    isNoteOn:true,
                    isMenuOn:false,  //popMenu
                    isPopVideoOn:false, //popVideo
                }
                
    getLayoutOpts(){
        return this.layoutOpts
    }

    init(targetDiv:HTMLElement,opts:FtLayoutOpts){
       if((targetDiv as any)._ftMounted) return false;
       
       (targetDiv as any)._ftMounted=true
       this.targetDiv=targetDiv

       if(opts)this.layoutOpts={...this.layoutOpts,...opts}
       return this.doInit(opts)
    }

    setSubLetFac(fac:FtClassSubLetFac){this.subLetFac=fac}

    setLayoutOpts(opts:FtLayoutOpts){
        if(this.layoutOpts){
            this.layoutOpts={...this.layoutOpts, ...opts}
        } else  this.layoutOpts={ ...opts}
    }

    setRepoMng(rpMng:FtClassRepoMng){
        this.repoMng=rpMng;
    }

    getUiLets(){ return this.lets }
    setUiLets(lets:FtUiLets){this.lets=lets}

    //e : FtLayoutEvent
    layout(e?:FtLayoutEvent){
        if(e&& this.layoutOpts){
            if(e.width) this.layoutOpts.width=e.width
        }
        this.doUpdateUi(this.layoutOpts!)
    }

    abstract doInit(opt:FtLayoutOpts):boolean;
    abstract reinit(layoutType:string, opt:FtLayoutOpts):void;
    abstract doUpdateUi(opt:FtLayoutOpts):void; 
    abstract clearUi():void; 
}



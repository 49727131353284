import { FT_LAYER_EDIT, FT_LAYER_POP } from "../../consts";
import { FtoEtcSetting, FtoSetting,FtoUser, FtoUserTypeEnum } from "../../dto/ftclass.dto";
import { rzlog,RzUiLet,RzSplitLet,RzResizeLet,RzUiOpts,RzPos, RzUiGetSrc,RzDlg ,rzGet,rzPut} from "../../inc";

/** dbg */
const rzIs=rzlog.makeDefs()
export const FtClassUiLayoutDlg_setDbg =rzIs.setDbg


/*************** */

export interface FtSettingRepo {
    getSetting(): FtoSetting|undefined;
    setSetting(stt:FtoSetting):void;
}
/*************** */

export class FtPopSettingDlg extends RzUiLet {
    btnPos?:RzPos;
    repo?:FtSettingRepo;
    userInfo?:FtoUser;

    setRepo(repo:FtSettingRepo){
        this.repo=repo
    }

    setUserInfo(ui:FtoUser){
        this.userInfo=ui
    }

    doGetUType(){
        return this.userInfo?.userType? this.userInfo?.userType:FtoUserTypeEnum.OBSERVER
    }

    updateUi(){
        let utype=this.doGetUType()
        rzlog.debug("FtClassLayoutDlg.updateUi : utype=",utype,", repo=",this.repo)

        let setting=this.repo!.getSetting()
        if(!setting) return;

  

        if(!this.isTeacherNoteAllowedEl){
            rzlog.debug("FtClassLayoutDlg.updateUi : INIT > utype=",utype,", sett=",setting)
            if(utype==='teacher'){
                this.isTeacherNoteAllowedEl=_initCheckLet(this,'isTeacherNoteAllowed','setting',this.repo!,(st)=>{this.repo!.setSetting(st)})
                this.isAnswerNoteAllowedEl=_initCheckLet(this,'isAnswerNoteAllowed','setting',this.repo!,(st)=>{this.repo!.setSetting(st)})
                this.isParentsAllowedEl=_initCheckLet(this,'isParentsAllowed','setting',this.repo!,(st)=>{this.repo!.setSetting(st)})
                this.isParentsChatAllowedEl=_initCheckLet(this,'isParentsChatAllowed','setting',this.repo!,(st)=>{this.repo!.setSetting(st)})
            } else {
                this.isTeacherNoteAllowedEl=_initCheckLet(this,'isTeacherNoteAllowed','setting',this.repo!,(st)=>{this.repo!.setSetting(st)})
                this.isAnswerNoteAllowedEl=_initCheckLet(this,'isAnswerNoteAllowed','setting',this.repo!,(st)=>{this.repo!.setSetting(st)})
            }
    
        } else {
            rzlog.debug("FtClassLayoutDlg.updateUi : PUT > utype=",utype,", sett=",setting)
            if(utype==='teacher'){
                //@RMV
                this.isTeacherNoteAllowedEl!.checked=setting.isTeacherNoteAllowed;
                this.isAnswerNoteAllowedEl!.checked=setting.isAnswerNoteAllowed;
                this.isParentsAllowedEl!.checked=setting.isParentsAllowed;
                this.isParentsChatAllowedEl!.checked=setting.isParentsChatAllowed;
            }else {
                //@RMV
                this.isTeacherNoteAllowedEl!.checked=setting.isTeacherNoteAllowed;
                this.isAnswerNoteAllowedEl!.checked=setting.isAnswerNoteAllowed;
            }
        }

    }

    doGetHtml(){

        let utype=this.doGetUType()
        let htm2=`<div style="display:flex; flex-direction:column;">
                    <div style="margin-top: 20px; display:flex; flex-direction:row; justify-content: space-between;">
                        <span class='ftnote_popmenu_item'>답안지 보이기</span>
                        <label class="switch"> <input id="${this.id}.isAnswerNoteAllowed" type="checkbox" > <span class="slider round"></span> </label>
                    </div>
                    <div style="margin-top: 20px;display:flex; flex-direction:row; justify-content: space-between;">
                        <span class='ftnote_popmenu_item'>선생님 노트 보이기</span>
                        <label class="switch"> <input id="${this.id}.isTeacherNoteAllowed" type="checkbox"> <span class="slider round"></span> </label>
                    </div>
                </div>`
        let html=`<div style="display:flex; flex-direction:column;">
                    <div style=" display:flex; flex-direction:row; justify-content: space-between;">
                        <span class='ftnote_popmenu_item'>선생님 노트보기 허용</span>
                        <label class="switch"> <input id=${this.id+".isTeacherNoteAllowed"} type="checkbox"> <span class="slider round"></span> </label>
                    </div>
                    <div style="margin-top: 20px; display:flex; flex-direction:row; justify-content: space-between;">
                        <span class='ftnote_popmenu_item'>답안지 보기 허용</span>
                        <label class="switch"> <input id=${this.id+".isAnswerNoteAllowed"} type="checkbox"> <span class="slider round"></span> </label>
                    </div>
                    <div style="margin-top: 20px;display:flex; flex-direction:row; justify-content: space-between;">
                        <span class='ftnote_popmenu_item'>학부모 참관 허용</span>
                        <label class="switch"> <input id=${this.id+".isParentsAllowed"} type="checkbox"> <span class="slider round"></span> </label>
                    </div>
                    <div style="margin-top: 20px;display:flex; flex-direction:row; justify-content: space-between;">
                        <span class='ftnote_popmenu_item'>학부모 채팅 허용</span>
                        <label class="switch"> <input id=${this.id+".isParentsChatAllowed"} type="checkbox"> <span class="slider round"></span> </label>
                    </div>
            </div>`

        if(utype==='student'){
            html=htm2
        }

        return html

    }

    doPreOpts(opts: RzUiOpts): RzUiOpts {
        let w=300
        let h=221
        let ppos=this.btnPos!;
        let pos={x:ppos.x -(w/2), y:ppos.y+20,width:w,height:h}
        let html=this.doGetHtml()
        return {
            className:'ftclass_menu_subpopup',
            cssText:`position:absolute; 
                top:${pos.y}px; left:${pos.x}px; width:${pos.width}px;  
                z-index:${FT_LAYER_POP};  `,
            innerHTML:html
        }
    }
    
    isTeacherNoteAllowedEl?:HTMLInputElement
    isAnswerNoteAllowedEl?:HTMLInputElement
    isParentsAllowedEl?:HTMLInputElement
    isParentsChatAllowedEl?:HTMLInputElement
    doInit(opts: RzUiOpts): boolean {
        let setting=this.repo!.getSetting()
        if(!setting) return true;

        this.updateUi()

        return true;
    }
}//

/***************
 * 
 */
 export interface FtEtcSettingRepo {
    getEtcSetting(): FtoEtcSetting|undefined;
    setEtcSetting(stt:FtoEtcSetting):void;
}

/*********** */
export class FtPopEtcDlg extends RzUiLet {

    btnPos?:RzPos;
    userInfo?:FtoUser;
    repo?:FtEtcSettingRepo;

    setRepo(repo:FtEtcSettingRepo){
        this.repo=repo
    }
    setUserInfo(ui:FtoUser){
        this.userInfo=ui
    }
    doGetHtml(){
        let utype=(this.userInfo?.userType)?this.userInfo?.userType:'observer'

        let html2=`<div style="display:flex; flex-direction:column;">
                    <div style="margin-top: 20px;display:flex; flex-direction:row; justify-content: space-between;">
                        <span class='ftnote_popmenu_item'>내 카메라 보이기</span>
                        <label class="switch"> <input id="${this.id}.isCameraShown" type="checkbox"> <span class="slider round"></span> </label>
                    </div>
                    <div style="margin-top: 20px;display:flex; flex-direction:row; justify-content: space-between;">
                        <span class='ftnote_popmenu_item'>선생님 카메라 보이기</span>
                        <label class="switch"> <input id="${this.id}.isCameraTeacherShown" type="checkbox"> <span class="slider round"></span> </label>
                    </div>
                    <div style="margin-top: 20px;display:flex; flex-direction:row; justify-content: space-between;">
                        <span class='ftnote_popmenu_item'>팬툴 켜기</span>
                        <label class="switch"> <input id="${this.id}.isPenToolShown" type="checkbox"> <span class="slider round"></span> </label>
                    </div>                    
            </div>`

            let html=`<div style="display:flex; flex-direction:column;">
                            <div style="margin-top: 20px;display:flex; flex-direction:row; justify-content: space-between;">
                                <span class='ftnote_popmenu_item'>교재보이기</span>
                                <label class="switch"> <input id="${this.id}.isTeachingBookShown" type="checkbox"> <span class="slider round"></span> </label>
                            </div>
                            <div style="margin-top: 20px;display:flex; flex-direction:row; justify-content: space-between;">
                                <span class='ftnote_popmenu_item'>답안지 보이기</span>
                                <label class="switch"> <input id="${this.id}.isAnswerBookShown" type="checkbox"> <span class="slider round"></span> </label>
                            </div>
                            <div style="margin-top: 20px;display:flex; flex-direction:row; justify-content: space-between;">
                                <span class='ftnote_popmenu_item'>선생님 노트 보이기</span>
                                <label class="switch"> <input id="${this.id}.isTeacherNoteShown" type="checkbox"> <span class="slider round"></span> </label>
                            </div>                    
                            <div style="margin-top: 20px;display:flex; flex-direction:row; justify-content: space-between;">
                                <span class='ftnote_popmenu_item'>전체 카메라 보이기</span>
                                <label class="switch"> <input id="${this.id}.isCameraAllShown" type="checkbox"> <span class="slider round"></span> </label>
                            </div>                    
                    </div>`
                    
            if(utype==='student'){
                html=html2
            }

            return html
    }

    doPreOpts(opts: RzUiOpts): RzUiOpts {

        let w=300
        let h=385
        let ppos=this.btnPos!;
        let pos={x:ppos.x -(w*2/3), y:ppos.y+20,width:w,height:h}
        let html=this.doGetHtml()
 
        return {
            className:'ftclass_menu_subpopup',
            cssText:`position:absolute; top:${pos.y}px; left:${pos.x}px; width:${pos.width}px;  z-index:19;  `,
            innerHTML: html
        }
    }//init

    isPenToolShownEl?:HTMLInputElement
    isTeachingBookShownEl?:HTMLInputElement
    isAnswerBookShownEl?:HTMLInputElement
    isTeacherNoteShownEl?:HTMLInputElement

    isCameraAllShownEl?:HTMLInputElement
    isCameraShownEl?:HTMLInputElement
    isCameraTeacherShownEl?:HTMLInputElement

    doInit(opts: RzUiOpts): boolean {

        let setting=this.repo!.getEtcSetting();
        if(!setting) return true;

        this.updateUi()

        return true;
    } 

    doGetUType(){
        return (this.userInfo?.userType) ? this.userInfo.userType:FtoUserTypeEnum.OBSERVER;
    }
    updateUi(){
        let etcSetting=this.repo!.getEtcSetting()
        if(!etcSetting) return;
        let utype=this.doGetUType()
        if(!this.isPenToolShownEl){
            if(utype===FtoUserTypeEnum.STUDENT){
                this.isPenToolShownEl=_initCheckLet(this,'isPenToolShown','etcSetting',this.repo!,(st)=>{
                    rzlog.debug('SetEtcSetting(isPenToolShown):st=',st,',repo=',this.repo);
                    this.repo!.setEtcSetting(st);
                }, true)
        
                this.isCameraShownEl=_initCheckLet(this,'isCameraShown','etcSetting',this.repo!,(st)=>{
                    rzlog.debug('SetEtcSetting(isCameraShown):st=',st,',repo=',this.repo);
                    this.repo!.setEtcSetting(st)},true)
                this.isCameraTeacherShownEl=_initCheckLet(this,'isCameraTeacherShown','etcSetting',this.repo!,(st)=>{this.repo!.setEtcSetting(st)},true)
    
            } else {
                // this.isPenToolShownEl=_initCheckLet(this,'isPenToolShown',setting,(st)=>{this.repo!.setEtcSetting(st)},this.repo!,true)
                this.isTeachingBookShownEl=_initCheckLet(this,'isTeachingBookShown','etcSetting',this.repo!,(st)=>{this.repo!.setEtcSetting(st)},true)
                this.isAnswerBookShownEl=_initCheckLet(this,'isAnswerBookShown','etcSetting',this.repo!,(st)=>{this.repo!.setEtcSetting(st)},true)
                this.isTeacherNoteShownEl=_initCheckLet(this,'isTeacherNoteShown','etcSetting',this.repo!,(st)=>{this.repo!.setEtcSetting(st)},true)
        
                this.isCameraAllShownEl=_initCheckLet(this,'isCameraAllShown','etcSetting',this.repo!,(st)=>{this.repo!.setEtcSetting(st)},true)
                // this.isCameraShownEl=_initCheckLet(this,'isCameraShown',setting,(st)=>{this.repo!.setEtcSetting(st)},this.repo!,true)
                // this.isCameraTeacherShownEl=_initCheckLet(this,'isCameraTeacherShown',setting,(st)=>{this.repo!.setEtcSetting(st)},this.repo!,true)
            }
    
        } else {
            if(utype==='student'){
                this.isPenToolShownEl!.checked=etcSetting.isPenToolShown;
                this.isCameraShownEl!.checked=etcSetting.isCameraShown;
                this.isCameraTeacherShownEl!.checked=etcSetting.isCameraTeacherShown;

            } else {
                // this.isPenToolShownEl!.checked=setting.isPenToolShown;
                this.isTeachingBookShownEl!.checked=etcSetting.isTeachingBookShown;
                this.isAnswerBookShownEl!.checked=etcSetting.isAnswerBookShown;
                this.isTeacherNoteShownEl!.checked=etcSetting.isTeacherNoteShown;
        
                this.isCameraAllShownEl!.checked=etcSetting.isCameraAllShown;
                // this.isCameraShownEl!.checked=setting.isCameraShown;
                // this.isCameraTeacherShownEl!.checked=setting.isCameraTeacherShown;
    
            }
    
        }
    }
   
}//class


function _initCheckLet(pctrl : RzUiLet,ctrlId:string,
    type:string,repo:FtSettingRepo|FtEtcSettingRepo,  setSetting:(st:any)=>void, isEtc?:boolean){
    

    let chk=pctrl.getElementById(ctrlId) as HTMLInputElement
    //if(!chk) return undefined;
    let setting =(type==='setting')?(repo as FtSettingRepo).getSetting()
            :(repo as FtEtcSettingRepo).getEtcSetting();

    chk.checked= rzGet(setting,ctrlId)
    chk.onchange=async (e)=>{
        let osett=(type==='setting')?(repo as FtSettingRepo ).getSetting() 
            : (repo as FtEtcSettingRepo).getEtcSetting()
        const v=rzGet(osett,ctrlId)
        let nst={...osett}
        let src=RzUiGetSrc(e)
        let el=src.getElementById(ctrlId);
        let chked=el.checked

        rzPut(nst,ctrlId,chked)
        //let tsetting= (isEtc)? repo.getEtcSetting(): repo.getSetting()      ;   
        //nst[ctrlId]= ! setting[ctrlId];
        rzlog.debug('Setting(etc) : onchange = ',ctrlId,',chk=',chked,',nst=',osett,',org=',osett)
        setSetting(nst);
        
        src.updateUi();
        //setTimeout(()=>{},300)

        //this.repo!.setEtcSetting(setting);
    }

    rzPut(pctrl,ctrlId+'El',chk)    
    return chk
}
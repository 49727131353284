import { CmComponent, RzBtn, RzCol, RzImg, RzRow, RzTxt } from "./rzcomp";
import { CSSProperties, Component, ReactNode, createRef } from "react";
import { IsFail, rzToDateFormat, rzlog } from "../../inc";
import { RzChatMsg } from "../ftclass/ftclass.ui.chat";
import { FtoClassInfo, FtoUserTypeEnum } from "../../dto/ftclass.dto";
import { FT_LAYOUT2_PORTRAIT_FLOAT } from "../../consts";




let defChats = [
    { senderName: '나', body: "이름1...\n jskljlkd", dateAt: new Date() },
    { senderName: '나', body: "이름2...\n jskljlkd", dateAt: new Date() },
    { senderName: '상대1', body: "이름3...\n jskljlkd", dateAt: new Date() },
    { senderName: '나', body: "이름4...\n jskljlkd", dateAt: new Date() },
    { senderName: '상대1', body: "이름5...\n jskljlkd", dateAt: new Date() },
]
let isDefOn = false;

/**************************
 * 
 */
export class RzoChat {
    senderName?: string;
    receiverName?: string;
    body?: string;
    dateAt?: Date;
};

interface FtChatProp {
    style?: CSSProperties;
    isDlgOn?: boolean;
    isVertical?: boolean;
    onToggle?: (dlgOn: boolean) => void;
}

interface FtChatStat {
    chats: RzoChat[];
    text?: string;
    ownerName?: string;
    isParentJoinOn?: boolean;
    isParentChatOn?: boolean;
    isMount?: boolean;
}

export class FtChatFlat extends CmComponent<FtChatProp, FtChatStat> {

    constructor(pr?: any) {
        super(pr);
        this.state = {
            chats: isDefOn ? defChats : [], ownerName: '나',
            isMount: true,
        };
    }


    getClientRect(): DOMRect | undefined {
        return this.colRef.current?.getClientRect();
    }

    colRef = createRef<RzCol>();

    componentDidMount(): void {
        // window.addEventListener('mousedown',(e)=>{})    
        // window.addEventListener('mouseleave',(e)=>{})   
        // window.addEventListener('mousedown',(e)=>{})             
        let ctx = this.getCtx();        

        if (ctx) {
            let repo = ctx.repoMng?.getChatRepo() as any;            
            if (repo) {
                let cu = repo?.clientRef?.client?.username;
                // const ui = repoMng?.getUserInfo();
                if (cu) {
                    this.setState({ ownerName: cu, isParentJoinOn: ctx.isParentJoinOn });
                } else {
                    this.setState({ ownerName: ctx.userInfo?.username, isParentJoinOn: ctx.isParentJoinOn });
                }
            } else {
                this.setState({ ownerName: ctx.userInfo?.username, isParentJoinOn: ctx.isParentJoinOn });
            }
        }

        this.doInitChatRepo();

    }

    doGetChatRepo() {
        let repoMng = this.getRepoMng();
        if (!repoMng) { return null; }

        let chatRepo = repoMng.getChatRepo();
        return chatRepo || null;
    }
    async doInitChatRepo() {
        let chatRepo = this.doGetChatRepo();
        if (!chatRepo) return;

        /** 23.11.29 기존 채팅(임시) */
        if (chatRepo.messages) {
            let msgs = chatRepo.messages.map(t => this.toChatVo(t));
            this.setState({ chats: [...this.state.chats, ...msgs] });
        }   

        /** 채팅 전송시 사용 */
        chatRepo.addOnChatEvent((e) => {
            
            let chatRepo1 = this.doGetChatRepo();
            if (!chatRepo1) return;
            if (!e.messages) return;
            
            let msgs = e.messages.map(t => this.toChatVo(t));
            this.setState({ chats: [...this.state.chats, ...msgs] });

            this.doGoScrollEnd();
        }, this);

        /** 1.15일 주석처리(처음 채팅 입력시 가비지 데이터 나옴) */
        // let r = await chatRepo.getItmes();
        
        // if (IsFail(r)) return;
        // if (r.data) this.setState({ chats: r.data });
    }

    toChatVo(msg: RzChatMsg) {
        let m = {
            senderName: msg.fromName,
            receiverName: msg.to,
            body: msg.body,
            dateAt: msg.createdAt,
        } as RzoChat;
        return m;
    }

    isFirstScrollOn = false;
    componentDidUpdate() {
        if (!this.isFirstScrollOn && this.chatListRef.current) {
            this.doGoScrollEnd();
            this.isFirstScrollOn = true;
        }

        if (this.chatListRef.current) this.chatListRef.current.scrollEnd();

        let ctx = this.getCtx();

        if (ctx.isParentJoinOn !== this.state.isParentJoinOn) {
            //alert('parintJoinOn');
            this.setState({ isParentJoinOn: ctx.isParentJoinOn });
        }
    }


    doGoScrollEnd() {
        if (this.chatListRef.current) this.chatListRef.current.scrollEnd();
    }


    dlgWidth = 340;
    // dlgWidth = 358;

    render() {
        let txt = this.state.text || '';
        let chats = this.state.chats;
        let parentJoinOn = this.state.isParentJoinOn;

        let ctx = this.getCtx();
        if (ctx.isParentJoinOn) parentJoinOn = true;
        if (!parentJoinOn && ctx.camCount !== undefined && ctx.camCount > 2) parentJoinOn = true;
        parentJoinOn = true;
        let camCnt = ctx.camCount;

        let isTeacher = ctx.userInfo?.userType == FtoUserTypeEnum.TEACHER;
        let isParent = ctx.userInfo?.userType == FtoUserTypeEnum.OBSERVER;
        if (isParent) parentJoinOn = false;

        let parentChatOn = ctx.isParentNotiOn;
        //let parentChatOn=this.state.isParentChatOn;

        let isChatOn = true;
        if (isParent) isChatOn = parentChatOn || false;

        let isDlgOn = this.props.isDlgOn || false;
        let isDlgOn2 = this.props.isDlgOn || false;
        let st = this.props.style;

        let layoutType = ctx.layoutType;
        let isNotesOff = ctx.setting?.isNotesOff || false;

        if(layoutType === "float2"){
            isDlgOn = true;
        }
        
        let isVertical = this.props.isVertical || false;

        let btnSt = { border: '2px solid white', marginTop: 2, justifyContent: 'center', borderRadius: 13, width: 120, height: 22, marginRight: 20 }
        let wst = { width: '100%' } as CSSProperties;
        if (isDlgOn) wst = { width: this.dlgWidth };
        let styleProp={}
        if(this.props.style) styleProp=this.props.style;
        return (<RzCol ref={this.colRef} style={{ height: '100%',...styleProp }}  className="ft2chatMB">
            <RzRow style={{ ...wst, height: 30, justifyContent: 'space-between', backgroundColor: '#037DED' }}  className="ft2chatMT">
                <RzCol className="ft2chatMT1" > <RzTxt style={{ marginLeft: 24, marginTop: 6, fontFamily: 'Pretendard', fontSize: 15, fontWeight:600 }} text="채팅" /></RzCol>

                <RzRow style={{ height: 30 }} className="ft2chatMTp">
                    {(parentJoinOn&&!isVertical) && (<RzBtn title={(parentChatOn ? "학부모 채팅" : "학부모 채팅 불가")}
                        style={btnSt}
                        textStyle={{ fontSize: 13, fontWeight: 400, marginTop: 3 }}
                        onClick={() => {
                            if (isTeacher) this.doToggleParentChat()
                        }}
                    />)}
                    <RzCol style={{ width: 25, height: 25, borderRadius: 3, marginTop: 2, marginRight: 6, backgroundColor: 'black' }}
                        onClick={() => { this.doFloat() }} className="ft2chatMTBtn">
                        <span className={isDlgOn2 ? "ftclass2-ic-minimize" : "ftclass2-ic-maximize"} style={{ marginLeft: 5, marginRight: 4, marginTop: 5 }} />
                    </RzCol>
                </RzRow>

            </RzRow>
            {(!isDlgOn && !isVertical ) && (
                <RzRow style={{ flex: '1 0 auto' }}  className="ft2chatM">
                    {this.doDrawChatFlat(chats, isDlgOn, isNotesOff,isVertical)}
                    {this.doDrawTxtInFlat(txt, isDlgOn, isChatOn,isVertical)}
                </RzRow>
            )}
            {(!isDlgOn && isVertical ) && (
                <RzCol style={{width:'100%',flex: '0 1 auto' , flexGrow:1}}>
                    {this.doDrawChatFlat(chats, isDlgOn, isNotesOff,isVertical)}
                    {this.doDrawTxtInFlat(txt, isDlgOn, isChatOn,isVertical)}
                </RzCol>
            )}
            {isDlgOn && (
                <RzCol style={{ flex: '1 0 auto', width: 340 }}>
                    {this.doDrawChatFlat(chats, isDlgOn, isNotesOff,true)}
                    {this.doDrawTxtInFlat(txt, isDlgOn, isChatOn,true)}
                </RzCol>
            )}
        </RzCol>);
    }

    chatListRef = createRef<RzCol>();
    doDrawChatFlat(chats: RzoChat[], isDlgOn: boolean, isNotesOff:boolean, isVertical:boolean) {        
        let ctx = this.getCtx();        
        console.log('doDrawChatFlat==================>', chats)
        //let st = { width: '60%', height: 'calc(100vh - 796px)', } as CSSProperties;
        let st = { width: '60%', height: 234, } as CSSProperties;
        // if (isDlgOn) st = { width: this.dlgWidth, height: 265 };

        if(isVertical){
            st = { width: '100%', height: '90%', } as CSSProperties;
        }

        if (isNotesOff) st = { width: 435, height: 234 };
        if (isDlgOn) st = { width: this.dlgWidth, height: 238 };        
        
        if (ctx.layoutType === FT_LAYOUT2_PORTRAIT_FLOAT) {
            st = { width: 'auto', height: 234, flexGrow: 1 };// 세로모드일때
        }
        // if (isNotesOff) st = { width: 435, height: 234 };

        return (<RzCol ref={this.chatListRef} style={{ ...st, backgroundColor: '#000000' }} className="ft2chatList">
            {chats && chats.map((t, i) => this.doDrawChat(t, i))}
        </RzCol>
        );
    }

    doDrawTxtInFlat(txt: string, isDlgOn: boolean, isChatOn: boolean,isVertical:boolean) {
        let ctx = this.getCtx();

        let st = { width: '40%', height: '100%', boxSizing: 'border-box' } as CSSProperties;
        let inH = 189;

        if(isVertical) {
            st = { width: '100%', height: inH, boxSizing: 'border-box' } as CSSProperties;
        }

        if (isDlgOn) {
            //st = { width: 342, height: 127 };
            st = { width: 340, height: 135 };
            inH = 195;
        }

        if (ctx.layoutType === FT_LAYOUT2_PORTRAIT_FLOAT) {
            st = { width: 317, height: '100%' };
            if (isDlgOn) {
                st = { width: 340, height: 135 };
                inH = 195;
            }
        }// 세로모드일때

        /**
                      <input type="text" onChange={(e)=>{ this.setState({text:e.target.value})}}  value={txt}  disabled={false} />
         */
        return (
            <RzCol key={'chatInFlat'} style={{ ...st, backgroundColor: '#383838', paddingTop: 4, paddingRight: 2, paddingLeft: 2, boxSizing:'border-box' }} className="ft2chatTBack">
                <RzCol style={{backgroundColor: 'white', height: inH, borderRadius: 4, paddingRight: 5, overflow: 'hidden' }} className="ft2chatTextara">
                    <textarea style={{imeMode:'active', fontWeight:400, fontFamily: 'Spoqa Han Sans Neo', fontSize:12, height: '100%', width: '100%', resize:'none', border:'none', paddingLeft:10, paddingTop:11, boxSizing: 'border-box'}} placeholder="메세지 입력하기"
                        onKeyDown={(e) => {
                            if (!e.shiftKey && e.key == 'Enter') {
                                e.preventDefault();
                                /** 한글 입력 시 중복 이벤트 발생 방지 */
                                if (e.nativeEvent.isComposing) return
                                this.doSendMsg();
                            }
                            if (e.shiftKey && e.key == 'Enter') {
                                e.preventDefault();
                                if (e.nativeEvent.isComposing) return
                                this.setState({ text: this.state.text + '\n' });
                            }
                        }}
                        disabled={!isChatOn}
                        rows={5} cols={33} value={txt}
                        onChange={(e) => {
                            //alert('e='+e.target.value);
                            // rzlog.debug("text=",e.target.value);
                            this.setState({ text: e.target.value });
                        }}
                    >{txt}</textarea>
                </RzCol>
                <RzCol style={{ alignItems: 'flex-end', marginRight: 4, paddingTop: 0, paddingBottom: (isDlgOn) ? 1 : 0 }} className="ft2ChatGo">
                    <RzBtn onClick={() => { this.doSendMsg(); }} style={{ paddingTop: 10, paddingBottom: 8 }} textStyle={{ color: '#5FB2FF', fontSize: 12 }} title={"보내기"} />
                </RzCol>
            </RzCol>);
    }

    doDrawChat(t: RzoChat, i: number) {
        let ctx = this.getCtx();
        // ctx.userInfo?.userType

        let name = t.senderName || '나';

        let txtSt = {}
        if (name == this.state.ownerName) {
            txtSt = { color: '#5BACF8', fontSize: 12, fontWeight:700 };
            name = '나';
        } else {
            if (ctx.userInfo?.userType === 'student') {
                name = '선생님';
            } else if (ctx.userInfo?.userType === 'teacher') {
                name = '학생';
            }
            txtSt = { color: '#CBCBCB', fontSize: 12, fontWeight:700 };
        }

        let body = t.body;
        let dt = '오후 10:22';

        dt = rzToDateFormat('pm H:i', t.dateAt);

        return (<RzCol key={'ftChatLog-' + i} style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 10 }}>
            <RzCol><RzTxt key={'ftChatLogNm-' + i} textStyle={txtSt} text={name} /></RzCol>
            <RzRow style={{ justifyContent: 'space-between', alignItems: 'flex-end' }}>
                <RzTxt style={{paddingRight:11}} key={'ftChatLogTxt-' + i} text={body} textStyle={{lineBreak:'anywhere', fontWeight:300}}/>
                <RzTxt key={'ftChatLogDate-' + i} textStyle={{ fontWeight:400, marginTop: 5, fontSize: 11, color: '#9B9B9B', flexShrink: 0, width: 60 }} text={dt} />
            </RzRow>
        </RzCol>)
    }

    /************* */
    async doToggleParentChat() {
        this.setState({ isParentChatOn: !Boolean(this.state.isParentChatOn) })

        let repoMng = this.getRepoMng();
        if (!repoMng) return;

        let ctx = this.getCtx();

        let classId = ctx.classId;
        let r = await repoMng.getClassInfo();

        let ci = { classId: classId, isObserverChatOn: !Boolean(this.state.isParentChatOn) } as FtoClassInfo;
        if (classId) await repoMng.putClassInfo(classId, ci);
    }

    doFloat() {
        if (this.props.onToggle) this.props.onToggle(!Boolean(this.props.isDlgOn));
    }
    /************* 채팅 보내기 */
    doSendMsg() {
        // let msg= this.get
        let repoMng = this.getRepoMng();
        if (!repoMng) { return; }

        let chatRepo = repoMng.getChatRepo();
        if (!chatRepo) { return; }

        let ctx = this.getCtx();        
        // let ui = ctx.userInfo;
        const ui = repoMng?.getUserInfo();
        let text = this.state.text;
        let uname = ui?.username;

        if (uname) chatRepo.setUserName(uname);        
        //chatRepo.addItem(chatMsg);

        if (text) chatRepo.sendMsg(text);
        this.setState({ text: undefined });


    }

}//class






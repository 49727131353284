import { CSSProperties, Component, ReactNode } from "react";
import { RzBasProp, RzBtn, RzCol, RzRow, RzTxt } from "./rzcomp";

/********************
 *  RzPop
 */

interface RzPopProp extends RzBasProp {
    zIndex?:number;
}

interface RzPopStat{
 
}

let isDbgBdr=false;
export class RzPop extends Component<RzPopProp,RzPopStat> {
    constructor(pr:any){
        super(pr);
        this.state={};
    }

    render() {
        let dbgBdr=isDbgBdr?{border:'2px solid orange'}:{};
        let st:CSSProperties={ position:'absolute',  ...this.props.style, ...dbgBdr};
        let {style, children,zIndex,...pr}=this.props;

        if(zIndex) st['zIndex']=zIndex;

        return (<RzCol {...pr} style={st}>{this.props.children}</RzCol>);
    }
 
}//class
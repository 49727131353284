import React, { CSSProperties, createRef, useContext, useEffect, useReducer, useRef, useState } from "react"
import { IsFail, NewFail, RzAwtDlgHolder, RzAwtTool, rzDlgShow, rzlog, RzNoteUi_setDbg, RzNoteUi_setDbgUi, RzNoteUiRepo_setDbg, RzRes } from "./inc"

import { FtClassFac, FtClass_setDbg } from "./ftclass"
import { FtClassConnCmdEnum, FtClassUi_setDbg, FtClassUi_setSessionLayoutOn, FtClientCmdEnum, FtClientDlgEnum } from "./ui/ftclass/ftclass.ui"

import './FtNoteView.css'
import { FtClassEvent, FtClassEventEnum } from "./ftclass.event"
// import { SignalingClient } from './ui/ftclass/ftclass.ui.side'

import { FtNoteTest, FtNote_isTest } from "./FtNoteTest"
import { FtDeskUi_setDbg, FtDeskUi_setDbgUi } from "./ui/ftclass/ftdesk.ui"
import { RzCmnUi_setDbg, RzNoteUiEdit_setDbg, RzNoteUiDraw_setDbg } from "./inc"
import { FtClassUiLayout_setDbg } from "./ui/ftclass/ftclass.ui.layout"
import { RzBook_setDbg, RzNoteUi_setPageInit } from "./inc"
import { FtClassRepoMng, FtClassRepoMng_setDbg } from "./repo/ftclass.repo-mng"
import { FtDeskUiRepo_setDbg } from "./ui/ftclass/ftdesk.ui.repo"
import { FtClassClientFac_setDbg } from "./ftclass.client.fac"
import { FtClassClient_setDbg } from "./ftclass.client"
import { FtClassRepoRef_setDbg } from "./repo/ftclass.repo.ref"
import { FtClassUiToolClient_setDbg } from "./ui/ftclass/ftclass.ui.tool.client"
import { FtDeskUiTop_setDbg } from "./ui/ftclass/ftdesk.ui.top"
import { FtClassClientWs_setDbg } from "./ftclass.client.ws"
import { FtClassRepoMngRecvHdr_setDbg } from "./repo/ftclass.repo-mng.rhdr"
import { FtClassRepoMngSendHdr_setDbg } from "./repo/ftclass.repo-mng.shdr"

import { FT_LAYOUT2_JOIN, FT_LAYOUT2_DEFAULT, FT_LAYOUT2_FRAME, FT_LAYOUT2_FLOAT, FT_LAYOUT2_FLOAT2, FT_LAYOUT2_PORTRAIT, FT_LAYOUT2_PORTRAIT_FLOAT } from "./consts"
import { RzNoteUiEditMemo_setDbg, RzNoteUiEditMemo_setDbgUi } from "./note.ui/rznote.ui.edit.memo"
import { FtClassUiLayoutHdr_setDbg } from "./ui/layout/ftclass.ui.layout.hdr"
import { RzNoteUiEvent_setDbg } from "./note.ui/rznote.ui.event"
import { FtUiContext } from "./ui/ftclass2/ftui.context"
import { FtClass2 } from "./ui/ftclass2/ftclass2.ui"
import { DBG_MIRROR_ON, DBG_ON, FtConfig, FtConfig_getConfig, FtConfig_initCtx, REPO_TYPE_REPO_MNG } from "../config2"
import { CmComponent, RzBtn, RzCol, RzRow, RzTxt } from "./ui/wiz2/rzcomp"
import { FtClass2Repo } from "./repo/ftclass2.repo";
import { FtClass2RepoFac } from "./repo/ftclass2.repo.fac"
import { FtClassRepo } from "./repo/ftclass.repo"
import { Button } from "react-bootstrap"
import { useLocation, useParams } from "react-router-dom"
import { join } from "path"
import { WavleClientContext, WavleMediaClient } from "../wavle-media-client"
import { randomUUID } from "crypto"
import { FtoClassInfo } from "./dto/ftclass.dto"




let _isDbgVisible = DBG_ON;
const _isDbgMirror = DBG_MIRROR_ON;
let _isVideoConn = false;
let _videoCb: ((isConn: boolean) => void)|null=null;

const initVideoConn = async () => {
   rzlog.debug("_IsVideoConn.navigator.mediaDevices=",navigator.mediaDevices);
   if(navigator.mediaDevices===undefined) return;
   if(navigator.mediaDevices.enumerateDevices===undefined) return;

   let devices =await navigator.mediaDevices.enumerateDevices();
   rzlog.debug("_IsVideoConn.devices=",devices);
   if(devices){
      let video = devices.filter(device => device.kind === 'videoinput');
      _isVideoConn= video.length > 0;
      if(_videoCb) _videoCb(_isVideoConn);
      rzlog.debug("_IsVideoConn=",_isVideoConn);
      console.log(_isVideoConn)
   }
}

initVideoConn();


const _isDbgChecked = true;

const initDbg = () => {

   //RzCmnUi_setDbg(true,false)

   //RzNoteUi_setDbg(true,true)
   //RzNoteUi_setDbgUi(true)
   //RzNoteUiDraw_setDbg(true)

   //RzNoteUiEdit_setDbg(true,true)
   RzNoteUi_setPageInit(true);
   //RzNoteUiRepo_setDbg(true)
   RzNoteUiEvent_setDbg(true);

   //RzNoteUiDraw_setDbg(true)
   //RzBook_setDbg(true)
   //FtClassRepoRef_setDbg(true)
   //FtClassClient_setDbg(true)
   //FtClassClientFac_setDbg(true)

   FtDeskUi_setDbg(true)
   // FtDeskUi_setDbgUi(true)
   FtDeskUiRepo_setDbg(true)
   FtDeskUiTop_setDbg(true)

   FtClassUiLayoutHdr_setDbg(true)
   //FtClass_setDbg(true)
   //FtClassRepo_setDbg(true)

   RzNoteUiEditMemo_setDbgUi(true)
   RzNoteUiEditMemo_setDbg(true)

   FtClassUi_setDbg(true)
   //FtClassUiLayout_setDbg(true)
   //FtClassUiFullLayout_setDbg(true)

   //FtClassRepoMng_setDbg(true)
   //FtClassRepoMngSendHdr_setDbg(true)
   FtClassRepoMngRecvHdr_setDbg(true)
   // FtClassClientWs_setDbg(true)

   //FtClassUiToolClient_setDbg(true)
}


/******* */
//const DEF_LAYOUT="frame"
const DEF_LAYOUT = FT_LAYOUT2_DEFAULT//"full"

let isRepoMngOn = false;

/******* */
interface FtDlgProp {
   title?: string;
   body?: string;
   visible?: boolean;
   x?: number;
   y?: number;
   width?: number;
   height?: number;
   onClose?: (btn?: boolean) => void;
}
interface FtDlgStat {
   title?: string;
   body?: string;
   visible?: boolean;
   x?: number;
   y?: number;
   width?: number;
   height?: number;
   onClose?: (btn?: boolean) => void;
   isConfirm?: boolean;
   isCancel?: boolean;
}

class FtDlg extends React.Component<FtDlgProp, FtDlgStat> {
   constructor(props: any) {
      super(props);
      this.state = { 
         isConfirm:true,
         isCancel:true,
         ...props 
      };//title:'',body:'',visible:false,x:0,y:0,width:0,height:0};
   }

   divRef = React.createRef<HTMLDivElement>();
   setPosition(x: number, y: number) {
      if (!this.divRef.current) return;
   }
   setVisible(visible: boolean) {
      if (!this.divRef.current) return;
      this.divRef.current.style.visibility = visible ? 'visible' : 'hidden';
      //this.setState({visible:visible});
   }
   setContent(title: string, body: string, cb?: (btn?: boolean) => void) {
      this.setState({ title: title, body: body, onClose:cb});
   }
   setButton(btn:{isConfirm:boolean, isCancel:boolean}) {      
      this.setState({ isConfirm:btn.isConfirm===undefined?true:btn.isConfirm, 
         isCancel:btn.isCancel===undefined?true:btn.isCancel });
   }

   doClose(btn?: boolean) {
      if(this.state.onClose) this.state.onClose(btn);
      this.setVisible(false);
      this.setButton({isConfirm:true, isCancel:true});
   }

   render() {
      let stat = this.state as any;
      let stRow = { display: 'flex', flexDirection: 'column', justifyContent: 'center' } as CSSProperties;
      let stCol = { display: 'flex', justifyContent: 'center' } as CSSProperties;
      let btnStyle = { fontFamily:'Pretendard Variable', width: '130px', height: '40px', borderRadius: '240px', border: 'none', color: '#FFFFFF', fontSize: '15px', fontWeight: '700' } as CSSProperties;

      let titleStyle = {  marginBottom:20, fontSize: '20px', fontWeight: '500' } as CSSProperties;
      let bodyStyle = { whiteSpace:'pre-wrap', color: '#222222', padding:'0 10px', marginBottom:20, fontFamily:'Pretendard Variable', fontSize: '18px', fontWeight: '500', textAlign:'center' } as CSSProperties;

      return (<div ref={this.divRef}
         style={{
            visibility: (stat.visible ? 'visible' : 'hidden'),
            position: 'relative', top: '-100%',
            width: '100%', height: '100%',
            display: 'flex', flexDirection: 'column',
            justifyContent: 'center', alignItems: 'center',
            zIndex: '9999'
         }}>
         <div style={{ width: stat.width, height: stat.height }}>
            <div style={{ width: '100%', height: '100%', backgroundColor: '#FFFFFF', borderRadius: '5px', ...stRow, boxShadow: '0px 0px 20px 0px #63636340'}}>
               <div style={{ width: '100%', ...stCol }} >
                  <div style={{ ...titleStyle }}>
                     <span>{stat.title}</span>
                     {/* <text>{`학부모 참관수업 요청`}</text> */}
                  </div>
                  {/* <div style={stCol}><div onClick={() => { this.doClose() }}>X</div></div> */}
               </div>
               <div style={{ ...bodyStyle, ...stCol }}>
                  {stat.body}
                  {/* <pre>{`참관수업 요청이 들어왔습니다.\n 참관을 허용하시겠습니까?`}</pre> */}
               </div>
               <div style={{ display: 'flex', gap: '10px', justifyContent: 'center', marginTop:10 }}>
                  {this.state.isCancel && <Button style={{ backgroundColor: '#FF4141', ...btnStyle }} onClick={() => this.doClose(false)}>취소</Button>}                  
                  {this.state.isConfirm && <Button style={{ backgroundColor: '#4176FF', ...btnStyle }} onClick={() => this.doClose(true)}>확인</Button>} 
               </div>
            </div>
         </div>
      </div >
      );
   }
}//class

/********* */
class FtDlgHolder implements RzAwtDlgHolder {
   dlgRef: React.RefObject<FtDlg>;
   homeRef: React.RefObject<HTMLDivElement>;
   constructor(dlgRef: React.RefObject<FtDlg>, homeRef: React.RefObject<HTMLDivElement>) {
      this.dlgRef = dlgRef;
      this.homeRef = homeRef;
   }

   showDlg(title: string, msg: string, cb: (btn?: boolean) => void, btn?:any): void {
      rzlog.debug('DlgHolder.showDlg : title=', title, ',msg=', msg, ',cb=', cb);
      let y = 0;
      let x = 0;
      let dw = 400;
      let dh = 200;
      if (this.homeRef.current) {

         let w = this.homeRef.current.clientWidth;
         let h = this.homeRef.current.clientHeight;
         if (w) x = (w - dw) / 2;
         if (h) y = (h - dh) / 2;
      }
      /** FIXME: error position calc 확인필요 */
      // if (x > 0 && y > 0 && this.dlgRef.current) {
      //    this.dlgRef.current.setPosition(x, y);
      // }
      if (this.dlgRef.current) {
         this.dlgRef.current.setVisible(true);
         /** add content */
         this.dlgRef.current.setContent(title, msg, cb);

         if (btn) {
            this.dlgRef.current.setButton(btn);
         }
      }
      // rzDlgShow(this.dlgRef,title,msg,btns,cb);
   }
}//class


interface PostJoinParams {
    userId:string;
    devType:string;
    userType:string;
    classId:string; 
    beginAt?:string;
    classInfo?:FtoClassInfo;
    hasJoined:boolean;
}


//classId=1&fromUid=2&type=student&devType=pc
interface JoinParams {
   classId?: string;
   fromUid?: string;    
   type?: string;
   deviceType?: string;
   mode?:string;
};

type FtAttrs = {
   isCtrlOn: boolean;
   isSideOn: boolean;
   isChatOn: boolean;
   layoutType: string;
   roomId: string;
   userId: string;
   userType: string;
   devType: string;
   isMirrorOn: boolean;
   isMirrorDbgOn: boolean;
   isClientDbgOn: boolean;
   isApiLogOn: boolean;
   mode:string|null;
   isDirectJoin:boolean;
};

 
/******* */

function sleep(ms: number) {
   return new Promise((r) => setTimeout(r, ms));
}

 

const FtClassView2 = (props: any) => {
   const ftClassRef = useRef<FtClass2>(null)
   const homeRef = useRef<HTMLDivElement>(null)
   const ftFunc = useRef<(ctx: FtUiContext) => void>();

   let { params } = props;
   let joinParams = params as JoinParams;
   rzlog.debug("Join PARAMS=", joinParams);
   let deviceType=joinParams?.deviceType||'pc';
   let conf = FtConfig_getConfig();
   
   //FtClass2RepoFac.initRepoMng(conf);

   // {} as FtClass2Repo;

   let defLayout=DEF_LAYOUT;
   if(deviceType==='mobile') defLayout=FT_LAYOUT2_PORTRAIT;

   /****** */
   const defAttrs :FtAttrs= {
      isCtrlOn: true, isSideOn: true, isChatOn: true,
      layoutType: defLayout,
      roomId: '1',
      userId: 'test01',
      userType: 'teacher',
      devType: 'pc',
      isMirrorOn: false,
      isMirrorDbgOn: false,
      isClientDbgOn: false,
      isApiLogOn: false,
      mode:null,
      isDirectJoin: Boolean(joinParams),
   };


 
   let defUiCtx: FtUiContext = {
      ftClassRef: ftClassRef,
      camCount: 1,
      videoCount:1,
      layoutType: defLayout||FT_LAYOUT2_JOIN,
      deviceType: deviceType,
      cousumer: [],
      conf: conf,
      isSkipJoin: false,
      isLoad: false,
      setGlobalCtx: (ctx: FtUiContext) => {
         rzlog.debug("FtUiContext : fn=", Boolean(ftFunc.current), ',ctx=', ctx);

         if(ctx?.notes&&ctx.notes.length>3&&ctx.notes[2].isShareOn==false) {
            let a=1;
         }
         if (ftFunc.current) ftFunc.current(ctx);
          
         setDefCtx(ctx);
      }      
   };

  
   let myDefCtx=defUiCtx;
   const setDefCtx=(ctx:FtUiContext)=>{
      myDefCtx=ctx;
   }
   
   
   const setTopDbgBar=(b:boolean)=>{
      _isDbgVisible=b;
   }
   
   const getDefCtx=()=>myDefCtx;




   defUiCtx = FtConfig_initCtx(defUiCtx);

   const [ftGlobalCtx, setGlobalCtx] = useState(defUiCtx);
   const [ftAttrs, setFtAttrs] = useState(defAttrs);
   const [curUserId, setUserId] = useState("test01");
   const [isDlgOn, setDlgOn] = useState(true);
   const dlgRef = useRef<FtDlg>(null);
   const [isLoading, setLoading] = useState(Boolean(joinParams));

   const [isCamLoad, setCamLoad] = useState(false);
   let repo = FtClass2RepoFac.getDefaultRepo(conf);
   if (repo) ftGlobalCtx.repo = repo;

   if (!isRepoMngOn && repo) {
      isRepoMngOn = true;
      //alert('Class2Repo='+repo+', rp.name='+repo?.getName()+",rp.mng="+repo?.getRepoMng());
   }

   const defFocus = { isRoomOn: false, isUserIdOn: false, isUserTypeOn: false, isDevTypeOn: false };
   //const defFocus={ isRoomOn:false, isUserIdOn:false,isUserTypeOn:false,isDevTypeOn:false};
   const [focus, setFocusOn] = useState(defFocus);
   const wavleClient = useContext(WavleClientContext);
   let tuiCtx = ftGlobalCtx as FtUiContext;
   ftFunc.current = setGlobalCtx;
   rzlog.debug(">>>>>>>>>>>>>>>>>> layoutType=", tuiCtx.layoutType, ', ctx=', tuiCtx);

   useEffect(() => {
      new Promise(async () => {
         let conf = { repoType: REPO_TYPE_REPO_MNG } as FtConfig;
         let r = await FtClass2RepoFac.initRepoMng(conf);
         //alert('initRepoMgn :r='+JSON.stringify(r));
         if (!IsFail(r)) {
            let clzRepo = r.data as FtClass2Repo;
            let rmng: FtClassRepoMng | null = null;

            if (clzRepo) rmng = clzRepo.getRepoMng();

            tuiCtx.setGlobalCtx({ ...tuiCtx, repo: clzRepo, repoMng: rmng || undefined });
            rzlog.info("useEffect > FtClassRepoFac.initRepoMng - rmng=", rmng);

            if(joinParams) {
               setDbgChecked(false);
               setTopDbgBar(false);
               setTimeout(() => {
                  doJoinClass(joinParams);
                  // setConn(true);
               },1400);
            }
            //@alert('initRepoMng OK:'+clzRepo+',mng='+rmng);
         }
      });

      let holder = new FtDlgHolder(dlgRef, homeRef);
      RzAwtTool.setDlgHolder(holder);
   }, []);

   const doChangeLayout = (fattrs: any) => {
      setFtAttrs(fattrs);

      if (ftClassRef.current) ftClassRef.current.setLayoutType(fattrs.layoutType);
   };

   const doLeaveClass = async () => {
      if (!ftClassRef.current) return;
      if (!ftClassRef.current.isRepoMngConnected()) return;
      await ftClassRef.current.leaveClass();
   };

   const doRejoinPrev = async ():Promise<RzRes<FtoClassInfo>> => {
      if(ftClassRef.current && (ftClassRef.current as FtClass2).hasStoredInfo() && tuiCtx.repoMng && !(tuiCtx.userInfo)){
               let classId=(ftClassRef.current as FtClass2).getStoredClassId();
               rzlog.debug("flcass2.didMnt - UserInfo : classId=",classId);
               if(classId) {
                  let r= await (ftClassRef.current as FtClass2).joinClass(classId);
                  return r;
               }
      }
      return NewFail('no prev class');
   }

   const doJoinClass = async (joinPrs?:JoinParams) => {
      let classId = ftAttrs.roomId;
      rzlog.debug('doJoinClass : classID=', classId, ',userId=', curUserId);
      let rr=await doRejoinPrev();
      if(!IsFail(rr) ) return;

      if (!ftClassRef.current) return;

      let accToken = '1111';
      let userId = curUserId;// ftAttrs.userId;
      rzlog.debug('FtNoteView.doJoinClass:userId=', userId);
      let devType = ftAttrs.devType;
      let userType = ftAttrs.userType;
      let loginMode:string|null=null;
      if(joinPrs) {
         classId=joinPrs.classId||classId;
         let fuid=joinPrs.fromUid;
         if(fuid)fuid='@'+fuid
         userId=fuid||userId;
         userType=joinPrs.type||userType;
         devType=joinPrs.deviceType||devType;
         loginMode=joinPrs.mode||null;
         //alert('JOIN direct');
      }
       
 

      
      (ftClassRef.current as FtClass2).setLoginInfo(userId, accToken, userType, devType,classId,loginMode||undefined);
      
      let rci=await (ftClassRef.current as FtClass2).joinClass(classId);
      if(IsFail(rci)){
         return rzlog.error('doJoinClass : Fail : rci=',rci);
      }
      let pr={ userId:curUserId, 
               devType:ftAttrs.devType,
               userType:userType,
               classId:classId, 
               beginAt:rci.data?.beginAt,
               classInfo:rci.data as FtoClassInfo,
               hasJoined:false,
            } as PostJoinParams;

      doPostJoin(pr,joinPrs);
   };

   function isIpad() {
      const userAgent = navigator.userAgent
      if (/Macintosh/.test(userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1) {
        return true; 
      }       
      return /iPad/.test(userAgent);
   }

   function isTablet() {
      const userAgent = navigator.userAgent.toLowerCase();                
      const isGenericTablet = /(tablet|ipad|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
               
      return isGenericTablet || isIpad();
   }       

   const doPostJoin = async (pr:PostJoinParams, joinPrs?:JoinParams  ) => {
      console.log('doPostJoin',pr, joinPrs)
       let userId = joinPrs?.fromUid || pr.userId;// ftAttrs.userId;
       rzlog.debug('FtNoteView.doJoinClass:userId=', userId);
       let devType = joinPrs?.deviceType || pr.devType;
       let userType = pr.userType;
       let classId=pr.classId;


      let ly = FT_LAYOUT2_JOIN;
      if(pr.hasJoined){
         ly=FT_LAYOUT2_FRAME;

         if(devType==='mobile' && !isTablet()){
            ly=FT_LAYOUT2_PORTRAIT_FLOAT;
         } 
      }
      // const userAgent = navigator.userAgent.toLowerCase();      
      // const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
      // if (isTablet) {
      //    ly = FT_LAYOUT2_PORTRAIT;
      // }


      let isSkipJoin = false;
      if(joinPrs){
         let curDate=new Date();
         rzlog.debug('doJoinClass : curDt=',curDate,',beginAt=',pr.beginAt);

         if(pr.beginAt && curDate.getTime() > new Date(pr.beginAt).getTime()){
            rzlog.debug('doJoinClass : class was started')            
            
            if(devType==='mobile' && !isTablet()) {
               ly = FT_LAYOUT2_PORTRAIT_FLOAT;                           
               isSkipJoin = true;   
            } else {
               ly = FT_LAYOUT2_FRAME;
               // 반응형으로 작업되면서 세로모드화면은 사용안함
               // if (isTablet()) {
               //    ly = FT_LAYOUT2_PORTRAIT;
               // }
               isSkipJoin = true;
            }
            
            // setTimeout(()=>{
            //    if(ftClassRef.current ){
            //       if(devType==='mobile')
            //          ftClassRef.current.setLayoutType(FT_LAYOUT2_PORTRAIT_FLOAT);
            //       else 
            //          ftClassRef.current.setLayoutType(FT_LAYOUT2_FRAME);
            //    }
                  
            // },1000);
         }
      }

      if (_isVideoConn) {       
         console.log('_isVideoConn 1 ===>',{
            roomId: classId,
            id: userId,
            // id: userId+'@'+devType,
            deviceType: devType === 'desktop' ? 'pc' : devType,
            deviceKind: devType === 'mobile' ? 'main' : devType === 'desktop' ? 'all' : 'sub'
         })      
         await wavleClient.init({
            roomId: classId,
            id: userId,
            // id: userId+'@'+devType,
            deviceType: devType === 'desktop' ? 'pc' : devType,
            deviceKind: devType === 'mobile' ? 'main' : devType === 'desktop' ? 'all' : 'sub'
         });        
         
         wavleClient.onConnect(async () => {
            console.log('onConnect ===> ', true)
            if(ftClassRef.current){
               ftClassRef.current.setInitLayoutType(ly);
            }            
        });
      } else {
         if(ftClassRef.current){
            ftClassRef.current.setInitLayoutType(ly);
         }         
      }

      setConn(true);     
   }

   

   const videoStart = async () => {
      if (_isVideoConn) {
         if (!tuiCtx) return;
         let ui = tuiCtx.userInfo as any;
         if (!ui) return;
    

         // await wavleClient.init({
         //    roomId: ui!.classId,
         //    id: ui!.uid+'1'
         // })        
         
         // wavleClient.onConnect(async () => {
         //    setGlobalCtx({ ...ftGlobalCtx, 
         //       isLoad:true
         //    });
         // })
      } else {

         setGlobalCtx({ ...ftGlobalCtx, 
            isLoad:true
         });
      }

   }
   const onMovePage = (pgOff: number, type?: string) => {
      rzlog.debug('onMovePage : move  pgOff=', pgOff, ',type=', type);
      if (!ftClassRef.current) return;
      (ftClassRef.current as any).movePage(pgOff, type);
   };

   const [isEventPaused, setEventPaused] = useState(false);
   const setEventLock = () => {
      if (ftClassRef.current) {
         (ftClassRef.current as any).setEventPaused(!isEventPaused);
      }
      setEventPaused(!isEventPaused);
   };

   const [isConn, setConn] = useState(false);
   
   useEffect(() => {      
      if (isConn) {         
         // videoStart();
         setLoading(false);
      }
      
   }, [isConn])


   //REJOIN when REFRESH(F5)
   useEffect(  () =>  {
      if (ftClassRef.current) {
         doRejoinPrev().then((r)=>{
            if(IsFail(r)){
               rzlog.debug('UserInfo: doRejoinPrev : Fail : r=',r);
               return;
            }
            
            setTimeout(()=>{
               //tuiCtx.setGlobalCtx({ ...tuiCtx, layoutType:FT_LAYOUT2_FRAME });
               let rci=r;
               let classId=r.data?.classId;

               let pr={ 
                  classId:classId, 

                  userId:curUserId, 
                  devType:ftAttrs.devType,
                  userType:ftAttrs.userType, 
                  
                  beginAt:rci.data?.beginAt,
                  classInfo:rci.data as FtoClassInfo,
                  hasJoined:true,
               } as PostJoinParams;
   
               let repoMng=tuiCtx.repoMng;
               if(repoMng){
                  repoMng.getChatRepo()
               }
               
               doPostJoin(pr);
            },1000);

         });

      }
   }, [ftGlobalCtx.repoMng]);

   const SubFtClassDbgView = () => {
      //const [userType,setUserType]=useState('teacher')
      const userType = ftAttrs.userType;
      const devType = ftAttrs.devType;

      // const [videoSignal, setVideoSignal] = useState<SignalingClient | null>(null);
      // const signalC = useContext(SignalingContext);
      // const wavleClient = useContext(WavleClientContext);

      return (<div style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#ffa50080', fontFamily: 'Pretendard', fontSize: 15 }}>
         <label style={{ width: '50px' }}><input type='checkbox' name='ctrl' checked={ftAttrs.isCtrlOn}
            onChange={() => {
               let nattrs = { ...ftAttrs, isCtrlOn: !ftAttrs.isCtrlOn };
               doChangeLayout(nattrs);
            }} />top</label>
         <label style={{ width: '50px' }}><input type='checkbox' name='side' checked={ftAttrs.isSideOn}
            onChange={() => {
               let nattrs = { ...ftAttrs, isSideOn: !ftAttrs.isSideOn };
               doChangeLayout(nattrs);
            }} />side</label>
         <label style={{ width: '60px' }}><input type='checkbox' name='chat' checked={ftAttrs.isChatOn}
            onChange={() => {
               let nattrs = { ...ftAttrs, isChatOn: !ftAttrs.isChatOn };
               doChangeLayout(nattrs);
            }} />chat</label>
         {/* <button onClick={() => { doChangeLayout({ ...ftAttrs, layoutType: 'full' }) }} >full</button>
               <button onClick={() => { doChangeLayout({ ...ftAttrs, layoutType: 'book' }) }} >book</button>
               <button onClick={() => { doChangeLayout({ ...ftAttrs, layoutType: 'note' }) }} >note</button> */}
         <button onClick={() => {
            doChangeLayout({
               ...ftAttrs,
               layoutType: ftAttrs.layoutType === FT_LAYOUT2_JOIN ? FT_LAYOUT2_FRAME :
                  ftAttrs.layoutType === FT_LAYOUT2_FRAME ? FT_LAYOUT2_FLOAT2 :
                     ftAttrs.layoutType === FT_LAYOUT2_FLOAT2 ? FT_LAYOUT2_FLOAT :
                        FT_LAYOUT2_JOIN
            })
         }} >
            {  ftAttrs.layoutType === FT_LAYOUT2_FRAME ? 'frm' :
               ftAttrs.layoutType === FT_LAYOUT2_FLOAT ? 'float' :
               ftAttrs.layoutType === FT_LAYOUT2_FLOAT2 ? 'float2' :
               'join'}
         </button>
         <button onClick={() => { setEventLock(); }} >{isEventPaused ? "ulk" : "lck"}</button>

         <label style={{ width: '56px', textAlign: 'end' }} >roomID </label>
         <input ref={(input) => { if (focus.isRoomOn && input) input.focus() }} type='text' value={ftAttrs.roomId} style={{ width: '40px' }}
            onChange={(e) => {
               setFocusOn({ ...defFocus, isRoomOn: true });
               setFtAttrs({ ...ftAttrs, roomId: e.target.value });
            }} />

         <label style={{ width: '56px', textAlign: 'end' }} >userId </label>

         <input ref={(input) => { if (focus.isUserIdOn && input) input.focus() }} type='text'
            defaultValue={curUserId} style={{ width: '50px' }}
            onChange={(e) => {
               e.preventDefault();
               rzlog.debug("new Name=" + e.target.value);
               setFocusOn({ ...defFocus, isUserIdOn: true });
               setUserId(e.target.value);
            }}
         />
         <select value={userType} onChange={(e) => {
            //rzlog.debug('Event : e=',e.target.value)
            setFtAttrs({ ...ftAttrs, userType: e.target.value });
         }}>
            <option value='teacher'  >teacher</option>
            <option value='student'  >student</option>
            <option value='observer' >observer</option>
         </select>

         <select onChange={(e) => {
            //rzlog.debug('Event : e=',e.target.value)
            setFtAttrs({ ...ftAttrs, devType: e.target.value });
         }} value={devType}>
            <option value='pc' >pc</option>
            <option value='mobile' >mobile</option>
         </select>

         <button onClick={async () => {
            if (isConn) {
               await doLeaveClass();

               if (_isVideoConn && wavleClient) {
                  wavleClient.disconnect();
               }

               setConn(false);
            } else {                                 
               //  alert('setConn:true')
               // setConn(true);
               await doJoinClass();
            }
         }} >{isConn ? 'leave' : 'join'}</button>
         <label style={{ width: '60px' }}><input type='checkbox' name='mode' checked={ftAttrs.mode!==null}
            onChange={() => {
               //let nattrs = { ...ftAttrs, mode: (ftAttrs.mode)?null:'force'};
               setFtAttrs({ ...ftAttrs, mode: (ftAttrs.mode)?null:'force'});   
            }} />force</label>         
         <label style={{ width: '50px', textAlign: 'end' }} > Book </label>
         <button onClick={async () => { onMovePage(-1, 'book') }} > &lt;</button>
         <button onClick={async () => { onMovePage(1, 'book') }}> &gt; </button>
         <label style={{ width: '50px', textAlign: 'end' }} > Note </label>
         <button onClick={async () => { onMovePage(-1, 'note') }} > &lt;</button>
         <button onClick={async () => { onMovePage(1, 'note') }}> &gt; </button>
      </div>)
   };


   const dlgX = 300;
   const dlgY = 400

   const [isDbgChecked, setDbgChecked] = useState(_isDbgChecked);
   const [isVideoOn ,setVideoOn]=useState(_isVideoConn);
   const [isInfoOn ,setInfoOn]=useState(false);

   const classInfoDlgRef=createRef<FtClassInfoDlg>();


   _videoCb=(b:boolean)=>{
      setVideoOn(b);
   }

   let ctx= ftGlobalCtx;
   let isDbgVisible = _isDbgVisible;
   let isDbgMirror = _isDbgMirror;
   let stDbg: CSSProperties = { position: 'absolute', left: '0px', top: '0px', display: 'flex', flexDirection: 'row', backgroundColor: '#00000030' };
   let stDbgContain: CSSProperties = { position: 'absolute', left: '0px', top: '0px', zIndex: 1, display: 'flex', flexDirection: 'column', backgroundColor: '#00000030' };
   let isDbgBtnOn=false;
   return (<div ref={homeRef} style={{ width: '100vw', height: '100vh' }}>
      {isDbgVisible && (
         <div style={stDbgContain}>
            <div style={stDbg}>
               <div style={{ backgroundColor: 'white', cursor: 'pointer' }}
                  onClick={() => { setDbgChecked(!isDbgChecked); }}>{isDbgChecked ? '<<' : '>>'}</div>
               {isDbgChecked && (<SubFtClassDbgView />)}
               <div style={{ backgroundColor: 'lightgray', cursor: 'pointer' }}
                  onClick={() => {                     
                     doChangeLayout({
                        ...ftAttrs,
                        layoutType: ftAttrs.layoutType === FT_LAYOUT2_JOIN ? FT_LAYOUT2_FRAME :
                              ftAttrs.layoutType === FT_LAYOUT2_FRAME ? FT_LAYOUT2_PORTRAIT :
                              ftAttrs.layoutType === FT_LAYOUT2_PORTRAIT ? FT_LAYOUT2_FLOAT2 :
                              ftAttrs.layoutType === FT_LAYOUT2_FLOAT2 ? FT_LAYOUT2_FLOAT :
                                 FT_LAYOUT2_JOIN
                     })
                  }} ><span style={{ fontWeight: 400 }}>NEXT</span>
               </div>

               
                  <RzBtn text={isVideoOn?'Video On':'Video Off'}style={{ backgroundColor: 'lightgray', marginLeft:10,width: 80  }} textStyle={{ color: 'black' }}
                        onClick={() => {
                              _isVideoConn=!_isVideoConn;
                              setVideoOn(_isVideoConn);
                     }} />
                  <RzBtn text={isInfoOn?'Prev Off':'Prev On'}style={{ backgroundColor: 'lightgray', marginLeft:10,width: 60  }} textStyle={{ color: 'black' }}
                     onClick={() => {
                        //setInfoOn(!isInfoOn);

                        if(ftClassRef.current){
                           ftClassRef.current.clearStoreInfo();
                        }

                     }} />
                  {isDbgBtnOn&&(
                        <RzBtn text="DBG" style={{ backgroundColor: 'lightgray', marginLeft:10,width: 80 }} textStyle={{ color: 'black' }}
                        onClick={() => {
                        //ftClassRef.current?.setContextAttr({isParentJoinOn:true });
                        //let ctx=ftGlobalCtx;//getDefCtx();
                        let ctx=ftClassRef.current?.getCtx();
                        alert('ctx='+ctx?.curNoteId);
                        //ftClassRef.current?.fetchClientCmd(FtClientCmdEnum.CLASS_INFO);
                     }} />
               )}

            </div>
            {(isInfoOn)&&(<FtClassInfoDlg  visible={isInfoOn} />)}
          
            {(isDbgChecked && isDbgMirror) && (
               <div style={{ position: 'absolute', left: '0px', top: '54px', height: '24px', display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: '20px' }}></div>
                  <SubMirrorDbgView ftClassRef={ftClassRef} setFtAttrs={setFtAttrs} ftAttrs={ftAttrs} />
                  <div style={{ width: '20px' }} />
                  {(<SubFtClientDbgView ftClassRef={ftClassRef} setFtAttrs={setFtAttrs} ftAttrs={ftAttrs} />)}
                  <div style={{ width: '20px' }} />
                  <SubFtApiLogDbgView ftClassRef={ftClassRef} setFtAttrs={setFtAttrs} ftAttrs={ftAttrs} />
               </div>
            )}
         </div>
      )}
      {(isLoading&& (!Boolean(ctx.isRepoLoaded))) && (
         <div className="loading-spinner" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>                
            <div>Loading...</div> 
         </div>
      )}
      <FtClass2 id='ftClass2Main' ref={ftClassRef} context={tuiCtx} layoutType={tuiCtx.layoutType} wavleClient={wavleClient} />
      <FtDlg ref={dlgRef} visible={false} width={300} height={200}/>
   </div>
   );
}


const SubMirrorDbgView = (attrs: any) => {
   let { ftClassRef, setFtAttrs, ftAttrs } = attrs;
   let isOff = !ftAttrs.isMirrorDbgOn;

   const onMirrorViewOn = (isMirrorViewOn: boolean) => {
      rzlog.debug('onMirrorViewOn');
      if (!ftClassRef.current) return;

      setFtAttrs({ ...ftAttrs, isMirrorViewOn: isMirrorViewOn });
      ftClassRef.current.showClientToolDlg(FtClientDlgEnum.MIRROR, isMirrorViewOn);
   };

   const onMirrorEventOn = (isMirror: boolean) => {
      if (!ftClassRef.current) return;
      rzlog.debug('SubMirrorDbgView.onMirrorOn:isMirror=', isMirror);
      if (isMirror) {
         ftClassRef.current.fetchClientCmd(FtClassConnCmdEnum.MIRROR_ON);
      } else {
         ftClassRef.current.fetchClientCmd(FtClassConnCmdEnum.MIRROR_OFF);
      }
   };

   useEffect(() => {
      if (ftClassRef.current?.showClientToolDlg)
         ftClassRef.current.showClientToolDlg(FtClientDlgEnum.MIRROR, !isOff && ftAttrs.isMirrorViewOn);

   }, [isOff]);


   return (<div style={{ backgroundColor: '#84c5ff80', display: 'flex', flexDirection: 'row' }}>
      <label style={{ width: '80px' }}><input type='checkbox' name='conn' checked={ftAttrs.isMirrorDbgOn}
         onChange={() => {
            let nattrs = { ...ftAttrs, isMirrorDbgOn: !ftAttrs.isMirrorDbgOn, isMirrorViewOn: !ftAttrs.isMirrorDbgOn };
            setFtAttrs(nattrs);
         }} />Mirror</label>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
         <button disabled={isOff} onClick={async () => { onMirrorEventOn(!ftAttrs.isMirror) }} >Run</button>
         <button disabled={isOff} onClick={async () => { onMirrorViewOn(!ftAttrs.isMirrorViewOn) }} >Show</button>
      </div>
   </div>);
}


/*** Client Debug view*/
const SubFtClientDbgView = (attrs: any) => {
   let { ftClassRef, setFtAttrs, ftAttrs } = attrs;
   let isOff = !ftAttrs.isClientDbgOn;


   const onFtClientConnOn = (isMirror: boolean) => {
      if (!ftClassRef.current) return;
      rzlog.debug('onFtClientConnOn');
      if (isMirror) {
         ftClassRef.current.fetchClientCmd(FtClientCmdEnum.CONN_ON);
      } else {
         ftClassRef.current.fetchClientCmd(FtClientCmdEnum.CONN_OFF);
      }
   };

   const onFtClientLogOn = (isLogOn: boolean) => {
      setFtAttrs({ ...ftAttrs, isClientLog: isLogOn })
      ftClassRef.current.showClientToolDlg(FtClientDlgEnum.CLIENT, !isOff && isLogOn)
   };

   useEffect(() => {
      if (ftClassRef.current?.showClientToolDlg)
         ftClassRef.current.showClientToolDlg(FtClientDlgEnum.CLIENT, !isOff)
   }, [isOff]);


   //640 x 780


   return (<div style={{ backgroundColor: '#f4c5ff80', display: 'flex', flexDirection: 'row' }} >
      <label style={{ width: '70px' }}><input type='checkbox' name='conn' checked={ftAttrs.isClientDbgOn}
         onChange={() => {
            let nattrs = { ...ftAttrs, isClientDbgOn: !ftAttrs.isClientDbgOn, isClientLog: !ftAttrs.isClientLog }
            setFtAttrs(nattrs);
         }} />Client</label>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
         <button disabled={isOff} onClick={async () => { onFtClientConnOn(!ftAttrs.isClientConn) }} >
            {ftAttrs.isClientConn ? 'Conn' : 'Close'}</button>
         <button disabled={isOff} onClick={async () => { onFtClientLogOn(!ftAttrs.isClientLog) }} >
            {!ftAttrs.isClientLog ? 'LogOn' : 'LogOff'}
         </button>
      </div>
   </div>);
}

/*** Client Debug view*/
const SubFtApiLogDbgView = (attrs: any) => {
   let { ftClassRef, setFtAttrs, ftAttrs } = attrs;
   let isOff = !ftAttrs.isApiLogOn;

   const onFtApiLogOn = (isLogOn: boolean) => {
      setFtAttrs({ ...ftAttrs, isApiLogOn: isLogOn });
      //ftClassRef.current.showClientToolDlg(FtClientDlgEnum.API_LOG,isLogOn)
   };

   useEffect(() => {
      if (ftClassRef.current?.showClientToolDlg)
         ftClassRef.current.showClientToolDlg(FtClientDlgEnum.API_LOG, !isOff);
   }, [isOff]);


   //640 x 780
   return (<div style={{ backgroundColor: '#f4c5ff80', display: 'flex', flexDirection: 'row' }} >
      <label style={{ width: '70px' }}>
         <input type='checkbox' name='conn'
            checked={ftAttrs.isApiLogOn}
            onChange={() => {
               let nattrs = {
                  ...ftAttrs,
                  isApiLogOn: !ftAttrs.isApiLogOn
               };
               setFtAttrs(nattrs);
            }} />Api Log
      </label>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
         <button disabled={isOff}
            onClick={async () => { onFtApiLogOn(!ftAttrs.isApiLog); }} >
            {!ftAttrs.isApiLog ? 'LogOn' : 'LogOff'}
         </button>
      </div>
   </div>);
}
/*********** */

const FtNoteView2 = (props:any) => {
   initDbg();
   const location = useLocation(); 
   let isTest = FtNote_isTest();
   if (isTest) return (<FtNoteTest></FtNoteTest>);

   let { search } = location;
   let params :any|null = null;
   //
   //console.log("search=", search);
   if(search && search.startsWith("?")) {
      search=search.substring(1);
      rzlog.debug("search=",search);
      let vs = search.split('&');
      params={};
      vs.forEach((v)=>{
         let kv = v.split('=');
         if(kv.length==2){
            //if(!params) params={};
            params[kv[0]]=kv[1];
         }
      });//for
   }

   return (<FtClassView2 params={params} />);
}


/******* */


export default FtNoteView2;


interface FtClassInfoProp{
   visible?:boolean;
}
interface FtClassInfoStat{
   classId?:string;
   classInfo?:FtoClassInfo;
}

export class FtClassInfoDlg extends CmComponent<FtClassInfoProp,FtClassInfoStat> {
   constructor(props: any) {
      super(props);
      this.state={};
   }

   componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<FtClassInfoStat>, snapshot?: any): void {
      let ctx=this.getCtx();
      rzlog.debug("FtClassInfoDlg.componentDidUpdate : ctx.classId=",ctx.classId);
      
      if(this.state.classId!==ctx.classId){
         rzlog.debug("FtClassInfoDlg.componentDidUpdate : classId=",ctx.classId);
         this.bindClassInfo();
      }
   }

   async bindClassInfo(){
         let rmng=this.getRepoMng();
         if(!rmng) return;
         let r=await rmng.getClassInfo();
         if(IsFail(r)) return;
         let ci=r.data as FtoClassInfo;
         if(ci) alert('ci='+JSON.stringify(ci));
         this.setState({classId:ci.classId,classInfo:ci});
   }


   public render() {

      let ci=this.state.classInfo;
      let atts= (ci?.attendants)? JSON.stringify(ci.attendants):'';
      let visi=this.props.visible;

      return (<RzCol style={{position:'absolute', 
            top:40, left:160,
            width:400,height:100,
            backgroundColor:'white'}}>
            <RzRow>
               <RzCol>
                  <RzTxt text="Class Info" textStyle={{fontSize:16,color:'black'}} />
               </RzCol>
            </RzRow>
            <RzRow>
               <RzCol>
                  <RzTxt text={"Atts:"+visi} textStyle={{fontSize:16,color:'black'}} />
               </RzCol>
               <RzCol>
                  <RzTxt text={atts} textStyle={{fontSize:16,color:'black'}} />
               </RzCol>
            </RzRow>
      </RzCol>);
   }
}
